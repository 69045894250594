import React, { Fragment } from "react";
import { List, Button, Input, Toast } from "antd-mobile";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import _ from 'lodash';
import {
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_OTHER_VALUE,
  FetchElementList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 基金属性更新
 */
export default class FundPopup extends BaseExtractValuePopup {
  
  constructor(props) {
    super(props)

    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })

    this.state = {
      _info: info,
      _investorTypeOptions: [],
      _investorCategoryOptions: [],
      _assetCategoryOptions: [],
    }
  }
  componentDidMount() {
    this.fetchInvestorCategoryList()
    this.fetchInvestorTypeList()
    this.fetchAssetCategoryList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  // 出资方身份
  async fetchInvestorTypeList() {
    try {
      let options = await FetchElementList("Lable_smartx_gp_investor")
      this.setState({ _investorTypeOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 出资方类型
  async fetchInvestorCategoryList() {
    try {
      let options = await FetchElementList("Lable_smartx_gp_investor_type")
      this.setState({ _investorCategoryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 资产类别
  async fetchAssetCategoryList() {
    try {
      let options = await FetchElementList("Lable_smartx_gp_asset_type")
      this.setState({ _assetCategoryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  renderBodyContent() {
    const {
      _info,
      _investorTypeOptions,
      _investorCategoryOptions,
      _assetCategoryOptions,
    } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="出资方身份"
            multiple={true}
            options={_investorTypeOptions}
            value={_info['investorTypes'] ? _.map(_info['investorTypes'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'investorTypes', options)}
          />

          <CECSelectorListItem label="出资方类型"
            multiple={true}
            options={_investorCategoryOptions}
            value={_info['investorCategorys'] ? _.map(_info['investorCategorys'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'investorCategorys', options)}
          />

          <CECSelectorListItem label={'币种'}
            multiple={true}
            options={CURRENCY_OPTIONS}
            value={_info['investorCurrencys'] ? _.map(_info['investorCurrencys'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'investorCurrencys', options)}
          >
            {
              _info['investorCurrencys'] && _.map(_info['investorCurrencys'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['investorCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'investorCurrencyOther')} />
            }
          </CECSelectorListItem>

          <CECSelectorListItem label="资产类别"
            multiple={true}
            columns={2}
            options={_assetCategoryOptions}
            value={_info['assetCategorys'] ? _.map(_info['assetCategorys'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'assetCategorys', options)}
          />
        </List>
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}