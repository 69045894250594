import React, { Fragment, useCallback, useState } from "react";
import { Tabs } from "antd-mobile";
import FinancingNeedsListView from "./FinancingNeedsListView";
import SaleRequirementListView from "./SaleRequirementListView";
import MergerAndAcquisitionNeedsListView from "./MergerAndAcquisitionNeedsListView";
import SplitAndRestructureNeedsListView from "./SplitAndRestructureNeedsListView";
import InvestmentNeedsListView from "./InvestmentNeedsListView";
import CapitalRaisingRequirementListView from './CapitalRaisingRequirementListView';
import InvestmentExitNeeds4DealListView from "./InvestmentAndExitNeedsListView/Deal";
import InvestmentExitNeeds4FundListView from "./InvestmentAndExitNeedsListView/Fund";

/**
 * 交易机会
 */
export default function TradingOpportunityListView({ code, targetExtensionValue }) {

  const [tabKey, setTabKey] = useState("info_enterprise_trading_opportunity_financing_needs")
  const handleChangeTab = useCallback((key) => setTabKey(key), [tabKey])

  return (
    <Fragment>
      <Tabs value={tabKey}
        activeLineMode='fixed'
        style={{ '--title-font-size': '12px', '--fixed-active-line-width': '20px' }}
        onChange={(value) => handleChangeTab(value)}
      >
        <Tabs.Tab title="融资需求" key="info_enterprise_trading_opportunity_financing_needs">
          <FinancingNeedsListView code={code} targetExtensionValue={targetExtensionValue} />
        </Tabs.Tab>
        <Tabs.Tab title="出售需求" key="info_enterprise_trading_opportunity_sale_requirement">
          <SaleRequirementListView code={code} targetExtensionValue={targetExtensionValue} />
        </Tabs.Tab>
        <Tabs.Tab title="并购整合需求" key="info_enterprise_trading_opportunity_merger_and_acquisition_needs">
          <MergerAndAcquisitionNeedsListView code={code} targetExtensionValue={targetExtensionValue} />
        </Tabs.Tab>
        <Tabs.Tab title="分拆/重组需求" key="info_enterprise_trading_opportunity_split_and_restructure_needs">
          <SplitAndRestructureNeedsListView code={code} targetExtensionValue={targetExtensionValue} />
        </Tabs.Tab>
        <Tabs.Tab title="投资需求" key="info_enterprise_trading_opportunity_investment_needs">
          <InvestmentNeedsListView code={code} targetExtensionValue={targetExtensionValue} />
        </Tabs.Tab>
        <Tabs.Tab title="募资需求" key="info_enterprise_trading_opportunity_capital_raising_requirement">
          <CapitalRaisingRequirementListView code={code} targetExtensionValue={targetExtensionValue} />
        </Tabs.Tab>
        <Tabs.Tab title="项目投退需求(公司)" key="info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_deal">
          <InvestmentExitNeeds4DealListView code={code} targetExtensionValue={targetExtensionValue} type="projectexit_com" />
        </Tabs.Tab>
        <Tabs.Tab title="基金投退需求(公司)" key="info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_fund">
          <InvestmentExitNeeds4FundListView code={code} targetExtensionValue={targetExtensionValue} type="fundexit_com" />
        </Tabs.Tab>
        <Tabs.Tab title="项目投退需求(股东/LP)" key="info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_deal">
          <InvestmentExitNeeds4DealListView code={code} targetExtensionValue={targetExtensionValue} type="projectexit_lp" />
        </Tabs.Tab>
        <Tabs.Tab title="基金投退需求(股东/LP)" key="info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_fund">
          <InvestmentExitNeeds4FundListView code={code} targetExtensionValue={targetExtensionValue} type="fundexit_lp" />
        </Tabs.Tab>
      </Tabs>
    </Fragment>
  )
}