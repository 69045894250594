import React, { Fragment, PureComponent } from "react";
import { Toast, Button, DotLoading, Space, Popup, TextArea, AutoCenter, Collapse, Popover } from 'antd-mobile'
import { AudioOutline, SoundOutline, DeleteOutline, ExclamationTriangleOutline, EditSOutline } from 'antd-mobile-icons'
import {
  checkJSAPI,
  startRecord,
  stopRecord,
  onVoiceRecordEnd,
  playVoice,
  stopVoice,
  translateVoice,
  uploadVoice,
} from '../../api/wecom'
import _ from "lodash";
import './index.css'
import moment from "moment";

/**
 * 语音识别
 */
export default class AudioSpeech extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      _disabled: props.disabled ? props.disabled : false,

      // 录制
      _recording: false,
      _complete: false,
      _voiceLocalIdArray: [],// 语音文件本地ID
      _voiceArray: _.get(props, 'value.audio', []), // 语音文件
      _recordingVisible: false,

      // 录制计时器
      _timerSecond: 1,//单位：秒

      // 语音转文字
      _translateVisible: false,
      _translateLoading: false,
      _translateId: null,
      _translateText: '',

      // 转文字
      _loading: false,
      _text: null,
      _categoryValues: [],

      // 上传
      _uploadVisible: false,
    }
    this.timer = null
  }

  componentDidMount() {
    // 尝试监听页面退出
    // window.addEventListener("beforeunload", () => Toast.show({ content: 'beforeunload', duration: 0 }));
    document.addEventListener('visibilitychange', () => {
      const { _recording, _complete } = this.state
      if (document.hidden) {
        // Toast.show({ content: 'visibilitychange hidden', duration: 0 });
        if (_recording === true && _complete === false) {
          Toast.show({ content: '语音录制中断', duration: 5 });
          this.handleClick('handle_complete_record_voice')
        }
      } else {
        // Toast.show({ content: 'visibilitychange show', duration: 0 });
      }
    })
    // window.addEventListener('pagehide', function (event) {
    //   Toast.show({ content: 'pagehide', duration: 0 });
    // })
    // window.addEventListener('pageshow', function (event) {
    //   Toast.show({ content: 'pageshow', duration: 0 });
    // })
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _disabled: props.disabled ? props.disabled : false,
      _voiceArray: _.get(props, 'value.audio', []), // 语音文件
    })
  }

  componentWillUnmount() {
    this.stopRecord()
    this.stopVoice()
  }

  handleClick(key, data, event) {
    console.log("handleClick ==>", key, data, event)
    const {
      _translateId,
      _translateText,
      _voiceArray
    } = this.state
    const {
      onConfirm,
    } = this.props

    switch (key) {
      case 'handle_open_record_voice_popup':
        this.setState({ _recording: true, _recordingVisible: true }, () => this.startRecord())
        break;
      case 'handle_complete_record_voice':
        this.setState({ _recording: false, _complete: true }, () => this.stopRecord())
        break;
      case 'handle_translate_record_voice_to_text':
        this.setState({ _translateLoading: true, _translateVisible: true }, () => this.translateVoice())
        break;
      case 'handle_open_translate_record_voice_popup':
        this.setState({ _translateVisible: true, _translateId: data['translateId'], _translateText: data['text'] })
        break;
      case 'handle_confirm_translate_record_voice_popup':
        let voiceArray = _.map(_voiceArray, item => ({ ...item, text: _translateId === item['translateId'] ? _translateText : item['text'] }))
        const voiceText = _.join(_.map(voiceArray, 'text'))

        this.setState({
          _voiceArray: voiceArray,
          _translateLoading: false,
          _translateVisible: false,
          _translateId: null,
          _translateText: '',
          _recordingVisible: false,
        })
        onConfirm && onConfirm({ audio: voiceArray, value: voiceText })
        break;
      case 'handle_delete_record_voice':
        this.setState({ _recording: false, _recordingVisible: false, _complete: false }, () => this.deleteRecord())
        break;
      case 'handle_delete_record_voice_item':
        if (data) {
          var array = []
          if (_voiceArray && _.size(_voiceArray) > 0) {
            _voiceArray.find(item => {
              if (item['url'] !== data['url']) {
                array.push(item)
              }
            })
          }
          this.setState({ _voiceArray: array })
        }
        break;
      default:
        break;
    }
  }

  handleRecord(key) {
    const {
      _timerSecond
    } = this.state

    switch (key) {
      case "startTimer":
        this.timer = setTimeout(() => {
          if (_timerSecond >= 59) {
            this.handleClick('handle_complete_record_voice')
            return
          }
          this.setState((prevState, props) => ({ ...prevState, _timerSecond: prevState['_timerSecond'] + 1 }), () => this.handleRecord('startTimer'))
          // this.setState({ _timerSecond: _timerSecond + 1 }, () => this.handleRecord('startTimer'))
        }, 1000)
        break
      case "stopTimer":
        clearTimeout(this.timer)
        this.timer = null
        break
      default:
        break;
    }
  }

  startRecord() {
    this.handleRecord('startTimer')
    startRecord()
      .catch(({ errMsg, errCode }) => {
        Toast.show({ content: `Error: ${errCode} ${errMsg}` })
        this.handleRecord('stopTimer')
      })
  }
  stopRecord() {
    this.handleRecord('stopTimer')
    stopRecord()
      .then((localId) => {
        // Toast.show({ content: `stopRecord localId: ${localId}` })
        let { _voiceLocalIdArray } = this.state
        _voiceLocalIdArray.push(localId)
        this.setState({ _voiceLocalIdArray: _voiceLocalIdArray })
      })
      .catch(({ errMsg, errCode }) => Toast.show({ content: `Error: ${errCode} ${errMsg}` }))
  }
  deleteRecord() {
    const { _voiceLocalIdArray } = this.state
    const lastLocalId = _.last(_voiceLocalIdArray)

    let array = []
    if (_voiceLocalIdArray && _.size(_voiceLocalIdArray) > 0) {
      _voiceLocalIdArray.find(item => {
        if (item !== lastLocalId) {
          array.push(item)
        }
      })
    }
    this.setState({ _voiceLocalIdArray: array })
  }
  playVoice() {
    const { _voiceLocalIdArray } = this.state
    const lastLocalId = _.last(_voiceLocalIdArray)

    playVoice(lastLocalId).then(() => Toast.show({ content: '播放语音' }))
  }
  stopVoice() {
    const { _voiceLocalIdArray } = this.state
    const lastLocalId = _.last(_voiceLocalIdArray)

    stopVoice(lastLocalId)
    // stopVoice(lastLocalId).then(() => Toast.show({ content: '停止播放语音' }))
  }
  translateVoice() {
    const { _voiceLocalIdArray } = this.state
    const lastLocalId = _.last(_voiceLocalIdArray)

    Toast.show({ content: '语音转换中...', icon: 'loading', maskClickable: false, duration: 0 })
    translateVoice(lastLocalId)
      .then(({ translateResult }) => {
        this.setState({ _translateLoading: false, _translateId: lastLocalId, _translateText: translateResult }, () => this.uploadVoice())
      })
      .catch(({ errMsg, errCode }) => {
        // Toast.show({ content: `Error: ${errCode} ${errMsg}` })
        this.setState({ _translateLoading: false, _translateId: lastLocalId, _translateText: '' }, () => this.uploadVoice())
      })
  }
  uploadVoice() {
    const {
      _translateId,
      _translateText
    } = this.state

    Toast.show({ content: '上传语音中...', icon: 'loading', maskClickable: false, duration: 0 })
    uploadVoice(_translateId)
      .then((data) => {
        Toast.show({ content: `上传语音成功` })
        let { _voiceArray } = this.state
        _voiceArray.push({ ...data, translateId: _translateId, text: _translateText })
        this.setState({ _voiceArray: _voiceArray })
      })
      .catch(({ errMsg }) => Toast.show({ content: `上传错误：${errMsg}` }))
  }

  render() {
    const {
      _disabled,
      _recording,
      _recordingVisible,
      _translateVisible,
      _translateLoading,
      _translateText,
      _voiceArray,
      _timerSecond,
    } = this.state

    return (
      <div className="cec-voice-speech-box">
        {/** 语音录制 Button */}
        <AudioSpeechButton disabled={_disabled || _recording || _recordingVisible} onClick={this.handleClick.bind(this, 'handle_open_record_voice_popup')} />
        <div style={{ paddingTop: '10px', color: '#999', fontStyle: 'italic' }}>注：支持多条语音录入，每条语音最长60秒</div>

        {/** 语音列表 */}
        <AudioSpeechList datas={_voiceArray}
          onDelete={this.handleClick.bind(this, 'handle_delete_record_voice_item')}
          onEdit={this.handleClick.bind(this, 'handle_open_translate_record_voice_popup')}
        />

        {/** 语音录制 Popup */}
        <Popup placement="bottom"
          className="cec-voice-speech-record-popup"
          bodyClassName="cec-voice-speech-record-popup-body"
          getContainer={() => document.querySelector('.cec-voice-speech-box')}
          bodyStyle={{ height: '60vh' }}
          visible={_recordingVisible}
          closeOnMaskClick={false}
          onClose={() => this.setState({ _recordingVisible: false })}>
          <Fragment>
            {
              _recording ? (
                <div className="cec-voice-speech-record-popup-content">
                  <div className="cec-voice-speech-record-popup-content-body">
                    <div style={{ color: '#1890FF', fontSize: 20 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="loading"><LoadingOutline /></div>
                        <div style={{ marginLeft: 5 }}>
                          <AutoCenter>录制中</AutoCenter>
                        </div>
                      </div>
                      <AutoCenter>录音计时：{_timerSecond}秒</AutoCenter>
                    </div>
                  </div>
                  <div className="cec-voice-speech-record-popup-content-foot">
                    <Button block color="danger" onClick={this.handleClick.bind(this, 'handle_complete_record_voice')}>完成录制</Button>
                  </div>
                </div>
              ) : (
                <div className="cec-voice-speech-record-popup-content">
                  <div className="cec-voice-speech-record-popup-content-body">
                    <div style={{ fontSize: '32px', color: '#1890FF', textAlign: 'center' }} onClick={this.playVoice.bind(this)}>
                      <div><SoundOutline /></div>
                      <div style={{ color: '#1890FF', fontSize: 14 }}>点击播放</div>
                    </div>
                  </div>
                  <div className="cec-voice-speech-record-popup-content-foot">
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <Button block color="danger" disabled={false} style={{ marginRight: 5 }} onClick={this.handleClick.bind(this, 'handle_delete_record_voice')}>删除并返回</Button>
                      <Button block color="primary" disabled={false} onClick={this.handleClick.bind(this, 'handle_translate_record_voice_to_text')}>转写成文字</Button>
                    </div>
                  </div>
                </div>
              )
            }
          </Fragment>
        </Popup>

        {/** 语音转文字 */}
        <Popup placement="bottom"
          className="cec-voice-speech-translate-popup"
          bodyClassName="cec-voice-speech-translate-popup-body"
          getContainer={() => document.querySelector('.cec-voice-speech-box')}
          bodyStyle={{ height: '60vh' }}
          visible={_translateVisible}
          closeOnMaskClick={false}>
          {
            _translateLoading ? (
              <AutoCenter>正在转写成文字<DotLoading /></AutoCenter>
            ) : (
              <div className="cec-voice-speech-translate-popup-content">
                <div className="cec-voice-speech-translate-popup-content-body">
                  <div style={{ width: '100%', height: `calc(100% - 20px)`, padding: 10 }}>
                    <div style={{ padding: 5 }}>可对转写的文字进行编辑后保存</div>
                    <TextArea value={_translateText}
                      rows={10}
                      className="translate-content"
                      onChange={(value) => this.setState({ _translateText: value })}
                    />
                  </div>
                </div>
                <div className="cec-voice-speech-translate-popup-content-foot">
                  <Button block color="primary" disabled={false} onClick={this.handleClick.bind(this, 'handle_confirm_translate_record_voice_popup')}>确认</Button>
                </div>
              </div>
            )
          }
        </Popup>
      </div>
    )
  }
}

// 语音录制Button
export function AudioSpeechButton({ disabled, onClick }) {
  return (
    <div>
      <div style={{ border: '2px dashed #ddd' }} onClick={disabled ? null : () => onClick && onClick()}>
        <div style={{ padding: 10, textAlign: 'center' }}>
          <AudioOutline style={disabled ? { color: '#ccc', fontSize: 32 } : { color: '#1890FF', fontSize: 32 }} />
        </div>
        <div style={disabled ? { color: '#ccc', textAlign: 'center' } : { color: '#1890FF', textAlign: 'center' }}>语音录入（点击开始）</div>
      </div>
    </div>
  )
}

// 语音列表Item
export function AudioSpeechList({ datas, onDelete, onEdit }) {
  return (
    <Fragment>
      {
        datas && _.size(datas) > 0 && (
          <Collapse className="cec-voice-speech-list" activeKey={_.map(datas, "name")}>
            {
              datas.map(item => (
                <Collapse.Panel key={item['name']} style={{ paddingLeft: 0 }}
                  title={
                    <Space block style={{ paddingTop: 12, fontSize: 12 }}>
                      <div>创建时间：{item['createTime'] ? moment(item['createTime']).format("MM-DD HH:mm") : null}</div>
                      <div>创建人：{item['createUser']}</div>
                    </Space>
                  }>
                  <div className="cec-voice-speech-item-body">
                    <Space block align="center">
                      <div style={{ flex: 1 }}>
                        <audio controls>
                          <source src={item['url']} type="audio/mpeg" />
                        </audio>
                      </div>
                      <div style={{ flex: 'auto' }}>
                        <Popover trigger="click"
                          content={
                            <div>
                              <div style={{ padding: 4 }}>
                                <AutoCenter><Space><ExclamationTriangleOutline style={{ color: '#ff8f1f', fontSize: 20 }} /><span>确定删除当前语音？</span></Space></AutoCenter>
                              </div>
                              <Space block justify="around">
                                <Button block size="mini" color="primary" onClick={() => onDelete && onDelete(item)}>确定</Button>
                                <Button block size="mini">取消</Button>
                              </Space>
                            </div>
                          }>
                          <Button size="mini" color="danger" shape='rounded'><DeleteOutline /></Button>
                        </Popover>
                      </div>
                    </Space>
                    <div>
                      <div>
                        <span>听写文本</span>
                        <span style={{ padding: '0 10px', fontSize: 14, color: '#333' }} onClick={() => onEdit && onEdit(item)}><EditSOutline /></span>
                      </div>
                      <div>{item['text']}</div>
                    </div>
                  </div>
                </Collapse.Panel>
              ))
            }
          </Collapse>
        )
      }
    </Fragment>
  )
}

// 加载Loading
function LoadingOutline({ size = 50 }) {
  return (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size}><path d="M441.376 139.36a70.624 70.624 0 1 0 141.216 0 70.624 70.624 0 1 0-141.248 0zM167.488 256A68.576 68.576 0 0 0 304.64 256a68.576 68.576 0 0 0-137.152 0z m-104.384 257.888a62.432 62.432 0 0 0 124.864 0 62.432 62.432 0 0 0-124.864 0z m110.528 281.408a54.24 54.24 0 0 0 108.48 0 54.24 54.24 0 0 0-108.48 0z m293.344 117.536a47.2 47.2 0 0 0 94.4 0 47.2 47.2 0 0 0-94.4 0z m303.904-108.288a33.824 33.824 0 1 0 67.648 0 33.824 33.824 0 1 0-67.648 0z m140.704-291.936a23.968 23.968 0 1 0 47.936 0 23.968 23.968 0 1 0-47.936 0z m-72-251.424a17.12 17.12 0 1 0 34.24 0 17.12 17.12 0 1 0-34.24 0z" fill="#1890FF"></path></svg>
  )
}