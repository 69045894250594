import _ from "lodash";
import request from "../../../../../../../api/HttpRequest";


// 是否有实际控制人
export const IS_ACTUAL_CONTROLLER_OPTIONS_VALUE_TRUE = "true"
export const IS_ACTUAL_CONTROLLER_OPTIONS = [
  { label: '是', value: IS_ACTUAL_CONTROLLER_OPTIONS_VALUE_TRUE },
  { label: '否', value: 'false' },
]

// 创始人/实际控制人在过去1年内是否发生变化
export const IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS_VALUE_TRUE = "true"
export const IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS = [
  { label: '是', value: IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS_VALUE_TRUE },
  { label: '否', value: 'false' },
]

// 创始人/股东在过去1年内是否发生变化
export const IS_SHAREHOLDER_CHANGE_OPTIONS_VALUE_TRUE = "true"
export const IS_SHAREHOLDER_CHANGE_OPTIONS = [
  { label: '是', value: IS_SHAREHOLDER_CHANGE_OPTIONS_VALUE_TRUE },
  { label: '否', value: 'false' },
]

// 是否有明确控股机会
export const IS_SHAREHOLDING_OPTIONS = [
  { label: '是', value: '是' },
  { label: '否', value: '否' },
]

/**
 * 获取股权结构列表
 */
export async function FetchOwnershipStructureDataList({ code, pageNum = 1, pageSize = 2 }) {

  const payload = {
    base_comp_code: code,
    pageNum: pageNum <= 0 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/ownership/list", payload)
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          id: item['id'],
          code: item['base_comp_code'],
          isActualController: item['ownerflag'] !== undefined ? _.join(_.map(_.filter(IS_ACTUAL_CONTROLLER_OPTIONS, i => i['value'] === item['ownerflag'].toString()), "label")) : null,
          isActualControllerText: item['ownernote'],
          isActualControllerChange: item['ownerchangeflag'] !== undefined ? _.join(_.map(_.filter(IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS, i => i['value'] === item['ownerchangeflag'].toString()), "label")) : null,
          isShareholderChange: item['holderchangeflag'] !== undefined ? _.join(_.map(_.filter(IS_SHAREHOLDER_CHANGE_OPTIONS, i => i['value'] === item['holderchangeflag'].toString()), "label")) : null,
          isActualControllerChangeRatio: item['owner_rate'],
          isShareholding: item['controlflag'] !== undefined ? _.join(_.map(_.filter(IS_SHAREHOLDER_CHANGE_OPTIONS, i => i['value'] === item['controlflag'].toString()), "label")) : null,
          manageShareholding: item['management_rate'],
          otherShareholding: item['investment_all_rate'],
          top3OtherShareholding: item['investment_top3_rate'],
          maxOtherShareholding: item['investment_top1_rate'],
          modifyDate: item['modifyDate'],
          modidyUser: item['updateusername'],
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 获取股权结构详情
 * @param {*} id 
 */
export async function FetchOwnershipStructureDataDetail(id) {
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/ownership/detail", { id: id })
    console.log("FetchFinancialDataDetail >>>>", response)

  } catch (error) {
    throw error
  }
}