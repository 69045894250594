import React from "react";

/**
 * 必填项
 * @returns 
 */
export default function Required() {

  return (
    <span style={{ color: 'red', marginLeft: 5 }}>*</span>
  )

}