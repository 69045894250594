import React, { Fragment, useEffect, useImperativeHandle, useCallback, useRef, useState } from "react"
import _ from "lodash"
import { List, Skeleton, ErrorBlock, Toast, Space } from "antd-mobile"
import { EditSFill } from "antd-mobile-icons"
import CECInfiniteScroll from "../../../../../../../../components/InfiniteScroll"
import CECPopup from "../../../../../../../../components/Popup"
import CapitalRaisingRequirementPopup from "../../../../../../UpdateEntry/components/TradingOpportunity/CapitalRaisingRequirementPopup"
import './index.less';
import {
  FetchCapitalRaisingRequirementDataList,
  FetchCapitalRaisingRequirementDataDetail,
} from './transform'

/**
 * 交易机会 - 募资需求列表
 * @returns 
 */
export default function CapitalRaisingRequirementListView({ code, targetExtensionValue }) {

  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [item, setItem] = useState({})
  const dataDetailPopupRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      await fetchDatas(code, 1);
    };
    code && fetchData();
  }, [code]);

  // 获取列表
  const fetchDatas = async (code, currentPageNum) => {
    setLoading(true)
    try {
      const { datas: newDatas, hasMore, total } = await FetchCapitalRaisingRequirementDataList({ code, pageNum: currentPageNum, pageSize })
      setLoading(false);
      setHasMore(hasMore);
      setPageNum(currentPageNum + 1);
      if (currentPageNum === 1) {
        setDatas(newDatas)
      } else {
        setDatas(prevDatas => [...prevDatas, ...newDatas]);
      }
    } catch (error) {
      Toast.show({ content: error })
      setLoading(false)
      setHasMore(false)
    }
  }

  const handleLoadMore = useCallback(async () => {
    code && await fetchDatas(code, pageNum);
  }, [code, pageNum])

  const handleClick = (key, data, event) => {
    console.log("handleClick >>>>", key, data, event)

    switch (key) {
      case 'handle_open_item_detail_popup':
        setItem(data)
        dataDetailPopupRef?.current?.show()
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <List>
        {
          loading && _.size(datas) == 0 && (
            <Fragment>
              <Skeleton.Title animated />
              <Skeleton.Paragraph lineCount={4} animated />
            </Fragment>
          )
        }
        {
          !loading && _.size(datas) == 0 && (
            <Fragment>
              <ErrorBlock status='empty' title="" description="暂无数据" />
            </Fragment>
          )
        }
        {
          datas.map(item => (
            <List.Item onClick={() => handleClick('handle_open_item_detail_popup', item)}>
              <div className="financing-history-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span className="financing-history-item-key">更新时间/更新人：</span>{item['modifyDate']}
                  </div>
                  <div style={{ margin: '0 5px' }}>/</div>
                  <div>{item['modidyUser']}</div>
                </div>
              </div>
            </List.Item>
          ))
        }
        <CECInfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} />
      </List>

      {/** 详情Popup */}
      <CapitalRaisingRequirementDetailPopup ref={dataDetailPopupRef} item={item} targetExtensionValue={targetExtensionValue} />
    </Fragment>
  )
}



/**
 * 交易机会 - 募资需求数据详情
 */
export const CapitalRaisingRequirementDetailPopup = React.forwardRef(({ item, targetExtensionValue }, ref) => {

  const [info, setInfo] = useState({})
  const basePopupRef = useRef(null)
  const editDataPopupRef = useRef(null)

  useImperativeHandle(ref, () => ({
    show: () => {
      basePopupRef?.current?.show()
    },
    close: () => {
      basePopupRef?.current?.close()
    },
    toggle: () => {
      basePopupRef?.current?.toggle()
    },
  }))

  useEffect(() => {
    const fetchData = async () => {
      await fetchDetail(item['id'])
    }
    item && fetchData()
  }, [item])

  const fetchDetail = async (id) => {
    Toast.show({ icon: 'loading' })
    try {
      const response = await FetchCapitalRaisingRequirementDataDetail(id)
      setInfo(response)
      Toast.clear()
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  const renderBodyContent = () => {
    return (
      <Fragment>
        {
          info && (
            <div className="merger-and-acquisition-needs-detail-box">

            </div>
          )
        }

        {/** 编辑页面Popup */}
        <CapitalRaisingRequirementPopup ref={editDataPopupRef}
          metadata={{
            title: '编辑是否有募资需求需求',
            targetRefKey: "info_option_list_attend_target",
          }}
          value={{
            value: { ...info }
          }}
          extensionValue={{ "info_option_list_attend_target": targetExtensionValue }}
        />
      </Fragment>
    )
  }

  return (
    <CECPopup ref={basePopupRef}
      overflow={false}
      navTitle="募资需求详情"
      navRight={<div onClick={() => editDataPopupRef?.current?.show()}><EditSFill style={{ color: '#1890FF', fontSize: 18 }} /></div>}
      destroyOnClose={false}
      onNavBack={() => ref?.current?.close()}>
      {
        renderBodyContent()
      }
    </CECPopup>
  )
})