import React, { Fragment, createRef } from "react";
import _ from "lodash";
import { List, Button, TextArea, Toast } from 'antd-mobile'
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import {
  SaveInfo,
  DeleteInfo
} from './transform'

const OPTION_YES_VALUE = 'YES', OPTION_NO_VALUE = 'NO', OPTION_UNSURE_VALUE = 'UNSURE'
const OPTIONS = [
  { label: '是', value: OPTION_YES_VALUE },
  { label: '否', value: OPTION_NO_VALUE },
  { label: '不确定', value: OPTION_UNSURE_VALUE },
]

/**
 * 策略及业务方向更新
 */
export default class BussinessStrategyPopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props);

    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })

    this.state = {
      _currentKey: null,
      _info: info,
    }
    this.pickerPopupRef = createRef();
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log('handleClick =>', key, data, event)
    let {
      _currentKey,
      _info,
    } = this.state

    switch (key) {
      case 'handle_open_picker':
      case 'handle_close_picker':
        this.setState({ _currentKey: data })
        this.pickerPopupRef?.current?.toggle()
        break;
      case 'handle_confirm_picker':
        this.setState({
          _info: _.assign({}, _info, {
            [_currentKey]: event
          })
        })
        this.handleClick('handle_close_picker')
        break;
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  renderBodyContent() {
    const {
      _info
    } = this.state
    console.log("_info", _info)

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="是否有新战略方向"
            options={OPTIONS}
            value={_info['newStrategicFocus'] ? _.map(_info['newStrategicFocus'], 'value') : null}
            onChange={(val, options) => {
              this.setState({ _currentKey: "newStrategicFocus" }, () => this.handleClick('handle_confirm_picker', val, options))
            }}
          />
          {
            _info['newStrategicFocus'] && _.map(_info['newStrategicFocus'], 'value').includes(OPTION_YES_VALUE) && (
              <TextArea style={{ padding: 10 }} placeholder="请填写新战略方向描述" value={_info['newStrategicFocusText']} onChange={(value) => this.setState({ _info: { ..._info, 'newStrategicFocusText': value } })} />
            )
          }
          <CECSelectorListItem label="是否有新业务"
            options={OPTIONS}
            value={_info['newBussiness'] ? _.map(_info['newBussiness'], 'value') : null}
            onChange={(val, options) => {
              this.setState({ _currentKey: "newBussiness" }, () => this.handleClick('handle_confirm_picker', val, options))
            }}
          />
          {
            _info['newBussiness'] && _.map(_info['newBussiness'], 'value').includes(OPTION_YES_VALUE) && (
              <TextArea style={{ padding: 10 }} placeholder="请填写新业务描述" value={_info['newBussinessText']} onChange={(value) => this.setState({ _info: { ..._info, 'newBussinessText': value } })} />
            )
          }
          <CECSelectorListItem label="是否有产业链整合计划"
            options={OPTIONS}
            value={_info['industryChainIntegrationPlan'] ? _.map(_info['industryChainIntegrationPlan'], 'value') : null}
            onChange={(val, options) => {
              this.setState({ _currentKey: "industryChainIntegrationPlan" }, () => this.handleClick('handle_confirm_picker', val, options))
            }}
          />
          {
            _info['industryChainIntegrationPlan'] && _.map(_info['industryChainIntegrationPlan'], 'value').includes(OPTION_YES_VALUE) && (
              <TextArea style={{ padding: 10 }} placeholder="请填写产业链整合计划描述" value={_info['industryChainIntegrationPlanText']} onChange={(value) => this.setState({ _info: { ..._info, 'industryChainIntegrationPlanText': value } })} />
            )
          }
          <CECSelectorListItem label="是否有出海计划"
            options={OPTIONS}
            value={_info['overseasExpansionPlan'] ? _.map(_info['overseasExpansionPlan'], 'value') : null}
            onChange={(val, options) => {
              this.setState({ _currentKey: "overseasExpansionPlan" }, () => this.handleClick('handle_confirm_picker', val, options))
            }}
          />
          {
            _info['overseasExpansionPlan'] && _.map(_info['overseasExpansionPlan'], 'value').includes(OPTION_YES_VALUE) && (
              <TextArea style={{ padding: 10 }} placeholder="请填写出海计划描述" value={_info['overseasExpansionPlanText']} onChange={(value) => this.setState({ _info: { ..._info, 'overseasExpansionPlanText': value } })} />
            )
          }
        </List>
      </Fragment>
    )
  }

  renderFootContent() {
    const {
      _info,
    } = this.state
    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}