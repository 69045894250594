import _ from "lodash"
import request from "../../../../api/HttpRequest"

/**
 * 保存会议记录信息
 *
 * @param {*} data 
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
export async function SaveMemoInfo(data, successCallback, errorCallback) {

  const newData = formatObject(data)
  let targetRelationArray = []

  _.keys(newData).find(key => {
    if (key.indexOf('memo_option_list_attend_target') > -1) {
      const keys = _.split(key, '_')

      // 组装对方参会人和关键信息更新Key
      let targetUserKey = 'memo_option_list_attend_target_user',
        updateContentKey = 'memo_option_list_update_content';
      if (_.size(keys) > 5) {
        targetUserKey = `${targetUserKey}_${keys[1]}_0`
        updateContentKey = `${updateContentKey}_${keys[1]}_1`
      }

      // 查找对象Value
      let targets = _.get(newData, `${key}.targets`),
        targetTypes = _.get(newData, `${key}.targetTypes`)

      if (targets && _.size(targets) > 0) {
        let targetRelation = {
          code_com: _.join(_.map(targets, "value")),
          name_com: _.join(_.map(targets, "label")),
          type_com: _.join(_.map(targets, "category")),//公司&机构&LP
          icon_com: _.join(_.map(targets, "avatar")),
          label_com: _.join(_.map(targets, "label")),
          data_type: _.join(_.map(targetTypes, "value")),
          data_type_label: _.join(_.map(targetTypes, "label")),
          data_com: newData[updateContentKey],
        }
        targetRelationArray.push(targetRelation)
      }
    }
  })

  await request('POST',
    '/cec-sims-server/smartx/meeting/save',
    {
      code_meeting: null,
      data_meeting: JSON.stringify(newData),
      data_array_com: JSON.stringify(targetRelationArray),
    }
  )
    .then(() => successCallback && successCallback())
    .catch(err => errorCallback && errorCallback(err))
}

function formatObject(data) {
  if (data) {
    _.map(_.keys(data), key => {
      if (key.indexOf('memo_option_list_update_content') > -1) {
        if (data[key]) {
          data[key] = _.map(data[key], item => {
            delete item.metadata?.ref
            delete item.metadata?.componentRender
            if (item.metadata?.parentPathItems) {
              item.metadata.parentPathItems = _.map(item.metadata.parentPathItems, path => ({ label: path['label'], value: path['value'], key: path['value'] }))
            }
            return { ...item }
          })
        }
      } else if (
        key.indexOf('memo_option_list_base_info') < 0 &&
        key.indexOf('memo_option_list_attend_target') < 0 &&
        key.indexOf('memo_option_list_attend_target_user') < 0 &&
        key.indexOf('memo_option_list_update_content') < 0 &&
        key.indexOf('memo_option_list_attend_ceccapital_user') < 0 &&
        key.indexOf('memo_option_list_memo_content') < 0
      ) {
        delete data[key]
      }
    })
  }
  return data
}