import _ from "lodash";
import moment from "moment";
import request from "../../../../../../../../api/HttpRequest";
import { parseAmountUnitNumber } from "../../../../../../../../components/AmountNumber";


// 融资范围
export const RANGE_OPTIONS = [
  { value: 'ALL', label: '公司整体融资' },
  { value: 'PART', label: '业务板块融资' }
]
// 是否有老股退出需求
export const OLD_STOCK_OUT_OPTIONS_TRUE = "true"
export const OLD_STOCK_OUT_OPTIONS = [
  { value: 'true', label: '是' },
  { value: 'false', label: '否' }
]
// FA
export const FA_SUPPORT_OPTIONS_TRUE = "true"
export const FA_SUPPORT_OPTIONS = [
  { value: FA_SUPPORT_OPTIONS_TRUE, label: '是' },
  { value: 'false', label: '否' }
]
// 其他需求
export const OTHER_RELATION_OPTIONS_TRUE = "true"
export const OTHER_RELATION_OPTIONS = [
  { value: 'true', label: '是' },
  { value: 'false', label: '否' }
]
// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]


/**
 * 获取交易机会 - 融资需求列表
 */
export async function FetchFinancingNeedsDataList({ code, pageNum = 1, pageSize = 2 }) {

  const payload = {
    base_comp_code: code,
    types: 'trade',
    pageNum: pageNum <= 0 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/trade/chance/list", payload)
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        const oldStockUnit = item['oldstockunit'] ? _.filter(AMOUNT_UNIT_OPTIONS, { value: item['oldstockunit'].toString() }) : null
        const nowStockUnit = item['newstockunit'] ? _.filter(AMOUNT_UNIT_OPTIONS, { value: item['newstockunit'].toString() }) : null

        let industryList = []
        if (item['label_list'] && _.size(item['label_list']) > 0) {
          industryList = _.map(item['label_list'], it => {

            const children = _.map(it['child'], subIt => ({
              value: subIt['sys_labelelement_code'],
              label: subIt['sys_labelelement_name'],
              type: subIt['sys_labelelement_type'],
            }))

            return {
              value: it['sys_labelelement_code'],
              label: it['sys_labelelement_name'],
              type: it['sys_labelelement_type'],
              children: children,
            }
          })
        }

        let faList = []
        if (item['investment_datas'] && _.size(item['investment_datas']) > 0) {
          faList = _.map(item['investment_datas'], it => ({ value: it['inv_code'], label: it['inv_name'] }))
        }
        return {
          id: item['base_trade_code'],
          code: item['base_comp_code'],
          type: item['type'],
          range: item['part_all'] ? _.filter(RANGE_OPTIONS, { value: item['part_all'] }) : null,
          industryList: industryList,
          scale: item['tradevalue2'],
          scaleMoney: item['tradevalue'],
          oldStockOut: item['oldstockexitflag'] ? _.filter(OLD_STOCK_OUT_OPTIONS, { value: item['oldstockexitflag'] }) : null,
          oldStockCurrency: item['oldstockcurrency'] ? _.filter(CURRENCY_OPTIONS, { value: item['oldstockcurrency'] }) : null,
          oldStockUnit: oldStockUnit,
          oldStockStart: parseAmountUnitNumber(item['oldstockvalue1'], oldStockUnit),
          oldStockEnd: parseAmountUnitNumber(item['oldstockvalue2'], oldStockUnit),
          nowStockCurrency: item['newstockcurrency'] ? _.filter(CURRENCY_OPTIONS, { value: item['newstockcurrency'] }) : null,
          nowStockUnit: nowStockUnit,
          nowStockStart: parseAmountUnitNumber(item['newstockvalue1'], nowStockUnit),
          nowStockEnd: parseAmountUnitNumber(item['newstockvalue1'], nowStockUnit),
          beginDate: item['tradedate'] ? moment(item['tradedate']).toDate() : null,
          FA: item['faflag'] ? _.filter(FA_SUPPORT_OPTIONS, { value: item['faflag'] }) : null,
          faList: faList,
          other: item['note'] ? _.filter(OTHER_RELATION_OPTIONS, { value: OTHER_RELATION_OPTIONS_TRUE }) : null,
          otherText: item['note'],
          modifyDate: item['modifyDate'],
          modidyUser: item['updateusername'],
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 获取交易机会 - 融资需求详情
 * @param {*} id 
 */
export async function FetchFinancingNeedsDataDetail(id) {
  if (!id) {
    return
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/trade/chance/detail", { id: id })
    console.log("FetchFinancingNeedsDataDetail >>>>", response)

  } catch (error) {
    throw error
  }
}