import React, { PureComponent, createRef } from "react";
import { Cascader } from "antd-mobile";
import _ from "lodash";

/**
 * 多层级联选择器
 */
export default class MultipleCascaderPopup extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _selectedValues: props.value && props.value.values ? props.value.values : [],
      _selectedItems: props.value && props.value.items ? props.value.items : [],
    }
    this.multipleCascaderPopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _selectedValues: props.value && props.value.values ? props.value.values : [],
      _selectedItems: props.value && props.value.items ? props.value.items : [],
    })
  }

  show() {
    this.multipleCascaderPopupRef.current.open()
  }
  close() {
    this.multipleCascaderPopupRef.current.close()
  }
  toggle() {
    this.multipleCascaderPopupRef.current.toggle()
  }

  handleChange(key, data, event) {
    // console.log("handleChange >>>>", key, data, event)
    const {
      sourcedata,
      onConfirm
    } = this.props
    const {
      options,
    } = sourcedata

    switch (key) {
      case 'change_cascader_value':
        const path = findPath(options, data)
        var selectedCompleted = false
        if (path) {
          const lastItem = _.last(path)
          selectedCompleted = lastItem && !lastItem['children'] ? true : false
        }

        this.setState({ _selectedValues: data, _selectedItems: path }, () => {
          if (selectedCompleted === true) {
            this.multipleCascaderPopupRef.current.close()
            onConfirm && onConfirm({ value: data, path: path })
          }
        })
        break;
      default:
        break;
    }
  }

  render() {
    const {
      metadata,
      sourcedata,
      onConfirm,
    } = this.props
    const {
      options,
    } = sourcedata
    const {
      _selectedValues,
      _selectedItems,
    } = this.state
    // console.log('_selectedValues >>>>', _selectedValues, _selectedItems)

    const content = (
      <Cascader ref={this.multipleCascaderPopupRef}
        title={metadata['title']}
        loading={false}
        value={_selectedValues}
        options={options ? options : []}
        // children={(items, actions) => console.log("children ....", items, actions)}
        // onCancel={() => console.log("cancel")}
        // onClose={() => console.log("close")}
        // onConfirm={(value, extend) => onConfirm && onConfirm({ value: value, path: _selectedItems })}
        onConfirm={this.handleChange.bind(this, 'change_cascader_value')}
        onSelect={this.handleChange.bind(this, 'change_cascader_value')}
      />
    )

    return content
  }

}

/**
 * 查找路径
 * @param {*} array 
 * @param {*} values 
 * @returns 
 */
function findPath(array, values) {
  const path = [];

  const search = (nodes, valueIndex) => {
    if (valueIndex >= values.length) return true;

    for (const node of nodes) {
      path.push({ ...node });

      if (node['value'] === values[valueIndex]) {
        if (valueIndex === values.length - 1 || (node['children'] && search(node['children'], valueIndex + 1))) {
          return true;
        }
      }

      path.pop();
    }

    return false;
  }

  const found = search(array, 0);
  return found ? path : null;
}