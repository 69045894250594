import React, { PureComponent } from "react";
import { SendOutline, MoreOutline, } from "antd-mobile-icons";
import _ from 'lodash'
import './index.css'

/**
 * 树结构
 */
export default class Tree extends PureComponent {
  render() {
    const {
      placeholder,
      metadata,
      sourcedata,
      values,
      datas,
      onSelect,
      onConfirm,
      onClick,
    } = this.props

    return (
      <div className="tree-box" style={{ padding: '0 10px' }}>
        {
          datas && _.size(datas) > 0 ? datas.map((node) => (
            <TreeNode key={node['key']} node={node} parentPath={[]} values={values} exsitData={_.get(_.head(_.filter(values, item => item.metadata.key === node['key'])), "metadata")} onSelect={(data) => onSelect && onSelect({ metadata, data })} onConfirm={onConfirm} onClick={onClick} />
          )) : <span style={{ color: '#999' }}>{placeholder}</span>
        }
      </div>
    )
  }
}


/**
 * 树节点
 */
export class TreeNode extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _isOpen: _.get(props['node'], 'open', false)
    }

    this.handleToggle = this.handleToggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _isOpen: _.get(props['node'], 'open', false)
    })
  }

  handleToggle() {
    let {
      _isOpen
    } = this.state
    const {
      node,
    } = this.props
    this.setState({
      _isOpen: !_isOpen,
    }, () => {
      node['open'] = !_isOpen
    })
  }

  handleSelect(data, event) {
    const { parentPath, onSelect } = this.props;
    const currentPaths = [...parentPath, data];

    if (!data['children'] || _.size(data['children']) === 0) {
      const currentPathValues = _.map(currentPaths, "key")
      onSelect({ path: currentPaths, value: currentPathValues });
    }
  }

  render() {
    let {
      node,
      values,
      exsitData,
      parentPath,
      onSelect,
      onClick,
    } = this.props
    const {
      _isOpen
    } = this.state

    let exsitDataValue = null
    if (node['parentPathValues'] && _.size(node['parentPathValues']) > 0) {
      const exsitNode = _.head(_.filter(values, item => item.metadata.key === node['key']))
      exsitData = _.get(exsitNode, "metadata")
      exsitDataValue = _.get(exsitNode, "key")
    }

    const handleNodeClick = node['children'] ? (event) => this.handleToggle(node, event) : (event) => this.handleSelect(node, event);
    return (
      <div className="leafs" style={parentPath && _.size(parentPath) > 0 ? { width: 'calc(100% - 10px)', padding: '0 10px' } : { width: '100%' }}>
        <div className="leaf-item" style={{ display: 'flex', alignItems: 'center', padding: '8px 0', color: '#1890FF', fontSize: 12 }}>
          {
            node['children'] && (
              <div style={{ marginRight: 2 }}>{_isOpen ? <DownFill /> : <RightFill />}</div>
            )
          }
          <div className="leaf-node" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div className="leaf-item-left" style={{ flex: 'auto', color: node['disabled'] ? "#999" : null }} onClick={node['disabled'] ? null : handleNodeClick}>{node['label']}{node['children'] ? null : <SendOutline style={{ marginLeft: 5 }} />}</div>
            {
              exsitData && exsitData['key'] === node['key'] && (
                <div className="leaf-item-right" style={{ fontSize: '12px', color: '#1890FF' }} onClick={() => onClick && onClick('handle_open_edit_popup', exsitData, exsitDataValue)}>编辑数据<MoreOutline style={{ marginLeft: 5 }} /></div>
              )
            }
          </div>
        </div>
        {
          _isOpen && node['children'] && (
            <div>
              {
                node['children'].map((childNode) => (
                  <TreeNode key={childNode['key']}
                    node={childNode}
                    values={values}
                    exsitData={_.get(_.head(_.filter(values, item => item.metadata.key === node['key'])), "metadata")}
                    parentPath={[...parentPath, node]}
                    onSelect={onSelect}
                    onClick={onClick}
                  />
                ))
              }
            </div>
          )
        }
      </div>
    )
  }
}

function DownFill({ color = '#1890FF', size = 12 }) {
  return (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size}><path d="M512.146286 619.52L245.296762 352.792381 193.584762 404.48l318.585905 318.415238 318.268952-318.415238-51.736381-51.687619z" fill={color}></path></svg>
  )
}

function UpFill({ color = '#1890FF', size = 12 }) {
  return (
    <svg viewBox="0 0 1394 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size}><path d="M808.676766 55.862468l557.622468 743.685447c46.363234 61.83217 34.03166 149.700085-27.539064 196.259404A139.176851 139.176851 0 0 1 1254.813957 1024H139.569021C62.485787 1024 0 961.252766 0 883.842723a140.52766 140.52766 0 0 1 28.061957-84.316595L585.684426 55.884255a139.176851 139.176851 0 0 1 222.99234 0z" fill={color}></path></svg>
  )
}

function RightFill({ color = '#1890FF', size = 12 }) {
  return (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size}><path d="M761.056 532.128c0.512-0.992 1.344-1.824 1.792-2.848 8.8-18.304 5.92-40.704-9.664-55.424L399.936 139.744c-19.264-18.208-49.632-17.344-67.872 1.888-18.208 19.264-17.376 49.632 1.888 67.872l316.96 299.84L335.2 813.632c-19.072 18.4-19.648 48.768-1.248 67.872 9.408 9.792 21.984 14.688 34.56 14.688 12 0 24-4.48 33.312-13.44l350.048-337.376c0.672-0.672 0.928-1.6 1.6-2.304 0.512-0.48 1.056-0.832 1.568-1.344 2.72-2.848 4.16-6.336 6.016-9.6z" fill={color}></path></svg>
  )
}