import React, { Fragment, PureComponent, createRef } from "react";
import _ from "lodash";
import { SearchBar } from "antd-mobile";
import { LeftOutline } from 'antd-mobile-icons'
import Layout from "../../../components/Layout";
import MenuListItem from "../../../components/MenuListItem";
import ProjectListViewPopup from "../../QueryEntry/components/ProjectListViewPopup";
import 
  InfoQuery, {
    FOR_COMPANY_QUERY_INVESTOR, FOR_COMPANY_QUERY_BUYER, FOR_COMPANY_QUERY_INVESTMENT_MA,
    FOR_COMPANY_QUERY_IP_BUYER,
    FOR_COMPANY_QUERY_OVERSEAS_BUSINESS_PARTNER,
    FOR_ORG_QUERY_FUNDRAISING_LP_INVESTOR,
    FOR_ORG_QUERY_FOLLOW_ON_FUND_LP,
    FOR_ORG_QUERY_SELLING_EXISTING_SHARES,
    FOR_ORG_QUERY_INVESTMENT_MA,
    FOR_ORG_QUERY_GP,
    FOR_ORG_QUERY_DEAL
  }
from './InfoQuery';
import GlobalInfoQuery from './GlobalInfoQuery';
import MemoQuery from './MemoQuery';
import './index.less';

/**
 * 查询入口
 */
export default class QueryEntry extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      _menus: [{
        key: 'query_entry_deal_search',
        title: '项目检索',
        category: 'collapse',
        children: [
          {
            key: '项目列表',
            label: '项目列表',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata }) => null,
          },
          {
            key: '立项评分',
            label: '立项评分',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata }) => null,
          },
          {
            key: '项目合同查询',
            label: '项目合同查询',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata }) => null,
          },
          {
            key: '项目健康度查询',
            label: '项目健康度查询',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata }) => null,
          },
        ]
      }, {
        key: 'query_entry_target_key_search',
        title: '按信息关键字查询',
        category: 'link',
        ref: createRef(),
        componentRender: ({ metadata, sourcedata }) => <GlobalInfoQuery key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />,
        render: () => <div style={{ marginBottom: 10 }}><SearchBar placeholder="请输入关键字" /></div>
      }, {
        key: 'query_entry_target_search',
        title: '按目标查询',
        category: 'collapse',
        children: [
          {
            key: 'query_entry_target_search_for_company',
            label: '为公司',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_target_search_for_company_query_investor',
                label: '找投资人',
                title: '找投资人',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_COMPANY_QUERY_INVESTOR} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_company_query_buyer',
                label: '找买方',
                title: '找买方',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_COMPANY_QUERY_BUYER} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_company_query_investment_ma',
                label: '找投资或并购目标',
                title: '找投资或并购目标',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_COMPANY_QUERY_INVESTMENT_MA} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_company_query_ip_buyer',
                label: '找IP购买方',
                title: '找IP购买方',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_COMPANY_QUERY_IP_BUYER} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_company_query_overseas_business_partner',
                label: '找业务合作方（出海）',
                title: '找业务合作方（出海）',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_COMPANY_QUERY_OVERSEAS_BUSINESS_PARTNER} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              },
            ]
          },
          {
            key: 'query_entry_target_search_for_organization',
            label: '为机构',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_target_search_for_organization_fundraising_lp_investor',
                label: '找LP/投资人（募资）',
                title: '找LP/投资人（募资）',
                ref: createRef(), 
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_ORG_QUERY_FUNDRAISING_LP_INVESTOR} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_organization_follow_on_fund_lp',
                label: '找接续基金投资人/出资人（接盘方）',
                title: '找接续基金投资人/出资人（接盘方）',
                ref: createRef(), 
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_ORG_QUERY_FOLLOW_ON_FUND_LP} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_organization_selling_existing_shares',
                label: '退老股',
                title: '退老股',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_ORG_QUERY_SELLING_EXISTING_SHARES} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_organization_investment_ma',
                label: '找投资或并购目标',
                title: '找投资或并购目标',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_ORG_QUERY_INVESTMENT_MA} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              },
            ]
          },
          {
            key: 'query_entry_target_search_for_lp',
            label: '为LP',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_target_search_for_organization_gp',
                label: '找GP',
                title: '找GP',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_ORG_QUERY_GP} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              }, {
                key: 'query_entry_target_search_for_organization_deal',
                label: '找项目',
                title: '找项目',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => <InfoQuery type={FOR_ORG_QUERY_DEAL} ref={metadata['ref']} key={metadata['key']} metadata={metadata} />
              },
            ]
          },
        ]
      }, {
        key: 'query_entry_memo_search',
        title: '会议记录查询',
        category: 'link',
        ref: createRef(),
        componentRender: ({ metadata, sourcedata }) => <MemoQuery key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />,
      }, {
        key: 'query_entry_oa_application',
        title: 'OA申报',
        category: 'collapse',
        children: [
          {
            key: 'query_entry_oa_application_',
            label: '工时',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_application_',
                label: '填报',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_application_',
                label: '查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          }, {
            key: 'query_entry_oa_application_',
            label: '休假',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_application_',
                label: '申请',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_application_',
                label: '查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          }, {
            key: 'query_entry_oa_application_',
            label: '用车',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_application_',
                label: '申报',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_application_',
                label: '查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          }, {
            key: 'query_entry_oa_application_',
            label: '合同',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_application_',
                label: '申请',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_application_',
                label: '查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          },
        ]
      }, {
        key: 'query_entry_oa_approve',
        title: 'OA审批',
        category: 'collapse',
        children: [
          {
            key: 'query_entry_oa_approve_',
            label: '工时',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_approve_',
                label: '待审批',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_approve_',
                label: '审批查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          }, {
            key: 'query_entry_oa_application_',
            label: '休假',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_approve_',
                label: '待审批',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_approve_',
                label: '审批查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          }, {
            key: 'query_entry_oa_application_',
            label: '用车',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_approve_',
                label: '待审批',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_approve_',
                label: '审批查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          }, {
            key: 'query_entry_oa_application_',
            label: '合同',
            category: 'collapse',
            children: [
              {
                key: 'query_entry_oa_approve_',
                label: '待审批',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              }, {
                key: 'query_entry_oa_approve_',
                label: '审批查询',
                ref: createRef(),
                componentRender: ({ metadata, sourcedata }) => null,
              },
            ]
          },
        ]
      }, {
        key: 'query_entry_deal_approve',
        title: '项目审批',
        category: 'collapse',
        children: [
          {
            key: 'query_entry_deal_approve_',
            label: '待审批',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata }) => null,
          }, {
            key: 'query_entry_deal_approve_',
            label: '审批查询',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata }) => null,
          },
        ]
      }, {
        key: 'query_entry_notice_query',
        title: '信息查询',
        category: 'collapse',
        children: []
      }]
    }
  }

  componentDidMount() {
    const {
      _menus
    } = this.state

    const array = this.collectComponentRenders(_menus)
    this.setState({ _components: array })
  }

  // 收集需要渲染的组件
  collectComponentRenders(items) {
    let array = []

    const recurse = (items) => {
      if (items && _.size(items) > 0) {
        items.forEach(menu => {
          if (menu['componentRender']) {
            array.push(menu)
          }
          if (menu['children'] && _.size(menu['children']) > 0) {
            recurse(menu['children'])
          }
        })
      }
    }

    recurse(items)
    return array
  }

  render() {
    const {
      _menus,
      _components,
    } = this.state

    return (
      <Layout head={(
        <div className="cec-query-entry-head">
          <div className="cec-query-entry-head-left" onClick={() => window.location.href = '/#/'} ><LeftOutline style={{ fontSize: '24px' }} /></div>
          <div className="cec-query-entry-head-center">查询</div>
          <div className="cec-query-entry-head-right"></div>
        </div>
      )}>
        <div className="cec-query-entry-body">
          {
            _menus.map(menu => <MenuListItem metadata={menu} />)
          }
        </div>
        {
          _components && _.size(_components) > 0 && (
            <Fragment>
              {
                _components.map(component => component.componentRender({ metadata: component }))
              }
            </Fragment>
          )
        }
      </Layout>
    )
  }
}