import _ from "lodash"
import { Fragment } from "react"
import BussinessStrategyListView from "./BussinessStrategyListView"
import FinancialDataListView from "./FinancialDataListView"
import TradingOpportunityListView from "./TradingOpportunityListView"
import OwnershipStructureListView from "./OwnershipStructureListView"
import FinancingHistoryListView from "./FinancingHistoryListView"
import InvestmentCaseListView from "./InvestmentCaseListView"
import EmploymentHistoryListView from "./EmploymentHistoryListView"

/**
 * 企业（产业方）- 表单部分
 * @param {*} param0 
 * @returns 
 */
export default function CompanyBodyInfoView({ code, targets, tabs }) {
  const currentActiveInfoTab = _.get(_.head(_.filter(tabs, { selected: true })), "key")
  // 组装编辑页面使用的数据
  const targetTypes = [{ value: "info_enterprise", label: "企业（产业方）" }]
  const targetExtensionValue = {
    targets,
    targetTypes
  }

  return (
    <Fragment>
      {
        currentActiveInfoTab === 'company_info_tab_1' && <BussinessStrategyListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'company_info_tab_2' && <FinancialDataListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'company_info_tab_3' && <TradingOpportunityListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'company_info_tab_4' && <OwnershipStructureListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'company_info_tab_5' && <FinancingHistoryListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'company_info_tab_6' && <InvestmentCaseListView code={code} type="company" targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'company_info_tab_7' && <EmploymentHistoryListView code={code} targetExtensionValue={targetExtensionValue} />
      }
    </Fragment>
  )
}