import React, { Fragment, useEffect, useImperativeHandle, useCallback, useRef, useState } from "react"
import _ from "lodash"
import { List, Skeleton, Space, Toast, Tag, ErrorBlock } from "antd-mobile"
import { EditSFill } from "antd-mobile-icons"
import CECInfiniteScroll from "../../../../../../../components/InfiniteScroll"
import CECPopup from "../../../../../../../components/Popup"
import './index.less';
import {
  FetchFamilyOfficeInvestmentCaseDataList,
  FetchFamilyOfficeInvestmentCaseDataDetail,
} from './transform'

/**
 * 家办投资案例列表
 * @returns 
 */
export default function FamilyOfficeInvestmentCaseListView({ code, type }) {

  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [item, setItem] = useState({})
  const dataDetailPopupRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      await fetchDatas(code, type, 1);
    }
    code && type && fetchData();
  }, [code, type]);

  // 获取列表
  const fetchDatas = async (code, type, currentPageNum) => {
    setLoading(true)
    try {
      const { datas: newDatas, hasMore, total } = await FetchFamilyOfficeInvestmentCaseDataList({ code, type, pageNum: currentPageNum, pageSize })
      setLoading(false);
      setHasMore(hasMore);
      setPageNum(currentPageNum + 1);
      if (currentPageNum === 1) {
        setDatas(newDatas)
      } else {
        setDatas(prevDatas => [...prevDatas, ...newDatas]);
      }
    } catch (error) {
      Toast.show({ content: error })
      setLoading(false)
      setHasMore(false);
    }
  }

  const handleLoadMore = useCallback(async () => {
    code && await fetchDatas(code, type, pageNum);
  }, [code, type, pageNum])

  const handleClick = (key, data, event) => {
    console.log("handleClick >>>>", key, data, event)

    switch (key) {
      case 'handle_open_item_detail_popup':
        setItem(data)
        dataDetailPopupRef?.current?.show()
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <List>
        {
          loading && _.size(datas) == 0 && (
            <Fragment>
              <Skeleton.Title animated />
              <Skeleton.Paragraph lineCount={4} animated />
            </Fragment>
          )
        }
        {
          !loading && _.size(datas) == 0 && (
            <Fragment>
              <ErrorBlock status='empty' title="" description="暂无数据" />
            </Fragment>
          )
        }
        {
          datas.map(item => (
            <List.Item onClick={() => handleClick('handle_open_item_detail_popup', item)}>
              <div className="investment-case-item">
                <div className="investment-case-item-row">XXXXX公司<span style={{ marginLeft: 5 }}><Tag color="#1890FF">A轮</Tag></span></div>
                <div className="investment-case-item-row">
                  <Space>
                    <span>
                      <span className="investment-case-item-key">投资时间：</span>
                      <span>xxxxxx</span>
                    </span>
                    <span>
                      <span className="investment-case-item-key">投资方式：</span>
                      <span>xxxxxx</span>
                    </span>
                  </Space>
                </div>
                <div className="investment-case-item-row">
                  <span className="investment-case-item-key">投资金额：</span>
                  <span>xxxxx</span>
                </div>
                <div className="investment-case-item-row">
                  <span className="investment-case-item-key">最新估值：</span>
                  <span>xxxxx</span>
                </div>
                <div className="investment-case-item-row">
                  <span className="investment-case-item-key">当前持股比例：</span>
                  <span>xxxxx %</span>
                </div>
                <div className="investment-case-item-row">
                  <span className="investment-case-item-key">项目主要负责人：</span>
                  <span>xxxxx</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span className="investment-case-item-key">更新时间/更新人：</span>{item['modifyDate']}
                  </div>
                  <div style={{ margin: '0 5px' }}>/</div>
                  <div>{item['modidyUser']}</div>
                </div>
              </div>
            </List.Item>
          ))
        }
        <CECInfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} />
      </List>

      {/** 详情Popup */}

    </Fragment>
  )
}