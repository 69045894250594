import React, { Fragment, PureComponent, createRef, useState } from "react";
import { Input, Avatar, Button, CheckList, Collapse, Dropdown, List, SearchBar, Selector, SideBar, Space, Tag, Toast } from "antd-mobile";
import { LeftOutline, RightOutline, DownOutline } from 'antd-mobile-icons'
import './index.css'
import Tree from "../../components/Tree";
import BaseExtractPopup from "../../components/BaseExtractPopup";
import _ from "lodash";
import { QueryDetailPopup } from './index';
import ProjectListViewPopup from './components/ProjectListViewPopup';
import { MeetingSearchBar, IndustryView, onIndustryItemClick, TSearchView, TSideBarBox, FINANCING_SCALE_OPTIONS } from "../../components/FormsUtils";
import {
  fetchMeetingList, fetchEnterpriseList
} from './transform';
import InfoUpdateDetailPopup from "../InfoUpdateDetail";
import moment from "moment";

const datas_1 = [
  {
    label: '找投资人',
    value: '找投资人',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindInvestorPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  }, {
    label: '找买方',
    value: '找买方',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindInvestorPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  }, {
    label: '找投资或并购目标',
    value: '找投资或并购目标',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindInvestorPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  }, {
    label: '找IP购买方',
    value: '找IP购买方',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindInvestorPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  }, {
    label: '找业务合作方（出海）',
    value: '找业务合作方（出海）',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindInvestorPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  },
]


const datas_2 = [
  {
    label: '找LP/投资人（募资）',
    value: '找LP/投资人（募资）',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindLPPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  }, {
    label: '找接续基金投资人/出资人（接盘方）',
    value: '找接续基金投资人/出资人（接盘方）',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindLPPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  }, {
    label: '退老股',
    value: '退老股',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindLPPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  }, {
    label: '找投资或并购目标',
    value: '找投资或并购目标',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindLPPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  },
]

const datas_3 = [
  {
    label: '找GP',
    value: '找GP',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindInvestorPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  },
  {
    label: '找项目',
    value: '找项目',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <FindInvestorPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  },
]
const datas_4 = [
  {
    label: '项目列表',
    value: '项目列表',
    ref: createRef(),
    componentRender: ({ metadata, sourcedata }) => <ProjectListViewPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
  },
  {
    label: '立项评分',
    value: '立项评分',
  },

  {
    label: '项目合同查询',
    value: '项目合同查询',
  },
  // {
  //   label: '项目相关审批',
  //   value: '项目相关审批',
  // },
  {
    label: '项目健康度查询',
    value: '项目健康度查询',
  },
]

const datas_6 = [
  {
    label: 'HR相关',
    value: 'HR相关',
    children: [
      {
        label: '入职手册',
        value: '入职手册',
      }, {
        label: '着装规定',
        value: '着装规定',
      }, {
        label: '员工福利',
        value: '员工福利',
      },
    ]
  },
  {
    label: '政策法规',
    value: '政策法规',
    children: [
      {
        label: '合规动态',
        value: '合规动态',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      }
    ]
  },
  {
    label: '财务信息',
    value: '财务信息',
    children: [
      {
        label: '易凯伙伴（深圳）',
        value: '易凯伙伴（深圳）',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '易铠（上海）',
        value: '易铠（上海）',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '北京易凯伙伴',
        value: '北京易凯伙伴',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '熙易熙（上海）',
        value: '熙易熙（上海）',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '易凯明天（北京）',
        value: '易凯明天（北京）',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '天津易凯明天',
        value: '天津易凯明天',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
    ]
  },
  {
    label: 'IT服务',
    value: 'IT服务',
    children: [
      {
        label: '关于开通腾讯会议会员版的说明',
        value: '关于开通腾讯会议会员版的说明',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'AI工具在工作中的实际应用',
        value: 'AI工具在工作中的实际应用',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '工位预定使用帮助',
        value: '工位预定使用帮助',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'CEC SMART系统使用帮助',
        value: 'CEC SMART系统使用帮助',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'How to Tele Conf',
        value: 'How to Tele Conf',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '上海办公区WiFi信息',
        value: '上海办公区WiFi信息',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '北京办公区WiFi信息',
        value: '北京办公区WiFi信息',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '合同用章申请使用帮助',
        value: '合同用章申请使用帮助',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'How to Setup Outlook',
        value: 'How to Setup Outlook',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'Mac连接公司打印机方法',
        value: 'Mac连接公司打印机方法',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'How to Remote Database',
        value: 'How to Remote Database',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'How to Setup MobileMail',
        value: 'How to Setup MobileMail',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'Contact General IT',
        value: 'Contact General IT',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: 'How to Printer/Device',
        value: 'How to Printer/Device',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
      {
        label: '关于CEC企业网盘的使用方法',
        value: '关于CEC企业网盘的使用方法',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
      },
    ]
  },
  {
    label: '实用信息',
    value: '实用信息',
    children: [
      {
        label: '数据库账号',
        value: '数据库账号',
        children: [
          {
            label: 'Wind公共账号',
            value: 'Wind公共账号',
            // ref: createRef(),
            // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
          },
          {
            label: '企查查公共账号',
            value: '企查查公共账号',
            // ref: createRef(),
            // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
          },
          {
            label: 'Capital IQ公共账号',
            value: 'Capital IQ公共账号',
            // ref: createRef(),
            // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
          },
          {
            label: '企名片公共账号',
            value: '企名片公共账号',
            // ref: createRef(),
            // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
          },
          {
            label: '医药魔方账号',
            value: '医药魔方账号',
            // ref: createRef(),
            // componentRender: ({ metadata, sourcedata }) => <EmptyPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} />
          },
        ]
      },
    ]
  },
]

const datas_7 = [
  {
    label: '工时',
    value: '工时',
    children: [
      {
        label: '填报',
        value: '填报',
      },
      {
        label: '查询',
        value: '查询',
      },
    ]
  },
  {
    label: '休假',
    value: '休假',
    children: [
      {
        label: '申请',
        value: '申请',
      },
      {
        label: '查询',
        value: '查询',
      },
    ]
  },
  {
    label: '用车',
    value: '用车',
    children: [
      {
        label: '申报',
        value: '申报',
      },
      {
        label: '查询',
        value: '查询',
      },
    ]
  },
  {
    label: '合同',
    value: '合同',
    children: [
      {
        label: '申请',
        value: '申请',
      },
      {
        label: '查询',
        value: '查询',
      },
    ]
  },
]

const datas_8 = [
  // {
  //   label: '项目审批',
  //   value: '项目审批',
  //   children: [
  //     {
  //       label: '待审批',
  //       value: '待审批',
  //     },
  //     {
  //       label: '审批查询',
  //       value: '审批查询',
  //     }
  //   ]
  // },
  {
    label: '工时',
    value: '工时',
    children: [
      {
        label: '待审批',
        value: '待审批',
      },
      {
        label: '审批查询',
        value: '审批查询',
      }
    ]
  },
  {
    label: '休假',
    value: '休假',
    children: [
      {
        label: '待审批',
        value: '待审批',
      },
      {
        label: '审批查询',
        value: '审批查询',
      },
      {
        label: '抄送查询',
        value: '抄送查询',
      }
    ]
  },
  {
    label: '用车',
    value: '用车',
    children: [
      {
        label: '待审批',
        value: '待审批',
      },
      {
        label: '审批查询',
        value: '审批查询',
      }
    ]
  },
  {
    label: '合同',
    value: '合同',
    children: [
      {
        label: '待审批',
        value: '待审批',
      },
      {
        label: '审批查询',
        value: '审批查询',
      }
    ]
  }
]
const datas_9 = [
  {
    label: '待审批',
    value: '待审批',
  },
  {
    label: '审批查询',
    value: '审批查询',
  },
]
export default class QueryEntry extends PureComponent {

  constructor(props) {
    super(props);

    let data = null
    if (props.match && props.match.params && props.match.params.data) {
      data = props.match.params.data
    }

    this.state = {
      _id: data,
      _selectItems: [],
    }

    this.searchInfoPopupRef = createRef()
    this.memoListPopupRef = createRef()
    this.queryDetailPopupRef = createRef()
    this.findInvestorPopupRef = createRef()
    this.SearchBarRef = createRef()
  }

  componentDidMount() {
    const {
      match
    } = this.props
    let data = null
    if (match && match.params && match.params.data) {
      data = match.params.data
    }

    const componentRenderNodes = _.concat(datas_1, datas_2, datas_3, datas_4, datas_6).reduce((acc, item) => acc.concat(collectComponentRenderNodes(item)), []);
    this.setState({ _id: data, _selectItems: componentRenderNodes }, () => this.handleClick('handle_toggle_popup'))
  }

  handleClick(key, data, event) {
    console.log('handleClick >>>>', key, data, event)
    const { _id } = this.state
    switch (key) {
      case 'handle_select_item':
        if (data && _.size(data) > 0) {
          const lastItem = _.last(_.get(data, "data.path"))
          if (lastItem.ref) {
            lastItem.ref?.current?.toggle()
          } else {
            Toast.show({ content: 'DEMO演示数据' })
          }
        }
        break;
      case 'handle_toggle_popup':
        if (_id) {
          this.queryDetailPopupRef.current?.toggle()
        }
        break;
      default: ;
    }
  }

  render() {
    const {
      _id,
      _selectItems
    } = this.state

    return (
      <Fragment>
        <List style={{ background: '#f0f0f0' }}
          header={
            <div style={{ height: 30, lineHeight: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '24px', height: '24px' }}><LeftOutline style={{ fontSize: '24px' }} onClick={() => window.location.href = '/#/'} /></div>
              <div style={{ color: '#333' }}>查询</div>
              <div style={{ width: '24px', height: '24px' }}></div>
            </div>
          }>
        </List>
        <div className="card-entry-list">

          <div className="card-box">
            <Collapse accordion className="card-list-collapse-2">
              <Collapse.Panel key='项目检索' title='项目检索' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                <Tree metadata={{}}
                  datas={datas_4}
                  onSelect={this.handleClick.bind(this, 'handle_select_item')}
                  onConfirm={(data) => console.log('onConfirm >>>', data)}
                />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="card-box">
            <div className="card-title">按信息关键字查询</div>
            <div style={{ margin: '10px 20px' }}>
              <SearchBar ref={this.SearchBarRef} placeholder="请输入关键字" onFocus={() => {
                this.searchInfoPopupRef?.current?.toggle()
                this.SearchBarRef?.current?.blur()
              }} />
            </div>
          </div>

          <div className="card-box">
            <Collapse accordion className="card-list-collapse-2">
              <Collapse.Panel key='按目标查询' title='按目标查询' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                <Collapse accordion className="card-list-collapse">
                  <Collapse.Panel key='为公司查询' title='为公司' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                    <Tree metadata={{}}
                      datas={datas_1}
                      onSelect={this.handleClick.bind(this, 'handle_select_item')}
                      onConfirm={(data) => console.log('onConfirm >>>', data)}
                    />
                  </Collapse.Panel>
                </Collapse>
                <Collapse accordion className="card-list-collapse">
                  <Collapse.Panel key='为机构查询' title='为机构' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                    <Tree metadata={{}}
                      datas={datas_2}
                      onSelect={this.handleClick.bind(this, 'handle_select_item')}
                      onConfirm={(data) => console.log('onConfirm >>>', data)}
                    />
                  </Collapse.Panel>
                </Collapse>
                <Collapse accordion className="card-list-collapse">
                  <Collapse.Panel key='为LP查询' title='为LP' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                    <Tree metadata={{}}
                      datas={datas_3}
                      onSelect={this.handleClick.bind(this, 'handle_select_item')}
                      onConfirm={(data) => console.log('onConfirm >>>', data)}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="card-box">
            <div className="card-list">
              <CardItem style={{ border: 'unset', padding: 0 }} onClick={() => this.memoListPopupRef.current.toggle()}>会议记录查询</CardItem>
            </div>
          </div>

          <div className="card-box">
            <Collapse accordion className="card-list-collapse-2">
              <Collapse.Panel key='1' title='OA申报' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                <Tree metadata={{}}
                  datas={datas_7}
                  onSelect={this.handleClick.bind(this, 'handle_select_item')}
                  onConfirm={(data) => console.log('onConfirm >>>', data)}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="card-box">
            <Collapse accordion className="card-list-collapse-2">
              <Collapse.Panel key='1' title='OA审批' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                <Tree metadata={{}}
                  datas={datas_8}
                  onSelect={this.handleClick.bind(this, 'handle_select_item')}
                  onConfirm={(data) => console.log('onConfirm >>>', data)}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="card-box">
            <Collapse accordion className="card-list-collapse-2">
              <Collapse.Panel key='1' title='项目审批' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                <Tree metadata={{}}
                  datas={datas_9}
                  onSelect={this.handleClick.bind(this, 'handle_select_item')}
                  onConfirm={(data) => console.log('onConfirm >>>', data)}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="card-box">
            <Collapse accordion className="card-list-collapse-2">
              <Collapse.Panel key='1' title='信息查询' arrow={active => active ? <DownOutline /> : <RightOutline />}>
                <Tree metadata={{}}
                  datas={datas_6}
                  onSelect={this.handleClick.bind(this, 'handle_select_item')}
                  onConfirm={(data) => console.log('onConfirm >>>', data)}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>

        {
          _selectItems && _.size(_selectItems) > 0 &&
          _selectItems.map(item =>
            item.componentRender({
              metadata: { ...item, title: item['label'] },
              sourcedata: {}
            })
          )
        }

        <FindInvestorPopup ref={this.findInvestorPopupRef} metadata={{ title: '为LP - 找GP' }} />
        <SearchInfoPopup ref={this.searchInfoPopupRef} metadata={{ title: '按信息关键字查询' }} />
        <MemoListPopup ref={this.memoListPopupRef} metadata={{ title: '会议记录查询' }} />
        <QueryDetailPopup ref={this.queryDetailPopupRef} metadata={{ title: '详细信息' }} id={_id} />
      </Fragment>
    )

  }
}

function CardItem({ style, onClick, children }) {
  return (
    <div className="card-item" style={style} onClick={onClick}>
      <div className="card-item-children">{children}</div>
      {onClick && <div><RightOutline style={{ fontSize: 20, color: '#ccc' }} /></div>}
    </div>
  )
}

function collectComponentRenderNodes(node) {
  let result = [];
  if (node.componentRender) {
    result.push(node);
  }
  if (node.children && Array.isArray(node.children)) {
    node.children.forEach(child => {
      result = result.concat(collectComponentRenderNodes(child));
    })
  }
  return result;
}

/**
 * 找投资人
 */
export class FindInvestorPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)
    this.overflow = true
    this.state = {
      _detailCode: null,
      _activeKey: null,
      _dropdownValue: null,
      _filter: {},
      _total: 0
    }
    this.dropdownRef = createRef()
    this.infoUpdateDetailPopupRef = createRef()
    this.EnterpriseListRef = createRef()
  }

  toggle() {
    const {
      _activeKey
    } = this.state
    this.setState({
      _activeKey: "类型"
    })
    super.toggle()
  }

  close() {
    this.setState({
      _activeKey: null
    })
    this.dropdownRef.current?.close()
    super.close()
  }

  getDestroyOnClose() {
    return true
  }

  handleClick(key, data, event) {
    const { _filter } = this.state
    switch (key) {
      case "onItemClick":
        this.setState({
          _detailCode: data['id']
        }, () => {
          this.infoUpdateDetailPopupRef?.current?.toggle()
        })
        break
      case 'onFilterChange':
        this.setState({
          _filter: { ..._filter, [data]: event }
        }, () => {
          if (data != 'keyWord') {
            Toast.show({ content: "DEMO演示，暂不支持当前操作" })
          }
        })
        break
    }
  }

  renderNavRight() {
    return (
      <div style={{ color: '#1890FF' }} onClick={() => Toast.show({ content: "导出结果将在正式版本中支持" })}>导出</div>
    )
  }

  renderBodyContent() {
    let {
      _detailCode,
      _activeKey,
      _dropdownValue,
      _filter,
      _total,
    } = this.state
    const { metadata } = this.props
    console.log("metadata===>", metadata)
    return (
      <div className="find-investor-popup">
        {/* <Dropdown getContainer={() => document.querySelector('.find-investor-popup')}
          ref={this.dropdownRef}
          defaultActiveKey={_activeKey}
          onChange={(activeKey) => {
            if (activeKey === '行业') {
              this.setState({ _dropdownValue: '企名片行业' })
            } else if (activeKey === '交易相关') {
              this.setState({ _dropdownValue: '交易时间' })
            } else if (activeKey === '更多筛选') {
              this.setState({ _dropdownValue: '关键字' })
            }
          }}>
          <Dropdown.Item key="类型" title="类型" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ margin: '10px' }}>
                <Selector columns={2} multiple
                  options={[
                    { label: 'VC', value: 'VC' },
                    { label: 'PE', value: 'PE' },
                    { label: '对冲', value: '对冲' },
                    { label: '金融机构', value: '金融机构' },
                    { label: '大企业集团', value: '大企业集团' },
                    { label: 'A股上市公司', value: 'A股上市公司' },
                    { label: '通道', value: '通道' },
                    { label: '国家队/政府背景', value: '国家队/政府背景' },
                    { label: '家族', value: '家族' },
                    { label: '母基金', value: '母基金' },
                    { label: '港美股上市公司', value: '港美股上市公司' },
                    { label: '跨国公司', value: '跨国公司' }
                  ]}
                  onChange={(val) => this.handleClick('onFilterChange', 'types', val)}
                />
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
          <Dropdown.Item key="行业" title="赛道/行业" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: 105 }}>
                  <SideBar>
                    <SideBar.Item key={"企名片行业"} title="企名片行业"></SideBar.Item>
                    <SideBar.Item key={"易凯行业"} title="易凯行业"></SideBar.Item>
                  </SideBar>
                </div>
                <div style={{ width: 'calc(100% - 105px)', height: '100%', overflow: 'auto' }}>
                  <CheckList multiple onChange={(val) => this.handleClick('onFilterChange', 'industrys', val)}>
                    {
                      industryOptions.map(item => <CheckList.Item key={item.value} value={item.value}>{item.label}</CheckList.Item>)
                    }
                  </CheckList>
                </div>
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
          <Dropdown.Item key="交易相关" title="交易相关" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: 105 }}>
                  <SideBar onChange={(value) => this.setState({ _dropdownValue: value })}>
                    <SideBar.Item key={"交易时间"} title="交易时间"></SideBar.Item>
                    <SideBar.Item key={"投资轮次"} title="投资轮次"></SideBar.Item>
                    <SideBar.Item key={"投资规模"} title="投资规模"></SideBar.Item>
                    <SideBar.Item key={"币种"} title="币种"></SideBar.Item>
                  </SideBar>
                </div>
                <div style={{ width: 'calc(100% - 105px)', height: '100%', overflow: 'auto', padding: 10 }}>
                  {
                    (!_dropdownValue || _dropdownValue === '交易时间') && (
                      <div>
                        <Selector columns={2} multiple onChange={(val) => this.handleClick('onFilterChange', 'date', val)}
                          options={[
                            { label: '近30天', value: '近30天' },
                            { label: '近60天', value: '近60天' },
                            { label: '近90天', value: '近90天' },
                            { label: '近180天', value: '近180天' },
                          ]} />
                      </div>
                    )
                  }
                  {
                    _dropdownValue === '投资轮次' && (
                      <div>
                        <Selector columns={2} multiple onChange={(val) => this.handleClick('onFilterChange', 'round', val)}
                          options={[
                            { label: '天使轮', value: '天使轮' },
                            { label: 'A轮', value: 'A轮' },
                            { label: 'B轮', value: 'B轮' },
                            { label: 'C轮', value: 'C轮' },
                            { label: 'D轮', value: 'D轮' },
                            { label: 'IPO', value: 'IPO' },
                            { label: '并购', value: '并购' },
                          ]} />
                      </div>
                    )
                  }
                  {
                    _dropdownValue === '投资规模' && (
                      <div>
                        <Selector columns={2} multiple onChange={(val) => this.handleClick('onFilterChange', 'scal', val)}
                          options={[
                            { label: '未透露', value: '未透露' },
                            { label: '数十万', value: '数十万' },
                            { label: '数百万', value: '数百万' },
                            { label: '数千万', value: '数千万' },
                            { label: '亿元及以上', value: '亿元及以上' },
                          ]} />
                      </div>
                    )
                  }
                  {
                    _dropdownValue === '币种' && (
                      <div>
                        <Selector columns={2} multiple onChange={(val) => this.handleClick('onFilterChange', 'currency', val)}
                          options={[
                            { label: '人民币', value: '人民币' },
                            { label: '美元', value: '美元' },
                            { label: '其他币种', value: '其他币种' },
                          ]} />
                      </div>
                    )
                  }
                </div>
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
          <Dropdown.Item key="更多筛选" title="更多筛选" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: 105 }}>
                  <SideBar onChange={(value) => this.setState({ _dropdownValue: value })}>
                    <SideBar.Item key={"关键字"} title="关键字"></SideBar.Item>
                    <SideBar.Item key={"地区"} title="地区"></SideBar.Item>
                    <SideBar.Item key={"合作方向"} title="合作方向"></SideBar.Item>
                  </SideBar>
                </div>
                <div style={{ width: 'calc(100% - 105px)', height: '100%', overflow: 'auto', padding: 10 }}>
                  {
                    (!_dropdownValue || _dropdownValue === '关键字') && (
                      <div>
                        <SearchBar placeholder="请输入关键字" onChange={(val) => this.handleClick('onFilterChange', 'keyWord', val)} />
                      </div>
                    )
                  }
                  {
                    _dropdownValue === '地区' && (
                      <div>
                        <Selector columns={2} multiple onChange={(val) => this.handleClick('onFilterChange', 'ares', val)}
                          options={[
                            { label: '中国', value: '中国' },
                            { label: '北美', value: '北美' },
                            { label: '欧洲', value: '欧洲' },
                            { label: '东南亚', value: '东南亚' },
                            { label: '中东', value: '中东' },
                            { label: '日韩', value: '日韩' },
                            { label: '海外其他', value: '海外其他' },
                          ]} />
                      </div>
                    )
                  }
                  {
                    _dropdownValue === '合作方向' && (
                      <div>
                        <Selector columns={2} multiple onChange={(val) => this.handleClick('onFilterChange', 'doits', val)}
                          options={[
                            { label: '研发', value: '研发' },
                            { label: '销售', value: '销售' },
                            { label: '生产制造', value: '生产制造' },
                            { label: '产品授权', value: '产品授权' },
                          ]} />
                      </div>
                    )
                  }
                </div>
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
        </Dropdown> */}
        {
          _.includes([
            '找投资人', '找买方', '找IP购买方', '找LP/投资人', '找接续基金投资人/出资人', '退老股', '找GP', ''
          ], metadata?.value) ? <EnterpriseLPSearchBar total={_total} />: <EnterpriseCompanySearchBar total={_total} />
        }

        <div className="find-investor-popup-list">
          <EnterpriseList
            ref={this.EnterpriseListRef}
            type="investor"
            onItemClick={it => this.handleClick('onItemClick', it)}
            fetchDataAfter={({ total }) => this.setState({ _total: total })}
          />
        </div>

        {/*  */}
        <InfoUpdateDetailPopup ref={this.infoUpdateDetailPopupRef} metadata={{ title: '企业详情' }} code={_detailCode} />

      </div >
    )
  }
}


/**
 * 找LP
 */
export class FindLPPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _activeKey: null,
      _dropdownValue: null,
      _detailCode: null,
      _filter: {}
    }
    this.dropdownRef = createRef()
    this.infoUpdateDetailPopupRef = createRef()
    this.EnterpriseListRef = createRef()
  }

  toggle() {
    const {
      _activeKey
    } = this.state
    this.setState({
      _activeKey: "类型"
    })
    super.toggle()
  }

  close() {
    this.setState({
      _activeKey: null
    })
    this.dropdownRef.current?.close()
    super.close()
  }

  getDestroyOnClose() {
    return true
  }
  handleClick(key, data, event) {
    const { _filter } = this.state
    switch (key) {
      case "onItemClick":
        this.setState({
          _detailCode: data['id']
        }, () => {
          this.infoUpdateDetailPopupRef?.current?.toggle()
        })
        break
      case 'onFilterChange':
        this.setState({
          _filter: { ..._filter, [data]: event }
        }, () => {
          if (data != 'keyWord') {
            Toast.show({ content: "DEMO演示，暂不支持当前操作" })
          }
        })
        break
    }
  }

  renderNavRight() {
    return (
      <div style={{ color: '#1890FF' }} onClick={() => Toast.show({ content: "DEMO演示" })}>导出</div>
    )
  }

  renderBodyContent() {
    const {
      _activeKey, _detailCode,
      _dropdownValue,
    } = this.state
    return (
      <div className="find-lp-popup">
        <Dropdown getContainer={() => document.querySelector('.find-lp-popup')}
          ref={this.dropdownRef}
          defaultActiveKey={_activeKey}
          onChange={(activeKey) => {
            console.log('activeKey >>>', activeKey)
            if (activeKey === '行业') {
              this.setState({ _dropdownValue: '主投方向' })
            } else if (activeKey === '交易相关') {
              this.setState({ _dropdownValue: '阶段' })
            } else if (activeKey === '更多筛选') {
              this.setState({ _dropdownValue: '期限' })
            }
          }}>
          <Dropdown.Item key="类型" title="类型" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ margin: '10px' }}>
                <Selector columns={2} multiple onChange={val => this.handleClick('onFilterChange', 'types', val)}
                  options={[
                    { label: '国有企业', value: '国有企业' },
                    { label: '上市公司', value: '上市公司' },
                    { label: '民营企业', value: '民营企业' },
                    { label: 'FOF', value: 'FOF' },
                    { label: '政府引导基金', value: '政府引导基金' },
                    { label: '母基金', value: '母基金' },
                    { label: '券商资管', value: '券商资管' },
                  ]}
                />
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
          <Dropdown.Item key="主投方向" title="主投方向" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: 105 }}>
                  <SideBar>
                    <SideBar.Item key={"主投方向"} title="主投方向"></SideBar.Item>
                  </SideBar>
                </div>
                <div style={{ width: 'calc(100% - 105px)', height: '100%', overflow: 'auto' }}>
                  <CheckList multiple onChange={val => this.handleClick('onFilterChange', 'industry', val)}>
                    {
                      industryOptions.map(item => <CheckList.Item key={item.value} value={item.value}>{item.label}</CheckList.Item>)
                    }
                  </CheckList>
                </div>
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
          <Dropdown.Item key="交易相关" title="交易相关" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: 105 }}>
                  <SideBar onChange={(value) => this.setState({ _dropdownValue: value })}>
                    <SideBar.Item key={"阶段"} title="阶段"></SideBar.Item>
                  </SideBar>
                </div>
                <div style={{ width: 'calc(100% - 105px)', height: '100%', overflow: 'auto', padding: 10 }}>
                  {
                    (!_dropdownValue || _dropdownValue === '阶段') && (
                      <div>
                        <Selector columns={2} multiple onChange={val => this.handleClick('onFilterChange', 'status', val)}
                          options={[
                            { label: '早期', value: '早期' },
                            { label: '成长期', value: '成长期' },
                            { label: '上市公司', value: '上市公司' },
                            { label: '定增', value: '定增' },
                          ]} />
                      </div>
                    )
                  }
                </div>
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
          <Dropdown.Item key="更多筛选" title="更多筛选" destroyOnClose>
            <BaseDropDownItem onClick={() => this.dropdownRef.current.close()}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: 105 }}>
                  <SideBar onChange={(value) => this.setState({ _dropdownValue: value })}>
                    <SideBar.Item key={"期限"} title="期限"></SideBar.Item>
                    <SideBar.Item key={"地区"} title="地区"></SideBar.Item>
                  </SideBar>
                </div>
                <div style={{ width: 'calc(100% - 105px)', height: '100%', overflow: 'auto', padding: 10 }}>
                  {
                    (!_dropdownValue || _dropdownValue === '期限') && (
                      <div>
                        <Selector columns={2} multiple onChange={val => this.handleClick('onFilterChange', 'date', val)}
                          options={[
                            { label: '1-3年', value: '1-3年' },
                            { label: '3-5年', value: '3-5年' },
                            { label: '5-8年', value: '5-8年' },
                          ]} />
                      </div>
                    )
                  }
                  {
                    _dropdownValue === '地区' && (
                      <div>
                        <Selector columns={2} multiple onChange={val => this.handleClick('onFilterChange', 'ares', val)}
                          options={[
                            { label: '中国大陆', value: '中国大陆' },
                            { label: '香港', value: '香港' },
                            { label: '海外', value: '海外' },
                          ]} />
                      </div>
                    )
                  }
                </div>
              </div>
            </BaseDropDownItem>
          </Dropdown.Item>
        </Dropdown>
        <EnterpriseList ref={this.EnterpriseListRef} type='lp' onItemClick={it => this.handleClick("onItemClick", it)} />
        {/*  */}
        <InfoUpdateDetailPopup ref={this.infoUpdateDetailPopupRef} metadata={{ title: '企业详情' }} code={_detailCode} />

      </div>
    )
  }
}


/**
 * 信息导向查询
 */
export class SearchInfoPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _detailCode: null
    }
    this.infoUpdateDetailPopupRef = createRef()
  }

  componentDidMount() {
    this.setState({ _activeKey: "类型" })
  }

  handleClick(key, data, event) {
    const { _filter } = this.state
    switch (key) {
      case "onItemClick":
        this.setState({
          _detailCode: data['id']
        }, () => {
          this.infoUpdateDetailPopupRef?.current?.toggle()
        })
        break
      // case 'onFilterChange':
      //   this.setState({
      //     _filter: {..._filter, [data]: event}
      //   }, ()=> {
      //     if(data != 'keyWord') {
      //       Toast.show({ content: "DEMO演示，暂不支持当前操作"})
      //     }
      //   })
      //   break
    }
  }

  renderBodyContent() {
    const { _detailCode } = this.state
    return (
      <div>
        <div style={{ padding: '10px' }}>
          <SearchBar placeholder="请输入关键字" style={{ marginBottom: 8 }} onChange={val => Toast.show({ content: "DEMO演示，暂不支持当前操作" })} />

          <EnterpriseList type='lp' onItemClick={it => this.handleClick("onItemClick", it)} />
        </div>
        {/*  */}
        <InfoUpdateDetailPopup ref={this.infoUpdateDetailPopupRef} metadata={{ title: '企业详情' }} code={_detailCode} />

      </div>
    )
  }

}
/**
 * 会议集合
 */
export class MemoListPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)
    this.overflow = true
    this.state = {
      _dataList: [],
      _detailData: {}
    }
    this.queryDetailPopupRef = createRef()
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData(filter = {}) {

    let _filter = filter ? {
      input1: filter['keyWord'],
      input2: _.join(_.map(filter['industry'], 'value'), ','),
    } : {}


    fetchMeetingList(1, 30, _filter).then(meetings => {
      // console.log("fetchMeetingList==>", meetings)
      this.setState({
        _dataList: meetings
      })
    }).catch(e => { })
  }

  handleClick(key, data, event) {
    console.log('handleClick >>>', key, data, event)
    switch (key) {
      case 'handle_toggle_popup':
        this.setState({
          _detailData: data ? data : {}
        }, () => this.queryDetailPopupRef.current?.toggle())

        break;
      default:
        break;
    }
  }

  renderBodyContent() {
    const { _dataList, _detailData } = this.state
    let _groupDataList = _.map(_.groupBy(_dataList, 'meetingDate'), (value, key) => {
      return { label: key, value: value, time: _.size(key) > 0 ? moment(key).toDate() : null }
    })
    _groupDataList = _.concat(
      _.reverse(
        _.sortBy(
          _.filter(_groupDataList, it => it.time != null)
          , 'time')
      ),
      _.filter(_groupDataList, it => it.time == null)
    )
    return (
      <div className="meeting-list-layout" >
        <div className="meeting-list-search-bar">
          <MeetingSearchBar onConfirm={filter => this.fetchData(filter)} />
        </div>
        <div className="meeting-list">
          {
            _.map(_groupDataList, (group, ii) => (
              <Fragment key={`group-list-${ii}`}>
                <div key={`group-${ii}`} style={{ color: '#999', textAlign: 'center', padding: '10px', background: '#f0f0f0' }}>{group.label}</div>
                {
                  _.map(group.value, (item, i) => (
                    <div key={`group-item-${i}`} style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup', item)}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <span style={{ fontSize: '16px' }}>{`${_.get(_.head(item.meetingAttendees), 'attendeeName', '')}【${_.get(_.head(item.meetingAttendees), 'identityName', '')}】`}</span>
                        {_.size(item.meetingAttendees) > 1 ? <span style={{ color: "#999" }}>{`共${_.size(item.meetingAttendees)}个与会者`}</span> : ''}
                      </div>
                      <div style={{ marginBottom: '4px' }}>
                        <span style={{ color: '#1890FF' }}>{item.meetingNature}</span>
                      </div>

                      <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {item.meetingContent}
                      </div>
                    </div>
                  ))
                }
              </Fragment>
            ))
          }
        </div>
        <QueryDetailPopup ref={this.queryDetailPopupRef} metadata={{ title: '会议记录' }} detailData={_detailData ? _detailData : {}} />

      </div>
    )
  }

}

function BaseDropDownItem({ children, onClick }) {
  return (
    <div style={{ height: '50vh' }}>
      <div style={{ height: 'calc(50vh - 60px)', overflow: 'auto' }}>{children}</div>
      <div style={{ padding: 10, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button block style={{ marginRight: 5 }} onClick={onClick}>重置</Button>
        <Button block color="primary" onClick={onClick}>确定</Button>
      </div>
    </div>
  )
}


const investors = [
  { id: "I239270541633758522", label: '红杉中国', value: ['PE'], area: '海外', avatar: 'http://file.ceccapitalgroup.com/logo/investment/25e8990b58858072fb6ec5b1d2d122d2.jpg', description: '可以领投,本地决策' },
  { id: "I681509246778977644", label: 'IDG', value: ['VC', 'PE'], area: '中国大陆', avatar: 'http://file.ceccapitalgroup.com/logo/investment/8c8a7bd8ab9687368c5ea5013889f391.jpg', description: '可以领投,本地决策' },
  { id: "I209863421753813906", label: '中信证券', value: ['VC'], area: '中国大陆', avatar: 'http://file.ceccapitalgroup.com/logo/investment/48cc5ed0d537922e274282b6e942c6ec.jpg', description: '可以领投' },
  { id: "I468219034574926474", label: '华泰证券', value: ['VC'], area: '中国大陆', avatar: 'http://file.ceccapitalgroup.com/investment/2022-07-13/1657687569414.png', description: '可以领投' },
  { id: "I956709284137669656", label: '君联资本', value: ['VC', 'PE'], area: '中国大陆', avatar: 'http://file.ceccapitalgroup.com/logo/investment/1b8a399c769fdae29ec87e6dc4119482.jpg', description: '可以领投' },
]

const lps = [
  { label: '上汽集团', value: ['上市公司', '国有企业'], description: '上海市' },
  { label: '泰康保险', value: ['银行/保险/信托'], description: '北京市' },
  { label: '建银国际', value: ['VC/PE投资机构', '银行/保险/信托'], description: '' },
  { label: '茅台基金', value: ['国有企业'], description: '' },
  { label: '德胜集团', value: ['民营企业'], description: '' },
]

const industryOptions = [
  {
    "label": "药和生物科技",
    "value": "Lable_comindu_yk2_001",
  },
  {
    "label": "医疗器械",
    "value": "Lable_comindu_yk2_002",
  },
  {
    "label": "医疗和健康服务",
    "value": "Lable_comindu_yk2_003",
  },
  {
    "label": "数字与AI健康",
    "value": "Lable_comindu_yk2_004",
  },
  {
    "label": "合成生物",
    "value": "Lable_comindu_yk2_022",
  },
  {
    "label": "元宇宙",
    "value": "Lable_comindu_yk2_005",
  },
  {
    "label": "媒体与娱乐",
    "value": "Lable_comindu_yk2_006",
  },
  {
    "label": "硬科技",
    "value": "Lable_comindu_yk2_007",
  },
  {
    "label": "软科技",
    "value": "Lable_comindu_yk2_008",
  },
  {
    "label": "碳中和与新能源",
    "value": "Lable_comindu_yk2_009",
  },
  {
    "label": "新材料及技术",
    "value": "Lable_comindu_yk2_010",
  },
  {
    "label": "区块链",
    "value": "Lable_comindu_yk2_011",
  },
  {
    "label": "食品与农业科技",
    "value": "Lable_comindu_yk2_012",
  },
  {
    "label": "新品牌",
    "value": "Lable_comindu_yk2_013",
  },
  {
    "label": "新零售",
    "value": "Lable_comindu_yk2_014",
  },
  {
    "label": "潮流文化",
    "value": "Lable_comindu_yk2_015",
  },
  {
    "label": "健康生活方式",
    "value": "Lable_comindu_yk2_016",
  },
  {
    "label": "智能硬件",
    "value": "Lable_comindu_yk2_017",
  },
  {
    "label": "新服务及新体验",
    "value": "Lable_comindu_yk2_018",
  },
  {
    "label": "教育",
    "value": "Lable_comindu_yk2_019",
  },
  {
    "label": "金融服务",
    "value": "Lable_comindu_yk2_020",
  },
  {
    "label": "其他",
    "value": "Lable_comindu_yk2_021",
  }
]


class EmptyPopup extends BaseExtractPopup {
  renderBodyContent() {
    const { metadata: { title } } = this.props
    return (
      <div>DEMO演示</div>
    )
  }
}


class EnterpriseList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      _type: props.type, //LP GP COMPANY
      _loading: false,
      _dataList: [],
      _pageSize: 30,
      _pageNum: 1,
      _filter: {},
      _total: 0,
    }
  }
  componentDidMount() {
    const { _pageSize, _pageNum, _filter } = this.state
    this.fetchData(_pageNum, _pageSize, _filter)
  }

  componentWillReceiveProps(props) {
    const { _type } = this.state
    if (_type != props.type) {
      this.setState({
        _type: props.type,
      }, () => {
        this.fetchData()
      })
    }

  }

  fetchData(pageNuber = 1, pageSize = 30, pageFilter = {}) {
    const { _loading } = this.state
    const { fetchDataAfter } = this.props
    if (_loading) {
      return
    }

    this.setState({ _loading: true })

    fetchEnterpriseList(pageNuber, pageSize, pageFilter).then(res => {
      // console.log("fetchEnterpriseList===>>> 2", res)
      this.setState({
        _loading: false,
        _dataList: res,
        _total: _.size(res)
      }, () => {
        fetchDataAfter && fetchDataAfter({ total: _.size(res) })
      })
    }).catch(e => this.setState({ _loading: false }, () => Toast.show({ content: e })))


  }

  render() {
    const { _dataList } = this.state
    const { onItemClick } = this.props
    return (
      <div>
        <List style={{ '--border-top': 'none' }}>
          {_.map(_dataList, data => (
            <List.Item key={`list-${Math.random()}`} onClick={() => onItemClick && onItemClick(data)}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Avatar src={data['avatar']} style={{ marginRight: 10, border: "1px solid #eee", borderRadius: 4, padding: 2 }} fallback={<svg t="1724051007943" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2482" width="40" height="40"><path d="M1001.7 969.6H890.4V399.4c0-27.7-17.4-52.8-43.3-62.5L580 236.7c-14.9-5.6-31.1-5.5-45.7-0.4V76.6c0-21.9-10.7-42.4-28.7-54.9s-41-15.3-61.5-7.6L176.9 114.3c-25.9 9.7-43.3 34.9-43.3 62.5v792.8H22.3C10 969.6 0 979.6 0 991.9s10 22.3 22.3 22.3H1001.8c12.3 0 22.3-10 22.3-22.3s-10.1-22.3-22.4-22.3zM178.1 176.8c0-9.2 5.8-17.6 14.4-20.8L459.7 55.8c7-2.6 14.4-1.7 20.5 2.5s9.6 10.9 9.6 18.3v893H178.1V176.8z m356.2 792.8V299.3c0-7.4 3.5-14.1 9.6-18.3 6.1-4.2 13.6-5.2 20.5-2.5l267.1 100.2c8.6 3.2 14.4 11.6 14.4 20.8v570.2H534.3z" p-id="2483"></path><path d="M391.8 346.3H258.2c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3h133.6c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM748 479.9H614.4c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3H748c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM748 613.4H614.4c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3H748c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM391.8 613.4H258.2c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3h133.6c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3z" p-id="2484"></path></svg>} />
                <Space direction="vertical">
                  <Space>
                    {data['nameLabel']}
                    <span style={{ fontSize: 12, color: '#999' }}>{data['typeLabel']}</span>

                  </Space>

                </Space>
              </div>
            </List.Item>
          ))}
        </List>
      </div>
    )
  }
}
/**GP/LP */
class EnterpriseLPSearchBar extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      // 是否加载中
      _loading: false,
      // 是否存在下一页数据
      _hasMore: true,
      // 当前数据集合
      _dataList: [],
      // 数据总数量
      _total: 0,
      // 查询条件
      _filter: {
        typesValue: [],

        tranckValue: [],
        industryValue: [],

        transactionDateValue: [],
        transactionRoundValue: [],
        transactionScalValue: [],
        transactionCurrencyValue: [],
      },
      //基本信息
      _identityOptions:[
        {label: "GP", value: "GP"}, 
        {label: "LP", value: "LP"}, 
      ],
      _typesOptions: [
        { label: '种子及天使', value: '种子及天使' },
        { label: 'VC', value: 'VC' },
        { label: 'PE', value: 'PE' },
        { label: '并购', value: '并购' },
        { label: '主权', value: '主权' },
        { label: '对冲', value: '对冲' },
        { label: '政府引导', value: '政府引导' },
        { label: '一二级联动', value: '一二级联动' },
        { label: '母基金', value: '母基金' },
        { label: 'S资产或S份额', value: 'S资产或S份额' },
      ],
      _assetsOptions: [
        { label: '股权基金', value: '股权基金' },
        { label: '债权基金', value: '债权基金' },
        { label: '夹层基金及股债结合', value: '夹层基金及股债结合' },
        { label: '加密币', value: '加密币' },
      ],
      _areaOptions: [
        { label: '中国', value: '中国' },
        { label: '北美', value: '北美' },
        { label: '欧洲', value: '欧洲' },
        { label: '东南亚', value: '东南亚' },
        { label: '中东', value: '中东' },
        { label: '日韩', value: '日韩' },
        { label: '海外其他', value: '海外其他' },
      ],
      //赛道
      _tranckOptions: industryOptions,

      //交易相关
      _transactionDateOptions: [
        { label: '近30天', value: '近30天' },
        { label: '近60天', value: '近60天' },
        { label: '近90天', value: '近90天' },
        { label: '近180天', value: '近180天' },
      ],
      _transactionRoundOptions: [
        { label: '天使轮', value: '天使轮' },
        { label: 'A轮', value: 'A轮' },
        { label: 'B轮', value: 'B轮' },
        { label: 'C轮', value: 'C轮' },
        { label: 'D轮', value: 'D轮' },
        { label: 'IPO', value: 'IPO' },
        { label: '并购', value: '并购' },
      ],
      _transactionScalOptions: [
        { label: '未透露', value: '未透露' },
        { label: '数十万', value: '数十万' },
        { label: '数百万', value: '数百万' },
        { label: '数千万', value: '数千万' },
        { label: '亿元及以上', value: '亿元及以上' },
      ],
      _transactionCurrencyOptions: [
        { label: '人民币', value: '人民币' },
        { label: '美元', value: '美元' },
        { label: '其他币种', value: '其他币种' },
      ],

      _investmentVehicleOptions: [],



      _baseInfoBarValue: "identity",
      _trackIndustryBarValue: "industry",
      _transactionCurrencyBarValue: "investmentVehicle",
      _moreBarValue: "financingRange",
    }
    this.dropdownRef = createRef()
    this.dropdownOptions = [
      { label: "基本信息", value: "baseInfo", filterKey: ['identityValue', 'typeValue', 'assetsValue', 'areaValue', 'currencyValue', 'singleInvestmentValue'] },
      { label: "行业", value: "Track/Industry", filterKey: ['tranckValue', 'industryValue'] },
      { label: "投资需求", value: "transaction", filterKey: ['investmentVehicleValue', 'investmentModeValue', 'investmentTypeValue', 'investmentAttributeValue', 'investmentMarketValue', 'investmentStatusValue', 'investmentDemandValue'] },
      // { label: "融资需求", value: "more", 
      //   filterKey: [
      //     'financingRangeValue', 'financingScaleValue', 'financingReturnInvestmentValue',
      //     'financingNewValuationValue', 'financingOldValuationValue', 'financingFaValue',
      //     'financingDemandValue'
      //   ] },
    ]

    this.baseInfoOptions = [
      {label: "身份", value: "identity", filterKey: ['identityValue']},
      {label: "类型", value: "type", filterKey: ['typeValue']},
      {label: "资产类别", value: "assets", filterKey: ['assetsValue']},
      {label: "地区", value: "area", filterKey: ['areaValue']},
      { label: "交易时间", value: "transactionDate", filterKey: ['transactionDateValue'] },
      { label: "投资轮次", value: "transactionRound", filterKey: ['transactionRoundValue'] },
      { label: "单笔投资金额", value: "transactionScal", filterKey: ['transactionScalValue'] },
      { label: "币种", value: "transactionCurrency", filterKey: ['transactionCurrencyValue'] },
    
    ]

    this.trackIndustryOptions = [
      // { label: "赛道", value: "tranck", filterKey: ['tranckValue'] },
      { label: "行业", value: "industry", filterKey: ['industryValue'] },
    ]

    this.transactionOptions = [
      { label: "投资载体", value: "investmentVehicle", filterKey: ['investmentVehicleValue'] },
      { label: "投资方式", value: "investmentMode", filterKey: ['investmentModeValue'] },
      { label: "资产类别", value: "investmentType", filterKey: ['investmentTypeValue'] },
      { label: "投资标的属性", value: "investmentAttribute", filterKey: ['investmentAttributeValue'] },
      { label: "投资地理市场", value: "investmentMarket", filterKey: ['investmentMarketValue'] },
      { label: "投资当前状态", value: "investmentStatus", filterKey: ['investmentStatusValue'] },
      { label: "投资其他需求", value: "investmentDemand", filterKey: ['investmentDemandValue'] },

    ]

    this.moreOptions = [
      { label: "融资范围", value: "financingRange", filterKey: ['financingRangeValue'] },
      { label: "计划融资规模", value: "financingScale", filterKey: ['financingScaleValue'] },
      { label: "老股退出需求", value: "financingReturnInvestment", filterKey: ['financingReturnInvestmentValue'] },
      { label: "新股目标估值", value: "financingNewValuation", filterKey: ['financingNewValuationValue'] },
      { label: "老股目标估值", value: "financingOldValuation", filterKey: ['financingOldValuationValue'] },
      { label: "融资是否有FA", value: "financingFa", filterKey: ['financingFaValue'] },
      { label: "融资其他需求", value: "financingDemand", filterKey: ['financingDemandValue'] },
    ]
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
    Toast.show({ content: "DEMO演示数据", maskStyle:{zIndex: 999999} })
  }

  handleClearClick(key, data, event) {
    const { _filter } = this.state
    const { fetchDataList, total } = this.props
    let obj = { ..._filter }
    console.log("handleClearClick", key, data, obj[key])
    switch (key) {

      case 'moreKeyWordValue':
        obj[key] = null
        break

      case 'transactionDateValue':
      case 'transactionDateValue':
      case 'transactionRoundValue':
      case 'transactionScalValue':
      case 'transactionCurrencyValue':
      case 'industryValue':
      case 'trackValue':
      case 'typesValue':
        obj[key] = _.filter(obj[key], ({ label, value }) => value != data)
        break
      case 'baseInfo':
      case 'Track/Industry':
      case 'transaction':
      case 'more':
      case 'all':
        obj = {}
        break

    }
    this.setState({ _filter: obj }, () => {
      if (_.includes(['baseInfo', 'Track/Industry', 'transaction', 'more'], key)) {
        if (this.dropdownRef && this.dropdownRef.current) {
          this.dropdownRef.current.closeDropdown()
        }
      } else {
        fetchDataList && fetchDataList()
      }
    })
  }

  getSearchTagOptions(filter) {
    let tagOptions = []
    
    return tagOptions
  }

  render() {
    const { _filter } = this.state
    const { fetchDataList, total } = this.props

    let filterTargetOptions = []
    return (
      <TSearchView
        ref={this.dropdownRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          if (this.dropdownRef && this.dropdownRef.current) {
            this.dropdownRef.current.closeDropdown()
          }
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { fetchDataList && fetchDataList() } }}
        //数据总数
        total={total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={this.getSearchTagOptions(_filter)}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  renderSearchMenu(key) {
    let content = <div></div>
    switch (key) {
      //交易日期
      case 'baseInfo':
        content = this.renderBaseInfo()
        break
      //交易对象
      case 'Track/Industry':
        content = this.renderTrackIndustry()
        break
      case 'transaction':
        content = this.renderTransaction()
        break
      case 'more':
        content = this.renderMore()
        break
    }
    return content
  }

  renderBaseInfo() {
    const { 
      _baseInfoBarValue, _filter,
      _identityOptions, _typesOptions, _assetsOptions, _areaOptions,
      _transactionDateOptions, _transactionRoundOptions, _transactionScalOptions, _transactionCurrencyOptions
   } = this.state



    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.baseInfoOptions}
        sideBarValue={_baseInfoBarValue}
        onSideBarChange={val => {
          this.setState({ _baseInfoBarValue: val,})
        }}
      >
        {
            _baseInfoBarValue == "identity" && 
            (
                <div className='cec-mobile-component-dropdown-page-box padding'>
                    <Selector multiple={true}
                        style={{ '--padding': '4px', fontSize: '12px' }}
                        columns={2}
                        options={_identityOptions}
                        value={_.map(_.get(_filter, 'identityValue', []), 'value')} 
                        onChange={val=> this.setFilterValue('identityValue', val, _identityOptions)}
                    />
                </div>
            )
            
        }
        {
            _baseInfoBarValue == "type" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_typesOptions}
                            value={_.map(_.get(_filter, 'typeValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('typeValue', val, _typesOptions)}
                        />
                    </div>
                )
            )
        }
        {
            _baseInfoBarValue == "assets" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_assetsOptions}
                            value={_.map(_.get(_filter, 'assetsValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('assetsValue', val, _assetsOptions)}
                        />
                    </div>
                )
            )
        }
        {
            _baseInfoBarValue == "area" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_areaOptions}
                            value={_.map(_.get(_filter, 'areaValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('areaValue', val, _areaOptions)}
                        />
                    </div>
                )
            )
        }
        {_baseInfoBarValue == "transactionDate" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionDateOptions}
              value={_.map(_.get(_filter, 'transactionDateValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionDateValue', val, _transactionDateOptions)}
            />
          </div>
        )}
        {_baseInfoBarValue == "transactionRound" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionRoundOptions}
              value={_.map(_.get(_filter, 'transactionRoundValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionRoundValue', val, _transactionRoundOptions)}
            />
          </div>
        )}
        {_baseInfoBarValue == "transactionScal" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionScalOptions}
              value={_.map(_.get(_filter, 'transactionScalValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionScalValue', val, _transactionScalOptions)}
            />
          </div>
        )}
        {_baseInfoBarValue == "transactionCurrency" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionCurrencyOptions}
              value={_.map(_.get(_filter, 'transactionCurrencyValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionCurrencyValue', val, _transactionCurrencyOptions)}
            />
          </div>
        )}

      </TSideBarBox>
    )
  }

  renderTrackIndustry() {
    const { 
      _tranckOptions, _filter, _trackIndustryBarValue, 
      _typesOptions
    } = this.state
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.trackIndustryOptions}
        sideBarValue={_trackIndustryBarValue}
        onSideBarChange={val => {
          this.setState({ _trackIndustryBarValue: val, _filter: { ..._filter, } })
        }}
      >

        {
            _trackIndustryBarValue == "tranck" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_tranckOptions}
                            value={_.map(_.get(_filter, 'tranckValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('tranckValue', val, _tranckOptions)}
                        />
                    </div>
                )
            )
        }
        {
          _trackIndustryBarValue == "industry" && (
            (
              <div className='cec-mobile-component-dropdown-page-box'>
                <IndustryView
                  multiple={true}
                  values={_filter['industryValue'] ? _.map(_filter['industryValue'], 'value') : []}
                  onChange={val => this.setFilterValue('industryValue', onIndustryItemClick({ value: val }))}
                />

              </div>
            )
          )
        }

      </TSideBarBox>
    )
  }

  renderTransaction() {
    const { _transactionCurrencyBarValue, _filter, _transactionDateOptions, _transactionRoundOptions, _transactionScalOptions, _transactionCurrencyOptions } = this.state
    
    let investmentVehicleOptions = [{ value: '企业投资（直接投资部）', label: '企业投资（直接投资部） ' }, { value: '企业发起设立的基金', label: '企业发起设立的基金 ' }, { value: '以Co-GP身份参与的外部基金', label: '以Co-GP身份参与的外部基金 ' }]
    let investmentModeOptions = [{ value: '直接投资', label: '直接投资' }, { value: '投资GP', label: '投资GP' }, { value: '投资FOF', label: '投资FOF' }, { value: '投资S资产或S份额', label: '投资S资产或S份额' },]     
    let investmentTypeOptions = [{ value: '股权', label: '股权 ' }, { value: '债权', label: '债权' }, { value: '夹层/股债结合', label: '夹层/股债结合' }, { value: '房地产', label: '房地产' }, { value: '加密币', label: '加密币' }, { value: '艺术品', label: '艺术品' }]
    let investmentAttributeOptions = [{ value: '单个项目', label: '单个项目' }, { value: '单一项目基金', label: '单一项目基金' }, { value: '多项目', label: '多项目组合' }, { value: '盲池基金', label: '盲池基金' }, { value: '现有基金份额', label: '现有基金份额' }, { value: '接续基金', label: '接续基金' }]
    let investmentMarketOptions = [
      { value: '中国大陆', label: '中国大陆 ' },
      { value: '港澳台', label: '港澳台 ' },
      { value: '东南亚', label: '东南亚' },
      { value: '日韩', label: '日韩' },
      { value: '中东', label: '中东' },
      { value: '北美', label: '北美' },
      { value: '欧洲', label: '欧洲' },
      { value: '非洲', label: '非洲' },
      { value: '海外其他', label: '海外其他' }
    ]

    let investmentStatusOptions = [{ value: '积极', label: '积极' }, { value: '保守', label: '保守' }, { value: '停滞', label: '停滞' }]
    
    let investmentDemandOptions = [{ value: '是', label: '是' }, { value: '否', label: '否' }]
    
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.transactionOptions}
        sideBarValue={_transactionCurrencyBarValue}
        onSideBarChange={val => {
          this.setState({ _transactionCurrencyBarValue: val })
        }}
      >
        {_transactionCurrencyBarValue == "investmentVehicle" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentVehicleOptions}
              value={_.map(_.get(_filter, 'investmentVehicleValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentVehicleValue', val, investmentVehicleOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentMode" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentModeOptions}
              value={_.map(_.get(_filter, 'investmentModeValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentModeValue', val, investmentModeOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentType" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentTypeOptions}
              value={_.map(_.get(_filter, 'investmentTypeValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentTypeValue', val, investmentTypeOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentAttribute" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentAttributeOptions}
              value={_.map(_.get(_filter, 'investmentAttributeValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentAttributeValue', val, investmentAttributeOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentMarket" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentMarketOptions}
              value={_.map(_.get(_filter, 'investmentMarketValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentMarketValue', val, investmentMarketOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentStatus" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentStatusOptions}
              value={_.map(_.get(_filter, 'investmentStatusValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentStatusValue', val, investmentStatusOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentDemand" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentDemandOptions}
              value={_.map(_.get(_filter, 'investmentDemandValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentDemandValue', val, investmentDemandOptions)}
            />
          </div>
        )}
      </TSideBarBox>
    )
  }

  renderMore() {
    const { _moreBarValue, _filter,  } = this.state

    let financingRangeOptions = [{ value: '公司整体融资', label: '公司整体融资 ' }, { value: '业务板块融资', label: '业务板块融资 ' }]


    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.moreOptions}
        sideBarValue={_moreBarValue}
        onSideBarChange={val => {
          this.setState({ _moreBarValue: val, })
        }}
      >
        {_moreBarValue == "financingRange" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={financingRangeOptions}
              value={_.map(_.get(_filter, 'financingRangeValue', []), 'value')}
              onChange={val => this.setFilterValue('financingRangeValue', val, financingRangeOptions)}
            />
          </div>
        )}
        {_moreBarValue == "financingScale" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={FINANCING_SCALE_OPTIONS}
              value={_.map(_.get(_filter, 'financingScaleValue', []), 'value')}
              onChange={val => this.setFilterValue('financingScaleValue', val, FINANCING_SCALE_OPTIONS)}
            />
          </div>
        )}
        {_moreBarValue == "financingReturnInvestment" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                [ {label: "是", value: "是"}, {label: "否", value: "否"}]
              }
              value={_.map(_.get(_filter, 'financingReturnInvestmentValue', []), 'value')}
              onChange={val => this.setFilterValue('financingReturnInvestmentValue', val, [ {label: "是", value: "是"}, {label: "否", value: "否"}])}
            />
          </div>
        )}
        {_moreBarValue == "financingNewValuation" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                FINANCING_SCALE_OPTIONS
              }
              value={_.map(_.get(_filter, 'financingNewValuationValue', []), 'value')}
              onChange={val => this.setFilterValue('financingNewValuationValue', val, FINANCING_SCALE_OPTIONS)}
            />
          </div>
        )}
        {_moreBarValue == "financingOldValuation" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                FINANCING_SCALE_OPTIONS
              }
              value={_.map(_.get(_filter, 'financingOldValuationValue', []), 'value')}
              onChange={val => this.setFilterValue('financingOldValuationValue', val, FINANCING_SCALE_OPTIONS)}
            />
          </div>
        )}
        {_moreBarValue == "financingFa" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                [ {label: "是", value: "是"}, {label: "否", value: "否"}]
              }
              value={_.map(_.get(_filter, 'financingFaValue', []), 'value')}
              onChange={val => this.setFilterValue('financingFaValue', val, [ {label: "是", value: "是"}, {label: "否", value: "否"}])}
            />
          </div>
        )}
        {_moreBarValue == "financingDemand" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                [ {label: "是", value: "是"}, {label: "否", value: "否"}]
              }
              value={_.map(_.get(_filter, 'financingDemandValue', []), 'value')}
              onChange={val => this.setFilterValue('financingDemandValue', val, [ {label: "是", value: "是"}, {label: "否", value: "否"}])}
            />
          </div>
        )}


      </TSideBarBox>
    )
  }

}

/**Company */
class EnterpriseCompanySearchBar extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      // 是否加载中
      _loading: false,
      // 是否存在下一页数据
      _hasMore: true,
      // 当前数据集合
      _dataList: [],
      // 数据总数量
      _total: 0,
      // 查询条件
      _filter: {
        typesValue: [],

        tranckValue: [],
        industryValue: [],

        transactionDateValue: [],
        transactionRoundValue: [],
        transactionScalValue: [],
        transactionCurrencyValue: [],
      },
      //基本信息
      _identityOptions:[
        {label: "GP", value: "GP"}, 
        {label: "LP", value: "LP"}, 
      ],
      _typesOptions: [
        { label: '上市公司', value: '上市公司' },
        { label: '大企业集团', value: '大企业集团' },
        { label: '跨国公司', value: '跨国公司' },
        { label: '创业公司', value: '创业公司' },
        // { label: '主权', value: '主权' },
        // { label: '对冲', value: '对冲' },
        // { label: '政府引导', value: '政府引导' },
        // { label: '一二级联动', value: '一二级联动' },
        // { label: '母基金', value: '母基金' },
        // { label: 'S资产或S份额', value: 'S资产或S份额' },
      ],
      _assetsOptions: [
        { label: '股权基金', value: '股权基金' },
        { label: '债权基金', value: '债权基金' },
        { label: '夹层基金及股债结合', value: '夹层基金及股债结合' },
        { label: '加密币', value: '加密币' },
      ],
      _areaOptions: [
        { label: '中国', value: '中国' },
        // { label: '北美', value: '北美' },
        // { label: '欧洲', value: '欧洲' },
        // { label: '东南亚', value: '东南亚' },
        // { label: '中东', value: '中东' },
        // { label: '日韩', value: '日韩' },
        { label: '海外', value: '海外' },
      ],
      //赛道
      _tranckOptions: industryOptions,

      //交易相关
      _transactionDateOptions: [
        { label: '近30天', value: '近30天' },
        { label: '近60天', value: '近60天' },
        { label: '近90天', value: '近90天' },
        { label: '近180天', value: '近180天' },
      ],
      _transactionRoundOptions: [
        { label: '天使轮', value: '天使轮' },
        { label: 'A轮', value: 'A轮' },
        { label: 'B轮', value: 'B轮' },
        { label: 'C轮', value: 'C轮' },
        { label: 'D轮', value: 'D轮' },
        { label: 'IPO', value: 'IPO' },
        { label: '并购', value: '并购' },
      ],
      _transactionScalOptions: [
        { label: '未透露', value: '未透露' },
        { label: '数十万', value: '数十万' },
        { label: '数百万', value: '数百万' },
        { label: '数千万', value: '数千万' },
        { label: '亿元及以上', value: '亿元及以上' },
      ],
      _transactionCurrencyOptions: [
        { label: '人民币', value: '人民币' },
        { label: '美元', value: '美元' },
        { label: '其他币种', value: '其他币种' },
      ],

      _investmentVehicleOptions: [],



      _baseInfoBarValue: "area",
      _trackIndustryBarValue: "industry",
      _transactionCurrencyBarValue: "investmentVehicle",
      _moreBarValue: "financingRange",
    }
    this.dropdownRef = createRef()
    this.dropdownOptions = [
      { label: "基本信息", value: "baseInfo", filterKey: ['identityValue', 'typeValue', 'assetsValue', 'areaValue', 'currencyValue', 'singleInvestmentValue'] },
      { label: "行业", value: "Track/Industry", filterKey: ['tranckValue', 'industryValue'] },
      // { label: "投资需求", value: "transaction", filterKey: ['investmentVehicleValue', 'investmentModeValue', 'investmentTypeValue', 'investmentAttributeValue', 'investmentMarketValue', 'investmentStatusValue', 'investmentDemandValue'] },
      { label: "融资需求", value: "more", 
        filterKey: [
          'financingRangeValue', 'financingScaleValue', 'financingReturnInvestmentValue',
          'financingNewValuationValue', 'financingOldValuationValue', 'financingFaValue',
          'financingDemandValue'
        ] },
    ]

    this.baseInfoOptions = [
      // {label: "身份", value: "identity", filterKey: ['identityValue']},
      // {label: "资产类别", value: "assets", filterKey: ['assetsValue']},
      // { label: "交易时间", value: "transactionDate", filterKey: ['transactionDateValue'] },
      {label: "地区", value: "area", filterKey: ['areaValue']},
      {label: "类型", value: "type", filterKey: ['typeValue']},
      { label: "融资轮次", value: "transactionRound", filterKey: ['transactionRoundValue'] },
      { label: "估值", value: "transactionScal", filterKey: ['transactionScalValue'] },
      // { label: "币种", value: "transactionCurrency", filterKey: ['transactionCurrencyValue'] },
    
    ]

    this.trackIndustryOptions = [
      // { label: "赛道", value: "tranck", filterKey: ['tranckValue'] },
      { label: "行业", value: "industry", filterKey: ['industryValue'] },
    ]

    this.transactionOptions = [
      { label: "投资载体", value: "investmentVehicle", filterKey: ['investmentVehicleValue'] },
      { label: "投资方式", value: "investmentMode", filterKey: ['investmentModeValue'] },
      { label: "资产类别", value: "investmentType", filterKey: ['investmentTypeValue'] },
      { label: "投资标的属性", value: "investmentAttribute", filterKey: ['investmentAttributeValue'] },
      { label: "投资地理市场", value: "investmentMarket", filterKey: ['investmentMarketValue'] },
      { label: "投资当前状态", value: "investmentStatus", filterKey: ['investmentStatusValue'] },
      { label: "投资其他需求", value: "investmentDemand", filterKey: ['investmentDemandValue'] },

    ]

    this.moreOptions = [
      { label: "融资范围", value: "financingRange", filterKey: ['financingRangeValue'] },
      { label: "计划融资规模", value: "financingScale", filterKey: ['financingScaleValue'] },
      { label: "老股退出需求", value: "financingReturnInvestment", filterKey: ['financingReturnInvestmentValue'] },
      { label: "新股目标估值", value: "financingNewValuation", filterKey: ['financingNewValuationValue'] },
      { label: "老股目标估值", value: "financingOldValuation", filterKey: ['financingOldValuationValue'] },
      { label: "融资是否有FA", value: "financingFa", filterKey: ['financingFaValue'] },
      { label: "融资其他需求", value: "financingDemand", filterKey: ['financingDemandValue'] },
    ]
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
    Toast.show({ content: "DEMO演示数据", maskStyle:{zIndex: 999999} })
  }

  handleClearClick(key, data, event) {
    const { _filter } = this.state
    const { fetchDataList, total } = this.props
    let obj = { ..._filter }
    console.log("handleClearClick", key, data, obj[key])
    switch (key) {

      case 'moreKeyWordValue':
        obj[key] = null
        break

      case 'transactionDateValue':
      case 'transactionDateValue':
      case 'transactionRoundValue':
      case 'transactionScalValue':
      case 'transactionCurrencyValue':
      case 'industryValue':
      case 'trackValue':
      case 'typesValue':
        obj[key] = _.filter(obj[key], ({ label, value }) => value != data)
        break
      case 'baseInfo':
      case 'Track/Industry':
      case 'transaction':
      case 'more':
      case 'all':
        obj = {}
        break

    }
    this.setState({ _filter: obj }, () => {
      if (_.includes(['baseInfo', 'Track/Industry', 'transaction', 'more'], key)) {
        if (this.dropdownRef && this.dropdownRef.current) {
          this.dropdownRef.current.closeDropdown()
        }
      } else {
        fetchDataList && fetchDataList()
      }
    })
  }

  getSearchTagOptions(filter) {
    let tagOptions = []
    
    return tagOptions
  }

  render() {
    const { _filter } = this.state
    const { fetchDataList, total } = this.props

    let filterTargetOptions = []
    return (
      <TSearchView
        ref={this.dropdownRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          if (this.dropdownRef && this.dropdownRef.current) {
            this.dropdownRef.current.closeDropdown()
          }
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { fetchDataList && fetchDataList() } }}
        //数据总数
        total={total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={this.getSearchTagOptions(_filter)}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  renderSearchMenu(key) {
    let content = <div></div>
    switch (key) {
      //交易日期
      case 'baseInfo':
        content = this.renderBaseInfo()
        break
      //交易对象
      case 'Track/Industry':
        content = this.renderTrackIndustry()
        break
      case 'transaction':
        content = this.renderTransaction()
        break
      case 'more':
        content = this.renderMore()
        break
    }
    return content
  }

  renderBaseInfo() {
    const { 
      _baseInfoBarValue, _filter,
      _identityOptions, _typesOptions, _assetsOptions, _areaOptions,
      _transactionDateOptions, _transactionRoundOptions, _transactionScalOptions, _transactionCurrencyOptions
   } = this.state



    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.baseInfoOptions}
        sideBarValue={_baseInfoBarValue}
        onSideBarChange={val => {
          this.setState({ _baseInfoBarValue: val,})
        }}
      >
        {
            _baseInfoBarValue == "identity" && 
            (
                <div className='cec-mobile-component-dropdown-page-box padding'>
                    <Selector multiple={true}
                        style={{ '--padding': '4px', fontSize: '12px' }}
                        columns={2}
                        options={_identityOptions}
                        value={_.map(_.get(_filter, 'identityValue', []), 'value')} 
                        onChange={val=> this.setFilterValue('identityValue', val, _identityOptions)}
                    />
                </div>
            )
            
        }
        {
            _baseInfoBarValue == "type" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_typesOptions}
                            value={_.map(_.get(_filter, 'typeValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('typeValue', val, _typesOptions)}
                        />
                    </div>
                )
            )
        }
        {
            _baseInfoBarValue == "assets" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_assetsOptions}
                            value={_.map(_.get(_filter, 'assetsValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('assetsValue', val, _assetsOptions)}
                        />
                    </div>
                )
            )
        }
        {
            _baseInfoBarValue == "area" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_areaOptions}
                            value={_.map(_.get(_filter, 'areaValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('areaValue', val, _areaOptions)}
                        />
                    </div>
                )
            )
        }
        {_baseInfoBarValue == "transactionDate" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionDateOptions}
              value={_.map(_.get(_filter, 'transactionDateValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionDateValue', val, _transactionDateOptions)}
            />
          </div>
        )}
        {_baseInfoBarValue == "transactionRound" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionRoundOptions}
              value={_.map(_.get(_filter, 'transactionRoundValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionRoundValue', val, _transactionRoundOptions)}
            />
          </div>
        )}
        {_baseInfoBarValue == "transactionScal" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionScalOptions}
              value={_.map(_.get(_filter, 'transactionScalValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionScalValue', val, _transactionScalOptions)}
            />
          </div>
        )}
        {_baseInfoBarValue == "transactionCurrency" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={_transactionCurrencyOptions}
              value={_.map(_.get(_filter, 'transactionCurrencyValue', []), 'value')}
              onChange={val => this.setFilterValue('transactionCurrencyValue', val, _transactionCurrencyOptions)}
            />
          </div>
        )}

      </TSideBarBox>
    )
  }

  renderTrackIndustry() {
    const { 
      _tranckOptions, _filter, _trackIndustryBarValue, 
      _typesOptions
    } = this.state
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.trackIndustryOptions}
        sideBarValue={_trackIndustryBarValue}
        onSideBarChange={val => {
          this.setState({ _trackIndustryBarValue: val, _filter: { ..._filter, } })
        }}
      >

        {
            _trackIndustryBarValue == "tranck" && (
                (
                    <div className='cec-mobile-component-dropdown-page-box padding'>
                        <Selector multiple={true}
                            style={{ '--padding': '4px', fontSize: '12px' }}
                            columns={2}
                            options={_tranckOptions}
                            value={_.map(_.get(_filter, 'tranckValue', []), 'value')} 
                            onChange={val=> this.setFilterValue('tranckValue', val, _tranckOptions)}
                        />
                    </div>
                )
            )
        }
        {
          _trackIndustryBarValue == "industry" && (
            (
              <div className='cec-mobile-component-dropdown-page-box'>
                <IndustryView
                  multiple={true}
                  values={_filter['industryValue'] ? _.map(_filter['industryValue'], 'value') : []}
                  onChange={val => this.setFilterValue('industryValue', onIndustryItemClick({ value: val }))}
                />

              </div>
            )
          )
        }

      </TSideBarBox>
    )
  }

  renderTransaction() {
    const { _transactionCurrencyBarValue, _filter, _transactionDateOptions, _transactionRoundOptions, _transactionScalOptions, _transactionCurrencyOptions } = this.state
    
    let investmentVehicleOptions = [{ value: '企业投资（直接投资部）', label: '企业投资（直接投资部） ' }, { value: '企业发起设立的基金', label: '企业发起设立的基金 ' }, { value: '以Co-GP身份参与的外部基金', label: '以Co-GP身份参与的外部基金 ' }]
    let investmentModeOptions = [{ value: '直接投资', label: '直接投资' }, { value: '投资GP', label: '投资GP' }, { value: '投资FOF', label: '投资FOF' }, { value: '投资S资产或S份额', label: '投资S资产或S份额' },]     
    let investmentTypeOptions = [{ value: '股权', label: '股权 ' }, { value: '债权', label: '债权' }, { value: '夹层/股债结合', label: '夹层/股债结合' }, { value: '房地产', label: '房地产' }, { value: '加密币', label: '加密币' }, { value: '艺术品', label: '艺术品' }]
    let investmentAttributeOptions = [{ value: '单个项目', label: '单个项目' }, { value: '单一项目基金', label: '单一项目基金' }, { value: '多项目', label: '多项目组合' }, { value: '盲池基金', label: '盲池基金' }, { value: '现有基金份额', label: '现有基金份额' }, { value: '接续基金', label: '接续基金' }]
    let investmentMarketOptions = [
      { value: '中国大陆', label: '中国大陆 ' },
      { value: '港澳台', label: '港澳台 ' },
      { value: '东南亚', label: '东南亚' },
      { value: '日韩', label: '日韩' },
      { value: '中东', label: '中东' },
      { value: '北美', label: '北美' },
      { value: '欧洲', label: '欧洲' },
      { value: '非洲', label: '非洲' },
      { value: '海外其他', label: '海外其他' }
    ]

    let investmentStatusOptions = [{ value: '积极', label: '积极' }, { value: '保守', label: '保守' }, { value: '停滞', label: '停滞' }]
    
    let investmentDemandOptions = [{ value: '是', label: '是' }, { value: '否', label: '否' }]
    
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.transactionOptions}
        sideBarValue={_transactionCurrencyBarValue}
        onSideBarChange={val => {
          this.setState({ _transactionCurrencyBarValue: val })
        }}
      >
        {_transactionCurrencyBarValue == "investmentVehicle" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentVehicleOptions}
              value={_.map(_.get(_filter, 'investmentVehicleValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentVehicleValue', val, investmentVehicleOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentMode" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentModeOptions}
              value={_.map(_.get(_filter, 'investmentModeValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentModeValue', val, investmentModeOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentType" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentTypeOptions}
              value={_.map(_.get(_filter, 'investmentTypeValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentTypeValue', val, investmentTypeOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentAttribute" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentAttributeOptions}
              value={_.map(_.get(_filter, 'investmentAttributeValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentAttributeValue', val, investmentAttributeOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentMarket" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentMarketOptions}
              value={_.map(_.get(_filter, 'investmentMarketValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentMarketValue', val, investmentMarketOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentStatus" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentStatusOptions}
              value={_.map(_.get(_filter, 'investmentStatusValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentStatusValue', val, investmentStatusOptions)}
            />
          </div>
        )}
        {_transactionCurrencyBarValue == "investmentDemand" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={investmentDemandOptions}
              value={_.map(_.get(_filter, 'investmentDemandValue', []), 'value')}
              onChange={val => this.setFilterValue('investmentDemandValue', val, investmentDemandOptions)}
            />
          </div>
        )}
      </TSideBarBox>
    )
  }

  renderMore() {
    const { _moreBarValue, _filter,  } = this.state

    let financingRangeOptions = [{ value: '公司整体融资', label: '公司整体融资 ' }, { value: '业务板块融资', label: '业务板块融资 ' }]


    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.moreOptions}
        sideBarValue={_moreBarValue}
        onSideBarChange={val => {
          this.setState({ _moreBarValue: val, })
        }}
      >
        {_moreBarValue == "financingRange" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={financingRangeOptions}
              value={_.map(_.get(_filter, 'financingRangeValue', []), 'value')}
              onChange={val => this.setFilterValue('financingRangeValue', val, financingRangeOptions)}
            />
          </div>
        )}
        {_moreBarValue == "financingScale" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={FINANCING_SCALE_OPTIONS}
              value={_.map(_.get(_filter, 'financingScaleValue', []), 'value')}
              onChange={val => this.setFilterValue('financingScaleValue', val, FINANCING_SCALE_OPTIONS)}
            />
          </div>
        )}
        {_moreBarValue == "financingReturnInvestment" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                [ {label: "是", value: "是"}, {label: "否", value: "否"}]
              }
              value={_.map(_.get(_filter, 'financingReturnInvestmentValue', []), 'value')}
              onChange={val => this.setFilterValue('financingReturnInvestmentValue', val, [ {label: "是", value: "是"}, {label: "否", value: "否"}])}
            />
          </div>
        )}
        {_moreBarValue == "financingNewValuation" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                FINANCING_SCALE_OPTIONS
              }
              value={_.map(_.get(_filter, 'financingNewValuationValue', []), 'value')}
              onChange={val => this.setFilterValue('financingNewValuationValue', val, FINANCING_SCALE_OPTIONS)}
            />
          </div>
        )}
        {_moreBarValue == "financingOldValuation" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                FINANCING_SCALE_OPTIONS
              }
              value={_.map(_.get(_filter, 'financingOldValuationValue', []), 'value')}
              onChange={val => this.setFilterValue('financingOldValuationValue', val, FINANCING_SCALE_OPTIONS)}
            />
          </div>
        )}
        {_moreBarValue == "financingFa" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                [ {label: "是", value: "是"}, {label: "否", value: "否"}]
              }
              value={_.map(_.get(_filter, 'financingFaValue', []), 'value')}
              onChange={val => this.setFilterValue('financingFaValue', val, [ {label: "是", value: "是"}, {label: "否", value: "否"}])}
            />
          </div>
        )}
        {_moreBarValue == "financingDemand" && (
          <div className='cec-mobile-component-dropdown-page-box padding'>
            <Selector
              multiple
              style={{ '--padding': '4px', fontSize: '12px' }}
              columns={2}
              options={
                [ {label: "是", value: "是"}, {label: "否", value: "否"}]
              }
              value={_.map(_.get(_filter, 'financingDemandValue', []), 'value')}
              onChange={val => this.setFilterValue('financingDemandValue', val, [ {label: "是", value: "是"}, {label: "否", value: "否"}])}
            />
          </div>
        )}


      </TSideBarBox>
    )
  }

}

