
import React, { Fragment, PureComponent } from "react";
import { List } from "antd-mobile";
import { LeftOutline } from 'antd-mobile-icons'
import Layout from "../../../components/Layout";

export default class Download extends PureComponent {

  render() {
    return (
      <Layout head={(
        <div className="cec-update-entry-head">
          <div className="cec-update-entry-head-left" onClick={() => window.location.href = '/#/'} ><LeftOutline style={{ fontSize: '24px' }} /></div>
          <div className="cec-update-entry-head-center">下载</div>
          <div className="cec-update-entry-head-right"></div>
        </div>
      )}>
        <div style={{ borderTop: '2px solid #fff' }}>
          <List style={{ background: '#f0f0f0' }} header={<span>OA</span>}>
            <List.Item onClick={() => null}>工时报告</List.Item>
          </List>
          <List style={{ background: '#f0f0f0' }} header={<span>SIMS</span>}>
            <List.Item onClick={() => null}>目标追踪报告</List.Item>
            <List.Item onClick={() => null}>SIMS用量及排会报告</List.Item>
            <List.Item onClick={() => null}>本周会议汇总报告</List.Item>
            <List.Item onClick={() => null}>ECM周报</List.Item>
            <List.Item onClick={() => null}>个人严肃Pitch统计</List.Item>
          </List>
          <List style={{ background: '#f0f0f0' }} header={<span>项目</span>}>
            <List.Item onClick={() => null}>立项BD线索统计</List.Item>
            <List.Item onClick={() => null}>立项EL统计</List.Item>
            <List.Item onClick={() => null}>EL</List.Item>
            <List.Item onClick={() => null}>管道健康度报告</List.Item>
            <List.Item onClick={() => null}>投资人周报</List.Item>
            <List.Item onClick={() => null}>项目成本预估</List.Item>
          </List>
        </div>
      </Layout>
    )
  }
}