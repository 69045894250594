import _ from "lodash";
import request from "../../../../../../../api/HttpRequest";


/**
 * 获取融资/收购历史列表
 */
export async function FetchFinancingHistoryDataList({ code, pageNum = 1, pageSize = 2 }) {

  const payload = {
    base_comp_code: code,
    pageNum: pageNum <= 0 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/company/tradelist", payload)
    const total = response.length // response['total']
    const list = response // response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          id: item['base_trade_code'],
          code: item['base_comp_code'],
          round: item['round_name'],
          tradeDate: item['base_trade_date'],
          // isMA: ,
          // company: ,
          // buyerCompanys: ,
          // investments: ,
          // investors: ,
          // amountValue: ,
          // amountUnit: ,
          // amountCurrency: ,
          // equityRatio: ,
          // postValue: ,
          // postUnit: ,
          // postCurrency: ,
          modifyDate: item['modifyDate'],
          modidyUser: item['updateusername'],
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 获取融资/收购历史详情
 * @param {*} id 
 */
export async function FetchFinancingHistoryDataDetail(id) {
  try {
    const response = await request("POST", "/cec-sims-server/trade/detail_trade", { base_trade_code: id })
    console.log("FetchFinancialDataDetail >>>>", response)

  } catch (error) {
    throw error
  }
}