import React, { createRef, Fragment } from "react";
import _, { times } from "lodash";
import { Button, DotLoading, Toast } from "antd-mobile";
import MultiplePathNavigator from '../../../../components/MultiplePathNavigator'
import BaseExtractPopup from "../../../../components/BaseExtractPopup";
import { ProjectDetailPopup } from '../../../QueryEntry/components/ProjectListViewPopup'
import {
  COMPONENT_OPTION_LIST,
  COMPONENT_OPTION_LIST_MULTIPLE_CASCADER_PATHS,
} from "./configuration"
import './index.css'

/**
 * 项目更新
 */
export default class DealUpdate extends BaseExtractPopup {

  constructor(props) {
    super(props);
    this.state = {
      _metadatas: [...COMPONENT_OPTION_LIST],
      _sourceData: { ...COMPONENT_OPTION_LIST_MULTIPLE_CASCADER_PATHS },
      _value: {},
    }
    this.dealBaseInfoPopupRef = createRef()
    this.projectDetailPopup = createRef()
  }
  close() {
    super.close()
    localStorage.removeItem("__dealStatus")
  }
  handleClick(key, data, event) {
    // console.log("handleClick =>>>", key, data, event)
    let {
      _value,
    } = this.state

    switch (key) {
      case 'handle_confirm_value':
        // 判断是否为多层级选择器弹窗
        const multiPathValues = _.get(data, "parentPathValues")
        var topParentKey = data['key']
        // 统一处理多层级值，后续调整
        var multiValue = { ...event }
        if (multiPathValues && _.size(multiPathValues) > 0) {
          topParentKey = _.head(multiPathValues)
          _value = _.assign({}, _value, {
            [data['key']]: {
              ..._value[data['key']],
              metadata: data,
            }
          })
          multiValue = {
            value: { ...event }
          }

          let arrayTemp = _value[topParentKey]
          if (arrayTemp) {
            const existLen = _.filter(arrayTemp, item => item.metadata.key === data.key)
            if (existLen > 0) {
              arrayTemp = _.map(arrayTemp, item => {
                if (item.metadata.key === data.key) {
                  return {
                    metadata: { ...data },
                    ...multiValue
                  }
                }
                return item
              })
            } else {
              arrayTemp.push({
                metadata: { ...data },
                ...multiValue
              })
            }
          } else {
            arrayTemp = [{
              metadata: { ...data },
              ...multiValue
            }]
          }

          this.setState({
            _value: _.assign({}, _value, {
              [topParentKey]: arrayTemp
            })
          })
        } else {
          this.setState({
            _value: _.assign({}, _value, {
              [topParentKey]: {
                ..._value[data['key']],
                ...multiValue
              }
            })
          })
        }
        break;
      case 'handle_submit_value':
        console.log("save info >>>>", _value)
        this.setState({
          _value: {},
        }, () => {
          this.close()
          Toast.show({ content: '保存成功，仅作为DEMO演示', maskStyle: { zIndex: 2000 } })
          this.projectDetailPopup.current?.toggle()
        })
        break;
      default:
        break;
    }
  }
  handleDataChange(metadata, sourcedata, { datas, sourcedataOrigin }) {
    // console.log("handleDatasChange >>>>", metadata, sourcedata, datas, sourcedataOrigin)
    this.setState({ _datas: datas, _sourceData: sourcedataOrigin })
  }
  handleValueChange(value) {
    // console.log("handleValueChange >>>>", value)
    this.setState({ _value: value })
  }

  renderBodyContent() {
    const {
      _metadatas,
      _sourceData,
      _value,
    } = this.state
    console.log('DealUpdate _metadatas >>>>', _metadatas, '_sourceData >>>>', _sourceData, ' _value >>>>', _value)

    return (
      <Fragment>
        {/** List选择 */}
        <MultiplePathNavigator
          metadatas={_metadatas}
          sourcedata={_sourceData}
          value={_value}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_value')}
          onSelect={this.handleClick.bind(this, 'handle_select_value')}
          onDataChange={this.handleDataChange.bind(this)}
          onValueChange={this.handleValueChange.bind(this)}
        />

        <ProjectDetailPopup ref={this.projectDetailPopup} metadata={{ title: 'GLB' }} />
      </Fragment >
    )
  }

  renderFootContent() {
    return (
      <div>
        <Button block color="primary" onClick={this.handleClick.bind(this, 'handle_submit_value')}>保存</Button>
      </div>
    )
  }
}
