import _ from "lodash";
import request from "../../../../../../../api/HttpRequest";

const BUSSINESS_STRATEGY_OPTIONS = [
  { label: '是', value: 'YES' },
  { label: '否', value: 'NO' },
  { label: '不确定', value: 'UNSURE' },
]

/**
 * 获取策略及业务方向列表
 */
export async function FetchBussinessStrategyList({ code, pageNum = 1, pageSize = 2 }) {

  const payload = {
    base_comp_code: code,
    pageNum: pageNum <= 0 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/business/list", payload)
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          id: item['id'],
          code: item['base_comp_code'],
          newStrategicFocus: _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: item['stratageflag'] }),
          newStrategicFocusText: item['stratagenote'],
          newBussiness: _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: item['businessflag'] }),
          newBussinessText: item['businessnote'],
          industryChainIntegrationPlan: _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: item['induschainflag'] }),
          industryChainIntegrationPlanText: item['induschainnote'],
          overseasExpansionPlan: _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: item['globalflag'] }),
          overseasExpansionPlanText: item['globalnote'],
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 获取策略及业务方向详情
 * @param {*} id 
 */
export async function FetchBussinessStrategyDetail(id) {
  if (!id) {
    return
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/business/detail", { id: id })
    let result = {}
    if (response) {
      result = {
        id: response['id'],
        code: response['base_comp_code'],

        newStrategicFocus: response['stratageflag'] ? _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: response['stratageflag'] }) : [],
        newStrategicFocusText: response['stratagenote'],

        newBussiness: response['businessflag'] ? _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: response['businessflag'] }) : [],
        newBussinessText: response['businessnote'],

        industryChainIntegrationPlan: response['induschainflag'] ? _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: response['induschainflag'] }) : [],
        industryChainIntegrationPlanText: response['induschainnote'],

        overseasExpansionPlan: response['globalflag'] ? _.filter(BUSSINESS_STRATEGY_OPTIONS, { value: response['globalflag'] }) : [],
        overseasExpansionPlanText: response['globalnote'],
      }
    }
    return result
  } catch (error) {
    throw error
  }
}