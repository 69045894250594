import React, { createRef, Fragment, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from 'moment';
import { List, Image, Space, Selector, Input, DatePicker, Toast, CalendarPicker } from "antd-mobile";
import { CloseCircleOutline } from 'antd-mobile-icons'
import BaseExtractPopup from "../../../../components/BaseExtractPopup";
import CECInfiniteScroll from "../../../../components/InfiniteScroll";
import { FetchTargetList } from '../transform';
import './index.less';
/**企业，投资人，会议 */
export default class GlobalInfoQuery extends BaseExtractPopup {

    constructor(props) {
        super(props)
        this.overflow = true
        this.state = {
            _type: props.type,
            // 列表检索
            _searchKey: null,

            _datas: [],
            _total: 0,
            _pageNum: 0,
            _pageSize: 50,
            _hasMore: true,
            _filter: {},
            // 详情
            _info: {},
        }

        this.infoDetailPagePopupRef = createRef()
    }

    getDestroyOnClose() { return true }

    handleClick(key, data, event) {
        console.log("handleClick >>>>", key, data, event)

        switch (key) {
            case 'handle_open_item_popup':
                if (data) {
                    this.setState({ _info: data }, () => this.infoDetailPagePopupRef?.current?.show())
                }
                break;
            case 'handle_close_info_detail_popup':

                break;
            default:
                break;
        }
    }

    async fetchDatas(resolve, reject) {
        const { _hasMore } = this.state

        if (!_hasMore) {
            return Promise.resolve();
        }
        const { _searchKey, _datas, _pageNum, _pageSize, _filter } = this.state
        console.log("===============================")
        console.log("fetchDatas 查询条件====>", _filter)
        try {
            const { datas, total, hasMore, } = await FetchTargetList({ searchKey: _searchKey, pageNum: _pageNum, pageSize: _pageSize, filter: _filter })
            this.setState({
                _datas: _pageNum <= 1 ? datas : _.concat(_datas, datas),
                _hasMore: hasMore,
                _total: total,
            }, () => resolve && resolve())
        } catch (error) {
            reject && reject(error)
        }
    }

    fetchDatasMore() {
        return new Promise((resolve, reject) => {
            try {
                const { _pageNum } = this.state
                this.setState({ _pageNum: _pageNum + 1 }, () => this.fetchDatas(resolve, reject))
            } catch (error) {
                reject && reject(error)
            }
        })
    }

    renderBodyContent() {
        const {
            _type,
            _total,
            _datas,
            _hasMore,
            _info,
        } = this.state
        return (
            <Fragment>
                <div id="info-query-search-list" className="info-query-search-box" style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div className="info-query-search-filter">

                    </div>
                    <div className="info-query-search-list-warp" style={{ flex: 1, overflow: "auto" }}>
                        <div className="info-query-search-list">
                            <List style={{ '--border-top': "none" }}>
                                {
                                    _datas.map(item => <InfoQueryItem data={item} onClick={this.handleClick.bind(this, 'handle_open_item_popup', item)} />)
                                }
                            </List>
                            <CECInfiniteScroll hasMore={_hasMore} onLoadMore={this.fetchDatasMore.bind(this)} />
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
/**
 * 投资人列表Item
 * @returns 
 */
function InfoQueryItem({ data, onClick }) {

    return (
        <List.Item key={`ListItem-${Math.random()}`} onClick={onClick}>
            <Space>
                <Image lazy fit="contain" src={data['avatar']} placeholder={<DefaultIcon />} fallback={<DefaultIcon />} className="info-query-item-avatar" style={{ '--width': '32px', '--height': '32px' }} />
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                        <div>{data['label']}</div>
                        {
                            data['titleExtension'] && <div style={{ marginLeft: 5 }}>{data['titleExtension']}</div>
                        }
                    </div>
                    {
                        data['description'] && <div style={{ color: '#999' }}>{data['description']}</div>
                    }
                </div>
            </Space>
        </List.Item>
    )
}

function DefaultIcon() {
    return <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path d="M894 462c30.9 0 43.8-39.7 18.7-58L530.8 126.2a31.81 31.81 0 0 0-37.6 0L111.3 404c-25.1 18.2-12.2 58 18.8 58H192v374h-72c-4.4 0-8 3.6-8 8v52c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-52c0-4.4-3.6-8-8-8h-72V462h62zM512 196.7l271.1 197.2H240.9L512 196.7zM264 462h117v374H264V462z m189 0h117v374H453V462z m307 374H642V462h118v374z" fill="#dbdbdb"></path></svg>
}
