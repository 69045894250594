import React, { Fragment, createRef } from "react";
import _ from "lodash";
import moment from "moment";
import { List, Button, Input, Toast, Space } from "antd-mobile";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import Required from "../../../../../components/Required";
import CECSelectPopup from "../../../../../components/SelectPopup";
import CECCalendarPopup from "../../../../../components/CalendarPopup";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import AttendTargetPopup from "../AttendTargetPopup";
import ListItemGroup from "../../../../../components/ListItemGroup";
import {
  CATEGORY_MA_VALUES,
  CURRENCY_OPTIONS_OTHER_VALUE,
  CURRENCY_OPTIONS,
  AMOUNT_UNIT_OPTIONS,
  FetchElementList,
  FetchInvestmentUserList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 融资/收购历史更新
 */
export default class FinancingHistoryPopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props);

    const { value, label } = super.getAttendTargetValue()

    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      companyName: label,
    })

    this.state = {
      _info: info,
    }
    this.tradeDateRef = createRef()
    this.companyRef = createRef()
    this.buyerCompanysRef = createRef()
    this.investmentRef = createRef()
    this.investorRef = createRef()
    this.roundRef = createRef()
    this.investmentSellRef = createRef()
  }
  componentDidMount() {
    this.fetchRoundList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, label } = super.getAttendTargetValue()

    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      companyName: label,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } }, () => this.afterChange(data))
        break;
      default:
        break;
    }
  }
  afterChange(dataKey) {
    const { _info } = this.state
    if (dataKey === 'round') {
      if (!CATEGORY_MA_VALUES.includes(_info['round'] && _.size(_info['round']) > 0 ? _.join(_.map(_info['round'], 'value')) : "")) {
        _info['company'] = _info['code'] && _info['companyName'] ? [{ value: _info['code'], label: _info['companyName'] }] : []
      } else {
        _info['company'] = []
      }
      this.setState({ _info: { ..._info } })
    }
  }
  // 轮次
  async fetchRoundList() {
    try {
      let options = await FetchElementList("Lable_trastage")
      if (options) {
        options = _.filter(options, item => item['label'] !== '未定义')
      }
      this.setState({ _roundOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  renderBodyContent() {
    const {
      _info,
      _roundOptions,
    } = this.state

    return (
      <Fragment>
        <List>

          <List.Item extra={_info['round'] && _.size(_info['round']) > 0 ? _.join(_.map(_info['round'], 'label')) : "请选择"} onClick={() => this.roundRef?.current?.toggle()}>交易轮次<Required /></List.Item>

          <List.Item extra={_info['tradeDate'] ? moment(_info['tradeDate']).format("YYYY/MM/DD") : "请选择"} onClick={() => this.tradeDateRef?.current?.toggle()}>交易日期</List.Item>

          {
            !CATEGORY_MA_VALUES.includes(_info['round'] && _.size(_info['round']) > 0 ? _.join(_.map(_info['round'], 'value')) : "") && (
              <Fragment>

                <List.Item disabled={true} extra={_info['company'] && _.size(_info['company']) > 0 ? _.join(_.map(_info['company'], 'label')) : "请选择"} onClick={() => this.companyRef?.current?.toggle()}>公司名称<Required /></List.Item>

                <List.Item extra={_info['investments'] && _.size(_info['investments']) > 0 ? _.join(_.map(_info['investments'], 'label')) : "请选择"} onClick={() => this.investmentRef?.current?.toggle()}>投资机构</List.Item>

                <List.Item extra={_info['investors'] && _.size(_info['investors']) > 0 ? _.join(_.map(_info['investors'], 'label')) : "请选择"} onClick={() => this.investorRef?.current?.toggle()}>投资人</List.Item>

                <ListItemGroup label="融资金额">
                  <CECSelectorListItem
                    value={_info['amountCurrency'] ? _.map(_info['amountCurrency'], 'value') : []}
                    options={CURRENCY_OPTIONS}
                    onChange={(value, options) => this.handleChange('change_value', 'amountCurrency', options)}
                  >
                    {_info['amountCurrency'] && _.map(_info['amountCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['amountCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'amountCurrencyOther')} />}
                  </CECSelectorListItem>

                  <CECSelectorListItem
                    value={_info['amountUnit'] ? _.map(_info['amountUnit'], 'value') : []}
                    options={AMOUNT_UNIT_OPTIONS}
                    onChange={(value, options) => this.handleChange('change_value', 'amountUnit', options)}
                  >
                    <Input type="number" placeholder="请填写金额" value={_info['amountValue']} onChange={this.handleChange.bind(this, 'change_value', 'amountValue')} />
                  </CECSelectorListItem>
                </ListItemGroup>

                <List.Item extra={<Space><Input type="number" placeholder="请填写" value={_info['equityRatio']} onChange={this.handleChange.bind(this, 'change_value', 'equityRatio')} /><span>%</span></Space>}>股权比例</List.Item>

                <ListItemGroup label="投后估值">
                  <CECSelectorListItem
                    value={_info['postCurrency'] ? _.map(_info['postCurrency'], 'value') : []}
                    options={CURRENCY_OPTIONS}
                    onChange={(value, options) => this.handleChange('change_value', 'postCurrency', options)}
                  >
                    {_info['postCurrency'] && _.map(_info['postCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['postCurrencyOther']} onChange={val => this.setState({ _info: { ..._info, "postCurrencyOther": val } })} />}
                  </CECSelectorListItem>

                  <CECSelectorListItem
                    value={_info['postUnit'] ? _.map(_info['postUnit'], 'value') : []}
                    options={AMOUNT_UNIT_OPTIONS}
                    onChange={(value, options) => this.handleChange('change_value', 'postUnit', options)}
                  >
                    <Input type="number" placeholder="请填写" value={_info['postValue']} onChange={this.handleChange.bind(this, 'change_value', 'postValue')} />
                  </CECSelectorListItem>
                </ListItemGroup>
              </Fragment>
            )
          }
          {
            CATEGORY_MA_VALUES.includes(_info['round'] && _.size(_info['round']) > 0 ? _.join(_.map(_info['round'], 'value')) : "") && (
              <Fragment>

                <List.Item extra={_info['buyerCompanys'] && _.size(_info['buyerCompanys']) > 0 ? _.join(_.map(_info['buyerCompanys'], 'label')) : "请选择"} onClick={() => this.buyerCompanysRef?.current?.toggle()}>被收购方名称<Required /></List.Item>

                <CECSelectorListItem label="并购金额币种"
                  value={_info['amountCurrency'] ? _.map(_info['amountCurrency'], 'value') : []}
                  options={CURRENCY_OPTIONS}
                  onChange={(v, options) => this.handleChange('change_value', 'amountCurrency', options)}
                >
                  {_info['amountCurrency'] && _.map(_info['amountCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['amountCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'amountCurrencyOther')} />}
                </CECSelectorListItem>

                <CECSelectorListItem label="并购金额单位"
                  value={_info['amountUnit'] ? _.map(_info['amountUnit'], 'value') : []}
                  options={AMOUNT_UNIT_OPTIONS}
                  onChange={(v, options) => this.handleChange('change_value', 'amountUnit', options)}
                />

                <List.Item extra={<Input type="number" placeholder="请填写" value={_info['amountValue']} onChange={this.handleChange.bind(this, 'change_value', 'amountValue')} />}>并购金额</List.Item>

                <List.Item extra={<Space><Input type="number" placeholder="请填写" value={_info['equityRatio']} onChange={this.handleChange.bind(this, 'change_value', 'equityRatio')} /><span>%</span></Space>}>收购方股权占比</List.Item>

              </Fragment>
            )
          }
        </List>

        <CECSelectPopup ref={this.roundRef}
          title={'融资轮次'}
          supportAvatar={false}
          multiple={false}
          options={_roundOptions}
          values={_info['round'] ? _.map(_info['round'], 'value') : []}
          onConfirm={this.handleChange.bind(this, 'change_value', 'round')}
        />

        <CECCalendarPopup ref={this.tradeDateRef}
          metadata={{ title: "交易日期" }}
          value={_info['tradeDate'] ? _info['tradeDate'] : moment().toDate()}
          onConfirm={this.handleChange.bind(this, 'change_value', 'tradeDate')}
        />

        {/** 公司 */}
        <AttendTargetPopup ref={this.companyRef}
          mode={"target"}
          metadata={{ title: '公司名称' }}
          targetCategorys={['company']}
          targetMultiple={false}
          value={{ targets: _info['company'] }}
          onConfirm={(data) => this.handleChange('change_value', 'company', data['targets'])}
        />

        {/** 被收购方 */}
        <AttendTargetPopup ref={this.buyerCompanysRef}
          mode={"target"}
          metadata={{ title: '被收购方名称' }}
          targetCategorys={['company']}
          targetMultiple={true}
          value={{ targets: _info['buyerCompanys'] }}
          onConfirm={(data) => this.handleChange('change_value', 'buyerCompanys', data['targets'])}
        />

        {/** 投资机构 */}
        <AttendTargetPopup ref={this.investmentRef}
          mode={"target"}
          metadata={{ title: '投资机构' }}
          targetCategorys={['investment']}
          targetMultiple={true}
          value={{ targets: _info['investments'] }}
          onConfirm={(data) => this.handleChange('change_value', 'investments', data['targets'])}
        />

        {/** 投资人 */}
        <CECSelectPopup ref={this.investorRef}
          title={'投资人'}
          supportSearch={true}
          supportAvatar={false}
          multiple={true}
          onFetchDatas={(params) => FetchInvestmentUserList({ ...params })}
          values={_info['investors']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'investors')}
        />

      </Fragment >
    )
  }
  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}