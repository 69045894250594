import React, { createRef } from "react";
import _ from "lodash";
import { Button, Toast } from "antd-mobile";
import MultiplePathNavigator from '../../../../components/MultiplePathNavigator'
import BaseExtractPopup from "../../../../components/BaseExtractPopup";
import InfoDetailPagePopup from "../../QueryEntry/InfoQuery/InfoDetailPagePopup";
import {
  COMPONENT_OPTION_LIST,
  COMPONENT_OPTION_LIST_MULTIPLE_CASCADER_PATHS,
} from './configuration'
import {
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 关键信息更新
 */
export default class InfoUpdatePopup extends BaseExtractPopup {

  constructor(props) {
    super(props);

    this.state = {
      _metadatas: [...COMPONENT_OPTION_LIST],
      _sourceData: { ...COMPONENT_OPTION_LIST_MULTIPLE_CASCADER_PATHS },
      _value: {},

      // 详情页
      _info: {},
    }
    this.infoDetailPagePopupRef = createRef()
  }

  handleClick(key, data, event) {
    // console.log("handleClick =>>>", key, data, event)
    let { _value, } = this.state

    switch (key) {
      case 'handle_confirm_value':
        // 判断是否为多层级选择器弹窗
        var topParentKey = data['key']
        if (event) {
          const multiPathValues = _.get(data, "parentPathValues")
          // 统一处理多层级值，后续调整
          var multiValue = { ...event }
          if (multiPathValues && _.size(multiPathValues) > 0) {
            topParentKey = _.head(multiPathValues)
            _value = _.assign({}, _value, {
              [data['key']]: {
                ..._value[data['key']],
                metadata: data,
              }
            })
            multiValue = { value: { ...event } }

            let arrayTemp = _value[topParentKey]
            if (arrayTemp) {
              const existLen = _.size(_.filter(arrayTemp, item => item.metadata.key === data.key))
              if (existLen > 0) {
                arrayTemp = _.map(arrayTemp, item => {
                  if (item.metadata.key === data.key) {
                    return { metadata: { ...data }, ...multiValue }
                  }
                  return item
                })
              } else {
                arrayTemp.push({ metadata: { ...data }, ...multiValue })
              }
            } else {
              arrayTemp = [{ metadata: { ...data }, ...multiValue }]
            }

            this.setState({
              _value: _.assign({}, _value, {
                [data['key']]: {
                  metadata: { ...data },
                  value: { ...event }
                },
                [topParentKey]: arrayTemp
              })
            })
          } else {
            this.setState({
              _value: _.assign({}, _value, {
                [data['key']]: {
                  metadata: { ...data },
                  value: { ...event }
                },
                [topParentKey]: {
                  ..._value[data['key']],
                  ...multiValue
                }
              })
            })
          }
        } else {
          const multiPathMetadataTree = _.get(_value[topParentKey], "metadata")
          const multiPathValuesTree = _.get(multiPathMetadataTree, "parentPathValues")
          if (multiPathValuesTree && _.size(multiPathValuesTree) > 0) {
            delete _value[data['key']]
            delete _value[multiPathValuesTree[0]]
            this.setState({ _value: { ..._value } })
          } else {
            delete _value[data['key']]
            this.setState({ _value: { ..._value } })
          }
        }
        break;
      case 'handle_submit_value':
        this.saveInfo(_value)
        break;
      default:
        break;
    }
  }

  close() {
    super.close()
    this.deleteInfo()
  }
  onlyCloseWindow() {
    super.close()
  }
  handleDataChange(metadata, sourcedata, { metadatas, sourcedataOrigin }) {
    // console.log("handleDatasChange >>>>", metadata, sourcedata, metadatas, sourcedataOrigin)
    this.setState({ _metadatas: metadatas, _sourceData: sourcedataOrigin })
  }
  handleValueChange(value) {
    // console.log("handleValueChange >>>>", value)
    this.setState({ _value: value })
  }
  saveInfo = async (data) => {
    Toast.show({ icon: 'loading', duration: 0 })

    try {
      await SaveInfo(data)
      Toast.show({ icon: 'success', content: '保存成功' })
      this.onlyCloseWindow()

      const targets = _.get(data, `info_option_list_attend_target.targets`)
      const target = _.head(targets)

      this.setState({
        _value: {},
        _info: target
      }, () => this.infoDetailPagePopupRef.current?.toggle())
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  deleteInfo = async () => {
    const { _value } = this.state
    try {
      await DeleteInfo(_value)
      this.setState({ _value: {} })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  renderBodyContent() {
    const {
      _metadatas,
      _sourceData,
      _value,
      _info,
    } = this.state
    // console.log('InfoUpdate _metadatas >>>>', _metadatas, '_sourceData >>>>', _sourceData, ' _value >>>>', _value)

    return (
      <div>
        {/** List选择 */}
        <MultiplePathNavigator
          metadatas={_metadatas}
          sourcedata={_sourceData}
          value={_value}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_value')}
          onSelect={this.handleClick.bind(this, 'handle_select_value')}
          onDataChange={this.handleDataChange.bind(this)}
          onValueChange={this.handleValueChange.bind(this)}
        />

        {/** 企业详情页 */}
        <InfoDetailPagePopup ref={this.infoDetailPagePopupRef} metadata={{ title: _info['label'] }} value={_info} />
      </div>
    )
  }

  renderFootContent() {
    return (
      <div>
        <Button block color="primary" onClick={this.handleClick.bind(this, 'handle_submit_value')}>保存</Button>
      </div>
    )
  }

}

