import React, { createRef, Fragment, useEffect, useState } from "react";
import _ from "lodash";
import moment from 'moment';
import { List, Image, Space, Selector, Input, DatePicker,  } from "antd-mobile";
import { CloseCircleOutline } from 'antd-mobile-icons'
import BaseExtractPopup from "../../../../components/BaseExtractPopup";
import CECInfiniteScroll from "../../../../components/InfiniteScroll";
import InfoDetailPagePopup from "./InfoDetailPagePopup"
import {
  FetchTargetList, FetchYesNoOptions, FetchTrueFlaseOptions, FetchUnits, FetchCurrencyOptions,
  /**Company */
  FetchCompanyRegionOptions, FetchCompanyTypeOptions, FetchCompanyRoundOptions, FetchCompanyValuationOptions,
  /**GP/LP 标准 */
  FetchInvestorIdentityOptions, FetchInvestorTypeOptions,
  FetchAssetCategoryOptions, FetchScaleOptions, FetchRegionOptions,
  /**投资需求*/
  FetchInvestmentVehicleOptions, FetchInvestmentMethodOptions, FetchInvestmentCategoryOptions,
  FetchInvestmentTargetAttributesOptions, FetchGeographicalMarketOptions, FetchCurrentStatueOptions,
  FetchYiKaiIndustrys, FetchImportantConsiderationsOptions,
  /** */
  FetchFinancingScopeOptions, FetchFundingScaleOptions, FetchFundAttributesOptions,

} from "../transform";
import {
  TSearchView, TSideBarBox, IndustryView
} from '../../../../components/FormsUtils';
import './index.less';
/**找投资人 */
export const FOR_COMPANY_QUERY_INVESTOR = 'for_company_query_investor';
/**找买方 */
export const FOR_COMPANY_QUERY_BUYER = 'for_company_query_buyer';
/**找投资人或并购目标 */
export const FOR_COMPANY_QUERY_INVESTMENT_MA = 'for_company_query_investment_ma';
/**找IP购买方 */
export const FOR_COMPANY_QUERY_IP_BUYER = 'for_company_query_ip_buyer';
/**找业务合作方（出海） */
export const FOR_COMPANY_QUERY_OVERSEAS_BUSINESS_PARTNER = 'for_company_query_overseas_business_partner';

/**找LP/投资人（募资） */
export const FOR_ORG_QUERY_FUNDRAISING_LP_INVESTOR = 'for_organization_fundraising_lp_investor';
/**找接续基金投资人/出资人（接盘方） */
export const FOR_ORG_QUERY_FOLLOW_ON_FUND_LP = 'for_organization_follow_on_fund_lp';
/**退老股 */
export const FOR_ORG_QUERY_SELLING_EXISTING_SHARES = 'for_organization_selling_existing_shares';
/**找投资或并购目标*/
export const FOR_ORG_QUERY_INVESTMENT_MA = 'for_organization_investment_ma';

/**找GP */
export const FOR_ORG_QUERY_GP = 'for_organization_gp';
/**找项目 */
export const FOR_ORG_QUERY_DEAL = 'for_organization_deal';




/**
 * 按目标查询
 */
export default class InfoQuery extends BaseExtractPopup {
  constructor(props) {
    super(props)
    this.overflow = true
    this.state = {
      _type: props.type,
      // 列表检索
      _searchKey: null,

      _datas: [],
      _total: 0,
      _pageNum: 0,
      _pageSize: 50,
      _hasMore: true,
      _filter: {},
      // 详情
      _info: {},
    }

    this.infoDetailPagePopupRef = createRef()
  }

  getDestroyOnClose() { return true }

  handleClick(key, data, event) {
    console.log("handleClick >>>>", key, data, event)

    switch (key) {
      case 'handle_open_item_popup':
        if (data) {
          this.setState({ _info: data }, () => this.infoDetailPagePopupRef?.current?.show())
        }
        break;
      default:
        break;
    }
  }

  async fetchDatas(resolve, reject) {
    const { _hasMore } = this.state

    if (!_hasMore) {
      return Promise.resolve();
    }
    const { _searchKey, _datas, _pageNum, _pageSize, _filter } = this.state
    console.log("===============================")
    console.log("fetchDatas 查询条件====>", _filter)
    try {
      const { datas, total, hasMore, } = await FetchTargetList({ searchKey: _searchKey, pageNum: _pageNum, pageSize: _pageSize, filter: _filter })

      console.log("fetchDatas datas====>", datas)
      this.setState({
        _datas: _pageNum <= 1 ? datas : _.concat(_datas, datas),
        _hasMore: hasMore,
        _total: total,
      }, () => resolve && resolve())
    } catch (error) {
      reject && reject(error)
    }
  }

  fetchDatasMore() {
    return new Promise((resolve, reject) => {
      try {
        const { _pageNum } = this.state
        this.setState({ _pageNum: _pageNum + 1 }, () => this.fetchDatas(resolve, reject))
      } catch (error) {
        reject && reject(error)
      }
    })
  }

  renderBodyContent() {
    const {
      _type,
      _total,
      _datas,
      _hasMore,
      _info,
    } = this.state
    const { SearchView } = listAdapter(_type)
    return (
      <Fragment>
        <div id="info-query-search-list" className="info-query-search-box" style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
          <div className="info-query-search-filter">
            <SearchView
              elementById="info-query-search-list"
              total={_total}
              onChange={filter => this.setState({ _pageNum: 0, _filter: filter }, () => this.fetchDatasMore())}
            />
          </div>
          <div className="info-query-search-list-warp" style={{ flex: 1, overflow: "auto" }}>
            <div className="info-query-search-list">
              <List style={{ '--border-top': "none" }}>
                {
                  _datas.map(item => <InfoQueryItem data={item} onClick={this.handleClick.bind(this, 'handle_open_item_popup', item)} />)
                }
              </List>
              <CECInfiniteScroll hasMore={_hasMore} onLoadMore={this.fetchDatasMore.bind(this)} />
            </div>
          </div>
        </div>

        {/** 企业详情页 */}
        <InfoDetailPagePopup ref={this.infoDetailPagePopupRef} metadata={{ title: _info['label'] }} value={_info} />
      </Fragment>
    )
  }
}

/**
 * 投资人列表Item
 * @returns 
 */
function InfoQueryItem({ data, onClick }) {

  return (
    <List.Item key={`ListItem-${Math.random()}`} onClick={onClick}>
      <Space>
        <Image lazy fit="contain" src={data['avatar']} placeholder={<DefaultIcon />} fallback={<DefaultIcon />} className="info-query-item-avatar" style={{ '--width': '32px', '--height': '32px' }} />
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div>{data['label']}</div>
            {
              data['titleExtension'] && <div style={{ marginLeft: 5 }}>{data['titleExtension']}</div>
            }
          </div>
          {
            data['description'] && <div style={{ color: '#999' }}>{data['description']}</div>
          }
        </div>
      </Space>
    </List.Item>
  )
}

function DefaultIcon() {
  return <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path d="M894 462c30.9 0 43.8-39.7 18.7-58L530.8 126.2a31.81 31.81 0 0 0-37.6 0L111.3 404c-25.1 18.2-12.2 58 18.8 58H192v374h-72c-4.4 0-8 3.6-8 8v52c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-52c0-4.4-3.6-8-8-8h-72V462h62zM512 196.7l271.1 197.2H240.9L512 196.7zM264 462h117v374H264V462z m189 0h117v374H453V462z m307 374H642V462h118v374z" fill="#dbdbdb"></path></svg>
}


/**适配不同类型搜索 */
function listAdapter(key) {
  let SearchView = null, requestUrl = null
  switch (key) {
    /**找投资人 */
    case FOR_COMPANY_QUERY_INVESTOR:
      SearchView = ForCompanyQueryInvestor
      break
    /**找卖方 */
    case FOR_COMPANY_QUERY_BUYER:
      SearchView = ForCompanyQueryBuyer
      break
    /**找投资人或并购目标 */
    case FOR_COMPANY_QUERY_INVESTMENT_MA:
      SearchView = ForCompanyQueryInvestmentMa
      break
    /**找IP购买方 */
    case FOR_COMPANY_QUERY_IP_BUYER:
      SearchView = ForCompanyQueryIPBuyer
      break
    /**找业务合作方（出海） */
    case FOR_COMPANY_QUERY_OVERSEAS_BUSINESS_PARTNER:
      SearchView = ForCompanyQueryOverseasBusinessParener
      break
    /**找LP/投资人（募资） */
    case FOR_ORG_QUERY_FUNDRAISING_LP_INVESTOR:
      SearchView = ForCompanyQueryFundRaisingLPInvestor
      break
    /**找接续基金投资人/出资人（接盘方） */
    case FOR_ORG_QUERY_FOLLOW_ON_FUND_LP:
      SearchView = ForCompanyQueryFollowOnFundLP
      break
    /**退老股 */
    case FOR_ORG_QUERY_SELLING_EXISTING_SHARES:
      SearchView = ForCompanyQueryFollowOnFundLP
      break
    /**找投资或并购目标*/
    case FOR_ORG_QUERY_INVESTMENT_MA:
      SearchView = ForCompanyQueryInvestmentMa
      break
    /**找GP */
    case FOR_ORG_QUERY_GP:
      SearchView = ForCompanyQueryFundRaisingLPInvestor
      break
    /**找项目 */
    case FOR_ORG_QUERY_DEAL:
      SearchView = ForCompanyQueryInvestmentMa
      break
  }
  return { SearchView, requestUrl }
}

/**找投资人 */
class ForCompanyQueryInvestor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 查询条件
      _filter: {},

      _total: 0,

      _baseValue: "identity",
      _moreValue: "investmentVehicle",

      _unitOptions: [],
      _currencyOptions: [],
      _identityOptions: [],
      _typeOptions: [],
      _assetsOptions: [],
      _areaOptions: [],
      _momeyOptions: [],

      _investmentVehicleOptions: [],
      _investmentMethodOptions: [],
      _assetCategoryOptions: [],
      _investmentTargetAttributesOptions: [],
      _targetTndustriesAndTracksOptions: [],
      _targetGeographicMarketOptions: [],
      _currentStateOptions: [],
      _otherRelatedRequirementsOptions: [],
    }

    this.tSearchViewRef = createRef()

    this.dropdownOptions = [
      { label: "基本信息", value: "base", filterKey: ['identity', 'type', 'assets', 'area', 'currency', 'money'] },
      { label: "投资相关", value: "investment", filterKey: ['investmentVehicle', 'investmentMethod', 'assetCategory', 'investmentTargetAttributes', 'targetTndustriesAndTracks', 'targetGeographicMarket', 'currentState', 'otherRelatedRequirements'] },
    ]

    this.baseOptions = [
      { label: "身份", value: "identity", filterKey: ['identity'] },
      { label: "类型", value: "type", filterKey: ['type'] },
      { label: "资产类别", value: "assets", filterKey: ['assets'] },
      { label: "地区", value: "area", filterKey: ['area'] },
      { label: "币种", value: "currency", filterKey: ['currency'] },
      { label: "单笔投资金额", value: "money", filterKey: ['money'] },
    ]

    this.investmentOptions = [
      { label: "投资载体", value: "investmentVehicle", filterKey: ['investmentVehicle'] },
      { label: "投资方式", value: "investmentMethod", filterKey: ['investmentMethod'] },
      { label: "资产类别", value: "assetCategory", filterKey: ['assetCategory'] },
      { label: "投资标的属性", value: "investmentTargetAttributes", filterKey: ['investmentTargetAttributes'] },
      { label: "目标产业及赛道", value: "targetTndustriesAndTracks", filterKey: ['targetTndustriesAndTracks'] },
      { label: "目标地理市场", value: "targetGeographicMarket", filterKey: ['targetGeographicMarket'] },
      { label: "当前状态", value: "currentState", filterKey: ['currentState'] },
      { label: "其他相关需求", value: "otherRelatedRequirements", filterKey: ['otherRelatedRequirements'] },
    ]
  }
  componentDidMount() {
    this.initOptions()
  }

  initOptions() {
    let request = [
      /**GP基本搜索 */
      FetchInvestorIdentityOptions(),
      FetchInvestorTypeOptions(),
      FetchAssetCategoryOptions(),
      FetchRegionOptions(),
      FetchCurrencyOptions(),
      FetchScaleOptions(),
      FetchUnits(),

      /**是否有投资需求 */
      FetchInvestmentVehicleOptions(),
      FetchInvestmentMethodOptions(),
      FetchInvestmentCategoryOptions(),
      FetchInvestmentTargetAttributesOptions(),
      FetchGeographicalMarketOptions(),
      FetchCurrentStatueOptions(),
      FetchYiKaiIndustrys(),
      FetchTrueFlaseOptions(),
    ]

    if (_.size(request) > 0) {

      Promise.all(request).then(array => {
        let obj = {}
        _.map(array, (res, i) => {
          switch (i) {
            case 0:
              obj['_identityOptions'] = res
              break
            case 1:
              obj['_typeOptions'] = res
              break
            case 2:
              obj['_areaOptions'] = res
              break
            case 3:
              obj['_assetsOptions'] = res
              break
            case 4:
              obj['_currencyOptions'] = res
              break
            case 5:
              obj['_moneyOptions'] = res
              break
            case 6:
              obj['_unitOptions'] = res
              break
            /** */
            case 7:
              obj['_investmentVehicleOptions'] = res
              break
            case 8:
              obj['_investmentMethodOptions'] = res
              break
            case 9:
              obj['_assetCategoryOptions'] = res
              break
            case 10:
              obj['_investmentTargetAttributesOptions'] = res
              break
            case 11:
              obj['_targetGeographicMarketOptions'] = res
              break
            case 12:
              obj['_currentStateOptions'] = res
              break
            case 13:
              obj['_targetTndustriesAndTracksOptions'] = res
              break
            case 14:
              obj['_otherRelatedRequirementsOptions'] = res
              break
          }
        })

        this.setState({ ...obj })
      }).catch(msg => console.log("initOptions", msg))
    }


  }

  componentWillReceiveProps(props) {
    this.setState({ _total: props.total })
  }

  fetchDataList() {
    console.log("fetchDataList")
    const { _filter } = this.state
    const { onChange } = this.props
    onChange && onChange({ ..._filter })
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
  }

  /** */
  handleClearClick(key, data) {
    console.log("handleClearClick", key)
    const { _filter } = this.state
    let filter = { ..._filter }
    switch (key) {
      case 'all':
        this.setState({
          _filter: {}
        }, () => this.fetchDataList('close'))
        break
      case 'base':
        _.map(this.baseOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'investment':
        _.map(this.investmentOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'identity':
      case 'type':
      case 'assets':
      case 'area':
      case 'money':
      case 'investmentVehicle':
      case 'investmentMethod':
      case 'assetCategory':
      case 'investmentTargetAttributes':
      case 'targetTndustriesAndTracks':
      case 'targetGeographicMarket':
      case 'currentState':
      case 'otherRelatedRequirements':
        filter[key] = _.filter(filter[key], ({ label, value }) => value != data)
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'close':
        if (this.tSearchViewRef && this.tSearchViewRef.current) {
          this.tSearchViewRef.current.closeDropdown()
        }
        break

    }
  }

  render() {
    const { _filter, _total } = this.state
    let searchCriteriaOptions = this.renderSearchCriteria()
    return (
      <TSearchView
        ref={this.tSearchViewRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          this.handleClearClick('close')
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
        //数据总数
        total={_total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={searchCriteriaOptions}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  /**渲染菜单 */
  renderSearchMenu(key) {
    let view = <div>{key}</div>
    switch (key) {
      case 'base':
        view = this.renderBaseMenu()
        break
      case 'investment':
        view = this.renderInvestmentMenu()
        break
    }

    return view
  }

  renderBaseMenu() {
    const {
      _filter, _baseValue,
      _identityOptions, _typeOptions, _assetsOptions,
      _areaOptions, _currencyOptions, _moneyOptions
    } = this.state
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.baseOptions}
        sideBarValue={_baseValue}
        onSideBarChange={val => this.setState({ _baseValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_baseValue == "identity" && (
            <SelectorView
              valueKey='identity'
              options={_identityOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "type" && (
            <SelectorView
              valueKey='type'
              options={_typeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "area" && (
            <SelectorView
              valueKey='area'
              options={_assetsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "assets" && (
            <SelectorView
              columns={1}
              valueKey='assets'
              options={_areaOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "currency" && (
            <SelectorView
              valueKey='assets'
              options={_currencyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "money" && (
            <SelectorView
              multiple={false}
              valueKey='money'
              options={_moneyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderInvestmentMenu() {
    const {
      _filter, _moreValue,
      _investmentVehicleOptions, _investmentMethodOptions, _assetCategoryOptions,
      _investmentTargetAttributesOptions, _targetTndustriesAndTracksOptions, _targetGeographicMarketOptions,
      _currentStateOptions, _otherRelatedRequirementsOptions,
    } = this.state
    return (
      <TSideBarBox
        width="125px"
        filter={_filter}
        sideBarOptions={this.investmentOptions}
        sideBarValue={_moreValue}
        onSideBarChange={val => this.setState({ _moreValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_moreValue == "investmentVehicle" && (
            <SelectorView
              columns={1}
              valueKey='investmentVehicle'
              options={_investmentVehicleOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_moreValue == "investmentMethod" && (
            <SelectorView
              columns={1}
              valueKey='investmentMethod'
              options={_investmentMethodOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_moreValue == "assetCategory" && (
            <SelectorView
              valueKey='assetCategory'
              options={_assetCategoryOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_moreValue == "investmentTargetAttributes" && (
            <SelectorView
              valueKey='investmentTargetAttributes'
              options={_investmentTargetAttributesOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_moreValue == "targetTndustriesAndTracks" && (
            <IndustryViewBox
              options={_targetTndustriesAndTracksOptions}
              value={_.map(_.get(_filter, "targetTndustriesAndTracks", []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, targetTndustriesAndTracks: options }
                })
              }
            />
          )}
          {_moreValue == "targetGeographicMarket" && (
            <SelectorView
              valueKey='targetGeographicMarket'
              options={_targetGeographicMarketOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_moreValue == "currentState" && (
            <SelectorView
              valueKey='currentState'
              options={_currentStateOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_moreValue == "otherRelatedRequirements" && (
            <SelectorView
              multiple={false}
              valueKey='otherRelatedRequirements'
              options={_otherRelatedRequirementsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderSearchCriteria() {
    const { _filter, _unitOptions, _currencyOptions } = this.state
    return searchCriteriaHelp(
      _.concat(this.baseOptions, this.investmentOptions),
      _filter,
      _unitOptions,
      _currencyOptions
    )

  }
}

/**找卖方 */
class ForCompanyQueryBuyer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 查询条件
      _filter: {},

      _total: 0,

      _baseValue: "identity",
      _mergerIntegrationValue: "targetCompanyIndustry",

      _unitOptions: [],
      _identityOptions: [],
      _typeOptions: [],
      _assetsOptions: [],
      _areaOptions: [],
      _currencyOptions: [],
      _momeyOptions: [],

      _targetCompanyIndustryOptions: [],
      _targetGeographicMarketOptions: [],
      _havingIncomeOptions: [],
      _thereIsProfitOptions: [],
      _isFAOptions: [],
      _importantConsiderationsOptions: [],
      _currentStateOptions: [],
      _otherRelatedRequirementsOptions: [],
    }

    this.tSearchViewRef = createRef()

    this.dropdownOptions = [
      { label: "基本信息", value: "base", filterKey: ['identity', 'type', 'assets', 'area', 'currency', 'money'] },
      {
        label: "并购整合相关", value: "mergerIntegration", filterKey: [
          'targetCompanyIndustry', 'targetGeographicMarket', 'havingIncome',
          'thereIsProfit', 'shareholdingRatio', 'startTime',
          'isFA', 'importantConsiderations', 'currentState', 'otherRelatedRequirements'
        ]
      },
    ]

    this.baseOptions = [
      { label: "身份", value: "identity", filterKey: ['identity'] },
      { label: "类型", value: "type", filterKey: ['type'] },
      { label: "资产类别", value: "assets", filterKey: ['assets'] },
      { label: "地区", value: "area", filterKey: ['area'] },
      { label: "币种", value: "currency", filterKey: ['currency'] },
      { label: "单笔投资金额", value: "money", filterKey: ['money'] },
    ]

    this.mergerIntegratioOptions = [
      { label: "标的公司所处行业", value: "targetCompanyIndustry", filterKey: ['targetCompanyIndustry'] },
      { label: "目标地理市场", value: "targetGeographicMarket", filterKey: ['targetGeographicMarket'] },
      { label: "是否必须有收入", value: "havingIncome", filterKey: ['havingIncome'] },
      { label: "是否必须有利润", value: "thereIsProfit", filterKey: ['thereIsProfit'] },
      { label: "目标持股比例", value: "shareholdingRatio", filterKey: ['shareholdingRatio'], viewType: "InputRatioView" },
      { label: "目标启动时间", value: "startTime", filterKey: ['startTime'], viewType: "DateRangeView" },
      { label: "是否已有FA", value: "isFA", filterKey: ['isFA'] },
      { label: "重要考量因素", value: "importantConsiderations", filterKey: ['importantConsiderations'] },
      { label: "当前状态", value: "currentState", filterKey: ['currentState'] },
      { label: "其他相关需求", value: "otherRelatedRequirements", filterKey: ['otherRelatedRequirements'] },
    ]
  }
  componentDidMount() {
    this.initOptions()
  }

  initOptions() {
    let request = [
      /**GP基本搜索 */
      FetchInvestorIdentityOptions(),
      FetchInvestorTypeOptions(),
      FetchAssetCategoryOptions(),
      FetchRegionOptions(),
      FetchCurrencyOptions(),
      FetchScaleOptions(),
      FetchUnits(),

      /**是否有并购整合需求 */
      FetchYiKaiIndustrys(),
      FetchGeographicalMarketOptions(),
      FetchTrueFlaseOptions(),
      FetchTrueFlaseOptions(),
      FetchTrueFlaseOptions(),
      FetchImportantConsiderationsOptions(),
      FetchCurrentStatueOptions(),
      FetchTrueFlaseOptions(),

    ]

    if (_.size(request) > 0) {

      Promise.all(request).then(array => {
        let obj = {}
        _.map(array, (res, i) => {
          switch (i) {
            case 0:
              obj['_identityOptions'] = res
              break
            case 1:
              obj['_typeOptions'] = res
              break
            case 2:
              obj['_areaOptions'] = res
              break
            case 3:
              obj['_assetsOptions'] = res
              break
            case 4:
              obj['_currencyOptions'] = res
              break
            case 5:
              obj['_moneyOptions'] = res
              break
            case 6:
              obj['_moneyOptions'] = res
              break
            /** */
            case 7:
              obj['_targetCompanyIndustryOptions'] = res
              break
            case 8:
              obj['_targetGeographicMarketOptions'] = res
              break
            case 9:
              obj['_havingIncomeOptions'] = res
              break
            case 10:
              obj['_thereIsProfitOptions'] = res
              break
            case 11:
              obj['_isFAOptions'] = res
              break
            case 12:
              obj['_importantConsiderationsOptions'] = res
              break
            case 13:
              obj['_currentStateOptions'] = res
              break
            case 14:
              obj['_otherRelatedRequirementsOptions'] = res
              break
          }
        })

        this.setState({ ...obj })
      }).catch(msg => console.log("initOptions", msg))
    }


  }

  componentWillReceiveProps(props) {
    this.setState({ _total: props.total })
  }

  fetchDataList() {
    console.log("fetchDataList")
    const { _filter } = this.state
    const { onChange } = this.props
    onChange && onChange({ ..._filter })
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
  }

  /** */
  handleClearClick(key, data) {
    console.log("handleClearClick", key)
    const { _filter } = this.state
    let filter = { ..._filter }
    switch (key) {
      case 'all':
        this.setState({
          _filter: {}
        }, () => this.fetchDataList('close'))
        break
      case 'base':
        _.map(this.baseOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'mergerIntegration':
        _.map(this.mergerIntegratioOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'shareholdingRatio':
      case 'startTime':
        filter[key] = undefined
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'identity':
      case 'type':
      case 'assets':
      case 'area':
      case 'money':
      /**分割 */
      case 'targetCompanyIndustry':
      case 'targetGeographicMarket':
      case 'havingIncome':
      case 'thereIsProfit':
      case 'isFA':
      case 'importantConsiderations':
      case 'currentState':
      case 'otherRelatedRequirements':
        filter[key] = _.filter(filter[key], ({ label, value }) => value != data)
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'close':
        if (this.tSearchViewRef && this.tSearchViewRef.current) {
          this.tSearchViewRef.current.closeDropdown()
        }
        break

    }
  }

  render() {
    const { _filter, _total } = this.state
    const { elementById } = this.props
    let searchCriteriaOptions = this.renderSearchCriteria()
    return (
      <TSearchView
        elementById={elementById}
        ref={this.tSearchViewRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          this.handleClearClick('close')
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
        //数据总数
        total={_total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={searchCriteriaOptions}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  /**渲染菜单 */
  renderSearchMenu(key) {
    let view = <div>{key}</div>
    switch (key) {
      case 'base':
        view = this.renderBaseMenu()
        break
      case 'mergerIntegration':
        view = this.renderMergerIntegrationMenu()
        break
    }

    return view
  }

  renderBaseMenu() {
    const {
      _filter, _baseValue,
      _identityOptions, _typeOptions, _assetsOptions,
      _areaOptions, _currencyOptions, _moneyOptions
    } = this.state
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.baseOptions}
        sideBarValue={_baseValue}
        onSideBarChange={val => this.setState({ _baseValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_baseValue == "identity" && (
            <SelectorView
              valueKey='identity'
              options={_identityOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "type" && (
            <SelectorView
              valueKey='type'
              options={_typeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "area" && (
            <SelectorView
              valueKey='area'
              options={_assetsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "assets" && (
            <SelectorView
              columns={1}
              valueKey='assets'
              options={_areaOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "currency" && (
            <SelectorView
              valueKey='assets'
              options={_currencyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "money" && (
            <SelectorView
              multiple={false}
              valueKey='money'
              options={_moneyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderMergerIntegrationMenu() {
    const {
      _filter, _mergerIntegrationValue,
      _targetCompanyIndustryOptions, _targetGeographicMarketOptions,
      _havingIncomeOptions, _thereIsProfitOptions,
      _isFAOptions, _importantConsiderationsOptions,
      _currentStateOptions, _otherRelatedRequirementsOptions,
    } = this.state
    return (
      <TSideBarBox
        width="135px"
        filter={_filter}
        sideBarOptions={this.mergerIntegratioOptions}
        sideBarValue={_mergerIntegrationValue}
        onSideBarChange={val => this.setState({ _mergerIntegrationValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>

          {_mergerIntegrationValue == "targetCompanyIndustry" && (
            <IndustryViewBox
              options={_targetCompanyIndustryOptions}
              value={_.map(_.get(_filter, "targetCompanyIndustry", []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, targetCompanyIndustry: options }
                })
              }
            />
          )}
          {_mergerIntegrationValue == "targetGeographicMarket" && (
            <SelectorView
              valueKey='targetGeographicMarket'
              options={_targetGeographicMarketOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_mergerIntegrationValue == "havingIncome" && (
            <SelectorView
              valueKey='havingIncome'
              options={_havingIncomeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_mergerIntegrationValue == "thereIsProfit" && (
            <SelectorView
              valueKey='thereIsProfit'
              options={_thereIsProfitOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_mergerIntegrationValue == "isFA" && (
            <SelectorView
              valueKey='isFA'
              options={_isFAOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_mergerIntegrationValue == "importantConsiderations" && (
            <SelectorView
              valueKey='importantConsiderations'
              options={_importantConsiderationsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_mergerIntegrationValue == "currentState" && (
            <SelectorView
              valueKey='currentState'
              options={_currentStateOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_mergerIntegrationValue == "otherRelatedRequirements" && (
            <SelectorView
              multiple={false}
              valueKey='otherRelatedRequirements'
              options={_otherRelatedRequirementsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}

          {_mergerIntegrationValue == "shareholdingRatio" && (
            <InputRatioView
              filter={_filter}
              valueKey="shareholdingRatio"
              onChange={(key, value) => this.setFilterValue(key, value, '')}
            />
          )}

          {_mergerIntegrationValue == "startTime" && (
            <DateRangeView filter={_filter} valueKey="startTime" onChange={val => {
              if (_.isUndefined(val[0]) && _.isUndefined(val[1])) {
                this.setFilterValue('startTime', null, '')
              } else {
                this.setFilterValue('startTime', val, '')
              }
            }} />
          )}



        </div>
      </TSideBarBox>
    )
  }

  renderSearchCriteria() {
    const { _filter, _currencyOptions, _unitOptions } = this.state
    return searchCriteriaHelp(
      _.concat(this.baseOptions, this.mergerIntegratioOptions),
      _filter,
      _unitOptions,
      _currencyOptions
    )

  }
}
/**找投资或并购目标 */
class ForCompanyQueryInvestmentMa extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 查询条件
      _filter: {},

      _total: 0,

      _baseValue: "industry",
      _financingValue: "financingScope",
      _sellValue: "sellFinancingScope",

      _industryOptions: [],
      _areaOptions: [],
      _typeOptions: [],
      _roundOptions: [],
      _momeyOptions: [],

      _plannedFinancingScaleOptions: [],
      _financingScopeOptions: [],
      _isFAOptions: [],
      _otherRelatedRequirementsOptions: [],

      _unitOptions: [],
      _currencyOptions: [],
    }

    this.tSearchViewRef = createRef()

    this.dropdownOptions = [
      { label: "基本信息", value: "base", filterKey: ['industry', 'area', 'type', 'round', 'money'] },
      {
        label: "融资相关", value: "financing", filterKey: [
          "financingScope", "financingIndustry", "plannedFinancingScale",
          "newStockTargetValuation", "isThereANeedToExitOldStocks", "oldStockTargetValuation",
          "startTime", "isFA", "otherRelatedRequirements"
        ]
      },
      {
        label: "出售相关", value: "sell", filterKey: [
          "sellFinancingScope", "sellFinancingIndustry", "stockTargetValuation",
          "sellEquity", "sellStartTime", "sellIsFA", "sellOtherRelatedRequirements",
        ]
      },
    ]

    this.baseOptions = [
      { label: "行业", value: "industry", filterKey: ['industry'] },
      { label: "地区", value: "area", filterKey: ['area'] },
      { label: "类型", value: "type", filterKey: ['type'] },
      { label: "融资轮次", value: "round", filterKey: ['round'] },
      { label: "估值", value: "money", filterKey: ['money'] },
    ]

    this.financingOptions = [
      { label: "融资范围", value: "financingScope", filterKey: ['financingScope'] },
      { label: "融资业务所处行业", value: "financingIndustry", filterKey: ['financingIndustry'] },
      { label: "计划融资规模", value: "plannedFinancingScale", filterKey: ['plannedFinancingScale'] },
      { label: "新股目标估值", value: "newStockTargetValuation", filterKey: ['newStockTargetValuation'], viewType: "ValuationViewRange" },
      { label: "是否有老股退出需求", value: "isThereANeedToExitOldStocks", filterKey: ['isThereANeedToExitOldStocks'] },
      { label: "老股目标估值", value: "oldStockTargetValuation", filterKey: ['oldStockTargetValuation'], viewType: "ValuationViewRange" },
      { label: "目标启动时间", value: "startTime", filterKey: ['startTime'], viewType: "DateRangeView" },
      { label: "是否有FA", value: "isFA", filterKey: ['isFA'] },
      { label: "其他相关需求", value: "otherRelatedRequirements", filterKey: ['otherRelatedRequirements'] },
    ]

    this.sellOptions = [
      { label: "融资范围", value: "sellFinancingScope", filterKey: ['sellFinancingScope'] },
      { label: "融资业务所处行业", value: "sellFinancingIndustry", filterKey: ['sellFinancingIndustry'] },
      { label: "目标估值", value: "stockTargetValuation", filterKey: ['stockTargetValuation'], viewType: "ValuationView" },
      { label: "出售股权", value: "sellEquity", filterKey: ['sellEquity'], viewType: "InputRatioView" },
      { label: "目标启动时间", value: "sellStartTime", filterKey: ['sellStartTime'], viewType: "DateRangeView" },
      { label: "是否有FA", value: "sellIsFA", filterKey: ['sellIsFA'] },
      { label: "其他相关需求", value: "sellOtherRelatedRequirements", filterKey: ['sellOtherRelatedRequirements'] },
    ]
  }
  componentDidMount() {
    this.initOptions()
  }

  initOptions() {
    let request = [
      /**Company基本搜索 */
      FetchYiKaiIndustrys(),
      FetchCompanyRegionOptions(),
      FetchCompanyTypeOptions(),
      FetchCompanyRoundOptions(),
      FetchCompanyValuationOptions(),



      FetchFinancingScopeOptions(),
      FetchTrueFlaseOptions(),
      FetchScaleOptions(),
      FetchUnits(),
      FetchCurrencyOptions(),

    ]

    if (_.size(request) > 0) {

      Promise.all(request).then(array => {
        let obj = {}
        _.map(array, (res, i) => {
          switch (i) {
            case 0:
              obj['_industryOptions'] = res
              break
            case 1:
              obj['_areaOptions'] = res
              break
            case 2:
              obj['_typeOptions'] = res
              break
            case 3:
              obj['_roundOptions'] = res
              break
            case 4:
              obj['_moneyOptions'] = res
              break
            /** */
            case 5:
              obj['_financingScopeOptions'] = res
              break
            case 6:
              obj['_isFAOptions'] = res
              obj['_otherRelatedRequirementsOptions'] = res
              break
            case 7:
              obj['_plannedFinancingScaleOptions'] = res
              break
            case 8:
              obj['_unitOptions'] = res
              break
            case 9:
              obj['_currencyOptions'] = res
              break
          }
        })

        this.setState({ ...obj })
      }).catch(msg => console.log("initOptions", msg))
    }


  }

  componentWillReceiveProps(props) {
    this.setState({ _total: props.total })
  }

  fetchDataList() {
    console.log("fetchDataList")
    const { _filter } = this.state
    const { onChange } = this.props
    onChange && onChange({ ..._filter })
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
  }

  /** */
  handleClearClick(key, data) {
    console.log("handleClearClick", key)
    const { _filter } = this.state
    let filter = { ..._filter }
    switch (key) {
      case 'all':
        this.setState({
          _filter: {}
        }, () => this.fetchDataList('close'))
        break
      case 'base':
        _.map(this.baseOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'financing':
        _.map(this.financingOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'sell':
        _.map(this.sellOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      /**分割 */
      case 'newStockTargetValuation':
      case 'oldStockTargetValuation':
      case 'stockTargetValuation':
      case 'shareholdingRatio':
      case 'startTime':
      case 'sellStartTime':
      case 'sellEquity':
        filter[key] = undefined
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      /**分割 */
      case 'industry':
      case 'area':
      case 'type':
      case 'round':
      case 'money':
      /**分割 */
      case 'financingScope':
      case 'financingIndustry':
      case 'plannedFinancingScale':
      case 'isThereANeedToExitOldStocks':
      case 'isFA':
      case 'otherRelatedRequirements':
      case 'sellFinancingScope':
      case 'sellFinancingIndustry':
      case 'sellIsFA':
      case 'sellOtherRelatedRequirements':
        filter[key] = _.filter(filter[key], ({ label, value }) => value != data)
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'close':
        if (this.tSearchViewRef && this.tSearchViewRef.current) {
          this.tSearchViewRef.current.closeDropdown()
        }
        break

    }
  }

  render() {
    const { _filter, _total } = this.state
    const { elementById } = this.props
    let searchCriteriaOptions = this.renderSearchCriteria()
    return (
      <TSearchView
        elementById={elementById}
        ref={this.tSearchViewRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          this.handleClearClick('close')
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
        //数据总数
        total={_total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={searchCriteriaOptions}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  /**渲染菜单 */
  renderSearchMenu(key) {
    let view = <div>{key}</div>
    switch (key) {
      case 'base':
        view = this.renderBaseMenu()
        break
      case 'financing':
        view = this.renderFinancingMenu()
        break
      case 'sell':
        view = this.renderSellMenu()
        break
    }

    return view
  }

  renderBaseMenu() {
    const {
      _filter, _baseValue,
      _industryOptions, _typeOptions, _areaOptions,
      _roundOptions, _moneyOptions
    } = this.state
    return (
      <TSideBarBox
        width="90px"
        filter={_filter}
        sideBarOptions={this.baseOptions}
        sideBarValue={_baseValue}
        onSideBarChange={val => this.setState({ _baseValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_baseValue == "industry" && (
            <IndustryViewBox
              options={_industryOptions}
              value={_.map(_.get(_filter, "industry", []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, industry: options }
                })
              }
            />
          )}
          {_baseValue == "area" && (
            <SelectorView
              valueKey='area'
              options={_areaOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "type" && (
            <SelectorView
              valueKey='type'
              options={_typeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}

          {_baseValue == "round" && (
            <SelectorView
              valueKey='round'
              options={_roundOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "money" && (
            <SelectorView
              multiple={false}
              valueKey='money'
              options={_moneyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderFinancingMenu() {
    const {
      _filter, _financingValue,
      _industryOptions, _financingScopeOptions, _plannedFinancingScaleOptions,
      _isFAOptions, _otherRelatedRequirementsOptions, _currencyOptions, _unitOptions
    } = this.state
    return (
      <TSideBarBox
        width="145px"
        filter={_filter}
        sideBarOptions={this.financingOptions}
        sideBarValue={_financingValue}
        onSideBarChange={val => this.setState({ _financingValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_financingValue == "financingScope" && (
            <SelectorView
              valueKey='financingScope'
              options={_financingScopeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_financingValue == "financingIndustry" && (
            <IndustryViewBox
              options={_industryOptions}
              value={_.map(_.get(_filter, "financingIndustry", []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, financingIndustry: options }
                })
              }
            />
          )}
          {_financingValue == "plannedFinancingScale" && (
            <SelectorView
              valueKey='plannedFinancingScale'
              options={_plannedFinancingScaleOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_financingValue == "newStockTargetValuation" && (
            <ValuationView
              type="range"
              filter={_filter}
              valueKey="newStockTargetValuation"
              currencyOptions={_currencyOptions}
              unitOptions={_unitOptions}
              onChange={val => {
                this.setFilterValue('newStockTargetValuation', val, '')
              }}
            />
          )}
          {_financingValue == "isThereANeedToExitOldStocks" && (
            <SelectorView
              valueKey='isThereANeedToExitOldStocks'
              options={_isFAOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_financingValue == "oldStockTargetValuation" && (
            <ValuationView
              type="range"
              filter={_filter}
              valueKey="oldStockTargetValuation"
              currencyOptions={_currencyOptions}
              unitOptions={_unitOptions}
              onChange={val => {
                this.setFilterValue('oldStockTargetValuation', val, '')
              }}
            />
          )}
          {_financingValue == "isFA" && (
            <SelectorView
              valueKey='isFA'
              options={_isFAOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_financingValue == "otherRelatedRequirements" && (
            <SelectorView
              multiple={false}
              valueKey='otherRelatedRequirements'
              options={_otherRelatedRequirementsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}

          {_financingValue == "startTime" && (
            <DateRangeView filter={_filter} valueKey="startTime" onChange={val => {
              if (_.isUndefined(val[0]) && _.isUndefined(val[1])) {
                this.setFilterValue('startTime', null, '')
              } else {
                this.setFilterValue('startTime', val, '')
              }
            }} />
          )}

        </div>
      </TSideBarBox>
    )
  }

  renderSellMenu() {
    const {
      _filter, _sellValue,
      _industryOptions, _financingScopeOptions, _plannedFinancingScaleOptions,
      _isFAOptions, _otherRelatedRequirementsOptions, _currencyOptions, _unitOptions
    } = this.state
    return (
      <TSideBarBox
        width="135px"
        filter={_filter}
        sideBarOptions={this.sellOptions}
        sideBarValue={_sellValue}
        onSideBarChange={val => this.setState({ _sellValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_sellValue == "sellFinancingScope" && (
            <SelectorView
              valueKey='sellFinancingScope'
              options={_financingScopeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_sellValue == "sellFinancingIndustry" && (
            <IndustryViewBox
              options={_industryOptions}
              value={_.map(_.get(_filter, "sellFinancingIndustry", []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, sellFinancingIndustry: options }
                })
              }
            />
          )}
          {_sellValue == "stockTargetValuation" && (
            <ValuationView
              filter={_filter}
              valueKey="stockTargetValuation"
              currencyOptions={_currencyOptions}
              unitOptions={_unitOptions}
              onChange={val => {
                this.setFilterValue('stockTargetValuation', val, '')
              }}
            />
          )}
          {_sellValue == "sellEquity" && (
            <InputRatioView
              filter={_filter}
              valueKey="sellEquity"
              onChange={(key, value) => this.setFilterValue(key, value, '')}
            />
          )}
          {_sellValue == "sellStartTime" && (
            <DateRangeView filter={_filter} valueKey="sellStartTime" onChange={val => {
              if (_.isUndefined(val[0]) && _.isUndefined(val[1])) {
                this.setFilterValue('sellStartTime', null, '')
              } else {
                this.setFilterValue('sellStartTime', val, '')
              }
            }} />
          )}
          {_sellValue == "sellIsFA" && (
            <SelectorView
              valueKey='sellIsFA'
              options={_isFAOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_sellValue == "sellOtherRelatedRequirements" && (
            <SelectorView
              multiple={false}
              valueKey='sellOtherRelatedRequirements'
              options={_otherRelatedRequirementsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderSearchCriteria() {
    const { _filter, _unitOptions, _currencyOptions } = this.state
    return searchCriteriaHelp(
      _.concat(this.baseOptions, this.financingOptions, this.sellOptions),
      _filter,
      _unitOptions,
      _currencyOptions
    )

  }
}
/**找IP购买方 */
class ForCompanyQueryIPBuyer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 查询条件
      _filter: {},

      _total: 0,

      _baseValue: "identity",
      _moreValue: "isSplitReorganize",

      _identityOptions: [],
      _typeOptions: [],
      _assetsOptions: [],
      _areaOptions: [],
      _currencyOptions: [],
      _momeyOptions: [],

      _industrysOptions: [],
      _financingScopeOptions: [],
      _isFAOptions: [],
      _unitOptions: [],
      _plannedFinancingScaleOptions: [],

      investmentOptions: [
        { label: "是否分拆/重组", value: "isSplitReorganize", filterKey: ['isSplitReorganize'], },
        { label: "是否涉及融资", value: "isFinancing", filterKey: ['isFinancing'], disabled: true },
        { label: "融资范围", value: "financingScope", filterKey: ['financingScope'], disabled: true },
        { label: "融资业务所处行业", value: "financingTargetIndustry", filterKey: ['financingTargetIndustry'], disabled: true },
        { label: "计划融资规模", value: "plannedFinancingScale", filterKey: ['plannedFinancingScale'], disabled: true },
        { label: "老股退出需求", value: "isThereANeedToExitOldStocks", filterKey: ['isThereANeedToExitOldStocks'], disabled: true },
        { label: "新股目标估值", value: "newStockTargetValuation", filterKey: ['newStockTargetValuation'], viewType: "ValuationViewRange", disabled: true },
        { label: "老股目标估值", value: "oldStockTargetValuation", filterKey: ['oldStockTargetValuation'], viewType: "ValuationViewRange", disabled: true },
        { label: "目标启动时间", value: "startTime", filterKey: ['startTime'], viewType: "DateRangeView", disabled: true },
        { label: "是否已有FA", value: "isFA", filterKey: ['isFA'], disabled: true },
        { label: "其他相关需求", value: "otherRelatedRequirements", filterKey: ['otherRelatedRequirements'], disabled: true },



      ]
    }

    this.tSearchViewRef = createRef()

    this.dropdownOptions = [
      { label: "基本信息", value: "base", filterKey: ['identity', 'type', 'assets', 'area', 'currency', 'money'] },
      {
        label: "分拆/重组相关", value: "investment", filterKey: [
          'isSplitReorganize', 'isFinancing', 'financingScope',
          'financingTargetIndustry', 'plannedFinancingScale',
          'isThereANeedToExitOldStocks', 'newStockTargetValuation', 'oldStockTargetValuation',
          'startTime', 'isFA', 'otherRelatedRequirements',
        ]
      },
    ]

    this.baseOptions = [
      { label: "身份", value: "identity", filterKey: ['identity'] },
      { label: "类型", value: "type", filterKey: ['type'] },
      { label: "资产类别", value: "assets", filterKey: ['assets'] },
      { label: "地区", value: "area", filterKey: ['area'] },
      { label: "币种", value: "currency", filterKey: ['currency'] },
      { label: "单笔投资金额", value: "money", filterKey: ['money'] },
    ]


  }
  componentDidMount() {
    this.initOptions()
  }

  initOptions() {
    let request = [
      /**GP基本搜索 */
      FetchInvestorIdentityOptions(),
      FetchInvestorTypeOptions(),
      FetchAssetCategoryOptions(),
      FetchRegionOptions(),
      FetchCurrencyOptions(),
      FetchScaleOptions(),

      /**是否有投资需求 */
      FetchYiKaiIndustrys(),
      FetchTrueFlaseOptions(),
      FetchFinancingScopeOptions(),
      FetchScaleOptions(),
      FetchUnits(),
      FetchCurrencyOptions(),
    ]

    if (_.size(request) > 0) {

      Promise.all(request).then(array => {
        let obj = {}
        _.map(array, (res, i) => {
          switch (i) {
            case 0:
              obj['_identityOptions'] = res
              break
            case 1:
              obj['_typeOptions'] = res
              break
            case 2:
              obj['_areaOptions'] = res
              break
            case 3:
              obj['_assetsOptions'] = res
              break
            case 4:
              obj['_currencyOptions'] = res
              break
            case 5:
              obj['_moneyOptions'] = res
              break
            /** */
            case 6:
              obj['_industrysOptions'] = res
              break
            case 7:
              obj['_isFAOptions'] = res
              break
            case 8:
              obj['_financingScopeOptions'] = res
              break
            case 9:
              obj['_plannedFinancingScaleOptions'] = res
              break
            case 10:
              obj['_unitOptions'] = res
              break
            case 11:
              obj['_currentStateOptions'] = res
              break
            case 12:
              obj['_currencyOptions'] = res
              break

          }
        })

        this.setState({ ...obj })
      }).catch(msg => console.log("initOptions", msg))
    }


  }

  componentWillReceiveProps(props) {
    this.setState({ _total: props.total })
  }

  fetchDataList() {
    console.log("fetchDataList")
    const { _filter } = this.state
    const { onChange } = this.props
    onChange && onChange({ ..._filter })
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
  }

  /**分拆重组 */
  setIsSplitReorganizeMenu(key, value, options){
    console.log("setIsSplitReorganizeMenu==>", key, value, options)
    const { _filter, investmentOptions } = this.state
    let filter = {..._filter};
    let _tempInvestmentOptions = [];
    if(_.includes(value, true)) {
      _tempInvestmentOptions = _.map(investmentOptions, (option)=> {
        if(key != option['value']) {
          filter[option['value']] = undefined
          if(option['value'] == "isFinancing") {
            return({...option, disabled: false})
          }else {
            return ({...option, disabled: true})
          }
        }else {
          return ({...option})
        }
      })
    }else {
      _tempInvestmentOptions = _.map(investmentOptions, (option)=> {
        if(key != option['value']) {
          filter[option['value']] = undefined
          return ({...option, disabled: true})
        }else {
          return ({...option})
        }
      })
    }

    console.log("filter===>", filter)

    this.setState({ 
      investmentOptions: _tempInvestmentOptions,
      _filter: { ...filter, [key]: _.filter(options, option => _.includes(value, option.value)) } 
    })
  }
  /**设计融资 */
  setIsFinancingValue(key, value, options){
    const { _filter, investmentOptions } = this.state
    let filter = {..._filter};
    let _tempInvestmentOptions = [...investmentOptions];
    if(_.includes(value, true)) {
      _tempInvestmentOptions = _.map(investmentOptions, (option)=> {
        if(!_.includes(["isFinancing", "otherRelatedRequirements", "isSplitReorganize"], option['value'])) {
          filter[option['value']] = undefined
          return({...option, disabled: false})
        }else {
          return ({...option})
        }
      })
    }else {
      _tempInvestmentOptions = _.map(investmentOptions, (option)=> {
        if(option['value'] == "otherRelatedRequirements"){
          filter[option['value']] = undefined
          if(_.includes(value, false)) {
            return ({...option, disabled: false})
          }else{
            return ({...option, disabled: true})
          }
        }else if(key != option['value'] && option['value'] != "isSplitReorganize") {
          filter[option['value']] = undefined
          return ({...option, disabled: true})
        }else {
          return ({...option})
        }
      })
    }
    this.setState({ 
      investmentOptions: _tempInvestmentOptions,
      _filter: { ...filter, [key]: _.filter(options, option => _.includes(value, option.value)) } 
    })
  }

  /** */
  handleClearClick(key, data) {
    console.log("handleClearClick", key)
    const { _filter, investmentOptions } = this.state
    let filter = { ..._filter }
    switch (key) {
      case 'all':
        this.setState({
          _filter: {}
        }, () => this.fetchDataList('close'))
        break
      case 'base':
        _.map(this.baseOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'investment':
        _.map(investmentOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'startTime':
      case 'newStockTargetValuation':
      case 'oldStockTargetValuation':
        filter[key] = undefined
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'identity':
      case 'type':
      case 'assets':
      case 'area':
      case 'money':
      case 'isSplitReorganize':
      case 'isFinancing':
      case 'financingScope':
      case 'financingTargetIndustry':
      case 'plannedFinancingScale':
      case 'isThereANeedToExitOldStocks':
      case 'isFA':
      case 'otherRelatedRequirements':
        filter[key] = _.filter(filter[key], ({ label, value }) => value != data)
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'close':
        if (this.tSearchViewRef && this.tSearchViewRef.current) {
          this.tSearchViewRef.current.closeDropdown()
        }
        break

    }
  }

  render() {
    const { _filter, _total } = this.state
    let searchCriteriaOptions = this.renderSearchCriteria()
    return (
      <TSearchView
        ref={this.tSearchViewRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          this.handleClearClick('close')
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
        //数据总数
        total={_total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={searchCriteriaOptions}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  /**渲染菜单 */
  renderSearchMenu(key) {
    let view = <div>{key}</div>
    switch (key) {
      case 'base':
        view = this.renderBaseMenu()
        break
      case 'investment':
        view = this.renderInvestmentMenu()
        break
    }

    return view
  }

  renderBaseMenu() {
    const {
      _filter, _baseValue,
      _identityOptions, _typeOptions, _assetsOptions,
      _areaOptions, _currencyOptions, _moneyOptions
    } = this.state
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.baseOptions}
        sideBarValue={_baseValue}
        onSideBarChange={val => this.setState({ _baseValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_baseValue == "identity" && (
            <SelectorView
              valueKey='identity'
              options={_identityOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "type" && (
            <SelectorView
              valueKey='type'
              options={_typeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "area" && (
            <SelectorView
              valueKey='area'
              options={_assetsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "assets" && (
            <SelectorView
              columns={1}
              valueKey='assets'
              options={_areaOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "currency" && (
            <SelectorView
              valueKey='assets'
              options={_currencyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "money" && (
            <SelectorView
              multiple={false}
              valueKey='money'
              options={_moneyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderInvestmentMenu() {
    const {
      _filter, _moreValue,
      _industrysOptions,
      _financingScopeOptions,
      _isFAOptions,
      _currencyOptions,
      _unitOptions,
      _plannedFinancingScaleOptions,
      investmentOptions
    } = this.state
    return (
      <TSideBarBox
        width="135px"
        filter={_filter}
        sideBarOptions={investmentOptions}
        sideBarValue={_moreValue}
        onSideBarChange={val => this.setState({ _moreValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>

          {_.includes(['plannedFinancingScale'], _moreValue) && (
            <SelectorView
              multiple={false}
              valueKey={_moreValue}
              options={_plannedFinancingScaleOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_.includes(['oldStockTargetValuation', 'newStockTargetValuation'], _moreValue) && (
            <ValuationView
              type="range"
              filter={_filter}
              valueKey={_moreValue}
              currencyOptions={_currencyOptions}
              unitOptions={_unitOptions}
              onChange={val => {
                this.setFilterValue(_moreValue, val, '')
              }}
            />
          )}
          {_.includes(['financingTargetIndustry'], _moreValue) && (
            <IndustryViewBox
              options={_industrysOptions}
              value={_.map(_.get(_filter, "financingTargetIndustry", []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, financingTargetIndustry: options }
                })
              }
            />
          )}

          {_.includes(['financingScope'], _moreValue) && (
            <SelectorView
              multiple={false}
              valueKey={_moreValue}
              options={_financingScopeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}

          {_.includes([
            'isThereANeedToExitOldStocks', 'isFA', 'otherRelatedRequirements'
          ], _moreValue) && (
              <SelectorView
                multiple={false}
                valueKey={_moreValue}
                options={_isFAOptions}
                filter={_filter}
                onChange={(key, value, options) => this.setFilterValue(key, value, options)}
              />
            )}

          {_moreValue == "startTime" && (
            <DateRangeView filter={_filter} valueKey="startTime" onChange={val => {
              if (_.isUndefined(val[0]) && _.isUndefined(val[1])) {
                this.setFilterValue('startTime', null, '')
              } else {
                this.setFilterValue('startTime', val, '')
              }
            }} />
          )}

          {_.includes(['isSplitReorganize'], _moreValue) && (
            <SelectorView
              multiple={false}
              valueKey={_moreValue}
              options={_isFAOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setIsSplitReorganizeMenu(key, value, options)}
            />
          )}
          {_.includes(['isFinancing'], _moreValue) && (
            <SelectorView
              multiple={false}
              valueKey={_moreValue}
              options={_isFAOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setIsFinancingValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderSearchCriteria() {
    const { _filter, investmentOptions, _unitOptions, _currencyOptions } = this.state
    // let searchCriteriaOptions = []

    // _.map(this.baseOptions, ({ label, value }) => {
    //   _.map(_.get(_filter, value, []), option => {
    //     searchCriteriaOptions.push({
    //       key: value,
    //       label: `${label}：${_.get(option, 'label', null)}`,
    //       value: _.get(option, 'value', null),
    //       group: label
    //     })
    //   })
    // })

    // _.map(investmentOptions, ({ label, value }) => {
    //   _.map(_.get(_filter, value, []), option => {
    //     if (_.includes(['newStockTargetValuation', 'oldStockTargetValuation'], value) && _.get(_filter, value, null) != null) {
    //       const val = _.get(_filter, value, null)

    //       let _moneyLabel = ''
    //       let start = _.get(val, 'value', [])[0]
    //       let end = _.get(val, 'value', [])[1]

    //       if (
    //         (start != '' && !_.isNull(start) && !_.isUndefined(start))
    //         &&
    //         (end != '' && !_.isNull(end) && !_.isUndefined(end))
    //       ) {
    //         _moneyLabel = `${start}-${end}`
    //       } else if (
    //         (start == '' || _.isNull(start) || _.isUndefined(start))
    //         &&
    //         (end != '' && !_.isNull(end) && !_.isUndefined(end))
    //       ) {
    //         _moneyLabel = `最大${end}`
    //       } else if (
    //         (start != '' && !_.isNull(start) && !_.isUndefined(start))
    //         &&
    //         (end == '' || _.isNull(end) || _.isUndefined(end))
    //       ) {
    //         _moneyLabel = `最小${start}`
    //       }
    //       const unitLabel = _.head(_.map(_.filter(_unitOptions, unit => _.includes(_.get(val, 'unit', []), unit['value'])), 'label'))

    //       const currencyLabel = _.head(_.map(_.filter(_currencyOptions, currency => _.includes(_.get(val, 'currency', []), currency['value'])), 'label'))

    //       searchCriteriaOptions.push({
    //         key: value,
    //         label: `${label}：${_moneyLabel}${unitLabel ? unitLabel : ''}${currencyLabel ? currencyLabel : ''}`,
    //         value: _.get(_filter, value, ''),
    //         group: label
    //       })
    //     } else if (value == "startTime" && (!_.isUndefined(_.get(_filter, value, [])[0]) || !_.isUndefined(_.get(_filter, value, [])[1]))) {
    //       let _label = ''
    //       let start = !_.isUndefined(_.get(_filter, value, [])[0]) ? moment(_.get(_filter, value, [])[0]).format('YYYY-MM') : null;
    //       let end = !_.isUndefined(_.get(_filter, value, [])[1]) ? moment(_.get(_filter, value, [])[1]).format('YYYY-MM') : null;

    //       if (!_.isNull(start) && !_.isNull(end)) {
    //         _label = `${label}：${start}至${end}`
    //       } else if (_.isNull(start) && !_.isNull(end)) {
    //         _label = `${label}：${end}之前`
    //       } else if (!_.isNull(start) && _.isNull(end)) {
    //         _label = `${label}：${start}之后`
    //       }


    //       searchCriteriaOptions.push({
    //         key: value,
    //         label: `${_label}`,
    //         value: '',
    //         group: label
    //       })
    //     }
    //     else {
    //       _.map(_.get(_filter, value, []), option => {
    //         searchCriteriaOptions.push({
    //           key: value,
    //           label: `${label}：${_.get(option, 'label', null)}`,
    //           value: _.get(option, 'value', null),
    //           group: label
    //         })
    //       })
    //     }
    //   })
    // })


    return searchCriteriaHelp(
      _.concat(this.baseOptions, investmentOptions),
      _filter,
      _unitOptions,
      _currencyOptions
    )

  }
}
/**找业务合作方 */
class ForCompanyQueryOverseasBusinessParener extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 查询条件
      _filter: {},

      _total: 0,

      _baseValue: "industry",
      _financingValue: "isNewStrategicDirection",

      _industryOptions: [],
      _areaOptions: [],
      _typeOptions: [],
      _roundOptions: [],
      _momeyOptions: [],

      _yesNoOptions: [],
    }

    this.tSearchViewRef = createRef()

    this.dropdownOptions = [
      { label: "基本信息", value: "base", filterKey: ['industry', 'area', 'type', 'round', 'money'] },
      {
        label: "策略及业务相关", value: "financing", filterKey: [
          "isNewStrategicDirection", "isNewBusiness", "isIndustrialChainIntegrationPlan",
          "isOverseasPlan"
        ]
      },
    ]

    this.baseOptions = [
      { label: "行业", value: "industry", filterKey: ['industry'] },
      { label: "地区", value: "area", filterKey: ['area'] },
      { label: "类型", value: "type", filterKey: ['type'] },
      { label: "融资轮次", value: "round", filterKey: ['round'] },
      { label: "估值", value: "money", filterKey: ['money'] },
    ]

    this.financingOptions = [
      { label: "是否有新战略方向", value: "isNewStrategicDirection", filterKey: ['isNewStrategicDirection'] },
      { label: "是否有新业务", value: "isNewBusiness", filterKey: ['isNewBusiness'] },
      { label: "是否有产业链整合计划", value: "isIndustrialChainIntegrationPlan", filterKey: ['isIndustrialChainIntegrationPlan'] },
      { label: "是否有出海计划", value: "isOverseasPlan", filterKey: ['isOverseasPlan'] },
    ]

  }
  componentDidMount() {
    this.initOptions()
  }

  initOptions() {
    let request = [
      /**Company基本搜索 */
      FetchYiKaiIndustrys(),
      FetchCompanyRegionOptions(),
      FetchCompanyTypeOptions(),
      FetchCompanyRoundOptions(),
      FetchCompanyValuationOptions(),


      FetchYesNoOptions(),

    ]

    if (_.size(request) > 0) {

      Promise.all(request).then(array => {
        let obj = {}
        _.map(array, (res, i) => {
          switch (i) {
            case 0:
              obj['_industryOptions'] = res
              break
            case 1:
              obj['_areaOptions'] = res
              break
            case 2:
              obj['_typeOptions'] = res
              break
            case 3:
              obj['_roundOptions'] = res
              break
            case 4:
              obj['_moneyOptions'] = res
              break
            /** */
            case 5:
              obj['_yesNoOptions'] = res
              break

          }
        })

        this.setState({ ...obj })
      }).catch(msg => console.log("initOptions", msg))
    }


  }

  componentWillReceiveProps(props) {
    this.setState({ _total: props.total })
  }

  fetchDataList() {
    console.log("fetchDataList")
    const { _filter } = this.state
    const { onChange } = this.props
    onChange && onChange({ ..._filter })
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
  }

  /** */
  handleClearClick(key, data) {
    console.log("handleClearClick", key)
    const { _filter } = this.state
    let filter = { ..._filter }
    switch (key) {
      case 'all':
        this.setState({
          _filter: {}
        }, () => this.fetchDataList('close'))
        break
      case 'base':
        _.map(this.baseOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'financing':
        _.map(this.financingOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'sell':
        _.map(this.sellOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      /**分割 */
      // case 'newStockTargetValuation':
      // case 'oldStockTargetValuation':
      // case 'stockTargetValuation':
      // case 'shareholdingRatio':
      // case 'startTime':
      // case 'sellStartTime':
      // case 'sellEquity':
      //   filter[key] = undefined
      //   this.setState({
      //     _filter: filter
      //   }, () => this.fetchDataList('close'))
      //   break
      /**分割 */
      case 'industry':
      case 'area':
      case 'type':
      case 'round':
      case 'money':
      /**分割 */
      case 'isNewStrategicDirection':
      case 'isNewBusiness':
      case 'isIndustrialChainIntegrationPlan':
      case 'isOverseasPlan':
        filter[key] = _.filter(filter[key], ({ label, value }) => value != data)
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'close':
        if (this.tSearchViewRef && this.tSearchViewRef.current) {
          this.tSearchViewRef.current.closeDropdown()
        }
        break

    }
  }

  render() {
    const { _filter, _total } = this.state
    const { elementById } = this.props
    let searchCriteriaOptions = this.renderSearchCriteria()
    return (
      <TSearchView
        elementById={elementById}
        ref={this.tSearchViewRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          this.handleClearClick('close')
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
        //数据总数
        total={_total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={searchCriteriaOptions}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  /**渲染菜单 */
  renderSearchMenu(key) {
    let view = <div>{key}</div>
    switch (key) {
      case 'base':
        view = this.renderBaseMenu()
        break
      case 'financing':
        view = this.renderFinancingMenu()
        break
    }

    return view
  }

  renderBaseMenu() {
    const {
      _filter, _baseValue,
      _industryOptions, _typeOptions, _areaOptions,
      _roundOptions, _moneyOptions
    } = this.state
    return (
      <TSideBarBox
        width="90px"
        filter={_filter}
        sideBarOptions={this.baseOptions}
        sideBarValue={_baseValue}
        onSideBarChange={val => this.setState({ _baseValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_baseValue == "industry" && (
            <IndustryViewBox
              options={_industryOptions}
              value={_.map(_.get(_filter, "industry", []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, industry: options }
                })
              }
            />
          )}
          {_baseValue == "area" && (
            <SelectorView
              valueKey='area'
              options={_areaOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "type" && (
            <SelectorView
              valueKey='type'
              options={_typeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}

          {_baseValue == "round" && (
            <SelectorView
              valueKey='round'
              options={_roundOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "money" && (
            <SelectorView
              multiple={false}
              valueKey='money'
              options={_moneyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderFinancingMenu() {
    const {
      _filter, _financingValue,
      _yesNoOptions
    } = this.state
    return (
      <TSideBarBox
        width="155px"
        filter={_filter}
        sideBarOptions={this.financingOptions}
        sideBarValue={_financingValue}
        onSideBarChange={val => this.setState({ _financingValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_.includes(["isNewStrategicDirection", "isNewBusiness", "isIndustrialChainIntegrationPlan",
            "isOverseasPlan"], _financingValue) && (
              <SelectorView
                columns={1}
                valueKey={_financingValue}
                options={_yesNoOptions}
                filter={_filter}
                onChange={(key, value, options) => this.setFilterValue(key, value, options)}
              />
            )}



        </div>
      </TSideBarBox>
    )
  }


  renderSearchCriteria() {
    const { _filter, _currencyOptions, _unitOptions } = this.state
    return searchCriteriaHelp(
      _.concat(this.baseOptions, this.financingOptions),
      _filter,
      _unitOptions,
      _currencyOptions
    )

  }
}
/**找LP/投资人 */
class ForCompanyQueryFundRaisingLPInvestor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 查询条件
      _filter: {},

      _total: 0,

      _baseValue: "identity",
      _fundraisingValue: "investmentFunds",

      _identityOptions: [],
      _typeOptions: [],
      _assetsOptions: [],
      _areaOptions: [],
      _currencyOptions: [],
      _momeyOptions: [],

      _investmentFundsOptions: [],
      _targetFundSizeOptions: [],
      _fundAttributesOptions: [],
      _currencyOptions: [],
      _unitOptions: []
    }

    this.tSearchViewRef = createRef()

    this.dropdownOptions = [
      { label: "基本信息", value: "base", filterKey: ['identity', 'type', 'assets', 'area', 'currency', 'money'] },
      {
        label: "募资相关", value: "fundraising", filterKey: [
          'investmentFunds', 'targetFundSize', 'startTime',
          'fundingGap', 'fundAttributes',
        ]
      },
    ]

    this.baseOptions = [
      { label: "身份", value: "identity", filterKey: ['identity'] },
      { label: "类型", value: "type", filterKey: ['type'] },
      { label: "资产类别", value: "assets", filterKey: ['assets'] },
      { label: "地区", value: "area", filterKey: ['area'] },
      { label: "币种", value: "currency", filterKey: ['currency'] },
      { label: "单笔投资金额", value: "money", filterKey: ['money'] },
    ]

    this.fundraisingOptions = [
      { label: "设立产业投资基金", value: "investmentFunds", filterKey: ['investmentFunds'] },
      { label: "目标基金规模", value: "targetFundSize", filterKey: ['targetFundSize'] },
      { label: "目标完成募集时间", value: "startTime", filterKey: ['startTime'], viewType: "DateRangeView" },
      { label: "资金缺口", value: "fundingGap", filterKey: ['fundingGap'], viewType: "ValuationView" },
      { label: "基金属性", value: "fundAttributes", filterKey: ['fundAttributes'] },
    ]
  }
  componentDidMount() {
    this.initOptions()
  }

  initOptions() {
    let request = [
      /**GP基本搜索 */
      FetchInvestorIdentityOptions(),
      FetchInvestorTypeOptions(),
      FetchAssetCategoryOptions(),
      FetchRegionOptions(),
      FetchCurrencyOptions(),
      FetchScaleOptions(),

      /**是否有并购整合需求 */
      FetchTrueFlaseOptions(),
      FetchFundingScaleOptions(),
      FetchFundAttributesOptions(),
      FetchCurrencyOptions(),
      FetchUnits()

    ]

    if (_.size(request) > 0) {

      Promise.all(request).then(array => {
        let obj = {}
        _.map(array, (res, i) => {
          switch (i) {
            case 0:
              obj['_identityOptions'] = res
              break
            case 1:
              obj['_typeOptions'] = res
              break
            case 2:
              obj['_areaOptions'] = res
              break
            case 3:
              obj['_assetsOptions'] = res
              break
            case 4:
              obj['_currencyOptions'] = res
              break
            case 5:
              obj['_moneyOptions'] = res
              break
            /** */
            case 6:
              obj['_investmentFundsOptions'] = res
              break
            case 7:
              obj['_targetFundSizeOptions'] = res
              break
            case 8:
              obj['_fundAttributesOptions'] = res
              break
            case 9:
              obj['_currencyOptions'] = res
              break
            case 10:
              obj['_unitOptions'] = res
              break
          }
        })

        this.setState({ ...obj })
      }).catch(msg => console.log("initOptions", msg))
    }


  }

  componentWillReceiveProps(props) {
    this.setState({ _total: props.total })
  }

  fetchDataList() {
    console.log("fetchDataList")
    const { _filter } = this.state
    const { onChange } = this.props
    onChange && onChange({ ..._filter })
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
  }

  /** */
  handleClearClick(key, data) {
    console.log("handleClearClick", key)
    const { _filter } = this.state
    let filter = { ..._filter }
    switch (key) {
      case 'all':
        this.setState({
          _filter: {}
        }, () => this.fetchDataList('close'))
        break
      case 'base':
        _.map(this.baseOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'fundraising':
        _.map(this.fundraisingOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'fundingGap':
      case 'startTime':
        filter[key] = undefined
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'identity':
      case 'type':
      case 'assets':
      case 'area':
      case 'money':
      /**分割 */
      case 'investmentFunds':
      case 'targetFundSize':
      case 'fundAttributes':
        filter[key] = _.filter(filter[key], ({ label, value }) => value != data)
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'close':
        if (this.tSearchViewRef && this.tSearchViewRef.current) {
          this.tSearchViewRef.current.closeDropdown()
        }
        break

    }
  }

  render() {
    const { _filter, _total } = this.state
    const { elementById } = this.props
    let searchCriteriaOptions = this.renderSearchCriteria()
    return (
      <TSearchView
        elementById={elementById}
        ref={this.tSearchViewRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          this.handleClearClick('close')
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
        //数据总数
        total={_total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={searchCriteriaOptions}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  /**渲染菜单 */
  renderSearchMenu(key) {
    let view = <div>{key}</div>
    switch (key) {
      case 'base':
        view = this.renderBaseMenu()
        break
      case 'fundraising':
        view = this.renderFundraisingMenu()
        break
    }

    return view
  }

  renderBaseMenu() {
    const {
      _filter, _baseValue,
      _identityOptions, _typeOptions, _assetsOptions,
      _areaOptions, _currencyOptions, _moneyOptions
    } = this.state
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.baseOptions}
        sideBarValue={_baseValue}
        onSideBarChange={val => this.setState({ _baseValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_baseValue == "identity" && (
            <SelectorView
              valueKey='identity'
              options={_identityOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "type" && (
            <SelectorView
              valueKey='type'
              options={_typeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "area" && (
            <SelectorView
              valueKey='area'
              options={_assetsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "assets" && (
            <SelectorView
              columns={1}
              valueKey='assets'
              options={_areaOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "currency" && (
            <SelectorView
              valueKey='assets'
              options={_currencyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "money" && (
            <SelectorView
              multiple={false}
              valueKey='money'
              options={_moneyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderFundraisingMenu() {
    const {
      _filter, _fundraisingValue, _currencyOptions, _unitOptions,
      _investmentFundsOptions, _targetFundSizeOptions, _fundAttributesOptions,
    } = this.state
    return (
      <TSideBarBox
        width="135px"
        filter={_filter}
        sideBarOptions={this.fundraisingOptions}
        sideBarValue={_fundraisingValue}
        onSideBarChange={val => this.setState({ _fundraisingValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_.includes(['investmentFunds'], _fundraisingValue) && (
            <SelectorView
              valueKey={_fundraisingValue}
              options={_investmentFundsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_.includes(['targetFundSize'], _fundraisingValue) && (
            <SelectorView
              multiple={false}
              valueKey={_fundraisingValue}
              options={_targetFundSizeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_.includes(['fundAttributes'], _fundraisingValue) && (
            <SelectorView
              valueKey={_fundraisingValue}
              options={_fundAttributesOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}


          {_.includes(['startTime'], _fundraisingValue) && (
            <DateRangeView filter={_filter} valueKey={_fundraisingValue} onChange={val => {
              if (_.isUndefined(val[0]) && _.isUndefined(val[1])) {
                this.setFilterValue(_fundraisingValue, null, '')
              } else {
                this.setFilterValue(_fundraisingValue, val, '')
              }
            }} />
          )}

          {_.includes(['fundingGap'], _fundraisingValue) && (
            <ValuationView
              filter={_filter}
              valueKey={_fundraisingValue}
              currencyOptions={_currencyOptions}
              unitOptions={_unitOptions}
              onChange={val => {
                this.setFilterValue(_fundraisingValue, val, '')
              }}
            />
          )}

        </div>
      </TSideBarBox>
    )
  }

  renderSearchCriteria() {
    const { _filter, _unitOptions, _currencyOptions } = this.state
    return searchCriteriaHelp(
      _.compact(this.baseOptions, this.fundraisingOptions),
      _filter,
      _unitOptions,
      _currencyOptions
    )

  }
}
/**找接续基金投资人/出资人*/
class ForCompanyQueryFollowOnFundLP extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 查询条件
      _filter: {},

      _total: 0,

      _baseValue: "identity",
      _projectValue: "projectName",
      _fundValue: "fundName",

      _identityOptions: [],
      _typeOptions: [],
      _assetsOptions: [],
      _areaOptions: [],
      _currencyOptions: [],
      _momeyOptions: [],

      _industryOptions: [],
      _unitOptions: []
    }

    this.tSearchViewRef = createRef()

    this.dropdownOptions = [
      { label: "基本信息", value: "base", filterKey: ['identity', 'type', 'assets', 'area', 'currency', 'money'] },
      {
        label: "项目投退", value: "project", filterKey: [
          'projectName', 'projectIndustry', 'projectStartTime',
          'projectInvestmentCost', 'projectCurrentValue',
          'projectShareholdingRatio'
        ]
      },
      {
        label: "基金投退", value: "fund", filterKey: [
          'fundName', 'fundShareholdingRatio', 'fundStartTime',
          'fundInvestmentCost', 'fundCurrentValue',
          'fundManager', 'fundProjectScope'
        ]
      },
    ]

    this.baseOptions = [
      { label: "身份", value: "identity", filterKey: ['identity'] },
      { label: "类型", value: "type", filterKey: ['type'] },
      { label: "资产类别", value: "assets", filterKey: ['assets'] },
      { label: "地区", value: "area", filterKey: ['area'] },
      { label: "币种", value: "currency", filterKey: ['currency'] },
      { label: "单笔投资金额", value: "money", filterKey: ['money'] },
    ]

    this.projectOptions = [
      { label: "项目名称", value: "projectName", filterKey: ['projectName'], viewType: "InputView" },
      { label: "项目所处行业", value: "projectIndustry", filterKey: ['projectIndustry'] },
      { label: "投资时间", value: "projectStartTime", filterKey: ['projectStartTime'], viewType: "DateRangeView" },
      { label: "投资成本", value: "projectInvestmentCost", filterKey: ['projectInvestmentCost'], viewType: "ValuationView" },
      { label: "当前价值", value: "projectCurrentValue", filterKey: ['projectCurrentValue'], viewType: "ValuationView" },
      { label: "持股比例", value: "projectShareholdingRatio", filterKey: ['projectShareholdingRatio'], viewType: "InputRatioView" },
    ]

    this.fundOptions = [
      { label: "基金名称", value: "fundName", filterKey: ['fundName'], viewType: "InputView" },
      { label: "基金持股", value: "fundShareholdingRatio", filterKey: ['fundShareholdingRatio'], viewType: "InputRatioView" },
      { label: "投资时间", value: "fundStartTime", filterKey: ['fundStartTime'], viewType: "DateRangeView" },
      { label: "投资成本", value: "fundInvestmentCost", filterKey: ['fundInvestmentCost'], viewType: "ValuationView" },
      { label: "当前价值", value: "fundCurrentValue", filterKey: ['fundCurrentValue'], viewType: "ValuationView" },
      { label: "基金管理人（GP）", value: "fundManager", filterKey: ['fundManager'], viewType: "InputView" },
      { label: "项目范围", value: "fundProjectScope", filterKey: ['fundProjectScope'] },
    ]
  }
  componentDidMount() {
    this.initOptions()
  }

  initOptions() {
    let request = [
      /**GP基本搜索 */
      FetchInvestorIdentityOptions(),
      FetchInvestorTypeOptions(),
      FetchAssetCategoryOptions(),
      FetchRegionOptions(),
      FetchCurrencyOptions(),
      FetchScaleOptions(),


      FetchYiKaiIndustrys(),
      FetchCurrencyOptions(),
      FetchUnits()

    ]

    if (_.size(request) > 0) {

      Promise.all(request).then(array => {
        let obj = {}
        _.map(array, (res, i) => {
          switch (i) {
            case 0:
              obj['_identityOptions'] = res
              break
            case 1:
              obj['_typeOptions'] = res
              break
            case 2:
              obj['_areaOptions'] = res
              break
            case 3:
              obj['_assetsOptions'] = res
              break
            case 4:
              obj['_currencyOptions'] = res
              break
            case 5:
              obj['_moneyOptions'] = res
              break
            /** */
            case 6:
              obj['_industryOptions'] = res
              break
            case 7:
              obj['_currencyOptions'] = res
              break
            case 8:
              obj['_unitOptions'] = res
              break
          }
        })

        this.setState({ ...obj })
      }).catch(msg => console.log("initOptions", msg))
    }


  }

  componentWillReceiveProps(props) {
    this.setState({ _total: props.total })
  }

  fetchDataList() {
    console.log("fetchDataList")
    const { _filter } = this.state
    const { onChange } = this.props
    onChange && onChange({ ..._filter })
  }

  setFilterValue(key, value, options) {
    const { _filter } = this.state
    if (_.isArray(value) && _.isArray(options)) {
      this.setState({ _filter: { ..._filter, [key]: _.filter(options, option => _.includes(value, option.value)) } })
    } else {
      this.setState({ _filter: { ..._filter, [key]: value } })
    }
  }

  /** */
  handleClearClick(key, data) {
    console.log("handleClearClick", key)
    const { _filter } = this.state
    let filter = { ..._filter }
    switch (key) {
      case 'all':
        this.setState({
          _filter: {}
        }, () => this.fetchDataList('close'))
        break
      case 'base':
        _.map(this.baseOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'project':
        _.map(this.projectOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'fund':
        _.map(this.fundOptions, option => {
          filter[option['value']] = undefined
        })
        this.setState({
          _filter: filter
        }, () => this.handleClearClick('close'))
        break
      case 'projectName':
      case 'projectStartTime':
      case 'projectShareholdingRatio':
      case 'projectInvestmentCost':
      case 'projectCurrentValue':
      case 'fundName':
      case 'fundShareholdingRatio':
      case 'fundStartTime':
      case 'fundInvestmentCost':
      case 'fundCurrentValue':
      case 'fundManager':
        filter[key] = undefined
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'identity':
      case 'type':
      case 'assets':
      case 'area':
      case 'money':
      /**分割 */
      case 'projectIndustry':
      case 'fundProjectScope':
        filter[key] = _.filter(filter[key], ({ label, value }) => value != data)
        this.setState({
          _filter: filter
        }, () => this.fetchDataList('close'))
        break
      case 'close':
        if (this.tSearchViewRef && this.tSearchViewRef.current) {
          this.tSearchViewRef.current.closeDropdown()
        }
        break

    }
  }

  render() {
    const { _filter, _total } = this.state
    const { elementById } = this.props
    let searchCriteriaOptions = this.renderSearchCriteria()
    return (
      <TSearchView
        elementById={elementById}
        ref={this.tSearchViewRef}
        //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
        dropdownOptions={this.dropdownOptions}
        //选中数据集
        filter={_filter}
        //重置按钮 key 对用dropdownOptions的value
        onReSetting={key => {
          this.handleClearClick(key)
        }}
        //确定按钮key 对用dropdownOptions的value
        onComfirm={key => {
          this.handleClearClick('close')
        }}
        //val == null 的时候 dorpdown 关闭，查询数据
        onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
        //数据总数
        total={_total}
        //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
        //选中搜索条件集合
        filterTargetOptions={searchCriteriaOptions}
        //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
        onFilterTargetItemClearClick={option => {
          this.handleClearClick(option['key'], option['value'])
        }}
        //清楚全部搜索条件
        onFilterTargetAllClearClick={() => {
          this.handleClearClick('all')
        }}
      >
        {/* key 对用dropdownOptions的value */}
        {(key) => this.renderSearchMenu(key)}
      </TSearchView>
    )
  }

  /**渲染菜单 */
  renderSearchMenu(key) {
    let view = <div>{key}</div>
    switch (key) {
      case 'base':
        view = this.renderBaseMenu()
        break
      case 'project':
        view = this.renderProjectMenu()
        break
      case 'fund':
        view = this.renderFundMenu()
        break
    }

    return view
  }

  renderBaseMenu() {
    const {
      _filter, _baseValue,
      _identityOptions, _typeOptions, _assetsOptions,
      _areaOptions, _currencyOptions, _moneyOptions
    } = this.state
    return (
      <TSideBarBox
        filter={_filter}
        sideBarOptions={this.baseOptions}
        sideBarValue={_baseValue}
        onSideBarChange={val => this.setState({ _baseValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_baseValue == "identity" && (
            <SelectorView
              valueKey='identity'
              options={_identityOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "type" && (
            <SelectorView
              valueKey='type'
              options={_typeOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "area" && (
            <SelectorView
              valueKey='area'
              options={_assetsOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "assets" && (
            <SelectorView
              columns={1}
              valueKey='assets'
              options={_areaOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "currency" && (
            <SelectorView
              valueKey='assets'
              options={_currencyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_baseValue == "money" && (
            <SelectorView
              multiple={false}
              valueKey='money'
              options={_moneyOptions}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderProjectMenu() {
    const {
      _filter, _projectValue, _currencyOptions, _unitOptions, _industryOptions,
    } = this.state
    return (
      <TSideBarBox
        width="135px"
        filter={_filter}
        sideBarOptions={this.projectOptions}
        sideBarValue={_projectValue}
        onSideBarChange={val => this.setState({ _projectValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>
          {_.includes(['projectName'], _projectValue) && (
            <InputView
              valueKey={_projectValue}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_.includes(['projectIndustry'], _projectValue) && (
            <IndustryViewBox
              options={_industryOptions}
              value={_.map(_.get(_filter, _projectValue, []), 'value')}
              onChange={options =>
                this.setState({
                  _filter: { ..._filter, [_projectValue]: options }
                })
              }
            />
          )}
          {_.includes(['projectStartTime'], _projectValue) && (
            <DateRangeView filter={_filter} valueKey={_projectValue} onChange={val => {
              if (_.isUndefined(val[0]) && _.isUndefined(val[1])) {
                this.setFilterValue(_projectValue, null, '')
              } else {
                this.setFilterValue(_projectValue, val, '')
              }
            }} />
          )}
          {_.includes(['projectInvestmentCost', 'projectCurrentValue'], _projectValue) && (
            <ValuationView
              filter={_filter}
              valueKey={_projectValue}
              currencyOptions={_currencyOptions}
              unitOptions={_unitOptions}
              onChange={val => {
                this.setFilterValue(_projectValue, val, '')
              }}
            />
          )}
          {_.includes(['projectShareholdingRatio'], _projectValue) && (
            <InputRatioView
              filter={_filter}
              valueKey={_projectValue}
              onChange={(key, value) => this.setFilterValue(key, value, '')}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderFundMenu() {
    const {
      _filter, _fundValue, _currencyOptions, _unitOptions,
    } = this.state
    return (
      <TSideBarBox
        width="135px"
        filter={_filter}
        sideBarOptions={this.fundOptions}
        sideBarValue={_fundValue}
        onSideBarChange={val => this.setState({ _fundValue: val })}
      >
        <div style={{ height: "100%", overflow: "auto" }}>

          {_.includes(['fundName'], _fundValue) && (
            <InputView
              valueKey={_fundValue}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
          {_.includes(['fundShareholdingRatio'], _fundValue) && (
            <InputRatioView
              filter={_filter}
              valueKey={_fundValue}
              onChange={(key, value) => this.setFilterValue(key, value, '')}
            />
          )}
          {_.includes(['fundStartTime'], _fundValue) && (
            <DateRangeView filter={_filter} valueKey={_fundValue} onChange={val => {
              if (_.isUndefined(val[0]) && _.isUndefined(val[1])) {
                this.setFilterValue(_fundValue, null, '')
              } else {
                this.setFilterValue(_fundValue, val, '')
              }
            }} />
          )}
          {_.includes(['fundInvestmentCost', 'fundCurrentValue'], _fundValue) && (
            <ValuationView
              filter={_filter}
              valueKey={_fundValue}
              currencyOptions={_currencyOptions}
              unitOptions={_unitOptions}
              onChange={val => {
                this.setFilterValue(_fundValue, val, '')
              }}
            />
          )}
          {_.includes(['fundManager'], _fundValue) && (
            <InputView
              filter={_filter}
              valueKey={_fundValue}
              onChange={(key, value) => this.setFilterValue(key, value, '')}
            />
          )}
          {_.includes(['fundProjectScope'], _fundValue) && (
            <SelectorView
              valueKey={_fundValue}
              options={[
                { label: "全部项目", value: "ALL" },
                { label: "部分项目", value: "PART" },
              ]}
              filter={_filter}
              onChange={(key, value, options) => this.setFilterValue(key, value, options)}
            />
          )}
        </div>
      </TSideBarBox>
    )
  }

  renderSearchCriteria() {
    const { _filter, _unitOptions, _currencyOptions } = this.state
    return searchCriteriaHelp(
      _.concat(this.baseOptions, this.projectOptions,this.fundOptions),
      _filter,
      _unitOptions,
      _currencyOptions
    )

  }
}
/** */
function searchCriteriaHelp(options, filter, unitOptions, currencyOptions){
  let searchCriteriaOptions = []
  let _filter = filter, _unitOptions = unitOptions, _currencyOptions = currencyOptions
  _.map(
    options,
    ({ label, value, viewType }) => {
      let start, end, _moneyLabel, unitLabel, currencyLabel, val;
      if(_.get(filter, value, null) != null) {
        switch (viewType) {
          case 'InputView':
            searchCriteriaOptions.push({
              key: value,
              label: `${label}：${_.get(_filter, value, '')}`,
              value: _.get(_filter, value, ''),
              group: label
            })
            break
          case 'ValuationView':
            val = _.get(_filter, value, null)

            _moneyLabel = _.get(val, 'value', '')
            
            unitLabel = _.head(_.map(_.filter(_unitOptions, unit => _.includes(_.get(val, 'unit', []), unit['value'])), 'label'))

            currencyLabel = _.head(_.map(_.filter(_currencyOptions, currency => _.includes(_.get(val, 'currency', []), currency['value'])), 'label'))

            searchCriteriaOptions.push({
              key: value,
              label: `${label}：${_moneyLabel}${unitLabel ? unitLabel : ''}${currencyLabel ? currencyLabel : ''}`,
              value: _.get(_filter, value, ''),
              group: label
            })
            break
          case 'ValuationViewRange':
            val = _.get(_filter, value, null)

            _moneyLabel = ''
            start = _.get(val, 'value', [])[0]
            end = _.get(val, 'value', [])[1]

            if (
              (start != '' && !_.isNull(start) && !_.isUndefined(start))
              &&
              (end != '' && !_.isNull(end) && !_.isUndefined(end))
            ) {
              _moneyLabel = `${start}-${end}`
            } else if (
              (start == '' || _.isNull(start) || _.isUndefined(start))
              &&
              (end != '' && !_.isNull(end) && !_.isUndefined(end))
            ) {
              _moneyLabel = `最大${end}`
            } else if (
              (start != '' && !_.isNull(start) && !_.isUndefined(start))
              &&
              (end == '' || _.isNull(end) || _.isUndefined(end))
            ) {
              _moneyLabel = `最小${start}`
            }
            unitLabel = _.head(_.map(_.filter(_unitOptions, unit => _.includes(_.get(val, 'unit', []), unit['value'])), 'label'))

            currencyLabel = _.head(_.map(_.filter(_currencyOptions, currency => _.includes(_.get(val, 'currency', []), currency['value'])), 'label'))

            searchCriteriaOptions.push({
              key: value,
              label: `${label}：${_moneyLabel}${unitLabel ? unitLabel : ''}${currencyLabel ? currencyLabel : ''}`,
              value: _.get(_filter, value, ''),
              group: label
            })
            break
          case 'DateRangeView':
            let _label = ''
            start = !_.isUndefined(_.get(_filter, value, [])[0]) ? moment(_.get(_filter, value, [])[0]).format('YYYY-MM') : null;
            end = !_.isUndefined(_.get(_filter, value, [])[1]) ? moment(_.get(_filter, value, [])[1]).format('YYYY-MM') : null;
            if (!_.isNull(start) && !_.isNull(end)) {
              _label = `${label}：${start}至${end}`
            } else if (_.isNull(start) && !_.isNull(end)) {
              _label = `${label}：${end}之前`
            } else if (!_.isNull(start) && _.isNull(end)) {
              _label = `${label}：${start}之后`
            }
            searchCriteriaOptions.push({
              key: value,
              label: `${_label}`,
              value: '',
              group: label
            })
            break
          case 'InputRatioView':
            searchCriteriaOptions.push({
              key: value,
              label: `${label}：${_.get(_filter, value, '')}%`,
              value: _.get(_filter, value, ''),
              group: label
            })
            break
          default:
            _.map(_.get(_filter, value, []), option => {
              searchCriteriaOptions.push({
                key: value,
                label: `${label}：${_.get(option, 'label', null)}`,
                value: _.get(option, 'value', null),
                group: label
              })
            })
            break
        }
      }
    }
  )
  return searchCriteriaOptions
}
/**估值组件 */
function ValuationView({ type, filter, valueKey, onChange, unitOptions, currencyOptions }) {
  const [initFlag, setInitFlag] = useState(false)
  const [unit, setUnit] = useState(_.get(_.get(filter, valueKey, {}), 'unit', null))
  const [currency, setCurrency] = useState(_.get(_.get(filter, valueKey, {}), 'currency', null))
  const [value, setValue] = useState(_.get(_.get(filter, valueKey, {}), 'value', type == "range" ? ['', ''] : null))

  useEffect(() => {
    init(initFlag)
  }, [filter, valueKey])

  function init(flag) {
    let val = _.get(filter, valueKey, null)
    if (!flag) {
      setCurrency(_.get(val, 'currency', null))
      setUnit(_.get(val, 'unit', null))
    }
    setValue(_.get(val, 'value', type == "range" ? ['', ''] : null))
    setInitFlag(true)
  }

  function setChangeClick(_val) {
    setValue(_val)
    if (onChange) {
      if (type == "range") {
        let isValue = _.isArray(_val) && _val[0] == '' && _val[1] == '' ? false : true
        if (isValue) {
          onChange({
            value: _val,
            unit: unit ? unit : null,
            currency: currency ? currency : null
          })
        } else {
          onChange(null)
        }
      } else {
        if (_val) {
          onChange({
            value: _val,
            unit: unit ? unit : null,
            currency: currency ? currency : null
          })
        } else {
          onChange(null)
        }
      }
    }
  }

  return (
    <div style={{ padding: 8, fontSize: "13px" }}>
      <div style={{ marginBottom: 6 }}>币种</div>
      <Selector
        multiple={false}
        style={{ '--padding': '4px', fontSize: '13px', marginBottom: 8 }}
        columns={3}
        options={currencyOptions}
        value={currency}
        onChange={val => {
          setCurrency(val)
        }}
      />
      <div style={{ marginBottom: 6 }}>单位</div>
      <Selector
        multiple={false}
        style={{ '--padding': '4px', fontSize: '13px', marginBottom: 8 }}
        columns={3}
        options={unitOptions}
        value={unit}
        onChange={val => {
          setUnit(val)
        }}
      />
      {type == "range" ? (
        <Fragment>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', padding: 8, borderBottom: '1px solid #eeeeee' }}>
            <div>起</div>
            <Input
              style={{ '--font-size': '13px' }}
              type="number"
              clearable
              placeholder="请输入"
              value={_.isArray(value) ? value[0] : ''}
              onChange={val => {
                setChangeClick([val, value[1]])
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', padding: 8, borderBottom: '1px solid #eeeeee' }}>
            <div>止</div>
            <Input
              style={{ '--font-size': '13px' }}
              type="number"
              clearable
              placeholder="请输入"
              value={_.isArray(value) ? value[1] : ''}
              onChange={val => {
                setChangeClick([value[0], val])
              }}
            />
          </div>
        </Fragment>
      ) : (
        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', padding: 8, borderBottom: '1px solid #eeeeee' }}>
          <Input
            style={{ '--font-size': '13px' }}
            type="number"
            clearable
            placeholder="请输入"
            value={value != null ? value : ''}
            onChange={val => setChangeClick(val)}
          />
        </div>
      )}
    </div>
  )
}
/**选择组件 */
function SelectorView({ options, filter, valueKey, onChange, multiple = true, columns = 2 }) {
  return (
    <div style={{ padding: 8 }}>
      <Selector
        multiple={multiple}
        style={{ '--padding': '4px', fontSize: '13px' }}
        columns={columns}
        options={options}
        value={_.map(_.get(filter, valueKey, []), 'value')}
        onChange={val => onChange && onChange(valueKey, val, options)}
      />
    </div>
  )
}
/**易凯行业组件 */
function IndustryViewBox({ options, value, onChange }) {

  const _allOptions = []
  _.map(options, it => {
    _allOptions.push({ ...it })
    _.map(_.get(it, 'children', []), sub => {
      _allOptions.push({ ...sub })
    })
  })
  return (
    <IndustryView
      multiple={true}
      options={options}
      values={value}
      onChange={val => {
        let selectOptions = [], parentIds = []
        _.map(_allOptions, option => {
          if (_.includes(val, option['value'])) {
            if (option['pId']) {
              parentIds.push(option['pId'])
            }
          }
        })

        let value = Array.from(new Set(_.concat(val, parentIds)))

        _.map(_allOptions, option => {
          if (_.includes(value, option['value'])) {
            selectOptions.push(option)
          }
        })

        onChange && onChange(selectOptions)
      }}
    />
  )
}
/**时间范围组件 */
function DateRangeView({ filter, valueKey, onChange }) {
  const [startDateVisible, setStartDateVisible] = useState(false)
  const [endDateVisible, setEndDateVisible] = useState(false)
  const [value, setValue] = useState(_.get(filter, valueKey, []))

  return (
    <Fragment>
      <List style={{ "--border-top": "none", "--font-size": "13px" }}>
        <List.Item onClick={() => { setStartDateVisible(true) }} extra={_.isArray(value) && _.size(value) > 0 && value[0] ? <Space onClick={(e) => { e.stopPropagation(); setValue([undefined, value[1]]); onChange && onChange([undefined, value[1]]) }}><div>{moment(value[0]).format('YYYY-MM')}</div><div><CloseCircleOutline /></div></Space> : <div style={{ fontSize: 13 }}>请选择</div>}>开始时间</List.Item>
        <List.Item onClick={() => { setEndDateVisible(true) }} extra={_.isArray(value) && _.size(value) > 0 && value[1] ? <Space onClick={(e) => { e.stopPropagation(); setValue([value[0], undefined]); onChange && onChange([value[0], undefined]) }}><div>{moment(value[1]).format('YYYY-MM')}</div><div><CloseCircleOutline /></div></Space> : <div style={{ fontSize: 13 }}>请选择</div>}>结束时间</List.Item>
      </List>

      <DatePicker
        title='开始时间选择'
        visible={startDateVisible}
        onClose={() => setStartDateVisible(false)}
        precision='month'
        onConfirm={val => {
          setValue([val, value[1]])
          onChange && onChange([val, value[1]])
        }}
      />

      <DatePicker
        title='结束时间选择'
        visible={endDateVisible}
        precision='month'
        onClose={() => { setEndDateVisible(false) }}
        onConfirm={val => {
          setValue([value[0], val])
          onChange && onChange([value[0], val])
        }}
      />
    </Fragment>
  )
}
/**百分比组件 */
function InputRatioView({ filter, valueKey, onChange }) {
  return (
    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', padding: 8, borderBottom: '1px solid #eeeeee' }}>
      <Input
        style={{ '--font-size': '13px' }}
        type="number"
        clearable
        placeholder="请输入"
        value={_.get(filter, valueKey, '')}
        onChange={value => onChange && onChange(valueKey, value, '')}
      />
      <div style={{ marginLeft: 8, color: "#999", padding: '4px 0px', textAlign: 'center' }}>%</div>
    </div>
  )
}
/**Input组件 */
function InputView({ filter, valueKey, onChange }) {
  return (
    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', padding: 8, borderBottom: '1px solid #eeeeee' }}>
      <Input
        style={{ '--font-size': '13px' }}
        clearable
        placeholder="请输入"
        value={_.get(filter, valueKey, '')}
        onChange={value => onChange && onChange(valueKey, value, '')}
      />
    </div>
  )
}