import React, { Fragment, useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Modal, Toast, Ellipsis } from 'antd-mobile';
import InfoDetailBaseItem, {
  InfoDetailBaseItemTagComponent,
  InfoDetailBaseItemIndustryComponent,
  InfoDetailBaseItemCloseadbleTagComponent
} from '../InfoDetailBaseItem';
import CECSelectPopup from '../../../../../../components/SelectPopup';
import {
  FetchCompanyTags,
  AddCompanyTags,
  AddNewCompanyTag,
  DeleteCompanyTag,
} from '../transform';
import './index.less';

/**
 * 公司基本信息
 */
export default function CompanyBaseInfoView({ value = {}, onRefresh }) {

  const [tagSearchKey, setTagSearchKey] = useState('')
  const tagsSelectRef = useRef(null);

  const handleClick = useCallback(async (key, data, event) => {
    // console.log("handleClick =>", key, data, event)
    switch (key) {
      case 'handle_delete_tag':
        Modal.confirm({
          title: '删除',
          content: '确定删除该标签？',
          onConfirm: async () => await deleteCompanyTag(data['id']),
        })
        break;
      case 'handle_open_add_tag_popup':
        tagsSelectRef?.current?.show()
        break;
      case 'handle_add_tags':
        await addCompanyTags(_.map(data, "value"))
        break;
      case 'handle_new_tag':
        await addNewCompanyTag(data)
        break;
      default:
        break;
    }
  })

  const fetchCompanyTags = async ({ searchKey, pageNum, pageSize }) => {
    try {
      const response = await FetchCompanyTags({ searchKey, pageNum, pageSize })
      return response
    } catch (error) {
      Toast.show({ icon: 'fail', content: error })
    }
  }
  const addCompanyTags = async (tagCodes) => {
    try {
      Toast.show({ icon: 'loading', content: '保存中...' })
      await AddCompanyTags({ companyCode: value['code'], tagCodes: _.concat(_.map(value['tags'], "value"), tagCodes) })
      Toast.clear()
    } catch (error) {
      Toast.show({ icon: 'fail', content: error })
    } finally {
      onRefresh && onRefresh()
    }
  }
  const addNewCompanyTag = async (tagKey) => {
    try {
      Toast.show({ icon: 'loading', content: '保存中...' })
      await AddNewCompanyTag({ companyCode: value['code'], tagKey, exsitTagCodes: _.map(value['tags'], "value") })
      tagsSelectRef?.current?.close()
      Toast.clear()
    } catch (error) {
      Toast.show({ icon: 'fail', content: error })
    } finally {
      onRefresh && onRefresh()
    }
  }
  const deleteCompanyTag = async (tagId) => {
    try {
      Toast.show({ icon: 'loading' })
      await DeleteCompanyTag({ companyCode: value['code'], tagId })
      Toast.clear()
    } catch (error) {
      Toast.show({ icon: 'fail', content: error })
    } finally {
      onRefresh && onRefresh()
    }
  }

  return (
    <Fragment>
      <div className="info-detail-base-item-box">
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="类型" renderComponent={() => value['types'] && _.size(value['types']) > 0 && <InfoDetailBaseItemTagComponent datas={value['types']} />} />
        </div>
        <div className='info-detail-space'>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="注册名称" renderComponent={() => value['fullName'] && <span>{value['fullName']}</span>} />
          </div>
          <div className='info-detail-space-column'>

          </div>
        </div>

        <div className='info-detail-space'>
          <InfoDetailBaseItem label="地区" renderComponent={() => value['nation'] && value['location'] && <span>{value['nation']} - {value['location']}</span>} />
        </div>
        <div className='info-detail-space'>
          <div className='info-detail-space-column'>

            <InfoDetailBaseItem label="成立时间" renderComponent={() => value['compBornYear'] && value['compBornMonth'] && <span>{value['compBornYear']}年{value['compBornMonth']}月</span>} />
          </div>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="公司规模" renderComponent={() => value['compScale'] && <span>{value['compScale']}</span>} />
          </div>
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="简介" renderComponent={() => value['description'] && <Ellipsis direction='end' rows={2} expandText='展开' collapseText='收起' content={value['description']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="易凯行业" renderComponent={() => value['ecapitalIndustrys'] && _.size(value['ecapitalIndustrys']) > 0 && <InfoDetailBaseItemIndustryComponent datas={value['ecapitalIndustrys']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="企名片" renderComponent={() => value['industrys'] && _.size(value['industrys']) > 0 && <InfoDetailBaseItemIndustryComponent datas={value['industrys']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="标签" renderComponent={() => <InfoDetailBaseItemCloseadbleTagComponent datas={value['tags']} onDelete={handleClick.bind(null, 'handle_delete_tag')} onAdd={handleClick.bind(null, 'handle_open_add_tag_popup')} />} />
        </div>
      </div>

      {/** 选择标签列表 */}
      <CECSelectPopup ref={tagsSelectRef}
        title={"标签"}
        supportSearch={true}
        supportNotFound={() => (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: 5 }}>
            <Button color='primary' fill='none' onClick={handleClick.bind(null, 'handle_new_tag', tagSearchKey)}>新增并选择标签</Button>
          </div>
        )}
        multiple={true}
        values={value['tags']}
        onFetchDatas={(params) => {
          setTagSearchKey(params['searchKey'])
          return fetchCompanyTags(params)
        }}
        onConfirm={(datas) => handleClick('handle_add_tags', datas)}
      />
    </Fragment>
  )
}