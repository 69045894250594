import React from 'react';
import ReactDom from 'react-dom/client';
import { Route, HashRouter, Switch } from 'react-router-dom';

import Auth from './pages/Auth';
import Home from './pages/mobile/Home';
import UpdateEntry from './pages/mobile/UpdateEntry';
import QueryEntry from './pages/mobile/QueryEntry';
import QueryEntry1 from './pages/QueryEntry/entry';
import DownloadEntry from './pages/mobile/DownloadEntry';

const app = ReactDom.createRoot(document.getElementById('cec-app'));
app.render((
  <HashRouter>
    <Switch>
      <Route path='/mobile/entry/update' component={UpdateEntry} />
      <Route path='/mobile/entry/query/:data' component={QueryEntry} />
      <Route path='/mobile/entry/query' component={QueryEntry} />
      <Route path='/mobile/entry/download' component={DownloadEntry} />
      <Route path='/mobile/home/:data' component={Home} />
      <Route path='/mobile/home' component={Home} />
      <Route path='/auth/:profile' component={Auth} />
      <Route path='/*' component={Home} />
    </Switch>
  </HashRouter>
))