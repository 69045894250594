import React, { Fragment, PureComponent, createRef, useRef, useState } from "react";
import { Toast, Cascader, Input, List, Switch, TextArea, Button, NoticeBar, SwipeAction } from 'antd-mobile'
import { AddOutline, SoundOutline } from 'antd-mobile-icons'
import _ from "lodash";
import moment from "moment";
import Required from "../../Required";
import CECCalendarPopup from "../../CalendarPopup";
import CECSelectorListItem from "../../SelectorListItem";
import CECSelectPopup from "../../SelectPopup";
import AmountUnitItem from "../../AmountUnitItem";
import BaseExtractPopup from "../../BaseExtractPopup";
import CECSelectIndustryPopup from "../../SelectIndustryPopup";
import "./index.less";
import {
  FetchElementList,
  FetchECUserList,
  FetchTargetList,
  FetchElementLevelList,
  FetchRegion,
} from '../transform'

const AMOUNT_UNIT_OPTIONS = [
  { label: '千元', value: '1000' },
  { label: '百万', value: '1000000' },
  { label: '十亿', value: '1000000000' },
]
const SPECIAL_CONDITION_OPTIONS = [
  { value: '1', label: '是' },
  { value: '0', label: '否' }
]

/**
 * LP 创建/修改页面
 */
export default class LPView extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _info: props.info ? props.info : {},

      _activeOptions: [
        { label: "活跃", value: "ACTIVE" },
        { label: "不活跃", value: "INACTIVE" },
        { label: "待定", value: "UNKNOWN" }
      ],
      _typeOptions: [],
      _termOptions: [],//期限
      _tendencyOptions: [],//投资倾向
      _areaOptions: [],
      _ecUserOptions: [],
      // 币种
      _manageFundScaleCurrencyOptions: [],//基金规模
      _outScaleCurrencyOptions: [],//出资规模
      _investmentWayCurrencyOptions: [],//已出资
      _manageScaleCurrencyOptions: [],//管理规模
      // 单位
      _manageFundScaleUnitOptions: _.map(AMOUNT_UNIT_OPTIONS, item => ({ ...item })),//基金规模
      _outScaleUnitOptions: _.map(AMOUNT_UNIT_OPTIONS, item => ({ ...item })),//出资规模
      _investmentWayUnitOptions: _.map(AMOUNT_UNIT_OPTIONS, item => ({ ...item })),//已出资
      _manageScaleUnitOptions: _.map(AMOUNT_UNIT_OPTIONS, item => ({ ...item })),//管理规模
    }
    this.establishRef = createRef()
    this.ecUserRef = createRef()
    this.receiveInvestmentsRef = createRef()
    this.areaRef = createRef()
    this.lpIndustryAndStagePopupRef = createRef()
    this.lpSpecialConditionPopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _info: props.info ? props.info : {}
    })
  }

  componentDidMount() {
    try {
      this.fetchTypeList()
      this.fetchTendencyList()
      this.fetchTermList()
      this.fetchRegionList()
      this.fetchECUserList()
      this.fetchCurrencyList()
    } catch (error) {
    } finally {
    }
  }

  handleChange(key, data, event) {
    console.log('handleChange ===>', key, data, event)
    const {
      onChange
    } = this.props
    const {
      _areaOptions,
      _info,
    } = this.state

    switch (key) {
      case 'change_value':
        const info = _.assign({}, _info, {
          [data]: event
        })
        onChange && onChange(info)
        break;
      case 'change_area_value':
        let areas = []
        if (data) {
          _areaOptions.find(item => {
            if (item['value'] === data[0]) {
              areas.push(item)
              item['children'].find(childrenItem => {
                if (childrenItem['value'] === data[1]) {
                  areas.push(childrenItem)
                }
              })
            }
          })
        }
        const areaInfo = _.assign({}, _info, {
          area: areas
        })
        onChange && onChange(areaInfo)
        break;
      case 'change_input_value':
        const textInfo = _.assign({}, _info, {
          [data]: event
        })
        onChange && onChange(textInfo)
        break;
      case 'select_area_value':
        if (data) {
          this.fetchRegionList(data[0], _areaOptions)
        }
        break;
      default:
        break;
    }
  }

  async fetchTypeList() {
    try {
      const options = await FetchElementList("Label_type")
      this.setState({ _typeOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 投资倾向
  async fetchTendencyList() {
    try {
      const options = await FetchElementList("Label_lp_leanto")
      this.setState({ _tendencyOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 期限
  async fetchTermList() {
    try {
      const options = await FetchElementList("Label_lp_deadline")
      this.setState({ _termOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 区域
  async fetchRegionList(pid = 0, datas) {
    try {
      const options = await FetchRegion(pid, datas)
      this.setState({ _areaOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 币种
  async fetchCurrencyList() {
    try {
      const options = await FetchElementList("Lable_lp_currency")
      this.setState({
        _manageFundScaleCurrencyOptions: _.map(options, item => ({ ...item, value: item['label'] })),//基金规模
        _outScaleCurrencyOptions: _.map(options, item => ({ ...item, value: item['label'] })),//出资规模
        _investmentWayCurrencyOptions: _.map(options, item => ({ ...item, value: item['label'] })),//已出资
        _manageScaleCurrencyOptions: _.map(options, item => ({ ...item, value: item['label'] })),//管理规模
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchECUserList() {
    try {
      const options = await FetchECUserList()
      this.setState({ _ecUserOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  render() {
    const {
      _info,
      _activeOptions,
      _typeOptions,
      _termOptions,
      _tendencyOptions,
      _areaOptions,
      _ecUserOptions,
      _manageFundScaleCurrencyOptions,
      _outScaleCurrencyOptions,
      _investmentWayCurrencyOptions,
      _manageScaleCurrencyOptions,
      // 单位
      _manageFundScaleUnitOptions,
      _outScaleUnitOptions,
      _investmentWayUnitOptions,
      _manageScaleUnitOptions,
    } = this.state
    
    return (
      <Fragment>
        <List>
          <List.Item extra={<Switch checked={_info['coverage']} onChange={this.handleChange.bind(this, 'change_value', 'coverage')} />}>易凯覆盖</List.Item>
          <CECSelectorListItem label="是否活跃"
            isRequired={false}
            multiple={true}
            options={_activeOptions}
            value={_info['active'] ? [_info['active']] : []}
            onChange={(val, options) => this.handleChange('change_value', 'active', options)}
          />
          <List.Item><Input placeholder="LP名称（必填）" style={{ '--text-align': 'left' }} value={_info['lpName']} onChange={this.handleChange.bind(this, 'change_input_value', 'lpName')} /></List.Item>
          <CECSelectorListItem label="LP类型"
            isRequired={true}
            multiple={true}
            options={_typeOptions}
            value={_info['types'] ? _.map(_info['types'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'types', options)}
          />
          <List.Item extra={_info['lpEstablish'] ? moment(_info['lpEstablish']).format("YYYY") : "请选择"} onClick={() => this.establishRef.current.toggle()}>成立时间</List.Item>

          <List.Item extra={_info['area'] && _.size(_info['area']) > 0 ? _.join(_.map(_info['area'], 'label')) : "请选择"} onClick={() => this.areaRef.current?.toggle()}>地区(省市)</List.Item>
          <List.Item extra={<Input placeholder="请填写" value={_info['districtText']} onChange={this.handleChange.bind(this, 'change_input_value', 'districtText')} />}>地区(区)</List.Item>
          <List.Item extra={<Input placeholder="请填写" value={_info['address']} onChange={this.handleChange.bind(this, 'change_input_value', 'address')} />}>LP地址</List.Item>
          <List.Item extra={<div style={{ display: 'flex', color: '#000' }}>1:<Input placeholder="请填写" value={_info['reinvestmentConditions']} onChange={this.handleChange.bind(this, 'change_input_value', 'reinvestmentConditions')} /></div>}>返投条件</List.Item>

          <AmountUnitItem label="基金规模"
            required={false}
            currencyOptions={_manageFundScaleCurrencyOptions}
            unitOptions={_manageFundScaleUnitOptions}
            value={_info['manageFundScale']}
            onChange={(data) => this.handleChange('change_value', 'manageFundScale', data)}
          />

          <AmountUnitItem label="出资规模"
            required={false}
            currencyOptions={_outScaleCurrencyOptions}
            unitOptions={_outScaleUnitOptions}
            value={_info['outScale']}
            onChange={(data) => this.handleChange('change_value', 'outScale', data)}
          />

          <List.Item extra={<Switch checked={_info['investment']} onChange={this.handleChange.bind(this, 'change_value', 'investment')} />}>已出资</List.Item>
          {
            _info['investment'] === true && (
              <AmountUnitItem label=""
                required={false}
                currencyOptions={_investmentWayCurrencyOptions}
                unitOptions={_investmentWayUnitOptions}
                value={_info['investmentWay']}
                onChange={(data) => this.handleChange('change_value', 'investmentWay', data)}
              />
            )
          }

          <AmountUnitItem label="管理规模"
            required={false}
            currencyOptions={_manageScaleCurrencyOptions}
            unitOptions={_manageScaleUnitOptions}
            value={_info['manageScale']}
            onChange={(data) => this.handleChange('change_value', 'manageScale', data)}
          />

          <CECSelectorListItem label="期限"
            isRequired={false}
            options={_termOptions}
            value={_info['term'] ? _.map(_info['term'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'term', options)}
          />
          <CECSelectorListItem label="投资倾向"
            isRequired={false}
            columns={2}
            options={_tendencyOptions}
            value={_info['tendency'] ? _.map(_info['tendency'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'tendency', options)}
          />
          <List.Item extra={_info['ecapitalUsers'] && _.size(_info['ecapitalUsers']) > 0 ? _.join(_.map(_info['ecapitalUsers'], "label")) : "请选择"} onClick={() => this.ecUserRef.current.toggle()}>易凯负责人</List.Item>
          <List.Item extra={_info['receiveInvestments'] && _.size(_info['receiveInvestments']) > 0 ? _.join(_.map(_info['receiveInvestments'], "label")) : "请选择"} onClick={() => this.receiveInvestmentsRef.current.toggle()}>受托管机构</List.Item>

          <List.Item extra={_info['industryAndStages'] && _.size(_info['industryAndStages']) > 0 ? `${_.size(_info['industryAndStages'])}条数据` : "请选择"} onClick={() => this.lpIndustryAndStagePopupRef?.current?.show()}>主投方向/阶段（评分）</List.Item>
          <List.Item extra={_info['specialConditions'] && _.size(_info['specialConditions']) > 0 ? `${_.size(_info['specialConditions'])}条数据` : "请选择"} onClick={() => this.lpSpecialConditionPopupRef?.current?.show()}>特殊条件</List.Item>
          <List.Item>
            <TextArea placeholder="决策流程" rows={3} value={_info['voteProgress']} onChange={this.handleChange.bind(this, 'change_input_value', 'voteProgress')} />
          </List.Item>
          <List.Item>
            <TextArea placeholder="LP简介" rows={3} value={_info['description']} onChange={this.handleChange.bind(this, 'change_input_value', 'description')} />
          </List.Item>
          <List.Item>
            <TextArea placeholder="备注" rows={3} value={_info['note']} onChange={this.handleChange.bind(this, 'change_input_value', 'note')} />
          </List.Item>
        </List>

        {/** 日历 */}
        <CECCalendarPopup ref={this.establishRef}
          precision="year"
          value={moment().toDate()}
          metadata={{ title: "成立时间" }}
          onConfirm={this.handleChange.bind(this, 'change_value', 'lpEstablish')}
        />

        {/** 省市区 */}
        <Cascader title="地区"
          ref={this.areaRef}
          loading={false}
          value={_info['area'] && _.size(_info['area']) > 0 ? _.map(_info['area'], "value") : []}
          options={_areaOptions}
          onSelect={this.handleChange.bind(this, 'select_area_value')}
          onConfirm={this.handleChange.bind(this, 'change_area_value')}
        />

        {/** 受托管机构 */}
        <CECSelectPopup ref={this.receiveInvestmentsRef}
          title={"受托管机构"}
          supportSearch={true}
          supportAvatar={true}
          multiple={false}
          values={_info['receiveInvestments'] ? _.map(_info['receiveInvestments'], "value") : []}
          onFetchDatas={(params) => FetchTargetList({ ...params, types: ['investment'] })}
          onConfirm={this.handleChange.bind(this, 'change_value', 'receiveInvestments')}
        />

        {/** 易凯联系人 */}
        <CECSelectPopup ref={this.ecUserRef}
          title={'易凯联系人'}
          supportSearch={true}
          supportAvatar={false}
          multiple={true}
          options={_ecUserOptions}
          values={_info['ecapitalUsers']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'ecapitalUsers')}
        />

        {/** 主投方向/阶段（评分） */}
        <LPIndustryAndStagePopup ref={this.lpIndustryAndStagePopupRef}
          metadata={{ title: '主投方向/阶段（评分）' }}
          value={_info['industryAndStages']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'industryAndStages')}
        />

        {/** 特殊条件 */}
        <LPSpecialConditionPopup ref={this.lpSpecialConditionPopupRef}
          metadata={{ title: '特殊条件' }}
          value={_info['specialConditions']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'specialConditions')}
        />
      </Fragment>
    )
  }
}

/**
 * LP 主投方向、阶段和评分(列表)
 */
export class LPIndustryAndStagePopup extends BaseExtractPopup {

  constructor(props) {
    super(props)
    this.state = {
      _datas: props.value ? props.value : [],
      _itemInfo: {},
    }
    this.addLPIndustryAndStagePopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ _datas: props.value ? props.value : [] })
  }
  handleChange(key, data, event) {
    console.log("handleChange >>>>", key, data, event)
    const {
      _datas
    } = this.state
    const {
      onConfirm
    } = this.props

    switch (key) {
      case 'change_add_item':
        if (data) {
          let datas = [..._datas]
          datas.push(data)
          this.setState({ _datas: datas }, () => onConfirm && onConfirm(datas))
        }
        break;
      case 'handle_open_item_edit_popup':
        if (data) {
          this.setState({ _itemInfo: data }, () => this.addLPIndustryAndStagePopupRef?.current?.show())
        }
        break;
      case 'handle_delete_item':
        if (data) {
          let newDatas = []
          _datas.find(item => {
            if (data['key'] !== item['key']) {
              newDatas.push(item)
            }
          })
          this.setState({ _datas: newDatas })
        }
        break;
      default:
        break;
    }
  }
  renderNavRight() {
    return (
      <div onClick={() => this.addLPIndustryAndStagePopupRef?.current?.show()}>
        <AddOutline style={{ color: '#1890FF', fontSize: 18 }} />
      </div>
    )
  }
  renderFootContent() {
    const {
      _datas
    } = this.state
    const {
      onConfirm
    } = this.props
    return (
      <Button block color="primary" onClick={() => { onConfirm && onConfirm(_datas); this.close() }}>确定</Button>
    )
  }
  renderBodyContent() {
    const {
      _datas,
      _itemInfo,
    } = this.state

    return (
      <Fragment>
        <div className="lp-industry-stage-score-list">
          <div>
            <NoticeBar color='info' icon={<SoundOutline style={{ color: '#1890FF' }} />} content="在内容文字上“左滑”可进行编辑或删除" style={{ '--font-size': '12px' }} />
          </div>
          {
            _datas.map((item, index) => {
              let industrySelectedValues = ""
              if (item['industrys'] && _.size(item['industrys']) > 0) {
                industrySelectedValues = _.join(_.map(item['industrys'], item => {
                  const label = item['label']
                  const childrenLabel = _.join(_.map(item['children'], "label"))
                  return `${label}${childrenLabel ? `(${childrenLabel})` : ""}`
                }), "、")
              }

              return (
                <ItemSwipeAction key={`lp-industry-stage-score-item-${index}`}
                  item={item}
                  onEdit={(data) => this.handleChange("handle_open_item_edit_popup", data)}
                  onDelete={(data) => this.handleChange("handle_delete_item", data)}
                >
                  <div className="lp-industry-stage-score-item">
                    <div className="item-1">
                      <div className="item-1-key">主投方向</div>
                      <div className="item-1-label">{industrySelectedValues}</div>
                    </div>
                    <div className="item-1">
                      <div className="item-1-key">阶段</div>
                      <div className="item-1-label">{item['stages'] ? _.join(_.map(item['stages'], "label")) : null}</div>
                    </div>
                    <div className="item-1">
                      <div className="item-1-key">评分</div>
                      <div className="item-1-label">{item['score']}</div>
                    </div>
                  </div>
                </ItemSwipeAction>
              )
            })
          }
        </div>

        {/** 添加窗口 */}
        <AddLPIndustryAndStagePopup ref={this.addLPIndustryAndStagePopupRef}
          metadata={{ title: '主投方向/阶段（评分）' }}
          info={_itemInfo}
          onConfirm={this.handleChange.bind(this, 'change_add_item')}
        />
      </Fragment>
    )
  }
}

/**
 * LP 主投方向、阶段和评分(添加)
 */
class AddLPIndustryAndStagePopup extends BaseExtractPopup {

  constructor(props) {
    super(props)
    this.state = {
      _industryOptions: [],
      _stageOptions: [],

      _info: props.info ? props.info : {},
    }
    this.industryPopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _info: props.info ? props.info : {},
    })
  }

  async show() {
    super.show()
    await this.fetchIndustryList()
    await this.fetchStageList()
  }

  async fetchIndustryList() {
    try {
      const options = await FetchElementLevelList("Label_lp_comindu_yk")
      this.setState({ _industryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchStageList() {
    try {
      const options = await FetchElementList("Lable_investage")
      this.setState({ _stageOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  handleChange(key, data, event) {
    // console.log(">>>>handleChange>>>>", key, data, event)
    const { _info } = this.state

    switch (key) {
      case 'change_industry_value':
        this.setState({
          _info: _.assign({}, _info, {
            industrys: data
          })
        })
        break;
      case 'change_stage_value':
        this.setState({
          _info: _.assign({}, _info, {
            stages: event
          })
        })
        break;
      case 'change_input_value':
        this.setState({
          _info: _.assign({}, _info, {
            score: data
          })
        })
        break;
      default:
        break;
    }
  }

  renderFootContent() {
    let { _info } = this.state
    const { onConfirm } = this.props

    if (_info && !_info['key']) {
      _info = {
        ..._info,
        key: moment().format("x")
      }
    }

    return (
      <Button block color="primary" onClick={() => { onConfirm && onConfirm(_info); this.close() }}>确定</Button>
    )
  }

  renderBodyContent() {
    const {
      _info,
      _industryOptions,
      _stageOptions,
    } = this.state

    let industrySelectedValues = ""
    if (_info['industrys'] && _.size(_info['industrys']) > 0) {
      industrySelectedValues = _.join(_.map(_info['industrys'], item => {
        const label = item['label']
        const childrenLabel = _.join(_.map(item['children'], "label"))
        return `${label}${childrenLabel ? `(${childrenLabel})` : ""}`
      }), "、")
    }
    return (
      <Fragment>
        <List>
          <List.Item extra={industrySelectedValues ? industrySelectedValues : "请选择"} onClick={() => this.industryPopupRef?.current?.show()}>主投方向</List.Item>
          <CECSelectorListItem label="阶段"
            isRequired={false}
            multiple={true}
            value={_info['stages'] ? _.map(_info['stages'], "value") : []}
            options={_stageOptions}
            onChange={this.handleChange.bind(this, 'change_stage_value')}
          />
          <List.Item extra={<Input placeholder="请输入" value={_info['score']} onChange={this.handleChange.bind(this, 'change_input_value')} />}>评分</List.Item>
        </List>

        {/** 行业选择 */}
        <CECSelectIndustryPopup ref={this.industryPopupRef}
          metadata={{ title: '主投方向' }}
          multiple={false}
          value={_info['industrys'] ? _.map(_info['industrys'], "value") : []}
          options={_industryOptions}
          onConfirm={this.handleChange.bind(this, 'change_industry_value')}
        />
      </Fragment>
    )
  }
}


/**
 * LP 特殊条件（列表）
 */
export class LPSpecialConditionPopup extends BaseExtractPopup {
  constructor(props) {
    super(props)
    this.state = {
      _datas: props.value ? props.value : [],
      _itemInfo: {},
    }
    this.addLPSpecialConditionPopupRef = createRef()
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ _datas: props.value ? props.value : [] })
  }

  handleChange(key, data, event) {
    // console.log("handleChange >>>>", key, data, event)
    const {
      _datas
    } = this.state
    const {
      onConfirm
    } = this.props

    switch (key) {
      case 'change_add_item':
        if (data) {
          let datas = [..._datas]
          datas.push(data)
          this.setState({ _datas: datas }, () => onConfirm && onConfirm(datas))
        }
        break;
      case 'handle_open_item_edit_popup':
        if (data) {
          this.setState({ _itemInfo: data }, () => this.addLPSpecialConditionPopupRef?.current?.show())
        }
        break;
      case 'handle_delete_item':
        if (data) {
          let newDatas = []
          _datas.find(item => {
            if (data['key'] !== item['key']) {
              newDatas.push(item)
            }
          })
          this.setState({ _datas: newDatas })
        }
        break;
      default:
        break;
    }
  }

  renderNavRight() {
    return (
      <div onClick={() => this.addLPSpecialConditionPopupRef?.current?.show()}>
        <AddOutline style={{ color: '#1890FF', fontSize: 18 }} />
      </div>
    )
  }

  renderBodyContent() {
    const {
      _datas,
      _itemInfo,
    } = this.state

    return (
      <Fragment>
        <div className="lp-industry-stage-score-list">
          <div>
            <NoticeBar color='info' icon={<SoundOutline style={{ color: '#1890FF' }} />} content="在内容文字上“左滑”可进行编辑或删除" style={{ '--font-size': '12px' }} />
          </div>
          {
            _datas.map((item, index) => (
              <ItemSwipeAction key={`lp-industry-stage-score-item-${index}`}
                item={item}
                onEdit={(data) => this.handleChange("handle_open_item_edit_popup", data)}
                onDelete={(data) => this.handleChange("handle_delete_item", data)}
              >
                <div className="lp-industry-stage-score-item">
                  <div className="item-1">
                    <div className="item-1-key">特殊条件</div>
                    <div className="item-1-label">{item['specialCondition'] ? _.join(_.map(item['specialCondition'], "label")) : null}</div>
                  </div>
                  <div className="item-1">
                    <div className="item-1-key">是否同意</div>
                    <div className="item-1-label">{item['active'] ? _.join(_.map(item['active'], "label")) : null}</div>
                  </div>
                </div>
              </ItemSwipeAction>
            ))
          }
        </div>

        {/** LP 特殊条件（添加） */}
        <AddLPSpecialConditionPopup ref={this.addLPSpecialConditionPopupRef}
          metadata={{ title: "特殊条件" }}
          info={_itemInfo}
          onConfirm={this.handleChange.bind(this, 'change_add_item')}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const {
      _datas
    } = this.state
    const {
      onConfirm
    } = this.props
    return (
      <Button block color="primary" onClick={() => { onConfirm && onConfirm(_datas); this.close() }}>确定</Button>
    )
  }
}

/**
 * LP 特殊条件（添加）
 */
class AddLPSpecialConditionPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _info: props.info ? props.info : {},
    }

    this.specialCondtionSelectPopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _info: props.info ? props.info : {},
    })
  }
  async show() {
    super.show()
    await this.fetchSpecialCondtionList()
  }
  async fetchSpecialCondtionList() {
    try {
      const options = await FetchElementList("Label_lp_special_condition")
      this.setState({ _specialCondtionOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  handleChange(key, data, event) {
    console.log("handleChange >>>>", key, data, event)
    const {
      _info
    } = this.state

    switch (key) {
      case 'change_value':
        this.setState({
          _info: _.assign({}, _info, {
            [data]: event
          })
        })
        break;
      default:
        break;
    }
  }

  renderBodyContent() {
    const {
      _info,
      _specialCondtionOptions,
    } = this.state

    return (
      <Fragment>
        <List>
          <List.Item extra={_info['specialCondition'] && _.size(_info['specialCondition']) > 0 ? _.join(_.map(_info['specialCondition'], "label")) : "请选择"} onClick={() => this.specialCondtionSelectPopupRef?.current?.show()}>特殊条件</List.Item>
          <CECSelectorListItem
            label="是否同意"
            isRequired={false}
            multiple={false}
            options={SPECIAL_CONDITION_OPTIONS}
            value={_info['active'] ? _.map(_info['active'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'active', options)}
          />
        </List>

        {/** 特殊条件 */}
        <CECSelectPopup ref={this.specialCondtionSelectPopupRef}
          supportSearch={true}
          multiple={false}
          title="特殊条件"
          value={_info['specialCondition'] ? _.map(_info['specialCondition'], "value") : []}
          options={_specialCondtionOptions}
          onConfirm={this.handleChange.bind(this, 'change_value', 'specialCondition')}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    let { _info } = this.state
    const { onConfirm } = this.props

    if (_info && !_info['key']) {
      _info = {
        ..._info,
        key: moment().format("x")
      }
    }

    return (
      <Button block color="primary" onClick={() => { onConfirm && onConfirm(_info); this.close() }}>确定</Button>
    )
  }
}

/**
 * 滑动操作
 * @returns 
 */
function ItemSwipeAction({ key, item, onDelete, onEdit, children }) {

  const [confirmDeleted, setConfirmDeleted] = useState(false)
  const [deleteActions, setDeleteActions] = useState([
    {
      key: 'delete',
      text: '确定删除？',
      color: 'danger',
      onClick: () => {
        swipeActionRef?.current?.close()
        setConfirmDeleted(false)
        onDelete && onDelete(item)
      }
    },
  ])
  const [itemActions, setItemActions] = useState([
    {
      key: 'edit',
      text: '编辑',
      color: 'warning',
      onClick: () => onEdit && onEdit(item)
    }, {
      key: 'delete',
      text: '删除',
      color: 'danger',
      onClick: () => {
        setConfirmDeleted(true)
        swipeActionRef?.current?.show('right')
      }
    },
  ])
  const swipeActionRef = useRef(null)

  return (
    <SwipeAction key={key}
      ref={swipeActionRef}
      closeOnAction={false}
      rightActions={confirmDeleted ? deleteActions : itemActions}>
      {
        children
      }
    </SwipeAction>
  )

}