import { List } from "antd-mobile";
import './index.less';

/**
 * List中Item多项分组
 * @returns 
 */
export default function ListItemGroup({ label, children }) {

  return (
    <List.Item className="cec-list-item-group">
      <div style={{ padding: '10px 0' }}>{label}</div>
      <div className="cec-list-item-group-body">
        {
          children
        }
      </div>
    </List.Item>
  )

}