import React, { PureComponent } from 'react'
import _ from 'lodash'
import { List, Selector } from 'antd-mobile'
import Required from '../Required'

/*
 * Selector列表项
 */
export default class CECSelectorListItem extends PureComponent {
  constructor(props) {
    super(props)
    let options = _.get(props, 'options', [])
    this.state = {
      _disabled: _.get(props, 'disabled', false),
      _multiple: _.get(props, 'multiple', false),
      _options: options,
      _label: _.get(props, 'label', ''),
      _isRequired: _.get(props, 'isRequired', false),
      _columns: _.size(options) <= 3 ? _.size(options) : _.get(props, 'columns', 3),
      _maxRows: 5,
      _value: _.get(props, 'value', []),
      _collapse: true,
    }
    this.storageOptions = _.map(_.get(props, 'options', []), item => ({ ...item }))
    this.onChange = _.get(props, 'onChange', null);
  }

  componentWillReceiveProps(props) {
    let options = _.get(props, 'options', [])
    this.setState({
      _disabled: _.get(props, 'disabled', false),
      _multiple: _.get(props, 'multiple', false),
      _options: options,
      _label: _.get(props, 'label', ''),
      _isRequired: _.get(props, 'isRequired', false),
      _columns: _.size(options) <= 3 ? _.size(options) : _.get(props, 'columns', 3),
      _value: _.get(props, 'value', []),
    })
    this.storageOptions = _.map(_.get(props, 'options', []), item => ({ ...item }))
    this.onChange = _.get(props, 'onChange', null);
  }

  render() {
    const {
      _disabled,
      _multiple,
      _value,
      _options,
      _label,
      _isRequired,
      _columns,
      _collapse,
      _maxRows
    } = this.state
    const {
      placeholder,
      children
    } = this.props

    return (
      <List.Item disabled={_disabled} className='cec-selector-item-wrap'>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div>
            {_label}
            {_isRequired && <Required />}
          </div>
          <Selector size='small'
            className='cec-selector-item'
            style={{ fontSize: '13px', marginTop: 8, '--padding': '6px 8px' }}
            columns={_columns}
            disabled={_disabled}
            multiple={_multiple}
            options={_collapse === true && _.size(_options) > (_maxRows * _columns) ? _.take(_options, (_maxRows * _columns)) : _options}
            value={_value}
            onChange={(arr, extend) => {
              this.setState({ _value: arr },
                () => this.onChange && this.onChange(arr, _.filter(_options, option => _.includes(arr, option.value)))
              )
            }}
          />
          {
            _.size(_options) === 0 && placeholder ? placeholder : null
          }
          {
            children && (<div style={{ marginTop: 8 }}>{children}</div>)
          }
        </div>
        {
          _collapse === true && _.size(_options) > (_maxRows * _columns) && (
            <div style={{ color: '#1890FF', paddingTop: 8, textAlign: 'center' }} onClick={() => this.setState({ _collapse: false, _options: this.storageOptions })}>展开</div>
          )
        }
      </List.Item>
    )
  }
}