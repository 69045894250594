

const configUrlMap = {
  'dev': {
    'hostSmartX': 'https://smartx.ceccapitalgroup.com',
    'hostOA': 'http://10.10.10.30:50',
    'hostNeos': 'http://10.10.10.30:40',
    'hostSmart': 'http://10.10.10.30:90',
    'hostSSO': 'http://10.10.10.30:60',
    'corpId': 'wx68295102be0cd28b',
    'oAuthAgentId': '1000019',//页面认证需要使用
    'agentId': '1000019',//WECOMLogin-SDK注册使用
    'requiredAuth': false,
  },
  'uat': {
    'hostSmartX': 'https://smartx.ceccapitalgroup.com',
    'hostOA': 'http://toa.ceccapitalgroup.com',
    'hostNeos': 'http://tneos.ceccapitalgroup.com',
    'hostSmart': 'http://tsmart.ceccapitalgroup.com',
    'hostSSO': 'http://tsso.ceccapitalgroup.com',
    'corpId': 'wx68295102be0cd28b',
    'oAuthAgentId': '1000019',//页面认证需要使用
    'agentId': '1000019',//WECOMLogin-SDK注册使用
    'requiredAuth': true,
  },
  'prd': {
    'hostSmartX': 'https://smartx.ceccapitalgroup.com',
    'hostOA': 'http://oa.ceccapitalgroup.com',
    'hostNeos': 'http://neos.ceccapitalgroup.com',
    'hostSmart': 'http://smart.ceccapitalgroup.com',
    'hostSSO': 'http://sso.ceccapitalgroup.com',
    'requiredAuth': true,
  }
}

export function getCurrentRuntimeUrlConfig(env = 'uat') {

  const config = configUrlMap[env]

  if (!config) {

    return configUrlMap['uat']

  }

  return config
}