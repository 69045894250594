import React, { Fragment, createRef } from "react";
import { List, Input, Button, Toast } from "antd-mobile";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import CECCalendarPopup from "../../../../../components/CalendarPopup";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import CECSelectIndustryPopup from "../../../../../components/SelectIndustryPopup";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import ListItemGroup from "../../../../../components/ListItemGroup";
import _ from 'lodash';
import moment from "moment";
import {
  IS_FUND_OPTIONS,
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_OTHER_VALUE,
  AMOUNT_UNIT_OPTIONS,
  ConvertIndustryToLabels,
  FetchElementList,
  FetchElementLevelList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 基金募集更新
 */
export default class FundraisingPopup extends BaseExtractValuePopup {

  constructor(props) {
    super(props)

    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })

    this.state = {
      _info: info,
      _newFundFirstScaleOptions: [],
      _singleInvestmentAmountRangeOptions: [],
      _ecIndustryOptions: [],
    }
    this.dateRef = createRef()
    this.ecIndustrySelectPopupRef = createRef()
  }
  componentDidMount() {
    this.fetchCompanyIndustryList()
    this.fetchNewFundFirstScaleList()
    this.fetchSingleInvestmentAmountRangeUnitList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  // 投资方向
  async fetchCompanyIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 新基金首关规模
  async fetchNewFundFirstScaleList() {
    try {
      const options = await FetchElementList("Lable_smartx_funding_scale")
      this.setState({ _newFundFirstScaleOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 单笔投资金额
  async fetchSingleInvestmentAmountRangeUnitList() {
    try {
      const options = await FetchElementList("Lable_smartx_trade_scale")
      this.setState({ _singleInvestmentAmountRangeOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _newFundFirstScaleOptions,
      _singleInvestmentAmountRangeOptions,
      _ecIndustryOptions,
    } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="近1年内是否有新基金募集完成"
            options={IS_FUND_OPTIONS}
            value={_info['isFund'] ? _.map(_info['isFund'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'isFund', options)}
          />

          <List.Item extra={_info['date'] ? moment(_info['date']).format('YYYY/MM/DD') : "请选择"} onClick={() => this.dateRef?.current?.toggle()}>募集时间</List.Item>

          <ListItemGroup label="新基金首关规模">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['newFundFirstScaleCurrency'] ? _.map(_info['newFundFirstScaleCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'newFundFirstScaleCurrency', options)}
            >
              {_info['newFundFirstScaleCurrency'] && _.map(_info['newFundFirstScaleCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['newFundFirstScaleCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'newFundFirstScaleCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['newFundFirstScaleUnit'] ? _.map(_info['newFundFirstScaleUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'newFundFirstScaleUnit', options)}
            />

            <CECSelectorListItem
              columns={2}
              options={_newFundFirstScaleOptions}
              value={_info['newFundFirstScale'] ? _.map(_info['newFundFirstScale'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'newFundFirstScale', options)}>
              <Input placeholder="请输入具体金额" value={_info['newFundFirstScaleValue']} onChange={this.handleChange.bind(this, 'change_value', 'newFundFirstScaleValue')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <List.Item extra={_info['industry'] ? ConvertIndustryToLabels(_info['industry']) : "请选择"} onClick={() => this.ecIndustrySelectPopupRef?.current?.show()}>投资方向</List.Item>

          <ListItemGroup label="单笔投资金额">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['singleInvestmentAmountRangeCurrency'] ? _.map(_info['singleInvestmentAmountRangeCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleInvestmentAmountRangeCurrency', options)}
            >
              {_info['singleInvestmentAmountRangeCurrency'] && _.map(_info['singleInvestmentAmountRangeCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['singleInvestmentAmountRangeCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'singleInvestmentAmountRangeCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['singleInvestmentAmountRangeUnit'] ? _.map(_info['singleInvestmentAmountRangeUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleInvestmentAmountRangeUnit', options)}
            />

            <CECSelectorListItem
              columns={2}
              options={_singleInvestmentAmountRangeOptions}
              value={_info['singleInvestmentAmountRange'] ? _.map(_info['singleInvestmentAmountRange'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleInvestmentAmountRange', options)}>
              <Input placeholder="请输入具体金额" value={_info['singleInvestmentAmountRangeValue']} onChange={this.handleChange.bind(this, 'change_value', 'singleInvestmentAmountRangeValue')} />
            </CECSelectorListItem>
          </ListItemGroup>
        </List>

        <CECCalendarPopup
          ref={this.dateRef}
          value={_info['date'] ? _info['date'] : moment().toDate()}
          metadata={{ title: "募集时间" }}
          onConfirm={this.handleChange.bind(this, 'change_value', 'date')}
        />

        <CECSelectIndustryPopup
          ref={this.ecIndustrySelectPopupRef}
          title="投资方向"
          multiple={true}
          options={_ecIndustryOptions}
          values={_info['industry']}
          onConfirm={options => this.handleChange('change_value', 'industry', options)}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}