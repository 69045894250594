import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";


// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type']
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 保存 融资/收购历史更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  let datas = []
  if (info['investorTypes']) {
    info['investorTypes'].find(item => {
      datas.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }
  if (info['investorCategorys']) {
    info['investorCategorys'].find(item => {
      datas.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }
  if (info['assetCategorys']) {
    info['assetCategorys'].find(item => {
      datas.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }
  // 币种
  let currencyValues = info['investorCurrencys'] ? _.join(_.map(info['investorCurrencys'], "value")) : null
  if (info['investorCurrencyOther']) {
    currencyValues += ',' + info['investorCurrencyOther']
  }

  const payload = {
    id: info['id'],
    base_investment_code: info['code'],
    currencys: currencyValues,
    data_labels: JSON.stringify(datas),
    showflag: info['id'] ? true : false,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/investment/base/prop/save", payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 融资/收购历史更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/smartx/investment/base/prop/delete", { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}