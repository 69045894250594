import React, { Fragment } from "react";
import { Space, Tag } from 'antd-mobile'
import _ from "lodash";
import moment from "moment";
import request from "../../api/HttpRequest"


/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取公司标签
 * @param {*} searchKey 
 */
export async function FetchCompanyIndustryTagSearchList(searchKey) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/element/search", { "etype": "Label_comtag", "input": searchKey })

    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取易凯联系人
 */
export async function FetchECUserList(searchKey) {
  try {
    const response = await request("POST", "/cec-sso-server/user/search", { input: searchKey ? searchKey : "" })

    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['fullname'],
          value: item['id'],
          avatar: item['headpic'],
          description: <span style={{ color: '#999' }}>{item['jobLevel']}</span>
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 获取活跃投资人
 */

export async function FetchActiveInvestmentUserList({ searchKey, orgCode, pageNum = 1, pageSize = 20 }) {

  try {
    const response = await request("POST", "/cec-sims-server/investor/search", { base_investment_code: orgCode, input: searchKey ? searchKey : "", pageNum, pageSize })
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          label: item['investor_full_name'],
          value: item['base_investor_code'],
          description: (
            <Fragment>
              {
                item['base_investor_state'] && (
                  <div>
                    {
                      item['base_investor_state'] == 'normal' ? "在职" : "离职"
                    }
                  </div>
                )
              }
            </Fragment>
          )
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 获取公司/机构/LP/企业列表
 * @param {*} searchKey 
 * @param {*} type ['comgplp', 'company', 'investment', 'LP'
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
export async function FetchTargetList({ searchKey, types, pageNum = 1, pageSize = 20 }) {
  const params = {
    input: searchKey,
    base_types: types ? _.join(types) : '',
    pageNum: pageNum < 1 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/comgplp/search", params)
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, (item, index) => {

        const categorys = []
        const categoryLabels = []
        if (item['iscomp'] === 1) {
          categorys.push('company')
          categoryLabels.push("企业(产业方)")
        }
        if (item['isgp'] === 1) {
          categorys.push('investment')
          categoryLabels.push("市场化机构")
        }
        if (item['islp'] === 1) {
          categorys.push('lp')
          categoryLabels.push("政府/家办及个人/其他")
        }
        const typeLabels = item['type_name'] ? _.split(_.replace(item['type_name'], /，/g, ","), ',') : []
        const industryLabels = item['cat_yk_name'] ? _.split(_.replace(item['cat_yk_name'], /，/g, ","), ',') : []

        return {
          label: item['base_name'],
          value: item['base_code'],
          avatar: item['base_logo'],
          categorys: categorys,
          categoryLabels: categoryLabels,
          titleExtension: (
            <Space wrap>
              {
                item['location_name'] && <span style={{ color: '#999', fontSize: '12px' }}>{item['location_name']}</span>
              }
              {
                typeLabels.map(label => <Tag style={{ '--border-color': '#87e8de', '--text-color': '#08979c', '--background-color': '#e6fffb', '--font-size': '12px' }}>{label}</Tag>)
              }
            </Space>
          ),
          description: (
            <Space wrap>
              {
                categoryLabels.map(label => <Tag color="primary">{label}</Tag>)
              }
              {
                industryLabels.map(label => <Tag color="primary">{label}</Tag>)
              }
            </Space>
          ),
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 查询地区
 * @param {*} level 
 * @param {*} successCallback 
 */
export async function FetchRegion(pid = 0, datas = []) {
  try {
    const response = await request("POST", "/cec-sso-server/board/region", { pid: pid, })

    if (response) {
      if (pid === 0) {
        var array = []
        response.find(item => {
          array.push({
            label: item['name'],
            value: item['id'],
            level: item['level'],
            children: []
          })
        })
        return array
      } else {
        var array = []
        response.find(item => {
          array.push({
            label: item['name'],
            value: item['id'],
            level: item['level'],
          })
        })

        let newDatas = _.map(datas, item => {
          if (item['value'] === pid) {
            item['children'] = array
          }
          return item
        })
        return newDatas
      }
    }
    return []
  } catch (error) {
    throw error
  }
}

/**
 * 创建企业（公司/机构/LP）【Popover】
 * @param {*} param
 * @returns 
 */
export async function SwapRelationCode({ code, type }) {
  try {
    const response = await request("POST", "/cec-sims-server/comgplp/simple/info/get", { code: code, type: type })
    return {
      enterpriseCode: _.get(response, "code"),
      companyCode: _.get(response, "main_code_com"),
      gpCode: _.get(response, "main_code_gp"),
      lpCode: _.get(response, "main_code_lp")
    }
  } catch (error) {
    throw error
  }
}

/**
 * 获取公司详情 - 基本信息
 * @param {*} code 
 * @returns 
 */
export async function FetchCompanyDetailBaseInfo(code) {
  try {
    const response = await request("POST", "/cec-sims-server/company/detail_more2", { base_comp_code: code })

    let tags = []
    if (_.size(response['tags'])) {
      response['tags'].find(item => {
        tags.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name, id: item.id })
      })
    }
    let types = []
    if (_.size(response['types'])) {
      response['types'].find(item => {
        types.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }
    // 企名片
    let industrys = [], industrySeconds = []
    if (_.size(response['cat_qmp'])) {
      response['cat_qmp'].find(item => {
        let array = []
        if (_.size(item.list)) {
          item.list.find(subItem => {
            industrySeconds.push(subItem.sys_labelelement_name)
            array.push({
              value: subItem.sys_labelelement_code,
              label: subItem.sys_labelelement_name,
            })
          })
        }
        industrys.push({
          value: item.sys_labelelement_code,
          label: item.sys_labelelement_name,
          children: array
        })
      })
    }
    // 易凯行业
    let ecIndustrys = [], ecapitalIndustrySeconds = []
    if (_.size(response['cat_yk'])) {
      response['cat_yk'].find(item => {
        let array = []
        if (_.size(item.list)) {
          item.list.find(subItem => {
            ecapitalIndustrySeconds.push(subItem.sys_labelelement_name)
            array.push({
              value: subItem.sys_labelelement_code,
              label: subItem.sys_labelelement_name,
            })
          })
        }
        ecapitalIndustrySeconds.push(item.sys_labelelement_name)
        ecIndustrys.push({
          value: item.sys_labelelement_code,
          label: item.sys_labelelement_name,
          children: array
        })
      })
    }

    const result = {
      code: response['base_comp_code'],
      companyName: response['base_comp_name'],
      boardName: response['base_comp_fullname'],
      logo: response['base_comp_img'],
      area: response['location_code'] && response['location_name'] ? [{ label: response['location_name'], value: response['location_code'] }] : [],
      scale: response['comp_scale'],
      companyEstablish: response['comp_born_year'] && response['comp_born_month'] ? moment(response['comp_born_year'] + "-" + (response['comp_born_month'] < 10 ? "0" + response['comp_born_month'] : response['comp_born_month']) + "-01") : null,
      tags: tags,
      types: types,
      description: response['base_comp_about'],

      industrys: industrys,
      industrySeconds: industrySeconds,
      ecIndustrys: ecIndustrys,
      ecIndustrySeconds: ecapitalIndustrySeconds,
    }
    return result
  } catch (error) {
    throw error
  }
}

/**
 * 获取投资机构详情 - 基本信息
 * @param {*} code 
 * @returns 
 */
export async function FetchGPDetailBaseInfo(code) {
  try {
    const response = await request("POST", "/cec-sims-server/investment/detail_more", { base_investment_code: code })

    let types = []
    if (_.size(response['labelelements'])) {
      response['labelelements'].find(item => {
        types.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }
    let stages = []
    if (_.size(response['stage'])) {
      response['stage'].find(item => {
        stages.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }
    let activeInvestmentUsers = []
    if (_.size(response['list_investor_active'])) {
      response['list_investor_active'].find(item => {
        activeInvestmentUsers.push({ value: item.base_investor_code, label: item.base_investor_name, level: item.base_investor_posiname })
      })
    }
    let icInvestmentUsers = []
    if (_.size(response['list_investor_ic'])) {
      response['list_investor_ic'].find(item => {
        icInvestmentUsers.push({ value: item.base_investor_code, label: item.base_investor_name, level: item.base_investor_posiname })
      })
    }
    let ecapitalUsers = []
    if (_.size(response['ykusers'])) {
      response['ykusers'].find(item => {
        ecapitalUsers.push({ value: item.userid, label: item.username })
      })
    }
    // 企名片
    let industrys = [], industrySeconds = []
    if (_.size(response['directions'])) {
      response['directions'].find(item => {
        let array = []
        if (_.size(item.list)) {
          item.list.find(subItem => {
            industrySeconds.push(subItem.sys_labelelement_name)
            array.push({
              value: subItem.sys_labelelement_code,
              label: subItem.sys_labelelement_name,
            })
          })
        }
        industrys.push({
          value: item.sys_labelelement_code,
          label: item.sys_labelelement_name,
          children: array
        })
      })
    }

    const areaLabel = _.get(response, "location.sys_labelelement_name")
    const areaValue = _.get(response, "location.sys_labelelement_code")

    const result = {
      code: response['base_investment_code'],
      orgName: response['base_investment_name'],
      logo: response['base_investment_img'],
      desc: response['base_investment_about'],
      area: areaValue && areaLabel ? [{ value: areaValue, label: areaLabel }] : [],
      area2: response['location_code2'] && response['location_name2'] ? [{ value: response['location_code2'], label: response['location_name2'] }] : [],
      currencys: response['currency_code'] && response['currency_name'] ? [{ label: response['currency_name'], value: response['currency_code'] }] : [],
      types: types,
      stages: stages,
      groupLocation: response['investment_base'], // 公司集团所在属地
      listonLocation: response['investment_stock_exchange'], // 上市地
      activeInvestmentUsers: activeInvestmentUsers, //活跃投资人
      icInvestmentUsers: icInvestmentUsers,//投委会成员
      ecapitalUsers: ecapitalUsers,
      ecIndustrys: industrys,
    }
    return result
  } catch (error) {
    throw error
  }
}

/**
 * 获取LP详情 - 基本信息
 * @param {*} code 
 * @returns 
 */
const LP_ACTIVE_MAP = {
  "ACTIVE": "活跃",
  "INACTIVE": "不活跃",
  "UNKNOWN": "待定",
}
export async function FetchLPDetailBaseInfo(code) {

  try {
    const response = await request("POST", "/cec-sims-server/lp/detail_more2", { base_lp_code: code })

    let types = []
    if (_.size(response['labels'])) {
      response['labels'].find(item => {
        types.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }

    let ecapitalUsers = []
    if (_.size(response['ykusers'])) {
      response['ykusers'].find(item => {
        ecapitalUsers.push({ value: item.userid, label: item.username })
      })
    }

    let industryAndStages = []
    if (_.size(response['labels_industry'])) {
      response['labels_industry'].find((item, index) => {
        let stages = []
        if (item['stages'] && _.size(item['stages']) > 0) {
          item['stages'].find(stage => {
            stages.push({ value: stage['stagecode'], label: stage['stagename'], })
          })
        }

        if (item['pname']) {
          industryAndStages.push({
            key: `${moment().format("x")}-${index}`,
            industrys: item['pcode'] && item['pname'] ? [{ value: item['pcode'], label: item['pname'], children: [{ value: item['lcode'], label: item['lname'] }] }] : [],
            score: item['flagint'],
            stages: stages
          })
        } else {
          industryAndStages.push({
            key: `${moment().format("x")}-${index}`,
            industrys: item['lcode'] && item['lname'] ? [{ value: item['lcode'], label: item['lname'] }] : [],
            score: item['flagint'],
            stages: stages
          })
        }
      })
    }

    let specialConditions = []
    if (_.size(response['labels_special_condition'])) {
      response['labels_special_condition'].find((item, index) => {
        specialConditions.push({
          key: `${moment().format("x")}-${index}`,
          specialCondition: [{ label: item['sys_labelelement_name'], value: item['sys_labelelement_code'], selected: true }],
          active: item['flagint'] !== undefined ? [{ value: `${item['flagint']}`, label: item['flagint'] === 1 ? '是' : '否' }] : []
        })
      })
    }

    let manageInvestments = []
    if (response['lpinvestment']) {
      const item = response['lpinvestment']

      let amountValue = item['manage_money'] && item['manage_unit'] ? _.round(_.divide(item['manage_money'], item['manage_unit']), 2) : null,
        amountUnitValue = item['manage_unit'],
        amountUnitLabel = convertAmountUnit(item['manage_unit']),
        currency = item['manage_currency']

      manageInvestments.push({ value: item['base_investment_code'], label: item['base_investment_name'], amountValue: amountValue, amountUnitValue: amountUnitValue, amountUnitLabel: amountUnitLabel, currency: currency, id: item['id'] })
    }

    const result = {
      code: response['base_lp_code'],
      lpName: response['base_lp_name'],
      types: types,
      area: response['province'] && response['city'] ? [{ label: response['province'] }, { label: response['city'] }] : [],
      districtText: response['location'],
      address: response['address'],
      logo: null,
      description: response['note'],
      lpEstablish: response['cdate'] ? moment(response['cdate']) : null,
      // 易凯覆盖
      coverage: ["CREATE_YK", "UPDATE_YK"].includes(response['base_lp_stem']) ? true : false,
      coverageLabel: ["CREATE_YK", "UPDATE_YK"].includes(response['base_lp_stem']) ? "是" : "否",
      // 是否活跃
      active: response['activeflag'],
      activeLabel: response['activeflag'] ? LP_ACTIVE_MAP[response['activeflag']] : null,
      // 易凯负责人
      ecapitalUsers: ecapitalUsers,
      // 管理机构
      manageInvestments: manageInvestments,
      // 受托管理机构
      receiveInvestments: response['fund_manager_investment_code'] && response['fund_manager_investment_name'] ? [{ value: response['fund_manager_investment_code'], label: response['fund_manager_investment_name'] }] : [],
      // 管理规模
      manageScale: {
        amountValue: response['manage2_money'] && response['manage2_unit'] ? _.round(_.divide(response['manage2_money'], response['manage2_unit']), 2) : null,
        unit: response['manage2_unit'] ? [{ value: response['manage2_unit'].toString(), label: convertAmountUnit(response['manage2_unit']) }] : [],
        currency: response['manage2_currency'] ? [{ value: response['manage2_currency'], label: response['manage2_currency'] }] : [],
      },
      // 基金规模
      manageFundScale: {
        amountValue: response['manage_money'] && response['manage_unit'] ? _.round(_.divide(response['manage_money'], response['manage_unit']), 2) : null,
        unit: response['manage_unit'] ? [{ value: response['manage_unit'].toString(), label: convertAmountUnit(response['manage_unit']) }] : [],
        currency: response['manage_currency'] ? [{ value: response['manage_currency'], label: response['manage_currency'] }] : [],
      },
      // 出资规模
      outScale: {
        amountValue: response['scale'] && response['scale_unit'] ? _.round(_.divide(response['scale'], response['scale_unit']), 2) : null,
        unit: response['scale_unit'] ? [{ value: response['scale_unit'].toString(), label: convertAmountUnit(response['scale_unit']) }] : [],
        currency: response['base_lp_currency'] ? [{ value: response['base_lp_currency'], label: response['base_lp_currency'] }] : [],
      },
      // 已出资
      investment: response['financed_flag'],
      investmentLabel: response['financed_flag'] === true ? "已出资" : "未出资",
      investmentWay: {
        amountValue: response['financed_money'] && response['financed_unit'] ? _.round(_.divide(response['financed_money'], response['financed_unit']), 2) : null,
        unit: response['financed_unit'] ? [{ value: response['financed_unit'].toString(), label: convertAmountUnit(response['financed_unit']) }] : [],
        currency: response['financed_currency'] ? [{ value: response['financed_currency'], label: response['financed_currency'] }] : []
      },
      // 期限
      term: response['deadline'] && response['deadline_name'] ? [{ value: response['deadline'], label: response['deadline_name'], }] : [],
      // 投资倾向
      tendency: response['leanto'] && response['leanto_name'] ? [{ value: response['leanto'], label: response['leanto_name'] }] : [],
      // 返投条件
      reinvestmentConditions: response['reverse_condition'],
      // 特殊条件
      specialConditions: specialConditions,
      // 行业/阶段（评分）
      industryAndStages: industryAndStages,
      // 决策流程
      voteProgress: response['note_decision'],
      // 备注
      description: response['note'],
      note: response['note2'],
    }
    return result
  } catch (error) {
    throw error
  }
}

/**
 * 金额单位转换
 * @param {*} unit 
 * @returns 
 */
function convertAmountUnit(unit) {
  let unitValue = unit ? unit.toString() : null
  let unitLabel = null;
  switch (unitValue) {
    case '1000':
      unitLabel = '千元'
      break;
    case '1000000':
      unitLabel = '百万'
      break;
    case '1000000000':
      unitLabel = '亿元'
      break;
    default:
      break;
  }
  return unitLabel
}