import React, { PureComponent, createRef } from "react";
import { Button } from "antd-mobile";
import { AddOutline } from 'antd-mobile-icons'
import _ from "lodash";
import './index.css'

/**
 * 追加与会方分组
 */
export default class AppendTargetGroupItem extends PureComponent {

  handleClick(key, data, event) {
    // console.log('handleClick >>>>', key, data, event)
    const {
      metadata,
      sourcedata,
      sourcedataOrigin,
      datas,
      appendItems,
      onDataChange
    } = this.props

    switch (key) {
      case 'handle_append_group_item':
        let dataArray = [...datas]
        let sourcedataOriginNew = { ...sourcedataOrigin }
        if (appendItems && _.size(appendItems) > 0) {
          appendItems.find((item, index) => {
            const insertIndex = _.findLastIndex(dataArray, fItem => _.startsWith(fItem['key'], 'memo_option_list_attend_target'))
            const suffix = new Date().getTime()
            dataArray.splice(insertIndex + 1 + index, 0, {
              ...item,
              key: `${item['key']}_${suffix}`,
              // title: `${item['title']}_${suffix}`,
              ref: createRef(),
              groups: _.map(item['groups'], (gitem, gindex) => {
                const gKey = `${gitem['key']}_${suffix}_${gindex}`
                sourcedataOriginNew = _.assign({}, sourcedataOrigin, {
                  [gKey]: {
                    ...sourcedataOriginNew['memo_option_list_update_content']
                  }
                })
                return {
                  ...gitem,
                  key: gKey,
                  ref: createRef(),
                }
              })
            })
            return item
          })
        }

        onDataChange && onDataChange({ metadatas: dataArray, sourcedataOrigin: sourcedataOriginNew })
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button color="primary" fill="none" onClick={this.handleClick.bind(this, 'handle_append_group_item')}><AddOutline style={{ marginRight: 5, fontSize: '16px' }} />添加更多与会方</Button>
      </div>
    )
  }
}