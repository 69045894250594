import React from "react";
import BaseExtractPopup from "../BaseExtractPopup";
import {
  Button,
  TextArea
} from 'antd-mobile'

/**
 * 大文本编辑器
 */
export default class CECTextAreaPopup extends BaseExtractPopup {

  constructor(props) {
    super(props);

    this.state = {
      _value: "",
    }
  }

  handleChange(key, data, event) {
    // console.log('handleChange >>>>', key, data, event)
    let {
      _value
    } = this.state
    const {
      onConfirm
    } = this.props

    switch (key) {
      case 'change_text_value':
        this.setState({ _value: data })
        break;
      case 'change_confirm_value':
        onConfirm && onConfirm({ value: _value })
        this.close()
        break;
      default:
        break;
    }
  }

  renderBodyContent() {
    const {
      maxLength
    } = this.props
    const {
      _value
    } = this.state
    return (
      <div style={{ padding: 10 }}>
        <TextArea autoSize showCount
          rows={10}
          maxLength={maxLength}
          placeholder="请输入"
          value={_value}
          onChange={this.handleChange.bind(this, 'change_text_value')}
        />
      </div>
    )
  }

  renderFootContent() {
    return (
      <div>
        <Button block color="primary" onClick={this.handleChange.bind(this, 'change_confirm_value')}>确定</Button>
      </div>
    )
  }
}