import React, { Fragment, createRef } from "react";
import _ from "lodash";
import { Toast } from "antd-mobile";
import CECSelectPopup from "../../../../../components/SelectPopup";

/**
 * 项目Code
 */
export default class DealTargetPopup extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      _targetOptions: [],
      _targets: [],
    }

    this.dealTargetPopupRef = createRef()
  }

  show() {
    this.fetchDealTargetList()
    this.dealTargetPopupRef.current.show()
  }

  close() {
    this.dealTargetPopupRef.current.close()
  }

  toggle() {
    this.fetchDealTargetList()
    this.dealTargetPopupRef.current.toggle()
  }

  handleClick(key, data, event) {
    // console.log("handleClick >>>>", key, data, event)
    const { onConfirm } = this.props
    const { _targets } = this.state

    switch (key) {
      case 'handle_confirm_update_target_type_popup':
        this.setState({ _targets: data }, () => this.dealTargetPopupRef.current.close())
        onConfirm && onConfirm({ targets: data })
        break;
      case 'handle_open_update_target_type_popup':
        this.setState({ _targets: data }, () => {
          this.dealTargetPopupRef.current.close()
          this.attendTargetTypePopupRef.current.toggle()
        })
        break;
      case 'handle_confirm_update_target_type_value':
        this.setState({ _targetTypes: data }, () => this.attendTargetTypePopupRef.current.close())
        onConfirm && onConfirm({ targets: _targets, targetTypes: data })
        break;
      case 'handle_back_update_target_popup':
        this.dealTargetPopupRef.current.toggle()
        break;
      default:
        break;
    }
  }

  fetchDealTargetList = async () => {
    Toast.show({ content: '加载中...', icon: 'loading', duration: 0, maskStyle: { zIndex: 1500 } })
    await FetchDealTargetList().then((datas) => {
      Toast.show({ content: 'DEMO演示，仅支持部分数据', maskStyle: { zIndex: 1500 } })
      this.setState({ _targetOptions: datas })
    })
  }

  render() {
    const {
      _targetOptions,
      _targets,
    } = this.state
    const {
      metadata
    } = this.props

    return (
      <Fragment>

        {/** 更新项目Code */}
        <CECSelectPopup ref={this.dealTargetPopupRef}
          title={metadata['title']}
          supportSearch={true}
          supportAvatar={false}
          multiple={false}
          values={_targets}
          options={_targetOptions}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_update_target_type_popup')}
        />
      </Fragment>
    )
  }
}

export async function FetchDealTargetList() {
  return new Promise((resolve, reject) => {
    const datas = [
      { label: 'Space X', value: 'Space X' },
      { label: 'Metadata', value: 'Metadata' },
      { label: 'Qinghua', value: 'Qinghua' },
      { label: 'DDD', value: 'DDD' },
      { label: 'FFF', value: 'FFF' },
      { label: 'Yutube', value: 'Yutube' },
      { label: 'Yutube II', value: 'Yutube II' },
      { label: 'Yutube III', value: 'Yutube III' },
    ]
    resolve(datas)
  })
}