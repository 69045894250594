
import React, { useCallback, useEffect, useState } from "react"
import _ from "lodash"
import { Input, List, Space } from "antd-mobile"
import Required from "../Required"
import CECSelectorListItem from "../SelectorListItem"

/**
 * 金额、单位和币种
 * @param mode: single / range
 * @returns 
 */
export default function AmountUnitItem({ label, mode = "single", required = false, multiple = false, disabled = false, value, currencyOptions = [], unitOptions = [], onChange }) {

  const [amountValue, setAmountValue] = useState("")
  const [amountRangeValue, setAmountRangeValue] = useState([])
  const [currency, setCurrency] = useState([])
  const [unit, setUnit] = useState([])

  useEffect(() => {
    if (mode == 'range') {
      setAmountRangeValue(value && value['amountValue'] ? value['amountValue'] : [])
    } else {
      setAmountValue(value && value['amountValue'] ? value['amountValue'] : "")
    }
    setCurrency(value && value['currency'] ? value['currency'] : [])
    setUnit(value && value['unit'] ? value['unit'] : [])
  }, [value])

  const handleChange = useCallback((key, data, event) => {
    console.log("handleChange >>>>", key, data, event)

    switch (key) {
      case 'change_currency_value':
        setCurrency(event)
        onChange && onChange({ amountValue, currency: event, unit })
        break;
      case 'change_unit_value':
        setUnit(event)
        onChange && onChange({ amountValue, currency, unit: event })
        break;
      case 'change_input_value':
        setAmountValue(data)
        onChange && onChange({ amountValue: data, currency, unit })
        break;
      case 'change_input_min_value':
        amountRangeValue[0] = data
        setAmountValue(amountRangeValue)
        onChange && onChange({ amountValue: data, currency, unit })
        break;
      case 'change_input_max_value':
        break;
      default:
        break;
    }
  }, [amountValue, amountRangeValue, currency, unit])

  return (
    <List.Item description={
      <div>
        <CECSelectorListItem
          multiple={multiple}
          isRequired={required}
          disabled={disabled}
          columns={2}
          options={currencyOptions}
          value={currency ? _.map(currency, "value") : []}
          onChange={(value, options) => handleChange('change_currency_value', value, options)}
        />
        <CECSelectorListItem
          multiple={multiple}
          isRequired={required}
          disabled={disabled}
          columns={2}
          options={unitOptions}
          value={unit ? _.map(unit, "value") : []}
          onChange={(value, options) => handleChange('change_unit_value', value, options)}
        >
          {
            mode == 'range' ? (
              <Space>
                <Input placeholder="区间最小值" value={amountValue && _.size(amountValue) > 0 ? amountValue[0] : ""} disabled={disabled} onChange={handleChange.bind(null, 'change_input_min_value')} />
                <span>至</span>
                <Input placeholder="区间最大值" value={amountValue && _.size(amountValue) > 1 ? amountValue[1] : ""} disabled={disabled} onChange={handleChange.bind(null, 'change_input_max_value')} />
              </Space>
            ) : (
              <Input placeholder="请输入金额" value={amountValue} disabled={disabled} onChange={handleChange.bind(null, 'change_input_value')} />
            )
          }
        </CECSelectorListItem>
      </div>
    }>
      {label}{required === true && <Required />}
    </List.Item>
  )
}