import React, { createRef, Fragment } from "react";
import _ from 'lodash';
import BaseExtractPopup from "../../../../../../components/BaseExtractPopup";
import CECSelectPopup from "../../../../../../components/SelectPopup";
import CECTextAreaPopup from "../../../../../../components/TextAreaPopup";
import Required from "../../../../../../components/Required";
import UploadFile from "../../../../../../components/UploadFile";
import CECSelectorListItem from "../../../../../../components/SelectorListItem";
import {
    ListItemExtraPopoverMenu, 
    INVESTOR_USERS_OPTIONS, CURRENCY_OPTIONS, UNIT_OPTIONS,
} from "../../../../../../components/FormsUtils";
import { List, Button, Input, Toast } from 'antd-mobile';
import './index.css';
/**
 * 【项目更新：执行信息 - 立项】
 */


const FORM_LABE_OPTIONS = [
    //收入/利润
    { label: "单位", key: "create_project_revenue_profit_unit", type: 'options' },
    { label: "币种", key: "create_project_revenue_profit_currency", type: 'options' },
    { label: "去年利润", key: "create_project_profit", type: 'options' },
    { label: "去年利润金额", key: "create_project_profit_momeny", type: 'string' },
    { label: "预期当年收入", key: "create_project_revenue_momeny", type: 'string' },
    { label: "预期当年利润", key: "create_project_profit_year", type: 'options' },
    { label: "预期当年利润金额", key: "create_project_profit_year_momeny", type: 'string' },
    //规模/估值
    { label: "单位", key: "create_project_scale_valuation_unit", type: 'options' },
    { label: "币种", key: "create_project_scale_valuation_currency", type: 'options' },
    { label: "是否有历史融资", key: "create_project_scale_valuation_history", type: 'options' },
    { label: "预期交易规模", key: "create_project_scale_valuation_profit_momeny", type: 'string' },
    { label: "预期投前估值", key: "create_project_scale_valuation_revenue_momeny", type: 'string' },
    { label: "备注", key: "create_project_profit_scale_valuation_des", type: 'string' },
    //预期收费规模
    { label: "单位", key: "create_project_fee_scale_unit", type: 'options' },
    { label: "币种", key: "create_project_fee_scale_currency", type: 'options' },
    { label: "金额", key: "create_project_fee_scale_momeny", type: 'string' },
    { label: "费率 (%)", key: "create_project_fee_scale_rate", type: 'string' },
    //其他信息
    { label: "项目线索", key: "create_project_clue", type: 'options' },
    { label: "主BD", key: "create_project_fee_bd_main", type: 'options' },
    { label: "BD协助", key: "create_project_fee_bd", type: 'options' },
    { label: "项目是否做过前期试水", key: "create_project_test_water", type: 'options' },
    { label: "ECM是否参与立项评估", key: "create_project_ecm_assess", type: 'options' },
    { label: "公司架构", key: "create_project_company_framework", type: 'options' },
    { label: "公司类型", key: "create_project_company_type", type: 'options' },
    { label: "现有股东股权机构", key: "create_project_ownership_structure", type: 'options' },
    { label: "上传立项材料", key: "create_project_files", type: 'options' },
    

]
const FORM_LABEL_MAP = _.chain(FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()


export default class CreateProjectPopup extends BaseExtractPopup {
    constructor(props) {
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: true,
        }
        this.options_ref = createRef()
        this.text_area_ref = createRef()
        this.ownershipStructurePopupRef = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch(key){
            case "onStateChange":
                this.setState({
                    _info: {..._info, [data]: event}
                })
                break
            case 'openSelect':
                Toast.show({ content: "DEMO, 临时数据...."})
                let _tempOptions = [], _tempMultiple = true
                switch(data){
                    case 'create_project_fee_bd_main':
                    case 'create_project_fee_bd':
                    case 'create_project_clue':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        break
                    // case 'BDInvestor_type':
                    //     _tempOptions = INVESTOR_TYPE_OPTIONS
                    //     break
                    // case 'BDInvestor_users':
                    //     _tempOptions = INVESTOR_USERS_OPTIONS
                    //     _tempMultiple = true
                    //     break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, ()=> this.options_ref?.current?.toggle())
                break
            case 'openTextArea':
                this.setState({
                    _onStateChangeKey: data,
                }, ()=> this.text_area_ref?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info, _onStateChangeKey, _multiple, _options } = this.state
        return (
            <Fragment>
                <List className="cec-smartx-create-deal-gourp-list"
                    header={
                        <div className="cec-smartx-create-deal-gourp-title">
                            收入/利润
                        </div>
                    }
                >
                       
                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_revenue_profit_unit']}
                        isRequired={true}
                        options={UNIT_OPTIONS}
                        value={_info['create_project_revenue_profit_unit'] ? _.map(_info['create_project_revenue_profit_unit'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_revenue_profit_unit', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_revenue_profit_currency']}
                        isRequired={true}
                        options={CURRENCY_OPTIONS}
                        value={_info['create_project_revenue_profit_currency'] ? _.map(_info['create_project_revenue_profit_currency'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_revenue_profit_currency', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_profit']} columns={4}
                        isRequired={true}
                        options={[
                            {label: "金额", value: "金额"}, {label: "微利", value: "微利"},
                            {label: "亏损", value: "亏损"}, {label: "打平", value: "打平"},
                        ]}
                        value={_info['create_project_profit'] ? _.map(_info['create_project_profit'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_profit', options)}
                    />

                    {
                        _.isEqual("金额", _.head(_.map(_info['create_project_profit'], 'value'))) && (
                           <List.Item 
                                key={`create_project_profit_momeny`}
                                extra={
                                    <Input 
                                        placeholder="请输入" 
                                        value={_info['create_project_profit_momeny']}
                                        onChange={val=> this.handleClick('onStateChange', 'create_project_profit_momeny', val)}
                                    />
                                }
                            >
                                {FORM_LABEL_MAP['create_project_profit_momeny']}<Required />
                            </List.Item> 
                        )
                    }
                    <List.Item 
                        key={`create_project_revenue_momeny`}
                        extra={
                            <Input 
                                placeholder="请输入" 
                                value={_info['create_project_revenue_momeny']}
                                onChange={val=> this.handleClick('onStateChange', 'create_project_revenue_momeny', val)}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['create_project_revenue_momeny']}<Required />
                    </List.Item> 

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_profit_year']} columns={4}
                        isRequired={true}
                        options={[
                            {label: "金额", value: "金额"}, {label: "微利", value: "微利"},
                            {label: "亏损", value: "亏损"}, {label: "打平", value: "打平"},
                        ]}
                        value={_info['create_project_profit_year'] ? _.map(_info['create_project_profit_year'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_profit_year', options)}
                    />

                    {
                        _.isEqual("金额", _.head(_.map(_info['create_project_profit_year'], 'value'))) && (
                           <List.Item 
                                key={`create_project_profit_year_momeny`}
                                extra={
                                    <Input 
                                        placeholder="请输入" 
                                        value={_info['create_project_profit_year_momeny']}
                                        onChange={val=> this.handleClick('onStateChange', 'create_project_profit_year_momeny', val)}
                                    />
                                }
                            >
                                {FORM_LABEL_MAP['create_project_profit_year_momeny']}<Required />
                            </List.Item> 
                        )
                    }
                </List>


                <List className="cec-smartx-create-deal-gourp-list"
                    header={
                        <div className="cec-smartx-create-deal-gourp-title">
                            规模/估值
                        </div>
                    } 
                >
                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_scale_valuation_unit']}
                        isRequired={true}
                        options={UNIT_OPTIONS}
                        value={_info['create_project_scale_valuation_unit'] ? _.map(_info['create_project_scale_valuation_unit'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_scale_valuation_unit', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_scale_valuation_currency']}
                        isRequired={true}
                        options={CURRENCY_OPTIONS}
                        value={_info['create_project_scale_valuation_currency'] ? _.map(_info['create_project_scale_valuation_currency'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_scale_valuation_currency', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_scale_valuation_history']}
                        isRequired={true}
                        options={[
                            {label: "是", value: "是"}, {label: "否", value: "否"}, 
                        ]}
                        value={_info['create_project_scale_valuation_history'] ? _.map(_info['create_project_scale_valuation_history'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_scale_valuation_history', options)}
                    />

                    <List.Item 
                        key={`create_project_scale_valuation_profit_momeny`}
                        extra={
                            <Input 
                                placeholder="请输入" 
                                value={_info['create_project_scale_valuation_profit_momeny']}
                                onChange={val=> this.handleClick('onStateChange', 'create_project_scale_valuation_profit_momeny', val)}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['create_project_scale_valuation_profit_momeny']}<Required />
                    </List.Item> 

                    <List.Item 
                        key={`create_project_scale_valuation_revenue_momeny`}
                        extra={
                            <Input 
                                placeholder="请输入" 
                                value={_info['create_project_scale_valuation_revenue_momeny']}
                                onChange={val=> this.handleClick('onStateChange', 'create_project_scale_valuation_revenue_momeny', val)}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['create_project_scale_valuation_revenue_momeny']}<Required />
                    </List.Item> 


                    <List.Item 
                        key={`create_project_profit_scale_valuation_des`}
                        extra={
                            _info['create_project_profit_scale_valuation_des'] ? _info['create_project_profit_scale_valuation_des']: ""
                        }
                        onClick={()=> this.handleClick("openTextArea", 'create_project_profit_scale_valuation_des')}
                    >
                        {FORM_LABEL_MAP['create_project_profit_scale_valuation_des']}<Required />
                    </List.Item> 

                    

                </List>



                <List className="cec-smartx-create-deal-gourp-list"
                    header={
                        <div className="cec-smartx-create-deal-gourp-title">
                            预期收费规模
                        </div>}
                >
                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_fee_scale_unit']}
                        isRequired={true}
                        options={UNIT_OPTIONS}
                        value={_info['create_project_fee_scale_unit'] ? _.map(_info['create_project_fee_scale_unit'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_fee_scale_unit', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_fee_scale_currency']}
                        isRequired={true}
                        options={CURRENCY_OPTIONS}
                        value={_info['create_project_fee_scale_currency'] ? _.map(_info['create_project_fee_scale_currency'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_fee_scale_currency', options)}
                    />

                    <List.Item 
                        key={`create_project_fee_scale_momeny`}
                        extra={
                            <Input 
                                placeholder="请输入" 
                                value={_info['create_project_fee_scale_momeny']}
                                onChange={val=> this.handleClick('onStateChange', 'create_project_fee_scale_momeny', val)}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['create_project_fee_scale_momeny']}<Required />
                    </List.Item> 

                    <List.Item 
                        key={`create_project_fee_scale_rate`}
                        extra={
                            <Input 
                                placeholder="请输入" 
                                value={_info['create_project_fee_scale_rate']}
                                onChange={val=> this.handleClick('onStateChange', 'create_project_fee_scale_rate', val)}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['create_project_fee_scale_rate']}<Required />
                    </List.Item> 
                </List>



                <List className="cec-smartx-create-deal-gourp-list"
                    header={
                        <div className="cec-smartx-create-deal-gourp-title">
                            其他信息
                        </div>
                    }
                    style={{'--border-bottom': '1px solid #eee'}}
                >

                    <List.Item 
                        key={`create_project_clue`}
                        extra={
                            _info['create_project_clue'] ? _.join(_.map(_info['create_project_clue'], 'label'), '，'): ""
                        }
                        onClick={()=> this.handleClick("openSelect", 'create_project_clue')}
                    >
                        {FORM_LABEL_MAP['create_project_clue']}<Required />
                    </List.Item> 


                    <List.Item 
                        key={`create_project_fee_bd_main`}
                        extra={
                            _info['create_project_fee_bd_main'] ? _.join(_.map(_info['create_project_fee_bd_main'], 'label'), '，'): ""
                        }
                        onClick={()=> this.handleClick("openSelect", 'create_project_fee_bd_main')}
                    >
                        {FORM_LABEL_MAP['create_project_fee_bd_main']}<Required />
                    </List.Item> 


                    <List.Item 
                        key={`create_project_fee_bd`}
                        extra={
                            _info['create_project_fee_bd'] ? _.join(_.map(_info['create_project_fee_bd'], 'label'), '，'): ""
                        }
                        onClick={()=> this.handleClick("openSelect", 'create_project_fee_bd')}
                    >
                        {FORM_LABEL_MAP['create_project_fee_bd']}<Required />
                    </List.Item>


                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_test_water']}
                        isRequired={true}
                        options={[
                            {label: "是", value: "是"}, {label: "否", value: "否"}, 
                        ]}
                        value={_info['create_project_test_water'] ? _.map(_info['create_project_test_water'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_test_water', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_ecm_assess']}
                        isRequired={true}
                        options={[
                            {label: "是", value: "是"}, {label: "否", value: "否"}, 
                        ]}
                        value={_info['create_project_ecm_assess'] ? _.map(_info['create_project_ecm_assess'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_ecm_assess', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_company_framework']}
                        isRequired={true}
                        options={[
                            {label: "境内", value: "境内"}, {label: "境外", value: "境外"}, 
                        ]}
                        value={_info['create_project_company_framework'] ? _.map(_info['create_project_company_framework'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_company_framework', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['create_project_company_type']}
                        isRequired={true} columns={2}
                        options={[
                            {label: "上市公司", value: "上市公司"}, {label: "大企业集团", value: "大企业集团"},
                            {label: "跨国公司", value: "跨国公司"}, {label: "创业公司", value: "创业公司"},  
                        ]}
                        value={_info['create_project_company_type'] ? _.map(_info['create_project_company_type'], 'value'): []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'create_project_company_type', options)}
                    />

                    <List.Item 
                        key={`create_project_ownership_structure`}
                        extra={
                            _.size(_info['create_project_ownership_structure']) > 0 ? 
                            <ListItemExtraPopoverMenu 
                                onDelete={()=> this.handleClick('onStateChange', 'create_project_ownership_structure', [])}
                                onEdit={()=> this.ownershipStructurePopupRef?.current?.toggle()}
                            />: ""
                        }
                        onClick={()=> this.ownershipStructurePopupRef?.current?.toggle()}
                    >
                        {FORM_LABEL_MAP['create_project_ownership_structure']}<Required />
                    </List.Item>

                    <List.Item 
                        key={`create_project_files`}
                        description={
                            <UploadFile 
                                multiple={false} 
                                values={_info['create_project_files']} 
                                onChange={(values) => {
                                    this.handleClick("onStateChange", 'create_project_files', _.map(values, it=> ({...it, label: it.name, value: it.url}))) 
                                }} 
                            />
                        }
                    >
                        {FORM_LABEL_MAP['create_project_files']}<Required />
                    </List.Item>
                </List>

                <OwnershipStructurePopup 
                    ref={this.ownershipStructurePopupRef }
                    metadata={{title: FORM_LABEL_MAP['create_project_ownership_structure']}}
                    onConfirm={val=> {
                        let list = _.get(_info, 'create_project_ownership_structure', [])
                        list.push(val)
                        this.handleClick('onStateChange', 'create_project_ownership_structure', list)
                    }}
                />


                <CECSelectPopup 
                    ref={this.options_ref}
                    supportSearch={true}
                    multiple={_multiple}
                    title={_.get(FORM_LABEL_MAP, _onStateChangeKey, '')}
                    options={_options}
                    onConfirm={val=> this.handleClick('onStateChange', _onStateChangeKey, val)}
                />


                <CECTextAreaPopup 
                    metadata={{title: _.get(FORM_LABEL_MAP, _onStateChangeKey, '')}}
                    ref={this.text_area_ref} 
                    onConfirm={val=> this.handleClick('onStateChange', _onStateChangeKey, _.get(val, 'value', null))}/>
            
            </Fragment>
        )
    }

    renderFootContent() {
        const { _info } = this.state
        const { onConfirm } = this.props
        return (
            <Button block color="primary" onClick={() => {
              onConfirm && onConfirm({..._info, labelOptions: FORM_LABE_OPTIONS})
              this.close()
            }}>确定</Button>
          )
    }
}




/**股权机构 */
const OS_FORM_LABEL_OPTIONS = [
    {label: "股东名称", key: "os_name", type: "string"},
    {label: "占比", key: "os_proportion", type: "string"},
]
const OS_FORM_LABEL_MAP = _.chain(OS_FORM_LABEL_OPTIONS).keyBy('key').mapValues('label').value()

class OwnershipStructurePopup extends BaseExtractPopup{
    constructor(props){
        super(props)
        this.state = {
            _info: {}
        }
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch(key){
            case "onStateChange":
                this.setState({
                    _info: {..._info, [data]: event}
                })
                break
            case 'openSelect':
                let _tempOptions = [], _tempMultiple = true
                switch(data){
                    case 'create_project_fee_bd_main':
                    case 'create_project_fee_bd':
                    case 'create_project_clue':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        break
                    // case 'BDInvestor_type':
                    //     _tempOptions = INVESTOR_TYPE_OPTIONS
                    //     break
                    // case 'BDInvestor_users':
                    //     _tempOptions = INVESTOR_USERS_OPTIONS
                    //     _tempMultiple = true
                    //     break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, ()=> this.options_ref?.current?.toggle())
                break
            case 'openTextArea':
                this.setState({
                    _onStateChangeKey: data,
                }, ()=> this.text_area_ref?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info } = this.state
        return (
            <List>
                <List.Item 
                    key={`os_name`}
                    extra={
                        <Input 
                            placeholder="请输入" 
                            value={_info['os_name']}
                            onChange={val=> this.handleClick('onStateChange', 'os_name', val)}
                        />
                    }
                >
                    {OS_FORM_LABEL_MAP['os_name']}<Required />
                </List.Item> 
                <List.Item 
                    key={`os_proportion`}
                    extra={
                        <Input 
                            placeholder="请输入" 
                            value={_info['os_proportion']}
                            onChange={val=> this.handleClick('onStateChange', 'os_proportion', val)}
                        />
                    }
                >
                    {OS_FORM_LABEL_MAP['os_proportion']}<Required />
                </List.Item>
            </List>
        )
    }

    renderFootContent() {
        const { _info } = this.state
        const { onConfirm } = this.props
        return (
            <Button block color="primary" onClick={() => {
              onConfirm && onConfirm({..._info, labelOptions: OS_FORM_LABEL_OPTIONS})
              this.close()
            }}>确定</Button>
          )
    }
}