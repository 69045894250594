import React, { createRef, Fragment, useState, useCallback, useEffect } from "react"
import _ from "lodash";
import DealTargetPopup from "../components/DealTargetPopup"
import BaseDealInfoPopup from "../components/BaseDealInfoPopup"
import { CreateBDInvestorNameListPopup, UploadDBInvestorNameListFilesPopup } from '../components/DealExecInfoUpdateContent/BDInvestorNameList';
import CreateProjectPopup from '../components/DealExecInfoUpdateContent/CreateDeal'
import CreateELPopup from '../components/DealExecInfoUpdateContent/EL'
import { TeaserPopup, BPPopup, PitchbookPopup, InfoMemoPopup, LookPopup } from "../components/DealExecInfoUpdateContent/MarketingDocuments"
import CreateMarketInvestorNameListPopup from "../components/DealExecInfoUpdateContent/MarketInvestorNameList"
import RefundPopup from "../components/DealExecInfoUpdateContent/Refund"
import CompanySimplePopup from "../components/DealComlete/CompanySimplePopup"
import TheRoundInvestorSimplePopup from "../components/DealComlete/TheRoundInvestorSimplePopup"
import SignAgreement1Popup from "../components/DealComlete/SignAgreement/1Popup"
import SignAgreement2Popup from "../components/DealComlete/SignAgreement/2Popup"
import SignAgreement3Popup from "../components/DealComlete/SignAgreement/3Popup"
import SignAgreement4Popup from "../components/DealComlete/SignAgreement/4Popup"
import SignAgreement5Popup from "../components/DealComlete/SignAgreement/5Popup"
import SignAgreement6Popup from "../components/DealComlete/SignAgreement/6Popup"
import SignAgreement7Popup from "../components/DealComlete/SignAgreement/7Popup"
import SignAgreement8Popup from "../components/DealComlete/SignAgreement/8Popup"
import SignAgreement9Popup from "../components/DealComlete/SignAgreement/9Popup"
import ExecArchive1Popup from "../components/DealComlete/ExecArchive/1Popup"
import ExecArchive2Popup from "../components/DealComlete/ExecArchive/2Popup"
import ExecArchive3Popup from "../components/DealComlete/ExecArchive/3Popup"
import ExecArchive4Popup from "../components/DealComlete/ExecArchive/4Popup"
import ExecArchive5Popup from "../components/DealComlete/ExecArchive/5Popup"
import ExecArchive6Popup from "../components/DealComlete/ExecArchive/6Popup"
import ExecArchive7Popup from "../components/DealComlete/ExecArchive/7Popup"
import ExecArchive8Popup from "../components/DealComlete/ExecArchive/8Popup"
import ExecArchive9Popup from "../components/DealComlete/ExecArchive/9Popup"
import ExecArchive10Popup from "../components/DealComlete/ExecArchive/10Popup"
import ExecArchive11Popup from "../components/DealComlete/ExecArchive/11Popup"
import LawArchive1Popup from "../components/DealComlete/LawArchive/1Popup"
import LawArchive2Popup from "../components/DealComlete/LawArchive/2Popup"
import LawArchive3Popup from "../components/DealComlete/LawArchive/3Popup"
import LawArchive4Popup from "../components/DealComlete/LawArchive/4Popup"
import LawArchive5Popup from "../components/DealComlete/LawArchive/5Popup"
import LawArchive6Popup from "../components/DealComlete/LawArchive/6Popup"
import LawArchive7Popup from "../components/DealComlete/LawArchive/7Popup"
import ActionAndEndReportPopup from "../components/DealComlete/ActionAndEndReportPopup"
import UnCompleteItemsPopup from "../components/DealComlete/UnCompleteItems"
import Tree from "../../../../components/Tree";
import { Selector } from "antd-mobile";

export const BASE_EXEC_DOCUMENTS_OPTIONS = [
  {
    label: 'BD投资人名单',
    value: 'deal_option_bd_investor_name_list',
    children: [
      {
        label: '在线创建',
        value: 'deal_option_bd_investor_name_list_online',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <CreateBDInvestorNameListPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '上传附件',
        value: 'deal_option_bd_investor_name_list_upload_file',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <UploadDBInvestorNameListFilesPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      },
    ]
  }, {
    label: '立项',
    value: 'deal_option_creation',
    // ref: createRef(),
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <CreateProjectPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }, {
    label: 'EL（仅VP及以上）',
    value: 'deal_option_el',
    // ref: createRef(),
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <CreateELPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }, {
    label: '营销文件',
    value: 'deal_option_marketing_documents',
    children: [
      {
        label: 'Teaser',
        value: 'deal_option_marketing_documents_teaser',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <TeaserPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'BP',
        value: 'deal_option_marketing_documents_bp',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <BPPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Pitchbook',
        value: 'deal_option_marketing_documents_pitchbook',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <PitchbookPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Info Memo',
        value: 'deal_option_marketing_documents_info_memo',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <InfoMemoPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '管理层演示',
        value: 'deal_option_marketing_documents_management_interview',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LookPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }
    ]
  }, {
    label: 'Marketing投资人名单',
    value: 'deal_option_marketing_investor_name_list',
    // ref: createRef(),
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <CreateMarketInvestorNameListPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }, {
    label: '回款',
    value: 'deal_option_refund',
    children: [
      {
        label: '协议应收数据',
        value: 'deal_option_refund_agreement_receivable',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <RefundPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '实际回款申请/审批',
        value: 'deal_option_refund_actual_receivable_request_approve',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <RefundPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '财务收款',
        value: 'deal_option_refund_financial',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <RefundPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      },
    ]
  },
]

export const BASE_DEAL_COMPLETE_SUSPENDED_TERMINATED_OPTIONS = [
  {
    label: '公司概况',
    value: 'deal_option_complete_company_simple',
    // ref: createRef(),
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <CompanySimplePopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }, {
    label: '执行资料归档',
    value: 'deal_option_complete_exec_archive',
    children: [
      {
        label: 'Pitchbook',
        value: 'deal_option_complete_exec_archive_pitchbook',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive1Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '投资人名单',
        value: 'deal_option_complete_exec_archive_investor_name_list',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive2Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '投资人Tracking Report',
        value: 'deal_option_complete_exec_archive_tracking_report',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive3Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Financial Model',
        value: 'deal_option_complete_exec_archive_financial_model',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive4Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Datapack',
        value: 'deal_option_complete_exec_archive_datapack',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive5Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'DD Report（尽调报告/尽调清单）',
        value: 'deal_option_complete_exec_archive_dd_report',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive6Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Teaser',
        value: 'deal_option_complete_exec_archive_teaser',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive7Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '发行概要BP',
        value: 'deal_option_complete_exec_archive_simple_bp',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive8Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Info Memo',
        value: 'deal_option_complete_exec_archive_info_memo',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive9Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '管理层演示',
        value: 'deal_option_complete_exec_archive_management_interview',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive10Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '其他',
        value: 'deal_option_complete_exec_archive_others',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive11Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }
    ]
  }, {
    label: '法律文件归档',
    value: 'deal_option_complete_law_archive',
    children: [
      {
        label: '委托协议EL（盖章扫描）',
        value: 'deal_option_complete_law_archive_el',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive1Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '股东协议',
        value: 'deal_option_complete_law_archive_share_agreement',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive2Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '公司章程',
        value: 'deal_option_complete_law_archive_company_law_file',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive3Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '重组备忘录',
        value: 'deal_option_complete_law_archive_important_memo',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive4Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '保密协议（NDA）',
        value: 'deal_option_complete_law_archive_nda',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive5Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '条款要约（TS/LOI）',
        value: 'deal_option_complete_law_archive_ts_loi',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive6Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'SPA',
        value: 'deal_option_complete_law_archive_spa',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive7Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      },
    ]
  },
]


export const BASE_DEAL_COMPLETE_CLOSED_OPTIONS = [
  {
    label: '公司概况',
    value: 'deal_option_full_complete_company_simple',
    // ref: createRef(),
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <CompanySimplePopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }, {
    label: '本轮投资人概况',
    value: 'deal_option_full_complete_this_round_investor_simple_info',
    // ref: createRef(),
    // disabled: true,
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <TheRoundInvestorSimplePopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }, {
    label: '签约条款',
    value: 'deal_option_full_complete_sign_agreements',
    children: [
      {
        label: '过桥贷款',
        value: 'deal_option_full_complete_sign_agreements_1',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement1Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '长期债权',
        value: 'deal_option_full_complete_sign_agreements_2',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement2Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '涡轮',
        value: 'deal_option_full_complete_sign_agreements_3',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement3Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '业绩对赌',
        value: 'deal_option_full_complete_sign_agreements_4',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement4Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '回购要求',
        value: 'deal_option_full_complete_sign_agreements_5',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement5Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '优先清算权',
        value: 'deal_option_full_complete_sign_agreements_6',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement6Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '反稀释',
        value: 'deal_option_full_complete_sign_agreements_7',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement7Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '董事会席位',
        value: 'deal_option_full_complete_sign_agreements_8',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement8Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'ESOP',
        value: 'deal_option_full_complete_sign_agreements_9',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <SignAgreement9Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      },
    ]
  }, {
    label: '执行资料归档',
    value: 'deal_option_full_complete_exec_archive',
    children: [
      {
        label: 'Pitchbook',
        value: 'deal_option_full_complete_exec_archive_pitchbook',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive1Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '投资人名单',
        value: 'deal_option_full_complete_exec_archive_investor_name_list',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive2Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '投资人Tracking Report',
        value: 'deal_option_full_complete_exec_archive_tracking_report',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive3Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Financial Model',
        value: 'deal_option_full_complete_exec_archive_financial_model',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive4Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Datapack',
        value: 'deal_option_full_complete_exec_archive_datapack',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive5Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'DD Report（尽调报告/尽调清单）',
        value: 'deal_option_full_complete_exec_archive_dd_report',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive6Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Teaser',
        value: 'deal_option_full_complete_exec_archive_teaser',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive7Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '发行概要BP',
        value: 'deal_option_full_complete_exec_archive_simple_bp',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive8Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'Info Memo',
        value: 'deal_option_full_complete_exec_archive_info_memo',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive9Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '管理层演示',
        value: 'deal_option_full_complete_exec_archive_management_interview',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive10Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '其他',
        value: 'deal_option_full_complete_exec_archive_others',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <ExecArchive11Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }
    ]
  }, {
    label: '法律文件归档',
    value: 'deal_option_full_complete_law_archive',
    children: [
      {
        label: '委托协议EL（盖章扫描）',
        value: 'deal_option_full_complete_law_archive_el',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive1Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '股东协议',
        value: 'deal_option_full_complete_law_archive_share_agreement',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive2Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '公司章程',
        value: 'deal_option_full_complete_law_archive_company_law_file',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive3Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '重组备忘录',
        value: 'deal_option_full_complete_law_archive_important_memo',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive4Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '保密协议（NDA）',
        value: 'deal_option_full_complete_law_archive_nda',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive5Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: '条款要约（TS/LOI）',
        value: 'deal_option_full_complete_law_archive_ts_loi',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive6Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      }, {
        label: 'SPA',
        value: 'deal_option_full_complete_law_archive_spa',
        // ref: createRef(),
        // componentRender: ({ metadata, sourcedata, onConfirm }) => <LawArchive7Popup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
      },
    ]
  }, {
    label: '规定动作/结案报告',
    value: 'deal_option_full_complete_end_report',
    // ref: createRef(),
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <ActionAndEndReportPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }, {
    label: '未结事项',
    value: 'deal_option_full_complete_uncomplete_items',
    // ref: createRef(),
    // componentRender: ({ metadata, sourcedata, onConfirm }) => <UnCompleteItemsPopup ref={metadata['ref']} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
  }
]

export const COMPONENT_OPTION_LIST = [
  {
    label: '项目Code',
    key: 'deal_option_list_code',
    showItem: true,
    required: true,
    ref: createRef(),
    title: '项目Code',
    componentRender: ({ metadata, sourcedata, onConfirm }) => (
      <DealTargetPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
    ),
  }, {
    label: '基本信息',
    key: 'deal_option_list_base_info',
    showItem: true,
    required: false,
    disabled: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      const targetValues = _.get(extensionValue, `deal_option_list_code.targets`)
      if (targetValues && _.size(targetValues) > 0) {
        return false
      }
      return true
    },
    ref: createRef(),
    title: '基本信息',
    componentRender: ({ metadata, sourcedata, onConfirm }) => <BaseDealInfoPopup key={metadata['key']} ref={metadata.ref} metadata={{ ...metadata, codeDisable: true }} sourcedata={sourcedata} onConfirm={onConfirm} />,
  }, {
    label: '执行信息',
    key: 'deal_option_list_exec_info_content',
    className: 'cec-hidden-arrow',
    showItem: true,
    required: false,
    disabled: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      const targetValues = _.get(extensionValue, `deal_option_list_code.targets`)
      if (targetValues && _.size(targetValues) > 0) {
        return false
      }
      return true
    },
    getValue: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      return {}
    },
    placeholder: '',
    // 可自定义Label后缀返回信息
    getLabelSuffix: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      return null
    },
    multiPathComponentRender: ({ groupKey, metadata, sourcedata, extensionValue, onSelect, onConfirm, onClick }) => {
      let values = _.get(extensionValue, `${metadata.key}`)
      return (
        <Tree placeholder="请选择更新内容" metadata={metadata} sourcedata={sourcedata} values={values} datas={BASE_EXEC_DOCUMENTS_OPTIONS} onSelect={onSelect} onConfirm={onConfirm} onClick={onClick} />
      )
    },
  }, {
    label: '项目状态变更为',
    key: 'deal_option_list_complete_info',
    className: 'cec-hidden-arrow cec-hidden-deal-empty',
    showItem: true,
    required: false,
    disabled: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      const targetValues = _.get(extensionValue, `deal_option_list_code.targets`)
      if (targetValues && _.size(targetValues) > 0) {
        return false
      }
      return true
    },
    getValue: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      return null
    },
    placeholder: '',
    // 可自定义Label后缀返回信息
    getLabelSuffix: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      return null
    },
    multiPathComponentRender: ({ groupKey, metadata, sourcedata, extensionValue, onSelect, onConfirm, onClick }) => {
      // console.log("multiPathComponentRender >>>>", metadata, sourcedata, extensionValue)
      let values = _.get(extensionValue, `${metadata.key}`)
      return (
        <CompositeTree metadata={metadata} sourcedata={sourcedata} values={values} onSelect={onSelect} onConfirm={onConfirm} onClick={onClick} />
      )
    },
  },
]

export const COMPONENT_OPTION_LIST_MULTIPLE_CASCADER_PATHS = {
  'deal_option_list_complete_info': {
    options: [
      {
        label: 'Suspended',
        value: 'deal_status_suspended',
        children: [...BASE_DEAL_COMPLETE_SUSPENDED_TERMINATED_OPTIONS]
      },
      {
        label: 'Terminated',
        value: 'deal_status_terminated',
        children: [...BASE_DEAL_COMPLETE_SUSPENDED_TERMINATED_OPTIONS]
      },
      {
        label: 'Closed',
        value: 'deal_status_closed',
        children: [...BASE_DEAL_COMPLETE_CLOSED_OPTIONS]
      }
    ]
  }
}

export function CompositeTree({ groupKey, metadata, sourcedata, extensionValue, values, onSelect, onConfirm, onClick }) {

  const [dealStatus, setDealStatus] = useState(localStorage.getItem("__dealStatus"))
  const handleDealStatus = useCallback((value) => {
    setDealStatus(value)
    localStorage.setItem("__dealStatus", value)
  }, [dealStatus])

  useEffect(() => {
    console.log("CompositeTree mount....");
    return () => {
      console.log("CompositeTree destory....");
    };
  }, []);

  return (
    <Fragment>
      <div>
        <div style={{ marginTop: 10 }}>
          <Selector value={dealStatus}
            options={[
              { label: "Suspended", value: 'Suspended' },
              { label: "Terminated", value: "Terminated" },
              { label: 'Closed', value: 'Closed' }
            ]}
            onChange={handleDealStatus}
          />
        </div>
      </div>
      {
        dealStatus && _.size(dealStatus) > 0 && (
          <Fragment>
            <div style={{ marginTop: 12 }}>完结信息</div>
            <div>
              <Tree placeholder="请选择更新内容" metadata={metadata} sourcedata={sourcedata} values={values} datas={dealStatus.includes('Closed') ? BASE_DEAL_COMPLETE_CLOSED_OPTIONS : BASE_DEAL_COMPLETE_SUSPENDED_TERMINATED_OPTIONS} onSelect={onSelect} onConfirm={onConfirm} onClick={onClick} />
            </div>
          </Fragment>
        )
      }
    </Fragment>
  )
}