import _ from "lodash";
import request from "../../../../../../../api/HttpRequest";

/**
 * 获取人事列表
 */
export async function FetchEmploymentHistoryDataList({ code, pageNum = 1, pageSize = 2 }) {

  const payload = {
    base_lp_code: code,
    comgplpflag: true,
    pageNum: pageNum <= 0 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/lp/lpuserlist", payload)
    const total = response.length // response['total']
    const list = response // response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          id: item['id'],
          code: item['base_lp_code'],

          modifyDate: item['modifyDate'],
          modidyUser: item['updateusername'],
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    console.log("employee >>>>", error)
    throw error
  }
}

/**
 * 获取人事详情
 * @param {*} id 
 */
export async function FetchEmploymentHistoryDataDetail(id) {
  try {
    const response = await request("POST", "", { base_investment_code: id })
    console.log("FetchEmploymentHistoryDataDetail >>>>", response)

  } catch (error) {
    throw error
  }
}