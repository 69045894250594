import React, { PureComponent } from "react";
import { NavBar, Popup } from 'antd-mobile';
import Layout from '../../components/Layout'
import './index.css';

/**
 * 弹窗
 */
export default class CECPopup extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      _visible: false,
    }
  }

  show() {
    this.setState({ _visible: true })
  }

  close() {
    this.setState({ _visible: false })
  }

  toggle() {
    const { _visible } = this.state
    this.setState({ _visible: !_visible })
  }

  render() {
    const {
      _visible
    } = this.state
    const {
      navTitle,
      navRight,
      onNavBack,
      backArrow = true,
      navLeft,
      children,
      foot,
      destroyOnClose = false,
      closeOnMaskClick = false,
      position = 'right',
      bodyStyle,
      afterShow,
      afterClose,
      overflow,
    } = this.props
    
    return (
      <Popup className="cec-smartx-popup"
        bodyClassName="cec-smartx-popup-body"
        bodyStyle={bodyStyle}
        position={position}
        visible={_visible}
        closeOnSwipe={true}
        closeOnMaskClick={closeOnMaskClick}
        onClose={onNavBack}
        destroyOnClose={destroyOnClose}
        afterShow={afterShow}
        afterClose={afterClose}>
        <Layout>
          <NavBar className="cec-smartx-inner-head" onBack={onNavBack} backArrow={backArrow} left={navLeft} right={navRight}>{navTitle}</NavBar>
          <div className={foot ? "cec-smartx-inner-body has-foot" : "cec-smartx-inner-body"} style={overflow ? { overflow: "hidden" } : {}}>
            {
              children
            }
          </div>
          {
            foot && (
              <div className="cec-smartx-inner-foot">
                {
                  foot
                }
              </div>
            )
          }
        </Layout>
      </Popup>
    )
  }
}