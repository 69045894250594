import React, { Fragment, createRef } from "react";
import { List, Button, TextArea, Input, Toast } from "antd-mobile";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import CECSelectIndustryPopup from "../../../../../components/SelectIndustryPopup";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import _ from 'lodash';
import {
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_OTHER_VALUE,
  ConvertIndustryToLabels,
  FetchElementList,
  FetchElementLevelList,
  SaveInfo,
  DeleteInfo,
} from './transform'


/**
 * 投资方向更新
 */
export default class InvestmentSectorPopup extends BaseExtractValuePopup {

  constructor(props) {
    super(props)

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })

    this.state = {
      _info: info,

      _orgIdentityOptions: [],
      _investmentTargetAttributesOptions: [],
      _participateSTradeOptions: [],
      _ecIndustryOptions: [],
      _currentStatusOptions: [],
      _specialConditionOptions: [],
    }
    this.ecIndustrySelectPopupRef = createRef()
  }
  componentDidMount() {
    this.fetchOrgIdentitysList()
    this.fetchInvestmentTargetAttributesList()
    this.fetchParticipateSTradeList()
    this.fetchIndustryList()
    this.fetchAreaList()
    this.fetchCurrentStatusList()
    this.fetchSpecialConditionList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  // 机构身份
  async fetchOrgIdentitysList() {
    try {
      const options = await FetchElementList("Lable_smartx_gp_identity")
      this.setState({ _orgIdentityOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 投资标的属性
  async fetchInvestmentTargetAttributesList() {
    try {
      const options = await FetchElementList("Lable_smartx_gp_asset_attribute")
      this.setState({ _investmentTargetAttributesOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 参与S交易
  async fetchParticipateSTradeList() {
    try {
      const options = await FetchElementList("Lable_smartx_gp_strade")
      this.setState({ _participateSTradeOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 产业和赛道
  async fetchIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 地理市场
  async fetchAreaList() {
    try {
      const options = await FetchElementList("Lable_smartx_location")
      this.setState({ _areaOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 当前状态
  async fetchCurrentStatusList() {
    try {
      const options = await FetchElementList("Lable_smartx_now_attitude")
      this.setState({ _currentStatusOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 特殊要求
  async fetchSpecialConditionList() {
    try {
      const options = await FetchElementList("Lable_smartx_gp_special_request")
      this.setState({ _specialConditionOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _orgIdentityOptions,
      _investmentTargetAttributesOptions,
      _participateSTradeOptions,
      _areaOptions,
      _ecIndustryOptions,
      _currentStatusOptions,
      _specialConditionOptions
    } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="机构身份"
            columns={2}
            multiple={true}
            options={_orgIdentityOptions}
            value={_info['orgIdentitys'] ? _.map(_info['orgIdentitys'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'orgIdentitys', options)}
          />

          <CECSelectorListItem label="投资标的属性"
            multiple={true}
            options={_investmentTargetAttributesOptions}
            value={_info['investmentTargetAttributes'] ? _.map(_info['investmentTargetAttributes'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'investmentTargetAttributes', options)}
          />

          <CECSelectorListItem label="是否能参与S交易"
            multiple={true}
            options={_participateSTradeOptions}
            value={_info['participateSTrade'] ? _.map(_info['participateSTrade'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'participateSTrade', options)}
          />

          <List.Item extra={_info['industry'] ? ConvertIndustryToLabels(_info['industry']) : "请选择"} onClick={() => this.ecIndustrySelectPopupRef?.current?.toggle()}>产业和赛道</List.Item>

          <CECSelectorListItem label="地理市场"
            multiple={true}
            options={_areaOptions}
            value={_info['areas'] ? _.map(_info['areas'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'areas', options)}
          />

          <CECSelectorListItem label="币种"
            multiple={true}
            options={CURRENCY_OPTIONS}
            value={_info['currencys'] ? _.map(_info['currencys'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'currencys', options)}
          >
            {_info['currencys'] && _.map(_info['currencys'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['currencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'currencyOther')} />}
          </CECSelectorListItem>

          <CECSelectorListItem label="当前状态"
            multiple={false}
            options={_currentStatusOptions}
            value={_info['currentStatus'] ? _.map(_info['currentStatus'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'currentStatus', options)}
          />

          <CECSelectorListItem label="特殊要求"
            columns={2}
            isRequired={false}
            multiple={true}
            options={_specialConditionOptions}
            value={_info['specialConditions'] ? _.map(_info['specialConditions'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'specialConditions', options)}
          />
          {
            _info['specialConditions'] && _.map(_info['specialConditions'], 'label').includes('其他') && (
              <List.Item>
                <TextArea placeholder="请填写其他特殊条件" value={_info['specialConditionText']} onChange={this.handleChange.bind(this, 'change_value', 'specialConditionText')} />
              </List.Item>
            )
          }
        </List>

        {/** 产业和赛道 */}
        <CECSelectIndustryPopup
          ref={this.ecIndustrySelectPopupRef}
          title="产业和赛道"
          multiple={true}
          options={_ecIndustryOptions}
          values={_info['industry']}
          onConfirm={options => this.handleChange('change_value', 'industry', options)}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}