import _ from "lodash";
import moment from "moment";
import request from "../../../../../../api/HttpRequest";

// FA
export const FA_SUPPORT_OPTIONS_TRUE = "true"
export const FA_SUPPORT_OPTIONS = [
  { value: FA_SUPPORT_OPTIONS_TRUE, label: '是' },
  { value: 'false', label: '否' }
]
// 其他需求
export const OTHER_RELATION_OPTIONS_TRUE = "true"
export const OTHER_RELATION_OPTIONS = [
  { value: 'true', label: '是' },
  { value: 'false', label: '否' }
]
// 收入
export const INCOME_OPTIONS = [
  { value: "true", label: '是' },
  { value: 'false', label: '否' }
]
// 利润
export const PROFIT_OPTIONS = [
  { value: "true", label: '是' },
  { value: 'false', label: '否' }
]

/**
 * 转换行业为Label
 * @param {*} datas 
 * @returns 
 */
export const ConvertIndustryToLabels = (datas) => {
  let selectedIndustrys = ""
  if (datas) {
    selectedIndustrys = _.join(_.map(datas, item => {
      let industry = item['label']
      let childrenLabel = ""
      if (item['children'] && _.size(item['children']) > 0) {
        childrenLabel = _.join(_.map(item['children'], "label"), "、")
      }
      if (childrenLabel) {
        industry += `（${childrenLabel}）`
      }
      return industry
    }), "，")
  }
  return selectedIndustrys
}

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 保存是否有并购整合
 * @param {*} info 
 */
export async function SaveInfo(info) {

  let industryList = []
  if (info['industry']) {
    info['industry'].find(item => {
      industryList.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })

      if (item['children'] && _.size(item['children']) > 0) {
        item['children'].find(childrenItem => {
          industryList.push({ sys_labelelement_code: childrenItem['value'], sys_labelelement_type: childrenItem['type'] })
        })
      }
    })
  }
  let importantList = []
  if (info['important']) {
    info['important'].find(item => {
      importantList.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }
  let marketAreaList = []
  if (info['marketArea']) {
    info['marketArea'].find(item => {
      marketAreaList.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let array = []
  array = _.concat(array, industryList, importantList, marketAreaList)

  let faList = []
  if (info['FA']) {
    info['FA'].find(item => {
      faList.push({ base_investment_code: item['value'], base_investment_name: item['label'] })
    })
  }
  let payload = {}
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/smartx/comp/trade/chance/save"
    payload = {
      id: info['id'],
      base_comp_code: info['code'],
      type: 'merge',
      label_datas: JSON.stringify(array),
      // 收入要求
      incomerequireflag: info['income'] ? _.join(_.map(info['income'], "value")) : null,
      // 利润要求
      netrequireflag: info['profit'] ? _.join(_.map(info['profit'], "value")) : null,
      tradepercent: info['ratio'],
      tradedate: info['beginDate'] ? moment(info['beginDate']).toDate().toString() : null,
      comstate: info['currentStatus'] ? _.join(_.map(info['currentStatus'], "value")) : null,
      faflag: info['FA'] ? _.join(_.map(info['FA'], "value")) : null,
      investment_datas: JSON.stringify(faList),
      note: info['otherText'],
      showflag: info['id'] ? true : false,
    }
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/save"
    payload = {
      id: info['id'],
      base_investment_code: info['code'],
      type: 'merge',
      label_datas: JSON.stringify(array),
      // 收入要求
      incomerequireflag: info['income'] ? _.join(_.map(info['income'], "value")) : null,
      // 利润要求
      netrequireflag: info['profit'] ? _.join(_.map(info['profit'], "value")) : null,
      tradepercent: info['ratio'],
      tradedate: info['beginDate'] ? moment(info['beginDate']).toDate().toString() : null,
      comstate: info['currentStatus'] ? _.join(_.map(info['currentStatus'], "value")) : null,
      faflag: info['FA'] ? _.join(_.map(info['FA'], "value")) : null,
      investment_datas: JSON.stringify(faList),
      note: info['otherText'],
      showflag: info['id'] ? true : false,
    }
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/trade/family/chance/save"
    payload = {
      id: info['id'],
      base_lp_code: info['code'],
      type: 'merge',
      label_datas: JSON.stringify(array),
      // 收入要求
      incomerequireflag: info['income'] ? _.join(_.map(info['income'], "value")) : null,
      // 利润要求
      netrequireflag: info['profit'] ? _.join(_.map(info['profit'], "value")) : null,
      tradepercent: info['ratio'],
      tradedate: info['beginDate'] ? moment(info['beginDate']).toDate().toString() : null,
      comstate: info['currentStatus'] ? _.join(_.map(info['currentStatus'], "value")) : null,
      faflag: info['FA'] ? _.join(_.map(info['FA'], "value")) : null,
      investment_datas: JSON.stringify(faList),
      note: info['otherText'],
      showflag: info['id'] ? true : false,
    }
  }
  try {
    const response = await request("POST", url, payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}
/**
 * 删除是否有并购整合
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/smartx/comp/trade/chance/delete"
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/delete"
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/trade/family/chance/delete"
  }
  try {
    await request("POST", url, { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}