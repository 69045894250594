import _ from "lodash";
import moment from "moment";
import request from "../../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../../components/AmountNumber";

//
export const IS_FUND_OPTIONS = [
  { value: 'true', label: '是' },
  { value: 'false', label: '否' },
]

// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 保存是否有募资需求
 * @param {*} info 
 */
export async function SaveInfo(info) {

  let fundPropsList = []
  if (info['fundProps']) {
    info['fundProps'].find(item => {
      fundPropsList.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let moneyCurrency = info['moneyCurrencyOther']
  if (!moneyCurrency) {
    moneyCurrency = info['moneyCurrency'] ? _.join(_.map(info['moneyCurrency'], 'value')) : null
  }
  let moneyUnitValue = info['moneyUnit'] ? _.join(_.map(info['moneyUnit'], 'value')) : null
  let moneyAmount = parseAmountNumber(info['moneyAmount'], moneyUnitValue)

  let payload = {}
  let url = "/cec-sims-server/smartx/comp/trade/chance/save"
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = ""
    payload = {
      id: info['id'],
      base_comp_code: info['code'],
      type: 'fundraising',
      indusfundflag: info['isFund'] ? _.join(_.map(info['isFund'], 'value')) : null,
      tradevalue2: info['fundScale'] ? _.join(_.map(info['fundScale'], 'label')) : null,
      tradedate: info['completeDate'] ? info['completeDate'].toString() : null,
      tradecurrency: moneyCurrency,
      tradeunit: moneyUnitValue,
      tradevalue: moneyAmount,
      label_datas: JSON.stringify(fundPropsList),
      showflag: info['id'] ? true : false,
    }
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/save"
    payload = {
      id: info['id'],
      base_investment_code: info['code'],
      type: 'fundraising',
      indusfundflag: info['isFund'] ? _.join(_.map(info['isFund'], 'value')) : null,
      tradevalue2: info['fundScale'] ? _.join(_.map(info['fundScale'], 'label')) : null,
      tradedate: info['completeDate'] ? info['completeDate'].toString() : null,
      tradecurrency: moneyCurrency,
      tradeunit: moneyUnitValue,
      tradevalue: moneyAmount,
      label_datas: JSON.stringify(fundPropsList),
      showflag: info['id'] ? true : false,
    }
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/investment/trade/chance/save"
    payload = {
      id: info['id'],
      base_lp_code: info['code'],
      type: 'fundraising',
      indusfundflag: info['isFund'] ? _.join(_.map(info['isFund'], 'value')) : null,
      tradevalue2: info['fundScale'] ? _.join(_.map(info['fundScale'], 'label')) : null,
      tradedate: info['completeDate'] ? info['completeDate'].toString() : null,
      tradecurrency: moneyCurrency,
      tradeunit: moneyUnitValue,
      tradevalue: moneyAmount,
      label_datas: JSON.stringify(fundPropsList),
      showflag: info['id'] ? true : false,
    }
  }
  try {
    const response = await request("POST", url, payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}
/**
 * 删除是否有募资需求
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/smartx/comp/trade/chance/delete"
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/delete"
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/trade/family/chance/delete"
  }
  try {
    await request("POST", url, { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}