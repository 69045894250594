import React, { Fragment, useEffect, useImperativeHandle, useCallback, useRef, useState } from "react"
import _ from "lodash"
import { List, Skeleton, Toast, ErrorBlock } from "antd-mobile"
import { EditSFill } from "antd-mobile-icons"
import CECInfiniteScroll from "../../../../../../../components/InfiniteScroll"
import CECPopup from "../../../../../../../components/Popup"
import FinancialDataPopup from "../../../../../UpdateEntry/components/FinancialDataPopup"
import {
  FetchFinancialDataList,
  FetchFinancialDataDetail,
} from './transform'
import './index.less'

/**
 * 财务数据列表
 * @returns 
 */
export default function FinancialDataListView({ code, targetExtensionValue }) {

  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [item, setItem] = useState({})
  const dataDetailPopupRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      await fetchDatas(code, 1);
    };
    code && fetchData();
  }, [code]);

  // 获取列表
  const fetchDatas = async (code, currentPageNum) => {
    setLoading(true)
    try {
      const { datas: newDatas, hasMore, total } = await FetchFinancialDataList({ code, pageNum: currentPageNum, pageSize })
      setLoading(false)
      setHasMore(hasMore)
      setPageNum(currentPageNum + 1);
      if (currentPageNum === 1) {
        setDatas(newDatas)
      } else {
        setDatas(prevDatas => [...prevDatas, ...newDatas]);
      }
    } catch (error) {
      Toast.show({ content: error })
      setLoading(false)
      setHasMore(false);
    }
  }

  const handleLoadMore = useCallback(async () => {
    code && await fetchDatas(code, pageNum);
  }, [code, pageNum])

  const handleClick = (key, data, event) => {
    console.log("handleClick >>>>", key, data, event)

    switch (key) {
      case 'handle_open_item_detail_popup':
        setItem(data)
        dataDetailPopupRef?.current?.show()
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <List>
        {
          loading && _.size(datas) == 0 && (
            <Fragment>
              <Skeleton.Title animated />
              <Skeleton.Paragraph lineCount={4} animated />
            </Fragment>
          )
        }
        {
          !loading && _.size(datas) == 0 && (
            <Fragment>
              <ErrorBlock status='empty' title="" description="暂无数据" />
            </Fragment>
          )
        }
        {
          datas.map(item => (
            <List.Item onClick={() => handleClick('handle_open_item_detail_popup', item)}>
              <div>
                <div>是否有收入：{_.join(_.map(item['income'], "label"))}</div>
                <div>是否有正向EBITDA：{_.join(_.map(item['ebitda'], "label"))}</div>
                <div>是否有利润：{_.join(_.map(item['profit'], "label"))}</div>
                <div>是否有正现金流：{_.join(_.map(item['cashflow'], "label"))}</div>
              </div>
            </List.Item>
          ))
        }
        <CECInfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} />
      </List>

      {/** 详情Popup */}
      <FinancialDataDetailPopup ref={dataDetailPopupRef} item={item} targetExtensionValue={targetExtensionValue} />
    </Fragment>
  )
}

/**
 * 财务数据详情
 */
export const FinancialDataDetailPopup = React.forwardRef(({ item, targetExtensionValue }, ref) => {

  const [info, setInfo] = useState({})
  const basePopupRef = useRef(null)
  const editDataPopupRef = useRef(null)

  useImperativeHandle(ref, () => ({
    show: () => {
      basePopupRef?.current?.show()
    },
    close: () => {
      basePopupRef?.current?.close()
    },
    toggle: () => {
      basePopupRef?.current?.toggle()
    },
  }))

  useEffect(() => {
    const fetchData = async () => {
      await fetchDetail(item['id'])
    }
    item && fetchData()
  }, [item])

  const fetchDetail = async (id) => {
    try {
      const response = await FetchFinancialDataDetail(id)
      setInfo(response)
      Toast.clear()
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  const renderBodyContent = () => {
    return (
      <Fragment>
        {
          info && (
            <div className="financial-data-detail-box">
              <div className="financial-data-detail-item">
                <span className="detail-item-key">是否有收入：</span>
                <span>{_.join(_.map(item['income'], "label"))}</span>
              </div>
              <div className="financial-data-detail-item">
                <span className="detail-item-key">具体财务数据：</span>
                <span>

                </span>
              </div>
              <div className="split-line"></div>

              <div className="financial-data-detail-item">
                <span className="detail-item-key">是否有正向EBITDA：</span>
                <span>{_.join(_.map(item['ebitda'], "label"))}</span>
              </div>
              <div className="financial-data-detail-item">
                <span className="detail-item-key">具体财务数据：</span>
                <span>

                </span>
              </div>
              <div className="split-line"></div>

              <div className="financial-data-detail-item">
                <span className="detail-item-key">是否有利润：</span>
                <span>{_.join(_.map(item['profit'], "label"))}</span>
              </div>
              <div className="financial-data-detail-item">
                <span className="detail-item-key">具体财务数据：</span>
                <span>

                </span>
              </div>
              <div className="split-line"></div>

              <div className="financial-data-detail-item">
                <span className="detail-item-key">是否有正现金流：</span>
                <span>{_.join(_.map(item['cashflow'], "label"))}</span>
              </div>
              <div className="financial-data-detail-item">
                <span className="detail-item-key">具体财务数据：</span>
                <span>

                </span>
              </div>
              <div className="split-line"></div>
            </div>
          )
        }

        {/** 财务数据编辑页面Popup */}
        <FinancialDataPopup ref={editDataPopupRef}
          metadata={{
            title: '编辑财务数据',
            targetRefKey: "info_option_list_attend_target",
          }}
          value={{
            value: { ...info }
          }}
          extensionValue={{ "info_option_list_attend_target": targetExtensionValue }}
        />
      </Fragment>
    )
  }

  return (
    <CECPopup ref={basePopupRef}
      overflow={false}
      navTitle="财务数据详情"
      navRight={<div onClick={() => editDataPopupRef?.current?.show()}><EditSFill style={{ color: '#1890FF', fontSize: 18 }} /></div>}
      destroyOnClose={false}
      onNavBack={() => ref?.current?.close()}>
      {
        renderBodyContent()
      }
    </CECPopup>
  )
})