import React, { PureComponent } from "react";
import { Avatar, Popover } from "antd-mobile";
import { LeftOutline } from 'antd-mobile-icons';
import Layout from '../../../components/Layout';
import { register } from '../../../api/wecom';
import { requestUrlConfig, getProfile } from '../../../api/HttpRequest';
import HomeBackGroundImg from '../../../assets/mobile/home/sx_01.jpg'
import UpdateImg from '../../../assets/mobile/home/sx_02.jpg'
import QueryImg from '../../../assets/mobile/home/sx_03.jpg'
import DownloadImg from '../../../assets/mobile/home/sx_04.jpg'
import './index.css';

export default class Home extends PureComponent {

  constructor(props) {
    super(props);

    let requestParam = null
    if (props.match && props.match.params) {
      requestParam = props.match.params.data ? JSON.parse(decodeURIComponent(props.match.params.data)) : null
    }

    const profile = getProfile();
    this.state = {
      _profile: { ...profile },
      _requestBody: requestParam,
    }
    if (requestUrlConfig['requiredAuth'] && !profile) {
      window.location.href = `${requestUrlConfig['hostSSO']}/#/agentcallback/${requestUrlConfig['oAuthAgentId']}/${requestUrlConfig['hostSmartX']}/#/auth/&${window.location.href}`;
    }
  }

  componentDidMount() {
    register()
  }

  render() {
    const {
      _profile,
      _requestBody,
    } = this.state

    return (
      <Layout>
        <Welcome className="welcome" style={{ marginBottom: '10px' }} profile={_profile} navLeft={_requestBody}></Welcome>
      </Layout>
    )
  }
}

function Welcome({ profile, navLeft }) {
  console.log("navLeft >>>", navLeft)
  return (
    <div style={{ width: '100%', height: '100%', background: '#fff' }}>
      <div style={{ height: 30, lineHeight: '30px', padding: '10px', background: '#3977c3', boxShadow: '0px 5px 10px 5px #3977c3', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, display: 'flex' }}>
          {navLeft && <div style={{ marginRight: 6, color: '#fff', fontSize: 20, fontWeight: '500' }} onClick={() => window.location.href = `${navLeft['from']}`}><LeftOutline /></div>}
          <Popover trigger="click" mode="dark" content={<span>{profile && profile['userName'] ? `${profile['userName']}` : '未登录'}</span>}>
            <Avatar src={profile && profile['avatar'] ? profile['avatar'] : null} style={{ '--size': '32px', '--border-radius': '20px' }} />
          </Popover>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <img style={{ width: '100%', objectFit: 'fill' }} src={HomeBackGroundImg} />
        <div>
          <div style={{ width: '100%' }} onClick={() => window.location.href = `/#/mobile/entry/update`}><img style={{ width: '100%', userSelect: 'none' }} src={UpdateImg} /></div>
          <div style={{ width: '100%' }} onClick={() => window.location.href = `/#/mobile/entry/query`}><img style={{ width: '100%', userSelect: 'none' }} src={QueryImg} /></div>
          <div style={{ width: '100%' }} onClick={() => window.location.href = `/#/mobile/entry/download`}><img style={{ width: '100%', userSelect: 'none' }} src={DownloadImg} /></div>
        </div>
      </div>
    </div>
  )
}