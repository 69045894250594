import _ from "lodash"
import request from "../../../../api/HttpRequest"

/**
 * 保存关键信息（显示）
 * @param {*} data 
 */
export async function SaveInfo(data) {
  return UpdateInfo('SHOW', data)
}

/**
 * 保存关键信息（删除）
 * @param {*} data 
 */
export async function DeleteInfo(data) {
  return UpdateInfo('DELETE', data)
}

/**
 * 更新关键信息状态（显示/删除）
 * @param {*} status: SHOW / DELETE
 * @param {*} data 
 */
export async function UpdateInfo(status, data) {

  const newData = convertObjects(data)
  console.log("newData ==>>>", newData)

  if (newData && _.size(newData) > 0) {
    const payload = {
      state: status,
      data_ids: JSON.stringify(newData),
    }
    try {
      await request('POST', '/cec-sims-server/smartx/comp/base/updateflag', payload)
      return { ...data }
    } catch (error) {
      throw error
    }
  }
}

function convertObjects(data) {

  let objects = []
  if (data) {
    _.map(_.keys(data), key => {
      if (key.indexOf('info_option_list_update_content') > -1) {
        objects = _.map(data[key], item => {
          const metadataItem = item['metadata']
          const valueItem = item['value']

          return {
            type: metadataItem['dbTypeKey'],
            id: valueItem['id'],
          }
        })
      }
    })
  }

  return objects
}

// function convertObjects(data) {
//   if (data) {
//     _.map(_.keys(data), key => {
//       if (key.indexOf('info_option_list_update_content') > -1) {
//         if (data[key]) {
//           data[key] = _.map(data[key], item => {
//             delete item.metadata?.ref
//             delete item.metadata?.componentRender
//             if (item.metadata?.parentPathItems) {
//               item.metadata.parentPathItems = _.map(item.metadata.parentPathItems, path => ({ label: path['label'], value: path['value'], key: path['value'] }))
//             }
//             return { ...item }
//           })
//         }
//       } else if (key.indexOf('info_option_list_attend_target') < 0) {
//         delete data[key]
//       }
//     })
//   }
//   return data
// }