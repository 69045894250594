import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../components/AmountNumber";

export const IS_FUND_OPTIONS = [
  { value: 'true', label: '是' },
  { value: 'false', label: '否' },
]

// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]


/**
 * 转换行业为Label
 * @param {*} datas 
 * @returns 
 */
export const ConvertIndustryToLabels = (datas) => {
  let selectedIndustrys = ""
  if (datas) {
    selectedIndustrys = _.join(_.map(datas, item => {
      let industry = item['label']
      let childrenLabel = ""
      if (item['children'] && _.size(item['children']) > 0) {
        childrenLabel = _.join(_.map(item['children'], "label"), "、")
      }
      if (childrenLabel) {
        industry += `（${childrenLabel}）`
      }
      return industry
    }), "，")
  }
  return selectedIndustrys
}

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type']
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 保存 融资/收购历史更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  let industrys = []
  if (info['industry'] && _.size(info['industry']) > 0) {
    info['industry'].find(item => {
      industrys.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let newFundFirstScaleCurrency = info['newFundFirstScaleCurrencyOther']
  if (!newFundFirstScaleCurrency) {
    newFundFirstScaleCurrency = info['newFundFirstScaleCurrency'] ? _.join(_.map(info['newFundFirstScaleCurrency'], "value")) : null
  }
  let newFundFirstScaleUnit = info['newFundFirstScaleUnit'] ? _.join(_.map(info['newFundFirstScaleUnit'], "value")) : null
  let newFundFirstScaleValue = parseAmountNumber(info['newFundFirstScaleValue'], newFundFirstScaleUnit)


  let singleInvestmentAmountRangeCurrency = info['singleInvestmentAmountRangeCurrencyOther']
  if (!singleInvestmentAmountRangeCurrency) {
    singleInvestmentAmountRangeCurrency = info['singleInvestmentAmountRangeCurrency'] ? _.join(_.map(info['singleInvestmentAmountRangeCurrency'], "value")) : null
  }
  let singleInvestmentAmountRangeUnit = info['singleInvestmentAmountRangeUnit'] ? _.join(_.map(info['singleInvestmentAmountRangeUnit'], "value")) : null
  let singleInvestmentAmountRangeValue = parseAmountNumber(info['singleInvestmentAmountRangeValue'], singleInvestmentAmountRangeUnit)

  const payload = {
    id: info['id'],
    base_investment_code: info['code'],
    newflag: info['isFund'] ? _.join(_.map(info['isFund'], 'value')) : null,
    fund_date: info['date'] ? info['date'].toString() : null,
    fund_currency: newFundFirstScaleCurrency,
    fund_unit: newFundFirstScaleUnit,
    fund_money2: newFundFirstScaleValue,
    fund_money3: info['newFundFirstScale'] ? _.join(_.map(info['newFundFirstScale'], "label")) : null,
    single_currency: singleInvestmentAmountRangeCurrency,
    single_unit: singleInvestmentAmountRangeUnit,
    single_money: singleInvestmentAmountRangeValue,
    single_money2: info['singleInvestmentAmountRange'] ? _.join(_.map(info['singleInvestmentAmountRange'], "label")) : null,
    label_datas: JSON.stringify(industrys),
    showflag: info['id'] ? true : false,
    smartxflag: true,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/investment/base/prop/save", payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 融资/收购历史更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/smartx/investment/base/prop/delete", { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}