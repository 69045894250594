import request from '../../../../../api/HttpRequest'
import _ from 'lodash'

/**
 * 保存策略以及业务方向更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  const payload = {
    id: info['id'],
    base_comp_code: info['code'],
    stratageflag: info['newStrategicFocus'] ? _.join(_.map(info['newStrategicFocus'], 'value')) : null,
    stratagenote: info['newStrategicFocusText'],
    businessflag: info['newBussiness'] ? _.join(_.map(info['newBussiness'], 'value')) : null,
    businessnote: info['newBussinessText'],
    induschainflag: info['industryChainIntegrationPlan'] ? _.join(_.map(info['industryChainIntegrationPlan'], 'value')) : null,
    induschainnote: info['industryChainIntegrationPlanText'],
    globalflag: info['overseasExpansionPlan'] ? _.join(_.map(info['overseasExpansionPlan'], 'value')) : null,
    globalnote: info['overseasExpansionPlanText'],
    showflag: info['id'] ? true : false,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/business/save", payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除策略以及业务方向更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {

  try {
    await request("POST", "/cec-sims-server/smartx/comp/base/business/delete", { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}