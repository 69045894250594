import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../components/AmountNumber";

// 并购类
export const CATEGORY_MA_VALUES = ['Lable-trastage-018', 'Lable-trastage-034',]

// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 投资人列表
 * @param {*} params 
 */
export async function FetchInvestmentUserList({ searchKey, pageNum, pageSize }) {

  try {
    const response = await request("POST", "/cec-sims-server/investor/search", { input: searchKey ? searchKey : "", pageNum, pageSize })
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          label: item['investor_full_name'],
          value: item['base_investor_code'],
          titleExtension: (
            <span style={{ color: '#999', marginLeft: 5 }}>
              {
                item['base_investor_state'] == 'normal' ? "在职" : "离职"
              }
            </span>
          ),
          description: (
            <span style={{ color: '#999' }}>{item['base_investment_name'] ? item['base_investment_name'] : "未知"}</span>
          )
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }

}

/**
 * 保存 融资/收购历史更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  if (!info['round'] || _.size(info['round']) === 0) {
    throw '请选择交易轮次'
  }

  let isMA = false
  let roundValues = info['round'] ? _.join(_.map(info['round'], "value")) : null
  if (CATEGORY_MA_VALUES.includes(roundValues)) {
    isMA = true
    if (!info['buyerCompanys'] || _.size(info['buyerCompanys']) === 0) {
      throw '被收购方名称为空'
    }
  } else {
    isMA = false
    if (!info['company'] || _.size(info['company']) === 0) {
      throw '公司名称为空'
    }
  }

  let investments = []
  if (info['investments']) {
    info['investments'].find(item => {
      investments.push({ base_investment_code: item['value'] })
    })
  }

  let buyerCompanys = []
  if (info['buyerCompanys']) {
    info['buyerCompanys'].find(item => {
      buyerCompanys.push({ base_comp_code: item['value'] })
    })
  }

  let amountCurrency = info['amountCurrencyOther']
  if (!amountCurrency) {
    amountCurrency = info['amountCurrency'] ? _.join(_.map(info['amountCurrency'], "value")) : null
  }
  let amountUnit = info['amountUnit'] ? _.join(_.map(info['amountUnit'], "value")) : null
  let amountValue = parseAmountNumber(info['amountValue'], amountUnit)

  let postCurrency = info['postCurrencyOther']
  if (!postCurrency) {
    postCurrency = info['postCurrency'] ? _.join(_.map(info['postCurrency'], "value")) : null
  }
  let postUnit = info['postUnit'] ? _.join(_.map(info['postUnit'], "value")) : null
  let postValue = parseAmountNumber(info['postValue'], postUnit)

  let payload = {}
  if (isMA) {
    payload = {
      base_trade_code: info['id'],
      base_trade_stage: roundValues,
      base_comp_code: info['code'],
      base_trade_date: info['tradeDate'] ? info['tradeDate'].toString() : null,
      // 被收购方
      investment_datas: JSON.stringify(buyerCompanys),
      // 并购金额/币种/单位
      invse_detail_money: amountValue,
      invse_currency_name: amountCurrency,
      invse_detail_unit: amountUnit,
      showflag: info['id'] ? true : false,
    }
  } else {
    payload = {
      base_trade_code: info['id'],
      base_trade_stage: roundValues,
      base_comp_code: info['code'],
      base_trade_date: info['tradeDate'] ? info['tradeDate'].toString() : null,
      // 机构/投资人
      investment_datas: JSON.stringify(investments),
      base_investor_codes: info['investors'] ? _.join(_.map(info['investors'], "value")) : null,
      // 融资金额/币种/单位
      invse_detail_money: amountValue,
      invse_currency_name: amountCurrency,
      invse_detail_unit: amountUnit,
      // 股权比例
      invse_stock_ownership: info['equityRatio'],
      // 投后估值金额/币种/单位
      invse_guess_particulars: postValue,
      invse_guess_currency_name: postCurrency,
      invse_guess_unit: postUnit,
      showflag: info['id'] ? true : false,
    }
  }

  try {
    const response = await request("POST", "/cec-sims-server/trade/save", payload)
    return { ...info, id: response['base_trade_code'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 融资/收购历史更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/trade/delete", { base_trade_code: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}