import React, { Fragment, createRef } from "react";
import _ from "lodash";
import { Toast } from "antd-mobile";
import CECSelectPopup from "../../../../../components/SelectPopup";
import TargetFormEntryMenu from "../../../../../components/TargetFormEntryMenu"
import { FetchTargetList } from "../../transform";

/**
 * 【会议更新：与会方】
 */
export default class AttendTargetPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      _mode: props.mode ? props.mode : 'default', // target：只选择对象，default：选择对象和类型
      _targetCategorys: props.targetCategorys ? props.targetCategorys : ['company', 'investment', 'lp'],
      _targetMultiple: props.targetMultiple ? props.targetMultiple : false,
      _targetOptions: [],
      _targetTypeOptions: props.targetTypeOptions ? props.targetTypeOptions : [],
      _targets: _.get(props, "value.targets", []),
      _targetTypes: _.get(props, "value.targetTypes", []),
    }

    this.attendTargetPopupRef = createRef()
    this.attendTargetTypePopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _mode: props.mode ? props.mode : 'default', // target：只选择对象，default：选择对象和类型
      _targetCategorys: props.targetCategorys ? props.targetCategorys : ['company', 'investment', 'lp'],
      _targetMultiple: props.targetMultiple ? props.targetMultiple : false,
      _targetTypeOptions: props.targetTypeOptions ? props.targetTypeOptions : [],
      _targets: _.get(props, "value.targets", []),
      _targetTypes: _.get(props, "value.targetTypes", []),
    })
  }

  show() {
    this.attendTargetPopupRef.current.show()
  }
  close() {
    this.attendTargetPopupRef.current.close()
  }
  toggle() {
    this.attendTargetPopupRef.current.toggle()
  }

  handleClick(key, data, event) {
    // console.log("handleClick >>>>", key, data, event)
    const { onConfirm, } = this.props
    const { _targets } = this.state

    switch (key) {
      case 'handle_open_update_target_type_popup':
        this.setState({ _targets: data }, () => {
          this.attendTargetPopupRef?.current?.close()
          this.attendTargetTypePopupRef?.current?.toggle()
        })
        break;
      case 'handle_confirm_update_target_type_popup':
        this.setState({ _targets: data }, () => this.attendTargetPopupRef?.current?.close())
        onConfirm && onConfirm({ targets: data })
        break;
      case 'handle_confirm_update_target_type_value':
        this.setState({ _targetTypes: data }, () => this.attendTargetTypePopupRef?.current?.close())
        onConfirm && onConfirm({ targets: _targets, targetTypes: data })
        break;
      case 'handle_back_update_target_popup':
        this.attendTargetTypePopupRef?.current?.toggle()
        this.attendTargetPopupRef?.current?.toggle()
        break;
      case 'handle_clear_target_info':
        onConfirm && onConfirm()
        this.attendTargetTypePopupRef?.current?.close()
        this.attendTargetPopupRef?.current?.close()
        break;
      default:
        break;
    }
  }

  fetchTargetList = async (params = { pageNum: 1, pageSize: 50 }) => {
    try {
      const response = await FetchTargetList(params)
      if (response) {
        return response
      }
    } catch (error) {
      Toast.show({ content: error });
      throw error;
    }
  }

  render() {
    const {
      _mode,
      _targetMultiple,
      _targetTypeOptions,
      _targets,
      _targetTypes,
      _targetCategorys,
    } = this.state
    const {
      metadata,
    } = this.props

    return (
      <Fragment>
        {/** 更新对象/与会方 */}
        <CECSelectPopup ref={this.attendTargetPopupRef}
          title={metadata['title']}
          supportSearch={true}
          supportAvatar={true}
          supportNotFound={() => (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <TargetFormEntryMenu mode="none" onConfirm={(data) => this.handleClick('handle_open_update_target_type_popup', [data])} />
            </div>
          )}
          navRight={<div style={{ color: '#1890FF' }} onClick={this.handleClick.bind(this, 'handle_clear_target_info')}>清空</div>}
          multiple={_targetMultiple}
          values={_targets}
          onFetchDatas={(params) => this.fetchTargetList({ ...params, types: _targetCategorys })}
          onConfirm={_mode === 'default' ? this.handleClick.bind(this, 'handle_open_update_target_type_popup') : this.handleClick.bind(this, 'handle_confirm_update_target_type_popup')}
        />

        {/** 更新对象身份/与会方类型 */}
        <CECSelectPopup ref={this.attendTargetTypePopupRef}
          title={metadata['key'] === 'info_option_list_attend_target' ? '更新对象身份' : '与会方身份'}
          multiple={false}
          options={_targetTypeOptions}
          values={_targetTypes}
          onNavBack={this.handleClick.bind(this, 'handle_back_update_target_popup')}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_update_target_type_value')}
        />
      </Fragment>
    )
  }
}