import React, { createRef, Fragment, PureComponent, useCallback, useRef, useState, useEffect } from "react";
import _ from "lodash";
import { Toast, Button, Popover, Modal, Space } from "antd-mobile";
import { AddOutline } from 'antd-mobile-icons';
import CECTargetFormPopup from "../TargetFormPopup";
import {
  SwapRelationCode,
  SaveTargetInfo,
} from "./transform";
import './index.less';

/**
 * 创建企业（公司/机构/LP）【Popover】
 * @param {*} param
 * @returns 
 */
export default function TargetFormEntryMenu({ className, mode, enterpriseCode, enterpriseType, disabledValues = [], children, onConfirm, onRefresh }) {

  const [menus, setMenus] = useState([])
  const [visible, setVisible] = useState(false)
  const [targetCategory, setTargetCategory] = useState(null)
  const [targetCategoryLabel, setTargetCategoryLabel] = useState(null)
  const [categoryTitleMap, setCategoryTitleMap] = useState({})
  const createTargetFormRef = useRef(null)
  const modalRef = useRef(null);

  useEffect(() => {
    const fetchExistInfo = async () => {
      const { companyCode, gpCode, lpCode } = await SwapRelationCode({ code: enterpriseCode, type: enterpriseType })

      let categoryTitleMap = {}
      if (companyCode) {
        categoryTitleMap = {
          ...categoryTitleMap,
          company: `编辑企业（产业方）`
        }
      } else {
        categoryTitleMap = {
          ...categoryTitleMap,
          company: `新建企业（产业方）`
        }
      }
      if (gpCode) {
        categoryTitleMap = {
          ...categoryTitleMap,
          gp: `编辑市场化机构`
        }
      } else {
        categoryTitleMap = {
          ...categoryTitleMap,
          gp: `新建市场化机构`
        }
      }
      if (lpCode) {
        categoryTitleMap = {
          ...categoryTitleMap,
          lp: `编辑政府/家办及个人`
        }
      } else {
        categoryTitleMap = {
          ...categoryTitleMap,
          lp: `新建政府/家办及个人`
        }
      }
      setCategoryTitleMap(categoryTitleMap)
      return categoryTitleMap
    }

    if (enterpriseCode && enterpriseType) {
      fetchExistInfo()
    }
  }, [enterpriseCode, enterpriseType])

  useEffect(() => {
    if (targetCategory) {
      createTargetFormRef?.current?.show();
    }
  }, [targetCategory]);

  // 点击组件以外区域关闭
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  useEffect(() => {
    setMenus([
      { text: '企业（产业方）', value: 'company', disabled: _.includes(disabledValues, 'company'), onClick: () => handleClick('handle_open_create_target_popup', 'company', '企业（产业方）') },
      { text: '市场化机构', value: 'investment', disabled: _.includes(disabledValues, 'investment'), onClick: () => handleClick('handle_open_create_target_popup', 'investment', '市场化机构') },
      { text: '政府/家办及个人', value: 'lp', disabled: _.includes(disabledValues, 'lp'), onClick: () => handleClick('handle_open_create_target_popup', 'lp', '政府/家办及个人') },
    ])
  }, [disabledValues])

  const handleClick = async (key, data, event) => {
    // console.log('handleClick >>>>>', key, data, event)
    switch (key) {
      case 'handle_open_create_target_popup':
        setTargetCategory(data);
        setTargetCategoryLabel(event);
        setVisible(false);
        break;
      case 'handle_confirm_create_target_popup':
        await saveTargetInfo(data);
        onRefresh && onRefresh(targetCategory)
        break;
      default:
        break;
    }
  }

  const saveTargetInfo = useCallback(
    async (data) => {
      try {
        const targetInfo = await SaveTargetInfo(targetCategory, data);
        if (targetInfo) {
          Toast.show({ icon: 'success', content: '保存成功' })
          onConfirm && onConfirm(targetInfo)
          setTargetCategory(null)
          setTargetCategoryLabel(null)
          setVisible(false)
          createTargetFormRef?.current?.close()
        }
      } catch (error) {
        Toast.show({ icon: 'fail', content: error });
      }
    }, [targetCategory]
  )

  return (
    <div className={`cec-target-form-entry-menu-box ${className} `} style={{ display: 'inline-flex' }} ref={modalRef}>
      <Popover.Menu placement='bottom-start'
        getContainer={() => document.querySelector(`.cec-target-form-entry-menu-box.${className} `)}
        trigger="click"
        // destroyOnHide={false}
        visible={visible}
        actions={[
          { text: <span style={{ fontSize: 12 }}>请选择企业身份</span>, value: 'enterprise', disabled: true },
          ...menus
        ]}>
        <Fragment>
          {
            mode === 'default' && <Button color="primary" fill="outline" onClick={() => setVisible(!visible)}>新建企业</Button>
          }
          {
            mode === 'none' && <div style={{ color: '#1890FF', padding: 10 }} onClick={() => setVisible(!visible)}>新建企业</div>
          }
          {
            mode === 'addBtn' && <div style={{ display: 'inline-flex' }} onClick={() => setVisible(!visible)}><AddOutline style={{ color: '#1890FF', fontSize: 18 }} /></div>
          }
          {
            children && (
              <div onClick={() => setVisible(!visible)}>{children}</div>
            )
          }
        </Fragment>
      </Popover.Menu>

      {/** 新建对象 */}
      <CECTargetFormPopup
        ref={createTargetFormRef}
        metadata={{ title: categoryTitleMap[targetCategory] }}
        category={targetCategory}
        enterpriseCode={enterpriseCode}
        enterpriseType={enterpriseType}
        onConfirm={handleClick.bind(null, 'handle_confirm_create_target_popup')}
        onClose={() => {
          setTargetCategory(null)
          setTargetCategoryLabel(null)
          setVisible(false)
        }}
      />
    </div>
  )
}

/**
 * 创建企业（公司/机构/LP）【Modal】
 * @param {*} param
 * @returns 
 */
export class TargetFormEntryModal extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      menus: [
        { text: '企业（产业方）', value: 'company', onClick: () => this.handleClick('handle_open_create_target_popup', 'company', '企业（产业方）') },
        { text: '市场化机构', value: 'investment', onClick: () => this.handleClick('handle_open_create_target_popup', 'investment', '市场化机构') },
        { text: '政府/家办及个人', value: 'lp', onClick: () => this.handleClick('handle_open_create_target_popup', 'lp', '政府/家办及个人') },
      ],
      targetCategory: null,
      targetCategoryLabel: null,
    }

    this.createTargetFormRef = createRef()
    this.modal = null
  }

  show() {
    const { menus } = this.state
    this.modal = Modal.show({
      closeOnMaskClick: true,
      title: <span style={{ fontSize: 12 }}>请选择企业身份</span>,
      content: (
        <Space direction="vertical">
          {
            menus.map(item => <Button color="primary" fill="none" style={{ fontSize: 12 }} key={item['value']} disabled={item['disabled']} onClick={item['onClick']}>{item['text']}</Button>)
          }
        </Space>
      )
    })
  }
  close() {
    this.modal?.close()
    this.modal = null
  }
  toggle() {
    this.show()
  }

  async handleClick(key, data, event) {
    switch (key) {
      case 'handle_open_create_target_popup':
        this.setState({
          targetCategory: data,
          targetCategoryLabel: event,
        }, () => {
          this.modal?.close()
          this.createTargetFormRef?.current.toggle();
        })
        break;
      case 'handle_confirm_create_target_popup':
        await this.saveTargetInfo(data);
        break;
      default:
        break;
    }
  }

  async saveTargetInfo(data) {
    const {
      targetCategory,
    } = this.state
    const {
      onConfirm
    } = this.props
    try {
      const targetInfo = await SaveTargetInfo(targetCategory, data);
      if (targetInfo) {
        Toast.show({ icon: 'success', content: '保存成功' })
        onConfirm && onConfirm(targetInfo)
        this.modalRef?.current?.close()
        this.createTargetFormRef?.current?.close()
      }
    } catch (error) {
      Toast.show({ icon: 'fail', content: error });
    }
  }

  render() {
    const {
      menus,
      targetCategory,
      targetCategoryLabel,
    } = this.state

    return (
      <Fragment>
        {/** 新建对象 */}
        <CECTargetFormPopup
          ref={this.createTargetFormRef}
          metadata={{ title: `新建${targetCategoryLabel} ` }}
          category={targetCategory}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_create_target_popup')}
        />
      </Fragment>
    )
  }
}