import React, { Fragment, createRef, PureComponent } from "react";
import { Ellipsis, Input, List, TextArea, Toast, } from 'antd-mobile'
import _ from "lodash";
import moment from "moment";
import Required from "../../Required";
import CECCalendarPopup from "../../CalendarPopup";
import CECSelectorListItem from "../../SelectorListItem";
import CECSelectIndustryPopup from "../../SelectIndustryPopup";
import CECSelectPopup from "../../SelectPopup";
import {
  FetchElementList,
  FetchElementLevelList,
  FetchCompanyIndustryTagSearchList,
} from "../transform";

/**
 * 公司创建/修改页面
 */
export default class CompanyView extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _info: props.info ? props.info : {},

      _areaOptions: [],
      _typeOptions: [],
      _ecIndustryOptions: [],
      _tagOptions: [],
    }

    this.companyEstablishRef = createRef()
    this.tagsRef = createRef()
    this.cecIndustrySelectPopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _info: props.info ? props.info : {},
    })
  }

  componentDidMount() {
    try {
      this.fetchCompanyAreaList()
      this.fetchCompanyTypeList()
      this.fetchCompanyIndustryList()
      this.fetchCompanyTagList()
    } catch (error) {
    } finally {
    }
  }

  handleChange(key, data, event) {
    // console.log("handleChange >>>", key, data, event)
    const {
      _info,
    } = this.state
    const {
      onChange
    } = this.props

    switch (key) {
      case 'change_input_value':
      case 'change_value':
        const info = _.assign({}, _info, {
          [data]: event
        })
        onChange && onChange(info)
        break;
      default:
        break;
    }
  }

  async fetchCompanyAreaList() {
    try {
      const options = await FetchElementList("Lable_bground")
      const newOptions = _.filter(options, item => item['value'] !== 'Lable-bground-004')
      this.setState({ _areaOptions: [...newOptions] })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchCompanyTypeList() {
    try {
      const options = await FetchElementList("Label_comptype")
      this.setState({ _typeOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchCompanyIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchCompanyTagList() {
    try {
      const options = await FetchCompanyIndustryTagSearchList()
      this.setState({ _tagOptions: [...options] })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  render() {
    const {
      _info,
      _areaOptions,
      _typeOptions,
      _ecIndustryOptions,
      _tagOptions,
    } = this.state

    let selectedIndustrys = ""
    if (_info['ecIndustrys']) {
      selectedIndustrys = _.join(_.map(_info['ecIndustrys'], item => {
        let industry = item['label']
        let childrenLabel = ""
        if (item['children'] && _.size(item['children']) > 0) {
          childrenLabel = _.join(_.map(item['children'], "label"), "、")
        }
        if (childrenLabel) {
          industry += `（${childrenLabel}）`
        }
        return industry
      }), "，")
    }

    return (
      <Fragment>
        <List>
          <List.Item><Input placeholder="公司名称（必填）" style={{ '--text-align': 'left' }} value={_info['companyName']} onChange={this.handleChange.bind(this, 'change_input_value', 'companyName')} /></List.Item>
          <List.Item><Input placeholder="注册名称" style={{ '--text-align': 'left' }} value={_info['boardName']} onChange={this.handleChange.bind(this, 'change_input_value', 'boardName')} /></List.Item>
          <List.Item extra={_info['companyEstablish'] ? moment(_info['companyEstablish']).format("YYYY/MM") : "请选择"} onClick={() => this.companyEstablishRef.current.toggle()}>成立时间</List.Item>
          <CECSelectorListItem label="地区"
            isRequired={true}
            options={_areaOptions}
            value={_info['area'] ? _.map(_info['area'], "value") : []}
            onChange={(val, options) => this.handleChange('change_value', 'area', options)}
          />
          <CECSelectorListItem label="公司类型"
            multiple={true}
            isRequired={true}
            columns={2}
            options={_typeOptions}
            value={_info['types'] ? _.map(_info['types'], "value") : []}
            onChange={(val, options) => this.handleChange('change_value', 'types', options)}
          />
          <List.Item extra={<Ellipsis content={selectedIndustrys ? selectedIndustrys : "请选择"} />} onClick={() => this.cecIndustrySelectPopupRef.current.toggle()}>易凯行业<Required /></List.Item>
          <List.Item extra={<Input placeholder="请输入" value={_info['scale']} onChange={this.handleChange.bind(this, 'change_input_value', 'scale')} />}>公司规模（人）</List.Item>
          <List.Item extra={<Ellipsis content={_info['tags'] ? _.join(_.map(_info['tags'], "label")) : "请选择"} />} onClick={() => this.tagsRef.current.toggle()}>标签</List.Item>
          <List.Item>
            <TextArea rows={5} placeholder="公司简介" value={_info['description']} onChange={this.handleChange.bind(this, 'change_input_value', 'description')} />
          </List.Item>
        </List>

        {/** 日历 */}
        <CECCalendarPopup ref={this.companyEstablishRef}
          precision="month"
          value={moment().toDate()}
          metadata={{ title: "成立时间" }}
          onConfirm={this.handleChange.bind(this, 'change_value', 'companyEstablish')}
        />

        {/** 易凯行业 */}
        <CECSelectIndustryPopup
          ref={this.cecIndustrySelectPopupRef}
          title="易凯行业"
          multiple={true}
          options={_ecIndustryOptions}
          values={_info['ecIndustrys']}
          onConfirm={options => this.handleChange('change_value', 'ecIndustrys', options)}
        />

        {/** 标签 */}
        <CECSelectPopup ref={this.tagsRef}
          supportSearch={true}
          multiple={true}
          title="标签"
          options={_tagOptions}
          onConfirm={this.handleChange.bind(this, 'change_value', 'tags')}
        />
      </Fragment>
    )
  }
}