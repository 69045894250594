import React, { Fragment, PureComponent, createRef } from "react";
import { Ellipsis, Input, List, TextArea, Toast, } from 'antd-mobile'
import _ from "lodash";
import Required from "../../Required";
import CECSelectorListItem from "../../SelectorListItem";
import CECSelectIndustryPopup from "../../SelectIndustryPopup";
import CECSelectPopup from "../../SelectPopup";
import {
  FetchElementList,
  FetchElementLevelList,
  FetchECUserList,
  FetchActiveInvestmentUserList,
} from "../transform";


/**
 * 机构创建/修改页面 
 */
export default class GPView extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _info: props.info ? props.info : {},

      _currencyOptions: [],
      _areaOptions: [],//地区
      _area2Options: [],//地域
      _typeOptions: [],
      _stageOptions: [],
      _ecIndustryOptions: [],
      _ecUserOptions: [],
    }

    this.cecIndustrySelectPopupRef = createRef()
    this.cecEcapitalUserRef = createRef()
    this.activeInvestmentUserRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const info = props.info ? props.info : {}
    this.setState({
      _info: info,
    }, () => {
      if (info['area']) {
        this.afterChange('area', info)
      }
    })
  }

  componentDidMount() {
    try {
      this.fetchCurrencyList()
      this.fetchAreaList()
      this.fetchStageList()
      this.fetchIndustryList()
      this.fetchECUserList()
      this.fetchActiveInvestmnetUserList()
    } catch (error) {
    } finally {
    }
  }

  handleChange(key, data, event) {
    console.log("handleChange ===>", key, data, event)
    const {
      _info,
    } = this.state
    const {
      onChange
    } = this.props

    switch (key) {
      case 'change_input_value':
      case 'change_value':
        const info = _.assign({}, _info, {
          [data]: event
        })
        onChange && onChange(info)
        this.afterChange(data, info)
        break;
      default:
        break;
    }
  }
  afterChange(dataIndex, info) {
    if (dataIndex === 'area') {
      try {
        this.fetchTypeList()
        this.fetchArea2List(_.join(_.map(info['area'], "value")))
      } catch (error) { }
    }
  }
  async fetchCurrencyList() {
    try {
      const options = await FetchElementList("Lable_investment_currency")
      this.setState({ _currencyOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 地区
  async fetchAreaList() {
    try {
      let options = await FetchElementList("Lable_inv_bground")
      if (options) {
        options = _.filter(options, item => item['value'] !== 'Lable-inv-bground-004')
      }
      this.setState({ _areaOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 地域
  async fetchArea2List(areaCode) {
    try {
      const newAreaCode = _.replace(areaCode, /Lable-inv-bground-/g, "Lable_inv_bground")
      let options = await FetchElementLevelList(newAreaCode)
      this.setState({ _area2Options: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchStageList() {
    try {
      const options = await FetchElementList("Lable_investage2")
      this.setState({ _stageOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchTypeList() {
    const { _info } = this.state
    try {
      const options = await FetchElementList("Lable_type", _.join(_.map(_info['area'], "value")))
      this.setState({ _typeOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchECUserList() {
    try {
      const options = await FetchECUserList()
      this.setState({ _ecUserOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  render() {
    const {
      _info,
      _currencyOptions,
      _areaOptions,
      _area2Options,
      _typeOptions,
      _stageOptions,
      _ecUserOptions,
      _ecIndustryOptions,
    } = this.state

    let selectedIndustrys = ""
    if (_info['ecIndustrys']) {
      selectedIndustrys = _.join(_.map(_info['ecIndustrys'], item => {
        let industry = item['label']
        let childrenLabel = ""
        if (item['children'] && _.size(item['children']) > 0) {
          childrenLabel = _.join(_.map(item['children'], "label"), "、")
        }
        if (childrenLabel) {
          industry += `（${childrenLabel}）`
        }
        return industry
      }), "，")
    }

    return (
      <Fragment>
        <List>
          <List.Item><Input placeholder="机构名称（必填）" style={{ '--text-align': 'left' }} value={_info['orgName']} onChange={this.handleChange.bind(this, 'change_input_value', 'orgName')} /></List.Item>
          <CECSelectorListItem label="币种"
            isRequired={true}
            options={_currencyOptions}
            value={_info['currencys'] ? _.map(_info['currencys'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'currencys', options)}
          />
          <CECSelectorListItem label="地区"
            isRequired={true}
            options={_areaOptions}
            value={_info['area'] ? _.map(_info['area'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'area', options)}
          />
          {
            _info['area'] && ['Lable-inv-bground-001', 'Lable-inv-bground-003'].includes(_.join(_.map(_info['area'], "value"))) && (
              <CECSelectorListItem label="地域"
                isRequired={false}
                placeholder={<div style={{ color: '#999' }}>请先选择地区</div>}
                options={_area2Options}
                value={_info['area2'] ? _.map(_info['area2'], 'value') : []}
                onChange={(val, options) => this.handleChange('change_value', 'area2', options)}
              />
            )
          }
          <CECSelectorListItem label="类型"
            isRequired={true}
            multiple={true}
            columns={2}
            placeholder={<div style={{ color: '#999' }}>请先选择地区</div>}
            options={_typeOptions}
            value={_info['types'] ? _.map(_info['types'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'types', options)}
          />
          <CECSelectorListItem label="阶段"
            isRequired={false}
            options={_stageOptions}
            value={_info['stages'] ? _.map(_info['stages'], 'value') : []}
            onChange={(val, options) => this.handleChange('change_value', 'stages', options)}
          />
          <List.Item extra={<Ellipsis content={selectedIndustrys ? selectedIndustrys : "请选择"} />} onClick={() => this.cecIndustrySelectPopupRef.current.toggle()}>易凯行业<Required /></List.Item>

          {
            _info['code'] && (
              <Fragment>
                <List.Item extra={<Ellipsis content={_info['icInvestmentUsers'] && _.size(_info['icInvestmentUsers']) > 0 ? _.join(_.map(_info['icInvestmentUsers'], "label")) : "暂无"} />}>投委会成员</List.Item>
                <List.Item extra={<Ellipsis content={_info['activeInvestmentUsers'] && _.size(_info['activeInvestmentUsers']) > 0 ? _.join(_.map(_info['activeInvestmentUsers'], "label")) : "请选择"} />} onClick={() => this.activeInvestmentUserRef.current.toggle()}>活跃投资人</List.Item>
              </Fragment>
            )
          }
          <List.Item extra={<Ellipsis content={_info['ecapitalUsers'] && _.size(_info['ecapitalUsers']) > 0 ? _.join(_.map(_info['ecapitalUsers'], "label")) : "请选择"} />} onClick={() => this.cecEcapitalUserRef.current.toggle()}>易凯联系人</List.Item>

          <List.Item><Input placeholder="公司集团所在属地" style={{ '--text-align': 'left' }} value={_info['groupLocation']} onChange={this.handleChange.bind(this, 'change_input_value', 'groupLocation')} /></List.Item>
          <List.Item><Input placeholder="上市地" style={{ '--text-align': 'left' }} value={_info['listonLocation']} onChange={this.handleChange.bind(this, 'change_input_value', 'listonLocation')} /></List.Item>
          <List.Item>
            <TextArea placeholder="机构简介" rows={5} value={_info['description']} onChange={this.handleChange.bind(this, 'change_input_value', 'description')} />
          </List.Item>
        </List>

        {/** 正关注领域 */}
        <CECSelectIndustryPopup
          ref={this.cecIndustrySelectPopupRef}
          title="正关注领域"
          multiple={true}
          options={_ecIndustryOptions}
          values={_info['ecIndustrys']}
          onConfirm={options => this.handleChange('change_value', 'ecIndustrys', options)}
        />

        {/** 活跃投资人 */}
        <CECSelectPopup ref={this.activeInvestmentUserRef}
          title={'活跃投资人'}
          supportSearch={true}
          supportAvatar={false}
          multiple={true}
          onFetchDatas={(params) => FetchActiveInvestmentUserList({ orgCode: _info['code'], ...params })}
          values={_info['activeInvestmentUsers']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'activeInvestmentUsers')}
        />

        {/** 易凯联系人 */}
        <CECSelectPopup ref={this.cecEcapitalUserRef}
          title={'易凯联系人'}
          supportSearch={true}
          supportAvatar={true}
          multiple={true}
          options={_ecUserOptions}
          values={_info['ecapitalUsers']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'ecapitalUsers')}
        />
      </Fragment>
    )
  }

}