import React, { PureComponent } from "react";
import _ from "lodash";
import { Button, Dialog } from "antd-mobile";
import { DeleteOutline } from 'antd-mobile-icons'

/**
 * 删除与会方分组
 */
export default class DeleteTargetGroupItem extends PureComponent {

  render() {
    const {
      metadata,
      datas,
      sourcedata,
      sourcedataOrigin,
      extensionValue,
      onDataChange,
      onValueChange
    } = this.props

    return (
      <div style={{ position: 'absolute', top: '6px', left: '70px', background: '#fff', padding: '4px', zIndex: 1000 }}
        key={metadata['key']}
        onClick={() => {
          Dialog.confirm({
            title: '确定删除该条记录？',
            onConfirm: () => {
              const groupKeys = _.map(_.get(metadata, 'groups'), "key")
              // 删除原始SourceData
              delete sourcedataOrigin[metadata['key']]
              if (sourcedataOrigin && groupKeys && _.size(groupKeys) > 0) {
                for (const groupKey of groupKeys) {
                  delete sourcedataOrigin[groupKey]
                }
              }
              // metadata列表项
              const newDatas = _.remove(datas, item => item['key'] != metadata['key'])
              onDataChange({ metadatas: newDatas, sourcedataOrigin: sourcedataOrigin })

              // 删除Value
              delete extensionValue[metadata['key']]
              if (extensionValue && groupKeys && _.size(groupKeys) > 0) {
                for (const groupKey of groupKeys) {
                  delete extensionValue[groupKey]
                }
                onValueChange(extensionValue)
              }
            },
            onCancel: () => console.log('cancel......'),
          })
        }}>
        <Button size="mini" color="danger" shape='rounded'><DeleteOutline /></Button>
      </div>
    )
  }
}