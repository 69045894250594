import React, { Fragment, createRef } from 'react';
import {
    Dropdown, SideBar, Toast, Popover, List, Selector, Button, CheckList, Space, SearchBar, Modal,
} from 'antd-mobile';
import _ from 'lodash';
import { DeleteOutline, EditSOutline, MoreOutline } from 'antd-mobile-icons';
import {
    DROPDOWN_OPTIONS,
    DATE_SIDE_BAR_OPTIONS,
    DATE_TIMER_OPTIONS,
    MORE_SIDE_BAR_OPTIONS,
    MEETING_TYPE_OPTIONS, MEETING_OBJECTS_TYPE_OPTIONS, MEETING_CATEGORY_OPTIONS_KEY_VAL, MEETING_GROUPS, MEETING_USERS
} from './configuration';
import './index.less';
//融资规模
export const FINANCING_SCALE_OPTIONS = [
    { label: "1亿以下", value: "1亿以下" },
    { label: "1-3亿", value: "1-3亿" },
    { label: "3-5亿", value: "3-5亿" },
    { label: "5-10亿", value: "5-10亿" },
    { label: "10亿以上", value: "10亿以上" },
]
//募资规模
export const FUNDRAISING_SCALE_OPTIONS = [
    { label: "5亿以下", value: "5亿以下" },
    { label: "5-10亿", value: "5-10亿" },
    { label: "10亿-15亿", value: "10亿-15亿" },
    { label: "15-20亿", value: "15-20亿" },
    { label: "20-50亿", value: "20-50亿" },
    { label: "50-100亿", value: "50-100亿" },
    { label: "100亿以上", value: "100亿以上" },
]
const gov = [
    {
        "createDate": "2021-01-27",
        "name": "北京市",
        "pid": 0,
        "level": 1,
        "code": "11",
        "path": "11",
        "isDeleted": false,
        "new": false,
        "id": 1
    },
    {
        "createDate": "2021-01-27",
        "name": "天津市",
        "pid": 0,
        "level": 1,
        "code": "12",
        "path": "12",
        "isDeleted": false,
        "new": false,
        "id": 2
    },
    {
        "createDate": "2021-01-27",
        "name": "河北省",
        "pid": 0,
        "level": 1,
        "code": "13",
        "path": "13",
        "isDeleted": false,
        "new": false,
        "id": 3
    },
    {
        "createDate": "2021-01-27",
        "name": "山西省",
        "pid": 0,
        "level": 1,
        "code": "14",
        "path": "14",
        "isDeleted": false,
        "new": false,
        "id": 4
    },
    {
        "createDate": "2021-01-27",
        "name": "内蒙古自治区",
        "pid": 0,
        "level": 1,
        "code": "15",
        "path": "15",
        "isDeleted": false,
        "new": false,
        "id": 5
    },
    {
        "createDate": "2021-01-27",
        "name": "辽宁省",
        "pid": 0,
        "level": 1,
        "code": "21",
        "path": "21",
        "isDeleted": false,
        "new": false,
        "id": 6
    },
    {
        "createDate": "2021-01-27",
        "name": "吉林省",
        "pid": 0,
        "level": 1,
        "code": "22",
        "path": "22",
        "isDeleted": false,
        "new": false,
        "id": 7
    },
    {
        "createDate": "2021-01-27",
        "name": "黑龙江省",
        "pid": 0,
        "level": 1,
        "code": "23",
        "path": "23",
        "isDeleted": false,
        "new": false,
        "id": 8
    },
    {
        "createDate": "2021-01-27",
        "name": "上海市",
        "pid": 0,
        "level": 1,
        "code": "31",
        "path": "31",
        "isDeleted": false,
        "new": false,
        "id": 9
    },
    {
        "createDate": "2021-01-27",
        "name": "江苏省",
        "pid": 0,
        "level": 1,
        "code": "32",
        "path": "32",
        "isDeleted": false,
        "new": false,
        "id": 10
    },
    {
        "createDate": "2021-01-27",
        "name": "浙江省",
        "pid": 0,
        "level": 1,
        "code": "33",
        "path": "33",
        "isDeleted": false,
        "new": false,
        "id": 11
    },
    {
        "createDate": "2021-01-27",
        "name": "安徽省",
        "pid": 0,
        "level": 1,
        "code": "34",
        "path": "34",
        "isDeleted": false,
        "new": false,
        "id": 12
    },
    {
        "createDate": "2021-01-27",
        "name": "福建省",
        "pid": 0,
        "level": 1,
        "code": "35",
        "path": "35",
        "isDeleted": false,
        "new": false,
        "id": 13
    },
    {
        "createDate": "2021-01-27",
        "name": "江西省",
        "pid": 0,
        "level": 1,
        "code": "36",
        "path": "36",
        "isDeleted": false,
        "new": false,
        "id": 14
    },
    {
        "createDate": "2021-01-27",
        "name": "山东省",
        "pid": 0,
        "level": 1,
        "code": "37",
        "path": "37",
        "isDeleted": false,
        "new": false,
        "id": 15
    },
    {
        "createDate": "2021-01-27",
        "name": "河南省",
        "pid": 0,
        "level": 1,
        "code": "41",
        "path": "41",
        "isDeleted": false,
        "new": false,
        "id": 16
    },
    {
        "createDate": "2021-01-27",
        "name": "湖北省",
        "pid": 0,
        "level": 1,
        "code": "42",
        "path": "42",
        "isDeleted": false,
        "new": false,
        "id": 17
    },
    {
        "createDate": "2021-01-27",
        "name": "湖南省",
        "pid": 0,
        "level": 1,
        "code": "43",
        "path": "43",
        "isDeleted": false,
        "new": false,
        "id": 18
    },
    {
        "createDate": "2021-01-27",
        "name": "广东省",
        "pid": 0,
        "level": 1,
        "code": "44",
        "path": "44",
        "isDeleted": false,
        "new": false,
        "id": 19
    },
    {
        "createDate": "2021-01-27",
        "name": "广西壮族自治区",
        "pid": 0,
        "level": 1,
        "code": "45",
        "path": "45",
        "isDeleted": false,
        "new": false,
        "id": 20
    },
    {
        "createDate": "2021-01-27",
        "name": "海南省",
        "pid": 0,
        "level": 1,
        "code": "46",
        "path": "46",
        "isDeleted": false,
        "new": false,
        "id": 21
    },
    {
        "createDate": "2021-01-27",
        "name": "重庆市",
        "pid": 0,
        "level": 1,
        "code": "50",
        "path": "50",
        "isDeleted": false,
        "new": false,
        "id": 22
    },
    {
        "createDate": "2021-01-27",
        "name": "四川省",
        "pid": 0,
        "level": 1,
        "code": "51",
        "path": "51",
        "isDeleted": false,
        "new": false,
        "id": 23
    },
    {
        "createDate": "2021-01-27",
        "name": "贵州省",
        "pid": 0,
        "level": 1,
        "code": "52",
        "path": "52",
        "isDeleted": false,
        "new": false,
        "id": 24
    },
    {
        "createDate": "2021-01-27",
        "name": "云南省",
        "pid": 0,
        "level": 1,
        "code": "53",
        "path": "53",
        "isDeleted": false,
        "new": false,
        "id": 25
    },
    {
        "createDate": "2021-01-27",
        "name": "西藏自治区",
        "pid": 0,
        "level": 1,
        "code": "54",
        "path": "54",
        "isDeleted": false,
        "new": false,
        "id": 26
    },
    {
        "createDate": "2021-01-27",
        "name": "陕西省",
        "pid": 0,
        "level": 1,
        "code": "61",
        "path": "61",
        "isDeleted": false,
        "new": false,
        "id": 27
    },
    {
        "createDate": "2021-01-27",
        "name": "甘肃省",
        "pid": 0,
        "level": 1,
        "code": "62",
        "path": "62",
        "isDeleted": false,
        "new": false,
        "id": 28
    },
    {
        "createDate": "2021-01-27",
        "name": "青海省",
        "pid": 0,
        "level": 1,
        "code": "63",
        "path": "63",
        "isDeleted": false,
        "new": false,
        "id": 29
    },
    {
        "createDate": "2021-01-27",
        "name": "宁夏回族自治区",
        "pid": 0,
        "level": 1,
        "code": "64",
        "path": "64",
        "isDeleted": false,
        "new": false,
        "id": 30
    },
    {
        "createDate": "2021-01-27",
        "name": "新疆维吾尔自治区",
        "pid": 0,
        "level": 1,
        "code": "65",
        "path": "65",
        "isDeleted": false,
        "new": false,
        "id": 31
    },
    {
        "createDate": "2021-01-27",
        "name": "台湾省",
        "pid": 0,
        "level": 1,
        "code": "台湾",
        "path": "台湾",
        "isDeleted": false,
        "new": false,
        "id": 32
    },
    {
        "createDate": "2021-01-27",
        "name": "香港特别行政区",
        "pid": 0,
        "level": 1,
        "code": "香港",
        "path": "香港",
        "isDeleted": false,
        "new": false,
        "id": 33
    },
    {
        "createDate": "2021-01-27",
        "name": "澳门特别行政区",
        "pid": 0,
        "level": 1,
        "code": "澳门",
        "path": "澳门",
        "isDeleted": false,
        "new": false,
        "id": 34
    },
    {
        "createDate": "2021-01-27",
        "name": "钓鱼岛",
        "pid": 0,
        "level": 1,
        "code": "钓鱼岛",
        "path": "钓鱼岛",
        "isDeleted": false,
        "new": false,
        "id": 35
    }
]
//政府层级
export const GOV_ADDRESS_OPTIONS = _.map(gov, item => ({
    label: _.replace(_.replace(item['name'], '省', ''), '市', ''), value: item['id']
}))

export function formatAmount(num) {
    if (_.isNumber(num)) {
        let _num = num.toFixed(2).replace(/\.?0+$/, '')
        let [integerPart, decimalPart] = _num.split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    }
    return num
}

/**列表项多条数据 */
export function ListItemExtraPopoverMenu({ onEdit, onDelete }) {
    return (
        <Popover.Menu placement="bottom"
            trigger="click"
            actions={[
                { text: <span style={{ fontSize: '12px' }}>编辑</span>, key: 'edit', icon: <EditSOutline />, onClick: (e) => onEdit && onEdit(), },
                {
                    text: <span style={{ color: 'red', fontSize: '12px' }}>删除</span>, key: 'delete', icon: <DeleteOutline style={{ color: 'red' }} />, onClick: () => {
                        Modal.confirm({
                            content: `确定删除数据？`,
                            onConfirm: () => onDelete && onDelete()
                        })
                    },
                },
            ]}>
            <Button color="primary" fill="none" style={{ fontSize: '12px' }} onClick={(e) => e.stopPropagation()}>已添加数据<MoreOutline style={{ marginLeft: 5 }} /></Button>
        </Popover.Menu>
    )
}

/** 易凯行业数据模拟 */
const cec_industry =
    [
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_001",
            "sys_labelelement_name": "药和生物科技"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_002",
            "sys_labelelement_name": "医疗器械"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_003",
            "sys_labelelement_name": "医疗和健康服务"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_004",
            "sys_labelelement_name": "数字与AI健康"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_022",
            "sys_labelelement_name": "合成生物"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_005_001",
                    "sys_labelelement_name": "VR/AR",
                    "sys_labelelement_en_name": "VR/AR",
                    "sys_labelelement_type": "Lable_comindu_yk2_005",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_005_002",
                    "sys_labelelement_name": "数字孪生",
                    "sys_labelelement_en_name": "数字孪生",
                    "sys_labelelement_type": "Lable_comindu_yk2_005",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_005_003",
                    "sys_labelelement_name": "引擎",
                    "sys_labelelement_en_name": "引擎",
                    "sys_labelelement_type": "Lable_comindu_yk2_005",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_005_004",
                    "sys_labelelement_name": "内容",
                    "sys_labelelement_en_name": "内容",
                    "sys_labelelement_type": "Lable_comindu_yk2_005",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_005_005",
                    "sys_labelelement_name": "其他",
                    "sys_labelelement_en_name": "其他",
                    "sys_labelelement_type": "Lable_comindu_yk2_005",
                    "isDeleted": false
                }
            ],
            "sys_labelelement_code": "Lable_comindu_yk2_005",
            "sys_labelelement_name": "元宇宙"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_006",
            "sys_labelelement_name": "媒体与娱乐"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_007_001",
                    "sys_labelelement_name": "半导体芯片",
                    "sys_labelelement_en_name": "半导体芯片",
                    "sys_labelelement_type": "Lable_comindu_yk2_007",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_007_002",
                    "sys_labelelement_name": "新一代智能载具",
                    "sys_labelelement_en_name": "新一代智能载具",
                    "sys_labelelement_type": "Lable_comindu_yk2_007",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_007_003",
                    "sys_labelelement_name": "机器人",
                    "sys_labelelement_en_name": "机器人",
                    "sys_labelelement_type": "Lable_comindu_yk2_007",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_007_004",
                    "sys_labelelement_name": "AI",
                    "sys_labelelement_en_name": "AI",
                    "sys_labelelement_type": "Lable_comindu_yk2_007",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_007_005",
                    "sys_labelelement_name": "先进制造",
                    "sys_labelelement_en_name": "先进制造",
                    "sys_labelelement_type": "Lable_comindu_yk2_007",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_007_006",
                    "sys_labelelement_name": "航空航天",
                    "sys_labelelement_en_name": "航空航天",
                    "sys_labelelement_type": "Lable_comindu_yk2_007",
                    "isDeleted": false
                }
            ],
            "sys_labelelement_code": "Lable_comindu_yk2_007",
            "sys_labelelement_name": "硬科技"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_008_001",
                    "sys_labelelement_name": "企业服务及SaaS",
                    "sys_labelelement_en_name": "企业服务及SaaS",
                    "sys_labelelement_type": "Lable_comindu_yk2_008",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_008_002",
                    "sys_labelelement_name": "大数据云服务",
                    "sys_labelelement_en_name": "大数据云服务",
                    "sys_labelelement_type": "Lable_comindu_yk2_008",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_008_003",
                    "sys_labelelement_name": "物流",
                    "sys_labelelement_en_name": "物流",
                    "sys_labelelement_type": "Lable_comindu_yk2_008",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_008_004",
                    "sys_labelelement_name": "产业互联网",
                    "sys_labelelement_en_name": "产业互联网",
                    "sys_labelelement_type": "Lable_comindu_yk2_008",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_008_005",
                    "sys_labelelement_name": "其他",
                    "sys_labelelement_en_name": "其他",
                    "sys_labelelement_type": "Lable_comindu_yk2_008",
                    "isDeleted": false
                }
            ],
            "sys_labelelement_code": "Lable_comindu_yk2_008",
            "sys_labelelement_name": "软科技"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_009_001",
                    "sys_labelelement_name": "电池与储能",
                    "sys_labelelement_en_name": "电池与储能",
                    "sys_labelelement_type": "Lable_comindu_yk2_009",
                    "isDeleted": false
                },
                {
                    "sys_labelelement_code": "Lable_comindu_yk2_009_002",
                    "sys_labelelement_name": "新能源",
                    "sys_labelelement_en_name": "新能源",
                    "sys_labelelement_type": "Lable_comindu_yk2_009",
                    "isDeleted": false
                }
            ],
            "sys_labelelement_code": "Lable_comindu_yk2_009",
            "sys_labelelement_name": "碳中和与新能源"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_010",
            "sys_labelelement_name": "新材料及技术"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_011",
            "sys_labelelement_name": "区块链"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_012",
            "sys_labelelement_name": "食品与农业科技"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_013",
            "sys_labelelement_name": "新品牌"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_014",
            "sys_labelelement_name": "新零售"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_015",
            "sys_labelelement_name": "潮流文化"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_016",
            "sys_labelelement_name": "健康生活方式"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_017",
            "sys_labelelement_name": "智能硬件"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_018",
            "sys_labelelement_name": "新服务及新体验"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_019",
            "sys_labelelement_name": "教育"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_020",
            "sys_labelelement_name": "金融服务"
        },
        {
            "sys_labelelement_type": "Lable_comindu_yk2",
            "children": [],
            "sys_labelelement_code": "Lable_comindu_yk2_021",
            "sys_labelelement_name": "其他"
        }
    ]
const IndustryOptions = _.map(cec_industry, item =>
({
    label: item['sys_labelelement_name'],
    value: item['sys_labelelement_code'],
    children: _.isArray(item['children']) ? _.map(item['children'], subItem => ({ pId: item['sys_labelelement_code'], label: subItem['sys_labelelement_name'], value: subItem['sys_labelelement_code'] })) : [],
})
)

const cec_invsetor_type = [
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-002",
        "sys_labelelement_name": "VC",
        "sys_labelelement_en_name": "VC",
        "sys_labelelement_type": "Lable_type",
        "rank": 1,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-003",
        "sys_labelelement_name": "PE",
        "sys_labelelement_en_name": "PE",
        "sys_labelelement_type": "Lable_type",
        "rank": 2,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-032",
        "sys_labelelement_name": "PE/Buyout",
        "sys_labelelement_en_name": "PE/Buyout",
        "sys_labelelement_type": "Lable_type",
        "rank": 3,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2019-07-18",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-004",
        "sys_labelelement_name": "对冲",
        "sys_labelelement_en_name": "Hedge Fund",
        "sys_labelelement_type": "Lable_type",
        "rank": 4,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2019-07-18",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-005",
        "sys_labelelement_name": "主权",
        "sys_labelelement_en_name": "Sovereign Wealth Fund",
        "sys_labelelement_type": "Lable_type",
        "rank": 5,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-025",
        "sys_labelelement_name": "母基金",
        "sys_labelelement_en_name": "FOF",
        "sys_labelelement_type": "Lable_type",
        "rank": 6,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-010",
        "sys_labelelement_name": "FOF",
        "sys_labelelement_en_name": "FOF",
        "sys_labelelement_type": "Lable_type",
        "rank": 7,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-012",
        "sys_labelelement_name": "金融机构",
        "sys_labelelement_en_name": "Financial Institution",
        "sys_labelelement_type": "Lable_type",
        "rank": 8,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-022",
        "sys_labelelement_name": "家族",
        "sys_labelelement_en_name": "Family Foundation",
        "sys_labelelement_type": "Lable_type",
        "rank": 9,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-028",
        "sys_labelelement_name": "战略",
        "sys_labelelement_en_name": "Strategic Investor",
        "sys_labelelement_type": "Lable_type",
        "rank": 10,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-027",
        "sys_labelelement_name": "超级基金",
        "sys_labelelement_en_name": "Superannuation/Pension Fund",
        "sys_labelelement_type": "Lable_type",
        "rank": 11,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-030",
        "sys_labelelement_name": "Super Funds",
        "sys_labelelement_en_name": "Super Funds",
        "sys_labelelement_type": "Lable_type",
        "rank": 12,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-020",
        "sys_labelelement_name": "国家队/政府背景",
        "sys_labelelement_en_name": "Stated-Owned/Backed Fund",
        "sys_labelelement_type": "Lable_type",
        "rank": 13,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2022-12-05",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-035",
        "sys_labelelement_name": "央企/国企",
        "sys_labelelement_en_name": "SOEs",
        "sys_labelelement_type": "Lable_type",
        "rank": 14,
        "isshow": true,
        "updateusername": "Admin",
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-017",
        "sys_labelelement_name": "大企业集团",
        "sys_labelelement_en_name": "Business Conglomerate",
        "sys_labelelement_type": "Lable_type",
        "rank": 15,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-018",
        "sys_labelelement_name": "A股上市公司",
        "sys_labelelement_en_name": "A-share Listed Company",
        "sys_labelelement_type": "Lable_type",
        "rank": 16,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-05-26",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-026",
        "sys_labelelement_name": "港美股上市公司",
        "sys_labelelement_en_name": "Overseas Listed Company",
        "sys_labelelement_type": "Lable_type",
        "rank": 17,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-11-13",
        "sys_labelelement_code": "Lable-type-036",
        "sys_labelelement_name": "CVC",
        "sys_labelelement_en_name": "CVC",
        "sys_labelelement_type": "Lable_type",
        "rank": 18,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2018-08-31",
        "modifyDate": "2023-11-13",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-019",
        "sys_labelelement_name": "通道",
        "sys_labelelement_en_name": "Channel Service",
        "sys_labelelement_type": "Lable_type",
        "rank": 19,
        "isshow": true,
        "isDeleted": false
    },
    {
        "createDate": "2022-12-05",
        "modifyDate": "2023-11-13",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-034",
        "sys_labelelement_name": "跨国公司",
        "sys_labelelement_en_name": "MultinationalCompany",
        "sys_labelelement_type": "Lable_type",
        "rank": 20,
        "isshow": true,
        "updateusername": "Admin",
        "isDeleted": false
    },
    {
        "modifyDate": "2023-11-13",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-029",
        "sys_labelelement_name": "Crossover",
        "sys_labelelement_en_name": "Crossover",
        "sys_labelelement_type": "Lable_type",
        "rank": 21,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-11-13",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-031",
        "sys_labelelement_name": "Real Estate Funds",
        "sys_labelelement_en_name": "Real Estate Funds",
        "sys_labelelement_type": "Lable_type",
        "rank": 22,
        "isshow": true,
        "isDeleted": false
    },
    {
        "modifyDate": "2023-11-13",
        "createuserid": 1,
        "sys_labelelement_code": "Lable-type-033",
        "sys_labelelement_name": "未定义",
        "sys_labelelement_en_name": "Undefined",
        "sys_labelelement_type": "Lable_type",
        "rank": 23,
        "isshow": true,
        "isDeleted": false
    }
]
export const INVESTOR_TYPE_OPTIONS = _.map(cec_invsetor_type, it => ({ label: it.sys_labelelement_name, value: it.sys_labelelement_code }))
export const INVESTOR_OPTIONS = [
    { label: '红杉中国', value: '红杉中国', avatar: 'http://file.ceccapitalgroup.com/logo/investment/25e8990b58858072fb6ec5b1d2d122d2.jpg', selected: false },
    { label: 'IDG', value: 'IDG', avatar: 'http://file.ceccapitalgroup.com/logo/investment/8c8a7bd8ab9687368c5ea5013889f391.jpg', selected: false },
    { label: '京东集团', value: '京东集团', avatar: 'https://img1.qimingpian.cn/product/upload/5ba19e101b185.png', selected: false },
    { label: '匹克体育', value: '匹克体育', avatar: 'https://img1.qimingpian.cn/uploadImg/202109/614fd4fda279e.png', selected: false },
    { label: '顺为资本', value: '顺为资本', avatar: 'http://file.ceccapitalgroup.com/logo/investment/48cc5ed0d537922e274282b6e942c6ec.jpg', selected: false },
    { label: '启明创投', value: '启明创投', avatar: 'http://file.ceccapitalgroup.com/investment/2022-07-13/1657687569414.png', selected: false },
    { label: '君联资本', value: '君联资本', avatar: 'http://file.ceccapitalgroup.com/logo/investment/1b8a399c769fdae29ec87e6dc4119482.jpg', selected: false },
]
export const INVESTOR_USERS_OPTIONS = [
    { label: '张三', value: '张三', selected: false },
    { label: '李四', value: '李四', selected: false },
    { label: '王五', value: '王五', selected: false },
]

export const UNIT_OPTIONS = [
    { label: "千", value: "千" },
    { label: "百万", value: "百万" },
    { label: "十亿", value: "十亿" },
]

export const CURRENCY_OPTIONS = [
    { label: "人民币", value: "人民币" },
    { label: "美元", value: "美元" },
    { label: "其他", value: "其他" },
]
// console.log("IndustryOptions==>", IndustryOptions)


export function getIndustrySelectOptionTxt(options = []) {
    return _.join(_.map(options, option => {
        let subStr = ''
        if (_.size(option['children']) > 0) {
            subStr = `（${_.join(_.map(option['children'], 'label'), '，')}）`
        }
        return `${option['label']}${subStr}`
    }), '，')
}

export function onIndustryItemClick({ options = IndustryOptions, value = [] }) {


    let _options = _.map(options, item => {
        let subItemSelected = false
        if (_.size(item['children']) > 0) {
            item['children'] = _.map(item['children'], subItem => {
                if (value.includes(subItem['value'])) {
                    subItem['selected'] = true
                    item['selected'] = true
                    subItemSelected = true
                } else {
                    subItem['selected'] = false
                }

                return subItem
            })
        }

        if (subItemSelected == false) {
            if (value.includes(item['value'])) {
                item['selected'] = true
            } else {
                item['selected'] = false
            }
        }

        return item
    })

    var selectedItems = []
    _.map(_.filter(_options, { selected: true }), option => {
        selectedItems.push(option)
        _.map(_.filter(option['children'], { selected: true }), subOption => {
            selectedItems.push(subOption)
        })
    })

    return selectedItems
}

export function IndustryView({ options = IndustryOptions, values, multiple, onChange, fontSize = '13px' }) {
    return (
        <CheckList
            style={{ '--font-size': fontSize, '--border-bottom': 'none', '--border-top': 'none' }}
            multiple={multiple}
            value={values}
            onChange={onChange}>
            {
                options.map(item => (
                    <Fragment>
                        <CheckList.Item key={item['value']} value={item['value']}>
                            <Space>
                                <div>
                                    <div>{item['label']}{item['titleExtension'] && <span style={{ marginLeft: 5 }}>{item['titleExtension']}</span>}</div>
                                    {item['description'] && <div>{item['description']}</div>}
                                </div>
                            </Space>
                        </CheckList.Item>
                        {
                            _.map(item['children'], subItem => (
                                <CheckList.Item key={subItem['value']} value={subItem['value']}>
                                    <Space>
                                        <div>
                                            <div style={{ marginLeft: 16 }}>{subItem['label']}{subItem['titleExtension'] && <span style={{ marginLeft: 5 }}>{subItem['titleExtension']}</span>}</div>
                                            {subItem['description'] && <div>{subItem['description']}</div>}
                                        </div>
                                    </Space>
                                </CheckList.Item>
                            ))
                        }
                    </Fragment>
                ))
            }
        </CheckList>
    )
}

export function DropdownItemLayoutView({ children, onResetting, onConfirm }) {
    return (
        <div className='cec-smartx-dropdown-item-layout'>
            <div className='cec-smartx-dropdown-item-main'>
                {children}
            </div>
            <div className='cec-smartx-dropdown-item-foot'>
                <div className='cec-smartx-dropdown-item-foot-flex1' style={{ marginRight: 6 }}>
                    <Button style={{ fontSize: 14 }} block onClick={onResetting}>重置</Button>
                </div>
                <div className='cec-smartx-dropdown-item-foot-flex1' style={{ marginLeft: 6 }}>
                    <Button style={{ fontSize: 14 }} block color='primary' onClick={onConfirm}>确定</Button>
                </div>
            </div>
        </div>
    )
}

export function SideBarView({ options, value, onChange, children }) {
    return (
        <div className='cec-smartx-side-bar-layout'>
            <SideBar className='cec-smartx-side-bar' onChange={onChange} activeKey={value}>
                {
                    _.map(options, option =>
                        <SideBar.Item
                            key={option.value}
                            title={option.label}
                            badge={option.badge}
                        />)
                }
            </SideBar>
            <div className='cec-smartx-side-bar-main'>{children}</div>
        </div>
    )
}


export class MeetingSearchBar extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            dropdownOptions: DROPDOWN_OPTIONS,
            dateSideBarOptions: DATE_SIDE_BAR_OPTIONS,
            dateSideBarValue: "dateTime",
            moreSideBarOptions: MORE_SIDE_BAR_OPTIONS,
            moreSideBarValue: "types",

            _filter: {},
        }
        this.dropdownRef = createRef()
    }

    handleClick(key, data, event) {
        console.log("handleClick", key, data, event, this.dropdownRef)
        const { _filter } = this.state
        const { onConfirm } = this.props
        switch (key) {
            case 'onStateChange':
                this.setState({
                    [data]: event
                })
                break
            case 'onFilterChange':
                this.setState(
                    { _filter: { ..._filter, [data]: event } },
                    () => {
                        if (_.includes([
                            'dateTime', 'dateAuto',
                            'types',
                            'investmentTypes_old', 'investmentTypes_fund', 'investmentTypes_lp',
                            'objects_type', 'business', 'nature', 'users', 'groups'
                        ], data)) {
                            Toast.show({
                                maskStyle: { "--z-index": 999999 },
                                content: 'DEMO演示，暂不支持当前条件',
                            })
                        }
                    }
                )
                break
            case 'onResetting':
                Toast.show({
                    maskStyle: { "--z-index": 999999 },
                    content: 'DEMO演示，暂不支持重置条件',
                })
                break
            case 'onConfirm':
                break
            case 'closeDropdown':
                if (this.dropdownRef && this.dropdownRef.current) {
                    this.dropdownRef.current.close()
                }
                onConfirm && onConfirm(_filter)
                break

        }
    }

    render() {
        const { dropdownOptions, _filter } = this.state
        console.log('_filter===>', _filter)
        return (
            <Dropdown ref={this.dropdownRef} style={{ '--adm-font-size-main': '14px' }}>
                {_.map(dropdownOptions, ({ label, value }) =>
                    <Dropdown.Item key={value} title={label}>
                        {this.renderDropdownItemView(value)}
                    </Dropdown.Item>
                )}
            </Dropdown>
        )
    }

    renderDropdownItemView(type) {
        switch (type) {
            case 'date':
                return this.renderDateView()
                break
            case 'keyWord':
                return this.renderKeyWrodView()
                break
            case 'industry':
                return this.renderIndustryView()
                break
            case 'more':
                return this.renderMoreView()
                break
        }
    }

    renderDateView() {
        const { dateSideBarOptions, dateSideBarValue, _filter } = this.state
        return (
            <DropdownItemLayoutView
                onResetting={() => this.handleClick('onResetting', 'date')}
                onConfirm={() => this.handleClick('closeDropdown', 'date')}
            >
                <SideBarView
                    options={dateSideBarOptions}
                    value={dateSideBarValue}
                    onChange={val => this.handleClick('onStateChange', 'dateSideBarValue', val)}
                >
                    {
                        dateSideBarValue == "dateTime" && (
                            <div>
                                <Selector
                                    style={{ padding: 12, '--font-size': '13px' }}
                                    columns={2}
                                    options={DATE_TIMER_OPTIONS}
                                    value={_filter['dateTime']}
                                    onChange={val => this.handleClick('onFilterChange', 'dateTime', val)}
                                />
                            </div>
                        )
                    }
                    {
                        dateSideBarValue == "dateAuto" && (
                            <div>
                                <List style={{ "--font-size": "13px", '--border-top': "none" }}>
                                    <List.Item onClick={() => this.handleClick('onFilterChange', 'dateAuto', null)} style={{}}>开始时间</List.Item>
                                    <List.Item onClick={() => this.handleClick('onFilterChange', 'dateAuto', null)} style={{}}>结束时间</List.Item>
                                </List>
                            </div>
                        )
                    }
                </SideBarView>
            </DropdownItemLayoutView>
        )
    }

    renderKeyWrodView() {
        const { dateSideBarOptions, dateSideBarValue, _filter } = this.state
        return (
            <DropdownItemLayoutView
                onResetting={() => this.handleClick('onResetting', 'keyWord')}
                onConfirm={() => this.handleClick('closeDropdown', 'date')}
            >
                <div style={{ padding: 12 }}>
                    <SearchBar
                        placeholder='请输入关键字'
                        value={_filter['keyWord']}
                        onChange={val => this.handleClick('onFilterChange', 'keyWord', val)}
                    />
                </div>
            </DropdownItemLayoutView>
        )
    }

    renderIndustryView() {
        const { _filter } = this.state
        console.log("_filter['industry']===>", _filter['industry'])
        return (
            <DropdownItemLayoutView
                onResetting={() => this.handleClick('onResetting', 'industry')}
                onConfirm={() => this.handleClick('closeDropdown', 'industry')}
            >
                <div className='cec-smartx-side-bar-main1'>
                    <IndustryView
                        multiple={true}
                        values={_filter['industry'] ? _.map(_filter['industry'], 'value') : []}
                        onChange={val => this.handleClick('onFilterChange', 'industry', onIndustryItemClick({ value: val }))}
                    />
                </div>
            </DropdownItemLayoutView>

        )
    }

    renderMoreView() {
        const { moreSideBarOptions, moreSideBarValue, _filter } = this.state
        return (
            <DropdownItemLayoutView
                onResetting={() => this.handleClick('onResetting', 'more')}
                onConfirm={() => this.handleClick('closeDropdown', 'more')}
            >
                <SideBarView
                    options={moreSideBarOptions}
                    value={moreSideBarValue}
                    onChange={val => this.handleClick('onStateChange', 'moreSideBarValue', val)}
                >
                    {
                        moreSideBarValue == "types" && (
                            <div>
                                <IndustryView options={MEETING_TYPE_OPTIONS} onChange={val => this.handleClick('onFilterChange', 'types', val)} />
                            </div>
                        )
                    }

                    {
                        moreSideBarValue == "investmentTypes" && (
                            <div style={{ padding: 12, }}>
                                <div style={{ color: "#999", fontSize: 13, marginBottom: 4 }}>参与老股投资</div>
                                <Selector
                                    style={{ '--font-size': '13px' }}
                                    columns={2}
                                    options={[{ label: "是", value: "true" }, { label: "否", value: "false" }]}
                                    value={_filter['investmentTypes_old']}
                                    onChange={val => this.handleClick('onFilterChange', 'investmentTypes_old', val)}
                                />
                                <div style={{ color: "#999", fontSize: 13, marginTop: 12, marginBottom: 4 }}>项目/基金退出需求</div>
                                <Selector
                                    style={{ '--font-size': '13px' }}
                                    columns={2}
                                    options={[{ label: "是", value: "true" }, { label: "否", value: "false" }]}
                                    value={_filter['investmentTypes_fund']}
                                    onChange={val => this.handleClick('onFilterChange', 'investmentTypes_fund', val)}
                                />
                                <div style={{ color: "#999", fontSize: 13, marginTop: 12, marginBottom: 4 }}>可做LP</div>
                                <Selector
                                    style={{ '--font-size': '13px' }}
                                    columns={2}
                                    options={[{ label: "是", value: "true" }, { label: "否", value: "false" }]}
                                    value={_filter['investmentTypes_lp']}
                                    onChange={val => this.handleClick('onFilterChange', 'investmentTypes_lp', val)}
                                />
                            </div>
                        )
                    }

                    {
                        moreSideBarValue == "objects" && (
                            <div>
                                <Selector
                                    style={{ padding: 12, '--font-size': '13px' }}
                                    columns={2}
                                    options={MEETING_OBJECTS_TYPE_OPTIONS}
                                    value={_filter['objects_type']}
                                    onChange={val => this.handleClick('onFilterChange', 'objects_type', val)}
                                />
                            </div>
                        )

                    }

                    {
                        moreSideBarValue == "nature" && (
                            <div>
                                <Selector
                                    style={{ padding: 12, '--font-size': '13px' }}
                                    columns={2}
                                    options={MEETING_CATEGORY_OPTIONS_KEY_VAL}
                                    value={_filter['nature']}
                                    onChange={val => this.handleClick('onFilterChange', 'nature', val)}
                                />
                            </div>
                        )
                    }
                    {
                        moreSideBarValue == "business" && (
                            <div>
                                <Selector
                                    style={{ padding: 12, '--font-size': '13px' }}
                                    columns={2}
                                    options={[
                                        { label: "融资", value: "融资" },
                                        { label: "并购", value: "并购" },
                                        { label: "募资", value: "募资" },
                                        { label: "投退", value: "投退" },
                                    ]}
                                    value={_filter['business']}
                                    onChange={val => this.handleClick('onFilterChange', 'business', val)}
                                />
                            </div>
                        )
                    }
                    {
                        moreSideBarValue == "users" && (
                            <div>
                                {/* <div style={{padding: 12}}>
                                    <SearchBar style={{'font-size': '13px'}} placeholder='请输入关键字'/>
                                </div> */}
                                <IndustryView options={MEETING_USERS} onChange={val => this.handleClick('onFilterChange', 'users', val)} />
                            </div>
                        )
                    }
                    {
                        moreSideBarValue == "groups" && (
                            <div>
                                <Selector
                                    style={{ padding: 12, '--font-size': '13px' }}
                                    columns={2}
                                    options={MEETING_GROUPS}
                                    value={_filter['groups']}
                                    onChange={val => this.handleClick('onFilterChange', 'groups', val)}
                                />
                            </div>
                        )
                    }
                </SideBarView>
            </DropdownItemLayoutView>
        )
    }
}



export class TSearchView extends React.PureComponent {
    constructor(props) {
        super(props)
        this.dropdownRef = createRef()
    }

    render() {
        const {
            dropdownClassName,
            //下拉菜单部分
            dropdownOptions, filter, onReSetting, onComfirm, children, onDropdownChange,
            //显示总数量
            total = 0,
            //
            filterTargetOptions, onFilterTargetItemClearClick, onFilterTargetAllClearClick
        } = this.props

        const _options = _.map(dropdownOptions, option => {
            let highlight = false
            _.map(_.get(option, 'filterKey', []), key => {
                let filterObj = filter[key]
                let flag = (filter && filterObj != null && _.size(filterObj) > 0) ? true : false
                if (!highlight) {
                    highlight = flag
                }
            })
            return ({ ...option, highlight: highlight })
        })
        return (
            <div className='cec-mobile-component-search-nav'>
                <TDropdown
                    dropdownClassName={dropdownClassName}
                    ref={this.dropdownRef}
                    options={_options}
                    onChange={val => onDropdownChange && onDropdownChange(val)}
                    renderOptionChildren={key => {
                        return (
                            <TDropdownBox
                                onReSetting={() => {
                                    onReSetting && onReSetting(key)
                                }}
                                onComfirm={() => {
                                    onComfirm && onComfirm(key)
                                }}
                            >
                                {children && children(key)}
                            </TDropdownBox>
                        )
                    }}
                />
                {
                    _.size(filterTargetOptions) > 0 && (
                        <TagListView
                            options={filterTargetOptions}
                            onItemClearClick={onFilterTargetItemClearClick}
                            onAllClearClick={onFilterTargetAllClearClick}
                        />
                    )

                }

                {
                    total >= 0 && (
                        <div className='cec-mobile-component-search-nav-number'>
                            共{total}条
                        </div>
                    )
                }
                
            </div>
        )
    }

    closeDropdown() {
        if (this.dropdownRef && this.dropdownRef.current) {
            this.dropdownRef.current.close()
        }
    }
}


export class TDropdown extends React.PureComponent {
    constructor(props) {
        super(props)
        this.dropdownRef = createRef()
    }

    close() {
        if (this.dropdownRef && this.dropdownRef.current) {
            this.dropdownRef.current.close()
        }
    }

    render() {
        const { dropdownClassName, options, renderOptionChildren, onChange, elementById } = this.props
        return (
            <Dropdown className={dropdownClassName} getContainer={()=> elementById ? document.getElementById(elementById): undefined} ref={this.dropdownRef} onChange={onChange}>
                {
                    _.map(options, ({ value, label, highlight }) => (
                        <Dropdown.Item key={value} title={label} highlight={highlight}>{
                            renderOptionChildren && renderOptionChildren(value)
                        }</Dropdown.Item>
                    ))
                }
            </Dropdown>
        )
    }
}

export class TDropdownBox extends React.PureComponent {
    render() {
        const { children, onReSetting, onComfirm } = this.props
        return (
            <div className='cec-mobile-component-dropdown-page'>
                <div className='cec-mobile-component-dropdown-page-main'>{children}</div>
                <div className='cec-mobile-component-dropdown-page-row padding border-top'>
                    <div className='cec-mobile-component-dropdown-page-row flex' style={{ marginRight: 16 }}>
                        <Button block className='font-size' onClick={onReSetting}>重置</Button>
                    </div>
                    <div className='cec-mobile-component-dropdown-page-row flex'>
                        <Button onC block color='primary' className='font-size' onClick={onComfirm}>确定</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export function TSideBarBox({ sideBarOptions, sideBarValue, onSideBarChange, filter, children, width = '115px' }) {
    const _options = _.map(sideBarOptions, option => {
        let selected = false
        _.map(_.get(option, 'filterKey', []), key => {
            if (!selected) {
                selected = (filter && filter[key] != null && _.size(filter[key]) > 0) ? true : false
            }
        })
        return ({ ...option, selected: selected })
    })
    return (
        <div className='cec-mobile-component-side-bar-box'>
            <div className='cec-mobile-component-side-bar-box-side-bar'>
                <SideBar value={sideBarValue} style={{ '--font-size': '13px', '--width': width }} onChange={val => onSideBarChange && onSideBarChange(val)}>
                    {
                        _.map(_options, ({ value, label, selected, disabled }) => <SideBar.Item disabled={disabled == true ? true: false} key={value} title={<SideBarTitle title={label} selected={selected} />} />)
                    }
                </SideBar>
            </div>
            <div className='cec-mobile-component-side-bar-box-main'>
                {children}
            </div>
        </div>
    )
}

function SideBarTitle({ title, selected }) {
    return (
        <Space align='center' style={{ '--gap': '4px' }}>
            <div>{title}</div>
            {selected && <div style={{ backgroundColor: "red", width: 6, height: 6, borderRadius: "50%" }}></div>}
        </Space>
    )
}


export class TagListView extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            _modalVisible: false
        }
    }

    render() {
        const { options, onItemClearClick, onAllClearClick } = this.props
        return (
            <div className='cec-mobile-component-tag-list-layout cec-mobile-component-tag-list-layout-row'>
                <div className='cec-mobile-component-tag-list-layout-row-scorll' style={{ marginRight: 8 }}>
                    <Space align='center'>
                        {_.map(options, option => <DefaultTag onClick={() => onItemClearClick && onItemClearClick(option)}>{option['label']}</DefaultTag>)}
                    </Space>
                </div>
                <div onClick={() => this.setState({ _modalVisible: true })}>
                    <Space align='center'>
                        <div style={{ color: "#999999" }}>{`${_.size(options)}个筛选条件`}</div>
                        <div style={{ color: "#1890ff" }}>查看</div>
                    </Space>
                </div>
                {this.renderModal()}
            </div>
        )
    }

    renderModal() {
        const { _modalVisible, } = this.state
        const { options, onAllClearClick, onItemClearClick } = this.props
        const groupOptions = _.map(_.groupBy(options, 'group'), (value, key) => ({ title: key, options: value }))
        let content = (
            <div style={{ height: 300, fontSize: 12, overflowY: "auto" }}>
                {
                    _.map(groupOptions, ({ title, options }, i) => (
                        <div className='cec-sims-moblie-target-company-column' style={{ marginTop: i > 0 ? 8 : 0 }}>
                            <div style={{ lineHeight: "25px" }}>{title}</div>
                            <div style={{ lineHeight: "25px" }}>
                                <Space wrap>
                                    {
                                        _.map(options, option => <DefaultTag onClick={() => onItemClearClick && onItemClearClick(option)}>{option['label']}</DefaultTag>)
                                    }
                                </Space>
                            </div>
                        </div>
                    ))
                }
            </div>
        )

        return (
            <Fragment>
                <Modal
                    title={
                        <div className='row-between'>
                            <span style={{ color: "#999999", fontSize: 16 }}>{`全部筛选条件(${_.size(options)})`}</span>
                            <span onClick={() => this.setState({ _modalVisible: false }, () => onAllClearClick && onAllClearClick())} style={{ color: "#d46b08", fontSize: 13, fontWeight: "normal" }}>清空全部</span>
                        </div>
                    }
                    visible={_modalVisible}
                    closeOnAction
                    content={content}
                    onClose={() => this.setState({ _modalVisible: false })}
                    actions={[
                        {
                            style: { padding: 4, fontSize: 13, border: "1px solid #eeeeee", borderRadius: 4, color: "#333333" },
                            key: 'confirm',
                            text: '关闭',
                            onClick: () => this.setState({ _modalVisible: false })
                        }
                    ]}
                />
            </Fragment>
        )
    }
}

export function DefaultTag({ children, onClick }) {
    let lable = children
    let svg = <svg width="17px" height="13px" viewBox="0 0 17 13" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(-2832.000000, -1103.000000)" stroke="#FFFFFF" stroke-width="3"><g transform="translate(2610.000000, 955.000000)"><g transform="translate(24.000000, 91.000000)"><g transform="translate(179.177408, 36.687816)"><polyline points="34.2767388 22 24.797043 31.4796958 21 27.6826527"></polyline></g></g></g></g></g></svg>
    return (
        <div onClick={() => onClick && onClick()} className='cec-mobile-component-tag'>
            <span>{lable}</span>
            <span className='selector-check-mark-wrapper'>{svg}</span>
        </div>
    )
}



