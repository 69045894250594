import React, { createRef, Fragment, PureComponent } from "react";
import _ from "lodash";
import { UploadOutline, DeleteOutline } from 'antd-mobile-icons'
import { Space, Toast } from "antd-mobile";

/**
 * 上传附件
 */
export default class UploadFile extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      _files: props.values ? props.values : [],
    }
    this.inputFileRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _files: props.values ? props.values : [],
    })
  }

  uploadFile(files) {
    const { onChange } = this.props
    Toast.show({ icon: 'loading', content: '上传中...' })

    let uploadNum = 0
    if (files && _.isArray(files)) {
      files.find(fileItem => {
        UploadAttach(fileItem,
          (data) => {
            uploadNum++
            const { _files } = this.state
            const newFiles = _.concat(_files, { ...data })
            this.setState({ _files: newFiles }, () => onChange && onChange(newFiles))
          },
          (msg) => {
            uploadNum++
            Toast.show({ icon: 'fail', content: `${fileItem['name']}上传失败：${msg}` })
          }
        )
      })
      if (uploadNum >= files.length) {
        Toast.clear()
      }
    }
  }

  deleteFile(file) {
    const {
      onChange
    } = this.props
    const {
      _files
    } = this.state

    let newFiles = []
    _files.find(item => {
      if (item['name'] !== file['name'] && item['url'] !== file['url']) {
        newFiles.push(item)
      }
    })
    this.setState({ _files: newFiles }, () => onChange && onChange(newFiles))
  }

  render() {
    const {
      accept = 'image/*,.txt,.pdf,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      multiple = false,
    } = this.props
    const {
      _files
    } = this.state
    
    return (
      <div className="upload-file-box">
        <div className="upload-file-component" style={{ display: 'none' }}>
          {
            multiple ? (
              <input ref={this.inputFileRef}
                type="file"
                name="files"
                multiple
                accept={accept}
                onChange={e => {
                  const newFiles = e.target && e.target.files ? Array.from(e.target.files) : []
                  this.uploadFile(newFiles)
                }}
              />
            ) : (
              <input ref={this.inputFileRef}
                type="file"
                name="files"
                accept={accept}
                onChange={e => {
                  const newFiles = e.target && e.target.files ? Array.from(e.target.files) : []
                  this.uploadFile(newFiles)
                }}
              />
            )
          }
        </div>
        <div className="upload-file-component" style={{ padding: 10 }}>
          {
            _files && _.size(_files) > 0 && (
              <div style={{ border: '1px solid #ddd', padding: 10 }}>
                {
                  _files.map((item, index) => <div style={{ marginTop: index > 0 ? '10px' : '0px', color: '#333' }}><Space block align="center" justify="between"><span>{item['name']}</span><DeleteOutline fontSize={16} style={{ color: 'red' }} onClick={() => this.deleteFile(item)} /></Space></div>)
                }
              </div>
            )
          }
          <div onClick={() => this.inputFileRef?.current?.click()}>
            <UploadOutline fontSize={32} style={{ padding: '10px 0', color: '#1890FF' }} />
          </div>
        </div>
      </div>
    )
  }
}


/**
 * 保存附件
 * @param {*} params 
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
export function UploadAttach(file, successCallback, errorCallback) {

  const url = "https://smartx.ceccapitalgroup.com/cec-sso-server/file/uploadfile"
  const formData = new FormData()
  formData.append('file', file);
  formData.append('filetype', 'smartx_file');

  const profile = localStorage.getItem("profile")
  let accessToken = null
  if (profile) {
    try {
      const profileJSON = JSON.parse(profile)
      accessToken = profileJSON['access_token']
    } catch (error) { }
  }

  fetch(url,
    {
      method: 'POST',
      headers: {
        "access_token": accessToken,
      },
      body: formData,
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.code == "C_200") {
        successCallback({ url: _.get(response, "data.url"), name: _.get(response, "data.name") })
      } else {
        errorCallback()
      }
    }).catch(() => errorCallback())

}