import React, { createRef, Fragment } from "react";
import _ from 'lodash';
import moment from 'moment';
import BaseExtractPopup from "../../../../../../components/BaseExtractPopup";
import CECCalendarPopup from "../../../../../../components/CalendarPopup";
import CECSelectPopup from "../../../../../../components/SelectPopup";
import CECTextAreaPopup from "../../../../../../components/TextAreaPopup";
import Required from "../../../../../../components/Required";
import UploadFile from "../../../../../../components/UploadFile";
import CECSelectorListItem from "../../../../../../components/SelectorListItem";
import {
    ListItemExtraPopoverMenu,
    INVESTOR_USERS_OPTIONS, INVESTOR_OPTIONS,
} from "../../../../../../components/FormsUtils";
import { List, Button, Input, Toast } from 'antd-mobile';
import '../CreateDeal/index.css'

const FORM_LABE_OPTIONS = [
    //收入/利润
    { label: "项目Code", key: "el_project_code", type: 'string' },
    { label: "公司全称", key: "el_company", type: 'options' },
    { label: "合同条款", key: "el_clause", type: 'options' },
    { label: "是否独家", key: "el_is_sole", type: 'options' },
    { label: "是否独家备注", key: "el_is_sole_des", type: 'string' },
    { label: "开始日期", key: "el_start_date", type: 'date' },
    { label: "到期日期", key: "el_end_date", type: 'date' },
    { label: "长尾期", key: "el_long_date", type: 'string' },
    { label: "延期条款", key: "el_extension_clause", type: 'options' },
    { label: "说明", key: "el_explain", type: 'string' },
    { label: "利益冲突", key: "el_conflict_of_interest", type: 'options' },
    { label: "涉及利益冲突排除公司", key: "el_conflict_of_interest_des", type: 'options' },
    { label: "上传附件", key: "el_files", type: 'options' },
]
const FORM_LABEL_MAP = _.chain(FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()


/**
 * 【项目更新：执行信息 - EL】
 */
export default class ELPopup extends BaseExtractPopup {
    constructor(props) {
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: true,
        }
        this.options_ref = createRef()
        this.text_area_ref = createRef()
        this.dateRef = createRef()
        this.ownershipStructurePopupRef = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch (key) {
            case "onStateChange":
                this.setState({
                    _info: { ..._info, [data]: event }
                })
                break
            case 'openSelect':
                Toast.show({ content: "DEMO, 临时数据...." })
                let _tempOptions = [], _tempMultiple = true
                switch (data) {
                    case 'el_company':
                        _tempOptions = INVESTOR_OPTIONS
                        _tempMultiple = false
                        break
                    // case 'BDInvestor_type':
                    //     _tempOptions = INVESTOR_TYPE_OPTIONS
                    //     break
                    // case 'BDInvestor_users':
                    //     _tempOptions = INVESTOR_USERS_OPTIONS
                    //     _tempMultiple = true
                    //     break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, () => this.options_ref?.current?.toggle())
                break
            case 'openTextArea':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.text_area_ref?.current?.toggle())
                break
            case 'openDate':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.dateRef?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info, _multiple, _onStateChangeKey, _options } = this.state
        return (
            <Fragment>
                <List className="cec-smartx-create-deal-gourp-list" style={{ '--border-top': '1px solid #eee', '--border-bottom': '1px solid #eee' }}>
                    <List.Item
                        key={`el_project_code`}
                        extra={
                            <Input
                                placeholder="请输入"
                                value={_info['el_project_code']}
                                onChange={val => this.handleClick('onStateChange', 'el_project_code', val)}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['el_project_code']}<Required />
                    </List.Item>

                    <List.Item
                        key={`el_company`}
                        extra={
                            _info['el_company'] ? _.join(_.map(_info['el_company'], 'label')) : ""
                        }
                        onClick={() => this.handleClick('openSelect', 'el_company')}
                    >
                        {FORM_LABEL_MAP['el_company']}<Required />
                    </List.Item>


                    <CECSelectorListItem label={FORM_LABEL_MAP['el_clause']}
                        isRequired={true}
                        options={[
                            { label: "常规条款", value: "常规条款" }, { label: "非常规条款", value: "非常规条款" }
                        ]}
                        value={_info['el_clause'] ? _.map(_info['el_clause'], 'value') : []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'el_clause', options)}
                    />

                    <CECSelectorListItem label={FORM_LABEL_MAP['el_is_sole']}
                        isRequired={true}
                        options={[
                            { label: "是", value: "是" }, { label: "否", value: "否" }
                        ]}
                        value={_info['el_is_sole'] ? _.map(_info['el_is_sole'], 'value') : []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'el_is_sole', options)}
                    />
                    {
                        _.isEqual("是", _.head(_.map(_info['el_is_sole'], 'value'))) && (
                            <List.Item
                                key={`el_is_sole_des`}
                                extra={_info['el_is_sole_des'] ? _info['el_is_sole_des'] : '请选择'}
                                onClick={() => this.handleClick('openTextArea', 'el_is_sole_des')}
                            >
                                {FORM_LABEL_MAP['el_is_sole_des']}
                            </List.Item>
                        )
                    }
                    <List.Item
                        key={`el_start_date`}
                        extra={_info['el_start_date'] ? moment(_info['el_start_date']).format('YYYY-MM-DD') : '请选择'}
                        onClick={() => this.handleClick('openDate', 'el_start_date')}
                    >
                        {FORM_LABEL_MAP['el_start_date']}<Required />
                    </List.Item>

                    <List.Item
                        key={`el_end_date`}
                        extra={_info['el_end_date'] ? moment(_info['el_end_date']).format('YYYY-MM-DD') : '请选择'}
                        onClick={() => this.handleClick('openDate', 'el_end_date')}
                    >
                        {FORM_LABEL_MAP['el_end_date']}<Required />
                    </List.Item>

                    <List.Item
                        key={`el_long_date`}
                        extra={
                            <Input
                                placeholder="请输入"
                                value={_info['el_long_date']}
                                onChange={val => this.handleClick('onStateChange', 'el_long_date', val)}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['el_long_date']}
                    </List.Item>

                    <CECSelectorListItem label={FORM_LABEL_MAP['el_extension_clause']}
                        isRequired={true}
                        options={[
                            { label: "有", value: "有" }, { label: "无", value: "无" }
                        ]}
                        value={_info['el_extension_clause'] ? _.map(_info['el_extension_clause'], 'value') : []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'el_extension_clause', options)}
                    />

                    <List.Item
                        key={`el_explain`}
                        extra={
                            _info['el_explain'] ? _info['el_explain'] : ""
                        }
                        onClick={() => this.handleClick('openTextArea', 'el_explain')}
                    >
                        {FORM_LABEL_MAP['el_explain']}<Required />
                    </List.Item>

                    <CECSelectorListItem label={FORM_LABEL_MAP['el_conflict_of_interest']}
                        isRequired={true}
                        options={[
                            { label: "有", value: "有" }, { label: "无", value: "无" }
                        ]}
                        value={_info['el_conflict_of_interest'] ? _.map(_info['el_conflict_of_interest'], 'value') : []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'el_conflict_of_interest', options)}
                    />

                    {
                        _.isEqual("有", _.head(_.map(_info['el_conflict_of_interest'], 'value'))) && (
                            <List.Item
                                key={`el_conflict_of_interest_des`}
                                extra={
                                    _.size(_info['el_conflict_of_interest_des']) > 0 ?
                                        <ListItemExtraPopoverMenu
                                            onDelete={() => this.handleClick('onStateChange', 'el_conflict_of_interest_des', [])}
                                            onEdit={() => this.ownershipStructurePopupRef?.current?.toggle()}
                                        /> : ""
                                }
                                onClick={() => this.ownershipStructurePopupRef?.current?.toggle()}
                            >
                                {FORM_LABEL_MAP['el_conflict_of_interest_des']}<Required />
                            </List.Item>
                        )
                    }


                    <List.Item
                        key={`el_files`}
                        description={
                            <UploadFile
                                multiple={false}
                                values={_info['el_files']}
                                onChange={(values) => {
                                    this.handleClick("onStateChange", 'el_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                                }}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['el_files']}<Required />
                    </List.Item>

                </List>

                <CECCalendarPopup ref={this.dateRef}
                    value={moment().toDate()}
                    metadata={{ title: _.get(FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />

                <CECSelectPopup
                    ref={this.options_ref}
                    supportSearch={true}
                    multiple={_multiple}
                    title={_.get(FORM_LABEL_MAP, _onStateChangeKey, '')}
                    options={_options}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />


                <CECTextAreaPopup
                    metadata={{ title: _.get(FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    ref={this.text_area_ref}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, _.get(val, 'value', null))} />

                <OwnershipStructurePopup
                    ref={this.ownershipStructurePopupRef}
                    metadata={{ title: FORM_LABEL_MAP['el_conflict_of_interest_des'] }}
                    onConfirm={val => {
                        let list = _.get(_info, 'el_conflict_of_interest_des', [])
                        list.push(val)
                        this.handleClick('onStateChange', 'el_conflict_of_interest_des', list)
                    }}
                />

            </Fragment>

        )
    }

    renderFootContent() {
        const { _info } = this.state
        const { onConfirm } = this.props
        return (
            <Button block color="primary" onClick={() => {
                onConfirm && onConfirm({ ..._info, labelOptions: FORM_LABE_OPTIONS })
                this.close()
            }}>确定</Button>
        )
    }
}



const OS_FORM_LABEL_OPTIONS = [
    { label: "公司工商注册名称", key: "os_name", type: "string" },
    { label: "排他期限开始日期", key: "os_start_date", type: "date" },
    { label: "排他期限截止日期", key: "os_end_date", type: "date" },
]
const OS_FORM_LABEL_MAP = _.chain(OS_FORM_LABEL_OPTIONS).keyBy('key').mapValues('label').value()

class OwnershipStructurePopup extends BaseExtractPopup {
    constructor(props) {
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: true,
        }
        this.dateRef = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch (key) {
            case "onStateChange":
                this.setState({
                    _info: { ..._info, [data]: event }
                })
                break
            case 'openSelect':
                let _tempOptions = [], _tempMultiple = true
                switch (data) {
                    case 'create_project_fee_bd_main':
                    case 'create_project_fee_bd':
                    case 'create_project_clue':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        break
                    // case 'BDInvestor_type':
                    //     _tempOptions = INVESTOR_TYPE_OPTIONS
                    //     break
                    // case 'BDInvestor_users':
                    //     _tempOptions = INVESTOR_USERS_OPTIONS
                    //     _tempMultiple = true
                    //     break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, () => this.options_ref?.current?.toggle())
                break
            case 'openTextArea':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.text_area_ref?.current?.toggle())
                break
            case 'openDate':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.dateRef?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info, _multiple, _onStateChangeKey, _options } = this.state

        return (
            <List>
                <List.Item
                    key={`os_name`}
                    extra={
                        <Input
                            placeholder="请输入"
                            value={_info['os_name']}
                            onChange={val => this.handleClick('onStateChange', 'os_name', val)}
                        />
                    }
                >
                    {OS_FORM_LABEL_MAP['os_name']}<Required />
                </List.Item>
                <List.Item
                    key={`os_start_date`}
                    extra={_info['os_start_date'] ? moment(_info['os_start_date']).format('YYYY-MM-DD') : '请选择'}
                    onClick={() => this.handleClick('openDate', 'os_start_date')}
                >
                    {OS_FORM_LABEL_MAP['os_start_date']}<Required />
                </List.Item>
                <List.Item
                    key={`os_end_date`}
                    extra={_info['os_end_date'] ? moment(_info['os_end_date']).format('YYYY-MM-DD') : '请选择'}
                    onClick={() => this.handleClick('openDate', 'os_end_date')}
                >
                    {OS_FORM_LABEL_MAP['os_end_date']}<Required />
                </List.Item>

                <CECCalendarPopup ref={this.dateRef}
                    value={moment().toDate()}
                    metadata={{ title: _.get(OS_FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />
            </List>
        )
    }

    renderFootContent() {
        const { _info } = this.state
        const { onConfirm } = this.props
        return (
            <Button block color="primary" onClick={() => {
                onConfirm && onConfirm({ ..._info, labelOptions: OS_FORM_LABEL_OPTIONS })
                this.close()
            }}>确定</Button>
        )
    }
}