import React, { Fragment, createRef } from "react";
import { List, Button, Input, Toast } from "antd-mobile";
import BaseExtractValuePopup from "../../BaseExtractValuePopup";
import CECCalendarPopup from "../../../../../../components/CalendarPopup";
import CECSelectorListItem from "../../../../../../components/SelectorListItem";
import DeleteAndSaveButton from "../../../../../../components/CompositeButton";
import ListItemGroup from "../../../../../../components/ListItemGroup";
import _ from 'lodash';
import moment from 'moment';
import {
  IS_FUND_OPTIONS,
  CURRENCY_OPTIONS,
  AMOUNT_UNIT_OPTIONS,
  FetchElementList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 是否有募资需求
 */
export default class CapitalRaisingRequirementPopup extends BaseExtractValuePopup {

  constructor(props) {
    super(props)

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.state = {
      _info: info,
      _fundingScaleOptions: [],
      _fundingPropsOptions: [],
    }
    this.fundPropsRef = createRef()
    this.relationRef = createRef()
    this.scaleRef = createRef()
    this.dateRef = createRef()
  }
  componentDidMount() {
    this.fetchFundingScaleList()
    this.fetchFundingPropsList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  // 目标基金规模
  async fetchFundingScaleList() {
    try {
      const options = await FetchElementList("Lable_smartx_funding_scale")
      this.setState({ _fundingScaleOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 基金属性
  async fetchFundingPropsList() {
    try {
      const options = await FetchElementList("Lable_smartx_funding_attribute")
      this.setState({ _fundingPropsOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _fundingScaleOptions,
      _fundingPropsOptions,
    } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="是否正在考虑设立产业投资基金"
            columns={2}
            options={IS_FUND_OPTIONS}
            value={_info['isFund'] ? _.map(_info['isFund'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'isFund', options)}
          />

          <CECSelectorListItem label="目标基金规模"
            columns={2}
            options={_fundingScaleOptions}
            value={_info['fundScale'] ? _.map(_info['fundScale'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'fundScale', options)}
          />

          <List.Item extra={_info['completeDate'] ? moment(_info['completeDate']).format('YYYY/MM') : "请选择"} onClick={() => this.dateRef?.current?.toggle()}>目标完成募集时间</List.Item>

          <ListItemGroup label="资金缺口">
            <CECSelectorListItem
              value={_info['moneyCurrency'] ? _.map(_info['moneyCurrency'], 'value') : []}
              options={CURRENCY_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, moneyCurrency: options } })}
            >
              {_info['moneyCurrency'] && _.map(_info['moneyCurrency'], 'value').includes("其他") && <Input placeholder="请输入其他币种" value={_info['moneyCurrencyOther']} onChange={val => this.handleChange('change_value', 'moneyCurrencyOther', val)} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              value={_info['moneyUnit'] ? _.map(_info['moneyUnit'], 'value') : []}
              options={AMOUNT_UNIT_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, moneyUnit: options } })}
            >
              <Input type="number" placeholder="请输入金额" value={_info['moneyAmount'] ? _info['moneyAmount'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'moneyAmount')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <CECSelectorListItem label="基金属性"
            columns={2}
            multiple={true}
            options={_fundingPropsOptions}
            value={_info['fundProps'] ? _.map(_info['fundProps'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'fundProps', options)}
          />
        </List>

        <CECCalendarPopup
          precision="month"
          ref={this.dateRef}
          value={_info['completeDate'] ? _info['completeDate'] : moment().toDate()}
          metadata={{ title: "目标完成募集时间" }}
          onConfirm={val => this.handleChange('change_value', 'completeDate', val)}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state
    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}