import React, { Fragment, createRef } from "react";
import BaseExtractPopup from "../BaseExtractPopup";
import {
  Button,
  Input,
  List,
} from 'antd-mobile'
import _ from "lodash";

/**
 * 创建公司/机构/LP 联系人表单
 */
export default class CECTargetUserFormPopup extends BaseExtractPopup {
  constructor(props) {
    super(props);

    this.state = {
      _info: {
        category: props.category,
        targetValue: props.targetValue,
      },
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _info: {
        category: props.category,
        targetValue: props.targetValue,
      }
    })
  }

  handleChange(key, data, event) {
    let {
      _info
    } = this.state

    switch (key) {
      case 'change_input_value':
        this.setState({
          _info: _.assign({}, _info, {
            [data]: event
          })
        })
        break;
      default:
        break;
    }
  }

  renderBodyContent() {
    const { _info } = this.state
    return (
      <List>
        <List.Item><Input placeholder="姓名（必填）" style={{ '--text-align': 'left' }} value={_info['name']} onChange={this.handleChange.bind(this, 'change_input_value', 'name')} /></List.Item>
        <List.Item><Input placeholder="英文名" style={{ '--text-align': 'left' }} value={_info['ENName']} onChange={this.handleChange.bind(this, 'change_input_value', 'ENName')} /></List.Item>
        <List.Item><Input placeholder="职位（必填）" style={{ '--text-align': 'left' }} value={_info['jobLevel']} onChange={this.handleChange.bind(this, 'change_input_value', 'jobLevel')} /></List.Item>
        <List.Item><Input placeholder="电话" style={{ '--text-align': 'left' }} value={_info['mobile']} onChange={this.handleChange.bind(this, 'change_input_value', 'mobile')} /></List.Item>
        <List.Item><Input placeholder="邮箱" style={{ '--text-align': 'left' }} value={_info['email']} onChange={this.handleChange.bind(this, 'change_input_value', 'email')} /></List.Item>
        <List.Item><Input placeholder="微信" style={{ '--text-align': 'left' }} value={_info['wechat']} onChange={this.handleChange.bind(this, 'change_input_value', 'wechat')} /></List.Item>
      </List>
    )
  }

  renderFootContent() {
    const { _info } = this.state
    const {
      onConfirm
    } = this.props
    return (
      <Button block color="primary" onClick={() => onConfirm && onConfirm(_info)}>创建</Button>
    )
  }
}