import { Popover, Button } from "antd-mobile";
import { ExclamationTriangleOutline } from 'antd-mobile-icons';
import { useRef } from "react";


/**
 * 含有删除和保存的组合Button
 * @returns 
 */
export default function DeleteAndSaveButton({ dangerText = '删除', primaryText = '保存', onDelete, onConfirm }) {

  const deletePopoverRef = useRef(null)

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Popover trigger="click"
        destroyOnHide={true}
        ref={deletePopoverRef}
        content={(
          <div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <span><ExclamationTriangleOutline style={{ color: '#ff8f1f', fontSize: 20, marginRight: 5 }} /></span>
              <span>确定删除已保存数据？</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="mini" style={{ marginRight: 5 }} onClick={() => deletePopoverRef?.current?.hide()}>取消</Button>
              <Button size="mini" color="primary" onClick={onDelete}>确定</Button>
            </div>
          </div>
        )}
      >
        <Button block color="danger" style={{ marginRight: 5 }}>{dangerText}</Button>
      </Popover>
      <Button block color="primary" onClick={onConfirm}>{primaryText}</Button>
    </div>
  )
}