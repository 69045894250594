import _ from "lodash";
import moment from "moment";
import request from "../../../../../api/HttpRequest";

/**
 * 交换Code
 * @param {*} params 
 * @returns {
 *  enterpriseCode 企业Code
 *  companyCode 公司Code
 *  gpCode 投资机构Code
 *  lpCode LP合伙人Code
 * }
 */
export async function SwapRelationCode({ code, type }) {
  try {
    const response = await request("POST", "/cec-sims-server/comgplp/simple/info/get", { code: code, type: type })

    let categorys = [], categoryLabels = []
    if (response['iscomp'] === true) {
      categorys.push('company')
      categoryLabels.push("企业(产业方)")
    }
    if (response['isgp'] === true) {
      categorys.push('investment')
      categoryLabels.push("市场化机构")
    }
    if (response['islp'] === true) {
      categorys.push('lp')
      categoryLabels.push("政府/家办及个人/其他")
    }
    return {
      enterpriseCode: _.get(response, "code"),
      companyCode: _.get(response, "main_code_com"),
      gpCode: _.get(response, "main_code_gp"),
      lpCode: _.get(response, "main_code_lp"),
      extensionValue: {
        avatar: _.get(response, "logo"),
        value: _.get(response, "code"),
        label: _.get(response, "name"),
        categorys: categorys,
        categoryLabels: categoryLabels,
        companyCode: _.get(response, "main_code_com"),
        gpCode: _.get(response, "main_code_gp"),
        lpCode: _.get(response, "main_code_lp"),
      },
    }
  } catch (error) {
    throw error
  }
}

/**
 * 获取公司详情 - 基本信息
 * @param {*} code 
 * @returns 
 */
export async function FetchCompanyDetailBaseInfo(code) {
  try {
    const response = await request("POST", "/cec-sims-server/company/detail_more2", { base_comp_code: code })

    let tags = []
    if (_.size(response['tags'])) {
      response['tags'].find(item => {
        tags.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name, id: item.id })
      })
    }
    let types = []
    if (_.size(response['types'])) {
      response['types'].find(item => {
        types.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }
    // 企名片
    let industrys = [], industrySeconds = []
    if (_.size(response['cat_qmp'])) {
      response['cat_qmp'].find(item => {
        let array = []
        if (_.size(item.list)) {
          item.list.find(subItem => {
            industrySeconds.push(subItem.sys_labelelement_name)
            array.push({
              value: subItem.sys_labelelement_code,
              label: subItem.sys_labelelement_name,
            })
          })
        }
        industrys.push({
          value: item.sys_labelelement_code,
          label: item.sys_labelelement_name,
          children: array
        })
      })
    }
    // 易凯行业
    let ecIndustrys = [], ecapitalIndustrySeconds = []
    if (_.size(response['cat_yk'])) {
      response['cat_yk'].find(item => {
        let array = []
        if (_.size(item.list)) {
          item.list.find(subItem => {
            ecapitalIndustrySeconds.push(subItem.sys_labelelement_name)
            array.push({
              value: subItem.sys_labelelement_code,
              label: subItem.sys_labelelement_name,
            })
          })
        }
        ecapitalIndustrySeconds.push(item.sys_labelelement_name)
        ecIndustrys.push({
          value: item.sys_labelelement_code,
          label: item.sys_labelelement_name,
          children: array
        })
      })
    }

    const result = {
      code: response['base_comp_code'],
      name: response['base_comp_name'],
      fullName: response['base_comp_fullname'],
      mergeNames: response['base_comp_merge_names'],
      logo: response['base_comp_img'],
      nation: response['location_name'],
      location: response['com_prov'],
      compScale: response['comp_scale'],
      compBornYear: response['comp_born_year'],
      compBornMonth: response['comp_born_month'],
      tags: tags,
      types: types,
      description: response['base_comp_about'],

      industrys: industrys,
      industrySeconds: industrySeconds,
      ecapitalIndustrys: ecIndustrys,
      ecapitalIndustrySeconds: ecapitalIndustrySeconds,
    }
    return result
  } catch (error) {
    throw error
  }
}

/**
 * 查询公司标签
 * @return {
 *  datas, 
 *  total, 
 *  hasMore
 * }
 */
export async function FetchCompanyTags({ searchKey, pageNum = (pageNum <= 1 ? 1 : pageNum), pageSize = 20 }) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/element/search3", { etype: 'Label_comtag', input: searchKey, pageNum, pageSize, })
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(_.filter(list, { isshow: true }), item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          avatar: null,
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
    return {}
  } catch (error) {
    throw error
  }
}

/**
 * 删除公司标签
 */
export async function DeleteCompanyTag({ companyCode, tagId }) {
  try {
    await request("POST", "/cec-sims-server/company/label/delete", { base_comp_code: companyCode, id: tagId, })
    return Promise.resolve()
  } catch (error) {
    throw error
  }
}

/**
 * 添加公司标签 
 */
export async function AddCompanyTags({ companyCode, tagCodes }) {
  try {
    await request("POST", "/cec-sims-server/company/label/add", { base_comp_code: companyCode, sys_labelelement_codes: _.join(tagCodes), sys_labelelement_type: 'Label_comtag' })
    return Promise.resolve()
  } catch (error) {
    throw error
  }
}

/**
 * 创建公司标签 
 */
export async function AddNewCompanyTag({ companyCode, tagKey, exsitTagCodes }) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/add", { sys_labelelement_name: tagKey })
    if (response) {
      await AddCompanyTags({ companyCode, tagCodes: _.concat(exsitTagCodes, [response['sys_labelelement_code']]) })
    }
    return Promise.resolve()
  } catch (error) {
    throw error
  }
}

/**
 * 获取投资机构详情 - 基本信息
 * @param {*} code 
 * @returns 
 */
export async function FetchGPDetailBaseInfo(code) {
  try {
    const response = await request("POST", "/cec-sims-server/investment/detail_more", { base_investment_code: code })

    let types = []
    if (_.size(response['labelelements'])) {
      response['labelelements'].find(item => {
        types.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }
    let stages = []
    if (_.size(response['stage'])) {
      response['stage'].find(item => {
        stages.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }
    let icInvestmentUsers = []
    if (_.size(response['list_investor_ic'])) {
      response['list_investor_ic'].find(item => {
        icInvestmentUsers.push({ value: item.base_investor_code, label: item.base_investor_name, level: item.base_investor_posiname })
      })
    }
    let activeInvestmentUsers = []
    if (_.size(response['list_investor_active'])) {
      response['list_investor_active'].find(item => {
        activeInvestmentUsers.push({ value: item.base_investor_code, label: item.base_investor_name, level: item.base_investor_posiname })
      })
    }
    let ecapitalUsers = []
    if (_.size(response['ykusers'])) {
      response['ykusers'].find(item => {
        ecapitalUsers.push({ value: item.userid, label: item.username })
      })
    }
    // 企名片
    let industrys = [], industrySeconds = []
    if (_.size(response['directions'])) {
      response['directions'].find(item => {
        let array = []
        if (_.size(item.list)) {
          item.list.find(subItem => {
            industrySeconds.push(subItem.sys_labelelement_name)
            array.push({
              value: subItem.sys_labelelement_code,
              label: subItem.sys_labelelement_name,
            })
          })
        }
        industrys.push({
          value: item.sys_labelelement_code,
          label: item.sys_labelelement_name,
          children: array
        })
      })
    }

    const result = {
      code: response['base_investment_code'],
      name: response['base_investment_name'],
      logo: response['base_investment_img'],
      description: response['base_investment_about'],
      nation: _.get(response, "location.sys_labelelement_name"),
      location: response['location_name2'],
      currencys: response['currency_code'] && response['currency_name'] ? [{ label: response['currency_name'], value: response['currency_code'] }] : [],
      types: types,
      stages: stages,
      groupBaseLocation: response['investment_base'], // 公司集团所在属地
      groupListOn: response['investment_stock_exchange'], // 上市地
      activeInvestmentUsers: activeInvestmentUsers, //活跃投资人
      icInvestmentUsers: icInvestmentUsers,//投委会成员
      ecapitalUsers: ecapitalUsers,
      industrys: industrys,
    }
    return result
  } catch (error) {
    throw error
  }
}

/**
 * 获取LP详情 - 基本信息
 * @param {*} code 
 * @returns 
 */
const LP_ACTIVE_MAP = {
  "ACTIVE": "活跃",
  "INACTIVE": "不活跃",
  "UNKNOWN": "待定",
}
export async function FetchLPDetailBaseInfo(code) {

  try {
    const response = await request("POST", "/cec-sims-server/lp/detail_more2", { base_lp_code: code })

    let types = []
    if (_.size(response['labels'])) {
      response['labels'].find(item => {
        types.push({ value: item.sys_labelelement_code, label: item.sys_labelelement_name })
      })
    }

    let ecapitalUsers = []
    if (_.size(response['ykusers'])) {
      response['ykusers'].find(item => {
        ecapitalUsers.push({ value: item.userid, label: item.username })
      })
    }

    let industrys = []
    if (_.size(response['labels_industry'])) {
      response['labels_industry'].find(item => {
        let stages = []
        if (item['stages'] && _.size(item['stages']) > 0) {
          item['stages'].find(stage => {
            stages.push({ value: stage['stagecode'], label: stage['stagename'], })
          })
        }

        if (item['pname']) {
          industrys.push({
            industrys: item['pcode'] && item['pname'] ? [{ value: item['pcode'], label: item['pname'], children: [{ value: item['lcode'], label: item['lname'] }] }] : [],
            score: item['flagint'],
            stages: stages
          })
        } else {
          industrys.push({
            industrys: item['lcode'] && item['lname'] ? [{ value: item['lcode'], label: item['lname'] }] : [],
            score: item['flagint'],
            stages: stages
          })
        }
      })
    }

    let specialConditions = []
    if (_.size(response['labels_special_condition'])) {
      response['labels_special_condition'].find((item, index) => {
        specialConditions.push({
          key: `${moment().format("x")}-${index}`,
          specialCondition: [{ label: item['sys_labelelement_name'], value: item['sys_labelelement_code'], selected: true }],
          active: item['flagint'] !== undefined ? [{ value: `${item['flagint']}`, label: item['flagint'] === 1 ? '是' : '否' }] : []
        })
      })
    }

    let manageInvestments = []
    if (response['lpinvestment']) {
      const item = response['lpinvestment']

      let amountValue = item['manage_money'] && item['manage_unit'] ? _.round(_.divide(item['manage_money'], item['manage_unit']), 2) : null,
        amountUnitValue = item['manage_unit'],
        amountUnitLabel = convertAmountUnit(item['manage_unit']),
        currency = item['manage_currency']

      manageInvestments.push({ value: item['base_investment_code'], label: item['base_investment_name'], amountValue: amountValue, amountUnitValue: amountUnitValue, amountUnitLabel: amountUnitLabel, currency: currency, id: item['id'] })
    }

    const result = {
      code: response['base_lp_code'],
      name: response['base_lp_name'],
      types: types,
      address: `${response['province'] ? response['province'] : ""}${response['city'] ? response['city'] : ""}${response['location'] ? response['location'] : ""}${response['address'] ? response['address'] : ""}`,
      logo: null,
      description: response['note'],
      // 易凯覆盖
      coverage: ["CREATE_YK", "UPDATE_YK"].includes(response['base_lp_stem']) ? true : false,
      coverageLabel: ["CREATE_YK", "UPDATE_YK"].includes(response['base_lp_stem']) ? "是" : "否",
      // 是否活跃
      active: response['activeflag'],
      activeLabel: response['activeflag'] ? LP_ACTIVE_MAP[response['activeflag']] : null,
      // 易凯负责人
      ecapitalUsers: ecapitalUsers,
      // 管理机构
      manageInvestments: manageInvestments,
      // 受托管理机构
      receiveInvestments: response['fund_manager_investment_code'] && response['fund_manager_investment_name'] ? [{ value: response['fund_manager_investment_code'], label: response['fund_manager_investment_name'] }] : [],
      // 管理规模
      manageScale: response['manage2_money'] && response['manage2_unit'] ? _.round(_.divide(response['manage2_money'], response['manage2_unit']), 2) : null,
      manageScaleUnit: response['manage2_unit'],
      manageScaleUnitLabel: convertAmountUnit(response['manage2_unit']),
      manageScaleCurrency: response['manage2_currency'],
      // 基金规模
      manageFundScale: response['manage_money'] && response['manage_money'] ? _.round(_.divide(response['manage_money'], response['manage_unit']), 2) : null,
      manageFundScaleUnit: response['manage_unit'],
      manageFundScaleUnitLabel: convertAmountUnit(response['manage_unit']),
      manageFundScaleCurrency: response['manage_currency'],
      // 出资规模
      outScale: response['scale'] && response['scale_unit'] ? _.round(_.divide(response['scale'], response['scale_unit']), 2) : null,
      outScaleUnit: response['scale_unit'],
      outScaleUnitLabel: convertAmountUnit(response['scale_unit']),
      outScaleCurrency: response['base_lp_currency'],
      // 投资倾向
      tendency: response['leanto'] && response['leanto_name'] ? [{ value: response['leanto'], label: response['leanto_name'] }] : [],
      // 已出资
      investment: response['financed_flag'],
      investmentLabel: response['financed_flag'] === true ? "已出资" : "未出资",
      investmentAmount: response['financed_money'] && response['financed_unit'] ? _.round(_.divide(response['financed_money'], response['financed_unit']), 2) : null,
      investmentUnit: response['financed_unit'],
      investmentUnitLabel: convertAmountUnit(response['financed_unit']),
      investmentCurrency: response['financed_currency'],
      // 期限
      term: response['deadline'] && response['deadline_name'] ? [{ value: response['deadline'], label: response['deadline_name'], }] : [],
      // 返投条件
      reinvestmentConditions: response['reverse_condition'],
      // 特殊条件
      specialConditions: specialConditions,
      // 行业/阶段（评分）
      industryAndStages: industrys,
      // 决策流程
      voteProgress: response['note_decision'],
      // 备注
      description: response['note'],
      note: response['note2'],
    }
    return result
  } catch (error) {
    throw error
  }
}

/**
 * 金额单位转换
 * @param {*} unit 
 * @returns 
 */
function convertAmountUnit(unit) {
  let unitValue = unit ? unit.toString() : null
  let unitLabel = null;
  switch (unitValue) {
    case '1000':
      unitLabel = '千元'
      break;
    case '1000000':
      unitLabel = '百万'
      break;
    case '1000000000':
      unitLabel = '十亿元'
      break;
    default:
      break;
  }
  return unitLabel
}

/**
 * 保存LP关联的管理机构信息
 * @param {*} params 
 */
export async function SaveLPManageInvestment(params) {
  const payLoad = {
    id: params['id'],
    base_lp_code: params['lpCode'],
    base_investment_code: params['orgCode'],
    manage_money: params['amountValue'],
    manage_unit: params['unitValue'],
    manage_currency: params['currency'],
  }
  try {
    await request("POST", "/cec-sims-server/lp/imvestment/save", payLoad)
  } catch (error) {
    throw error
  }
}

/**
 * 删除LP关联的管理机构信息
 * @param {*} id 
 */
export async function DeleteLPManageInvestment(id) {

  try {
    await request("POST", "/cec-sims-server/lp/imvestment/delete", { id: id })
  } catch (error) {
    throw error
  }
}