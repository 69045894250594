import React, { createRef, Fragment, PureComponent } from "react";
import _ from "lodash";
import { List } from "antd-mobile";
import Required from "../../../../../../components/Required";

/**
 * 机构/公司等对象携带附件
 */
export default class TargetUploadFile extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _targetCategorys: props.targetCategorys ? props.targetCategorys : ['company', 'investment', 'LP'],
      _target: null,
      _files: [],
    }
  }
  render() {
    const {
      _targetOptions,
      _target,
      _files,
    } = this.state
    const {
      idKey,
      onConfirm
    } = this.props
    return (
      <Fragment>
        <List.Item arrow={false}
          description={
            <div>
              
            </div>
          }
          onClick={() => { this.fetchTargetList(); this.targetSelectPopupRef?.current?.toggle() }}>
          {_target ? _target['label'] : <span>名称<Required /></span>}
        </List.Item>
      </Fragment >
    )
  }
}