import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";

export const STATUS_OPTIONS_VALUE_NORMAL = "normal"
export const STATUS_OPTIONS_VALUE_DISABLED = "disabled"
export const STATUS_OPTIONS_VALUE_PROMOTION = "promotion"
export const STATUS_OPTIONS = [
  { label: "加入", value: "normal" },
  { label: "离职", value: "disabled" },
  { label: "升职", value: "promotion" },
]

/**
 * 搜索公司相关联系人
 * @returns 
 */
export async function FetchCompanyOwnerUserList({ code, searchKey, pageNum = 1, pageSize }) {

  try {
    const response = await request("POST", "/cec-sims-server/company/entrepreneurlist", { base_comp_code: code, comgplpflag: true, input: searchKey ? searchKey : "", pageNum, pageSize })
    const total = response ? _.size(response) : 0 //response['total']
    const list = [...response] //response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          label: `${item['base_entrepreneur_name']}`,
          value: item['id'],
          extension: {
            code: item['base_entrepreneur_code'],
            job: item['base_entrepreneur_posiname'],
            phone: item['base_entrepreneur_phone'],
            email: item['base_entrepreneur_email'],
          },
          titleExtension: (
            <span style={{ color: '#999', marginLeft: 5 }}>{item['base_entrepreneur_posiname']}</span>
          ),
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}


/**
 * 搜索机构相关联系人
 * @returns 
 */
export async function FetchGPOwnerUserList({ code, searchKey, pageNum = 1, pageSize }) {

  try {
    const response = await request("POST", "/cec-sims-server/investor/search2", { base_investment_code: code, comgplpflag: true, input: searchKey ? searchKey : "", pageNum, pageSize })
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          label: `${item['base_investor_name']}`,
          value: item['id'],
          extension: {
            code: item['base_investor_code'],
            job: item['base_investor_posiname'],
            phone: item['base_investor_phone'],
            email: item['base_investor_email'],
          },
          titleExtension: (
            <span style={{ color: '#999', marginLeft: 5 }}>{item['base_investor_posiname']}</span>
          ),
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}
/**
 * 搜索LP相关联系人
 * @returns 
 */
export async function FetchLPOwnerUserList({ code, searchKey, pageNum = 1, pageSize }) {

  try {
    const response = await request("POST", "/cec-sims-server/lp/lpuserlist", { base_lp_code: code, comgplpflag: true, input: searchKey ? searchKey : "", pageNum, pageSize })
    const total = response ? _.size(response) : 0 //response['total']
    const list = [...response] //response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          label: `${item['base_lpuser_name']}`,
          value: item['id'],
          extension: {
            code: item['base_lpuser_code'],
            job: item['base_lpuser_posiname'],
            phone: item['base_lpuser_phone'],
            email: item['base_lpuser_email'],
          },
          titleExtension: (
            <span style={{ color: '#999', marginLeft: 5 }}>{item['base_lpuser_posiname']}</span>
          ),
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 保存 人事更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  let userCode = info['userCode']
  let id = info['id']
  let name = info['name']
  if (_.isArray(info['name'])) {
    userCode = _.join(_.map(info['name'], "extension.code"))
    id = _.join(_.map(info['name'], "value"))
    name = _.join(_.map(info['name'], "label"))
  }

  const status = info['status'] ? _.join(_.map(info['status'], 'value')) : null
  let employeeCode = null, employeeLabel = null, employeeType = null
  let job = info['job'], jobBefore = null
  if (['normal'].includes(status)) {
    job = info['job']

    const historyEmployee = info['historyEmployee'] ? _.head(info['historyEmployee']) : null
    if (historyEmployee) {
      // 获取其中一个身份即可
      const category = _.head(historyEmployee['categorys'])
      employeeCode = _.get(historyEmployee, `${category}Code`)
      employeeLabel = historyEmployee['label']
      employeeType = category
    }
  } else if (['disabled'].includes(status)) {
    job = info['job']

    const newEmployee = info['newEmployee'] ? _.head(info['newEmployee']) : null
    if (newEmployee) {
      // 获取其中一个身份即可
      const category = _.head(newEmployee['categorys'])
      employeeCode = _.get(newEmployee, `${category}Code`)
      employeeLabel = newEmployee['label']
      employeeType = category
    }
  } else if (['promotion'].includes(status)) {
    job = info['jobNew']
    jobBefore = info['job']
  }

  let payload = {}
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/company/saveentrepreneur"
    payload = {
      id: id,
      base_entrepreneur_code: userCode,
      base_comp_code: info['code'],
      base_entrepreneur_name: name,
      base_entrepreneur_phone: info['phone'],
      base_entrepreneur_email: info['email'],
      base_entrepreneur_posiname: job,
      base_entrepreneur_state: status,
      beforenext_code: employeeCode,
      beforenext_name: employeeLabel,
      beforenext_type: employeeType,
      before_entrepreneur_posiname: jobBefore,
      showflag: info['id'] ? true : false,
    }
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/investor/save"
    payload = {
      id: id,
      base_investor_code: userCode,
      base_investment_code: info['code'],
      base_investor_name: name,
      base_investor_phone: info['phone'],
      base_investor_email: info['email'],
      base_investor_posiname: job,
      base_investor_state: status,
      beforenext_code: employeeCode,
      beforenext_name: employeeLabel,
      beforenext_type: employeeType,
      before_investor_posiname: jobBefore,
      showflag: info['id'] ? true : false,
    }
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/lp/savereleuser"
    payload = {
      id: id,
      base_lpuser_code: userCode,
      base_lp_code: info['code'],
      base_lpuser_name: name,
      base_lpuser_phone: info['phone'],
      base_lpuser_email: info['email'],
      base_lpuser_posiname: job,
      base_lpuser_state: status,
      beforenext_code: employeeCode,
      beforenext_name: employeeLabel,
      beforenext_type: employeeType,
      before_lpuser_posiname: jobBefore,
      showflag: info['id'] ? true : false,
    }
  }
  try {
    const response = await request("POST", url, payload)
    let resultName = []
    if (info['targetType'] === 'info_enterprise') {// 企业
      userCode = response['base_entrepreneur_code']
      resultName = [{
        label: `${response['base_entrepreneur_name']}`,
        value: response['id'],
        extension: {
          code: response['base_entrepreneur_code'],
          job: response['base_entrepreneur_posiname'],
          phone: response['base_entrepreneur_phone'],
          email: response['base_entrepreneur_email'],
        },
        titleExtension: (
          <span style={{ color: '#999', marginLeft: 5 }}>{response['base_entrepreneur_posiname']}</span>
        ),
      }]
    } else if (info['targetType'] === 'info_market') {// 市场化机构
      userCode = response['base_investor_code']
      resultName = [{
        label: `${response['base_investor_name']}`,
        value: response['id'],
        extension: {
          code: response['base_investor_code'],
          job: response['base_investor_posiname'],
          phone: response['base_investor_phone'],
          email: response['base_investor_email'],
        },
        titleExtension: (
          <span style={{ color: '#999', marginLeft: 5 }}>{response['base_investor_posiname']}</span>
        ),
      }]
    } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
      userCode = response['base_lpuser_code']
      resultName = [{
        label: `${response['base_lpuser_name']}`,
        value: response['id'],
        extension: {
          code: response['base_lpuser_code'],
          job: response['base_lpuser_posiname'],
          phone: response['base_lpuser_phone'],
          email: response['base_lpuser_email'],
        },
        titleExtension: (
          <span style={{ color: '#999', marginLeft: 5 }}>{response['base_lpuser_posiname']}</span>
        ),
      }]
    }
    return { ...info, id: response['id'], userCode, name: resultName }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 人事更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {

  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/company/delentrepreneur"
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/investor/workhistorydelete"
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/lp/delreleuser2"
  }
  try {
    await request("POST", url, { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}