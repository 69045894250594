import _ from "lodash";
import request from "../../../../../../../../api/HttpRequest";


/**
 * 获取交易机会 - 募资需求列表
 */
export async function FetchCapitalRaisingRequirementDataList({ code, pageNum = 1, pageSize = 2 }) {

  const payload = {
    base_comp_code: code,
    types: 'fundraising',
    pageNum: pageNum <= 0 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/trade/chance/list", payload)
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          id: item['base_trade_code'],
          code: item['base_comp_code'],
          round: item['round_name'],
          tradeDate: item['base_trade_date'],

          modifyDate: item['modifyDate'],
          modidyUser: item['updateusername'],
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 获取交易机会 - 募资需求详情
 * @param {*} id 
 */
export async function FetchCapitalRaisingRequirementDataDetail(id) {
  if (!id) {
    return
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/trade/chance/detail", { id: id })
    console.log("FetchFinancingNeedsDataDetail >>>>", response)

  } catch (error) {
    throw error
  }
}