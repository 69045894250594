import React, { Fragment, createRef } from "react";
import _ from "lodash";
import { List, Button, Input, Space, Toast, TextArea } from "antd-mobile";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import ListItemGroup from "../../../../../components/ListItemGroup";
import {
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_OTHER_VALUE,
  AMOUNT_UNIT_OPTIONS,
  SINGLE_INVESTMENT_RANGE_OPTIONS,
  FAMILY_OFFICE_CATEGORY_OPTIONS,
  FetchElementList,
  SaveInfo,
  DeleteInfo,
} from "./transform.js"

/**
 * 家办及个人
 */
export default class FamilyOfficePopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props);

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.state = {
      _info: info,
      _extensionPopupComponents: [],
      _overallTargetScaleOptions: [],
      _establishedScaleOptions: [],
    }
    this.categoryRef = createRef()
  }
  componentDidMount() {
    this.fetchOverallTargetScaleList()
    this.fetchEstablishedScaleList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type, extensionValue } = super.getAttendTargetValue()

    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
    this.extensionValue = extensionValue
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)
    const {
      onSelect
    } = this.props
    const {
      _info
    } = this.state

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  // 总体管理规模
  fetchOverallTargetScaleList = async () => {
    try {
      const options = await FetchElementList("Lable_smartx_funding_scale")
      this.setState({ _overallTargetScaleOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 已到位规模
  fetchEstablishedScaleList = async () => {
    try {
      const options = await FetchElementList("Lable_smartx_funding_scale")
      this.setState({ _establishedScaleOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props

    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _overallTargetScaleOptions,
      _establishedScaleOptions,
    } = this.state
    console.log('office info >>>', _info)

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="性质"
            options={FAMILY_OFFICE_CATEGORY_OPTIONS}
            value={_info['category'] ? _.map(_info['category'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'category', options)}
          />

          <List.Item
            description={
              <TextArea rows={2}
                placeholder="请填写"
                value={_info['mainSourceOfFunds'] ? _info['mainSourceOfFunds'] : ''}
                onChange={this.handleChange.bind(this, 'change_value', 'mainSourceOfFunds')}
              />
            }>主要资金来源</List.Item>

          <ListItemGroup label="总体管理规模">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['overallScaleCurrency'] ? _.map(_info['overallScaleCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'overallScaleCurrency', options)}
            >
              {_info['overallScaleCurrency'] && _.map(_info['overallScaleCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['overallScaleCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'overallScaleCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['overallScaleUnit'] ? _.map(_info['overallScaleUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'overallScaleUnit', options)}
            />

            <CECSelectorListItem
              columns={4}
              options={_overallTargetScaleOptions}
              value={_info['overallScaleOption'] ? _.map(_info['overallScaleOption'], 'value') : null}
              onChange={(value, options) => this.handleChange('change_value', 'overallScaleOption', options)}
            >
              <Input placeholder="请输入具体金额" value={_info['overallScale'] ? _info['overallScale'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'overallScale')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <ListItemGroup label="已到位规模">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['establishedScaleCurrency'] ? _.map(_info['establishedScaleCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'establishedScaleCurrency', options)}
            >
              {_info['establishedScaleCurrency'] && _.map(_info['establishedScaleCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['establishedScaleCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'establishedScaleCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['establishedScaleUnit'] ? _.map(_info['establishedScaleUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'establishedScaleUnit', options)}
            />

            <CECSelectorListItem
              columns={4}
              options={_establishedScaleOptions}
              value={_info['establishedScaleOption'] ? _.map(_info['establishedScaleOption'], 'value') : null}
              onChange={(value, options) => this.handleChange('change_value', 'establishedScaleOption', options)}
            >
              <Input placeholder="请输入具体金额" value={_info['establishedScale'] ? _info['establishedScale'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'establishedScale')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <ListItemGroup label="单笔投资金额">
            <CECSelectorListItem
              options={SINGLE_INVESTMENT_RANGE_OPTIONS}
              value={_info['singleStrokeMoney'] ? _.map(_info['singleStrokeMoney'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleStrokeMoney', options)}
            />

            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['singleStrokeMoneyCurrency'] ? _.map(_info['singleStrokeMoneyCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleStrokeMoneyCurrency', options)}
            >
              {_info['singleStrokeMoneyCurrency'] && _.map(_info['singleStrokeMoneyCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['singleStrokeMoneyCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeMoneyCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['singleStrokeUnit'] ? _.map(_info['singleStrokeUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleStrokeUnit', options)}
            />
            {
              _info['singleStrokeMoney'] && _.map(_info['singleStrokeMoney'], 'value').includes('下限') && (
                <List.Item extra={
                  <Input style={{ width: '100px' }} placeholder="下限" value={_info['singleStrokeBeginMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeBeginMoney')} />
                }>金额（下限）</List.Item>
              )
            }
            {
              _info['singleStrokeMoney'] && _.map(_info['singleStrokeMoney'], 'value').includes('舒适区') && (
                <List.Item extra={
                  <Space>
                    <Input style={{ width: '100px' }} placeholder="起始" value={_info['singleStrokeBeginMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeBeginMoney')} />
                    <span> - </span>
                    <Input style={{ width: '100px' }} placeholder="截止" value={_info['singleStrokeEndMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeEndMoney')} />
                  </Space>
                }>金额（舒适区）</List.Item>
              )
            }
            {
              _info['singleStrokeMoney'] && _.map(_info['singleStrokeMoney'], 'value').includes('上限') && (
                <List.Item extra={
                  <Input style={{ width: '100px' }} placeholder="上限" value={_info['singleStrokeEndMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeEndMoney')} />
                }>金额（上限）</List.Item>
              )
            }
          </ListItemGroup>
          <List.Item
            description={
              <TextArea rows={4}
                placeholder="请填写"
                value={_info['specialRequirement'] ? _info['specialRequirement'] : ''}
                onChange={this.handleChange.bind(this, 'change_value', 'specialRequirement')}
              />
            }>特殊喜好</List.Item>
        </List>

      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}