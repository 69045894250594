import React, { PureComponent, createRef } from "react";
import CECPopup from "../Popup";

export default class BaseExtractPopup extends PureComponent {

  constructor(props) {
    super(props)
    this.basePopupRef = createRef()
    this.overflow = false
  }

  show() {
    this.basePopupRef.current.show();
  }

  close() {
    this.basePopupRef.current.close();
  }

  toggle() {
    this.basePopupRef.current.toggle();
  }

  getDestroyOnClose() {
    return false
  }

  renderBodyContent() {
    throw new Error("renderBodyContent() must be implemented by subclasses");
  }

  renderFootContent() {

  }

  renderNavRight() {

  }

  render() {
    const {
      metadata,
    } = this.props
    const {
      title
    } = metadata

    return (
      <CECPopup
        overflow={this.overflow}
        ref={this.basePopupRef}
        navTitle={title ? title : null}
        navRight={this.renderNavRight()}
        destroyOnClose={this.getDestroyOnClose()}
        onNavBack={this.close.bind(this)}
        foot={this.renderFootContent()}>
        {
          this.renderBodyContent()
        }
      </CECPopup>
    )
  }
}