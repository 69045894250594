import React, { Fragment, createRef } from "react";
import _ from 'lodash';
import { List, Button, Input, Space, TextArea, Toast, Ellipsis } from "antd-mobile";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import CECSelectPopup from "../../../../../components/SelectPopup";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import CECSelectIndustryPopup from "../../../../../components/SelectIndustryPopup";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import ListItemGroup from "../../../../../components/ListItemGroup";
import {
  FetchTargetList
} from "../../transform"
import {
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_OTHER_VALUE,
  AMOUNT_UNIT_OPTIONS,
  SINGLE_INVESTMENT_RANGE_OPTIONS,
  ConvertIndustryToLabels,
  FetchRegion,
  FetchElementList,
  FetchElementLevelList,
  SaveInfo,
  DeleteInfo,
} from "./transform"

/**
 * 政府
 */
export default class GovernmentPopup extends BaseExtractValuePopup {

  constructor(props) {
    super(props);

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.state = {
      _info: info,
      _areaOptions: [],
      _ecIndustryOptions: [],
      _investmentDirectionOptions: [],
      _overallTargetScaleOptions: [],
      _establishedScaleOptions: [],
      _ecapitalCooperationOptions: [],
    }
    this.governmentRef = createRef();
    this.invesmentPortalRef = createRef();
    this.specificRequirementRef = createRef();
    this.preferentialPoliciesRef = createRef();
    this.ecapitalCooperationRef = createRef();
    this.ecIndustrySelectPopupRef = createRef();
  }
  componentDidMount() {
    this.fetchRegion()
    this.fetchIndustryList()
    this.fetchInvestmentDirectionList()
    this.fetchOverallTargetScaleList()
    this.fetchEstablishedScaleList()
    this.fetchEcapitalCooperationList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()

    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)
    const {
      _info
    } = this.state

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  fetchRegion = async () => {
    try {
      const datas = await FetchRegion(0)
      this.setState({ _areaOptions: datas })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 目标产业
  async fetchIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 投资方向
  fetchInvestmentDirectionList = async () => {
    try {
      const options = await FetchElementList("Lable_smartx_invest_stage")
      this.setState({ _investmentDirectionOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 总体管理规模
  fetchOverallTargetScaleList = async () => {
    try {
      const options = await FetchElementList("Lable_smartx_funding_scale")
      this.setState({ _overallTargetScaleOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 已到位规模
  fetchEstablishedScaleList = async () => {
    try {
      const options = await FetchElementList("Lable_smartx_funding_scale")
      this.setState({ _establishedScaleOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 易凯潜在合作领域
  fetchEcapitalCooperationList = async () => {
    try {
      const options = await FetchElementList("Lable_smartx_yk_cooperation")
      this.setState({ _ecapitalCooperationOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props

    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _areaOptions,
      _ecIndustryOptions,
      _investmentDirectionOptions,
      _overallTargetScaleOptions,
      _establishedScaleOptions,
      _ecapitalCooperationOptions,
    } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="区域"
            multiple={true}
            options={_areaOptions}
            value={_info['areas'] ? _.map(_info['areas'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'areas', options)}
          />

          <List.Item
            extra={_info['mainContributions'] ? _.join(_.map(_info['mainContributions'], 'label'), '，') : "请选择"}
            onClick={() => this.invesmentPortalRef?.current?.toggle()}>主要出资平台或投资载体</List.Item>

          <List.Item
            description={
              <TextArea rows={2}
                placeholder="请填写"
                value={_info['mainSourceOfFunds'] ? _info['mainSourceOfFunds'] : ''}
                onChange={value => this.handleChange('change_value', 'mainSourceOfFunds', value)}
              />
            }>主要资金来源</List.Item>

          <ListItemGroup label="总体管理规模">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['overallTargetScaleCurrency'] ? _.map(_info['overallTargetScaleCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'overallTargetScaleCurrency', options)}
            >
              {_info['overallTargetScaleCurrency'] && _.map(_info['overallTargetScaleCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['overallTargetScaleCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'overallTargetScaleCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              isRequired={false}
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['overallTargetScaleUnit'] ? _.map(_info['overallTargetScaleUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'overallTargetScaleUnit', options)}
            />

            <CECSelectorListItem
              columns={4}
              options={_overallTargetScaleOptions}
              value={_info['overallTargetScaleOption'] ? _.map(_info['overallTargetScaleOption'], 'value') : null}
              onChange={(value, options) => this.handleChange('change_value', 'overallTargetScaleOption', options)}
            >
              <Input placeholder="请输入具体金额" value={_info['overallTargetScale'] ? _info['overallTargetScale'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'overallTargetScale')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <ListItemGroup label="已到位规模">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['establishedScaleCurrency'] ? _.map(_info['establishedScaleCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'establishedScaleCurrency', options)}
            >
              {_info['establishedScaleCurrency'] && _.map(_info['establishedScaleCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['establishedScaleCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'establishedScaleCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['establishedScaleUnit'] ? _.map(_info['establishedScaleUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'establishedScaleUnit', options)}
            />

            <CECSelectorListItem
              columns={4}
              options={_establishedScaleOptions}
              value={_info['establishedScaleOption'] ? _.map(_info['establishedScaleOption'], 'value') : null}
              onChange={(value, options) => this.handleChange('change_value', 'establishedScaleOption', options)}
            >
              <Input placeholder="请输入具体金额" value={_info['establishedScale'] ? _info['establishedScale'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'establishedScale')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <ListItemGroup label="单笔投资金额">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['singleStrokeMoneyCurrency'] ? _.map(_info['singleStrokeMoneyCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleStrokeMoneyCurrency', options)}
            >
              {_info['singleStrokeMoneyCurrency'] && _.map(_info['singleStrokeMoneyCurrency'], 'value').includes("其他") && <Input placeholder="请输入其他币种" value={_info['singleStrokeMoneyCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeMoneyCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={SINGLE_INVESTMENT_RANGE_OPTIONS}
              value={_info['singleStrokeMoney'] ? _.map(_info['singleStrokeMoney'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleStrokeMoney', options)}
            />

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['singleStrokeUnit'] ? _.map(_info['singleStrokeUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'singleStrokeUnit', options)}
            />
            {
              _info['singleStrokeMoney'] && _.map(_info['singleStrokeMoney'], 'value').includes('下限') && (
                <List.Item extra={
                  <Input style={{ width: '100px' }} placeholder="下限" value={_info['singleStrokeBeginMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeBeginMoney')} />
                }>金额（下限）</List.Item>
              )
            }
            {
              _info['singleStrokeMoney'] && _.map(_info['singleStrokeMoney'], 'value').includes('舒适区') && (
                <List.Item extra={
                  <Space>
                    <Input style={{ width: '100px' }} placeholder="起始" value={_info['singleStrokeBeginMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeBeginMoney')} />
                    <span> - </span>
                    <Input style={{ width: '100px' }} placeholder="截止" value={_info['singleStrokeEndMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeEndMoney')} />
                  </Space>
                }>金额（舒适区）</List.Item>
              )
            }
            {
              _info['singleStrokeMoney'] && _.map(_info['singleStrokeMoney'], 'value').includes('上限') && (
                <List.Item extra={
                  <Input style={{ width: '100px' }} placeholder="上限" value={_info['singleStrokeEndMoney']} onChange={this.handleChange.bind(this, 'change_value', 'singleStrokeEndMoney')} />
                }>金额（上限）</List.Item>
              )
            }
          </ListItemGroup>

          <List.Item extra={<Ellipsis content={_info['industry'] && _.size(_info['industry']) ? ConvertIndustryToLabels(_info['industry']) : "请选择"} />} onClick={() => this.ecIndustrySelectPopupRef?.current?.show()}>投资方向（目标产业）</List.Item>

          <CECSelectorListItem label="投资方向（目标阶段）"
            options={_investmentDirectionOptions}
            value={_info['investmentDirection'] ? _.map(_info['investmentDirection'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'investmentDirection', options)}
          />

          <List.Item
            description={
              <TextArea rows={4}
                placeholder="请填写"
                value={_info['specialPreferences'] ? _info['specialPreferences'] : ''}
                onChange={this.handleChange.bind(this, 'change_value', 'specialPreferences')}
              />
            }>特殊喜好</List.Item>

          <List.Item
            description={
              <TextArea rows={4}
                placeholder="请填写"
                value={_info['specificRequirementText']}
                onChange={this.handleChange.bind(this, 'change_value', 'specificRequirementText')} />
            }>特别诉求</List.Item>

          <List.Item
            description={
              <TextArea rows={4}
                placeholder="请填写"
                value={_info['favouredPolicy'] ? _info['favouredPolicy'] : ''}
                onChange={this.handleChange.bind(this, 'change_value', 'favouredPolicy')}
              />
            }>优惠政策</List.Item>

          <CECSelectorListItem label="易凯潜在合作领域"
            columns={2}
            multiple={true}
            options={_ecapitalCooperationOptions}
            value={_info['ecapitalCooperation'] ? _.map(_info['ecapitalCooperation'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'ecapitalCooperation', options)}
          >
            {
              _info['ecapitalCooperation'] && _.map(_info['ecapitalCooperation'], 'label').includes("其他咨询") && (
                <List.Item>
                  <TextArea placeholder="请输入" value={_info['ecapitalCooperationText']} onChange={this.handleChange.bind(this, 'change_value', 'ecapitalCooperationText')} />
                </List.Item>
              )
            }
          </CECSelectorListItem>
        </List>

        <CECSelectPopup ref={this.invesmentPortalRef}
          title="主要出资平台或投资载体"
          supportSearch={true}
          supportAvatar={true}
          multiple={false}
          onFetchDatas={(params) => FetchTargetList({ ...params, types: ['company'] })}
          values={_info['mainContributions'] ? _info['mainContributions'] : []}
          onConfirm={this.handleChange.bind(this, 'change_value', 'mainContributions')}
        />

        <CECSelectIndustryPopup
          ref={this.ecIndustrySelectPopupRef}
          title="投资方向（目标产业）"
          multiple={true}
          options={_ecIndustryOptions}
          values={_info['industry']}
          onConfirm={options => this.handleChange('change_value', 'industry', options)}
        />

      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}