import React, { createRef, Fragment } from "react";
import _ from 'lodash';
import BaseExtractPopup from "../../../../../../components/BaseExtractPopup";
import Required from "../../../../../../components/Required";
import UploadFile from "../../../../../../components/UploadFile";
import {
  INVESTOR_OPTIONS,
} from "../../../../../../components/FormsUtils";
import { List, Button, Toast } from 'antd-mobile';
import '../CreateDeal/index.css'
const FORM_LABE_OPTIONS = [
  { label: "Teaser", key: "teaser_files", type: 'options' },
  { label: "BP", key: "BP_files", type: 'options' },
  { label: "Pitchbook", key: "Pitchbook_files", type: 'options' },
  { label: "Info Memo", key: "InfoMemo_files", type: 'options' },
  { label: "管理层演示", key: "Look_files", type: 'options' },


]
const FORM_LABEL_MAP = _.chain(FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()


export class TeaserPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _info: {}
    }

  }

  handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case "onStateChange":
        this.setState({
          _info: { ..._info, [data]: event }
        })
        break
      case 'openSelect':
        Toast.show({ content: "DEMO, 临时数据...." })
        let _tempOptions = [], _tempMultiple = true
        switch (data) {
          case 'el_company':
            _tempOptions = INVESTOR_OPTIONS
            _tempMultiple = false
            break
          // case 'BDInvestor_type':
          //     _tempOptions = INVESTOR_TYPE_OPTIONS
          //     break
          // case 'BDInvestor_users':
          //     _tempOptions = INVESTOR_USERS_OPTIONS
          //     _tempMultiple = true
          //     break
        }
        this.setState({
          _onStateChangeKey: data,
          _options: _tempOptions,
          _multiple: _tempMultiple
        }, () => this.options_ref?.current?.toggle())
        break
      case 'openTextArea':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.text_area_ref?.current?.toggle())
        break
      case 'openDate':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.dateRef?.current?.toggle())
        break
    }
  }

  renderBodyContent() {
    const { _info } = this.state
    return (
      <Fragment>
        <List>
          <List.Item
            key={`teaser_files`}
            description={
              <UploadFile
                multiple={false}
                values={_info['teaser_files']}
                onChange={(values) => {
                  this.handleClick("onStateChange", 'teaser_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                }}
              />
            }
          >
            {FORM_LABEL_MAP['teaser_files']}<Required />
          </List.Item>
        </List>

      </Fragment>
    )
  }

  renderFootContent() {
    const {
      metadata,
      onConfirm
    } = this.props
    const {
      _info
    } = this.state
    return (
      <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
    )
  }
}

export class BPPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _info: {}
    }

  }
  handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case "onStateChange":
        this.setState({
          _info: { ..._info, [data]: event }
        })
        break
      case 'openSelect':
        Toast.show({ content: "DEMO, 临时数据...." })
        let _tempOptions = [], _tempMultiple = true
        switch (data) {
          case 'el_company':
            _tempOptions = INVESTOR_OPTIONS
            _tempMultiple = false
            break
          // case 'BDInvestor_type':
          //     _tempOptions = INVESTOR_TYPE_OPTIONS
          //     break
          // case 'BDInvestor_users':
          //     _tempOptions = INVESTOR_USERS_OPTIONS
          //     _tempMultiple = true
          //     break
        }
        this.setState({
          _onStateChangeKey: data,
          _options: _tempOptions,
          _multiple: _tempMultiple
        }, () => this.options_ref?.current?.toggle())
        break
      case 'openTextArea':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.text_area_ref?.current?.toggle())
        break
      case 'openDate':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.dateRef?.current?.toggle())
        break
    }
  }
  renderBodyContent() {
    const { _info } = this.state
    return (
      <Fragment>
        <List>
          <List.Item
            key={`BP_files`}
            description={
              <UploadFile
                multiple={false}
                values={_info['BP_files']}
                onChange={(values) => {
                  this.handleClick("onStateChange", 'BP_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                }}
              />
            }
          >
            {FORM_LABEL_MAP['BP_files']}<Required />
          </List.Item>
        </List>

      </Fragment>
    )
  }

  renderFootContent() {
    const {
      metadata,
      onConfirm
    } = this.props
    const {
      _info
    } = this.state
    return (
      <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
    )
  }
}

export class PitchbookPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _info: {}
    }
  }
  handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case "onStateChange":
        this.setState({
          _info: { ..._info, [data]: event }
        })
        break
      case 'openSelect':
        Toast.show({ content: "DEMO, 临时数据...." })
        let _tempOptions = [], _tempMultiple = true
        switch (data) {
          case 'el_company':
            _tempOptions = INVESTOR_OPTIONS
            _tempMultiple = false
            break
          // case 'BDInvestor_type':
          //     _tempOptions = INVESTOR_TYPE_OPTIONS
          //     break
          // case 'BDInvestor_users':
          //     _tempOptions = INVESTOR_USERS_OPTIONS
          //     _tempMultiple = true
          //     break
        }
        this.setState({
          _onStateChangeKey: data,
          _options: _tempOptions,
          _multiple: _tempMultiple
        }, () => this.options_ref?.current?.toggle())
        break
      case 'openTextArea':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.text_area_ref?.current?.toggle())
        break
      case 'openDate':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.dateRef?.current?.toggle())
        break
    }
  }

  renderBodyContent() {
    const { _info } = this.state
    return (
      <Fragment>
        <List>
          <List.Item
            key={`Pitchbook_files`}
            description={
              <UploadFile
                multiple={false}
                values={_info['Pitchbook_files']}
                onChange={(values) => {
                  this.handleClick("onStateChange", 'Pitchbook_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                }}
              />
            }
          >
            {FORM_LABEL_MAP['Pitchbook_files']}<Required />
          </List.Item>
        </List>

      </Fragment>
    )
  }

  renderFootContent() {
    const {
      metadata,
      onConfirm
    } = this.props
    const {
      _info
    } = this.state
    return (
      <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
    )
  }
}

export class InfoMemoPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _info: {}
    }

  }
  handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case "onStateChange":
        this.setState({
          _info: { ..._info, [data]: event }
        })
        break
      case 'openSelect':
        Toast.show({ content: "DEMO, 临时数据...." })
        let _tempOptions = [], _tempMultiple = true
        switch (data) {
          case 'el_company':
            _tempOptions = INVESTOR_OPTIONS
            _tempMultiple = false
            break
          // case 'BDInvestor_type':
          //     _tempOptions = INVESTOR_TYPE_OPTIONS
          //     break
          // case 'BDInvestor_users':
          //     _tempOptions = INVESTOR_USERS_OPTIONS
          //     _tempMultiple = true
          //     break
        }
        this.setState({
          _onStateChangeKey: data,
          _options: _tempOptions,
          _multiple: _tempMultiple
        }, () => this.options_ref?.current?.toggle())
        break
      case 'openTextArea':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.text_area_ref?.current?.toggle())
        break
      case 'openDate':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.dateRef?.current?.toggle())
        break
    }
  }

  renderBodyContent() {
    const { _info } = this.state
    return (
      <Fragment>
        <List>
          <List.Item
            key={`InfoMemo_files`}
            description={
              <UploadFile
                multiple={false}
                values={_info['InfoMemo_files']}
                onChange={(values) => {
                  this.handleClick("onStateChange", 'InfoMemo_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                }}
              />
            }
          >
            {FORM_LABEL_MAP['InfoMemo_files']}<Required />
          </List.Item>
        </List>

      </Fragment>
    )
  }

  renderFootContent() {
    const {
      metadata,
      onConfirm
    } = this.props
    const {
      _info
    } = this.state
    return (
      <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
    )
  }
}
export class LookPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _info: {}
    }

  }

  handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case "onStateChange":
        this.setState({
          _info: { ..._info, [data]: event }
        })
        break
      case 'openSelect':
        Toast.show({ content: "DEMO, 临时数据...." })
        let _tempOptions = [], _tempMultiple = true
        switch (data) {
          case 'el_company':
            _tempOptions = INVESTOR_OPTIONS
            _tempMultiple = false
            break
          // case 'BDInvestor_type':
          //     _tempOptions = INVESTOR_TYPE_OPTIONS
          //     break
          // case 'BDInvestor_users':
          //     _tempOptions = INVESTOR_USERS_OPTIONS
          //     _tempMultiple = true
          //     break
        }
        this.setState({
          _onStateChangeKey: data,
          _options: _tempOptions,
          _multiple: _tempMultiple
        }, () => this.options_ref?.current?.toggle())
        break
      case 'openTextArea':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.text_area_ref?.current?.toggle())
        break
      case 'openDate':
        this.setState({
          _onStateChangeKey: data,
        }, () => this.dateRef?.current?.toggle())
        break
    }
  }

  renderBodyContent() {
    const { _info } = this.state
    return (
      <Fragment>
        <List>
          <List.Item
            key={`Look_files`}
            description={
              <UploadFile
                multiple={false}
                values={_info['Look_files']}
                onChange={(values) => {
                  this.handleClick("onStateChange", 'Look_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                }}
              />
            }
          >
            {FORM_LABEL_MAP['Look_files']}<Required />
          </List.Item>
        </List>

      </Fragment>
    )
  }

  renderFootContent() {
    const {
      metadata,
      onConfirm
    } = this.props
    const {
      _info
    } = this.state
    return (
      <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
    )
  }
}