import axios from "axios";
import _ from "lodash";
import { getCurrentRuntimeUrlConfig } from './HttpRequestConfig';
import { Toast } from "antd-mobile";

let env = "dev";
if ('undefined' != (typeof process.env.REACT_APP_ENV_)) {
  env = process.env.REACT_APP_ENV_;
}
console.log('Current ENV =>', env);

// if ('uat' === env) {
//   axios.defaults.baseURL = "http://tneos.ceccapitalgroup.com/";
// } else if ("prd" === env) {
//   axios.defaults.baseURL = "http://neos.ceccapitalgroup.com/";
// }
axios.defaults.retry = 0;
axios.defaults.timeout = 1000 * 10;
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

axios.interceptors.request.use(
  (config) => {
    return requestHeaderRequireSetting(config)
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    // console.log('axios response success =>', JSON.stringify(response))
    if (response && response.data && response.data.code) {
      if (response.data.code === "C_401") {
        setTimeout(() => window.location.href = "/", 10)
      } else if (["W_402", "W_403", "W_404"].includes(response.data.code)) {
        Toast.show({ content: '正在登录认证...' })
        // setTimeout(() => window.location.href = `${requestUrlConfig['hostSSO']}/#/callback/${requestUrlConfig['hostSmartX']}/#/auth/`, 10)
        setTimeout(() => window.location.href = `${requestUrlConfig['hostSSO']}/#/agentcallback/${requestUrlConfig['oAuthAgentId']}/${requestUrlConfig['hostSmartX']}/#/auth/&${window.location.href}`);
      } else if (response.data.code !== "C_200") {
        return Promise.reject(_.get(response, "data.msg", "系统错误"))
      }
    }
    return response !== undefined && response.data !== undefined ? _.get(response, "data.data") : null;
  },
  (error) => {
    // console.log("axios response error =>", error);
    // return { code: error.code, msg: error.message }
    return Promise.reject(error.message)
  }
)

/**
 * 请求头设置
 * @param {*} config 
 * @returns 
 */
function requestHeaderRequireSetting(config) {

  // Config BaseUrl 
  if (env === 'dev') {
    if (config['url'].indexOf('cec-sso-server') > 0) {
      config['baseURL'] = requestUrlConfig['hostSSO']
    } else if (config['url'].indexOf('cec-sims-server') > 0) {
      config['baseURL'] = requestUrlConfig['hostNeos']
    }
  } else {
    config['baseURL'] = requestUrlConfig['hostSmartX']
  }

  // Config AccessToken
  const profileStr = localStorage.getItem('profile')
  let accessToken = null
  if (profileStr) {
    const profile = JSON.parse(profileStr)
    accessToken = profile['access_token']
  }
  config.headers['access_token'] = accessToken

  // console.log('axios request headers =>', config)
  return config
}

function get(url, params, headers) {
  if (params == null && headers == null) {
    return axios.get(url);
  } else if (params != null && headers == null) {
    return axios.get(url, { params: params })
  } else if (params == null && headers != null) {
    return axios.get(url, { headers: headers })
  } else {
    return axios.get(url, { params: params, headers: headers })
  }
}

function post(url, params, headers) {
  if (params == null && headers == null) {
    return axios.post(url);
  } else if (params != null && headers == null) {
    return axios.post(url, params);
  } else if (params == null && headers != null) {
    return axios.post(url, {}, { headers: headers });
  } else {
    return axios.post(url, params, { headers: headers });
  }
}
/**
 * 获取认证信息
 * @param {*} key 
 * @returns 
 */
export function getProfile(key) {
  const profileStr = localStorage.getItem('profile')
  if (!profileStr) {
    return null
  }

  // 按key来获取
  const profile = JSON.parse(profileStr)
  if (key) {
    return profile[key]
  }

  // 返回全部
  let accessToken = null, avatar = null, userName = null
  accessToken = profile['access_token']
  userName = profile['englishname']
  avatar = profile['headpic']

  return {
    userName: userName,
    avatar: avatar,
    accessToken: accessToken,
  }
}

export function requestAddonHeader(type, url, headers = {}, params = {}) {
  switch (type) {
    case 'POST':
      return post(url, params, headers)
    default:
      return get(url, params, headers)
  }
}

export default function request(type, url, params = {}) {
  switch (type) {
    case 'POST':
      return post(url, params, {})
    default:
      return get(url, params, {})
  }
}

export const requestUrlConfig = getCurrentRuntimeUrlConfig(env)