import React, { Fragment, PureComponent, createRef } from "react";
import _ from 'lodash';
import { Button, CheckList, Image, Space, SearchBar, Skeleton, ErrorBlock } from 'antd-mobile'
import { DownOutline } from 'antd-mobile-icons'
import CECPopup from "../Popup";
import CECInfiniteScroll from "../InfiniteScroll";
import './index.css'



export default class CECSelectPopup extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _supportAvatar: props.supportAvatar ? props.supportAvatar : false,
      _multiple: props.multiple ? props.multiple : false,
      _title: props.title,
      _options: props.options ? props.options : [],
      _values: props.values ? props.values : [],

      _searchKey: '',
      _total: 0,
      _pageNum: 0,
      _pageSize: 50,
      _hasMore: true,
    }

    this.cecSelectPopupRef = createRef()
    this.cecSelectActivePopupRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _multiple: props.multiple ? props.multiple : false,
      _title: props.title,
      _options: props.options ? props.options : [],
      _values: props.values ? props.values : [],
    })
  }

  show() {
    this.fetchDatas()
      .then(() => {
        let { _options, _values } = this.state
        if (_options && _.size(_options) > 0 && _values && _.size(_values) > 0) {
          _options = _.map(_options, item => {
            item['selected'] = _.includes(_.map(_values, "value"), item['value']) ? true : false
            return item
          })
          this.setState({ _options: _options })
        }
      })
    this.cecSelectPopupRef.current.show()
  }

  close() {
    this.cecSelectPopupRef.current.close()
  }

  toggle() {
    this.fetchDatas()
      .then(() => {
        let { _options, _values } = this.state
        if (_options && _.size(_options) > 0 && _values && _.size(_values) > 0) {
          _options = _.map(_options, item => {
            item['selected'] = _.includes(_.map(_values, "value"), item['value']) ? true : false
            return item
          })
          this.setState({ _options: _options })
        }
      })
    this.cecSelectPopupRef.current.toggle()
  }

  initSetData() {
    const { onFetchDatas } = this.props
    if (onFetchDatas) {
      this.setState({
        _options: [],
        _pageNum: 0,
        _hasMore: true,
      })
    }
  }

  handleChange(key, data, event) {
    // console.log('handleChange =>', key, data, event)
    let {
      _multiple,
      _options,
      _values,
    } = this.state
    const {
      onFetchDatas,
      onConfirm
    } = this.props

    switch (key) {
      case 'change_select_value':
        _options = _.map(_options, item => {
          if (data.includes(item['value'])) {
            item['selected'] = true
          } else {
            item['selected'] = false
          }
          return item
        })
        var selectedItems = _.filter(_options, { selected: true })
        this.setState({ _options: _options, _values: selectedItems })
        if (!_multiple && _.size(selectedItems) > 0) {
          this.handleChange('handle_confirm_value')
        }
        break;
      case 'handle_confirm_value':
        var confirmSelectedItems = _.filter(_options, { selected: true })
        onConfirm && onConfirm(confirmSelectedItems)
        this.close()
        break;
      case 'handle_toggle_active_popup':
        this.cecSelectActivePopupRef.current?.toggle()
        break;
      default:
        break;
    }
  }

  async fetchDatas(resolve, reject) {
    const { _hasMore } = this.state

    if (!_hasMore) {
      return Promise.resolve();
    }
    const { _searchKey, _options, _values, _pageNum, _pageSize, } = this.state
    const { onFetchDatas } = this.props

    if (onFetchDatas) {
      try {
        let { datas, total, hasMore, } = await onFetchDatas({ searchKey: _searchKey, pageNum: _pageNum, pageSize: _pageSize })
        datas = _.map(datas, item => {
          item['selected'] = _.includes(_.map(_values, "value"), item['value']) ? true : false
          return item
        })
        this.setState({
          _options: _pageNum <= 1 ? datas : _.concat(_options, datas),
          _hasMore: hasMore,
          _total: total,
        }, () => resolve && resolve())
      } catch (error) {
        console.error('Error fetching data:', error);
        reject && reject(error)
      }
    } else {
      this.setState({ _hasMore: false }, () => resolve && resolve())
    }
  }

  fetchDatasMore() {
    return new Promise((resolve, reject) => {
      const { _pageNum } = this.state
      this.setState({ _pageNum: _pageNum + 1 }, () => this.fetchDatas(resolve, reject))
    })
  }

  render() {
    const {
      _supportAvatar,
      _multiple,
      _title,
      _options,
      _values,
      _searchKey,
      _hasMore,
    } = this.state
    const {
      onNavBack,
      navRight,
      supportNotFound,
      supportSearch,
      onFetchDatas,
      afterShow,
    } = this.props

    const values = _.map(_values, "value")
    const options = onFetchDatas ? _options : _.filter(_options, (item) => item['label'].indexOf(_searchKey) > -1)

    const content = (
      <CECPopup ref={this.cecSelectPopupRef}
        navTitle={_title}
        onNavBack={() => onNavBack ? onNavBack() : this.close()}
        navRight={navRight}
        afterShow={afterShow}
        afterClose={this.initSetData.bind(this)}
        foot={
          _multiple ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 1 }}>
                <Button color="default" fill="none" onClick={this.handleChange.bind(this, 'handle_toggle_active_popup')}><DownOutline style={{ marginRight: 10 }} />已选择{_.size(_.filter(_options, { selected: true }))}条</Button>
              </div>
              <div style={{ flex: 1 }}>
                <Button color="primary" block onClick={this.handleChange.bind(this, 'handle_confirm_value')}>确定</Button>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button color="primary" block onClick={this.handleChange.bind(this, 'handle_confirm_value')}>确定</Button>
            </div>
          )
        }>
        <div className="multiple-select-box">
          {
            supportSearch && (
              <div className="multiple-select-search-bar">
                <SearchBar placeholder="请输入关键字" clearable
                  value={_searchKey}
                  onChange={(value) => this.setState({ _searchKey: value })}
                  onClear={this.initSetData.bind(this)}
                  onSearch={this.initSetData.bind(this)}
                />
              </div>
            )
          }
          <div className={supportSearch ? "multiple-select-search-body has-search-bar" : "multiple-select-search-body"}>
            {
              options && _.size(options) > 0 ? (
                <CheckList
                  multiple={_multiple}
                  value={values}
                  onChange={this.handleChange.bind(this, 'change_select_value')}>
                  {
                    options.map(item => (
                      <CheckList.Item key={item['value']} value={item['value']}>
                        <Space>
                          {_supportAvatar && (item['avatar'] ? <Image lazy fit="contain" src={item['avatar']} placeholder={<DefaultIcon />} fallback={<DefaultIcon />} className="multiple-select-item-avatar" style={{ '--width': '32px', '--height': '32px' }} /> : <DefaultIcon />)}
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div>{item['label']}</div>
                              {
                                item['titleExtension'] && <div style={{ marginLeft: 5 }}>{item['titleExtension']}</div>
                              }
                            </div>
                            {item['description'] && <div style={{ color: '#999' }}>{item['description']}</div>}
                          </div>
                        </Space>
                      </CheckList.Item>
                    ))
                  }
                </CheckList>
              ) : (
                <Fragment>
                  {
                    ((onFetchDatas && !_hasMore) || (!onFetchDatas && (!_options || _.size(options) == 0))) && (
                      <Fragment>
                        <ErrorBlock status="empty" title="暂无数据" description="" />
                      </Fragment>
                    )
                  }
                </Fragment>
              )
            }
            {
              ((onFetchDatas && !_hasMore) || (!onFetchDatas && (!_options || _.size(options) == 0))) && (
                <Fragment>
                  {
                    supportNotFound && supportNotFound()
                  }
                </Fragment>
              )
            }
            {
              onFetchDatas && <CECInfiniteScroll hasMore={_hasMore} onLoadMore={this.fetchDatasMore.bind(this)} />
            }
          </div>
        </div>

        <CECPopup navTitle="已选择列表"
          position="bottom"
          bodyStyle={{ height: '50%' }}
          closeOnMaskClick={true}
          backArrow={false}
          onNavBack={this.handleChange.bind(this, 'handle_toggle_active_popup')}
          ref={this.cecSelectActivePopupRef}>
          <CheckList multiple={_multiple}
            value={values}
            onChange={this.handleChange.bind(this, 'change_select_value')}>
            {
              _.filter(_options, item => values.includes(item['value']))
                .map(item => (
                  <CheckList.Item key={item['value']} value={item['value']}>
                    <Space>
                      {_supportAvatar && (item['avatar'] ? <Image fit="contain" lazy src={item['avatar']} placeholder={<DefaultIcon />} fallback={<DefaultIcon />} className="multiple-select-item-avatar" style={{ '--width': '32px', '--height': '32px' }} /> : <DefaultIcon />)}
                      <div>
                        <div>{item['label']}{item['titleExtension'] && <span style={{ marginLeft: 5 }}>{item['titleExtension']}</span>}</div>
                        {item['description'] && <div>{item['description']}</div>}
                      </div>
                    </Space>
                  </CheckList.Item>
                ))
            }
          </CheckList>
        </CECPopup>
      </CECPopup >
    )
    return content;
  }
}

function SkeletonItems({ count = 10 }) {
  return Array.from({ length: count }).map((_) => <SkeletonItem />)
}

function SkeletonItem() {
  return (
    <Fragment>
      <Skeleton.Title />
      <Skeleton.Paragraph />
    </Fragment>
  )
}

function DefaultIcon() {
  return <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path d="M894 462c30.9 0 43.8-39.7 18.7-58L530.8 126.2a31.81 31.81 0 0 0-37.6 0L111.3 404c-25.1 18.2-12.2 58 18.8 58H192v374h-72c-4.4 0-8 3.6-8 8v52c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-52c0-4.4-3.6-8-8-8h-72V462h62zM512 196.7l271.1 197.2H240.9L512 196.7zM264 462h117v374H264V462z m189 0h117v374H453V462z m307 374H642V462h118v374z" fill="#dbdbdb"></path></svg>
}