import * as ww from '@wecom/jssdk';
import _ from 'lodash';
import request, { requestUrlConfig } from './HttpRequest'


/** 当前用户企业所属企业ID */
const CORPID = requestUrlConfig['corpId'];
/** 当前应用的AgentID */
const AGENTID = requestUrlConfig['agentId'];
/** 需要使用的JSAPI列表 */
const JS_API = ['startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'stopVoice', 'pauseVoice', 'downloadVoice', 'uploadVoice', 'translateVoice'];


/**
 * 企业微信注册
 * @returns 
 */
export async function register(url) {
  await request('POST',
    '/cec-sso-server/user/wxsign',
    {
      "urlfrom": url ? url : `${requestUrlConfig['hostSmartX']}/`,
      "smartxflag": true
    }
  ).then(response => {
    if (response) {
      ww.register({
        corpId: CORPID,
        agentId: AGENTID,
        jsApiList: JS_API,
        // 必填，根据url生成【企业签名】的回调函数
        getConfigSignature: (url) => {
          return { nonceStr: _.get(response, "noncestr"), timestamp: _.get(response, "timestamp"), signature: _.get(response, "signature_com") }
        },
        // 必填，根据url生成【应用签名】的回调函数
        getAgentConfigSignature: (url) => {
          return { nonceStr: _.get(response, "noncestr"), timestamp: _.get(response, "timestamp"), signature: _.get(response, "signature_app") }
        }
      })
    }
  })
}

/**
 * 检查API接口
 * @returns |
 */
export function checkJSAPI() {
  return ww.checkJsApi({ jsApiList: JS_API })
}


/**
 * 开始录音 
 * @note 录音时长超过60s，会自动停止
 * @param {*} payLoad 
 *  {
 *    success: (res) => null,
      fail: (res) => null,
      cancel: (res) => null,
      complete: (res) => null
 *  },
 * @returns 
 */
export function startRecord(payLoad = {}) {
  return ww.startRecord(payLoad)
}

/**
 * 停止录音
 * @param {*} payLoad 
 * @returns Promise.resolve(localId)
 */
export function stopRecord(payLoad = {}) {
  return new Promise((resolve, reject) => ww.stopRecord(payLoad).then((res) => resolve(res['localId'])).catch(() => reject()))
}

/**
 * 监听录音自动停止
 * @returns Promise.resolve(localId)
 */
export function onVoiceRecordEnd() {
  return new Promise((resolve, reject) => {
    ww.onVoiceRecordEnd(({ localId }) => resolve && resolve(localId))
  })
}

/**
 * 播放语音
 * @param {*} localId 录制后本地存储的文件ID
 * @param {*} payLoad 
 * @returns 
 */
export function playVoice(localId, payLoad = {}) {
  // if (!localId) {
  //   return Promise.reject({ errMsg: 'playVoice `localId` is required' })
  // }
  return ww.playVoice({
    localId: localId,
    ...payLoad
  })
}

/**
 * 暂停播放
 * @param {*} localId 
 * @returns 
 */
export function pauseVoice(localId, payLoad = {}) {
  // if (!localId) {
  //   return Promise.reject({ errMsg: 'pauseVoice `localId` is required' })
  // }
  return ww.pauseVoice({
    localId: localId,
    ...payLoad
  })
}

/**
 * 停止播放
 * @param {*} localId 
 * @returns 
 */
export function stopVoice(localId, payLoad = {}) {
  // if (!localId) {
  //   return Promise.reject({ errMsg: 'stopVoice `localId` is required' })
  // }
  return ww.stopVoice({
    localId: localId,
    ...payLoad
  })
}

/**
 * 监听录音自动停止
 * @returns localId
 */
export function onVoicePlayEnd() {
  return new Promise((resolve, reject) => {
    ww.onVoicePlayEnd(({ localId }) => resolve && resolve(localId))
  })
}

/**
 * 上传语音到素材库
 * @param {*} localId 
 * @param {*} payLoad 
 * @returns 
 *  {
 *    serverId: 'xxx'
 *  }
 */
export function uploadVoice(localId, payLoad = {}) {
  // if (!localId) {
  //   return Promise.reject({ errMsg: 'uploadVoice `localId` is required' })
  // }
  return new Promise((resolve, reject) => {
    ww.uploadVoice({
      localId: localId,
      isShowProgressTips: false,
      ...payLoad
    })
      .then(res => syncVoiceToRemoteServer(res['serverId']))
      .then(res => resolve && resolve(res))
      .catch(err => reject && reject(err))
  })
}

/**
 * 同步语音到服务器
 * @param {*} serverId 
 * @returns 
 */
export function syncVoiceToRemoteServer(serverId) {

  return new Promise((resolve, reject) => {
    request('POST', '/cec-sso-server/file/downloadwxvoice', { media_id: serverId })
      .then((response) => {
        if (response) {
          const responseNewData = {
            name: _.get(response, "name_mp3"),
            url: _.get(response, "url_mp3"),
            createTime: _.get(response, "ctime"),
            createUser: _.get(response, "cuser"),
          }
          resolve(responseNewData)
        } else {
          reject(response)
        }
      })
      .catch(error => reject(error))
  })
}

/**
 * 下载语音
 * @param {*} serverId 
 * @param {*} payLoad 
 * @returns 
 *  {
 *    localId: 'xxx'
 *  }
 */
export function downloadVoice(serverId, payLoad = {}) {
  // if (!serverId) {
  //   return Promise.reject({ errMsg: 'downloadVoice `localId` is required' })
  // }
  return ww.downloadVoice({
    serverId: serverId,
    isShowProgressTips: true,
    ...payLoad
  })
}

/**
 * 语音转文字
 * @param {*} localId 
 * @param {*} payLoad 
 * @returns 
 *  {
 *    translateResult: 'xxx'
 *  }
 */
export function translateVoice(localId, payLoad = {}) {
  // if (!localId) {
  //   return Promise.reject({ errMsg: 'translateVoice `localId` is required' })
  // }
  return ww.translateVoice({
    localId: localId,
    isShowProgressTips: false,
    ...payLoad
  })
}