import { createRef } from "react";
import _ from "lodash";
import { TRADING_OPPORTUNITY_ORG_OPTIONS, TRADING_OPPORTUNITY_FOR_ENTERPRISE_OPTIONS } from '../configuration'
import BaseInfoPopup from '../components/BaseInfoPopup'
import AttendTargetPopup from '../components/AttendTargetPopup'
import AttendTargetUserPopup from '../components/AttendTargetUserPopup'
import CECTextAreaPopup from "../../../../components/TextAreaPopup";
import BussinessStrategyPopup from '../components/BussinessStrategyPopup'
import FinancialDataPopup from "../components/FinancialDataPopup";
import FinancingHistoryPopup from '../components/FinancingHistoryPopup'
import FundraisingPopup from '../components/FundraisingPopup'
import InvestmentCasePopup from '../components/InvestmentCasePopup'
import OwnershipStructurePopup from '../components/OwnershipStructurePopup'
import EmploymentHistoryPopup from '../components/EmploymentHistoryPopup'
import GovernmentPopup from '../components/GovernmentPopup'
import FamilyOfficePopup from '../components/FamilyOfficePopup'
import AudioSpeech from "../../../../components/AudioSpeech";
import AppendTargetGroupItem from '../components/AppendTargetGroupItem'
import DeleteTargetGroupItem from "../components/DeleteTargetGroupItem";
import FundPopup from "../components/FundPopup";
import InvestmentSectorPopup from "../components/InvestmentSectorPopup";
import Tree from "../../../../components/Tree";

const ATTEND_TARGET_TYPE_OPTIONS = [
  { label: '企业（产业方）', value: 'memo_enterprise', avatar: null, selected: false },
  { label: '市场化机构', value: 'memo_market', avatar: null, selected: false },
  { label: '政府', value: 'memo_government', avatar: null, selected: false },
  { label: '家办及个人', value: 'memo_family_office', avatar: null, selected: false },
]

// 与会方Item
export const ATTEND_TARGET_ITEM = {
  label: '与会方',
  key: 'memo_option_list_attend_target',
  showItem: true,
  required: true,
  ref: createRef(),
  title: '与会方',
  componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => (
    <AttendTargetPopup key={metadata['key']} ref={metadata.ref} targetTypeOptions={[...ATTEND_TARGET_TYPE_OPTIONS]} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} onConfirm={onConfirm} />
  ),
  groups: [
    {
      label: '对方参会人',
      key: 'memo_option_list_attend_target_user',
      showItem: true,
      required: true,
      disabled: ({ groupKey, metadata, sourcedata, extensionValue }) => {
        const targetValues = _.get(extensionValue, `${groupKey}.targets`),
          targetTypeValues = _.get(extensionValue, `${groupKey}.targets`)
        if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
          return false
        }
        return true
      },
      ref: createRef(),
      title: '对方参会人',
      componentRender: ({ groupKey, metadata, sourcedata, extensionValue, onConfirm }) => <AttendTargetUserPopup key={metadata['key']} ref={metadata.ref} groupKey={groupKey} metadata={metadata} sourcedata={sourcedata} extensionValue={extensionValue} onConfirm={onConfirm} />,
    }, {
      label: '关键信息更新',
      key: 'memo_option_list_update_content',
      className: 'cec-hidden-arrow',
      showItem: true,
      required: false,
      disabled: ({ groupKey, metadata, sourcedata, extensionValue }) => {
        const targetValues = _.get(extensionValue, `${groupKey}.targets`),
          targetTypeValues = _.get(extensionValue, `${groupKey}.targets`)
        if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
          return false
        }
        return true
      },
      getValue: ({ groupKey, metadata, sourcedata, extensionValue }) => {
        var responseValue = {}
        const targetTypeValues = _.get(extensionValue, `${groupKey}.targetTypes`)
        // const currentParentPathValues = _.get(extensionValue, "memo_option_list_update_content.metadata.parentPathValues")
        if (targetTypeValues && _.size(targetTypeValues) > 0) {
          targetTypeValues.find(item => item['key'] = item['value'])
          responseValue = {
            values: _.map(targetTypeValues, 'value'),
            items: targetTypeValues,
          }
        }
        return responseValue
      },
      placeholder: '',
      // 可自定义Label后缀返回信息
      getLabelSuffix: ({ groupKey, metadata, sourcedata, extensionValue }) => {
        const targetValues = _.get(extensionValue, `${groupKey}.targets`),
          targetTypeValues = _.get(extensionValue, `${groupKey}.targetTypes`)
        let labelValue = ''
        if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
          labelValue = `${_.join(_.map(targetValues, 'label'))} - ${_.join(_.map(targetTypeValues, 'label'))}`
        }
        return labelValue ? <span style={{ whiteSpace: 'nowrap' }}>【{labelValue}】</span> : null
      },
      multiPathComponentRender: ({ groupKey, metadata, sourcedata, extensionValue, onSelect, onConfirm, onClick }) => {
        const targetValues = _.get(extensionValue, `${groupKey}.targets`),
          targetTypeValues = _.get(extensionValue, `${groupKey}.targetTypes`)
        let labelValue = ''
        let options = []
        if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
          labelValue = `${_.join(_.map(targetValues, 'label'))} - ${_.join(_.map(targetTypeValues, 'label'))}`

          const targetType4UpdateItem = _.head(_.filter(_.get(sourcedata, `options`), { key: _.head(_.map(targetTypeValues, 'value')) }))
          if (targetType4UpdateItem && targetType4UpdateItem['children']) {
            options = targetType4UpdateItem['children']
          } else {
            options = [{ ...targetType4UpdateItem }]
          }
        }

        let values = _.get(extensionValue, `${metadata.key}`)
        return (
          <Tree placeholder="请选择与会方" metadata={metadata} sourcedata={sourcedata} values={values} datas={options} onSelect={onSelect} onConfirm={onConfirm} onClick={onClick} />
        )
      },
    },
  ],
  // 移除分组
  showRemove: ({ metadata, datas, sourcedata }) => {
    const attendTargetArray = _.filter(datas, item => _.startsWith(item['key'], 'memo_option_list_attend_target'))
    const currentAttendTargetIndex = _.findLastIndex(attendTargetArray, { key: metadata['key'] })
    const show = currentAttendTargetIndex > 0 ? true : false
    return show
  },
  onRemove: ({ metadata, datas, sourcedata, sourcedataOrigin, extensionValue, onDataChange, onValueChange }) => (
    <DeleteTargetGroupItem key={metadata['key']} metadata={metadata} datas={datas} sourcedata={sourcedata} sourcedataOrigin={sourcedataOrigin} extensionValue={extensionValue} onDataChange={onDataChange} onValueChange={onValueChange} />
  )
}



// 组件展示列表
export const COMPONENT_OPTION_LIST = [
  {
    label: '基本信息',
    key: 'memo_option_list_base_info',
    showItem: true,
    required: true,
    /** 组件配置信息 **/
    ref: createRef(),
    // 渲染弹窗组件：路径选择器 / 表单数据
    title: '基本信息',
    componentRender: ({ metadata, sourcedata, onConfirm }) => <BaseInfoPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
  }, {
    // 与会方
    ...ATTEND_TARGET_ITEM
  }, {
    label: '',
    placeholder: '',
    key: 'memo_option_list_add_targe_user',
    showItem: true,
    className: 'append-group-item',
    itemRender: ({ groupKey, metadata, datas, sourcedata, sourcedataOrigin, onDataChange }) => <AppendTargetGroupItem key={metadata['key']} metadata={metadata} datas={datas} sourcedata={sourcedata} sourcedataOrigin={sourcedataOrigin} groupKey={groupKey} appendItems={[{ ...ATTEND_TARGET_ITEM }]} onDataChange={onDataChange} />
  }, {
    label: '我方参会人',
    key: 'memo_option_list_attend_ceccapital_user',
    showItem: true,
    required: true,
    disabled: ({ metadata, sourcedata, extensionValue }) => {
      const targetValues = _.get(extensionValue, "memo_option_list_attend_target.targets"),
        targetTypeValues = _.get(extensionValue, "memo_option_list_attend_target.targets")
      if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
        return false
      }
      return true
    },
    ref: createRef(),
    title: '我方参会人',
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <AttendTargetUserPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} extensionValue={extensionValue} onConfirm={onConfirm} />,
  }, {
    label: '会议内容',
    key: 'memo_option_list_memo_content',
    showItem: true,
    required: true,
    disabled: ({ metadata, sourcedata, extensionValue }) => {
      const targetValues = _.get(extensionValue, "memo_option_list_attend_target.targets"),
        targetTypeValues = _.get(extensionValue, "memo_option_list_attend_target.targets")
      if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
        return false
      }
      return true
    },
    placeholder: '手动输入',
    description: ({ metadata, sourcedata, extensionValue, onConfirm }) => <AudioSpeech key={metadata['key']} metadata={metadata} sourcedata={sourcedata} value={extensionValue['memo_option_list_memo_content']} onConfirm={onConfirm} />,
    ref: createRef(),
    title: '会议内容',
    componentRender: ({ metadata, sourcedata, onConfirm }) => <CECTextAreaPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
  }
]


// 多层级关联组件映射关系
export const COMPONENT_OPTION_LIST_MULTIPLE_CASCADER_PATHS = {
  'memo_option_list_update_content': {
    options: [
      {
        label: '企业（产业方）',
        key: 'memo_enterprise',
        children: [
          {
            label: '策略及业务方向更新',
            key: 'memo_enterprise_bussiness_strategy',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <BussinessStrategyPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
          }, {
            label: '财务数据更新',
            key: 'memo_enterprise_bussiness_financial_data',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <FinancialDataPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
          }, {
            label: '交易机会更新',
            key: 'memo_enterprise_trading_opportunity',
            children: [...TRADING_OPPORTUNITY_FOR_ENTERPRISE_OPTIONS]
          }, {
            label: '股权结构更新',
            key: 'memo_enterprise_trading_opportunity_ownership_structure',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <OwnershipStructurePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
          }, {
            label: '融资/收购历史更新',
            key: 'memo_enterprise_trading_history',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <FinancingHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
          }, {
            label: '投资案例更新',
            key: 'memo_enterprise_trading_opportunity_investment_case',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <InvestmentCasePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
          }, {
            label: '人事更新',
            key: 'memo_enterprise_trading_opportunity_employment_history',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <EmploymentHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
          },
        ]
      }, {
        label: '市场化机构',
        key: 'memo_market',
        children: [
          {
            label: '基金属性',
            key: 'memo_market_fund',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <FundPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
          }, {
            label: '基金募集更新',
            key: 'memo_market_fundraising',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <FundraisingPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
          }, {
            label: '投资方向更新',
            key: 'memo_market_investment_focus',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <InvestmentSectorPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />
          }, {
            label: '交易机会更新',
            key: 'memo_market_trading_opportunity',
            children: [...TRADING_OPPORTUNITY_ORG_OPTIONS]
          }, {
            label: '投资案例更新',
            key: 'memo_market_investment_case',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <InvestmentCasePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
          }, {
            label: '人事更新',
            key: 'memo_market_employment_history',
            ref: createRef(),
            componentRender: ({ metadata, sourcedata, onConfirm }) => <EmploymentHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
          },
        ]
      }, {
        label: '政府',
        key: 'memo_government',
        ref: createRef(),
        componentRender: ({ metadata, sourcedata, onConfirm }) => <GovernmentPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
      }, {
        label: '家办及个人',
        key: 'memo_family_office',
        ref: createRef(),
        componentRender: ({ metadata, sourcedata, onConfirm }) => <FamilyOfficePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} onConfirm={onConfirm} />,
      },
    ]
  }
}