import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../components/AmountNumber";

// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]

/**
 * 转换行业为Label
 * @param {*} datas 
 * @returns 
 */
export const ConvertIndustryToLabels = (datas) => {
  let selectedIndustrys = ""
  if (datas) {
    selectedIndustrys = _.join(_.map(datas, item => {
      let industry = item['label']
      let childrenLabel = ""
      if (item['children'] && _.size(item['children']) > 0) {
        childrenLabel = _.join(_.map(item['children'], "label"), "、")
      }
      if (childrenLabel) {
        industry += `（${childrenLabel}）`
      }
      return industry
    }), "，")
  }
  return selectedIndustrys
}

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type']
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 保存 投资方向更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  let orgIdentitys = []
  if (info['orgIdentitys'] && _.size(info['orgIdentitys']) > 0) {
    info['orgIdentitys'].find(item => {
      orgIdentitys.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let investmentTargetAttributes = []
  if (info['investmentTargetAttributes'] && _.size(info['investmentTargetAttributes']) > 0) {
    info['investmentTargetAttributes'].find(item => {
      investmentTargetAttributes.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let participateSTrade = []
  if (info['participateSTrade'] && _.size(info['participateSTrade']) > 0) {
    info['participateSTrade'].find(item => {
      participateSTrade.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let areas = []
  if (info['areas'] && _.size(info['areas']) > 0) {
    info['areas'].find(item => {
      areas.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let industrys = []
  if (info['industry'] && _.size(info['industry']) > 0) {
    info['industry'].find(item => {
      industrys.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })
    })
  }

  let specialConditions = []
  let specialConditionText = ""
  if (info['specialConditions'] && _.size(info['specialConditions']) > 0) {
    info['specialConditions'].find(item => {
      specialConditions.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })

      if (item['label'] === "其他") {
        specialConditionText = info['specialConditionText']
      }
    })
  }

  let summaryArray = _.concat(orgIdentitys, investmentTargetAttributes, participateSTrade, areas, industrys, specialConditions)

  let url = ""
  let payload = {}
  if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/base/direction/save"
    payload = {
      id: info['id'],
      base_investment_code: info['code'],
      currencys: info['currencys'] ? _.join(_.map(info['currencys'], 'value')) : null,
      state: info['currentStatus'] ? _.join(_.map(info['currentStatus'], "label")) : null,
      // 特殊要求"其他"
      notes: specialConditionText,
      label_datas: JSON.stringify(summaryArray),
      showflag: info['id'] ? true : false,
      smartxflag: true,
    }
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/base/family/direction/save"
    payload = {
      id: info['id'],
      base_lp_code: info['code'],
      currencys: info['currencys'] ? _.join(_.map(info['currencys'], 'value')) : null,
      state: info['currentStatus'] ? _.join(_.map(info['currentStatus'], "label")) : null,
      // 特殊要求"其他"
      notes: specialConditionText,
      label_datas: JSON.stringify(summaryArray),
      showflag: info['id'] ? true : false,
      smartxflag: true,
    }
  }
  try {
    const response = await request("POST", url, payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 投资方向更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  let url = ""
  if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/base/direction/delete"
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/base/family/direction/delete"
  }
  try {
    await request("POST", url, { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}