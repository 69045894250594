import React, { PureComponent, createRef } from "react";
import _ from 'lodash';
import { Button, Tabs, CheckList, SearchBar, ErrorBlock } from 'antd-mobile'
import { DownOutline } from 'antd-mobile-icons'
import CECPopup from "../Popup";
import './index.css';

/**
 * 选择人员和组
 */
export default class CECSelectUserAndGroupPopup extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      _tabKey: 'tab_user',
      _searchKey: '',
      _tabGroups: props.tabGroups ? props.tabGroups : [{ label: '按人员', value: 'user' }, { label: '按组', value: 'group' }],
      _options: props.options ? props.options : [],
      _values: props.values ? props.values : [],
    }
    this.cecSelectUserAndGroupPopup = createRef()
    this.cecSelectUserAndGroupActivePopup = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _tabGroups: props.tabGroups ? props.tabGroups : [{ label: '按人员', value: 'user' }, { label: '按组', value: 'group' }],
      _options: props.options ? props.options : [],
      _values: props.values ? props.values : [],
    })
  }

  show() {
    this.cecSelectUserAndGroupPopup.current.show()
  }
  toggle() {
    this.cecSelectUserAndGroupPopup.current.toggle()
  }
  close() {
    this.cecSelectUserAndGroupPopup.current.close()
  }
  initSetData() {

  }
  handleChange(key, data, event) {
    console.log("handleChange >>>", key, data, event)
    let {
      _options
    } = this.state
    const {
      onConfirm
    } = this.props

    switch (key) {
      case 'change_tab_key':
        this.setState({ _tabKey: data })
        break;
      case 'change_select_value':
        if (_.size(event) > 0) {
          _options = _.map(_options, item => {
            if (item['category'] === data) {
              if (event.includes(item['value'])) {
                item['selected'] = true
              } else {
                item['selected'] = false
              }
            }
            return item
          })
        } else {
          _options = _.map(_options, item => {
            if (item['category'] === data) {
              item['selected'] = false
            }
            return item
          })
        }

        this.setState({ _options: _options, _values: [..._.filter(_options, { selected: true })] })
        break;
      case 'handle_toggle_active_popup':
        this.cecSelectUserAndGroupActivePopup.current?.toggle()
        break;
      case 'handle_confirm_value':
        var confirmSelectedItems = _.filter(_options, { selected: true })
        onConfirm && onConfirm(confirmSelectedItems)
        this.close()
        break;
      default:
        break;
    }
  }

  render() {
    const {
      title,
      supportSearch,
      navRight,
      afterShow,
      onNavBack,
    } = this.props
    const {
      _tabKey,
      _searchKey,
      _tabGroups,
      _options,
      _values,
    } = this.state

    const options = _.filter(_options, (item) => item['label'].indexOf(_searchKey) > -1)

    return (
      <CECPopup ref={this.cecSelectUserAndGroupPopup}
        navTitle={title}
        onNavBack={() => onNavBack ? onNavBack() : this.close()}
        navRight={navRight}
        afterShow={afterShow}
        afterClose={this.initSetData.bind(this)}
        foot={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1 }}>
              <Button color="default" fill="none" onClick={this.handleChange.bind(this, 'handle_toggle_active_popup')}><DownOutline style={{ marginRight: 10 }} />已选择{_.size(_.filter(_options, { selected: true }))}条</Button>
            </div>
            <div style={{ flex: 1 }}>
              <Button color="primary" block onClick={this.handleChange.bind(this, 'handle_confirm_value')}>确定</Button>
            </div>
          </div>
        }>
        <div className="multiple-select-user-group-container">
          {
            supportSearch && (
              <div className="multiple-select-user-group-search-bar">
                <SearchBar placeholder="请输入关键字" clearable
                  value={_searchKey}
                  onChange={(value) => this.setState({ _searchKey: value })}
                  onClear={this.initSetData.bind(this)}
                  onSearch={this.initSetData.bind(this)}
                />
              </div>
            )
          }
          <div className={supportSearch ? "multiple-select-user-group-body has-search-bar" : "multiple-select-user-group-body"}>
            <Tabs activeKey={_tabKey} onChange={this.handleChange.bind(this, 'change_tab_key')}>
              {
                _tabGroups.map(group => (
                  <Tabs.Tab title={group['label']} key={`tab_${group['value']}`}>
                    {
                      options && _.size(_.filter(options, { 'category': group['value'] })) > 0 ? (
                        <CheckList multiple={true}
                          value={_.map(_.filter(_values, { 'category': group['value'] }), 'value')}
                          onChange={this.handleChange.bind(this, 'change_select_value', group['value'])}>
                          {
                            _.filter(options, { 'category': group['value'] }).map(item => (
                              <CheckList.Item key={item['value']} value={item['value']}>
                                <div>
                                  <div>{item['label']}{item['titleExtension'] && <span style={{ marginLeft: 5 }}>{item['titleExtension']}</span>}</div>
                                  {item['description'] && <div style={{ color: '#999' }}>{item['description']}</div>}
                                </div>
                              </CheckList.Item>
                            ))
                          }
                        </CheckList>
                      ) : (
                        <ErrorBlock status="empty" title="暂无数据" description="" />
                      )
                    }
                  </Tabs.Tab>
                ))
              }
            </Tabs>
          </div>
        </div>

        {/** 展开折叠选中 */}
        <CECPopup navTitle="已选择列表"
          position="bottom"
          bodyStyle={{ height: '50%' }}
          closeOnMaskClick={true}
          backArrow={false}
          onNavBack={this.handleChange.bind(this, 'handle_toggle_active_popup')}
          ref={this.cecSelectUserAndGroupActivePopup}>
          {
            _tabGroups.map((groupTab, index) => (
              <CheckList multiple={true}
                style={(_.size(_tabGroups) - 1 != index) ? { '--border-bottom': 'none' } : null}
                value={_.map(_.filter(_values, { 'category': groupTab['value'] }), 'value')}
                onChange={this.handleChange.bind(this, 'change_select_value', groupTab['value'])}>
                {
                  _.filter(_values, { 'category': groupTab['value'] })
                    .map(item => (
                      <CheckList.Item key={item['value']} value={item['value']}>
                        <div>
                          <div>{item['label']}{item['titleExtension'] && <span style={{ marginLeft: 5 }}>{item['titleExtension']}</span>}</div>
                          {item['description'] && <div style={{ color: '#999' }}>{item['description']}</div>}
                        </div>
                      </CheckList.Item>
                    ))
                }
              </CheckList>
            ))
          }
        </CECPopup>
      </CECPopup>
    )
  }
}