import React, { createRef, Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import { Button, Input, List, Toast, Popover } from "antd-mobile";
import BaseExtractPopup from "../../../../../components/BaseExtractPopup";
import CECCalendarPopup from "../../../../../components/CalendarPopup"
import CECSelectPopup from "../../../../../components/SelectPopup";
import CECTargetFormPopup from "../../../../../components/TargetFormPopup";
import Required from "../../../../../components/Required";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import {
  fetchRoundOptions,
  fetchCurrencyOptions,
  fetchDealBussinessOptions,
  fetchECMUserOptions,
} from '../../configuration'
import {
  FetchTargetList,
  FetchCECUserCallbackList,
} from '../../transform'

/**
 * 【项目更新：基本信息】
 */
export default class BaseDealInfoPopup extends BaseExtractPopup {

  constructor(props) {
    super(props);

    this.state = {
      _info: props.metadata && props.metadata.codeDisable === true ? { ...baseInfo } : {},

      _companyOptions: [],
      _cecOwnerUserOptions: [],
      _cecOwnerOtherUserOptions: [],
      _ecmUserOptions: fetchECMUserOptions(),
      _pmUserOptions: [],
      _memberUserOptions: [],
      _dealGroupOptions: [...groups],
      _shareGroupOptions: [...groups],

      _targetCategory: null,
      _targetCategoryLabel: null,
    }
    this.companyPopupRef = createRef()
    this.dealOpenDateRef = createRef();
    this.dealGuessCompleteDateRef = createRef();
    this.cecOwnerUserPopupRef = createRef()
    this.cecOwnerOtherUserPopupRef = createRef()
    this.ecmUserPopupRef = createRef()
    this.pmUserPopupRef = createRef()
    this.memberUserPopupRef = createRef()
    this.dealGroupPopupRef = createRef()
    this.shareGroupPopupRef = createRef()
    this.createTargetFormRef = createRef()
  }

  componentDidMount() {
    this.fetchCompanyList()
    this.fetchCECUserList()
  }

  handleClick(key, data, event) {
    // console.log("handleClick >>>>", key, data, event)
    const { onConfirm } = this.props
    const { _targets } = this.state

    switch (key) {
      case 'handle_open_create_target_popup':
        this.setState({ _targetCategory: data, _targetCategoryLabel: event }, () => this.createTargetFormRef?.current?.show())
        break;
      case 'handle_confirm_create_target_popup':
        this.saveTargetInfo(data)
        break;
      default:
        break;
    }
  }

  fetchCompanyList = async () => {
    await FetchTargetList(['company'], datas => this.setState({ _companyOptions: datas }))
  }

  fetchCECUserList = async () => {
    Toast.show({ content: '加载中...', icon: 'loading', duration: 0, maskStyle: { zIndex: 1500 } })
    await FetchCECUserCallbackList('normal', datas => this.setState({ _cecOwnerUserOptions: [...datas], _cecOwnerOtherUserOptions: [...datas], _pmUserOptions: [...datas], _memberUserOptions: [...datas] }))
    Toast.clear()
  }
  // 创建对象
  saveTargetInfo = async (data) => {
    let { _targetCategory, _info, _companyOptions } = this.state
    // await SaveTargetInfo(_targetCategory, data,
    //   (data) => {
    //     Toast.show({ content: '保存成功', icon: 'success' })
    //     if (_.size(_.filter(_companyOptions, { value: data['value'] })) == 0) {
    //       let targetOptions = _.map(_companyOptions, item => ({ ...item }))
    //       targetOptions.unshift(data)
    //       this.setState({
    //         _companyOptions: targetOptions,
    //         _info: _.assign({}, _info, {
    //           companys: [data]
    //         })
    //       }, () => this.companyPopupRef.current.toggle())
    //     }
    //     this.createTargetFormRef.current?.close()
    //   },
    //   (msg) => Toast.show({ icon: 'fail', content: msg })
    // )
  }

  renderBodyContent() {
    const {
      _info,
      _companyOptions,
      _cecOwnerUserOptions,
      _cecOwnerOtherUserOptions,
      _ecmUserOptions,
      _pmUserOptions,
      _memberUserOptions,
      _dealGroupOptions,
      _shareGroupOptions,
      _targetCategory,
      _targetCategoryLabel,
    } = this.state

    const { metadata } = this.props

    return (
      <Fragment>
        <List header={<div style={{ background: '#f0f0f0', margin: '-10px', padding: '10px' }}>基本信息</div>}>
          {metadata.codeDisable != true && (
            <List.Item extra={<Input placeholder="请填写" value={_info['code']} onChange={value => this.setState({ _info: { ..._info, code: value } })} />}>项目Code</List.Item>
          )}
          <List.Item extra={_info['companys'] && _.size(_info['companys']) > 0 ? _.join(_.map(_info['companys'], "label")) : "请选择"} onClick={() => this.companyPopupRef.current?.toggle()}>客户名称</List.Item>
          <CECSelectorListItem label="业务类型"
            isRequired={true}
            multiple={true}
            options={fetchDealBussinessOptions()}
            value={_info['bussiness'] ? _.map(_info['bussiness'], 'value') : []}
            onChange={(value, options) => this.setState({ _info: { ..._info, "bussiness": options } })}
          />
          <CECSelectorListItem label="目标轮次"
            isRequired={true}
            multiple={false}
            options={fetchRoundOptions()}
            value={_info['rounds'] ? _.map(_info['rounds'], 'value') : []}
            onChange={(value, options) => this.setState({ _info: { ..._info, "rounds": options } })}
          />
          <CECSelectorListItem label="项目币种"
            isRequired={true}
            multiple={true}
            options={fetchCurrencyOptions()}
            value={_info['currency'] ? _.map(_info['currency'], 'value') : []}
            onChange={(value, options) => this.setState({ _info: { ..._info, "currency": options } })}
          >
            {_info['currency'] && _.map(_info['currency'], 'value').includes("其他") && <Input placeholder="请输入其他币种" value={_info['currencyOther']} onChange={val => this.setState({ _info: { ..._info, "currencyOther": val } })} />}
          </CECSelectorListItem>
          <List.Item extra={_info['dealOpenDate'] ? moment(_info['dealOpenDate']).format('YYYY/MM/DD') : "请选择"} onClick={() => this.dealOpenDateRef?.current?.toggle()}>启动时间<Required /></List.Item>
          <List.Item extra={_info['dealGuessCompleteDate'] ? moment(_info['dealGuessCompleteDate']).format('YYYY/MM/DD') : "请选择"} onClick={() => this.dealGuessCompleteDateRef?.current?.toggle()}>预计完结时间</List.Item>
        </List>
        <List header={<div style={{ background: '#f0f0f0', margin: '-10px', padding: '10px' }}>负责人</div>}>
          <List.Item
            extra={_info['ownerUsers'] && _.size(_info['ownerUsers']) > 0 ? _.join(_.map(_info['ownerUsers'], "label")) : "请选择"}
            onClick={() => this.cecOwnerUserPopupRef.current?.toggle()}>审批负责人<Required />
          </List.Item>
          <List.Item
            extra={_info['ownerOtherUsers'] && _.size(_info['ownerOtherUsers']) > 0 ? _.join(_.map(_info['ownerOtherUsers'], "label")) : "请选择"}
            onClick={() => this.cecOwnerOtherUserPopupRef.current?.toggle()}>其他负责人<Required />
          </List.Item>
          <List.Item
            extra={_info['ecmUsers'] && _.size(_info['ecmUsers']) > 0 ? _.join(_.map(_info['ecmUsers'], "label")) : "请选择"}
            onClick={() => this.ecmUserPopupRef.current?.toggle()}>ECM协调人<Required />
          </List.Item>
          <List.Item
            extra={_info['pmUsers'] && _.size(_info['pmUsers']) > 0 ? _.join(_.map(_info['pmUsers'], "label")) : "请选择"}
            onClick={() => this.pmUserPopupRef.current?.toggle()}>PM<Required /></List.Item>
        </List>
        <List header={<div style={{ background: '#f0f0f0', margin: '-10px', padding: '10px' }}>项目组</div>}>
          <List.Item description="审批负责人所在组"
            extra={_info['dealGroups'] && _.size(_info['dealGroups']) > 0 ? _.join(_.map(_info['dealGroups'], "label")) : "请选择"}
            onClick={() => this.dealGroupPopupRef.current?.toggle()}>项目组<Required /></List.Item>
          <List.Item
            extra={_info['shareGroups'] && _.size(_info['shareGroups']) > 0 ? _.join(_.map(_info['shareGroups'], "label")) : "请选择"}
            onClick={() => this.shareGroupPopupRef.current?.toggle()}>合作组</List.Item>
        </List>
        <List header={<div style={{ background: '#f0f0f0', margin: '-10px', padding: '10px' }}>项目成员</div>}>
          <List.Item
            extra={_info['memberUsers'] && _.size(_info['memberUsers']) > 0 ? _.join(_.map(_info['memberUsers'], "label")) : "请选择"}
            onClick={() => this.memberUserPopupRef.current?.toggle()}>其他项目成员
          </List.Item>
        </List>

        {/** 客户 */}
        <CECSelectPopup ref={this.companyPopupRef}
          title={'客户'}
          navRight={
            <Popover.Menu trigger="click"
              actions={[
                { text: <span style={{ fontSize: 12 }}>请选择企业身份</span>, value: 'enterprise', disabled: true },
                { text: '公司', value: 'company', onClick: () => this.handleClick('handle_open_create_target_popup', 'company', '公司') },
                { text: 'GP', value: 'investment', onClick: () => this.handleClick('handle_open_create_target_popup', 'investment', 'GP') },
                { text: 'LP', value: 'lp', onClick: () => this.handleClick('handle_open_create_target_popup', 'lp', 'LP') },
              ]}>
              <Button color="primary" fill="none">新建企业(公司/GP/LP)</Button>
            </Popover.Menu>
          }
          supportSearch={true}
          supportAvatar={true}
          options={_companyOptions}
          values={_info['companys']}
          onConfirm={(values) => this.setState({ _info: { ..._info, companys: values } })}
        />
        {/** 启动时间 */}
        <CECCalendarPopup ref={this.dealOpenDateRef}
          value={moment().toDate()}
          metadata={{ title: "启动时间" }}
          onConfirm={(value) => this.setState({ _info: { ..._info, dealOpenDate: value } })}
        />
        {/** 预计完结时间 */}
        <CECCalendarPopup ref={this.dealGuessCompleteDateRef}
          value={moment().toDate()}
          metadata={{ title: "预计完结时间" }}
          onConfirm={(value) => this.setState({ _info: { ..._info, dealGuessCompleteDate: value } })}
        />
        {/** 负责人 */}
        <CECSelectPopup ref={this.cecOwnerUserPopupRef}
          title={'审批负责人'}
          supportSearch={true}
          supportAvatar={true}
          multiple={true}
          options={_cecOwnerUserOptions}
          values={_info['ownerUsers']}
          onConfirm={(values) => this.setState({ _info: { ..._info, ownerUsers: values } })}
        />
        {/** 其他负责人 */}
        <CECSelectPopup ref={this.cecOwnerOtherUserPopupRef}
          title={'其他负责人'}
          supportSearch={true}
          supportAvatar={true}
          multiple={true}
          options={_cecOwnerOtherUserOptions}
          values={_info['ownerOtherUsers']}
          onConfirm={(values) => this.setState({ _info: { ..._info, ownerOtherUsers: values } })}
        />
        {/** ECM */}
        <CECSelectPopup ref={this.ecmUserPopupRef}
          title={'其他负责人'}
          supportSearch={true}
          supportAvatar={true}
          multiple={true}
          options={_ecmUserOptions}
          values={_info['ecmUsers']}
          onConfirm={(values) => this.setState({ _info: { ..._info, ecmUsers: values } })}
        />
        {/** PM */}
        <CECSelectPopup ref={this.pmUserPopupRef}
          title={'PM'}
          supportSearch={true}
          supportAvatar={true}
          multiple={true}
          options={_pmUserOptions}
          values={_info['pmUsers']}
          onConfirm={(values) => this.setState({ _info: { ..._info, pmUsers: values } })}
        />
        {/** 其他项目成员 */}
        <CECSelectPopup ref={this.memberUserPopupRef}
          title={'其他项目成员'}
          supportSearch={true}
          supportAvatar={true}
          multiple={true}
          options={_memberUserOptions}
          values={_info['memberUsers']}
          onConfirm={(values) => this.setState({ _info: { ..._info, memberUsers: values } })}
        />
        {/** 项目组 */}
        <CECSelectPopup ref={this.dealGroupPopupRef}
          title={'项目组'}
          supportSearch={true}
          supportAvatar={false}
          multiple={true}
          options={_dealGroupOptions}
          values={_info['dealGroups']}
          onConfirm={(values) => this.setState({ _info: { ..._info, dealGroups: values } })}
        />
        {/** 合作组 */}
        <CECSelectPopup ref={this.shareGroupPopupRef}
          title={'合作组'}
          supportSearch={true}
          supportAvatar={false}
          multiple={true}
          options={_shareGroupOptions}
          values={_info['shareGroups']}
          onConfirm={(values) => this.setState({ _info: { ..._info, shareGroups: values } })}
        />
        {/** 新建对象/新建与会方 */}
        <CECTargetFormPopup ref={this.createTargetFormRef}
          metadata={{ title: metadata['key'] == 'info_option_list_attend_target' ? `新建企业(${_targetCategoryLabel})` : '新建与会方' }}
          category={_targetCategory}
          value={null}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_create_target_popup')}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const {
      metadata,
      onConfirm
    } = this.props
    const {
      _info
    } = this.state
    return (
      <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
    )
  }
}

export const groups = [
  { label: '消费&娱乐', value: 'target_group_1', selected: false },
  { label: '科技', value: 'target_group_2', selected: false },
  { label: 'Pharma', value: 'target_group_3', selected: false },
  { label: 'Service', value: 'target_group_4', selected: false },
  { label: 'MedTech', value: 'target_group_5', selected: false },
  { label: 'M&A', value: 'target_group_6', selected: false },
  { label: 'SynBio', value: 'target_group_7', selected: false },
  { label: 'ECM', value: 'target_group_8', selected: false },
  { label: 'GP/LP服务', value: 'target_group_9', selected: false },
]

export const baseInfo = {
  "bussiness": [
    {
      "label": "投融资",
      "value": "投融资"
    }
  ],
  "rounds": [
    {
      "value": "Lable-trastage-006",
      "label": "种子轮"
    }
  ],
  "companys": [
    {
      "label": "特斯拉",
      "value": "C468921475046794188",
      "avatar": "http://file.ceccapitalgroup.com/logo/company/042d2e3fc59ae4265591e9336b976d40.png",
      "category": "company",
      "titleExtension": {
        "key": null,
        "ref": null,
        "props": {
          "color": "#1890FF",
          "children": "公司"
        },
        "_owner": null,
        "_store": {}
      },
      "selected": true
    }
  ],
  "currency": [
    {
      "value": "人民币",
      "label": "人民币"
    }
  ],
  "dealOpenDate": "2024-08-27T03:48:48.523Z",
  "dealGuessCompleteDate": "2025-08-01T03:49:13.482Z",
  "ownerUsers": [
    {
      "label": "Gang Li（李钢）",
      "value": 22,
      "avatar": "http://p.qlogo.cn/bizmail/lO4zoQt7cMBibbApLgyxE0T74crHziazAsaL39jlj6XaOEOrAnp7JmHw/0",
      "description": "Partner",
      "category": "user",
      "selected": false,
      "jobLevelIndex": 1,
      "valueIndex": 2
    }
  ],
  "ownerOtherUsers": [
    {
      "label": "Gang Li（李钢）",
      "value": 22,
      "avatar": "http://p.qlogo.cn/bizmail/lO4zoQt7cMBibbApLgyxE0T74crHziazAsaL39jlj6XaOEOrAnp7JmHw/0",
      "description": "Partner",
      "category": "user",
      "selected": false,
      "jobLevelIndex": 1,
      "valueIndex": 2
    },
    {
      "label": "Eric Li（李晋）",
      "value": 295,
      "avatar": "http://wework.qpic.cn/bizmail/sh7wBJXDPcHttaSXHial2T3bDBU1NA4P3NK0a1rdQzHFKgY8nDibTatQ/0",
      "description": "Partner",
      "category": "user",
      "selected": false,
      "jobLevelIndex": 1,
      "valueIndex": 3
    }
  ],
  "ecmUsers": [
    {
      "label": "Maggie Qi",
      "value": 43,
      "avatar": "http://p.qlogo.cn/bizmail/EiaIAPz321iaLlOTm2fQn68aB20gWIaiaslh9XKPFdia2xeoENJZQziapnw/0",
      "description": "MD",
      "category": "user",
      "selected": true
    },
    {
      "label": "Effie Zheng",
      "value": 134,
      "avatar": "http://p.qlogo.cn/bizmail/HOhDiauhzDZB6dLRibzX4HzP5PP0fxtzOdqIamFde6M4kYf7eSsu5K0w/0",
      "description": "VP",
      "category": "user",
      "selected": true
    }
  ],
  "pmUsers": [
    {
      "label": "Yang Fang（方洋）",
      "value": 140,
      "avatar": "http://p.qlogo.cn/bizmail/OEgOibS9UBpqxc4Lnrouias588NjtvhhP5iaIOk4ia4GSthBR91PWzQXug/0",
      "description": "ED",
      "category": "user",
      "selected": true,
      "jobLevelIndex": 3,
      "valueIndex": -1
    }
  ],
  "dealGroups": [
    {
      "label": "Pharma",
      "value": "target_group_3",
      "selected": false
    }
  ],
  "shareGroups": [
    {
      "label": "MedTech",
      "value": "target_group_5",
      "selected": true
    }
  ],
  "memberUsers": []
}