import React, { Fragment } from "react";
import { List, Button, Input, Space, TextArea, Toast } from 'antd-mobile'
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import _ from 'lodash';
import {
  IS_ACTUAL_CONTROLLER_OPTIONS,
  IS_ACTUAL_CONTROLLER_OPTIONS_VALUE_TRUE,
  IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS,
  IS_SHAREHOLDER_CHANGE_OPTIONS,
  IS_SHAREHOLDING_OPTIONS,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 股权结构更新
 */
export default class OwnershipStructurePopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props)

    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })

    this.state = {
      _info: info,
    }
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  renderBodyContent() {
    const { _info } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="是否有实际控制人"
            columns={2}
            options={IS_ACTUAL_CONTROLLER_OPTIONS}
            value={_info['isActualController'] ? _.map(_info['isActualController'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'isActualController', options)}
          >
            {
              _.isEqual(IS_ACTUAL_CONTROLLER_OPTIONS_VALUE_TRUE, _.head(_.map(_info['isActualController'], 'value'))) && (
                <TextArea placeholder="请输入实际控制人描述" clearable value={_info['isActualControllerText'] ? _info['isActualControllerText'] : ''} onChange={val => this.handleChange('change_value', 'isActualControllerText', val)} />
              )
            }
          </CECSelectorListItem>

          <CECSelectorListItem label="创始人/实际控制人在过去1年内是否发生变化"
            columns={2}
            options={IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS}
            value={_info['isActualControllerChange'] ? _.map(_info['isActualControllerChange'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'isActualControllerChange', options)}
          />

          <CECSelectorListItem label="创始人/股东在过去1年内是否发生变化"
            columns={2}
            options={IS_SHAREHOLDER_CHANGE_OPTIONS}
            value={_info['isShareholderChange'] ? _.map(_info['isShareholderChange'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'isShareholderChange', options)}
          />

          <List.Item extra={<Space><Input type="number" placeholder="请填写" value={_info['isActualControllerChangeRatio']} onChange={this.handleChange.bind(this, 'change_value', 'isActualControllerChangeRatio')} /><span>%</span></Space>}>创始人/实际控制人股权占比</List.Item>

          <CECSelectorListItem label="是否有明确控股机会"
            columns={2}
            options={IS_SHAREHOLDING_OPTIONS}
            value={_info['isShareholding'] ? _.map(_info['isShareholding'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'isShareholding', options)}
          />

          <List.Item extra={<Space><Input type="number" placeholder="请输入" clearable value={_info['manageShareholding'] ? _info['manageShareholding'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'manageShareholding')} /><span>%</span></Space>}>管理层股权占比</List.Item>

          <List.Item extra={<Space><Input type="number" placeholder="请输入" clearable value={_info['otherShareholding'] ? _info['otherShareholding'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'otherShareholding')} /><span>%</span></Space>}>所有外部机构股权占比</List.Item>

          <List.Item extra={<Space><Input type="number" placeholder="请输入" clearable value={_info['top3OtherShareholding'] ? _info['top3OtherShareholding'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'top3OtherShareholding')} /><span>%</span></Space>}>前三外部机构股权占比</List.Item>

          <List.Item extra={<Space><Input type="number" placeholder="请输入" clearable value={_info['maxOtherShareholding'] ? _info['maxOtherShareholding'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'maxOtherShareholding')} /><span>%</span></Space>}>最大外部机构股权占比</List.Item>
        </List>
      </Fragment>
    )
  }
  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}