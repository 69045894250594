import React, { Fragment, createRef } from "react";
import { List, Button, Input, Toast, } from 'antd-mobile'
import _ from 'lodash';
import BaseExtractValuePopup from '../BaseExtractValuePopup'
import Required from "../../../../../components/Required";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import AttendTargetPopup from "../AttendTargetPopup";
import CECSelectPopup from "../../../../../components/SelectPopup";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import {
  STATUS_OPTIONS,
  STATUS_OPTIONS_VALUE_NORMAL,
  STATUS_OPTIONS_VALUE_DISABLED,
  STATUS_OPTIONS_VALUE_PROMOTION,
  FetchCompanyOwnerUserList,
  FetchGPOwnerUserList,
  FetchLPOwnerUserList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 人事更新
 */
export default class EmploymentHistoryPopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props)
    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })

    this.state = {
      _info: info,
      _inputModelVisible: false,
      _visible: false,
    }
    this.searchUserKey = null
    this.dateRef = createRef()
    this.historyEmployeePopupRef = createRef()
    this.newEmployeePopupRef = createRef()
    this.employeeListPopRef = createRef()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()

    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)
    const {
      _info
    } = this.state

    switch (key) {
      case 'handle_switch_input_mode':
        this.employeeListPopRef?.current?.close()
        this.setState({ _inputModelVisible: true, _info: { code: _info['code'], targetType: _info['targetType'], name: this.searchUserKey } })
        break;
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } }, () => this.afterChange(data, event ? _.head(event) : {}))
        break;
      default:
        break;
    }
  }
  afterChange(dataIndex, data) {
    const { _info } = this.state
    if (dataIndex === 'name') {
      this.setState({
        _info: {
          ..._info,
          id: data['value'],
          job: _.get(data, 'extension.job'),
          phone: _.get(data, 'extension.phone'),
          email: _.get(data, 'extension.email'),
        }
      })
    }
  }
  close() {
    super.close()
    this.setState({ _inputModelVisible: false })
  }
  async saveInfo() {
    const { _info } = this.state
    const { mode, onConfirm } = this.props

    if (mode == "component") {// 无需提交表单
      this.close()
      onConfirm && onConfirm({ ..._info })
    } else {// 直接提交表单
      try {
        Toast.show({ icon: 'loading' })
        const response = await SaveInfo(_info)
        this.setState({ _info: response }, () => {
          this.close()
          onConfirm && onConfirm({ ...response })
          Toast.show({ content: '保存成功' })
        })
      } catch (error) {
        Toast.show({ content: error })
      }
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _inputModelVisible,
    } = this.state
    console.log(">>>>>info>>>>>>", _info)
    
    return (
      <Fragment>
        <List>
          <List.Item extra={_inputModelVisible ? <Input placeholder="请输入" value={_info['name']} onChange={this.handleChange.bind(this, 'change_value', 'name')} /> : (_info['name'] ? _.join(_.map(_info['name'], 'label')) : "请选择")}
            onClick={_inputModelVisible ? null : () => this.employeeListPopRef?.current?.toggle()}
          >
            姓名<Required />
          </List.Item>

          <List.Item extra={<Input placeholder="请输入" value={_info['job'] ? _info['job'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'job')} />}>职务</List.Item>

          <CECSelectorListItem label="人事动态"
            options={STATUS_OPTIONS}
            value={_info['status'] ? _.map(_info['status'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'status', options)}
          />
          {
            _.map(_info['status'], 'value').includes(STATUS_OPTIONS_VALUE_NORMAL) && (
              <List.Item extra={_info['historyEmployee'] ? _.join(_.map(_info['historyEmployee'], 'label'), '，') : "请选择"} onClick={() => this.historyEmployeePopupRef.current.toggle()}>上一任就职雇主</List.Item>
            )
          }
          {
            _.map(_info['status'], 'value').includes(STATUS_OPTIONS_VALUE_DISABLED) && <List.Item extra={_info['newEmployee'] ? _.join(_.map(_info['newEmployee'], 'label'), '，') : "请选择"} onClick={() => this.newEmployeePopupRef.current.toggle()}>离职去向</List.Item>
          }
          {
            _.map(_info['status'], 'value').includes(STATUS_OPTIONS_VALUE_PROMOTION) && <List.Item extra={<Input placeholder="请输入" value={_info['jobNew'] ? _info['jobNew'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'jobNew')} />}>升职后职务</List.Item>
          }
          <List.Item extra={<Input placeholder="请输入" value={_info['phone'] ? _info['phone'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'phone')} />}>电话 </List.Item>
          <List.Item extra={<Input placeholder="请输入" value={_info['email'] ? _info['email'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'email')} />}>Email</List.Item>
        </List>

        {/** 人员列表 */}
        <CECSelectPopup ref={this.employeeListPopRef}
          title={'人员列表'}
          supportSearch={true}
          supportAvatar={false}
          multiple={false}
          onFetchDatas={(params) => {
            this.searchUserKey = params['searchKey']
            if (_info['targetType'] === "info_enterprise") {
              return FetchCompanyOwnerUserList({ ...params, code: _info['code'] })
            } else if (_info['targetType'] === "info_market") {
              return FetchGPOwnerUserList({ ...params, code: _info['code'] })
            } else if (['info_government', 'info_family_office'].includes(_info['targetType'])) {
              return FetchLPOwnerUserList({ ...params, code: _info['code'] })
            }
          }}
          supportNotFound={() => <div style={{ color: '#999', textAlign: 'center', padding: '10px 0px', textDecoration: 'underline' }} onClick={this.handleClick.bind(this, 'handle_switch_input_mode')}>暂未找到，直接填写？</div>}
          values={_info['name']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'name')}
        />

        {/** 上一任就职雇主 */}
        <AttendTargetPopup ref={this.historyEmployeePopupRef}
          mode={"target"}
          metadata={{ title: "上一任就职雇主" }}
          onConfirm={(data) => this.handleChange('change_value', 'historyEmployee', data['targets'])}
        />

        {/** 离职去向 */}
        <AttendTargetPopup ref={this.newEmployeePopupRef}
          mode={"target"}
          metadata={{ title: "离职去向" }}
          onConfirm={(data) => this.handleChange('change_value', 'newEmployee', data['targets'])}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}