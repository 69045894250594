import React, { Fragment } from "react"
import _ from "lodash"
import request from "../../../api/HttpRequest"
import { Space, Tag } from "antd-mobile"

/**
 * 获取公司/机构/LP/企业列表
 * @param {*} searchKey 
 * @param {*} type ['comgplp', 'company', 'investment', 'LP'
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
export async function FetchTargetList({ searchKey, types, pageNum = 1, pageSize = 20, requestUrl = "/cec-sims-server/comgplp/search"}) {
  const params = {
    input: searchKey,
    base_types: types ? _.join(types) : '',
    pageNum: pageNum < 1 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", requestUrl, params)

    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, (item, index) => {

        const categorys = []
        const categoryLabels = []
        if (item['iscomp'] === 1) {
          categorys.push('company')
          categoryLabels.push("企业(产业方)")
        }
        if (item['isgp'] === 1) {
          categorys.push('investment')
          categoryLabels.push("市场化机构")
        }
        if (item['islp'] === 1) {
          categorys.push('lp')
          categoryLabels.push("政府/家办及个人/其他")
        }
        const typeLabels = item['type_name'] ? _.split(_.replace(item['type_name'], /，/g, ","), ',') : []
        const industryLabels = item['cat_yk_name'] ? _.split(_.replace(item['cat_yk_name'], /，/g, ","), ',') : []

        return {
          label: '测试100', //item['base_name'],
          value: 'CGL14520983467165793', //item['base_code'],
          avatar: item['base_logo'],
          type: 'comgplp', //item['base_type'],
          categorys: ['company', 'investment', 'lp'],//categorys,
          categoryLabels: categoryLabels,
          titleExtension: (
            <Space wrap>
              {
                item['location_name'] && <span style={{ color: '#999', fontSize: '12px' }}>{item['location_name']}</span>
              }
              {
                typeLabels.map(label => <Tag key={`typeLabels-${Math.random()}`} style={{ '--border-color': '#87e8de', '--text-color': '#08979c', '--background-color': '#e6fffb', '--font-size': '12px' }}>{label}</Tag>)
              }
            </Space>
          ),
          description: (
            <Space wrap>
              {
                categoryLabels.map(label => <Tag key={`categoryLabels-${Math.random()}`} color="primary">{label}</Tag>)
              }
              {
                industryLabels.map(label => <Tag key={`industryLabels-${Math.random()}`} color="primary">{label}</Tag>)
              }
            </Space>
          ),
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**公用是否选项 */
export function FetchTrueFlaseOptions() {
  return new Promise((resolve, reject)=> {
    resolve([
      {label: '是', value: true},
      {label: '否', value: false},
    ])
  })
}
export function FetchUnits(){
  return new Promise((resolve, reject)=> {
      resolve([
          { label: '千', value: 1000, },
          { label: '百万', value: 1000000, },
          { label: '十亿', value: 1000000000, }
      ])
  })
}
/**公用币种 */
export function FetchCurrencyOptions() {
  return new Promise((resolve, reject)=> {
    resolve([
      {label: '人民币', value: '人民币'},
      {label: '美元', value: '美元'},
      {label: '其他', value: '其他'}
    ])
  })
}
/**出资方身份 */
export function FetchInvestorIdentityOptions(){
  return BaseFetchOptions('Lable_smartx_gp_investor')
}
/**出资方类型 */
export function FetchInvestorTypeOptions(){
  return BaseFetchOptions('Lable_smartx_gp_investor_type')
}
/**资产类别  */
export function FetchAssetCategoryOptions(){
  return BaseFetchOptions('Lable_smartx_gp_asset_type')
}
/**计划融资规模*/
export function FetchScaleOptions(){
  return BaseFetchOptions('Lable_smartx_trade_scale', item=> {
      return ({...item, label: item['label'], value: item['label']})
  })
}
/**投资载体 */
export function FetchInvestmentVehicleOptions(){
  return BaseFetchOptions('Lable_smartx_invest_vehicle')
}
/**投资方式 */
export function FetchInvestmentMethodOptions(){
  return BaseFetchOptions('Lable_smartx_invest_way')
}
/**投资类别 */
export function FetchInvestmentCategoryOptions(){
  return BaseFetchOptions('Lable_smartx_asset_type')
}
/**投资标的属性 */
export function FetchInvestmentTargetAttributesOptions(){
  return BaseFetchOptions('Lable_smartx_invest_attribute')
}
/**地理市场 */
export function FetchGeographicalMarketOptions(){    
  return BaseFetchOptions('Lable_smartx_location')
}
/**当前状态 */
export function FetchCurrentStatueOptions(){
  return BaseFetchOptions('Lable_smartx_now_attitude', item=> ({...item, label: item['label'], value: item['label']}))
}
/**重要考量因素 */
export function FetchImportantConsiderationsOptions(){
  return BaseFetchOptions('Lable_smartx_consider')
}
/**易凯行业 */
export function FetchYiKaiIndustrys(etype = "Lable_comindu_yk2"){
  return new Promise((resolve, reject)=> {
    request('POST', '/cec-sims-server/labelelement/elementlist_level2',{ etype: etype, etype_p: ''})
      .then(res=> {
          let option = []
          _.map(res, item=> {
              let children = []
              _.map(item.children, sub=> {
                  children.push({
                      label: sub.sys_labelelement_name, 
                      value: sub.sys_labelelement_code, 
                      type: sub.sys_labelelement_type,
                      pId: item.sys_labelelement_code
                  })
              })
              option.push({
                  label: item.sys_labelelement_name, 
                  value: item.sys_labelelement_code, 
                  type: item.sys_labelelement_type,
                  children: children
              })
          })
          resolve(option)
      }).catch(msg=> reject(msg))
  })
}
/**GP地区 */
export function FetchRegionOptions(){
  return BaseFetchOptions('Lable_inv_bground')
}
/**Company地区 */
export function FetchCompanyRegionOptions(){
  return new Promise((resolve, reject)=> {
    request('POST', '/cec-sims-server/labelelement/elementlist', {etype: 'Lable_bground'}).then(response=>{
      resolve(
        _.filter(
          _.map(response, it=> {
            let format = {
              label: it['sys_labelelement_name'],
              value: it['sys_labelelement_code'],
              type: it['sys_labelelement_type'],
            }
            return format
          })
        , it=> it['value'] != 'Lable-bground-004' )
      )
    }).catch(error=> reject(error))
  })
}
/**Company 类型 */
export function FetchCompanyTypeOptions(){
  return new Promise((resolve, reject)=> {
    request('POST', '/cec-sims-server/labelelement/elementlist2', {etypes: 'Label_comptype'}).then(response=>{
      resolve(
        _.map(response['Label_comptype'], it=> {
          let format = {
            label: it['sys_labelelement_name'],
            value: it['sys_labelelement_code'],
            type: it['sys_labelelement_type'],
          }
          return format
        }) 
      )
    }).catch(error=> reject(error))
  })
}
/**Company 轮次 */
export function FetchCompanyRoundOptions(){
  return new Promise((resolve, reject) => {
    request('POST', '/cec-sims-server/labelelement/elementlist', { etype: 'Lable_trastage' }).then(response => {
      resolve(_.map(response, it => {
        let format = {
          label: it['sys_labelelement_name'],
          value: it['sys_labelelement_code'],
          type: it['sys_labelelement_type'],
          v1: _.get(it, 'v1', null),
          v2: _.get(it, 'v2', null),
        }
        if (_.isArray(it['children']) && _.size(it['children']) > 0) {
          format['children'] = _.map(it['children'], sub => ({
            label: sub['sys_labelelement_name'],
            value: sub['sys_labelelement_code'],
            type: sub['sys_labelelement_type'],
            pId: it['sys_labelelement_code'],
          }))
        }
        return format
      }))
    }).catch(error => {
      reject(error)
    })
  })
}
/**Company 估值 */
export function FetchCompanyValuationOptions(){
  return BaseFetchOptions('Lable_smartx_funding_scale', item=> ({...item, label: item['label'], value: item['label']}))
}

/**计划融资规模*/
export function FetchFinancingScopeOptions(){
  return new Promise((resolve, reject)=> {
    resolve([
      {label: '公司整体融资', value: 'ALL'},
      {label: '业务板块融资', value: 'PART'},
    ])
  })
}

export function FetchYesNoOptions(){
  return new Promise((resolve, reject)=> {
    resolve([
      {label: '是', value: 'YES'},
      {label: '否', value: 'NO'},
      {label: '不确定', value: 'UNSURE'},
    ])
  })
}

/**目标基金规模 */
export function FetchFundingScaleOptions(){
  return BaseFetchOptions('Lable_smartx_funding_scale', item=> ({...item, label: item['label'], value: item['label']}))
}
/**基金属性 */
export function FetchFundAttributesOptions(){
  return BaseFetchOptions('Lable_smartx_funding_attribute')
  
}

/** 获取标签 */
function BaseFetchOptions(type, formatItem) {
  return new Promise((resolve, reject) => {
    request('POST', '/cec-sims-server/labelelement/elementlist_level2', { etype: type }).then(response => {
      resolve(_.map(response, it => {
        let format = {
          label: it['sys_labelelement_name'],
          value: it['sys_labelelement_code'],
          type: it['sys_labelelement_type'],
          v1: _.get(it, 'v1', null),
          v2: _.get(it, 'v2', null),
        }
        if (_.isArray(it['children']) && _.size(it['children']) > 0) {
          format['children'] = _.map(it['children'], sub => ({
            label: sub['sys_labelelement_name'],
            value: sub['sys_labelelement_code'],
            type: sub['sys_labelelement_type'],
            pId: it['sys_labelelement_code'],
          }))
        }
        return formatItem ? formatItem(format) : format
      }))
    }).catch(error => {
      reject(error)
    })
  })
}
