import _ from "lodash"
import moment from "moment"
import request from "../../api/HttpRequest"


/**
 * 交换Code
 * @param {*} params 
 * @returns {
*  enterpriseCode 企业Code
*  companyCode 公司Code
*  gpCode 投资机构Code
*  lpCode LP合伙人Code
* }
*/
export async function SwapRelationCode({ code, type }) {
  try {
    const response = await request("POST", "/cec-sims-server/comgplp/simple/info/get", { code: code, type: type })
    return {
      enterpriseCode: _.get(response, "code"),
      companyCode: _.get(response, "main_code_com"),
      gpCode: _.get(response, "main_code_gp"),
      lpCode: _.get(response, "main_code_lp")
    }
  } catch (error) {
    throw error
  }
}

/**
 * 创建对象信息
 * @param {*} category 
 * @param {*} data 
 * @param {*} successCallback 
 * @param {*} failCallback 
 */
export async function SaveTargetInfo(category, data) {
  if (category == 'company') {
    return SaveTargetCompanyInfo(data)
  } else if (category == 'investment') {
    return SaveTargetInvestmentInfo(data)
  } else if (category == 'lp') {
    return SaveTargetLPInfo(data)
  }
}
/**
 * 创建对象信息 - 公司
 * @param {*} data 
 */
export async function SaveTargetCompanyInfo(data) {
  console.log("SaveTargetCompanyInfo data >>>>", data)

  let elements = []
  if (data['types'] && _.size(data['types']) > 0) {
    data['types'].find(item => {
      elements.push({ 'sys_labelelement_code': item['value'] })
    })
  }
  if (data['industrys'] && _.size(data['industrys']) > 0) {
    data['industrys'].find(item => {
      if (item['children'] && _.size(item['children']) > 0) {
        item['children'].find(child => {
          elements.push({ "sys_labelelement_code": child['value'] })
        })
      }
      elements.push({ "sys_labelelement_code": item['value'] })
    })
  }
  if (data['ecIndustrys'] && _.size(data['ecIndustrys']) > 0) {
    data['ecIndustrys'].find(item => {
      if (item['children'] && _.size(item['children']) > 0) {
        item['children'].find(child => {
          elements.push({ "sys_labelelement_code": child['value'] })
        })
      }
      elements.push({ "sys_labelelement_code": item['value'] })
    })
  }

  if (!data['companyName']) {
    throw '请输入公司名称'
  }
  if (!data['area'] || _.size(data['area']) == 0) {
    throw '请选择地区'
  }
  if (!data['types'] || _.size(data['types']) == 0) {
    throw '请选择公司类型'
  }
  if (!data['ecIndustrys'] || _.size(data['ecIndustrys']) == 0) {
    throw '请选择易凯行业'
  }

  const payloadData = {
    base_comp_code: data['code'],
    base_comp_name: data['companyName'],
    base_comp_img: data['logo'],
    base_comp_fullname: data['boardName'],
    comp_born_year: data['companyEstablish'] ? moment(data['companyEstablish']).get('year') : null,
    comp_born_month: data['companyEstablish'] ? moment(data['companyEstablish']).get('month') + 1 : null,
    location_code: data['area'] ? _.join(_.map(data['area'], "value")) : null,
    location_name: data['area'] ? _.join(_.map(data['area'], "label")) : null,
    comp_scale: data['scale'],
    base_comp_about: data['description'],
    elements: JSON.stringify(elements),
  }

  try {
    const response = await request("POST", "/cec-sims-server/company/save", payloadData)
    let responseBody = null
    if (response) {
      responseBody = {
        label: _.get(response, 'base_comp_name'),
        value: _.get(response, 'base_comp_code'),
        category: "company",
        selected: true,
      }
    }
    return responseBody
  } catch (error) {
    throw error
  }
}
/**
 * 创建对象信息 - 机构
 * @param {*} data 
 */
export async function SaveTargetInvestmentInfo(data) {
  // console.log("SaveTargetInvestmentInfo data >>>", data)

  let elements = []
  if (data['stages'] && _.size(data['stages']) > 0) {
    data['stages'].find(item => {
      elements.push({ "sys_labelelement_code": item['value'], "sys_labelelement_name": item['label'] })
    })
  }
  if (data['types'] && _.size(data['types']) > 0) {
    data['types'].find(item => {
      elements.push({ "sys_labelelement_code": item['value'], "sys_labelelement_name": item['label'] })
    })
  }

  if (data['ecIndustrys'] && _.size(data['ecIndustrys']) > 0) {
    data['ecIndustrys'].find(item => {
      if (item['children'] && _.size(item['children']) > 0) {
        item['children'].find(child => {
          elements.push({ "sys_labelelement_code": child['value'], "sys_labelelement_name": child['label'] })
        })
      }
      elements.push({ "sys_labelelement_code": item['value'], "sys_labelelement_name": item['label'] })
    })
  }

  let ecapitalUsers = data['ecapitalUsers'] ? _.map(data['ecapitalUsers'], item => ({ 'userid': item['value'], 'username': item['label'] })) : []

  const payloadData = {
    base_investment_code: data['code'],
    base_investment_name: data['orgName'],
    base_investment_img: data['logo'],
    base_investment_about: data['description'],
    location_code: data['area'] ? _.join(_.map(data['area'], 'value')) : null,//地区
    location_code2: data['area2'] ? _.join(_.map(data['area2'], 'value')) : null,//地域
    location_name2: data['area2'] ? _.join(_.map(data['area2'], 'value')) : null,
    currency_code: data['currencys'] ? _.join(_.map(data['currencys'], 'value')) : null,
    currency_name: data['currencys'] ? _.join(_.map(data['currencys'], 'label')) : null,
    investment_base: data['groupLocation'],//集团所在地
    investment_stock_exchange: data['listonLocation'],//上市地
    elements: JSON.stringify(elements),
    ykuser: JSON.stringify(ecapitalUsers),
    active_investor_codes: data['activeInvestmentUsers'] ? _.join(_.map(data['activeInvestmentUsers'], "value")) : null,//活跃投资人
  }

  try {
    const response = await request("POST", "/cec-sims-server/investment/save", payloadData)
    let responseBody = null
    if (response) {
      responseBody = {
        label: _.get(response, 'base_investment_name'),
        value: _.get(response, 'base_investment_code'),
        category: "investor",
        selected: true,
      }
    }
    return responseBody
  } catch (error) {
    throw error
  }
}

/**
 * 创建对象信息 - LP
 * @param {*} data 
 */
export async function SaveTargetLPInfo(data) {
  console.log("SaveTargetLPInfo data >>>", data)

  let types = []
  if (data['types'] && _.size(data['types']) > 0) {
    types = _.map(data['types'], item => ({ "sys_labelelement_code": item['value'], "sys_labelelement_type": "Label_type" }))
  }

  let specialConditions = []
  if (data['specialConditions'] && _.size(data['specialConditions']) > 0) {
    specialConditions = _.map(data['specialConditions'], item => {
      const specialCondition = _.head(_.get(item, "specialCondition", []))
      const active = _.head(_.get(item, "active", []))
      return {
        "sys_labelelement_pcode": null,
        "sys_labelelement_code": _.get(specialCondition, "value", ""),
        "sys_labelelement_type": "Label_lp_special_condition",
        "flagint": _.get(active, "value", ""),
      }
    })
  }

  let manageScaleUnit = _.get(_.head(_.get(data, "manageScale.unit")), "value")
  let manageScaleCurrency = _.get(_.head(_.get(data, "manageScale.currency")), "label")
  let manageScaleAmount = _.get(data, "manageScale.amountValue")
  let manageScaleAmountValue = manageScaleAmount && manageScaleUnit ? manageScaleAmount * manageScaleUnit : manageScaleAmount

  let manageFundScaleUnit = _.get(_.head(_.get(data, "manageFundScale.unit")), "value")
  let manageFundScaleCurrency = _.get(_.head(_.get(data, "manageFundScale.currency")), "label")
  let manageFundScaleAmount = _.get(data, "manageFundScale.amountValue")
  let manageFundScaleAmountValue = manageFundScaleAmount && manageFundScaleUnit ? manageFundScaleAmount * manageFundScaleUnit : manageFundScaleAmount

  let outScaleUnit = _.get(_.head(_.get(data, "outScale.unit")), "value")
  let outScaleCurrency = _.get(_.head(_.get(data, "outScale.currency")), "label")
  let outScaleAmount = _.get(data, "outScale.amountValue")
  let outScaleAmountValue = outScaleAmount && outScaleUnit ? outScaleAmount * outScaleUnit : outScaleAmount

  let investmentWayUnit = _.get(_.head(_.get(data, "investmentWay.unit")), "value")
  let investmentWayCurrency = _.get(_.head(_.get(data, "investmentWay.currency")), "label")
  let investmentWayAmount = _.get(data, "investmentWay.amountValue")
  let investmentWayAmountValue = investmentWayAmount && investmentWayUnit ? investmentWayAmount * investmentWayUnit : investmentWayAmount

  let industrys = []
  if (data['industryAndStages'] && _.size(data['industryAndStages']) > 0) {
    industrys = _.map(data['industryAndStages'], item => {
      const industry = _.head(_.get(item, "industrys", []))
      const children = _.get(industry, "children", [])
      const stage = _.head(_.get(item, "stages", []))
      return {
        "sys_labelelement_pcode": children && _.size(children) > 0 ? _.get(industry, "value", "") : "Label_lp_comindu_yk",
        "sys_labelelement_code": children && _.size(children) > 0 ? _.get(_.head(children), "value", "") : item['value'],
        "sys_labelelement_type": "Label_lp_comindu_yk",
        "flagint": item['score'],
        "stagecodes": _.get(stage, "value"),
      }
    })
  }

  const payloadData = {
    base_lp_code: data['code'],
    base_lp_name: data['lpName'],
    yktag: data['coverage'] === true ? 'YK' : null,
    activeflag: data['active'],
    elements: JSON.stringify(_.concat(types, specialConditions)),
    cdate: data['lpEstablish'] ? moment(data['lpEstablish']).toDate().toString() : null,
    province: data['area'] && _.size(data['area']) > 0 ? _.get(_.head(data['area']), "label") : null,
    city: data['area'] && _.size(data['area']) > 1 ? _.get(_.last(data['area']), "label") : null,
    location: data['districtText'],
    address: data['address'],
    // 返投条件
    reverse_condition: data['reinvestmentConditions'],
    // 管理规模
    manage2_money: manageScaleAmountValue,
    manage2_unit: manageScaleUnit,
    manage2_currency: manageScaleCurrency,
    // 基金规模
    manage_money: manageFundScaleAmountValue,
    manage_unit: manageFundScaleUnit,
    manage_currency: manageFundScaleCurrency,
    // 出资规模
    scale: outScaleAmountValue,
    base_lp_currency: outScaleCurrency,
    scale_unit: outScaleUnit,
    // 已出资
    financed_flag: data['investment'],
    financed_money: investmentWayAmountValue,
    financed_currency: investmentWayCurrency,
    financed_unit: investmentWayUnit,
    // 受托管机构
    fund_manager_investment_code: _.join(_.map(data['receiveInvestments'], 'value')),
    fund_manager_investment_name: _.join(_.map(data['receiveInvestments'], 'label')),
    // 期限
    deadline: _.join(_.map(data['term'], 'value')),
    // 投资倾向
    leanto: _.join(_.map(data['tendency'], 'value')),
    // 易凯负责人
    ykuser: data['ecapitalUsers'] && _.size(data['ecapitalUsers']) > 0 ? JSON.stringify(_.map(data['ecapitalUsers'], item => ({ "userid": item['value'], "username": item['label'] }))) : null,
    // 决策流程
    note_decision: data['voteProgress'],
    // 简介
    note: data['description'],
    // 备注
    note2: data['note'],
    // 行业 阶段 评分
    catandstage: JSON.stringify(industrys),
  }

  try {
    const response = await request("POST", "/cec-sims-server/lp/save", payloadData)
    let responseBody = null
    if (response) {
      responseBody = {
        label: _.get(response, 'base_lp_name'),
        value: _.get(response, 'base_lp_code'),
        category: "lp",
        selected: true,
      }
    }
    return responseBody
  } catch (error) {
    throw error
  }
}
