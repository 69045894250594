import React, { useCallback } from "react";
import _ from "lodash";
import { Collapse, } from "antd-mobile";
import { RightOutline, DownOutline, } from 'antd-mobile-icons'
import Tree from "../Tree";
import './index.less';


/**
 * 菜单Item
 * @param {*} param
 * @returns 
 */
export default function MenuListItem({ metadata }) {

  const handleClick = useCallback((key, data, event) => {
    switch (key) {
      case 'handle_select_item':
        if (metadata['category'] === 'collapse') {
          if (data && _.size(data) > 0) {
            const lastItem = _.last(_.get(data, "data.path"))
            if (lastItem.ref) {
              lastItem.ref?.current?.toggle()
            }
          }
        } else if (metadata['category'] === 'link') {
          metadata.ref?.current?.toggle()
        }
        break;
      default:
        break;
    }
  })

  return (
    <div className="menu-item-box">
      {
        metadata['category'] === 'collapse' && (
          <div className="menu-item-wrap">
            <Collapse accordion className="menu-item-collapse">
              <Collapse.Panel key={metadata['key']} title={metadata['title']} arrow={active => active ? <DownOutline /> : <RightOutline />}>
                <Tree metadata={{}}
                  datas={metadata['children']}
                  onSelect={handleClick.bind(null, 'handle_select_item')}
                  onConfirm={(data) => console.log('onConfirm >>>', data)}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        )
      }
      {
        metadata['category'] === 'link' && (
          <div className="menu-item-wrap" onClick={handleClick.bind(null, 'handle_select_item')}>
            <div className="menu-item">
              <div className="menu-item-title">{metadata['title']}</div>
              <div className="menu-item-extra">
                {metadata['extra'] && <RightOutline style={{ fontSize: 20, color: '#ccc' }} />}
              </div>
            </div>
            {
              metadata['render'] && (<div className="menu-description">{metadata['render']()}</div>)
            }
          </div>
        )
      }
    </div>
  )
}