import _ from "lodash";
import BaseExtractPopup from "../../../../../components/BaseExtractPopup";


/**
 * 获取更新对象的信息值
 */
export default class BaseExtractValuePopup extends BaseExtractPopup {
  /**
   * 更新对象的Value
   * 
   * @description 根据当前选择对象类型，返回相应对象类型的Code
   * 1、企业(产业方) 对应 公司code
   * 2、市场化机构 对应 机构code
   * 3、政府、家办及个人 对应 LP code
   */
  getAttendTargetValue() {
    const { metadata, extensionValue } = this.props
    // console.log("getAttendTargetValue extensionValue >>>>", extensionValue, " metadata >>>>>", metadata)

    if (!extensionValue) {
      throw new Error("getAttendTargetValue() extensionValue can't be empty");
    }

    const targets = _.get(extensionValue, `${metadata['targetRefKey']}.targets`)
    const target = _.head(targets)
    const targetTypes = _.get(extensionValue, `${metadata['targetRefKey']}.targetTypes`)
    const targetType = _.head(targetTypes)

    // 统一处理相应对象的Code值
    let targetValue = target ? target['value'] : null
    let targetLabel = target ? target['label'] : null
    if (target && targetType) {
      if (targetType['value'] === "info_enterprise") {
        targetValue = target['companyCode']
      } else if (targetType['value'] === "info_market") {
        targetValue = target['gpCode']
      } else if (["info_government", "info_family_office"].includes(targetType['value'])) {
        targetValue = target['lpCode']
      }
    }

    return {
      value: targetValue,
      label: targetLabel,
      type: targetType ? targetType['value'] : null,
      typeLabel: targetType ? targetType['label'] : null,
      extensionValue: {
        targets,
        targetTypes,
      }
    }
  }
}