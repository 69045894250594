import { createRef } from "react";
import _ from "lodash";
import { TRADING_OPPORTUNITY_FOR_ENTERPRISE_OPTIONS, TRADING_OPPORTUNITY_ORG_OPTIONS, TRADING_OPPORTUNITY_OFFICE_OPTIONS } from '../configuration'
import AttendTargetPopup from '../components/AttendTargetPopup'
import BussinessStrategyPopup from '../components/BussinessStrategyPopup'
import FinancialDataPopup from '../components/FinancialDataPopup'
import FinancingHistoryPopup from '../components/FinancingHistoryPopup'
import FundraisingPopup from '../components/FundraisingPopup'
import InvestmentCasePopup from '../components/InvestmentCasePopup'
import OwnershipStructurePopup from '../components/OwnershipStructurePopup'
import EmploymentHistoryPopup from '../components/EmploymentHistoryPopup'
import GovernmentPopup from '../components/GovernmentPopup'
import FamilyOfficePopup from '../components/FamilyOfficePopup'
import FundPopup from "../components/FundPopup";
import InvestmentSectorPopup from "../components/InvestmentSectorPopup";
import Tree from "../../../../components/Tree";

const ATTEND_TARGET_TYPE_OPTIONS = [
  { label: '企业（产业方）', value: 'info_enterprise', avatar: null, selected: false },
  { label: '市场化机构', value: 'info_market', avatar: null, selected: false },
  { label: '政府', value: 'info_government', avatar: null, selected: false },
  { label: '家办及个人', value: 'info_family_office', avatar: null, selected: false },
]

export const COMPONENT_OPTION_LIST = [
  {
    label: '更新对象',
    key: 'info_option_list_attend_target',
    showItem: true,
    required: true,
    ref: createRef(),
    title: '更新对象',
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => (
      <AttendTargetPopup key={metadata['key']} ref={metadata.ref} targetTypeOptions={[...ATTEND_TARGET_TYPE_OPTIONS]} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} onConfirm={onConfirm} />
    ),
  }, {
    label: '更新内容',
    key: 'info_option_list_update_content',
    className: 'cec-hidden-arrow',
    showItem: true,
    required: false,
    disabled: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      const targetValues = _.get(extensionValue, `info_option_list_attend_target.targets`),
        targetTypeValues = _.get(extensionValue, `info_option_list_attend_target.targets`)
      if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
        return false
      }
      return true
    },
    getValue: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      var responseValue = {}
      const targetTypeValues = _.get(extensionValue, `info_option_list_attend_target.targetTypes`)
      if (targetTypeValues && _.size(targetTypeValues) > 0) {
        targetTypeValues.find(item => item['key'] = item['value'])
        responseValue = {
          values: _.map(targetTypeValues, 'value'),
          items: targetTypeValues,
        }
      }
      return responseValue
    },
    placeholder: '',
    // 可自定义Label后缀返回信息
    getLabelSuffix: ({ groupKey, metadata, sourcedata, extensionValue }) => {
      const targetValues = _.get(extensionValue, `info_option_list_attend_target.targets`),
        targetTypeValues = _.get(extensionValue, `info_option_list_attend_target.targetTypes`)
      let labelValue = ''
      if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
        labelValue = `${_.join(_.map(targetValues, 'label'))} - ${_.join(_.map(targetTypeValues, 'label'))}`
      }
      return labelValue ? <span style={{ whiteSpace: 'nowrap' }}>【{labelValue}】</span> : null
    },
    multiPathComponentRender: ({ groupKey, metadata, sourcedata, extensionValue, onSelect, onConfirm, onClick }) => {
      const targetValues = _.get(extensionValue, `info_option_list_attend_target.targets`),
        targetTypeValues = _.get(extensionValue, `info_option_list_attend_target.targetTypes`)
      let labelValue = ''
      let options = []
      if (targetValues && _.size(targetValues) > 0 && targetTypeValues && _.size(targetTypeValues) > 0) {
        labelValue = `${_.join(_.map(targetValues, 'label'))} - ${_.join(_.map(targetTypeValues, 'label'))}`

        const targetType4UpdateItem = _.head(_.filter(_.get(sourcedata, `options`), { key: _.head(_.map(targetTypeValues, 'value')) }))
        if (targetType4UpdateItem && targetType4UpdateItem['children']) {
          options = targetType4UpdateItem['children']
        } else {
          options = [{ ...targetType4UpdateItem }]
        }
      }

      let values = _.get(extensionValue, `${metadata.key}`)
      return (
        <Tree placeholder="请选择更新内容" metadata={metadata} sourcedata={sourcedata} values={values} datas={options} onSelect={onSelect} onConfirm={onConfirm} onClick={onClick} />
      )
    },
  },
]



// 多层级关联组件映射关系
export const COMPONENT_OPTION_LIST_MULTIPLE_CASCADER_PATHS = {
  'info_option_list_update_content': {
    options: [
      {
        label: '企业（产业方）',
        key: 'info_enterprise',
        children: [
          {
            label: '策略及业务方向更新',
            key: 'info_enterprise_bussiness_strategy',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_BUSINESS",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => (
              <BussinessStrategyPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />
            ),
          }, {
            label: '财务数据更新',
            key: 'info_enterprise_bussiness_financial_data',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_FINANCIAL",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FinancialDataPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />
          }, {
            label: '交易机会更新',
            key: 'info_enterprise_trading_opportunity',
            children: [...TRADING_OPPORTUNITY_FOR_ENTERPRISE_OPTIONS]
          }, {
            label: '股权结构更新',
            key: 'info_enterprise_trading_opportunity_ownership_structure',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_OWNERSHIP",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <OwnershipStructurePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          }, {
            label: '融资/收购历史更新',
            key: 'info_enterprise_trading_history',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FinancingHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />
          }, {
            label: '投资案例更新',
            key: 'info_enterprise_trading_opportunity_investment_case',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentCasePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          }, {
            label: '人事更新',
            key: 'info_enterprise_trading_opportunity_employment_history',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_HR",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <EmploymentHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          },
        ]
      }, {
        label: '市场化机构',
        key: 'info_market',
        children: [
          {
            label: '基金属性',
            key: 'info_market_fund',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_PROP",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FundPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />
          }, {
            label: '基金募集更新',
            key: 'info_market_fundraising',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_FUNDINGNEWS",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FundraisingPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />
          }, {
            label: '投资方向更新',
            key: 'info_market_investment_focus',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_DIRECTION",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentSectorPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />
          }, {
            label: '交易机会更新',
            key: 'info_market_trading_opportunity',
            children: [...TRADING_OPPORTUNITY_ORG_OPTIONS]
          }, {
            label: '投资案例更新',
            key: 'info_market_investment_case',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentCasePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          }, {
            label: '人事更新',
            key: 'info_market_employment_history',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_HR",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <EmploymentHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          },
        ]
      }, {
        label: '政府',
        key: 'info_government',
        children: [
          {
            label: '基本信息',
            key: 'info_government_base_info',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "LP_GOV_FAMILY",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <GovernmentPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          }, {
            label: '人事更新',
            key: 'info_government_employment_history',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "LP_GOV_FAMILY_HR",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <EmploymentHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          },
        ],
      }, {
        label: '家办及个人',
        key: 'info_family_office',
        children: [
          {
            label: '基本信息',
            key: 'info_family_office_base_info',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "LP_GOV_FAMILY",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FamilyOfficePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          }, {
            label: '投资方向更新',
            key: 'info_market_investment_focus',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentSectorPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />
          }, {
            label: '交易机会更新',
            key: 'info_market_trading_opportunity',
            children: [...TRADING_OPPORTUNITY_OFFICE_OPTIONS]
          }, {
            label: '投资案例更新',
            key: 'info_market_investment_case',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "LP_GOV_FAMILY_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentCasePopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          }, {
            label: '人事更新',
            key: 'info_market_employment_history',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "LP_GOV_FAMILY_HR",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <EmploymentHistoryPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} onConfirm={onConfirm} />,
          },
        ],
      },
    ]
  }
}