import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../components/AmountNumber";


// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]
// 性质
export const FAMILY_OFFICE_CATEGORY_OPTIONS = [
  { label: '单家族办公室', value: 'true' },
  { label: '多家族办公室', value: 'false' },
]
// 单笔投资金额
export const SINGLE_INVESTMENT_RANGE_OPTIONS = [
  { label: '下限', value: '下限' },
  { label: '舒适区', value: '舒适区' },
  { label: '上限', value: '上限' },
]

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type']
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 保存 政府更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  // 总管理规模
  let overallTargetScaleCurrency = info['overallTargetScaleCurrencyOther']
  if (!overallTargetScaleCurrency) {
    overallTargetScaleCurrency = info['overallTargetScaleCurrency'] ? _.join(_.map(info['overallTargetScaleCurrency'], "value")) : null
  }
  let overallTargetScaleUnit = info['overallTargetScaleUnit'] ? _.join(_.map(info['overallTargetScaleUnit'], "value")) : null
  let overallTargetScaleValue = parseAmountNumber(info['overallTargetScale'], overallTargetScaleUnit)

  // 已到位规模
  let establishedScaleCurrency = info['establishedScaleCurrencyOther']
  if (!establishedScaleCurrency) {
    establishedScaleCurrency = info['establishedScaleCurrency'] ? _.join(_.map(info['establishedScaleCurrency'], "value")) : null
  }
  let establishedScaleUnit = info['establishedScaleUnit'] ? _.join(_.map(info['establishedScaleUnit'], "value")) : null
  let establishedScaleValue = parseAmountNumber(info['establishedScale'], establishedScaleUnit)

  // 单笔投资规模
  let singleStrokeMoneyCurrency = info['singleStrokeMoneyCurrencyOther']
  if (!singleStrokeMoneyCurrency) {
    singleStrokeMoneyCurrency = info['singleStrokeMoneyCurrency'] ? _.join(_.map(info['singleStrokeMoneyCurrency'], "value")) : null
  }
  let singleStrokeUnit = info['singleStrokeUnit'] ? _.join(_.map(info['singleStrokeUnit'], "value")) : null
  let singleStrokeBeginMoneyValue = parseAmountNumber(info['singleStrokeBeginMoney'], singleStrokeUnit)
  let singleStrokeEndMoneyValue = parseAmountNumber(info['singleStrokeEndMoney'], singleStrokeUnit)

  let payload = {
    id: info['id'],
    base_lp_code: info['code'],
    familyone: info['category'] ? _.join(_.map(info['category'], 'value')) : null,
    money_sources: info['mainSourceOfFunds'],
    managecurrency: overallTargetScaleCurrency,
    manageunit: overallTargetScaleUnit,
    managevalue: overallTargetScaleValue,
    managevalue2: _.join(_.map(info['overallTargetScaleOption'], "label")),
    inplacecurrency: establishedScaleCurrency,
    inplaceunit: establishedScaleUnit,
    inplacevalue: establishedScaleValue,
    inplacevalue2: _.join(_.map(info['establishedScaleOption'], "label")),
    singlecurrency: singleStrokeMoneyCurrency,
    singleunit: singleStrokeUnit,
    singlevalue1: singleStrokeBeginMoneyValue,
    singlevalue2: singleStrokeEndMoneyValue,
    interest: info['specialPreferences'],
    showflag: info['id'] ? true : false,
  }

  try {
    const response = await request("POST", "/cec-sims-server/smartx/lp/base/family/save", payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 政府历史更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/smartx/lp/base/family/delete", { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}