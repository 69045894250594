import { Fragment } from "react"
import _ from "lodash"
import GovEmploymentHistoryListView from "./GovEmployeeHistoryListView"
import GovBaseInfoListView from "./GovBaseInfoListView"
import FamilyOfficeBaseInfoListView from "./FamilyOfficeBaseInfoListView"
import FamilyOfficeInvestmentSectorListView from "./FamilyOfficeInvestmentSectorListView"
import FamilyOfficeInvestmentCaseListView from "./FamilyOfficeInvestmentCaseListView"
import FamilyOfficeEmploymentHistoryListView from "./FamilyOfficeEmployeeHistoryListView"

/**
 * 政府、家办及个人 - 表单部分
 * @param {*} param0 
 * @returns 
 */
export default function LPBodyInfoView({ code, targets, tabs }) {
  const currentActiveInfoTab = _.get(_.head(_.filter(tabs, { selected: true })), "key")
  // 组装编辑页面使用的数据
  const targetTypes4Gov = [{ value: "info_government", label: "政府" }]
  const targetTypes4FamilyOffice = [{ value: "info_family_office", label: "家办及个人" }]
  const targetExtensionValue4Gov = {
    targets,
    targetTypes: targetTypes4Gov
  }
  const targetExtensionValue4FamilyOffice = {
    targets,
    targetTypes: targetTypes4FamilyOffice
  }

  return (
    <Fragment>
      {
        currentActiveInfoTab === 'lp_info_tab_1' && <GovBaseInfoListView code={code} targetExtensionValue={targetExtensionValue4Gov} />
      }
      {
        currentActiveInfoTab === 'lp_info_tab_2' && <GovEmploymentHistoryListView code={code} targetExtensionValue={targetExtensionValue4Gov} />
      }
      {
        currentActiveInfoTab === 'lp_info_tab_3' && <FamilyOfficeBaseInfoListView code={code} targetExtensionValue={targetExtensionValue4FamilyOffice} />
      }
      {
        currentActiveInfoTab === 'lp_info_tab_4' && <FamilyOfficeInvestmentSectorListView code={code} targetExtensionValue={targetExtensionValue4FamilyOffice} />
      }
      {
        currentActiveInfoTab === 'lp_info_tab_5' && <div></div>
      }
      {
        currentActiveInfoTab === 'lp_info_tab_6' && <FamilyOfficeInvestmentCaseListView code={code} type="LP" targetExtensionValue={targetExtensionValue4FamilyOffice} />
      }
      {
        currentActiveInfoTab === 'lp_info_tab_7' && <FamilyOfficeEmploymentHistoryListView code={code} targetExtensionValue={targetExtensionValue4FamilyOffice} />
      }
    </Fragment>
  )
}