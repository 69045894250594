import React, { createRef, Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import { Button, Input, List, Picker } from "antd-mobile";
import BaseExtractPopup from "../../../../../components/BaseExtractPopup";
import CECSelectPopup from "../../../../../components/SelectPopup";
import MultipleCascaderPopup from "../../../../../components/MultipleCascaderPopup";
import Required from "../../../../../components/Required";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import CECSelectUserAndGroupPopup from "../../../../../components/SelectUserAndGroupPopup";
import CECCalendarPopup from '../../../../../components/CalendarPopup'
import {
  FetchCECUserCallbackList,
} from "../../transform";
const FORM_LABEL_OPTIONS = [
  { label: "会议日期", key: "memoDate", type: "date" },
  { label: "会议性质", key: "memoCategory", type: "options" },
  { label: "业务机会类型", key: "memoBussinessCategory", type: "options" },
  { label: "是否有交易机会", key: "memoTradeChoice", type: "options" },
  { label: "是否公开", key: "memoIsPublic", type: "options" },
  { label: "对会议可见（指定人）", key: "memoAuthUsers", type: "options" },
  { label: "对会议可见", key: "memoAuthUsersDepts", type: "options" },
  { label: "对会议可见", key: "memoAuthUsersGroups", type: "options" },
  { label: "是否推送", key: "memoIsPush", type: "options" },
  { label: "推送到人", key: "memoPushUsers", type: "options" },
]
const FORM_LABEL_MAP = _.chain(FORM_LABEL_OPTIONS).keyBy('key').mapValues('label').value()
/**
 * 【会议更新：基本信息】
 */
export default class BaseInfoPopup extends BaseExtractPopup {

  constructor(props) {
    super(props);

    this.state = {
      _optionPickerKey: null,
      _multipleSelectKey: null,
      _baseData: {},
      _cecUserAndGroupOptions: [],
      _cecUserAndDeptOptions: [],
    }

    this.memoDateRef = createRef();
    this.optionsRef = createRef();
    this.multipleSelectPopupRef = createRef()
    this.multipleCascaderPopup = createRef()
    this.multipleSelectUserAndGroupPopupRef = createRef()
    this.multipleSelectUserAndDeptPopupRef = createRef()
  }

  componentDidMount() {
    this.fetchCECUserList()
  }

  handleClick(key, data, event) {
    // console.log('handleClick >>>>', key, data, event)
    let {
      _baseData,
      _multipleSelectKey,
      _optionPickerKey,
    } = this.state
    const {
      onConfirm
    } = this.props

    switch (key) {
      case 'handle_toggle_option_picker':
        this.setState({ _optionPickerKey: data })
        this.optionsRef?.current?.toggle()
        break;
      case 'handle_toggle_multiple_select_popup':
        this.setState({ _multipleSelectKey: data })
        this.multipleSelectPopupRef?.current?.toggle()
        break;
      case 'handle_toggle_multiple_cascader_popup':
        this.setState({
          _multipleSelectKey: data,
          _baseData: _.assign({}, _baseData, {
            memoAuthUsers: [],
            memoAuthUsersDepts: [],
            memoAuthUsersGroups: [],
          })
        })
        this.multipleCascaderPopup?.current?.toggle()
        break;
      case 'handle_toggle_multiple_select_user_and_group_popup':
        this.multipleSelectUserAndGroupPopupRef.current?.toggle()
        break;
      case 'handle_toggle_multiple_select_user_and_dept_popup':
        this.multipleSelectUserAndDeptPopupRef.current?.toggle()
        break;
      case 'handle_confirm_option_picker':
        const sourceDatas = PICKER_OPTIONS[_optionPickerKey]
        if (_.size(sourceDatas) > 1) {
          var appendArray = _.flatMap(sourceDatas, (item, key, array) => [...item])

          this.setState({
            _baseData: _.assign({}, _baseData, {
              [_optionPickerKey]: _.filter(appendArray, item => data.includes(item['value']))
            })
          })
        } else if (_.size(sourceDatas) > 0) {
          this.setState({
            _baseData: _.assign({}, _baseData, {
              [_optionPickerKey]: _.filter(_.head(sourceDatas), item => data.includes(item['value']))
            })
          })
        }
        break;
      case 'handle_confirm_multiple_select_popup':
        this.setState({
          _baseData: _.assign({}, _baseData, {
            [_multipleSelectKey]: data
          })
        })
        break
      case 'handle_confirm_multiple_cascader_popup':
        this.setState({
          _baseData: _.assign({}, _baseData, {
            [_multipleSelectKey]: data && data.value ? data.value : []
          })
        })
        break;
      case 'handle_confirm_multiple_select_user_and_group_popup':
        this.setState({
          _baseData: _.assign({}, _baseData, {
            memoAuthUsersGroups: data
          })
        })
        break;
      case 'handle_confirm_multiple_select_user_and_dept_popup':
        this.setState({
          _baseData: _.assign({}, _baseData, {
            memoAuthUsersDepts: data
          })
        })
        break;
      case 'handle_confirm_value':
        onConfirm && onConfirm({ ..._baseData, labelOptions: FORM_LABEL_OPTIONS })
        this.close()
        break;
      case 'handle_clear_value':
        onConfirm && onConfirm(null)
        this.close()
        break;
      default:
        break;
    }
  }

  fetchCECUserList = async () => {
    await FetchCECUserCallbackList('normal',
      datas => {
        this.setState({ _cecUserAndGroupOptions: _.concat([...datas], GROUP_OPTIONS), _cecUserAndDeptOptions: _.concat([...datas], DEPT_OPTIONS) })
      }
    )
  }

  renderNavRight() {
    return <div style={{ color: '#1890FF' }} onClick={this.handleClick.bind(this, 'handle_clear_value')}>清空</div>
  }

  renderBodyContent() {
    const {
      _optionPickerKey,
      _multipleSelectKey,
      _baseData,
      _cecUserAndGroupOptions,
      _cecUserAndDeptOptions,
    } = this.state
    console.log('_baseData =>', _baseData)
    return (
      <div>
        <List>
          <List.Item extra={_baseData['memoDate'] ? moment(_baseData['memoDate']).format('YYYY/MM/DD') : "请选择"} onClick={() => this.memoDateRef?.current?.toggle()}>{FORM_LABEL_MAP['memoDate']}<Required /></List.Item>
          <List.Item extra={_baseData['memoCategory'] ? _.join(_.map(_baseData['memoCategory'], "label"), '&') : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_option_picker', 'memoCategory')}>{FORM_LABEL_MAP['memoCategory']}<Required /></List.Item>
          {/* <List.Item extra={_baseData['memoBussinessCategory'] ? _.join(_.map(_baseData['memoBussinessCategory'], "label")) : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_multiple_select_popup', 'memoBussinessCategory')}>业务机会类型<Required /></List.Item> */}
          <CECSelectorListItem label={FORM_LABEL_MAP['memoBussinessCategory']} isRequired={true} multiple={true}
            value={_baseData['memoBussinessCategory'] ? _.map(_baseData['memoBussinessCategory'], "value") : []}
            options={_.get(MULTIPLE_SELECT_OPTIONS, `memoBussinessCategory.options`)}
            onChange={(val, options) => {
              this.setState({
                _baseData: _.assign({}, _baseData, {
                  memoBussinessCategory: options
                })
              })
              // this.setState({ _optionPickerKey: "memoBussinessCategory" }, () => this.handleClick('handle_confirm_option_picker', val, options))
            }}
          />
          {/* <List.Item extra={_baseData['memoTradeChoice'] ? _.join(_.map(_baseData['memoTradeChoice'], "label")) : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_option_picker', 'memoTradeChoice')}>是否有交易机会<Required /></List.Item> */}
          <CECSelectorListItem
            label={FORM_LABEL_MAP['memoTradeChoice']} isRequired={true}
            value={_baseData['memoTradeChoice'] ? _.map(_baseData['memoTradeChoice'], "value") : null}
            options={
              [
                { label: '1个月内', value: '1个月内' }, { label: '3个月内', value: '3个月内' },
                { label: '6个月内', value: '6个月内' }, { label: '1年内', value: '1年内' },
                { label: '暂无', value: '暂无' }
              ]
            }
            onChange={(val, options) => {
              this.setState({ _optionPickerKey: "memoTradeChoice" }, () => this.handleClick('handle_confirm_option_picker', val, options))
            }}
          />
          <List.Item extra={_baseData['memoIsPublic'] ? _.join(_baseData['memoIsPublic']) : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_multiple_cascader_popup', 'memoIsPublic')}>{FORM_LABEL_MAP['memoIsPublic']}<Required /></List.Item>
          {
            _baseData['memoIsPublic'] && (_baseData['memoIsPublic'].includes('非公开')) && (
              <CECSelectorListItem label="非公开理由" isRequired={true} columns={1}
                value={_baseData['memoPrivateReason'] ? _.map(_baseData['memoPrivateReason'], "value") : null}
                options={
                  [
                    { label: '涉及拟进行重大交易的上市公司（包括但不限于少数股权融资、并购、反向收购等行为）', value: '涉及拟进行重大交易的上市公司（包括但不限于少数股权融资、并购、反向收购等行为）' },
                    { label: '涉及控股权交易', value: '涉及控股权交易' }
                  ]
                }
                onChange={(val, options) => {
                  this.setState({ _optionPickerKey: "memoPrivateReason" }, () => this.handleClick('handle_confirm_option_picker', val, options))
                }}
              />
            )
          }
          {
            _baseData['memoIsPublic'] && (_baseData['memoIsPublic'].includes('非公开')) && (
              <Fragment>
                <List.Item>
                  <Input placeholder="请输入上市公司名称" value={_baseData['memoPrivateReasonCompany']} onChange={val => this.handleClick('onStateChange', 'memoPrivateReasonCompany', val)} />
                </List.Item>
                <List.Item>
                  <Input placeholder="请输入上市公司股票代码" value={_baseData['memoPrivateReasonCompanyCode']} onChange={val => this.handleClick('onStateChange', 'memoPrivateReasonCompanyCode', val)} />
                </List.Item>
                <List.Item>
                  <Input placeholder="请输入公司上市地" value={_baseData['memoPrivateReasonCompanyArea']} onChange={val => this.handleClick('onStateChange', 'memoPrivateReasonCompanyArea', val)} />
                </List.Item>
              </Fragment>
            )
          }
          {
            _baseData['memoIsPublic'] && (_baseData['memoIsPublic'].includes('指定人公开')) && (
              <List.Item extra={_baseData['memoAuthUsers'] ? _.join(_.map(_baseData['memoAuthUsers'], "label")) : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_multiple_select_popup', 'memoAuthUsers')}>{FORM_LABEL_MAP['memoAuthUsers']}</List.Item>
            )
          }
          {
            _baseData['memoIsPublic'] && (_baseData['memoIsPublic'].includes('部门内公开')) && (
              <List.Item extra={_baseData['memoAuthUsersDepts'] ? _.join(_.map(_baseData['memoAuthUsersDepts'], "label")) : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_multiple_select_user_and_dept_popup', 'memoAuthUsersDepts')}>{FORM_LABEL_MAP['memoAuthUsersDepts']}</List.Item>
            )
          }
          {
            _baseData['memoIsPublic'] && (_baseData['memoIsPublic'].includes('小组内公开')) && (
              <Fragment>
                <List.Item extra={_baseData['memoAuthUsersGroups'] ? _.join(_.map(_baseData['memoAuthUsersGroups'], "label")) : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_multiple_select_user_and_group_popup', 'memoAuthUsersGroups')}>{FORM_LABEL_MAP['memoAuthUsersGroups']}</List.Item>
              </Fragment>
            )
          }
          <CECSelectorListItem label={FORM_LABEL_MAP['memoIsPush']} isRequired={true}
            value={_baseData['memoIsPush'] ? _.map(_baseData['memoIsPush'], "value") : null}
            options={[{ label: '全部可见人推送', value: '全部可见人推送', disabled: false }, { label: '自定义', value: '自定义' }, { label: '否', value: '否' },]}
            onChange={(val, options) => {
              this.setState({ _optionPickerKey: "memoIsPush" }, () => this.handleClick('handle_confirm_option_picker', val, options))
            }}
          />
          {
            _baseData['memoIsPush'] && _.map(_baseData['memoIsPush'], 'value').includes('自定义') && (
              <List.Item extra={_baseData['memoPushUsers'] ? _.join(_.map(_baseData['memoPushUsers'], "label")) : "请选择"} onClick={this.handleClick.bind(this, 'handle_toggle_multiple_select_popup', 'memoPushUsers')}>{FORM_LABEL_MAP['memoPushUsers']}<Required /></List.Item>
            )
          }
        </List>

        <CECCalendarPopup
          ref={this.memoDateRef}
          metadata={{ title: FORM_LABEL_MAP['memoDate'] }}
          value={_baseData['memoDate'] ? _baseData['memoDate'] : moment().toDate()}
          onConfirm={(value) => this.setState({ _baseData: { ..._baseData, memoDate: value } })}
        />

        <Picker ref={this.optionsRef}
          destroyOnClose={true}
          columns={_optionPickerKey && _.size(PICKER_OPTIONS[_optionPickerKey]) > 0 ? PICKER_OPTIONS[_optionPickerKey] : []}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_option_picker')}
        />

        <CECSelectPopup ref={this.multipleSelectPopupRef}
          title={_.get(MULTIPLE_SELECT_OPTIONS, `${_multipleSelectKey}.title`)}
          supportSearch={true}
          supportAvatar={false}
          multiple={_.get(MULTIPLE_SELECT_OPTIONS, `${_multipleSelectKey}.multiple`)}
          options={
            _multipleSelectKey == 'memoPushUsers' && _baseData['memoIsPublic'] && (_baseData['memoIsPublic'].includes('定向公开') || _baseData['memoIsPublic'].includes('非公开')) ? _.concat(_.filter(_.get(_baseData, 'memoAuthUsersGroups'), { category: 'user' }), _.filter(_.get(_baseData, 'memoAuthUsersDepts'), { category: 'user' }), _baseData['memoAuthUsers'] ? _baseData['memoAuthUsers'] : []) : _.get(MULTIPLE_SELECT_OPTIONS, `${_multipleSelectKey}.options`)
          }
          onConfirm={this.handleClick.bind(this, 'handle_confirm_multiple_select_popup')}
        />

        <CECSelectUserAndGroupPopup ref={this.multipleSelectUserAndGroupPopupRef}
          title={"对会议可见"}
          options={_cecUserAndGroupOptions}
          supportSearch={true}
          values={_baseData['memoAuthUsersGroups'] ? _baseData['memoAuthUsersGroups'] : []}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_multiple_select_user_and_group_popup')}
        />

        <CECSelectUserAndGroupPopup ref={this.multipleSelectUserAndDeptPopupRef}
          title={"对会议可见"}
          tabGroups={[{ label: '按人员', value: 'user' }, { label: '按部门', value: 'dept' }]}
          options={_cecUserAndDeptOptions}
          supportSearch={true}
          values={_baseData['memoAuthUsersDepts'] ? _baseData['memoAuthUsersDepts'] : []}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_multiple_select_user_and_dept_popup')}
        />

        <MultipleCascaderPopup ref={this.multipleCascaderPopup}
          metadata={{ title: '是否公开' }}
          sourcedata={{ options: _.get(MULTIPLE_SELECT_OPTIONS, `${_multipleSelectKey}.options`) }}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_multiple_cascader_popup')}
        />
      </div>
    )
  }

  renderFootContent() {
    return (
      <div>
        <Button block color="primary" onClick={this.handleClick.bind(this, 'handle_confirm_value')}>确定</Button>
      </div>
    )
  }

}

export const PICKER_OPTIONS = {
  'memoCategory': [
    [
      { label: 'BD及定期回访', value: 'BD及定期回访' },
      { label: '项目会议', value: '项目会议' }
    ],
    [
      { label: '企业', value: '企业' },
      { label: '机构(GP/LP)', value: '机构(GP/LP)' }
    ]
  ],
  'memoTradeChoice': [
    [
      { label: '1个月内', value: '1个月内' },
      { label: '3个月内', value: '3个月内' },
      { label: '6个月内', value: '6个月内' },
      { label: '1年内', value: '1年内' },
      { label: '暂无', value: '暂无' }
    ]
  ],
  'memoIsPush': [
    [
      { label: '全部可见人推送', value: '全部可见人推送' },
      { label: '自定义', value: '自定义' },
      { label: '否', value: '否' },
    ]
  ]
}

export const DEPT_OPTIONS = [
  { label: '消费&娱乐', value: 'target_dept_1', "category": 'dept', },
  { label: '科技', value: 'target_dept_2', "category": 'dept', },
  { label: 'Healthcare', value: 'target_dept_3', "category": 'dept', },
  { label: 'ECM', value: 'target_dept_4', "category": 'dept', },
  { label: 'GP/LP服务', value: 'target_dept_5', "category": 'dept', },
  { label: '基金', value: 'target_dept_6', "category": 'dept', },
  { label: 'IT', value: 'target_dept_7', "category": 'dept', },
  { label: 'Legal&Compliance', value: 'target_dept_8', "category": 'dept', },
  { label: 'HR', value: 'target_dept_9', "category": 'dept', },
  { label: '影响力中心', value: 'target_dept_10', "category": 'dept', },
]

export const GROUP_OPTIONS = [
  {
    "label": "消费&娱乐",
    "value": 120,
    "description": "3人",
    "category": 'group',
  },
  {
    "label": "科技",
    "value": 121,
    "description": "5人",
    "category": 'group',
  },
  {
    "label": "Pharma",
    "value": 122,
    "description": "7人",
    "category": 'group',
  },
  {
    "label": "Service",
    "value": 123,
    "description": "4人",
    "category": 'group',
  },
  {
    "label": "MedTech",
    "value": 124,
    "description": "6人",
    "category": 'group',
  },
  {
    "label": "M&A",
    "value": 125,
    "description": "6人",
    "category": 'group',
  },
  {
    "label": "SynBio",
    "value": 135,
    "description": "3人",
    "category": 'group',
  },
  {
    "label": "ECM",
    "value": 126,
    "description": "5人",
    "category": 'group',
  },
  {
    "label": "GP/LP服务-募资",
    "value": 133,
    "description": "1人",
    "category": 'group',
  },
  {
    "label": "GP/LP服务-投资退出",
    "value": 136,
    "description": "3人",
    "category": 'group',
  }
]

export const CEC_USER_OPTIONS = [
  {
    "label": "Ran Wang（王冉）",
    "value": 53,
    "avatar": "http://p.qlogo.cn/bizmail/bmxYuYKncdmpoEHme3Cqkafd0a4gibT8ndrGNwS3QYxlLK8LFj8ibgOQ/0",
    "description": "Founding Partner",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 0,
    "valueIndex": 0
  },
  {
    "label": "Irene Hong（Irene Hong）",
    "value": 26,
    "avatar": "http://shp.qpic.cn/bizmp/lYAblWYxSCMyThWIiaucRGWBkgyp1z8aV0Yt75dt2HM4l3NC8UeYaOw/",
    "description": "Founding Partner",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 0,
    "valueIndex": 1
  },
  {
    "label": "Gang Li（李钢）",
    "value": 22,
    "avatar": "http://p.qlogo.cn/bizmail/lO4zoQt7cMBibbApLgyxE0T74crHziazAsaL39jlj6XaOEOrAnp7JmHw/0",
    "description": "Partner",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 1,
    "valueIndex": 2
  },
  {
    "label": "Eric Li（李晋）",
    "value": 295,
    "avatar": "http://wework.qpic.cn/bizmail/sh7wBJXDPcHttaSXHial2T3bDBU1NA4P3NK0a1rdQzHFKgY8nDibTatQ/0",
    "description": "Partner",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 1,
    "valueIndex": 3
  },
  {
    "label": "Kevin Wang（王湘民）",
    "value": 37,
    "avatar": "http://p.qlogo.cn/bizmail/WFgP56pTBVzxDYdkiamHnYuU06Wcce4n3oEyNBb2uZlfRbmhAn2fB1w/0",
    "description": "Partner",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 1,
    "valueIndex": 4
  },
  {
    "label": "Michael Xu（徐华泽）",
    "value": 45,
    "avatar": "http://p.qlogo.cn/bizmail/MGHSquUN61czj5hjYa9CZLsnS1ayvCQ4DNXK41ib4bxAdJ9HicNAmiblw/0",
    "description": "Partner",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 1,
    "valueIndex": 5
  },
  {
    "label": "Bin Wang（王斌）",
    "value": 110,
    "avatar": "http://p.qlogo.cn/bizmail/IMbnSicKroXH7JbaSADENIogEgj4zJOdtvZ7jWW6IdjibrEwsgjxDp2A/0",
    "description": "MD",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 2,
    "valueIndex": -1
  },
  {
    "label": "Liao Jieying（廖洁莹）",
    "value": 598,
    "avatar": "https://wework.qpic.cn/wwpic/548434_3cW0zyZeSSmRZwe_1670208657/0",
    "description": "MD",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 2,
    "valueIndex": -1
  },
  {
    "label": "Maggie Qi（亓梦璐）",
    "value": 43,
    "avatar": "http://p.qlogo.cn/bizmail/EiaIAPz321iaLlOTm2fQn68aB20gWIaiaslh9XKPFdia2xeoENJZQziapnw/0",
    "description": "MD",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 2,
    "valueIndex": -1
  },
  {
    "label": "Yuan Ye（袁野）",
    "value": 678,
    "avatar": "https://wework.qpic.cn/wwpic/258396_rSydpFDBQQG4_Cy_1694141191/0",
    "description": "MD",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 2,
    "valueIndex": -1
  },
  {
    "label": "Zhang Xun（张逊）",
    "value": 695,
    "avatar": "https://wework.qpic.cn/wwpic3az/423669_h2I6p7aLTeu3Hvm_1701655760/0",
    "description": "MD",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 2,
    "valueIndex": -1
  },
  {
    "label": "Albert Zhang（张磊）",
    "value": 594,
    "avatar": "https://wework.qpic.cn/wwpic/718975_Abcl7OMEQduxK9D_1662654935/0",
    "description": "ED",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 3,
    "valueIndex": -1
  },
  {
    "label": "Anthony Shao（邵路遥）",
    "value": 274,
    "avatar": "http://wework.qpic.cn/bizmail/rib3QqYEnJNqkX6admkrI835IUKibXKLFrvOvuTowGx9Hpotjx3KCshw/0",
    "description": "ED",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 3,
    "valueIndex": -1
  },
  {
    "label": "Jessica Wang（王昕宇）",
    "value": 656,
    "avatar": "https://wework.qpic.cn/wwpic/867470_bajI07_GRD-5C2M_1686534091/0",
    "description": "ED",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 3,
    "valueIndex": -1
  },
  {
    "label": "Li Bonan（李伯男）",
    "value": 587,
    "avatar": "https://wework.qpic.cn/wwpic/345495_WkPig755SRiKv1L_1663118911/0",
    "description": "ED",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 3,
    "valueIndex": -1
  },
  {
    "label": "Ronald Sun（孙鹏）",
    "value": 301,
    "avatar": "https://wework.qpic.cn/bizmail/pB741BT0ABn1LiceeZmLwmohKDbichzGjlE1hwaqibzWtszuicaCbVZvibA/0",
    "description": "ED",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 3,
    "valueIndex": -1
  },
  {
    "label": "Yang Fang（方洋）",
    "value": 140,
    "avatar": "http://p.qlogo.cn/bizmail/OEgOibS9UBpqxc4Lnrouias588NjtvhhP5iaIOk4ia4GSthBR91PWzQXug/0",
    "description": "ED",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 3,
    "valueIndex": -1
  },
  {
    "label": "Yaping Tang（汤亚萍）",
    "value": 146,
    "avatar": "http://p.qlogo.cn/bizmail/J7qwjaUZ1E7QpuWUKg7k5KHnOgIL2BOUTnBxagD1THVgGP1F7hDibvQ/0",
    "description": "ED",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 3,
    "valueIndex": -1
  },
  {
    "label": "Effie Zheng（郑悰然）",
    "value": 134,
    "avatar": "http://p.qlogo.cn/bizmail/HOhDiauhzDZB6dLRibzX4HzP5PP0fxtzOdqIamFde6M4kYf7eSsu5K0w/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Freya Cui（崔春阳）",
    "value": 326,
    "avatar": "https://wework.qpic.cn/wwhead/duc2TvpEgSTPk74IwG7BszOze9BHyhlAMSxk5gmUBxicibRWXFtNDbaI05f6PavveI0TpicHq0Atw8/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Gary Gai（盖瑞杰）",
    "value": 286,
    "avatar": "http://wework.qpic.cn/bizmail/kpVsiasFcwOt08t0grYh4UJ1BxPb7x3ndLKb0nTtPianhPlow4yaeZRw/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Hai Bai（白海）",
    "value": 267,
    "avatar": "http://wework.qpic.cn/bizmail/FyYJxusv9h9FL8icVRNXODemsauG8HmQzkNJnUVe9JsstO7baDjLPKA/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Janice（金志冰）",
    "value": 106,
    "avatar": "http://p.qlogo.cn/bizmail/piaMRWdegxu4tDjYL025DcdKaKaAqI4gSngfibjicohR4HOD1IxP4WPxw/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Jason Wang（王嘉玮）",
    "value": 268,
    "avatar": "http://wework.qpic.cn/bizmail/ZA1H7zgqTaynicfK2Yia7mtrEFvulnhLicLGiaSyb2Ub8BibBSO8HXkDsFQ/",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Kevin Yang（杨朔）",
    "value": 639,
    "avatar": "https://wework.qpic.cn/wwpic/768763_Bcn1E-htTKuHXG5_1681089134/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Sara Wang（汪泠沁）",
    "value": 142,
    "avatar": "http://p.qpic.cn/wwhead/duc2TvpEgST9hicuyypLEKLeicqHhrvicK7YvHCsrOsUwia8xLbabyh4uR0dMzrc5vGtkMC036icJvG8/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Vincent Sun（孙韦谊）",
    "value": 273,
    "avatar": "http://wework.qpic.cn/bizmail/icvmXG2raO8WrXnPmpobnukib3tHqXnfVoVxtAHRxicZYEiboYFKqBJagw/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Wincy Weng（翁思思）",
    "value": 402,
    "avatar": "https://wework.qpic.cn/bizmail/79437VyGJDRnKsm2OTNsJSdyPUvCA5sRUzVyibZ6PhK6NTn4j9F5krA/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Zhao Kangxi（赵康熙）",
    "value": 283,
    "avatar": "http://wework.qpic.cn/bizmail/hfxjicKcfrUE4Sul80zW5yWFnvQGibQzITNoCt4Nrk1aSEGDeG6HbRbg/0",
    "description": "VP",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 4,
    "valueIndex": -1
  },
  {
    "label": "Celine Xu（许李幸）",
    "value": 305,
    "avatar": "http://wework.qpic.cn/bizmail/40xuFjTiaEbjlsCVR8icRbMZibmZIVfrogA2XDggPozU77q2I9xBjnUhw/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Daniel Zhao（赵昕淇）",
    "value": 630,
    "avatar": "https://wework.qpic.cn/wwpic/469329_s5LKtABaQrKiG0Q_1679275881/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Emily Cui（崔紫娇）",
    "value": 407,
    "avatar": "https://wework.qpic.cn/bizmail/7T5klibXRqemPVeSP1hbBQUJE80SeZdibnlLLiakyf2vL1DtgtbiaJBscA/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Huey Jia（贾修毅）",
    "value": 333,
    "avatar": "http://wework.qpic.cn/bizmail/DXFLicmviaEpt7SK5MEP77LwuTpOiby1icw8qjNMjEp6f7y02N641hWRhw/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Mengxuan Lu（陆梦瑄）",
    "value": 257,
    "avatar": "http://wework.qpic.cn/bizmail/eg6uBDQMenJGnyRNxa93ORibmroAqdwzmy0CqZ4Xv75U55x95VzUicvA/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Peter Zhang（张亦笑）",
    "value": 508,
    "avatar": "http://wework.qpic.cn/bizmail/mvNkRerGsal6Zj3ibsfduN3gFqlBj6LaKMNQpLxZNRxgcOaib0SNvR7w/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Sara Zhang（张心怡）",
    "value": 719,
    "avatar": "http://oss.ceccapitalgroup.com/head/defaultheadpic_0.svg",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Sean Chen（陈祥）",
    "value": 686,
    "avatar": "https://wework.qpic.cn/wwpic/65605_n5QyaoElRlGqeeb_1698802467/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Yi Chuanqi（裔传麒）",
    "value": 324,
    "avatar": "http://wework.qpic.cn/bizmail/BhTgpAwNpolUwB4ibHibUhen7icc5wZ1ElznluiatVVxYibcIkYxEc3yLYg/0",
    "description": "SrAssociate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 5,
    "valueIndex": -1
  },
  {
    "label": "Alyssa Lv（吕慈祯）",
    "value": 532,
    "avatar": "https://wework.qpic.cn/wwpic/526271_xTFyRqIwTdGXpKG_1649900278/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Chris Gong（龚晟）",
    "value": 386,
    "avatar": "https://wework.qpic.cn/bizmail/Y8EA0JPEXeAUib2tic6BTUBFotGGibvohctLgLX16doXia7KCjSAQmibKFg/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Core Xie（谢泽睿）",
    "value": 276,
    "avatar": "http://wework.qpic.cn/bizmail/gfQ80kVgVfNA4NHkURqDysBmxhAsj5MyshhHRXYRic7p5xw0IzNrhwQ/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Crystal Qian（钱蕾）",
    "value": 289,
    "avatar": "http://wework.qpic.cn/bizmail/P5skpaibrlkcw8Sqyc3IJO2vEf3QABJDwbdCWGdjtibXOEH677yqE6UA/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Day Hou（侯凯元）",
    "value": 419,
    "avatar": "http://wework.qpic.cn/bizmail/m1KxrkekhRRm3sVRoC0J3xqhh1p4UDqbsjGN4DLFSHrU0Vib27v0r5Q/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Liz Liang（梁爽）",
    "value": 393,
    "avatar": "http://wework.qpic.cn/bizmail/ClToXZyfwPmiaec2djyPatuV2dDo5rU0t7iauqmsn97J11cg1SLBWgdw/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Max Zhang（章明明）",
    "value": 696,
    "avatar": "https://wework.qpic.cn/wwpic/417363_lmk115oJTG6XDSu_1664423371/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Stacy Wang（王伊晶）",
    "value": 291,
    "avatar": "http://wework.qpic.cn/bizmail/icKY0Lu0Xv7wZ59y5eqqXLIyRSlJiahRKzeBTTiblU6jzSPcyXHW0kMQQ/0",
    "description": "Associate",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 6,
    "valueIndex": -1
  },
  {
    "label": "Fan Wenxin（范文心）",
    "value": 660,
    "avatar": "https://wework.qpic.cn/wwpic/498803_QUtZhBNVScmDuZo_1680056416/0",
    "description": "Analyst 2",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 7,
    "valueIndex": -1
  },
  {
    "label": "Harvey Wang（王天宇）",
    "value": 651,
    "avatar": "https://wework.qpic.cn/wwpic/760308_DpmSG2EIT9Wq9TV_1685928383/0",
    "description": "Analyst 2",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 7,
    "valueIndex": -1
  },
  {
    "label": "Roy Li（李鑫野）",
    "value": 670,
    "avatar": "https://wework.qpic.cn/wwpic/30504_SZsGCSxoRtGOBPR_1690769674/0",
    "description": "Analyst 2",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 7,
    "valueIndex": -1
  },
  {
    "label": "Sophie Teng（滕晓雨）",
    "value": 143,
    "avatar": "http://p.qlogo.cn/bizmail/me5u6nnsviaCRJkk9LdDBRgZvhoomI0OnDKpS5zTiagR09QBN3VBicq5Q/0",
    "description": "高级合伙人助理",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 10,
    "valueIndex": -1
  },
  {
    "label": "Foriol Zhou（周子荃）",
    "value": 391,
    "avatar": "http://wework.qpic.cn/wwhead/duc2TvpEgSQykrhF5Jh51LanoRIJyribCjHGMZBW4FmXeBda0juF45aSru0rQg09qEHru8GXu8c0/0",
    "description": "Deputy Director",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 11,
    "valueIndex": -1
  },
  {
    "label": "Mengying Zhao（赵梦莹）",
    "value": 100,
    "avatar": "http://p.qlogo.cn/bizmail/cruGIuXFxicia0wlCibhvJaibKI3Oj14zU22PCscWibV8K2VsvNXuUR2mbw/0",
    "description": "Director of IR, Changjian",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 12,
    "valueIndex": -1
  },
  {
    "label": "Xin Li（李鑫）",
    "value": 74,
    "avatar": "http://p.qlogo.cn/bizmail/NGgPtCMM8KVG0NwSIngh0Jnr73AIA9qJpmAkYyv2AC1v1At071y5iaw/0",
    "description": "Director, Productions",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 15,
    "valueIndex": -1
  },
  {
    "label": "Vivien Wang（王威）",
    "value": 70,
    "avatar": "http://shp.qpic.cn/bizmp/lYAblWYxSCM8AxLucrZPD0JEiaFfNbJj5Sica3LbqF6Piabyys2Kptc0w/",
    "description": "IBD财务负责人",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 16,
    "valueIndex": -1
  },
  {
    "label": "Mint Xu（徐烨）",
    "value": 673,
    "avatar": "https://wework.qpic.cn/bizmail/ic9xfAgwpqeuBs8IiciaSPIicBGuYmwVtFBLW4KNmB0CztFMq7mMaVTEUA/0",
    "description": "基金财务经理",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 17,
    "valueIndex": -1
  },
  {
    "label": "Vicky Ma（马唯）",
    "value": 68,
    "avatar": "http://p.qlogo.cn/bizmail/1HNaGYVQdicQVqSSmw4LkB2J98dQvPvOqALwcOXZgJGyJNM2OSmByCQ/0",
    "description": "Cashier",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 18,
    "valueIndex": -1
  },
  {
    "label": "Jeff Bi（毕宇鑫）",
    "value": 288,
    "avatar": "https://wework.qpic.cn/bizmail/ZTZrCesEceTBbjC5ibPfZZXwbVhFWwhlKic0lCDxKJqvdPrnPFDzPujA/0",
    "description": "HRD",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 19,
    "valueIndex": -1
  },
  {
    "label": "Yi Zhao（赵毅）",
    "value": 98,
    "avatar": "http://p.qlogo.cn/bizmail/ZW0luw3yMPKAmrNOib0lspFlLVtDLfAVh7ChHEedJDcT0MXd4WcX9Zg/0",
    "description": "Director, IT",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 20,
    "valueIndex": -1
  },
  {
    "label": "Bonnie Guo（郭邦晖）",
    "value": 129,
    "avatar": "http://p.qlogo.cn/bizmail/icDuntthbPzeTAbFlgicibl3UEorrxLssW5onF0VibGHkBj22DObIiazscA/0",
    "description": "影响力中心总经理",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 21,
    "valueIndex": -1
  },
  {
    "label": "Owen Zuo（左希杰）",
    "value": 321,
    "avatar": "http://wework.qpic.cn/bizmail/5ahfdWtlJBsiaIEQCOKCOicaiaRE0RqHnHeITwX0ygS5KAsmf2ZxuBW2g/0",
    "description": "HR Manager",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 22,
    "valueIndex": -1
  },
  {
    "label": "Roxy Rong（荣婧妍）",
    "value": 649,
    "avatar": "https://wework.qpic.cn/wwpic/177558_9uoPc_2fRMywIBM_1687239618/0",
    "description": "Senior Recruiter",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 23,
    "valueIndex": -1
  },
  {
    "label": "Depeng Xu（许德鹏）",
    "value": 1,
    "avatar": "http://p.qlogo.cn/bizmail/2IYbicodhbGkstZ3ibXn2uoib3APWled01fKTcd3Xfjuv1gNVvpVa1VWw/0",
    "description": "Senior Data Engineer",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 24,
    "valueIndex": -1
  },
  {
    "label": "Depeng Zou（邹德鹏）",
    "value": 135,
    "avatar": "http://p.qlogo.cn/bizmail/ow3nGB9WDmhPiciccIanYNNpl935aU5tSM5J4RteOEdOH8z46gSMxeCw/0",
    "description": "高级系统构架师",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 25,
    "valueIndex": -1
  },
  {
    "label": "Tian Zhiwen（田智文）",
    "value": 516,
    "avatar": "http://wework.qpic.cn/bizmail/wV1icgbEdibmkRibrwaua17Kdiaoq0QNicrOhmL2KkeBfBlMtlmIZBKXjEg/0",
    "description": "前端开发工程师",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 26,
    "valueIndex": -1
  },
  {
    "label": "Kong Tianyi（孔天怡）",
    "value": 712,
    "avatar": "",
    "description": "行政助理",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 27,
    "valueIndex": -1
  },
  {
    "label": "Renee Li（李心蕊）",
    "value": 137,
    "avatar": "http://p.qlogo.cn/bizmail/1orWJZRZQfygLEicKZE6pkNS2ibI1HjnW2dRrYQG2M1xSia7uFCYAG98g/0",
    "description": "前台",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 28,
    "valueIndex": -1
  },
  {
    "label": "Owen Han（韩琳枫）",
    "value": 522,
    "avatar": "http://wework.qpic.cn/bizmail/7VoIoCqcaUnsUIeb0SKOtDtUEbV2vblQ0zwEMJs1a6glrZhZTib076A/0",
    "description": "视觉设计师",
    "category": "user",
    "selected": false,
    "jobLevelIndex": 29,
    "valueIndex": -1
  }
]


export const MULTIPLE_SELECT_OPTIONS = {
  'memoBussinessCategory': {
    title: '业务机会类型',
    multiple: true,
    options: [
      { label: '投融资', value: '投融资' },
      { label: '并购', value: '并购' },
      { label: '分拆/重组', value: '分拆/重组' },
      { label: '投退', value: '投退' },
      { label: '募资', value: '募资' },
      { label: '其他', value: '其他' },
    ]
  },
  'memoIsPublic': {
    title: '是否公开',
    multiple: false,
    options: [
      { label: '公开', value: '公开', },
      {
        label: '定向公开',
        value: '定向公开',
        children: [
          {
            label: '小组内公开',
            value: '小组内公开',
          },
          {
            label: '部门内公开',
            value: '部门内公开',
          },
          {
            label: '指定人公开',
            value: '指定人公开',
          },
        ]
      },
      {
        label: '非公开',
        value: '非公开',
      },
    ]
  },
  'memoAuthUsers': {
    title: '权限（指定人）',
    multiple: true,
    options: [...CEC_USER_OPTIONS]
  },
  'memoAuthDepts': {
    title: '权限（指定部门）',
    multiple: true,
    options: [
      { label: '消费&娱乐', value: 'target_dept_1', selected: false },
      { label: '科技', value: 'target_dept_2', selected: false },
      { label: 'Healthcare', value: 'target_dept_3', selected: false },
      { label: 'ECM', value: 'target_dept_4', selected: false },
      { label: 'GP/LP服务', value: 'target_dept_5', selected: false },
      { label: '基金', value: 'target_dept_6', selected: false },
      { label: 'IT', value: 'target_dept_7', selected: false },
      { label: 'Legal&Compliance', value: 'target_dept_8', selected: false },
      { label: 'HR', value: 'target_dept_9', selected: false },
      { label: '影响力中心', value: 'target_dept_10', selected: false },
    ]
  },
  'memoAuthGroups': {
    title: '权限（指定小组）',
    multiple: true,
    options: [
      { label: '消费&娱乐', value: 'target_group_1', selected: false },
      { label: '科技', value: 'target_group_2', selected: false },
      { label: 'Pharma', value: 'target_group_3', selected: false },
      { label: 'Service', value: 'target_group_4', selected: false },
      { label: 'MedTech', value: 'target_group_5', selected: false },
      { label: 'M&A', value: 'target_group_6', selected: false },
      { label: 'SynBio', value: 'target_group_7', selected: false },
      { label: 'ECM', value: 'target_group_8', selected: false },
      { label: 'GP/LP服务', value: 'target_group_9', selected: false },
    ]
  },
  'memoPushUsers': {
    title: '推送到人',
    multiple: true,
    options: [...CEC_USER_OPTIONS]
  },
}