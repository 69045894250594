import React, { Fragment, PureComponent, createRef, useCallback, useState } from "react";
import _ from "lodash";
import { FloatingBubble, Popover } from 'antd-mobile'
import { LeftOutline, AddOutline } from 'antd-mobile-icons'
import Layout from "../../../components/Layout";
import MenuListItem from "../../../components/MenuListItem";
import InfoUpdatePopup from "./InfoUpdate";
import MemoUpdatePopup from "./MemoUpdate";
import DealUpdate from "./DealUpdate";
import BaseDealInfoPopup from "./components/BaseDealInfoPopup"
import { TargetFormEntryModal } from "../../../components/TargetFormEntryMenu";
import './index.less'

/**
 * 更新入口
 */
export default class UpdateEntry extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _menus: [{
        key: 'update_entry_info',
        title: '关键信息更新',
        category: 'link',
        extra: true,
        ref: createRef(),
        componentRender: ({ metadata, sourcedata }) => <InfoUpdatePopup key={metadata['key']} ref={metadata['ref']} metadata={metadata} />,
      }, {
        key: 'update_entry_memo',
        title: '会议记录更新',
        category: 'link',
        extra: true,
        ref: createRef(),
        componentRender: ({ metadata, sourcedata }) => <MemoUpdatePopup key={metadata['key']} ref={metadata['ref']} metadata={metadata} />,
      }, {
        key: 'update_entry_deal',
        title: '项目更新',
        category: 'link',
        extra: true,
        ref: createRef(),
        componentRender: ({ metadata, sourcedata }) => <DealUpdate key={metadata['key']} ref={metadata['ref']} metadata={metadata} />,
      },]
    }
  }

  componentDidMount() {
    const {
      _menus
    } = this.state

    const array = this.collectComponentRenders(_menus)
    this.setState({ _components: array })
  }

  // 收集需要渲染的组件
  collectComponentRenders(items) {
    let array = []

    const recurse = (items) => {
      if (items && _.size(items) > 0) {
        items.forEach(menu => {
          if (menu['componentRender']) {
            array.push(menu)
          }
          if (menu['children'] && _.size(menu['children']) > 0) {
            recurse(menu['children'])
          }
        })
      }
    }

    recurse(items)
    return array
  }

  render() {
    const {
      _menus,
      _components,
    } = this.state

    const content = (
      <Layout head={(
        <div className="cec-update-entry-head">
          <div className="cec-update-entry-head-left" onClick={() => window.location.href = '/#/'} ><LeftOutline style={{ fontSize: '24px' }} /></div>
          <div className="cec-update-entry-head-center">更新</div>
          <div className="cec-update-entry-head-right"></div>
        </div>
      )}>
        <div className="cec-update-entry-body">
          {
            _menus.map((menu, index) => <MenuListItem metadata={menu} key={`menu-list-item-${index}`} />)
          }
        </div>

        {/** 快捷添加入口 */}
        <QuickAddEntry />

        {/** 渲染弹窗 */}
        {
          _components && _.size(_components) > 0 && (
            <Fragment>
              {
                _components.map(component => component.componentRender({ metadata: component }))
              }
            </Fragment>
          )
        }
      </Layout>
    )

    return content;
  }
}


/**
 * 快捷添加入口
 * @returns 
 */
function QuickAddEntry() {

  const [actions, setActions] = useState(QUICK_ADD_ACTIONS)

  const handleAction = useCallback((item) => {
    if (item && item['componentRender']) {
      item.ref?.current?.toggle()
    }
  })

  return (
    <FloatingBubble
      style={{
        '--initial-position-bottom': '24px',
        '--initial-position-right': '24px',
        '--edge-distance': '24px',
      }}>
      <Popover.Menu trigger='click'
        placement="bottom-end"
        actions={actions}
        onAction={handleAction}>
        <AddOutline fontSize={32} />
      </Popover.Menu>
      {
        actions && actions.map(item => item.componentRender({ metadata: item }))
      }
    </FloatingBubble>
  )
}

const QUICK_ADD_ACTIONS = [
  {
    value: 'add_deal',
    text: '新建项目',
    title: '新建项目',
    ref: createRef(),
    componentRender: ({ metadata }) => <BaseDealInfoPopup key={metadata['value']} ref={metadata['ref']} metadata={metadata} />,
  },
  {
    value: 'add_target',
    text: '新建企业',
    title: '新建企业',
    ref: createRef(),
    componentRender: ({ metadata }) => <TargetFormEntryModal key={metadata['value']} ref={metadata['ref']} metadata={metadata} />
  }
]