import React, { Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import _ from "lodash";
import { List, Skeleton, Toast, ErrorBlock } from "antd-mobile";
import { EditSFill } from "antd-mobile-icons"
import CECPopup from "../../../../../../../components/Popup";
import CECInfiniteScroll from "../../../../../../../components/InfiniteScroll";
import BussinessStrategyPopup from "../../../../../UpdateEntry/components/BussinessStrategyPopup";
import './index.less';
import {
  FetchBussinessStrategyList,
  FetchBussinessStrategyDetail,
} from './transform';

/**
 * 企业（产业方）详情 - 策略
 * @returns 
 */
export default function BussinessStrategyListView({ code, targetExtensionValue }) {

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize] = useState(5);

  const [item, setItem] = useState({})
  const dataDetailPopupRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      await fetchDatas(code, 1);
    }
    code && fetchData();
  }, [code]);

  const fetchDatas = async (code, currentPageNum) => {
    setLoading(true);
    try {
      const { datas: newDatas, hasMore, total } = await FetchBussinessStrategyList({ code, pageNum: currentPageNum, pageSize });
      setLoading(false);
      setHasMore(hasMore);
      setPageNum(currentPageNum + 1);
      if (currentPageNum === 1) {
        setDatas(newDatas)
      } else {
        setDatas(prevDatas => [...prevDatas, ...newDatas]);
      }
    } catch (error) {
      Toast.show({ content: error });
      setLoading(false);
      setHasMore(false);
    }
  };

  const handleLoadMore = useCallback(async () => {
    code && await fetchDatas(code, pageNum);
  }, [code, pageNum])

  const handleClick = (key, data, event) => {
    console.log("handleClick >>>>", key, data, event)

    switch (key) {
      case 'handle_open_item_detail_popup':
        setItem(data)
        dataDetailPopupRef?.current?.show()
        break;
      default:
        break;
    }
  }

  return (
    <div className="bussiness-strategy-list-box">
      <List>
        {
          loading && _.size(datas) === 0 && (
            <Fragment>
              <Skeleton.Title animated />
              <Skeleton.Paragraph lineCount={4} animated />
            </Fragment>
          )
        }
        {
          !loading && _.size(datas) == 0 && (
            <Fragment>
              <ErrorBlock status='empty' title="" description="暂无数据" />
            </Fragment>
          )
        }
        {
          datas.map((item) => (
            <List.Item className="bussiness-strategy-list" onClick={() => handleClick('handle_open_item_detail_popup', item)}>
              <div className="bussiness-strategy-item">
                <div className="bussiness-strategy-item-row">
                  <span className="bussiness-strategy-item-key">是否有新战略方向：</span>
                  <span>{_.join(_.map(item['newStrategicFocus'], "label"))}</span>
                </div>
                <div className="bussiness-strategy-item-row">
                  <span className="bussiness-strategy-item-key">是否有新业务：</span>
                  <span>{_.join(_.map(item['newBussiness'], "label"))}</span>
                </div>
                <div className="bussiness-strategy-item-row">
                  <span className="bussiness-strategy-item-key">是否有产业链整合计划：</span>
                  <span>{_.join(_.map(item['industryChainIntegrationPlan'], "label"))}</span>
                </div>
                <div className="bussiness-strategy-item-row">
                  <span className="bussiness-strategy-item-key">是否有出海计划：</span>
                  <span>{_.join(_.map(item['overseasExpansionPlan'], "label"))}</span>
                </div>
              </div>
            </List.Item>
          ))
        }
        <CECInfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} />
      </List>

      {/** 详情Popup */}
      <BussinessStrategyDetailPopup ref={dataDetailPopupRef} item={item} targetExtensionValue={targetExtensionValue} />
    </div>
  );
}


/**
 * 财务数据详情
 */
export const BussinessStrategyDetailPopup = React.forwardRef(({ item, targetExtensionValue }, ref) => {

  const [info, setInfo] = useState({})
  const basePopupRef = useRef(null)
  const editDataPopupRef = useRef(null)

  useImperativeHandle(ref, () => ({
    show: () => {
      basePopupRef?.current?.show()
    },
    close: () => {
      basePopupRef?.current?.close()
    },
    toggle: () => {
      basePopupRef?.current?.toggle()
    },
  }))

  useEffect(() => {
    const fetchData = async () => {
      await fetchDetail(item['id'])
    }
    item && fetchData()
  }, [item])

  const fetchDetail = async (id) => {
    Toast.show({ icon: 'loading' })
    try {
      const response = await FetchBussinessStrategyDetail(id)
      setInfo(response)
      Toast.clear()
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  const renderBodyContent = () => {
    return (
      <Fragment>
        {
          info && (
            <div className="bussiness-strategy-detail-box">
              {
                info['newStrategicFocus'] && (
                  <Fragment>
                    <div className="bussiness-strategy-detail-item">
                      <span className="detail-item-key">是否有新战略方向：</span>
                      <span>{_.join(_.map(info['newStrategicFocus'], "label"))}</span>
                    </div>
                    {
                      info['newStrategicFocusText'] && (
                        <div className="bussiness-strategy-detail-item">
                          <span className="detail-item-key">具体描述：</span>
                          <span>{info['newStrategicFocusText']}</span>
                        </div>
                      )
                    }
                    <div className="split-line"></div>
                  </Fragment>
                )
              }

              {
                info['newBussiness'] && (
                  <Fragment>
                    <div className="bussiness-strategy-detail-item">
                      <span className="detail-item-key">是否有新业务：</span>
                      <span>{_.join(_.map(info['newBussiness'], "label"))}</span>
                    </div>
                    {
                      info['newBussinessText'] && (
                        <div className="bussiness-strategy-detail-item">
                          <span className="detail-item-key">具体描述：</span>
                          <span>{info['newBussinessText']}</span>
                        </div>
                      )
                    }
                    <div className="split-line"></div>
                  </Fragment>
                )
              }

              {
                info['industryChainIntegrationPlan'] && (
                  <Fragment>
                    <div className="bussiness-strategy-detail-item">
                      <span className="detail-item-key">是否有产业链整合计划：</span>
                      <span>{_.join(_.map(info['industryChainIntegrationPlan'], "label"))}</span>
                    </div>
                    {
                      info['industryChainIntegrationPlanText'] && (
                        <div className="bussiness-strategy-detail-item">
                          <span className="detail-item-key">具体描述：</span>
                          <span>{info['industryChainIntegrationPlanText']}</span>
                        </div>
                      )
                    }
                    <div className="split-line"></div>
                  </Fragment>
                )
              }

              {
                info['overseasExpansionPlan'] && (
                  <Fragment>
                    <div className="bussiness-strategy-detail-item">
                      <span className="detail-item-key">是否有出海计划：</span>
                      <span>{_.join(_.map(info['overseasExpansionPlan'], "label"))}</span>
                    </div>
                    {
                      info['overseasExpansionPlanText'] && (
                        <div className="bussiness-strategy-detail-item">
                          <span className="detail-item-key">具体描述：</span>
                          <span>{info['overseasExpansionPlanText']}</span>
                        </div>
                      )
                    }
                    <div className="split-line"></div>
                  </Fragment>
                )
              }
            </div>
          )
        }

        {/** 编辑页面Popup */}
        <BussinessStrategyPopup ref={editDataPopupRef}
          metadata={{
            title: '编辑策略及业务方向',
            targetRefKey: "info_option_list_attend_target",
          }}
          value={{
            value: { ...info }
          }}
          extensionValue={{ "info_option_list_attend_target": targetExtensionValue }}
        />
      </Fragment>
    )
  }

  return (
    <CECPopup ref={basePopupRef}
      overflow={false}
      navTitle="策略及业务方向详情"
      navRight={<div onClick={() => editDataPopupRef?.current?.show()}><EditSFill style={{ color: '#1890FF', fontSize: 18 }} /></div>}
      destroyOnClose={false}
      onNavBack={() => ref?.current?.close()}>
      {
        renderBodyContent()
      }
    </CECPopup>
  )
})