import { Space } from 'antd-mobile';
import { CloseOutline, AddOutline } from 'antd-mobile-icons'
import './index.less';

/**
 * 可关闭的标签
 * @returns 
 */
export default function CloseableTag({ label, className, onClick }) {
  return (
    <div className='cec-closeable-tag'>
      <Space align='center' style={{ '--gap': '4px' }}>
        <div className={className}>{label}</div>
        <CloseOutline style={{ fontSize: 12, color: "#999999" }} onClick={() => onClick && onClick()} />
      </Space>
    </div>
  )
}


/**
 * 可添加的标签
 * @returns 
 */
export function AddedTag({ label, className = "", onClick }) {

  return (
    <div className={`cec-added-tag ${className}`} onClick={() => onClick && onClick()}>
      <span><AddOutline />{label}</span>
    </div>
  )

}