import _ from "lodash";
import request from "../../../../../api/HttpRequest";


// 是否有实际控制人
export const IS_ACTUAL_CONTROLLER_OPTIONS_VALUE_TRUE = "true"
export const IS_ACTUAL_CONTROLLER_OPTIONS = [
  { label: '是', value: IS_ACTUAL_CONTROLLER_OPTIONS_VALUE_TRUE },
  { label: '否', value: 'false' },
]

// 创始人/实际控制人在过去1年内是否发生变化
export const IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS_VALUE_TRUE = "true"
export const IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS = [
  { label: '是', value: IS_ACTUAL_CONTROLLER_CHANGE_OPTIONS_VALUE_TRUE },
  { label: '否', value: 'false' },
]

// 创始人/股东在过去1年内是否发生变化
export const IS_SHAREHOLDER_CHANGE_OPTIONS_VALUE_TRUE = "true"
export const IS_SHAREHOLDER_CHANGE_OPTIONS = [
  { label: '是', value: IS_SHAREHOLDER_CHANGE_OPTIONS_VALUE_TRUE },
  { label: '否', value: 'false' },
]

// 是否有明确控股机会
export const IS_SHAREHOLDING_OPTIONS = [
  { label: '是', value: '是' },
  { label: '否', value: '否' },
]



/**
 * 保存 股权结构更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  const payload = {
    id: info['id'],
    base_comp_code: info['code'],
    ownerflag: info['isActualController'] ? _.join(_.map(info['isActualController'], "value")) : null,
    ownernote: info['isActualControllerText'],
    ownerchangeflag: info['isActualControllerChange'] ? _.join(_.map(info['isActualControllerChange'], "value")) : null,
    holderchangeflag: info['isShareholderChange'] ? _.join(_.map(info['isShareholderChange'], "value")) : null,
    owner_rate: info['isActualControllerChangeRatio'],
    controlflag: info['isShareholding'] ? _.join(_.map(info['isShareholding'], "value")) : null,
    management_rate: info['manageShareholding'],
    investment_all_rate: info['otherShareholding'],
    investment_top3_rate: info['top3OtherShareholding'],
    investment_top1_rate: info['maxOtherShareholding'],
    showflag: info['id'] ? true : false,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/trade/chance/save", payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}
/**
 * 删除 股权结构更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/smartx/comp/trade/chance/delete", { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}