import request from '../../api/HttpRequest';
import _ from 'lodash';
import { MEETING_LIST } from '../QueryDetailEntry/configuration'
import moment from 'moment';

function formatAmount(num) {
    if(_.isNumber(num)) {
        let _num = num.toFixed(4).replace(/\.?0+$/, '')
        let [integerPart, decimalPart] = _num.split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart ? `${integerPart}.${decimalPart}`: integerPart; 
    }
    return num
}

/**
 * 结构字符串日期类型
 * @param {*} label 
 * @param {*} data 
 * @returns 
 */
function formatStringNumber({label, value, suffix}) {
    let result = null
    if( _.includes(['string', 'number'], typeof(value)) ) {
        result = {
            label: label, 
            value: formatAmount(suffix ? `${value}${suffix}`: value)
        }
    }
    return result
}

function formatDate({label, value, formate = "YYYY-MM-DD"}) {
    let result = null
    if( _.includes(['string'], typeof(value)) ) {
        result = {
            label: label, 
            value: moment(value).format(formate)       
        }
    }
    return result
}

/**
 * 机构行业
 * @param {*} item 
 * @returns 
 */
function formatIndustry({label, value}) {
    let result = null
    if(value && _.size(value) > 0) {
        result = {
            label: label, 
            value: _.join(_.map(value, it=> {
                let res = it['label']
                let subLabel = _.join(_.map(it['children'], 'label'), '，')
                if(_.size(subLabel) > 0){
                    res = `${it['label']}（${subLabel}）`
                }
                return res
            }),'，'),
        }
    }
    return result
}
function fromatOptions({label, value}) {
    let result = null
    if(_.isArray(value) && _.size(value) > 0) {
        result = {label: label, value: _.join(_.map(value, 'label'),'，')}
    }
    return result
}
//
function dataToGovernment(item) {
    let _values = []
    // 政府
    _values.push(fromatOptions({
        label: "政府层级", 
        value: item['gov_level']
    }))
    
   
    _values.push(fromatOptions({
        label: "主要出资平台或投资载体", 
        value: item['gov_mainContributions']
    }))
    
    _values.push(formatStringNumber({
        label: "主要资金来源", 
        value: item['gov_mainSourceOfFunds']
    }))
    
    _values.push(formatStringNumber({
        label: "总体目标规模", 
        value: item['gov_overallTargetScale']
    }))
    
    _values.push(formatStringNumber({
        label: "已到位规模", 
        value: item['gov_establishedScale']
    }))
    
    _values.push(fromatOptions({
        label: "单笔投资金额", 
        value: item['gov_singleStrokeMoney']
    }))
    

    _values.push(formatIndustry({
        label: "投资方向（目标产业）", value: item['gov_cecIndustrySelect']
    }))
    
    _values.push(fromatOptions({
        label: "投资方向（目标阶段）", 
        value: item['gov_investmentDirection']
    }))
    
    _values.push(formatStringNumber({
        label: "特殊喜好", 
        value: item['gov_specialPreferences']
    }))
    
    _values.push(fromatOptions({
        label: "特别诉求", 
        value: item['gov_specificRequirement']
    }))
    
    _values.push(formatStringNumber({
        label: "优惠政策", 
        value: item['gov_favouredPolicy']
    }))
    
    
    if(item['gov_employments'] && _.size(item['gov_employments']) > 0) {
        let subHR = []
        _.map(item['gov_employments'], it=> {
            subHR = _.concat(subHR, dataToHR(it))
        } )

        if(_.size(subHR) > 0) {
            _values.push({label: "人事更新", value: subHR})
        }
        
    }

    _values.push(fromatOptions({
        label: "易凯潜在合作领域", 
        value: item['gov_ecapitalCooperation']
    }))
    

    return _.filter(_values, it=> it != null)
}
function dataToIndividual(item){
    let _values = []
    
    //家办及个人
    _values.push(fromatOptions({
        label: "性质", 
        value: item['individual_category']
    }))
    // if(item['individual_category'] && _.size(item['individual_category']) > 0) {
    //     _values.push({label: "性质", value: _.join(_.map(item['individual_category'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "主要资金来源", 
        value: item['individual_mainSourceOfFunds']
    }))
    // if( _.includes(['string', 'number'], typeof(item['individual_mainSourceOfFunds'])) ) {
    //     _values.push({label: "主要资金来源", value: formatAmount(item['individual_mainSourceOfFunds'])})
    // }
    _values.push(formatStringNumber({
        label: "总体规模", 
        value: item['individual_overallScale']
    }))
    // if( _.includes(['string', 'number'], typeof(item['individual_overallScale'])) ) {
    //     _values.push({label: "总体规模", value: formatAmount(item['individual_overallScale'])})
    // }
    _values.push(formatStringNumber({
        label: "已到位规模", 
        value: item['individual_establishedScale']
    }))
    // if( _.includes(['string', 'number'], typeof(item['individual_establishedScale'])) ) {
    //     _values.push({label: "已到位规模", value: formatAmount(item['individual_establishedScale'])})
    // }
    _values.push(fromatOptions({
        label: "单笔投资金额", 
        value: item['individual_singleStrokeMoney']
    }))
    // if(item['individual_singleStrokeMoney'] && _.size(item['individual_singleStrokeMoney']) > 0) {
    //     _values.push({label: "单笔投资金额", value: _.join(_.map(item['individual_singleStrokeMoney'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "特殊喜好", 
        value: item['individual_specialRequirement']
    }))
    // if(item['individual_specialRequirement'] && _.size(item['individual_specialRequirement']) > 0) {
    //     _values.push({label: "特殊喜好", value: _.join(_.map(item['individual_specialRequirement'], 'label'),'，')})
    // }

    //投资方向
    let subIndividual_direction = _.get(item, 'individual_direction', [])
    // console.log("subIndividual_direction===>", subIndividual_direction)
    if(_.size(subIndividual_direction) > 0 ) {
        
        _.map(subIndividual_direction, direction=> {
            let subDirections = dataToDirection(direction)
            // if(direction['direction_category'] && _.size(direction['direction_category']) > 0) {
            //     subDirections.push({
            //         label: "资产类别",  
            //         value: _.join(_.map(direction['direction_category'], 'label')),
            //     })
            // }
            // if(direction['direction_investmentTargetProp'] && _.size(direction['direction_investmentTargetProp']) > 0) {
            //     subDirections.push({
            //         label: "投资标的属性",  
            //         value: _.join(_.map(direction['direction_investmentTargetProp'], 'label')),
            //     })
            // }

            // if(direction['direction_industry'] && _.size(direction['direction_industry']) > 0) {
            //     subDirections.push({label: "产业和赛道", value: _.join(_.map(item['direction_industry'], it=> {
            //         let res = it['label']
            //         let subLabel = _.join(_.map(it['children'], 'label'), '，')
            //         if(_.size(subLabel) > 0){
            //             res = `${it['label']}（${subLabel}）`
            //         }
            //         return res
            //     }),'，')})
            // }

            // if(direction['direction_targetStage'] && _.size(direction['direction_targetStage']) > 0) {
            //     subDirections.push({
            //         label: "目标阶段",  
            //         value: _.join(_.map(direction['direction_targetStage'], 'label')),
            //     })
            // }
            
            // if(direction['direction_location'] && _.size(direction['direction_location']) > 0) {
            //     subDirections.push({
            //         label: "地理市场",  
            //         value: _.join(_.map(direction['direction_location'], 'label')),
            //     })
            // }
            
            // if(direction['direction_ownership'] && _.size(direction['direction_ownership']) > 0) {
            //     subDirections.push({
            //         label: "股权",  
            //         value: _.join(_.map(direction['direction_ownership'], 'label')),
            //     })
            // }

            // if(direction['direction_currency'] && _.size(direction['direction_currency']) > 0) {
            //     subDirections.push({
            //         label: "币种",  
            //         value: _.join(_.map(direction['direction_currency'], 'label')),
            //     })
            // }
            
            // if(direction['direction_currentStatus'] && _.size(direction['direction_currentStatus']) > 0) {
            //     subDirections.push({
            //         label: "当前状态",  
            //         value: _.join(_.map(direction['direction_currentStatus'], 'label')),
            //     })
            // }
            
            // if(direction['direction_specialRequirement'] && _.size(direction['direction_specialRequirement']) > 0) {
            //     subDirections.push({
            //         label: "特殊要求",  
            //         value: _.join(_.map(direction['direction_specialRequirement'], 'label')),
            //     })
            // }


            if(_.size(subDirections) > 0) {
                _values.push({
                    label: "投资方向", 
                    value: subDirections
                })
            }


        })

    }

    _values.push(fromatOptions({
        label: "交易机会", 
        value: item['individual_tradingOpportunities']
    }))
    // if(item['individual_tradingOpportunities'] && _.size(item['individual_tradingOpportunities']) > 0) {
    //     _values.push({label: "交易机会", value: _.map(item['individual_tradingOpportunities'], it=> ({label: "交易机会", value: it['label']}))})
    // }

    //投资案例
    let subIndividual_investmentCase = _.get(item, 'individual_investmentCase', [])
    if(_.size(subIndividual_investmentCase) > 0 ) {
        

        _.map(subIndividual_investmentCase, caseItem=> {
            let subCase = dataToCase(caseItem)
            if(_.size(subCase) > 0) {
                _values.push({
                    label: "投资方向", 
                    value: subCase
                })
            }
        })
 
    }




    if(item['individual_personnelMatters'] && _.size(item['individual_personnelMatters']) > 0) {
        let subHR = []
        _.map(item['individual_personnelMatters'], it=> {
            subHR = _.concat(subHR, dataToHR(it))
        } )

        if(_.size(subHR) > 0) {
            _values.push({label: "人事更新", value: subHR})
        }
    }
   
    // console.log("家办及个人===》", _values)
    return _.filter(_values, it=> it != null)
}
function dataToEnterprise(item) {
    let _values = []

    _values.push(fromatOptions({
        label: "投资成本单位", 
        value: item['investmentExitNeeds4Deal_unit']
    }))

    _values.push(fromatOptions({
        label: "投资成本币种", 
        value: item['investmentExitNeeds4Deal_currency']
    }))

    _values.push(fromatOptions({
        label: "当前价值单位", 
        value: item['investmentExitNeeds4Deal_value_unit']
    }))

    _values.push(fromatOptions({
        label: "当前价值币种", 
        value: item['investmentExitNeeds4Deal_value_currency']
    }))
    

    _values.push(fromatOptions({
        label: "出资方", 
        value: item['fund_type']
    }))

    _values.push(fromatOptions({
        label: "出资方类型", 
        value: item['investmentSector_investment_category']
    }))

    _values.push(fromatOptions({
        label: "资产类别", 
        value: item['investmentSector_category']
    }))
    
    _values.push(fromatOptions({
        label: "直投", 
        value: item['investmentSector_category_1']
    }))

    _values.push(fromatOptions({
        label: "收购", 
        value: item['investmentSector_category_2']
    }))

    _values.push(fromatOptions({
        label: "GP", 
        value: item['investmentSector_category_3']
    }))

    _values.push(fromatOptions({
        label: "LP", 
        value: item['investmentSector_category_4']
    }))

    _values.push(fromatOptions({
        label: "投资标的属性", 
        value: item['investmentSector_target_props']
    }))

    _values.push(formatIndustry({
        label: "产业和赛道", 
        value: item['investmentSector_industry']
    }))

    _values.push(fromatOptions({
        label: "地理市场", 
        value: item['investmentSector_area']
    }))

    _values.push(fromatOptions({
        label: "股权", 
        value: item['investmentSector_share']
    }))

    _values.push(fromatOptions({
        label: "币种", 
        value: item['investmentSector_currency']
    }))

    _values.push(fromatOptions({
        label: "当前状态", 
        value: item['investmentSector_status']
    }))

    _values.push(fromatOptions({
        label: "特殊要求", 
        value: item['investmentSector_specialCondition']
    }))
    
    _values.push(formatStringNumber({
        label: "其他特殊要求", 
        value: item['investmentSector_specialConditionText']
    }))

    //基金属性 和 
    _values.push(fromatOptions({
        label: "近1年内是否有新基金募集完成", 
        value: item['fundraising_isFund']
    }))

    _values.push(formatDate({
        label: "募集时间", 
        value: item['fundraising_date']
    }))

    _values.push(fromatOptions({
        label: "单位", 
        value: item['fundraising_unit']
    }))

    _values.push(fromatOptions({
        label: "币种", 
        value: item['fundraising_currency']
    }))

    _values.push(formatStringNumber({
        label: "新基金首关规模", 
        value: item['fundraising_scal']
    }))

    _values.push(formatIndustry({
        label: "投资方向", 
        value: item['fundraising_industry']
    }))
    
    _values.push(fromatOptions({
        label: "单笔投资金额", 
        value: item['fundraising_amount']
    }))
    
    
    //投资方向更新
    _values = _.concat(_values, dataToDirection(item))

    //策略及业务方向更新
    _values.push(fromatOptions({
        label: "是否有新战略方向", 
        value: item['sbdu_newStrategicFocus']
    }))
    // if(item['sbdu_newStrategicFocus'] && _.size(item['sbdu_newStrategicFocus']) > 0) {
    //     _values.push({label: "是否有新战略方向", value: _.join(_.map(item['sbdu_newStrategicFocus'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有新业务", 
        value: item['sbdu_newBussiness']
    }))
    // if(item['sbdu_newBussiness'] && _.size(item['sbdu_newBussiness']) > 0) {
    //     _values.push({label: "是否有新业务", value: _.join(_.map(item['sbdu_newBussiness'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有产业链整合计划", 
        value: item['sbdu_industryChainIntegrationPlan']
    }))
    // if(item['sbdu_industryChainIntegrationPlan'] && _.size(item['sbdu_industryChainIntegrationPlan']) > 0) {
    //     _values.push({label: "是否有产业链整合计划", value: _.join(_.map(item['sbdu_industryChainIntegrationPlan'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有出海计划", 
        value: item['sbdu_overseasExpansionPlan']
    }))
    // if(item['sbdu_overseasExpansionPlan'] && _.size(item['sbdu_overseasExpansionPlan']) > 0) {
    //     _values.push({label: "是否有出海计划", value: _.join(_.map(item['sbdu_overseasExpansionPlan'], 'label'),'，')})
    // }
    //财务数据更新
    _values.push(fromatOptions({
        label: "是否有收入", 
        value: item['fdu_income']
    }))
    // if(item['fdu_income'] && _.size(item['fdu_income']) > 0) {
    //     _values.push({label: "是否有收入", value: _.join(_.map(item['fdu_income'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有正向EBITDA", 
        value: item['fdu_ebita']
    }))
    // if(item['fdu_ebita'] && _.size(item['fdu_ebita']) > 0) {
    //     _values.push({label: "是否有正向EBITDA", value: _.join(_.map(item['fdu_ebita'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有利润", 
        value: item['fdu_netincome']
    }))
    // if(item['fdu_netincome'] && _.size(item['fdu_netincome']) > 0) {
    //     _values.push({label: "是否有利润", value: _.join(_.map(item['fdu_netincome'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有正现金流", 
        value: item['fdu_positiveCashFlow']
    }))
    // if(item['fdu_positiveCashFlow'] && _.size(item['fdu_positiveCashFlow']) > 0) {
    //     _values.push({label: "是否有正现金流", value: _.join(_.map(item['fdu_positiveCashFlow'], 'label'),'，')})
    // }
    
    //是否有融资需求
    _values.push(fromatOptions({
        label: "融资范围", 
        value: item['financingNeeds_range']
    }))
    // if(item['financingNeeds_range'] && _.size(item['financingNeeds_range']) > 0) {
    //     _values.push({label: "融资范围", value: _.join(_.map(item['financingNeeds_range'], 'label'),'，')})
    // }
    _values.push(formatIndustry({
        label: "融资业务所处行业",
        value: item['financingNeeds_industry']
    }))
    
    _values.push(fromatOptions({
        label: "计划融资规模", 
        value: item['financingNeeds_relation']
    }))
    // if(item['financingNeeds_relation'] && _.size(item['financingNeeds_relation']) > 0) {
    //     _values.push({label: "计划融资规模", value: _.join(_.map(item['financingNeeds_relation'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有老股退出需求", 
        value: item['financingNeeds_scale']
    }))
    // if(item['financingNeeds_scale'] && _.size(item['financingNeeds_scale']) > 0) {
    //     _values.push({label: "是否有老股退出需求", value: _.join(_.map(item['financingNeeds_scale'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "新股目标估值（区间）", 
        value: item['financingNeeds_now']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingNeeds_now']))  ) {
    //     _values.push({label: "新股目标估值（区间）", value: formatAmount(item['financingNeeds_now'])})
    // }
    _values.push(formatStringNumber({
        label: "老股目标估值（区间）", 
        value: item['financingNeeds_old']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingNeeds_old']))  ) {
    //     _values.push({label: "老股目标估值（区间）", value: formatAmount(item['financingNeeds_old'])})
    // }
    _values.push(formatDate({
        label: "目标启动时间", 
        value: item['financingNeeds_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingNeeds_date']))  ) {
    //     _values.push({label: "目标启动时间", value: moment(item['financingNeeds_date']).format('YYYY-MM-DD')})
    // }

    _values.push(fromatOptions({
        label: "是否已有FA", 
        value: item['financingNeeds_fa']
    }))
    // if(item['financingNeeds_fa'] && _.size(item['financingNeeds_fa']) > 0) {
    //     _values.push({label: "是否已有FA", value: _.join(_.map(item['financingNeeds_fa'], 'label'),'，')})
    // }

    _values.push(fromatOptions({
        label: "其它相关需求", 
        value: item['financingNeeds_other']
    }))
    _values.push(formatStringNumber({
        label: "补充其它相关需求", 
        value: item['financingNeeds_other_text']
    }))
    // if(item['financingNeeds_other'] && _.size(item['financingNeeds_other']) > 0) {
    //     _values.push({label: "其它相关需求", value: _.join(_.map(item['financingNeeds_other'], 'label'),'，')})
    // }

    //是否有出售需求
    _values.push(fromatOptions({
        label: "融资范围", 
        value: item['saleRequirement_range']
    }))
    // if(item['saleRequirement_range'] && _.size(item['saleRequirement_range']) > 0) {
    //     _values.push({label: "融资范围", value: _.join(_.map(item['saleRequirement_range'], 'label'),'，')})
    // }
    _values.push(formatIndustry({
        label: "出售业务所处行业", 
        value: item['saleRequirement_industry']
    }))
    
    _values.push(formatStringNumber({
        label: "目标估值", 
        value: item['saleRequirement_valuation']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['saleRequirement_valuation']))  ) {
    //     _values.push({label: "目标估值", value: formatAmount(item['saleRequirement_valuation'])})
    // }
    _values.push(formatStringNumber({
        label: "出售股权比例", 
        value: item['saleRequirement_ratio'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['saleRequirement_ratio']))  ) {
    //     _values.push({label: "出售股权比例", value: item['saleRequirement_ratio'] + "%"})
    // }
    _values.push(formatDate({
        label: "目标启动时间", 
        value: item['saleRequirement_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['saleRequirement_date']))  ) {
    //     _values.push({label: "目标启动时间", value: moment(item['saleRequirement_date']).format('YYYY-MM-DD')})
    // }
    _values.push(fromatOptions({
        label: "是否已有FA", 
        value: item['saleRequirement_range']
    }))
    // if(item['saleRequirement_range'] && _.size(item['saleRequirement_fa']) > 0) {
    //     _values.push({label: "是否已有FA", value: _.join(_.map(item['saleRequirement_fa'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "其它相关需求", 
        value: item['saleRequirement_other']
    }))
    _values.push(fromatOptions({
        label: "补充其它相关需求", 
        value: item['saleRequirement_other_text']
    }))
    // if(item['saleRequirement_other'] && _.size(item['saleRequirement_other']) > 0) {
    //     _values.push({label: "其它相关需求", value: _.join(_.map(item['saleRequirement_other'], 'label'),'，')})
    // }


    //是否有并购整合需求
    _values.push(formatIndustry({
        label: "标的公司所处行业", 
        value: item['mergerAndAcquisitionNeeds_industry']
    }))
    _values.push(fromatOptions({
        label: "目标公司地理市场", 
        value: item['mergerAndAcquisitionNeeds_location']
    }))
    // if(item['mergerAndAcquisitionNeeds_location'] && _.size(item['mergerAndAcquisitionNeeds_location']) > 0) {
    //     _values.push({label: "目标公司地理市场", value: _.join(_.map(item['mergerAndAcquisitionNeeds_location'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否必须有收入（收入水平等选项）", 
        value: item['mergerAndAcquisitionNeeds_income']
    }))
    // if(item['mergerAndAcquisitionNeeds_income'] && _.size(item['mergerAndAcquisitionNeeds_income']) > 0) {
    //     _values.push({label: "是否必须有收入（收入水平等选项）", value: _.join(_.map(item['mergerAndAcquisitionNeeds_income'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否必须有利润（利润水平等选项）", 
        value: item['mergerAndAcquisitionNeeds_profit']
    }))
    // if(item['mergerAndAcquisitionNeeds_profit'] && _.size(item['mergerAndAcquisitionNeeds_profit']) > 0) {
    //     _values.push({label: "是否必须有利润（利润水平等选项）", value: _.join(_.map(item['mergerAndAcquisitionNeeds_profit'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "目标持股比例", 
        value: item['mergerAndAcquisitionNeeds_ratio'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['mergerAndAcquisitionNeeds_ratio']))  ) {
    //     _values.push({label: "目标持股比例", value: item['mergerAndAcquisitionNeeds_ratio'] + "%"})
    // }
    _values.push(formatDate({
        label: "目标启动时间", 
        value: item['mergerAndAcquisitionNeeds_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['mergerAndAcquisitionNeeds_date']))  ) {
    //     _values.push({label: "目标启动时间", value: moment(item['mergerAndAcquisitionNeeds_date']).format('YYYY-MM-DD')})
    // }
    _values.push(fromatOptions({
        label: "是否已有FA", 
        value: item['mergerAndAcquisitionNeeds_fa']
    }))
    // if(item['mergerAndAcquisitionNeeds_fa'] && _.size(item['mergerAndAcquisitionNeeds_fa']) > 0) {
    //     _values.push({label: "是否已有FA", value: _.join(_.map(item['mergerAndAcquisitionNeeds_fa'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "重要考量因素", 
        value: item['mergerAndAcquisitionNeeds_considerations']
    }))
    // if(item['mergerAndAcquisitionNeeds_considerations'] && _.size(item['mergerAndAcquisitionNeeds_considerations']) > 0) {
    //     _values.push({label: "重要考量因素", value: _.join(_.map(item['mergerAndAcquisitionNeeds_considerations'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "当前状态", 
        value: item['mergerAndAcquisitionNeeds_currentStatus']
    }))
    // if(item['mergerAndAcquisitionNeeds_currentStatus'] && _.size(item['mergerAndAcquisitionNeeds_currentStatus']) > 0) {
    //     _values.push({label: "当前状态", value: _.join(_.map(item['mergerAndAcquisitionNeeds_currentStatus'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "其它相关需求", 
        value: item['mergerAndAcquisitionNeeds_other']
    }))
    // if(item['mergerAndAcquisitionNeeds_other'] && _.size(item['mergerAndAcquisitionNeeds_other']) > 0) {
    //     _values.push({label: "其它相关需求", value: _.join(_.map(item['mergerAndAcquisitionNeeds_other'], 'label'),'，')})
    // }
   


    //是否有分拆/重组需求
    if( _.isArray(item['type']) && _.includes(['date', 'string', 'number'], typeof(_.head(item['type'])))  ) {
        _values.push({label: "是否有分拆/重组需求", value: _.head(item['type'])})
    }
    if( _.isArray(item['cateogry']) && _.includes(['date', 'string', 'number'], typeof(_.head(item['cateogry'])))  ) {
        _values.push({label: _.head(item['cateogry']), value: "是"})
    }
    _values.push(formatStringNumber({
        label: "需求描述", 
        value: item['splitAndRestructureNeeds_content']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['splitAndRestructureNeeds_content']))  ) {
    //     _values.push({label: "需求描述", value: item['splitAndRestructureNeeds_content']})
    // }
    _values.push(fromatOptions({
        label: "融资范围", 
        value: item['splitAndRestructureNeeds_range']
    }))
    // if(item['splitAndRestructureNeeds_range'] && _.size(item['splitAndRestructureNeeds_range']) > 0) {
    //     _values.push({label: "融资范围", value: _.join(_.map(item['splitAndRestructureNeeds_range'], 'label'),'，')})
    // }
    _values.push(formatIndustry({
        label: "融资业务所处行业", 
        value: item['splitAndRestructureNeeds_industry']
    }))
    _values.push(fromatOptions({
        label: "计划融资规模", 
        value: item['splitAndRestructureNeeds_scale']
    }))
    // if(item['splitAndRestructureNeeds_scale'] && _.size(item['splitAndRestructureNeeds_scale']) > 0) {
    //     _values.push({label: "计划融资规模", value: _.join(_.map(item['splitAndRestructureNeeds_scale'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有老股退出需求", 
        value: item['splitAndRestructureNeeds_inOutService']
    }))
    // if(item['splitAndRestructureNeeds_inOutService'] && _.size(item['splitAndRestructureNeeds_inOutService']) > 0) {
    //     _values.push({label: "是否有老股退出需求", value: _.join(_.map(item['splitAndRestructureNeeds_inOutService'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "新股目标估值（区间）", 
        value: item['splitAndRestructureNeeds_new']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['splitAndRestructureNeeds_new']))  ) {
    //     _values.push({label: "新股目标估值（区间）", value: item['splitAndRestructureNeeds_new']})
    // }
    _values.push(formatStringNumber({
        label: "老股目标估值（区间）", 
        value: item['splitAndRestructureNeeds_old']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['splitAndRestructureNeeds_old']))  ) {
    //     _values.push({label: "老股目标估值（区间）", value: item['splitAndRestructureNeeds_old']})
    // }
    _values.push(formatDate({
        label: "目标启动时间", 
        value: item['splitAndRestructureNeeds_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['splitAndRestructureNeeds_date']))  ) {
    //     _values.push({label: "目标启动时间", value: moment(item['splitAndRestructureNeeds_date']).format('YYYY-MM-DD')})
    // }
    _values.push(fromatOptions({
        label: "是否已有FA", 
        value: item['splitAndRestructureNeeds_fa']
    }))
    // if(item['splitAndRestructureNeeds_fa'] && _.size(item['splitAndRestructureNeeds_fa']) > 0) {
    //     _values.push({label: "是否已有FA", value: _.join(_.map(item['splitAndRestructureNeeds_fa'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "其它相关需求", 
        value: item['splitAndRestructureNeeds_other']
    }))
    _values.push(formatStringNumber({
        label: "补充其它相关需求", 
        value: item['splitAndRestructureNeeds_other_text']
    }))
    // if(item['splitAndRestructureNeeds_other'] && _.size(item['splitAndRestructureNeeds_other']) > 0) {
    //     _values.push({label: "其它相关需求", value: _.join(_.map(item['splitAndRestructureNeeds_other'], 'label'),'，')})
    // }


    //是否有投资需求
    _values.push(fromatOptions({
        label: "投资载体", 
        value: item['investmentNeeds_carrier']
    }))
    // if(item['investmentNeeds_carrier'] && _.size(item['investmentNeeds_carrier']) > 0) {
    //     _values.push({label: "投资载体", value: _.join(_.map(item['investmentNeeds_carrier'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "投资方式", 
        value: item['investmentNeeds_way']
    }))
    // if(item['investmentNeeds_way'] && _.size(item['investmentNeeds_way']) > 0) {
    //     _values.push({label: "投资方式", value: _.join(_.map(item['investmentNeeds_way'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "资产类别", 
        value: item['investmentNeeds_category']
    }))
    // if(item['investmentNeeds_category'] && _.size(item['investmentNeeds_category']) > 0) {
    //     _values.push({label: "资产类别", value: _.join(_.map(item['investmentNeeds_category'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "投资标的属性", 
        value: item['investmentNeeds_considerations']
    }))
    // if(item['investmentNeeds_considerations'] && _.size(item['investmentNeeds_considerations']) > 0) {
    //     _values.push({label: "投资标的属性", value: _.join(_.map(item['investmentNeeds_considerations'], 'label'),'，')})
    // }
    _values.push(formatIndustry({
        label: "目标产业及赛道", 
        value: item['investmentNeeds_industry']
    }))
    

    _values.push(fromatOptions({
        label: "目标地理市场", 
        value: item['investmentNeeds_location']
    }))
    // if(item['investmentNeeds_location'] && _.size(item['investmentNeeds_location']) > 0) {
    //     _values.push({label: "目标地理市场", value: _.join(_.map(item['investmentNeeds_location'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "当前状态", 
        value: item['investmentNeeds_currentStatus']
    }))
    // if(item['investmentNeeds_currentStatus'] && _.size(item['investmentNeeds_currentStatus']) > 0) {
    //     _values.push({label: "当前状态", value: _.join(_.map(item['investmentNeeds_currentStatus'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "其它相关需求", 
        value: item['investmentNeeds_other']
    }))
    _values.push(formatStringNumber({
        label: "补充其它相关需求", 
        value: item['investmentNeeds_other_text']
    }))
    // if(item['investmentNeeds_other'] && _.size(item['investmentNeeds_other']) > 0) {
    //     _values.push({label: "其它相关需求", value: _.join(_.map(item['investmentNeeds_other'], 'label'),'，')})
    // }

    //是否有募资需求
    _values.push(fromatOptions({
        label: "是否正在考虑设立产业投资基金", 
        value: item['capitalRaisingRequirement_isFund']
    }))
    // if(item['capitalRaisingRequirement_isFund'] && _.size(item['capitalRaisingRequirement_isFund']) > 0) {
    //     _values.push({label: "是否正在考虑设立产业投资基金", value: _.join(_.map(item['capitalRaisingRequirement_isFund'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "目标基金规模", 
        value: item['capitalRaisingRequirement_scale']
    }))
    // if(item['capitalRaisingRequirement_scale'] && _.size(item['capitalRaisingRequirement_scale']) > 0) {
    //     _values.push({label: "目标基金规模", value: _.join(_.map(item['capitalRaisingRequirement_scale'], 'label'),'，')})
    // }
    _values.push(formatDate({
        label: "目标完成募集时间", 
        value: item['capitalRaisingRequirement_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['capitalRaisingRequirement_date']))  ) {
    //     _values.push({label: "目标完成募集时间", value: moment(item['capitalRaisingRequirement_date']).format('YYYY-MM-DD')})
    // }
    _values.push(formatStringNumber({
        label: "资金缺口", 
        value: item['capitalRaisingRequirement_money']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['capitalRaisingRequirement_money']))  ) {
    //     _values.push({label: "资金缺口", value: formatAmount(item['capitalRaisingRequirement_money'])})
    // }
    _values.push(fromatOptions({
        label: "基金属性", 
        value: item['capitalRaisingRequirement_fundProps']
    }))
    // if(item['capitalRaisingRequirement_fundProps'] && _.size(item['capitalRaisingRequirement_fundProps']) > 0) {
    //     _values.push({label: "基金属性", value: _.join(_.map(item['capitalRaisingRequirement_fundProps'], 'label'),'，')})
    // }
    //项目投退
    _values.push(formatStringNumber({
        label: "项目名称", 
        value: item['investmentExitNeeds4Deal_name']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Deal_name']))  ) {
    //     _values.push({label: "项目名称", value: formatAmount(item['investmentExitNeeds4Deal_name'])})
    // }
    _values.push(formatIndustry({
        label: "项目所处行业", 
        value: item['investmentExitNeeds4Deal_industry']
    }))
    
    _values.push(formatDate({
        label: "投资时间", 
        value: item['investmentExitNeeds4Deal_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Deal_date']))  ) {
    //     _values.push({label: "投资时间", value: moment(item['investmentExitNeeds4Deal_date']).format('YYYY-MM-DD')})
    // }
    _values.push(formatStringNumber({
        label: "投资成本", 
        value: item['investmentExitNeeds4Deal_cost']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Deal_cost']))  ) {
    //     _values.push({label: "投资成本", value: formatAmount(item['investmentExitNeeds4Deal_cost'])})
    // }
    _values.push(formatStringNumber({
        label: "当前价值", 
        value: item['investmentExitNeeds4Deal_value']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Deal_value']))  ) {
    //     _values.push({label: "当前价值", value: formatAmount(item['investmentExitNeeds4Deal_value'])})
    // }
    

    //基金投退
    _values.push(formatStringNumber({
        label: "基金名称", 
        value: item['investmentExitNeeds4Fund_name']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Fund_name']))  ) {
    //     _values.push({label: "基金名称", value: formatAmount(item['investmentExitNeeds4Fund_name'])})
    // }
    _values.push(formatStringNumber({
        label: "基金持股比例", 
        value: item['investmentExitNeeds4Fund_ratio'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Fund_ratio']))  ) {
    //     _values.push({label: "基金持股比例", value: item['investmentExitNeeds4Fund_ratio'] + "%"})
    // }
    _values.push(formatDate({
        label: "投资时间", 
        value: item['investmentExitNeeds4Fund_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Fund_date']))  ) {
    //     _values.push({label: "投资时间", value: moment(item['investmentExitNeeds4Fund_date']).format('YYYY-MM-DD')})
    // }
    _values.push(formatStringNumber({
        label: "投资成本", 
        value: item['investmentExitNeeds4Fund_cost']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Fund_cost']))  ) {
    //     _values.push({label: "投资成本", value: formatAmount(item['investmentExitNeeds4Fund_cost'])})
    // }
    _values.push(formatStringNumber({
        label: "当前价值", 
        value: item['investmentExitNeeds4Fund_value']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4Fund_value']))  ) {
    //     _values.push({label: "当前价值", value: formatAmount(item['investmentExitNeeds4Fund_value'])})
    // }

    //接续基金
    _values.push(formatStringNumber({
        label: "基金名称", 
        value: item['investmentExitNeeds4SuccessionFund_name']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4SuccessionFund_name']))  ) {
    //     _values.push({label: "基金名称", value: formatAmount(item['investmentExitNeeds4SuccessionFund_name'])})
    // }
    _values.push(fromatOptions({
        label: "基金管理人（GP）", 
        value: item['investmentExitNeeds4SuccessionFund_users']
    }))
    // if(item['investmentExitNeeds4SuccessionFund_users'] && _.size(item['investmentExitNeeds4SuccessionFund_users']) > 0) {
    //     _values.push({label: "基金管理人（GP）", value: _.join(_.map(item['investmentExitNeeds4SuccessionFund_users'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "项目范围", 
        value: item['investmentExitNeeds4SuccessionFund_range']
    }))
    // if(item['investmentExitNeeds4SuccessionFund_range'] && _.size(item['investmentExitNeeds4SuccessionFund_range']) > 0) {
    //     _values.push({label: "项目范围", value: _.join(_.map(item['investmentExitNeeds4SuccessionFund_range'], 'label'),'，')})
    // }
    _values.push(formatDate({
        label: "投资时间", 
        value: item['investmentExitNeeds4SuccessionFund_date']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4SuccessionFund_date']))  ) {
    //     _values.push({label: "投资时间", value: moment(item['investmentExitNeeds4SuccessionFund_date']).format('YYYY-MM-DD')})
    // }
    _values.push(formatStringNumber({
        label: "投资成本", 
        value: item['investmentExitNeeds4SuccessionFund_cost']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4SuccessionFund_cost']))  ) {
    //     _values.push({label: "投资成本", value: formatAmount(item['investmentExitNeeds4SuccessionFund_cost'])})
    // }
    _values.push(formatStringNumber({
        label: "当前价值", 
        value: item['investmentExitNeeds4SuccessionFund_value']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['investmentExitNeeds4SuccessionFund_value']))  ) {
    //     _values.push({label: "当前价值", value: formatAmount(item['investmentExitNeeds4SuccessionFund_value'])})
    // }






    
    _values = _.concat(_values, dataToFinancial('收入具体财务数据', item, 'fdu_income_fdu_financialData'))
    _values = _.concat(_values, dataToFinancial('正向EBITDA具体财务数据', item, 'fdu_ebita_fdu_financialData'))
    _values = _.concat(_values, dataToFinancial('利润具体财务数据', item, 'fdu_netincome_fdu_financialData'))
    _values = _.concat(_values, dataToFinancial('正现金流具体财务数据', item, 'fdu_positiveCashFlow_fdu_financialData'))
    
    //投资方向
    // let subfinancialData= _.get(item, 'fdu_financialData', [])
    // // console.log("subIndividual_direction===>", subIndividual_direction)
    // if(_.size(subfinancialData) > 0 ) {
        
    //     _.map(subfinancialData, data=> {
    //         let subDirections = []

    //         if( _.includes(['date', 'string', 'number'], typeof(data['fdd_year'])) ) {
    //             subDirections.push({label: "年", value: moment(data['fdd_year']).format('YYYY')})
    //         }

    //         if(data['fdd_currency'] && _.size(data['fdd_currency']) > 0) {
    //             subDirections.push({
    //                 label: "币种",  
    //                 value: _.join(_.map(data['fdd_currency'], 'label')),
    //             })
    //         }

    //         if(data['fdd_unit'] && _.size(data['fdd_unit']) > 0) {
    //             subDirections.push({
    //                 label: "单位",  
    //                 value: _.join(_.map(data['fdd_unit'], 'label')),
    //             })
    //         }

    //         if(data['fdd_income'] && _.size(data['fdd_income']) > 0) {
    //             subDirections.push({
    //                 label: "收入",  
    //                 value: _.join(_.map(data['fdd_income'], 'label')),
    //             })
    //         }
    //         if( _.includes(['date', 'string', 'number'], typeof(data['fdd_incomeMoney'])) ) {
    //             subDirections.push({label: "收入金额", value: formatAmount(data['fdd_incomeMoney'])})
    //         }
    //         if(data['fdd_profit'] && _.size(data['fdd_profit']) > 0) {
    //             subDirections.push({
    //                 label: "净利润",  
    //                 value: _.join(_.map(data['fdd_profit'], 'label')),
    //             })
    //         }
    //         if( _.includes(['date', 'string', 'number'], typeof(data['fdd_profitMoney'])) ) {
    //             subDirections.push({label: "净利润金额", value: formatAmount(data['fdd_profitMoney'])})
    //         }

    //         if(data['fdd_ebitda'] && _.size(data['fdd_ebitda']) > 0) {
    //             subDirections.push({
    //                 label: "净利润",  
    //                 value: _.join(_.map(data['fdd_ebitda'], 'label')),
    //             })
    //         }
    //         if( _.includes(['date', 'string', 'number'], typeof(data['fdd_ebitdaMoney'])) ) {
    //             subDirections.push({label: "EBITDA金额", value: formatAmount(data['fdd_ebitdaMoney'])})
    //         }


    //         if(_.size(subDirections) > 0) {
    //             _values.push({
    //                 label: "具体财务数据", 
    //                 value: subDirections
    //             })
    //         }


    //     })

    // }
    //股权结构
    _values.push(fromatOptions({
        label: "是否有实际控制人", 
        value: item['ownershipStructure_isActualController']
    }))
    // if(item['ownershipStructure_isActualController'] && _.size(item['ownershipStructure_isActualController']) > 0) {
    //     _values.push({label: "是否有实际控制人", value: _.join(_.map(item['ownershipStructure_isActualController'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "创始人/实际控制人在过去1年内是否发生变化", 
        value: item['ownershipStructure_isActualControllerChange']
    }))
    // if(item['ownershipStructure_isActualControllerChange'] && _.size(item['ownershipStructure_isActualControllerChange']) > 0) {
    //     _values.push({label: "创始人/实际控制人在过去1年内是否发生变化", value: _.join(_.map(item['ownershipStructure_isActualControllerChange'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "创始人/股东在过去1年内是否发生变化", 
        value: item['ownershipStructure_isShareholderChange']
    }))
    // if(item['ownershipStructure_isShareholderChange'] && _.size(item['ownershipStructure_isShareholderChange']) > 0) {
    //     _values.push({label: "创始人/股东在过去1年内是否发生变化", value: _.join(_.map(item['ownershipStructure_isShareholderChange'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "创始人/实际控制人股权", 
        value: item['ownershipStructure_isShareholderChange']
    }))
    // if(item['ownershipStructure_isScale'] && _.size(item['ownershipStructure_isScale']) > 0) {
    //     _values.push({label: "创始人/实际控制人股权", value: _.join(_.map(item['ownershipStructure_isScale'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "是否有明确控股机会", 
        value: item['ownershipStructure_isShareholding']
    }))
    // if(item['ownershipStructure_isShareholding'] && _.size(item['ownershipStructure_isShareholding']) > 0) {
    //     _values.push({label: "是否有明确控股机会", value: _.join(_.map(item['ownershipStructure_isShareholding'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "管理层股权占比", 
        value: item['ownershipStructure_manageShareholding'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['ownershipStructure_manageShareholding'])) ) {
    //     _values.push({label: "管理层股权占比", value: item['ownershipStructure_manageShareholding']+ "%"})
    // }
    _values.push(formatStringNumber({
        label: "所有外部机构股权占比", 
        value: item['ownershipStructure_otherShareholding'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['ownershipStructure_otherShareholding']))) {
    //     _values.push({label: "所有外部机构股权占比", value: item['ownershipStructure_otherShareholding']+ "%" })
    // }
    _values.push(formatStringNumber({
        label: "最大外部机构股权占比", 
        value: item['ownershipStructure_maxOtherShareholding'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['ownershipStructure_maxOtherShareholding']))  ) {
    //     _values.push({label: "最大外部机构股权占比", value: item['ownershipStructure_maxOtherShareholding'] + "%"})
    // }
    //融资并购历史
    _values.push(fromatOptions({
        label: "交易类型", 
        value: item['financingHistory_category']
    }))
    // if(item['financingHistory_category'] && _.size(item['financingHistory_category']) > 0) {
    //     _values.push({label: "交易类型", value: _.join(_.map(item['financingHistory_category'], 'label'),'，')})
    // }
    _values.push(formatDate({
        label: "交易日期", 
        value: item['financingHistory_tradeDate']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_tradeDate']))  ) {
    //     _values.push({label: "交易日期", value: moment(item['financingHistory_tradeDate']).format('YYYY-MM-DD')})
    // }
    _values.push(fromatOptions({
        label: "公司名称", 
        value: item['financingHistory_company']
    }))
    // if(item['financingHistory_company'] && _.size(item['financingHistory_company']) > 0) {
    //     _values.push({label: "公司名称", value: _.join(_.map(item['financingHistory_company'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "投资机构", 
        value: item['financingHistory_investment']
    }))
    // if(item['financingHistory_investment'] && _.size(item['financingHistory_investment']) > 0) {
    //     _values.push({label: "投资机构", value: _.join(_.map(item['financingHistory_investment'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "投资人", 
        value: item['financingHistory_investor']
    }))
    // if(item['financingHistory_investor'] && _.size(item['financingHistory_investor']) > 0) {
    //     _values.push({label: "投资人", value: _.join(_.map(item['financingHistory_investor'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "融资轮次", 
        value: item['financingHistory_round']
    }))
    // if(item['financingHistory_round'] && _.size(item['financingHistory_round']) > 0) {
    //     _values.push({label: "融资轮次", value: _.join(_.map(item['financingHistory_round'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "融资金额单位", 
        value: item['financingHistory_amountUnit']
    }))
    // if(item['financingHistory_amountUnit'] && _.size(item['financingHistory_amountUnit']) > 0) {
    //     _values.push({label: "融资金额单位", value: _.join(_.map(item['financingHistory_amountUnit'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "融资金额币种", 
        value: item['financingHistory_currency']
    }))
    // if(item['financingHistory_currency'] && _.size(item['financingHistory_currency']) > 0) {
    //     _values.push({label: "融资金额币种", value: _.join(_.map(item['financingHistory_currency'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "融资金额", 
        value: item['financingHistory_amount']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_amount']))  ) {
    //     _values.push({label: "融资金额", value: formatAmount(item['financingHistory_amount'])})
    // }
    _values.push(formatStringNumber({
        label: "股权比例", 
        value: item['financingHistory_amount'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_amount']))  ) {
    //     _values.push({label: "股权比例", value: item['financingHistory_equityRatio'] + "%"})
    // }
    _values.push(fromatOptions({
        label: "投后估值单位", 
        value: item['financingHistory_valueUnit']
    }))
    // if(item['financingHistory_valueUnit'] && _.size(item['financingHistory_valueUnit']) > 0) {
    //     _values.push({label: "投后估值单位", value: _.join(_.map(item['financingHistory_valueUnit'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "投后估值币种", 
        value: item['financingHistory_valueCurrency']
    }))
    // if(item['financingHistory_valueCurrency'] && _.size(item['financingHistory_valueCurrency']) > 0) {
    //     _values.push({label: "投后估值币种", value: _.join(_.map(item['financingHistory_valueCurrency'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "投后估值", 
        value: item['financingHistory_postValue']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_postValue']))  ) {
    //     _values.push({label: "投后估值", value: formatAmount(item['financingHistory_postValue'])})
    // }

    _values.push(fromatOptions({
        label: "被收购方名称", 
        value: item['financingHistory_ma_company']
    }))
    // if(item['financingHistory_ma_company'] && _.size(item['financingHistory_ma_company']) > 0) {
    //     _values.push({label: "被收购方名称", value: _.join(_.map(item['financingHistory_ma_company'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "并购金额单位", 
        value: item['financingHistory_ma_amountUnit']
    }))
    // if(item['financingHistory_ma_amountUnit'] && _.size(item['financingHistory_ma_amountUnit']) > 0) {
    //     _values.push({label: "并购金额单位", value: _.join(_.map(item['financingHistory_ma_amountUnit'], 'label'),'，')})
    // }
    _values.push(fromatOptions({
        label: "并购金额币种", 
        value: item['financingHistory_ma_currency']
    }))
    // if(item['financingHistory_ma_currency'] && _.size(item['financingHistory_ma_currency']) > 0) {
    //     _values.push({label: "并购金额币种", value: _.join(_.map(item['financingHistory_ma_currency'], 'label'),'，')})
    // }
    _values.push(formatStringNumber({
        label: "并购金额", 
        value: item['financingHistory_ma_amount']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_ma_amount']))  ) {
    //     _values.push({label: "并购金额", value: formatAmount(item['financingHistory_ma_amount'])})
    // }
    _values.push(formatStringNumber({
        label: "收购方股权比例", 
        value: item['financingHistory_ma_equityRatio'],
        suffix: "%"
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_ma_equityRatio']))  ) {
    //     _values.push({label: "收购方股权比例", value: item['financingHistory_ma_equityRatio'] + "%"})
    // }

    _values.push(formatStringNumber({
        label: "交易标题", 
        value: item['financingHistory_title']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_title']))  ) {
    //     _values.push({label: "交易标题", value: formatAmount(item['financingHistory_title'])})
    // }
    _values.push(formatStringNumber({
        label: "交易内容", 
        value: item['financingHistory_content']
    }))
    // if( _.includes(['date', 'string', 'number'], typeof(item['financingHistory_content']))  ) {
    //     _values.push({label: "交易内容", value: formatAmount(item['financingHistory_content'])})
    // }
    _values.push(fromatOptions({
        label: "买房机构", 
        value: item['financingHistory_sell_company']
    }))
    _values.push(fromatOptions({
        label: "出售金额单位", 
        value: item['financingHistory_sell_amountUnit']
    }))
    _values.push(fromatOptions({
        label: "出售金额币种", 
        value: item['financingHistory_sell_currency']
    }))
    _values.push(formatStringNumber({
        label: "出售金额", 
        value: item['financingHistory_sell_amount']
    }))
    _values.push(formatStringNumber({
        label: "出售金额占股权比例", 
        value: item['financingHistory_sell_equityRatio'],
        suffix: "%"
    }))
    //投资案例
    _values = _.concat(_values, dataToCase(item))
    //人事更新
    if( _.includes(['date', 'string', 'number'], typeof(item['name'])) && item['status'] && _.size(item['status']) > 0 ) {
        // _values.push({label: "人事更新", value: dataToHR(item)})
        _values = _.concat(_values, dataToHR(item))
    }
    
    
    

    return _.filter(_values, it=> it != null)
}
// 投资案例
function dataToCase(caseItem){

    let subCase = []
    subCase.push(fromatOptions({
        label: "投资标的", 
        value: caseItem['direction_category']
    }))
    
    subCase.push(fromatOptions({
        label: "投资轮次",  
        value: caseItem['investmentCase_round'],
    }))

    subCase.push(formatDate({
        label: "投资时间", 
        value: caseItem['investmentCase_date']
    }))
   
    subCase.push(fromatOptions({
        label: "投资币种",  
        value: caseItem['investmentCase_momenyCurrency'],
    }))

    subCase.push(fromatOptions({
        label: "投资单位",  
        value: caseItem['investmentCase_momenyUnit'],
    }))
    subCase.push(formatStringNumber({
        label: "投资金额", 
        value: caseItem['investmentCase_momeny']
    }))
    
    subCase.push(fromatOptions({
        label: "投资方式",  
        value: caseItem['investmentCase_way'],
    }))
    
    subCase.push(fromatOptions({
        label: "最新估值币种",  
        value: caseItem['investmentCase_valuationCurrency'],
    }))
    
    subCase.push(fromatOptions({
        label: "最新估值单位",  
        value: caseItem['investmentCase_valuationUnit'],
    }))

    subCase.push(formatStringNumber({
        label: "最新估值", 
        value: caseItem['investmentCase_valuation']
    }))
    
    subCase.push(formatStringNumber({
        label: "当前持股比例", 
        value: caseItem['investmentCase_ratio'],
        suffix: "%"
    }))

    subCase.push(fromatOptions({
        label: "项目主要负责人",  
        value: caseItem['investmentCase_ower'],
    }))

    

    
    return _.filter(subCase, it=> it != null)
}
// 投资方向
function dataToDirection(direction){
    let subDirections = []
    subDirections.push(fromatOptions({
        label: "资产类别",  
        value: direction['direction_category'],
    }))
    
    subDirections.push(fromatOptions({
        label: "投资标的属性",  
        value: direction['direction_investmentTargetProp'],
    }))
    
    subDirections.push(formatIndustry({
        label: "产业和赛道", value: direction['direction_industry']
    }))

    subDirections.push(fromatOptions({
        label: "目标阶段",  
        value: direction['direction_targetStage'],
    }))
    
    subDirections.push(fromatOptions({
        label: "地理市场",  
        value: direction['direction_location'],
    }))
    
    subDirections.push(fromatOptions({
        label: "股权",  
        value: direction['direction_ownership'],
    }))
    
    subDirections.push(fromatOptions({
        label: "币种",  
        value: direction['direction_currency'],
    }))
    
    subDirections.push(fromatOptions({
        label: "当前状态",  
        value: direction['direction_currentStatus'],
    }))
    
    
    subDirections.push(fromatOptions({
        label: "特殊要求",  
        value: direction['direction_specialRequirement'],
    }))

    

    return _.filter(subDirections, it=> it != null)
}
// 人事更新
function dataToHR(data) {
    let _values = []

    _values.push(formatStringNumber({
        label: "姓名", value: data['name']
    }))
    
    _values.push(formatStringNumber({
        label: "职务", value: data['job']
    }))

    _values.push(fromatOptions({
        label: "人事动态", value: data['status']
    }))

    
    _values.push(fromatOptions({
        label: "上一任就职雇主", value: data['historyEmployee']
    }))

    _values.push(fromatOptions({
        label: "离职去向", value: data['newEmployee']
    }))

    _values.push(formatStringNumber({
        label: "升值后职务", value: data['jobNew']
    }))

    _values.push(formatStringNumber({
        label: "电话", value: data['phone']
    }))

    _values.push(formatStringNumber({
        label: "电子邮件", value: data['email']
    }))
    // console.log("HR", _.filter(_values, it=> it != null))
    return _.filter(_values, it=> it != null)
}

function dataToFinancial(label, data, key){
    let _values = []
    //投资方向
    let subfinancialData= _.get(data, key, [])
    if(_.size(subfinancialData) > 0 ) {
        
        _.map(subfinancialData, data=> {
            let subDirections = []

            if( _.includes(['date', 'string', 'number'], typeof(data['fdd_year'])) ) {
                subDirections.push({label: "年", value: moment(data['fdd_year']).format('YYYY')})
            }

            if(data['fdd_currency'] && _.size(data['fdd_currency']) > 0) {
                subDirections.push({
                    label: "币种",  
                    value: _.join(_.map(data['fdd_currency'], 'label')),
                })
            }

            if(data['fdd_unit'] && _.size(data['fdd_unit']) > 0) {
                subDirections.push({
                    label: "单位",  
                    value: _.join(_.map(data['fdd_unit'], 'label')),
                })
            }

            if(data['fdd_income'] && _.size(data['fdd_income']) > 0) {
                subDirections.push({
                    label: "收入",  
                    value: _.join(_.map(data['fdd_income'], 'label')),
                })
            }
            if( _.includes(['date', 'string', 'number'], typeof(data['fdd_incomeMoney'])) ) {
                subDirections.push({label: "收入金额", value: formatAmount(data['fdd_incomeMoney'])})
            }
            if(data['fdd_profit'] && _.size(data['fdd_profit']) > 0) {
                subDirections.push({
                    label: "利润",  
                    value: _.join(_.map(data['fdd_profit'], 'label')),
                })
            }
            if( _.includes(['date', 'string', 'number'], typeof(data['fdd_profitMoney'])) ) {
                subDirections.push({label: "利润金额", value: formatAmount(data['fdd_profitMoney'])})
            }

            if(data['fdd_ebitda'] && _.size(data['fdd_ebitda']) > 0) {
                subDirections.push({
                    label: "利润",  
                    value: _.join(_.map(data['fdd_ebitda'], 'label')),
                })
            }
            if( _.includes(['date', 'string', 'number'], typeof(data['fdd_ebitdaMoney'])) ) {
                subDirections.push({label: "EBITDA金额", value: formatAmount(data['fdd_ebitdaMoney'])})
            }


            if(_.size(subDirections) > 0) {
                _values.push({
                    label: label, 
                    value: subDirections
                })
            }


        })

    }

    return _values
}
//
function dataToUpdateContent(tragets, data){
    const _governmentsKey = 'memo_option_list_update_content'
    
    let list = _.map(tragets, target=> {
        //获取对象政府id
        let _governmentsId = _governmentsKey
        if(target.targetIds != null){
            _governmentsId = `${_governmentsId}_${target.targetIds}_1`
        }
        let _governments = []
        _.map(_.map(_.get(data, _governmentsId, []), ), ({metadata, value})=> {
            let item = value
            let _values = []
            
            _values = _.concat(
                _values, 
                //政府
                dataToGovernment(item), 
                //家办个人
                dataToIndividual(item),
                //企业和机构
                dataToEnterprise(item),
            )

            


            
            

            _governments.push({
                label: metadata['label'], 
                value: [..._values]
            })
        })
        let others = _.concat(_.isArray(target.others) ? target.others: [], _governments)
    
        return {
            ...target,
            others: others
        }

    })

    return list
}

export function parse(item){
    return _.concat( 
        //政府
        dataToGovernment(item), 
        //家办个人
        dataToIndividual(item),
        //企业和机构
        dataToEnterprise(item),
    )
}

export function meetingFormat(data) {
    // console.log("meetingFormat", data)
    // 会议基本信息
    let baseInfo = _.get(data, 'memo_option_list_base_info', {})
    // 我方参会人
    let baseInfoUsers = _.get(data, 'memo_option_list_attend_ceccapital_user.value', [])
    // 会议内容 
    let baseInfoContent = _.get(data, 'memo_option_list_memo_content.value', '')
    // 会议音频文件 audio
    let baseInfoAudios = _.get(data, 'memo_option_list_memo_content.audio', null)
  
    // 与会方
    let targets = [
        {
            targetIds: null,
            //与会者编号
            attendeeId: _.get(_.head(_.get(data, 'memo_option_list_attend_target.targets', [])), 'value'), 
            //与会者名字            
            attendeeName: _.get(_.head(_.get(data, 'memo_option_list_attend_target.targets', [])), 'label'),  
            //与会者身份编号     
            identityId: _.get(_.head(_.get(data, 'memo_option_list_attend_target.targetTypes', [])), 'value'), 
            //与会者身份名称          
            identityName: _.get(_.head(_.get(data, 'memo_option_list_attend_target.targetTypes', [])), 'label'),         
            //与会方参会人 
            attendeeUsers: _.get(_.head(_.get(data, 'memo_option_list_attend_target_user.value', [])), 'label'),  
            //更多数据
            others: [],
        },
    ]

    let targetsIds = _.filter(_.map(_.keys(data), key=> {
        // console.log("data key==>", key, _.includes(key, 'memo_option_list_attend_target_'))
        if(
            _.includes(key, 'memo_option_list_attend_target_') 
            && key != 'memo_option_list_attend_target_user'
        ) {
            let id = _.replace(key, 'memo_option_list_attend_target_', '')
            if(_.size(id) > 0) {
                return id
            }
        }
        return null
    }), ids=> ids != null)
 
    _.map(targetsIds, id=> {
        // 与会对象
        let _idKey = `memo_option_list_attend_target_${id}`
        // 参会人
        let _userKey = `memo_option_list_attend_target_user_${id}`

        targets.push({
            targetIds: id,
            //与会者编号
            attendeeId: _.get(_.head(_.get(data, `${_idKey}.targets`, [])), 'value'), 
            //与会者名字            
            attendeeName: _.get(_.head(_.get(data, `${_idKey}.targets`, [])), 'label'),  
            //与会者类型    
            identityId: _.get(_.head(_.get(data, `${_idKey}.targetTypes`, [])), 'value'), 
            //与会者类型          
            identityName: _.get(_.head(_.get(data, `${_idKey}.targetTypes`, [])), 'label'),         
            //与会方参会人
            attendeeUsers: _.get(_.head(_.get(data, `${_userKey}.value`, [])), 'label'),  
            //更多数据
            others: [],
        })
    })
    //进行拆箱装箱
    targets = dataToUpdateContent(targets, data)

    let meeting = {
        //会议时间
        meetingDate: _.get(baseInfo, 'memoDate', '') != '' ? moment(_.get(baseInfo, 'memoDate', '')).format('YYYY-MM-DD'): '', 
        //会议性质 
        meetingNature: _.join(_.map(_.get(baseInfo, 'memoCategory', []), 'label'), '&'),  
        //业务机会类型        
        meetingBusinessType: _.join(_.map(_.get(baseInfo, 'memoBussinessCategory', []), 'label'), '&'),  
        //是否有交易
        isThereTransaction:  _.join(_.map(_.get(baseInfo, 'memoTradeChoice', []), 'label'), '&'), 
        //是否是公开会议 
        meetingIsOpen: _.join(_.get(baseInfo, 'memoIsPublic', []), '，'),  
        //对会议可见
        memoAuthUsersGroups: _.join(_.map(_.get(baseInfo, 'memoAuthUsersGroups', []), 'label'), '，'),
        //对会议可见指定人
        memoAuthUsers: _.join(_.map(_.get(baseInfo, 'memoAuthUsers', []), 'label'), '，'), 
        //会议消息推送类型        
        meetingMessagePushType: _.join(_.map(_.get(baseInfo, 'memoIsPush', []), 'label'), '&'), 
        //会议消息推送到人 memoPushUsers
        meetingMessagePushToUsers:  _.join(_.map(_.get(baseInfo, 'memoPushUsers', []), 'label'), ','),
        //我方参会人 
        meetingYiKaiUsers: _.join(_.map(baseInfoUsers, 'label'), ','),   
        //会议内容 
        meetingContent: baseInfoContent,
        //会议音频文件
        meetingAudioFiles: baseInfoAudios ? _.isArray(baseInfoAudios) ? _.map(baseInfoAudios, audio=> ({
            label: audio.text,
            value: audio.url,
            content: audio.text,
        })): [{
            label: baseInfoAudios.text,
            value: baseInfoAudios.url,
            content: baseInfoAudios.text,
        }]: [],
        //与会方
        meetingAttendees: targets,
        //

    }

    return meeting
}

export function fetchMeetingList(pageNum = 1, pageSize = 30, filter = {}){
    return new Promise((resolve, reject)=> {
        request('POST', '/cec-sims-server/smartx/meeting/list', {pageNum, pageSize, ...filter}).then(res=> {
            resolve(_.map(res, data=> meetingFormat(data) ))
        }).catch(e=> reject(e))


        // setTimeout(()=> resolve(_.map(MEETING_LIST, data=> meetingFormat(data) )), 1000)
    })
}

export function fetchEnterpriseList(pageNum= 1, pageSize = 30, filter = {}) {
    return new Promise((resolve, reject)=> {
        request('POST', '/cec-sims-server/smartx/com/list', {pageNum, pageSize, ...filter}).then(res=> {
            const list = _.map(res, array=> {
                let item = _.head(array)
                return {
                    id: _.get(item, 'code_com', null),
                    type: _.get(item, 'type_com', null),
                    nameLabel: _.get(item, 'label_com', null),
                    typeLabel: dataToType(_.get(item, 'data_type_label', null)),
                    avatar: _.get(item, 'icon_com', ''),
                    updateDatas: _.get(item, 'data_com', []),
                }
            })
            // console.log("fetchEnterpriseList====>", list)
            resolve( list )
        }).catch(e=> reject(e))


        // setTimeout(()=> resolve(_.map(MEETING_LIST, data=> meetingFormat(data) )), 1000)
    })
}

export function dataToType(keyType) {
    let result = ""
    switch(keyType){
        case "企业（产业方）":
            result = "公司"
            break
        case "政府":
            result = "LP"
            break
        default:
            result = "GP/LP"
            break
    }
    return result
}