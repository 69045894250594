import React, { Fragment, createRef } from "react";
import { List, Button, Input, TextArea, Ellipsis, Toast, Space } from "antd-mobile";
import BaseExtractValuePopup from "../../BaseExtractValuePopup";
import CECCalendarPopup from "../../../../../../components/CalendarPopup";
import CECSelectorListItem from "../../../../../../components/SelectorListItem";
import CECSelectIndustryPopup from "../../../../../../components/SelectIndustryPopup";
import DeleteAndSaveButton from "../../../../../../components/CompositeButton";
import AttendTargetPopup from "../../AttendTargetPopup";
import _ from 'lodash';
import moment from "moment";
import {
  FA_SUPPORT_OPTIONS,
  FA_SUPPORT_OPTIONS_TRUE,
  INCOME_OPTIONS,
  PROFIT_OPTIONS,
  OTHER_RELATION_OPTIONS,
  OTHER_RELATION_OPTIONS_TRUE,
  ConvertIndustryToLabels,
  FetchElementList,
  FetchElementLevelList,
  SaveInfo,
  DeleteInfo
} from './transform'

/**
 * 是否有并购整合需求
 */
export default class MergerAndAcquisitionNeedsPopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props)

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.state = {
      _info: info,
      _ecIndustryOptions: [],
      // 重要因素
      _importantOptions: [],
      // 当前状态
      _currentStatusOptions: [],
      // 目标地理市场
      _areaOptions: [],
    }
    this.rangeRef = createRef()
    this.industryRef = createRef()
    this.locationRef = createRef()
    this.relationRef = createRef()
    this.dateRef = createRef()
    this.considerationsRef = createRef()
    this.currentStatus = createRef()
    this.ecIndustrySelectPopupRef = createRef()
    this.faTargetPopupRef = createRef()
  }
  componentDidMount() {
    this.fetchCompanyIndustryList()
    this.fetchMarketAreaList()
    this.fetchImportantList()
    this.fetchCurrentStatusList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  async fetchMarketAreaList() {
    try {
      const options = await FetchElementList("Lable_smartx_location")
      this.setState({ _areaOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchImportantList() {
    try {
      const options = await FetchElementList("Lable_smartx_consider")
      this.setState({ _importantOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchCurrentStatusList() {
    try {
      const options = await FetchElementList("Lable_smartx_now_attitude")
      this.setState({ _currentStatusOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchCompanyIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _areaOptions,
      _importantOptions,
      _ecIndustryOptions,
      _currentStatusOptions,
    } = this.state

    return (
      <Fragment>
        <List>
          <List.Item extra={<Ellipsis content={_info['industry'] ? ConvertIndustryToLabels(_info['industry']) : "请选择"} />} onClick={() => this.ecIndustrySelectPopupRef?.current?.show()}>标的公司所处行业</List.Item>

          <CECSelectorListItem label='目标公司地理市场'
            options={_areaOptions} 
            multiple={true}
            value={_info['marketArea'] ? _.map(_info['marketArea'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'marketArea', options)}
          />

          <CECSelectorListItem label='是否必须有收入（收入水平等选项）'
            columns={2}
            options={INCOME_OPTIONS}
            value={_info['income'] ? _.map(_info['income'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'income', options)}
          />

          <CECSelectorListItem label='是否必须有利润（利润水平等选项）'
            columns={2}
            options={PROFIT_OPTIONS}
            value={_info['profit'] ? _.map(_info['profit'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'profit', options)}
          />

          <List.Item extra={<Space><Input placeholder="请输入" value={_info['ratio'] ? _info['ratio'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'ratio')} /><span>%</span></Space>}>目标持股比例</List.Item>

          <List.Item extra={_info['beginDate'] ? moment(_info['beginDate']).format('YYYY/MM') : "请选择"} onClick={() => this.dateRef?.current?.toggle()}>目标启动时间</List.Item>

          <CECSelectorListItem label='是否已有FA'
            columns={2}
            options={FA_SUPPORT_OPTIONS}
            value={_info['fa'] ? _.map(_info['fa'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'fa', options)}
          />
          {
            _info['fa'] && _.map(_info['fa'], 'value').includes(FA_SUPPORT_OPTIONS_TRUE) && (
              <List.Item extra={<Ellipsis content={_info['FAList'] ? _.join(_.map(_info['FAList'], "label")) : "请选择"} />} onClick={() => this.faTargetPopupRef.current.toggle()}>FA</List.Item>
            )
          }

          <CECSelectorListItem label='重要考量因素'
            columns={2}
            multiple={true}
            options={_importantOptions}
            value={_info['important'] ? _.map(_info['important'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'important', options)}
          />

          <CECSelectorListItem label='当前状态'
            options={_currentStatusOptions}
            value={_info['currentStatus'] ? _.map(_info['currentStatus'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'currentStatus', options)}
          />

          <CECSelectorListItem label="其它相关需求"
            columns={2}
            options={OTHER_RELATION_OPTIONS}
            value={_info['other'] ? _.map(_info['other'], 'value') : null}
            onChange={(val, options) => this.handleChange('change_value', 'other', options)}
          />
          {
            _.map(_info['other'], 'value').includes(OTHER_RELATION_OPTIONS_TRUE) && <List.Item>
              <TextArea rows={5} value={_info['otherText']} onChange={this.handleChange.bind(this, 'change_value', 'otherText')} placeholder="请补充其它相关需求" />
            </List.Item>
          }
        </List>

        <CECCalendarPopup
          ref={this.dateRef}
          precision="month"
          metadata={{ title: "目标启动时间" }}
          value={_info['beginDate'] ? _info['beginDate'] : moment().toDate()}
          onConfirm={v => this.handleChange('change_value', 'beginDate', v)}
        />

        <CECSelectIndustryPopup
          ref={this.ecIndustrySelectPopupRef}
          title="标的公司所处行业"
          multiple={true}
          values={_info['industry']}
          options={_ecIndustryOptions}
          onConfirm={options => this.handleChange('change_value', 'industry', options)}
        />

        <AttendTargetPopup ref={this.faTargetPopupRef}
          mode={"target"}
          targetCategorys={['investment']}
          targetMultiple={true}
          metadata={{ title: 'FAList' }}
          value={{ targets: _info['FAList'] }}
          onConfirm={(data) => this.handleChange('change_value', 'FAList', data['targets'])}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state
    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}