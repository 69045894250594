import React, { createRef } from 'react';
import _ from "lodash";
import moment from 'moment';
import { List, Image, Space, Tag, Popover, DatePicker, Toast, Ellipsis, Divider, } from "antd-mobile";
import { ClockCircleOutline } from 'antd-mobile-icons';
import BaseExtractPopup from "../../../../components/BaseExtractPopup";
import CECInfiniteScroll from "../../../../components/InfiniteScroll";
import {
    TSearchView,
} from '../../../../components/FormsUtils'
import {
    SearchMeetingDataList
} from './transform';
import {

} from './configuration';
import LOCK from '../../../../assets/mobile/meeting/lock.png';
import UNLOCK from '../../../../assets/mobile/meeting/unlock.png';
import './index.less';

/** */
export default class SearchMeetingPage extends BaseExtractPopup {
    constructor(props) {
        super(props)
        this.overflow = true;
        this.state = {
            _hasMore: true,
            _loading: false,
            _pageNum: 0,
            _pageSize: 30,
            _dataList: [],
            _total: 0,
            _filter: {
                aboutme: false,
                searchtype: 2,
            },

        }
    }
    getDestroyOnClose() { return true }

    renderNavRight() {
        const { _filter, _pageSize } = this.state
        return (
            <Popover.Menu
                className='memo-query-page-popover-menu'
                actions={[
                    {key: "All", text: "全部"},
                    {key: "PushNotifications", text: "推送给我"},
                    {key: "AboutMe", text: "与我相关"},
                ]}
                placement='bottom-start'
                onAction={node => {
                    switch(node.key) {
                        case 'All':
                            this.setState({_filter: {searchtype: 2, aboutme: false}}, ()=> this.fetchDataList())
                            break
                        case 'PushNotifications':
                            this.setState({_filter: {searchtype: 3, aboutme: false}}, ()=> this.fetchDataList())
                            break
                        case 'AboutMe':
                            this.setState({_filter: {searchtype: 2, aboutme: true}}, ()=> this.fetchDataList())
                            break
                    }
                }}
                trigger='click'
            >
                <div style={{color: "#1890ff"}}>{function(_val){
                    if(_val.aboutme == true) {
                        return "与我相关"
                    }else if(_val.searchtype == 2){
                        return "全部"
                    }else if(_val.searchtype == 3){
                        return "推送给我"
                    }else {
                        return ""
                    }
                }(_filter)}</div>
            </Popover.Menu>
        )
    }

    fetchDataList(pageNum = 1, pageSize = 30) {
        const { _loading, _dataList, _filter } = this.state
        if(_loading) {
            return
        }
        console.log("fetchDataList===>", pageNum, pageSize, _filter)
        this.setState({_loading: true})
        SearchMeetingDataList({ pageNum: pageNum, pageSize: pageSize, filter: { ... _filter} }).then(response => {
            const { list, total } = response
            console.log("fetchDataList===>", response)
            /**
             * 会议code
             * 业务机会，行业组，会议类型，会议性质，
             * 会议内容
             * 参会企业（参会企业身份）【多个】，
             * 我方参会人，会议时间，
             * 会议查看权限，
             */
            let datas = _.map(list, item => ({
                /**会议code */
                meetingCode: _.get(item, 'base_meeting_code', null),
                /** 会议时间 */
                meetingTime: _.get(item, 'base_meeting_time', null) != null ? moment(_.get(item, 'base_meeting_time', null)).format('YYYY-MM-DD'): '',
                /** 会议性质 */
                meetingNature: '会议性质',
                /** 业务机会类型*/
                meetingBusinessOpportunities: _.get(item, 'base_meeting_type2', null),
                /** 是否有交易机会 */
                meetingTradingOpportunities: '是否有交易机会',
                /** 是否公开 */
                meetingAuth: _.get(item, 'base_meeting_auth', null) == "PUBLIC" ? true : false,
                /** 会议可见人或组 */
                meetingUserOrGroup: _.get(item, 'base_meeting_auth', null) != "PUBLIC" ? "会议可见人或组" : "",
                /** 非公开理由 */
                meetingNonPublicReasons: _.get(item, 'base_meeting_auth', null) != "PUBLIC" ? "非公开理由" : "",
                /** 非公开理由-设计上市公司 {上市公司名称，上市公司股票代码，上市公司上市地}*/
                meetingNonPublicReasonsData: [],
                /** 是否推送 */
                meetingIsPushNotifications: '是否推送',
                /** 推送人 */
                meetingPushNotificationsUser: '推送人',
                /** 会议内容 */
                meetingContent: _.get(item, 'base_meeting_content', ''),
                /** 参会企业（参会企业身份）【多个】 */
                meetingParticipatingCompanies: [{
                    enterpriseCode: "",
                    enterpriseName: _.get(item, 'comorinv', ''),
                    enterpriseIdentity: "",
                    enterpriseAttendee: "",
                }],
                /**我方参会人 */
                meetingCECAttendee: _.get(item, 'ykuser', ''),
                /**会议查看权限 */
                meetingPermission: _.get(item, 'cat_flag', null),
                /**行业组 */
                meetingIndustryGroup: _.get(item, 'groupid', ''),

                /**移除？ 会议类型 */
                meetingType: '会议类型',
            }))


            if (pageNum > 1) {
                datas = _.concat(_dataList, datas)
            }

            this.setState({
                _loading: false,
                _total: total,
                _dataList: datas,
                _pageNum: pageNum,
                _pageSize: pageSize,
                _hasMore: _.size(datas) >= total ? false : true
            })
        }).catch(msg => this.setState({ _loading: false }, () => Toast.show({ content: msg })))
    }

    renderBodyContent() {
        const { _filter, _hasMore, _dataList, _total, _pageNum, _pageSize } = this.state
        return (
            <div className="memo-query-page">
                <div className="memo-query-page-search-filter">
                    <SearchView
                        value={_filter}
                        onChange={val => {

                        }}
                    />
                    <div className='memo-query-page-search-filter-total'>{`共${_total}条`}</div>
                </div>
                <div className="memo-query-page-search-list-warp">
                    {_.map(_dataList, meeting=> <MeetingItem data={meeting} onClick={undefined}/> )}
                    <CECInfiniteScroll hasMore={_hasMore} onLoadMore={()=> this.fetchDataList(_pageNum + 1, _pageSize, _filter)} />
                </div>
            </div>
        )
    }
}

/**搜索组件 */
class SearchView extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            _filter: {}
        }
        this.dropdownOptions = [
            { label: "会议时间", value: "base", filterKey: ['identity', 'type', 'assets', 'area', 'currency', 'money'] },
            { label: "关键字", value: "investment", filterKey: ['investmentVehicle', 'investmentMethod', 'assetCategory', 'investmentTargetAttributes', 'targetTndustriesAndTracks', 'targetGeographicMarket', 'currentState', 'otherRelatedRequirements'] },
            { label: "行业", value: "base2", filterKey: ['identity', 'type', 'assets', 'area', 'currency', 'money'] },
            { label: "更多筛选", value: "investment2", filterKey: ['investmentVehicle', 'investmentMethod', 'assetCategory', 'investmentTargetAttributes', 'targetTndustriesAndTracks', 'targetGeographicMarket', 'currentState', 'otherRelatedRequirements'] },
        ]
        this.tSearchViewRef = createRef()
    }

    handleClearClick(key) {

    }

    fetchDataList() {

    }

    render() {
        const { _filter } = this.state
        return (
            <div>
                <TSearchView
                    dropdownClassName="memo-query-page-search-dropdown"
                    ref={this.tSearchViewRef}
                    //dropdown.Item 数据 {label: '', value: '', filterKey: []} 
                    dropdownOptions={this.dropdownOptions}
                    //选中数据集
                    filter={_filter}
                    //重置按钮 key 对用dropdownOptions的value
                    onReSetting={key => {
                        this.handleClearClick(key)
                    }}
                    //确定按钮key 对用dropdownOptions的value
                    onComfirm={key => {
                        this.handleClearClick('close')
                    }}
                    //val == null 的时候 dorpdown 关闭，查询数据
                    onDropdownChange={val => { if (!val) { this.fetchDataList() } }}
                    //数据总数
                    total={-1}
                    //filterTargetOptions，onFilterTargetItemClearClick，onFilterTargetAllClearClick可以不设置
                    //选中搜索条件集合
                    filterTargetOptions={[]}
                    //清楚选中搜索条件 option 是filterTargetOptions的item数据 key是filter的属性 value就是要清除的数值
                    onFilterTargetItemClearClick={option => {
                        this.handleClearClick(option['key'], option['value'])
                    }}
                    //清楚全部搜索条件
                    onFilterTargetAllClearClick={() => {
                        this.handleClearClick('all')
                    }}
                >
                    {/* key 对用dropdownOptions的value */}
                    {(key) => this.renderSearchMenu(key)}
                </TSearchView>
            </div>
        )
    }

    renderSearchMenu() {
        return <div></div>
    }
}

/**会议列表 */
function MeetingItem({data, onClick}) {
    let meetingCECAttendeeArray =  _.split(_.get(data, 'meetingCECAttendee', ''), ',')
    let meetingCECAttendee = _.size(meetingCECAttendeeArray) > 1 ? `${_.head(meetingCECAttendeeArray)}等${_.size(meetingCECAttendeeArray) - 1}人`: _.head(meetingCECAttendeeArray)
    return (
        <div onClick={()=> {
            if(_.get(data, 'meetingAuth', true) == false && _.get(data, 'meetingPermission', false) == false) {
                Toast.show({content: _.get(data, 'meetingNonPublicReasons', '')})
            }else {
                onClick && onClick(data)
            }
        }} key={`MeetingItem-${Math.random()}`} className='memo-query-page-search-list-warp-meeting-item'>
            <div className='meeting-item'>
                {/* 参会企业 */}
                <div className='meeting-item-row' style={{marginBottom: 4}}>
                    <div className='meeting-item-row warp' style={{fontSize: 14, flex: 1}}>
                        {_.join(_.map(_.get(data, 'meetingParticipatingCompanies', []), enterprise=> enterprise['enterpriseName']), '，')}
                    </div>
                    <div className='meeting-group'>{_.get(data, 'meetingIndustryGroup', '')}</div>
                </div>
                
                {/* 会议属性 */}
                <div className='meeting-item-row between'>
                    <div className='meeting-item-row center'>
                        <div style={{color: "#1890ff"}}>
                            {_.get(data, 'meetingNature', '')}
                        </div>
                        <Divider direction='vertical' style={{margin: "3px 8px"}}/>
                        <div style={{color: "#1890ff"}}>
                            {_.get(data, 'meetingBusinessOpportunities', '')}
                        </div>
                        <Divider direction='vertical' style={{margin: "3px 8px"}}/>
                        <div style={{color: "#1890ff"}}>
                            {_.get(data, 'meetingTradingOpportunities', '')}
                        </div>
                    </div>
                    
                    
                </div>
                {/* 会议内容 */}
                <div className='meeting-item-row meeting-content' style={{margin: '6px 0'}}>
                    {/* 非公开，有权限 */}
                    {_.get(data, 'meetingAuth', true) == false && _.get(data, 'meetingPermission', false) == true && 
                        <div className='meeting-item-row center'>
                            <Image src={UNLOCK} style={{ width: 17.5, height: 17.5, marginRight: 8 }} /><Ellipsis content={_.get(data, 'meetingContent', '')}/>
                        </div>
                    }
                    {/* 非公开，没权限 */}
                    {_.get(data, 'meetingAuth', true) == false && _.get(data, 'meetingPermission', false) == false && 
                        <div className='meeting-item-row center'>
                            <Image src={LOCK} style={{ width: 17.5, height: 17.5, marginRight: 8 }} />{_.get(data, 'meetingNonPublicReasons', '')}
                        </div>
                    }
                    {/* 公开 */}
                    {_.get(data, 'meetingAuth', true) == true && <Ellipsis content={_.get(data, 'meetingContent', '')}/>} 
                </div>
                {/* 易凯参会人和会议时间 */}
                <div className='meeting-item-row center'>
                    <div style={{color: "#999"}}>
                        {meetingCECAttendee}
                    </div>
                    <Divider direction='vertical' style={{margin: "3px 8px"}}/>
                    <div style={{color: "#999"}}>
                        {_.get(data, 'meetingTime', '')}
                        {_.get(data, 'meetingTime', null) != null && dateDiff(_.get(data, 'meetingTime', '')) && <Week6Tag />}
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}

const dateDiff = function (sDate1) {
    let iDays = parseInt(Math.abs(new Date(sDate1) - new Date()) / 1000 / 60 / 60 / 24) //把相差的毫秒数转换为天数 
    if (iDays <= 7 * 6) {
        return true
    } else {
        return false
    }
}

export class Week6Tag extends React.Component {
    render() {
        return (
            <Tag style={{ '--background-color': '#fafafa', '--border-color': '#d9d9d9', '--text-color': 'rgba(0, 0, 0, 0.85)', marginLeft: 8 }}>
                <ClockCircleOutline style={{ color: "rgba(0, 0, 0, 0.85)" }} /> 6 WKS
            </Tag>
        )
    }
}
