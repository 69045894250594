import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../components/AmountNumber";


// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]
// 单笔投资金额
export const SINGLE_INVESTMENT_RANGE_OPTIONS = [
  { label: '下限', value: '下限' },
  { label: '舒适区', value: '舒适区' },
  { label: '上限', value: '上限' },
]

/**
 * 转换行业为Label
 * @param {*} datas 
 * @returns 
 */
export const ConvertIndustryToLabels = (datas) => {
  let selectedIndustrys = ""
  if (datas) {
    selectedIndustrys = _.join(_.map(datas, item => {
      let industry = item['label']
      let childrenLabel = ""
      if (item['children'] && _.size(item['children']) > 0) {
        childrenLabel = _.join(_.map(item['children'], "label"), "、")
      }
      if (childrenLabel) {
        industry += `（${childrenLabel}）`
      }
      return industry
    }), "，")
  }
  return selectedIndustrys
}

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type']
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取地区
 * @param {*} pid 
 * @param {*} datas 
 * @returns 
 */
export async function FetchRegion(pid = 0, datas = []) {
  try {
    const response = await request("POST", "/cec-sso-server/board/region", { pid: pid, })

    if (response) {
      var array = []
      response.find(item => {
        array.push({
          label: item['name'],
          value: item['id'],
        })
      })
      return array
    }
    return []
  } catch (error) {
    throw error
  }
}

/**
 * 保存 政府更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  // 总管理规模
  let overallTargetScaleCurrency = info['overallTargetScaleCurrencyOther']
  if (!overallTargetScaleCurrency) {
    overallTargetScaleCurrency = info['overallTargetScaleCurrency'] ? _.join(_.map(info['overallTargetScaleCurrency'], "value")) : null
  }
  let overallTargetScaleUnit = info['overallTargetScaleUnit'] ? _.join(_.map(info['overallTargetScaleUnit'], "value")) : null
  let overallTargetScaleValue = parseAmountNumber(info['overallTargetScale'], overallTargetScaleUnit)

  // 已到位规模
  let establishedScaleCurrency = info['establishedScaleCurrencyOther']
  if (!establishedScaleCurrency) {
    establishedScaleCurrency = info['establishedScaleCurrency'] ? _.join(_.map(info['establishedScaleCurrency'], "value")) : null
  }
  let establishedScaleUnit = info['establishedScaleUnit'] ? _.join(_.map(info['establishedScaleUnit'], "value")) : null
  let establishedScaleValue = parseAmountNumber(info['establishedScale'], establishedScaleUnit)

  // 单笔投资规模
  let singleStrokeMoneyCurrency = info['singleStrokeMoneyCurrencyOther']
  if (!singleStrokeMoneyCurrency) {
    singleStrokeMoneyCurrency = info['singleStrokeMoneyCurrency'] ? _.join(_.map(info['singleStrokeMoneyCurrency'], "value")) : null
  }
  let singleStrokeUnit = info['singleStrokeUnit'] ? _.join(_.map(info['singleStrokeUnit'], "value")) : null
  let singleStrokeBeginMoneyValue = parseAmountNumber(info['singleStrokeBeginMoney'], singleStrokeUnit)
  let singleStrokeEndMoneyValue = parseAmountNumber(info['singleStrokeEndMoney'], singleStrokeUnit)

  // 行业、易凯合作领域
  let industrys = []
  if (info['industry']) {
    info['industry'].find(item => {
      industrys.push({
        sys_labelelement_code: item['value'],
        sys_labelelement_type: item['type'],
      })
      if (item['children']) {
        item['children'].find(subItem => {
          industrys.push({
            sys_labelelement_code: subItem['value'],
            sys_labelelement_type: subItem['type'],
          })
        })
      }
    })
  }
  let ecapitalCooperations = []
  if (info['ecapitalCooperation']) {
    info['ecapitalCooperation'].find(item => {
      ecapitalCooperations.push({
        sys_labelelement_code: item['value'],
        sys_labelelement_type: item['type'],
      })
    })
  }
  let array = _.concat(industrys, ecapitalCooperations)
  
  let payload = {
    id: info['id'],
    base_lp_code: info['code'],
    location: _.join(_.map(info['areas'], 'label')),
    rele_code: _.join(_.map(info['mainContributions'], 'value')),
    rele_name: _.join(_.map(info['mainContributions'], 'label')),
    money_sources: info['mainSourceOfFunds'],
    managecurrency: overallTargetScaleCurrency,
    manageunit: overallTargetScaleUnit,
    managevalue: overallTargetScaleValue,
    managevalue2: _.join(_.map(info['overallTargetScaleOption'], "label")),
    inplacecurrency: establishedScaleCurrency,
    inplaceunit: establishedScaleUnit,
    inplacevalue: establishedScaleValue,
    inplacevalue2: _.join(_.map(info['establishedScaleOption'], "label")),
    singlecurrency: singleStrokeMoneyCurrency,
    singleunit: singleStrokeUnit,
    singlevalue1: singleStrokeBeginMoneyValue,
    singlevalue2: singleStrokeEndMoneyValue,
    investstage: info['investmentDirection'] ? _.join(_.map(info['investmentDirection'], "label")) : null,
    interest: info['specialPreferences'],
    note: info['specificRequirementText'],
    policy: info['favouredPolicy'],
    note2: info['ecapitalCooperationText'],
    data_labels: JSON.stringify(array),
    showflag: info['id'] ? true : false,
  }
  
  try {
    const response = await request("POST", "/cec-sims-server/smartx/lp/base/gov/save", payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 政府历史更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/smartx/lp/base/gov/delete", { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}