
import { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { Tag, Space } from 'antd-mobile'
import CloseableTag, { AddedTag } from '../../../../../../components/CloseableTag'
import './index.less';

/**
 * 关键信息 - 基本信息Item
 * @param {*} param
 * @returns 
 */
export default function InfoDetailBaseItem({ label, renderComponent, notData }) {

  return (
    <div className="info-detail-base-item">
      <div className="base-item-label">{label}</div>
      <div className="base-item-component">
        {
          renderComponent && renderComponent() ? renderComponent() : (notData ? notData() : <span className='base-item-empty'>暂无</span>)
        }
      </div>
    </div>
  )
}


/**
 * 关键信息 - 基本信息Item - Tag字段展示
 */
export function InfoDetailBaseItemTagComponent({ datas = [] }) {

  return (
    <Fragment>
      {
        datas && (
          <Space block wrap>
            {
              datas.map(item => <Tag style={{ '--background-color': '#e6fffb', '--border-color': '#87e8de', '--text-color': '#08979c' }}>{item['label']}</Tag>)
            }
          </Space>
        )
      }
    </Fragment>
  )
}


/**
 * 关键信息 - 基本信息Item - Tag字段添加/删除
 */
export function InfoDetailBaseItemCloseadbleTagComponent({ datas = [], onDelete, onAdd }) {

  return (
    <Fragment>
      {
        datas && (
          <Space block wrap>
            {
              datas.map(item => <CloseableTag label={item['label']} onClick={() => onDelete && onDelete(item)} />)
            }
            <AddedTag label="新增标签" onClick={() => onAdd && onAdd()} />
          </Space>
        )
      }
    </Fragment>
  )
}

/**
 * 关键信息 - 基本信息Item - 人员字段展示
 */
export function InfoDetailBaseItemUserComponent({ datas = [] }) {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const users = _.map(datas, item => item['label'])
    setUsers(users)
  }, [datas])

  return (
    <span>{users} </span>
  )
}

/**
 * 关键信息 - 基本信息Item - 行业字段展示（含父子行业）
 */
export function InfoDetailBaseItemIndustryComponent({ datas = [] }) {

  const [industrys, setIndustrys] = useState([]);

  useEffect(() => {
    const industrys = _.map(datas, item => {
      let label = item['label']
      if (item['children'] && _.size(item['children']) > 0) {
        label = `${label}（${_.join(_.map(item['children'], 'label'), "，")}）`
      }
      return label
    })
    setIndustrys(industrys)
  }, [datas])

  return (
    <span>{industrys} </span>
  )
}

/**
 * 关键信息 - 基本信息Item - 行业/阶段（评分）字段展示（含父子行业）
 */
export function InfoDetailBaseItemIndustryStageScoreComponent({ datas = [] }) {
  
  const [industrys, setIndustrys] = useState([]);

  useEffect(() => {
    const industrys = _.map(datas, (item, index) => {
      const industryItem = _.head(_.get(item, "industrys", []))

      let label = industryItem['label']
      if (industryItem['children'] && _.size(industryItem['children']) > 0) {
        label = `${label}【${_.join(_.map(industryItem['children'], 'label'), "，")}】`
      }
      if (item['stages'] && _.size(item['stages']) > 0) {
        label += `/${_.join(_.map(item['stages'], 'label'), "、")}`
      }
      if (item['score'] !== undefined) {
        label += ` (${item['score']})`
      }
      return index !== _.size(datas) - 1 ? `${label}，` : label
    })
    setIndustrys(industrys)
  }, [datas])

  return (
    <span>{industrys} </span>
  )
}

/**
 * 关键信息 - 基本信息Item - 金额展示
 * @returns 
 */
export function InfoDetailBaseItemAmountComponent({ data = {} }) {
  const { amountValue, amountUnitValue, amountUnitLabel, amountCurrency } = data
  return (
    <span>{amountValue}{amountUnitLabel}{amountCurrency}</span>
  )
}