import _ from "lodash";
import moment from "moment";
import request from "../../../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../../../components/AmountNumber";


// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]
// 项目范围
export const DEAL_RANGE_OPTIONS = [
  { value: 'ALL', label: '全部项目' },
  { value: 'PART', label: '部分项目' }
]


/**
 * 保存是否有投退需求 - 基金投退
 * @param {*} info 
 */
export async function SaveInfo(info) {

  if (!info['name']) {
    throw '请输入基金名称'
  }

  let industryList = []
  if (info['industry']) {
    info['industry'].find(item => {
      industryList.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })

      if (item['children'] && _.size(item['children']) > 0) {
        item['children'].find(childrenItem => {
          industryList.push({ sys_labelelement_code: childrenItem['value'], sys_labelelement_type: childrenItem['type'] })
        })
      }
    })
  }

  let costCurrency = info['costCurrencyOther']
  if (!costCurrency) {
    costCurrency = info['costCurrency'] ? _.join(_.map(info['costCurrency'], "value")) : null
  }
  let costUnit = info['costUnit'] ? _.join(_.map(info['costUnit'], "value")) : null
  let costAmount = parseAmountNumber(info['costAmount'], costUnit)

  let valueCurrency = info['valueCurrencyOther']
  if (!costCurrency) {
    valueCurrency = info['valueCurrency'] ? _.join(_.map(info['valueCurrency'], "value")) : null
  }
  let valueUnit = info['valueUnit'] ? _.join(_.map(info['valueUnit'], "value")) : null
  let valueAmount = parseAmountNumber(info['valueAmount'], costUnit)

  let gpOwners = []
  if (info['gpOwners']) {
    info['gpOwners'].find(item => {
      gpOwners.push({ base_investment_code: item['value'], base_investment_name: item['label'] })
    })
  }

  let payload = {}
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/smartx/comp/trade/chance/save"
    payload = {
      id: info['id'],
      base_comp_code: info['code'],
      type: info['type'],
      comp_name2: info['name'],
      tradepercent: info['ratio'],
      tradedate: info['date'] ? info['date'].toString() : null,
      tradecurrency: costCurrency,
      tradeunit: costUnit,
      tradevalue: costAmount,
      newstockcurrency: valueCurrency,
      newstockunit: valueUnit,
      newstockvalue1: valueAmount,
      investment_datas: JSON.stringify(gpOwners),
      part_all: info['dealRange'] ? _.join(_.map(info['dealRange'], "value")) : null,
      showflag: info['id'] ? true : false,
    }
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/save"
    payload = {
      id: info['id'],
      base_comp_code: info['code'],
      type: info['type'],
      comp_name: info['name'],
      tradepercent: info['ratio'],
      tradedate: info['date'] ? info['date'].toString() : null,
      tradecurrency: costCurrency,
      tradeunit: costUnit,
      tradevalue: costAmount,
      oldstockcurrency: valueCurrency,
      oldstockunit: valueUnit,
      oldstockvalue1: valueAmount,
      investment_datas: JSON.stringify(gpOwners),
      part_all: info['dealRange'] ? _.join(_.map(info['dealRange'], "value")) : null,
      showflag: info['id'] ? true : false,
    }
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/trade/family/chance/save"
    payload = {
      id: info['id'],
      base_lp_code: info['code'],
      type: info['type'],
      comp_name: info['name'],
      tradepercent: info['ratio'],
      tradedate: info['date'] ? info['date'].toString() : null,
      tradecurrency: costCurrency,
      tradeunit: costUnit,
      tradevalue: costAmount,
      oldstockcurrency: valueCurrency,
      oldstockunit: valueUnit,
      oldstockvalue1: valueAmount,
      investment_datas: JSON.stringify(gpOwners),
      part_all: info['dealRange'] ? _.join(_.map(info['dealRange'], "value")) : null,
      showflag: info['id'] ? true : false,
    }
  }
  try {
    const response = await request("POST", url, payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}
/**
 * 删除是否有投退需求 - 基金投退
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/smartx/comp/trade/chance/delete"
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/delete"
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/trade/family/chance/delete"
  }
  try {
    await request("POST", url, { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}