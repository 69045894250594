import React, { createRef, Fragment } from "react";
import _ from 'lodash';
import moment from 'moment';
import Layout from '../../../../../../components/Layout';
import BaseExtractPopup from "../../../../../../components/BaseExtractPopup";
import CECCalendarPopup from "../../../../../../components/CalendarPopup";
import CECSelectPopup from "../../../../../../components/SelectPopup";
import CECTextAreaPopup from "../../../../../../components/TextAreaPopup";
import Required from "../../../../../../components/Required";
import UploadFile from "../../../../../../components/UploadFile";
import CECSelectorListItem from "../../../../../../components/SelectorListItem";
import {
    ListItemExtraPopoverMenu,
    INVESTOR_USERS_OPTIONS, INVESTOR_OPTIONS,
} from "../../../../../../components/FormsUtils";
import { List, Button, Input, Popup, NavBar, Space, Image, Toast } from 'antd-mobile';
import { AddCircleOutline } from 'antd-mobile-icons'
import '../CreateDeal/index.css'

/**
 * 【项目更新：执行信息 - Marketing投资人名单】
 */

export default class CreateMarketInvestorListPopup extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            _dataList: []
        }

        this.popupRef = createRef()
        this.addPopupRef = createRef()
    }

    show() {
        this.popupRef?.current?.show()
    }

    hidden() {
        this.popupRef?.current?.hidden()
    }

    toggle() {
        this.popupRef?.current?.toggle()
    }

    close() {
        this.popupRef?.current?.hidden()
    }

    render() {
        const { _dataList } = this.state
        const { metadata, onConfirm } = this.props

        const rightView = (
            <div style={{ fontSize: 20, color: "#1890ff" }}>
                <Space style={{ '--gap': '16px' }}>
                    <AddCircleOutline onClick={() => this.addPopupRef?.current?.show()} />
                </Space>
            </div>
        )

        return (
            <BasePopup ref={this.popupRef}>
                <Layout
                    head={
                        <div style={_.size(_dataList) > 0 ? {} : { borderBottom: "1px solid #eeeeee" }}>
                            <NavBar onBack={() => this.hidden()} right={rightView}>
                                {_.get(metadata, 'title', '')}
                            </NavBar>
                        </div>
                    }
                    foot={
                        <div style={{ padding: 10 }}>
                            <Button color="primary" block onClick={() => { onConfirm && onConfirm({ investors: _dataList }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
                        </div>
                    }
                >
                    {_.size(_dataList) > 0 && (
                        <List>
                            {
                                _.map(_dataList, (item, i) => {

                                    let name = _.get(_.head(_.get(item, 'invsetor_name', [])), 'label', '测试企业')
                                    let investor = _.join(_.map(_.get(item, 'invsetor_user', []), 'label'), '，')
                                    let cec = _.join(_.map(_.get(item, 'cec_user', []), 'label'), '，')
                                    return (
                                        <List.Item
                                            key={`ITEM-${i}`}
                                            prefix={
                                                <Image
                                                    src=""
                                                    style={{ borderRadius: 20 }}
                                                    fit='cover'
                                                    width={40}
                                                    height={40}
                                                    fallback={<svg t="1724051007943" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2482" width="40" height="40"><path d="M1001.7 969.6H890.4V399.4c0-27.7-17.4-52.8-43.3-62.5L580 236.7c-14.9-5.6-31.1-5.5-45.7-0.4V76.6c0-21.9-10.7-42.4-28.7-54.9s-41-15.3-61.5-7.6L176.9 114.3c-25.9 9.7-43.3 34.9-43.3 62.5v792.8H22.3C10 969.6 0 979.6 0 991.9s10 22.3 22.3 22.3H1001.8c12.3 0 22.3-10 22.3-22.3s-10.1-22.3-22.4-22.3zM178.1 176.8c0-9.2 5.8-17.6 14.4-20.8L459.7 55.8c7-2.6 14.4-1.7 20.5 2.5s9.6 10.9 9.6 18.3v893H178.1V176.8z m356.2 792.8V299.3c0-7.4 3.5-14.1 9.6-18.3 6.1-4.2 13.6-5.2 20.5-2.5l267.1 100.2c8.6 3.2 14.4 11.6 14.4 20.8v570.2H534.3z" p-id="2483"></path><path d="M391.8 346.3H258.2c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3h133.6c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM748 479.9H614.4c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3H748c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM748 613.4H614.4c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3H748c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM391.8 613.4H258.2c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3h133.6c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3z" p-id="2484"></path></svg>}
                                                />
                                            }
                                            description={
                                                <div>
                                                    <div>机构对接人:{investor}</div>
                                                    <div>易凯对接人:{cec}</div>
                                                </div>
                                            }
                                            onClick={() => this.addPopupRef?.current?.show()}
                                        >
                                            <div style={{ fontSize: 16 }}>{name}</div>
                                        </List.Item>
                                    )
                                })
                            }
                        </List>
                    )}



                    <CreateMarketInvestorNameListPopup
                        ref={this.addPopupRef}
                        metadata={{ title: "投资人" }}
                        onConfirm={data => {
                            this.setState({ _dataList: _.concat(_dataList, [data]) })
                        }}
                    />
                </Layout>
            </BasePopup>
        )
    }
}
const FORM_LABE_OPTIONS = [
    { label: "机构名称", key: "invsetor_name", type: 'options' },
    { label: "机构描述", key: "invsetor_des", type: 'options' },
    { label: "机构定位", key: "invsetor_locate", type: 'options' },
    { label: "机构对接人", key: "invsetor_user", type: 'options' },
    { label: "易凯对接人", key: "cec_user", type: 'options' },
    { label: "批次", key: "batch", type: 'options' },
    { label: "导入日期", key: "import_date", type: 'date' },
    { label: "最新更新日期", key: "update_date", type: 'date' },
    { label: "状态", key: "status", type: 'options' },
    { label: "上传TS/LOI", key: "ts_loi_files", type: 'options' },
    { label: "上传SPA", key: "spa_files", type: 'options' },
    { label: "会议安排", key: "meetings", type: 'form' },
    { label: "添加项目进展和备注", key: "project_des", type: 'form' },
]
const FORM_LABEL_MAP = _.chain(FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()


class CreateMarketInvestorNameListPopup extends BaseExtractPopup {
    constructor(props) {
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: true,
        }
        this.options_ref = createRef()
        this.text_area_ref = createRef()
        this.dateRef = createRef()
        this.statusPopupRef = createRef()
        this.meetingPopupRef = createRef()
        this.projectDesPopupRef = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch (key) {
            case "onStateChange":
                this.setState({
                    _info: { ..._info, [data]: event }
                })
                break
            case 'openSelect':
                Toast.show({ content: "DEMO, 临时数据...." })
                let _tempOptions = [], _tempMultiple = true
                switch (data) {
                    case 'invsetor_user':
                    case 'cec_user':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        _tempMultiple = true
                        break
                    case 'invsetor_name':
                        _tempOptions = INVESTOR_OPTIONS
                        _tempMultiple = false
                        break
                    case 'batch':
                        _tempOptions = [
                            { label: "第一批", value: "第一批" },
                            { label: "第二批", value: "第二批" },
                            { label: "第三批", value: "第三批" },
                            { label: "第四批", value: "第四批" },
                        ]
                        _tempMultiple = false
                        break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, () => this.options_ref?.current?.toggle())
                break
            case 'openTextArea':
                Toast.show({ content: "DEMO, 临时数据...." })
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.text_area_ref?.current?.toggle())
                break
            case 'openDate':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.dateRef?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info, _multiple, _onStateChangeKey, _options } = this.state
        return (
            <Fragment>
                <List className="cec-smartx-create-deal-gourp-list" style={{ '--border-top': '1px solid #eee', '--border-bottom': '1px solid #eee' }}>


                    <List.Item
                        key={`invsetor_name`}
                        extra={_info['invsetor_name'] ? _.join(_.map(_info['invsetor_name'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'invsetor_name')}
                    >
                        {FORM_LABEL_MAP['invsetor_name']}<Required />
                    </List.Item>

                    <List.Item
                        key={`invsetor_des`}
                        extra={_info['invsetor_des'] ? _info['invsetor_des'] : '请选择'}
                        onClick={() => this.handleClick('openTextArea', 'invsetor_des')}
                    >
                        {FORM_LABEL_MAP['invsetor_des']}
                    </List.Item>

                    <CECSelectorListItem label={FORM_LABEL_MAP['invsetor_locate']}
                        isRequired={true}
                        options={[
                            { label: "领投", value: "领投" }, { label: "跟投", value: "跟投" }, { label: "领/跟投", value: "领/跟投" }
                        ]}
                        value={_info['invsetor_locate'] ? _.map(_info['invsetor_locate'], 'value') : []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'invsetor_locate', options)}
                    />

                    <List.Item
                        key={`invsetor_user`}
                        extra={_info['invsetor_user'] ? _.join(_.map(_info['invsetor_user'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'invsetor_user')}
                    >
                        {FORM_LABEL_MAP['invsetor_user']}
                    </List.Item>

                    <List.Item
                        key={`cec_user`}
                        extra={_info['cec_user'] ? _.join(_.map(_info['cec_user'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'cec_user')}
                    >
                        {FORM_LABEL_MAP['cec_user']}
                    </List.Item>

                    <List.Item
                        key={`batch`}
                        extra={_info['batch'] ? _.join(_.map(_info['batch'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'batch')}
                    >
                        {FORM_LABEL_MAP['batch']}
                    </List.Item>

                    <List.Item
                        key={`import_date`}
                        extra={_info['import_date'] ? moment(_info['import_date']).format('YYYY-MM-DD') : '请选择'}
                        onClick={() => this.handleClick('openDate', 'import_date')}
                    >
                        {FORM_LABEL_MAP['import_date']}
                    </List.Item>

                    <List.Item
                        key={`update_date`}
                        extra={_info['update_date'] ? moment(_info['update_date']).format('YYYY-MM-DD') : '请选择'}
                        onClick={() => this.handleClick('openDate', 'update_date')}
                    >
                        {FORM_LABEL_MAP['update_date']}
                    </List.Item>

                    <List.Item
                        key={`status`}
                        extra={_info['status'] ?
                            <ListItemExtraPopoverMenu
                                onDelete={() => this.handleClick("onStateChange", 'status', null)}
                                onEdit={() => this.statusPopupRef?.current?.toggle()}
                            />
                            : '请选择'}
                        onClick={() => this.statusPopupRef?.current?.toggle()}
                    >
                        {FORM_LABEL_MAP['status']}
                    </List.Item>

                    <List.Item
                        key={`meetings`}
                        extra={_info['meetings'] ?
                            <ListItemExtraPopoverMenu
                                onDelete={() => this.handleClick("onStateChange", 'meetings', null)}
                                onEdit={() => this.meetingPopupRef?.current?.toggle()}
                            />
                            : '请选择'}
                        onClick={() => this.meetingPopupRef?.current?.toggle()}
                    >
                        {FORM_LABEL_MAP['meetings']}
                    </List.Item>

                    <List.Item
                        key={`project_des`}
                        extra={_info['project_des'] ?
                            <ListItemExtraPopoverMenu
                                onDelete={() => this.handleClick("onStateChange", 'project_des', null)}
                                onEdit={() => this.projectDesPopupRef?.current?.toggle()}
                            />
                            : '请选择'}
                        onClick={() => this.projectDesPopupRef?.current?.toggle()}
                    >
                        {FORM_LABEL_MAP['project_des']}
                    </List.Item>




                    <List.Item
                        key={`ts_loi_files`}
                        description={
                            <UploadFile
                                multiple={false}
                                values={_info['ts_loi_files']}
                                onChange={(values) => {
                                    this.handleClick("onStateChange", 'ts_loi_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                                }}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['ts_loi_files']}<Required />
                    </List.Item>

                    <List.Item
                        key={`spa_files`}
                        description={
                            <UploadFile
                                multiple={false}
                                values={_info['spa_files']}
                                onChange={(values) => {
                                    this.handleClick("onStateChange", 'spa_files', _.map(values, it => ({ ...it, label: it.name, value: it.url })))
                                }}
                            />
                        }
                    >
                        {FORM_LABEL_MAP['spa_files']}<Required />
                    </List.Item>

                </List>
                <CECCalendarPopup ref={this.dateRef}
                    value={moment().toDate()}
                    metadata={{ title: _.get(FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />

                <CECSelectPopup
                    ref={this.options_ref}
                    supportSearch={true}
                    multiple={_multiple}
                    title={_.get(FORM_LABEL_MAP, _onStateChangeKey, '')}
                    options={_options}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />


                <CECTextAreaPopup
                    metadata={{ title: _.get(FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    ref={this.text_area_ref}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, _.get(val, 'value', null))} />

                <StatusPopup
                    ref={this.statusPopupRef}
                    metadata={{ title: FORM_LABEL_MAP['status'] }}
                    onConfirm={val => {
                        let list = _.get(_info, 'status', [])
                        list.push(val)
                        this.handleClick('onStateChange', 'status', list)
                    }}
                />

                <MeetingPopup
                    ref={this.meetingPopupRef}
                    metadata={{ title: FORM_LABEL_MAP['meetings'] }}
                    onConfirm={val => {
                        let list = _.get(_info, 'meetings', [])
                        list.push(val)
                        this.handleClick('onStateChange', 'meetings', list)
                    }}
                />

                <ProjectDesPopup
                    ref={this.projectDesPopupRef}
                    metadata={{ title: FORM_LABEL_MAP['project_des'] }}
                    onConfirm={val => {
                        let list = _.get(_info, 'project_des', [])
                        list.push(val)
                        this.handleClick('onStateChange', 'project_des', list)
                    }}
                />
            </Fragment>
        )
    }

    renderFootContent() {
        const {
            metadata,
            onConfirm
        } = this.props
        const {
            _info
        } = this.state
        return (
            <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
        )
    }
}

const STATUS_FORM_LABE_OPTIONS = [
    { label: "初次联系", key: "key1", type: "date" },
    { label: "发出Teaser", key: "key2", type: "date" },
    { label: "初次见面", key: "key3", type: "date" },
    { label: "后续见面", key: "key4", type: "date" },
    { label: "Pass", key: "key5", type: "date" },
    { label: "NDA状态", key: "key6", type: "date" },
    { label: "发出BP", key: "key7", type: "date" },
    { label: "发出数据包", key: "key8", type: "date" },
    { label: "发出财务模型", key: "key9", type: "date" },
    { label: "管访", key: "key10", type: "date" },
    { label: "TS/LOI状态", key: "key11", type: "date" },
    { label: "TS/LOI签署日期", key: "key12", type: "date" },
    { label: "DD开始日期", key: "key13", type: "date" },
    { label: "SPA状态", key: "key14", type: "date" },
    { label: "SPA签署日期", key: "key15", type: "date" },
    { label: "交割日期", key: "key16", type: "date" },
]
const STATUS_FORM_LABEL_MAP = _.chain(STATUS_FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()


class StatusPopup extends BaseExtractPopup {

    constructor(props) {
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: true,
        }
        this.options_ref = createRef()
        this.text_area_ref = createRef()
        this.dateRef = createRef()
        this.ownershipStructurePopupRef = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch (key) {
            case "onStateChange":
                this.setState({
                    _info: { ..._info, [data]: event }
                })
                break
            case 'openSelect':
                Toast.show({ content: "DEMO, 临时数据...." })
                let _tempOptions = [], _tempMultiple = true
                switch (data) {
                    case 'invsetor_user':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        _tempMultiple = true
                        break
                    case 'cec_user':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        _tempMultiple = true
                        break
                    case 'batch':
                        _tempOptions = [
                            { label: "第一批", value: "第一批" },
                            { label: "第二批", value: "第二批" },
                            { label: "第三批", value: "第三批" },
                            { label: "第四批", value: "第四批" },
                        ]
                        _tempMultiple = false
                        break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, () => this.options_ref?.current?.toggle())
                break
            case 'openTextArea':

                this.setState({
                    _onStateChangeKey: data,
                }, () => this.text_area_ref?.current?.toggle())
                break
            case 'openDate':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.dateRef?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info, _multiple, _onStateChangeKey, _options } = this.state
        return (
            <Fragment>
                <List>
                    {
                        _.map(STATUS_FORM_LABE_OPTIONS, ({ key, label }) => (
                            <List.Item
                                key={key}
                                extra={_info[key] ? moment(_info[key]).format('YYYY-MM-DD') : '请选择'}
                                onClick={() => this.handleClick('openDate', key)}
                            >
                                {label}
                            </List.Item>
                        ))
                    }
                </List>
                <CECCalendarPopup ref={this.dateRef}
                    value={moment().toDate()}
                    metadata={{ title: _.get(STATUS_FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />
            </Fragment>
        )
    }

    renderFootContent() {
        const {
            metadata,
            onConfirm
        } = this.props
        const {
            _info
        } = this.state
        return (
            <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: STATUS_FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
        )
    }
}


const MEETING_FORM_LABE_OPTIONS = [
    { label: "会议时间", key: "meeting_date", type: "date" },
    { label: "会议地点", key: "meeting_address", type: "string" },
    { label: "会议性质", key: "meeting_type", type: "string" },
    { label: "客户参会人", key: "users", type: "options" },
    { label: "易凯参会人", key: "cec_users", type: "options" },
    { label: "会议备注", key: "des", type: "string" },
    { label: "发送邮件通知所有项目成员", key: "isSend", type: "options" },
    { label: "机构名称", key: "invsetor", type: "options" },
    { label: "机构介绍", key: "invsetor_des", type: "string" },
    { label: "过往投资案例", key: "meeting_case", type: "string" },
    { label: "易凯关联项目信息", key: "meeting_msg", type: "string" },
    { label: "对该项目前期反馈", key: "meeting_txt", type: "string" },
    { label: "机构对接人", key: "invsetor_users", type: "options" },
]
const MEETING_FORM_LABEL_MAP = _.chain(MEETING_FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()


class MeetingPopup extends BaseExtractPopup {

    constructor(props) {
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: true,
        }
        this.options_ref = createRef()
        this.text_area_ref = createRef()
        this.dateRef = createRef()
        this.ownershipStructurePopupRef = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch (key) {
            case "onStateChange":
                this.setState({
                    _info: { ..._info, [data]: event }
                })
                break
            case 'openSelect':
                Toast.show({ content: "DEMO, 临时数据...." })
                let _tempOptions = [], _tempMultiple = true
                switch (data) {
                    case 'invsetor_users':
                    case 'users':
                    case 'cec_users':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        _tempMultiple = true
                        break
                    case 'invsetor':
                        _tempOptions = INVESTOR_OPTIONS
                        _tempMultiple = false
                        break
                    case 'batch':
                        _tempOptions = [
                            { label: "第一批", value: "第一批" },
                            { label: "第二批", value: "第二批" },
                            { label: "第三批", value: "第三批" },
                            { label: "第四批", value: "第四批" },
                        ]
                        _tempMultiple = false
                        break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, () => this.options_ref?.current?.toggle())
                break
            case 'openTextArea':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.text_area_ref?.current?.toggle())
                break
            case 'openDate':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.dateRef?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info, _multiple, _onStateChangeKey, _options } = this.state
        return (
            <Fragment>
                <List>

                    <List.Item
                        key={`meeting_date`}
                        extra={_info['meeting_date'] ? moment(_info['meeting_date']).format('YYYY-MM-DD') : '请选择'}
                        onClick={() => this.handleClick('openDate', 'meeting_date')}
                    >
                        {MEETING_FORM_LABEL_MAP['meeting_date']}
                    </List.Item>

                    <List.Item
                        key={`meeting_address`}
                        extra={
                            <Input
                                placeholder="请输入"
                                value={_info['meeting_address']}
                                onChange={val => this.handleClick('onStateChange', 'meeting_address', val)}
                            />
                        }
                    >
                        {MEETING_FORM_LABEL_MAP['meeting_address']}<Required />
                    </List.Item>

                    <List.Item
                        key={`meeting_type`}
                        extra={
                            <Input
                                placeholder="请输入"
                                value={_info['meeting_type']}
                                onChange={val => this.handleClick('onStateChange', 'meeting_type', val)}
                            />
                        }
                    >
                        {MEETING_FORM_LABEL_MAP['meeting_type']}<Required />
                    </List.Item>

                    <List.Item
                        key={`users`}
                        extra={_info['users'] ? _.join(_.map(_info['users'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'users')}
                    >
                        {MEETING_FORM_LABEL_MAP['users']}
                    </List.Item>

                    <List.Item
                        key={`cec_users`}
                        extra={_info['cec_users'] ? _.join(_.map(_info['cec_users'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'cec_users')}
                    >
                        {MEETING_FORM_LABEL_MAP['cec_users']}
                    </List.Item>

                    <List.Item
                        key={`des`}
                        extra={_info['des'] ? _info['des'] : '请选择'}
                        onClick={() => this.handleClick('openTextArea', 'des')}
                    >
                        {MEETING_FORM_LABEL_MAP['des']}
                    </List.Item>

                    <CECSelectorListItem label={MEETING_FORM_LABEL_MAP['isSend']}
                        isRequired={true}
                        options={[
                            { label: "是", value: "是" }, { label: "否", value: "否" },
                        ]}
                        value={_info['isSend'] ? _.map(_info['isSend'], 'value') : []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'isSend', options)}
                    />

                    <List.Item
                        key={`invsetor`}
                        extra={_info['invsetor'] ? _.join(_.map(_info['invsetor'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'invsetor')}
                    >
                        {MEETING_FORM_LABEL_MAP['invsetor']}
                    </List.Item>


                    <List.Item
                        key={`invsetor_des`}
                        extra={_info['invsetor_des'] ? _info['invsetor_des'] : '请选择'}
                        onClick={() => this.handleClick('openTextArea', 'invsetor_des')}
                    >
                        {MEETING_FORM_LABEL_MAP['invsetor_des']}
                    </List.Item>

                    <List.Item
                        key={`meeting_case`}
                        extra={_info['meeting_case'] ? _info['meeting_case'] : '请选择'}
                        onClick={() => this.handleClick('openTextArea', 'meeting_case')}
                    >
                        {MEETING_FORM_LABEL_MAP['meeting_case']}
                    </List.Item>

                    <List.Item
                        key={`meeting_msg`}
                        extra={_info['meeting_msg'] ? _info['meeting_msg'] : '请选择'}
                        onClick={() => this.handleClick('openTextArea', 'meeting_msg')}
                    >
                        {MEETING_FORM_LABEL_MAP['meeting_msg']}
                    </List.Item>


                    <List.Item
                        key={`meeting_txt`}
                        extra={_info['meeting_txt'] ? _info['meeting_txt'] : '请选择'}
                        onClick={() => this.handleClick('openTextArea', 'meeting_txt')}
                    >
                        {MEETING_FORM_LABEL_MAP['meeting_txt']}
                    </List.Item>


                    <List.Item
                        key={`invsetor_users`}
                        extra={_info['invsetor_users'] ? _.join(_.map(_info['invsetor_users'], 'label'), '，') : '请选择'}
                        onClick={() => this.handleClick('openSelect', 'invsetor_users')}
                    >
                        {MEETING_FORM_LABEL_MAP['invsetor_users']}
                    </List.Item>


                </List>
                <CECCalendarPopup ref={this.dateRef}
                    value={moment().toDate()}
                    metadata={{ title: _.get(MEETING_FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />
                <CECSelectPopup
                    ref={this.options_ref}
                    supportSearch={true}
                    multiple={_multiple}
                    title={_.get(MEETING_FORM_LABEL_MAP, _onStateChangeKey, '')}
                    options={_options}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />

                <CECTextAreaPopup
                    metadata={{ title: _.get(MEETING_FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    ref={this.text_area_ref}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, _.get(val, 'value', null))} />

            </Fragment>
        )
    }

    renderFootContent() {
        const {
            metadata,
            onConfirm
        } = this.props
        const {
            _info
        } = this.state
        return (
            <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: MEETING_FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
        )
    }
}


const PROJECT_FORM_LABE_OPTIONS = [
    { label: "日期", key: "date", type: "date" },
    { label: "发送邮件通知所有项目成员", key: "isSend", type: "options" },
    { label: "进展", key: "content", type: "string" },
]
const PROJECT_FORM_LABEL_MAP = _.chain(PROJECT_FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()


class ProjectDesPopup extends BaseExtractPopup {

    constructor(props) {
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: true,
        }
        this.options_ref = createRef()
        this.text_area_ref = createRef()
        this.dateRef = createRef()
        this.ownershipStructurePopupRef = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch (key) {
            case "onStateChange":
                this.setState({
                    _info: { ..._info, [data]: event }
                })
                break
            case 'openSelect':
                Toast.show({ content: "DEMO, 临时数据...." })
                let _tempOptions = [], _tempMultiple = true
                switch (data) {
                    case 'invsetor_user':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        _tempMultiple = true
                        break
                    case 'cec_user':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        _tempMultiple = true
                        break
                    case 'batch':
                        _tempOptions = [
                            { label: "第一批", value: "第一批" },
                            { label: "第二批", value: "第二批" },
                            { label: "第三批", value: "第三批" },
                            { label: "第四批", value: "第四批" },
                        ]
                        _tempMultiple = false
                        break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, () => this.options_ref?.current?.toggle())
                break
            case 'openTextArea':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.text_area_ref?.current?.toggle())
                break
            case 'openDate':
                this.setState({
                    _onStateChangeKey: data,
                }, () => this.dateRef?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info, _multiple, _onStateChangeKey, _options } = this.state
        return (
            <Fragment>
                <List>
                    <List.Item
                        key={`date`}
                        extra={_info['date'] ? moment(_info['date']).format('YYYY-MM-DD') : '请选择'}
                        onClick={() => this.handleClick('openDate', 'date')}
                    >
                        {PROJECT_FORM_LABEL_MAP['date']}
                    </List.Item>
                    <CECSelectorListItem label={PROJECT_FORM_LABEL_MAP['isSend']}
                        isRequired={true}
                        options={[
                            { label: "是", value: "是" }, { label: "否", value: "否" },
                        ]}
                        value={_info['isSend'] ? _.map(_info['isSend'], 'value') : []}
                        onChange={(val, options) => this.handleClick('onStateChange', 'isSend', options)}
                    />
                    <List.Item
                        key={`content`}
                        extra={_info['content'] ? _info['content'] : '请选择'}
                        onClick={() => this.handleClick('openTextArea', 'content')}
                    >
                        {PROJECT_FORM_LABEL_MAP['content']}
                    </List.Item>
                </List>
                <CECCalendarPopup ref={this.dateRef}
                    value={moment().toDate()}
                    metadata={{ title: _.get(PROJECT_FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, val)}
                />
                <CECTextAreaPopup
                    metadata={{ title: _.get(PROJECT_FORM_LABEL_MAP, _onStateChangeKey, '') }}
                    ref={this.text_area_ref}
                    onConfirm={val => this.handleClick('onStateChange', _onStateChangeKey, _.get(val, 'value', null))} />

            </Fragment>
        )
    }

    renderFootContent() {
        const {
            metadata,
            onConfirm
        } = this.props
        const {
            _info
        } = this.state
        return (
            <Button color="primary" block onClick={() => { onConfirm && onConfirm({ ..._info, labelOptions: PROJECT_FORM_LABE_OPTIONS }); this.close() }}>{metadata['confirmText'] ? metadata['confirmText'] : "确定"}</Button>
        )
    }
}

class BasePopup extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            _visible: false
        }
    }

    show() {
        this.setState({ _visible: true })
    }

    hidden() {
        this.setState({ _visible: false })
    }

    toggle() {
        const { _visible } = this.state
        this.setState({ _visible: !_visible })
    }

    render() {
        const { _visible } = this.state
        const { children } = this.props
        return (
            <Popup
                visible={_visible}
                onMaskClick={() => this.hidden()}
                position='right'
                bodyStyle={{ width: '100vw', padding: 0 }}
                destroyOnClose={true}
            >
                {children}
            </Popup>
        )
    }
}




