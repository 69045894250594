import React, { Fragment } from "react";
import { Button, Toast } from 'antd-mobile'
import _ from "lodash";
import BaseExtractPopup from "../BaseExtractPopup";
import CompanyView from "./CompanyView";
import GPView from "./GPView";
import LPView from "./LPView";
import {
  SwapRelationCode,
  FetchCompanyDetailBaseInfo,
  FetchGPDetailBaseInfo,
  FetchLPDetailBaseInfo
} from './transform'

/**
 * 创建公司/机构/LP表单
 */
export default class CECTargetFormPopup extends BaseExtractPopup {
  constructor(props) {
    super(props);

    const code = props.value
    this.state = {
      _info: {
        code: code
      },
    }
  }
  UNSAFE_componentWillReceiveProps(props) {
    const code = props.value
    this.setState({
      _info: {
        code: code
      },
    })
  }
  async show() {
    const {
      category,
      enterpriseCode,
      enterpriseType
    } = this.props
    super.show()
    if (enterpriseCode && enterpriseType) {
      if (category == 'company') {
        await this.fetchCompanyDetailBaseInfo()
      } else if (category == 'investment') {
        await this.fetchGPDetailBaseInfo()
      } else if (category == 'lp') {
        await this.fetchLPDetailBaseInfo()
      }
    }
  }
  async toggle() {
    const {
      category,
      enterpriseCode,
      enterpriseType
    } = this.props
    super.toggle()
    if (enterpriseCode && enterpriseType) {
      if (category == 'company') {
        await this.fetchCompanyDetailBaseInfo()
      } else if (category == 'investment') {
        await this.fetchGPDetailBaseInfo()
      } else if (category == 'lp') {
        await this.fetchLPDetailBaseInfo()
      }
    }
  }
  close() {
    const { onClose } = this.props
    super.close()
    this.setState({ _info: {} }, () => onClose && onClose())
  }
  async fetchCompanyDetailBaseInfo() {
    const { enterpriseCode, enterpriseType } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const { companyCode } = await SwapRelationCode({ code: enterpriseCode, type: enterpriseType })
      if (companyCode) {
        const response = await FetchCompanyDetailBaseInfo(companyCode)
        this.setState({ _info: response }, () => Toast.clear())
      } else {
        Toast.clear()
      }
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchGPDetailBaseInfo() {
    const { enterpriseCode, enterpriseType } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const { gpCode } = await SwapRelationCode({ code: enterpriseCode, type: enterpriseType })
      if (gpCode) {
        const response = await FetchGPDetailBaseInfo(gpCode)
        this.setState({ _info: response }, () => Toast.clear())
      } else {
        Toast.clear()
      }
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchLPDetailBaseInfo() {
    const { enterpriseCode, enterpriseType } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const { lpCode } = await SwapRelationCode({ code: enterpriseCode, type: enterpriseType })
      if (lpCode) {
        const response = await FetchLPDetailBaseInfo(lpCode)
        this.setState({ _info: response }, () => Toast.clear())
      } else {
        Toast.clear()
      }
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  renderBodyContent() {
    const {
      _info
    } = this.state
    const {
      category,
    } = this.props

    return (
      <Fragment>
        {
          category == 'company' && <CompanyView info={_info} onChange={(value) => this.setState({ _info: { ...value } })} />
        }
        {
          category == 'investment' && <GPView info={_info} onChange={(value) => this.setState({ _info: { ...value } })} />
        }
        {
          category == 'lp' && <LPView info={_info} onChange={(value) => this.setState({ _info: { ...value } })} />
        }
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state
    const {
      onConfirm,
      onDelete
    } = this.props
    return (
      <Fragment>
        {
          _info["code"] ? (
            <div style={{ display: 'flex' }}>
              <Button block color="danger" style={{ marginRight: 5 }} onClick={() => onDelete && onDelete(_info['code'])}>删除</Button>
              <Button block color="primary" onClick={() => onConfirm && onConfirm(_info)}>保存</Button>
            </div>
          ) : (
            <Button block color="primary" onClick={() => onConfirm && onConfirm(_info)}>创建</Button>
          )
        }
      </Fragment>
    )
  }
}