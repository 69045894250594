import React, { Fragment, useEffect, useImperativeHandle, useCallback, useRef, useState } from "react"
import _ from "lodash"
import { List, Skeleton, ErrorBlock, Toast } from "antd-mobile"
import { EditSFill } from "antd-mobile-icons"
import CECInfiniteScroll from "../../../../../../../components/InfiniteScroll"
import CECPopup from "../../../../../../../components/Popup"
import './index.less';
import {
  FetchOwnershipStructureDataList,
  FetchOwnershipStructureDataDetail,
} from './transform'

/**
 * 股权结构列表
 * @returns 
 */
export default function OwnershipStructureListView({ code }) {

  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [item, setItem] = useState({})
  const dataDetailPopupRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      await fetchDatas(code, 1);
    };
    code && fetchData();
  }, [code]);

  // 获取列表
  const fetchDatas = async (code, currentPageNum) => {
    setLoading(true)
    try {
      const { datas: newDatas, hasMore, total } = await FetchOwnershipStructureDataList({ code, pageNum: currentPageNum, pageSize })
      setLoading(false);
      setHasMore(hasMore);
      setPageNum(currentPageNum + 1);
      if (currentPageNum === 1) {
        setDatas(newDatas)
      } else {
        setDatas(prevDatas => [...prevDatas, ...newDatas]);
      }
    } catch (error) {
      Toast.show({ content: error })
      setLoading(false)
      setHasMore(false)
    }
  }

  const handleLoadMore = useCallback(async () => {
    code && await fetchDatas(code, pageNum);
  }, [code, pageNum])

  const handleClick = (key, data, event) => {
    console.log("handleClick >>>>", key, data, event)

    switch (key) {
      case 'handle_open_item_detail_popup':
        setItem(data)
        dataDetailPopupRef?.current?.show()
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <List>
        {
          loading && _.size(datas) == 0 && (
            <Fragment>
              <Skeleton.Title animated />
              <Skeleton.Paragraph lineCount={4} animated />
            </Fragment>
          )
        }
        {
          !loading && _.size(datas) == 0 && (
            <Fragment>
              <ErrorBlock status='empty' title="" description="暂无数据" />
            </Fragment>
          )
        }
        {
          datas.map(item => (
            <List.Item onClick={() => handleClick('handle_open_item_detail_popup', item)}>
              <div className="owner-ship-structure-item">
                <div><span className="owner-ship-structure-item-key">是否有实际控制人：</span>{item['isActualController']}</div>
                <div><span className="owner-ship-structure-item-key">创始人/实际控制人在过去1年内是否发生变化：</span>{item['isActualControllerChange']}</div>
                <div><span className="owner-ship-structure-item-key">创始人/股东在过去1年内是否发生变化：</span>{item['isShareholderChange']}</div>
                <div><span className="owner-ship-structure-item-key">创始人/实际控制人股权占比：</span>{item['isActualControllerChangeRatio']}%</div>
                <div><span className="owner-ship-structure-item-key">是否有明确控股机会：</span>{item['isShareholding']}</div>
                <div><span className="owner-ship-structure-item-key">管理层股权占比：</span>{item['manageShareholding']}%</div>
                <div><span className="owner-ship-structure-item-key">所有外部机构股权占比：</span>{item['otherShareholding']}%</div>
                <div><span className="owner-ship-structure-item-key">前三外部机构股权占比：</span>{item['top3OtherShareholding']}%</div>
                <div><span className="owner-ship-structure-item-key">最大外部机构股权占比：</span>{item['maxOtherShareholding']}%</div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span className="owner-ship-structure-item-key">更新时间/更新人：</span>{item['modifyDate']}
                  </div>
                  <div style={{ margin: '0 5px' }}>/</div>
                  <div>{item['modidyUser']}</div>
                </div>
              </div>
            </List.Item>
          ))
        }
        <CECInfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} />
      </List>

      {/** 详情Popup */}

    </Fragment>
  )
}