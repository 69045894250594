import React, { createRef, Fragment } from "react";
import _ from 'lodash';
import BaseExtractPopup from "../../../../../../components/BaseExtractPopup";
import CECSelectPopup from "../../../../../../components/SelectPopup";
import CECTextAreaPopup from "../../../../../../components/TextAreaPopup";
import Required from "../../../../../../components/Required";
import UploadFile from "../../../../../../components/UploadFile";
import {
    SelectorListItem, ListItemExtraPopoverMenu, CECIndustrySelectPopup,
    INVESTOR_TYPE_OPTIONS, INVESTOR_OPTIONS, INVESTOR_USERS_OPTIONS
} from "../../../../../../components/FormsUtils";
import { List, Button, ImageUploader, Toast } from 'antd-mobile';

/**
 * 【项目更新：执行信息 - BD投资人名单 - 在线创建】
 */
const FORM_LABE_OPTIONS = [
    {label: "机构名称", key: "BDInvestor_name", type: 'options'},
    {label: "机构类型", key: "BDInvestor_type", type: 'options'},
    {label: "机构描述", key: "BDInvestor_des", type: 'string'},
    {label: "投资案例", key: "BDInvestor_case", type: 'string'},
    {label: "管理规模", key: "BDInvestor_manage", type: 'string'},
    {label: "机构对接人", key: "BDInvestor_users", type: 'options'},
]
const FORM_LABEL_MAP = _.chain(FORM_LABE_OPTIONS).keyBy('key').mapValues('label').value()



export class CreateBDInvestorNameListPopup extends BaseExtractPopup{
    constructor(props){
        super(props)
        this.state = {
            _info: {},
            _onStateChangeKey: null,
            _options: [],
            _multiple: false,
        }
        this.BDInvestor_options_ref = createRef()
        this.BDInvestor_text_area_ref = createRef()
    }

    handleClick(key, data, event) {
        // console.log("handleClick", key, data, event)
        const { _info } = this.state
        switch(key){
            case "onStateChange":
                this.setState({
                    _info: {..._info, [data]: event}
                })
                break
            case 'openSelect':
                Toast.show({ content: "DEMO, 临时数据...."})
                let _tempOptions = [], _tempMultiple = false
                switch(data){
                    case 'BDInvestor_name':
                        _tempOptions = INVESTOR_OPTIONS
                        break
                    case 'BDInvestor_type':
                        _tempOptions = INVESTOR_TYPE_OPTIONS
                        _tempMultiple = true
                        break
                    case 'BDInvestor_users':
                        _tempOptions = INVESTOR_USERS_OPTIONS
                        _tempMultiple = true
                        break
                }
                this.setState({
                    _onStateChangeKey: data,
                    _options: _tempOptions,
                    _multiple: _tempMultiple
                }, ()=> this.BDInvestor_options_ref?.current?.toggle())
                break
            case 'openTextArea':
                this.setState({
                    _onStateChangeKey: data,
                }, ()=> this.BDInvestor_text_area_ref?.current?.toggle())
                break
        }
    }

    renderBodyContent() {
        const { _info ,_options, _onStateChangeKey, _multiple} = this.state
        return(
            <Fragment>
                <List>
                    <List.Item 
                        key={`BD_INVESTOR_${Math.random()}`}
                        extra={_info['BDInvestor_name'] ? _.join(_.map(_info['BDInvestor_name'], 'label'), ','): '请选择'}
                        onClick={()=> this.handleClick('openSelect', 'BDInvestor_name')}
                    >
                        {FORM_LABEL_MAP['BDInvestor_name']}<Required />
                    </List.Item>
                    <List.Item 
                        key={`BD_INVESTOR_${Math.random()}`}
                        extra={_info['BDInvestor_type'] ? _.join(_.map(_info['BDInvestor_type'], 'label'), ','): '请选择'}
                        onClick={()=> this.handleClick('openSelect', 'BDInvestor_type')}
                    >
                        {FORM_LABEL_MAP['BDInvestor_type']}
                    </List.Item>
                    <List.Item 
                        key={`BD_INVESTOR_${Math.random()}`}
                        extra={_info['BDInvestor_des'] ? _info['BDInvestor_des'] : '请选择'}
                        onClick={()=> this.handleClick('openTextArea', 'BDInvestor_des')}
                    >
                        {FORM_LABEL_MAP['BDInvestor_des']}
                    </List.Item>
                    <List.Item 
                        key={`BD_INVESTOR_${Math.random()}`}
                        extra={_info['BDInvestor_case'] ? _info['BDInvestor_case'] : '请选择'}
                        onClick={()=> this.handleClick('openTextArea', 'BDInvestor_case')}
                    >
                        {FORM_LABEL_MAP['BDInvestor_case']}
                    </List.Item>
                    <List.Item 
                        key={`BD_INVESTOR_${Math.random()}`}
                        extra={_info['BDInvestor_manage'] ? _info['BDInvestor_manage'] : '请选择'}
                        onClick={()=> this.handleClick('openTextArea', 'BDInvestor_manage')}
                    >
                        {FORM_LABEL_MAP['BDInvestor_manage']}
                    </List.Item>
                    <List.Item 
                        key={`BD_INVESTOR_${Math.random()}`}
                        extra={_info['BDInvestor_users'] ? _.join(_.map(_info['BDInvestor_users'], 'label'), ','): '请选择'}
                        onClick={()=> this.handleClick('openSelect', 'BDInvestor_users')}
                    >
                        {FORM_LABEL_MAP['BDInvestor_users']}
                    </List.Item>
                </List>
             
                <CECSelectPopup 
                    ref={this.BDInvestor_options_ref}
                    supportSearch={true}
                    multiple={_multiple}
                    title={_.get(FORM_LABEL_MAP, _onStateChangeKey, '')}
                    options={_options}
                    onConfirm={val=> this.handleClick('onStateChange', _onStateChangeKey, val)}
                />

                <CECTextAreaPopup 
                    metadata={{title: _.get(FORM_LABEL_MAP, _onStateChangeKey, '')}}
                    ref={this.BDInvestor_text_area_ref} 
                    onConfirm={val=> this.handleClick('onStateChange', _onStateChangeKey, _.get(val, 'value', null))}/>
            </Fragment>
            
        )
    }

    renderFootContent() {
        const { _info } = this.state
        const { onConfirm } = this.props
        return (
            <Button block color="primary" onClick={() => {
              onConfirm && onConfirm({..._info, labelOptions: FORM_LABE_OPTIONS})
              this.close()
            }}>确定</Button>
          )
    }
}



/**
 * 【项目更新：执行信息 - BD投资人名单 - 上传文件】
 */
export class UploadDBInvestorNameListFilesPopup extends BaseExtractPopup{
    constructor(props){
        super(props)
        this.state = {
            _info: {}
        }
    }

    renderBodyContent() {
        const { _info } = this.state
        return(
            <List>
                <List.Item 
                    description={
                        <UploadFile 
                            multiple={false} 
                            values={_info['fileList']} 
                            onChange={(values) => this.setState({ _info: { ..._info, "fileList": _.map(values, it=> ({...it, label: it.name, value: it.url})) } })} 
                        />
                    }>
                        附件<Required />
                </List.Item>
            </List>
        )
    }

    renderFootContent() {
        const { _info } = this.state
        const { onConfirm } = this.props
        return (
            <Button block color="primary" onClick={() => {
              onConfirm && onConfirm({..._info})
              this.close()
            }}>确定</Button>
          )
    }
}

