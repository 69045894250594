import { Fragment, PureComponent } from "react";
import { Button, List } from "antd-mobile";
import { MoreOutline } from 'antd-mobile-icons'
import Required from "../Required";
import _ from "lodash";
import './index.css'

/**
 * 多层级路径导航
 */
export default class MultiplePathNavigator extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      _metadatas: props.metadatas,
      _sourcedata: props.sourcedata,
      _value: props.value,
      _extensionPopupComponents: []
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _metadatas: props.metadatas,
      _sourcedata: props.sourcedata,
      _value: props.value,
    })
  }
  handleClick(key, data, event) {
    console.log("handleClick >>>>", key, data, event)
    let {
      _value
    } = this.state
    const {
      onSelect
    } = this.props

    switch (key) {
      case 'handle_open_selector_popup':
        data.ref?.current?.toggle()
        break;
      case 'handle_select_multiple_path_option':
        var extensionPopupComponents = _.filter(_.get(data, "data.path"), item => item.componentRender)
        extensionPopupComponents = extensionPopupComponents ? extensionPopupComponents : []

        if (_.size(extensionPopupComponents) > 0) {
          extensionPopupComponents.find(item => {
            item['parentPathValues'] = [_.get(data, 'metadata.key'), ..._.get(data, "data.value")]
            item['parentPathItems'] = [{ ...data['metadata'], value: _.get(data, 'metadata.key') }, ..._.get(data, "data.path")]
            return item
          })
        }

        this.setState({
          _extensionPopupComponents: extensionPopupComponents
        }, () => {
          if (extensionPopupComponents && _.size(extensionPopupComponents) > 0) {
            extensionPopupComponents.find(item => item.ref?.current?.toggle())
          }
        })
        onSelect && onSelect(data)
        break;
      case 'handle_open_edit_popup':
        if (data) {
          // 判断是否为多层级选择器弹窗
          const multiPathMetadata = _.get(_value[data['key']], "metadata")
          const multiPathValues = _.get(multiPathMetadata, "parentPathValues")
          if (multiPathValues && _.size(multiPathValues) > 0) {
            this.setState({ _extensionPopupComponents: [multiPathMetadata] }, () => {
              multiPathMetadata.ref?.current?.show()
            })
          } else {
            data.ref?.current?.show()
          }
        }
        break;
      default:
        break;
    }
  }

  render() {
    let {
      _metadatas,
      _sourcedata,
      _value,
      _extensionPopupComponents,
    } = this.state
    const {
      onConfirm,
      onDataChange,
      onValueChange,
    } = this.props
    console.log('MultiplePathNavigator _metadatas >>>>', _metadatas, "_sourcedata >>>", _sourcedata, "_value >>>", _value)

    return (
      <List className="multiple-path-nav-list">
        {/** 列表项 */}
        {
          _.filter(_metadatas, { showItem: true })
            .map((item, index) => (
              <DataItem key={`${item['key']}-${index}`}
                className={item['className']}
                metadata={item}
                value={_value}
                metadatas={_metadatas}
                sourcedata={_sourcedata}
                onClick={this.handleClick.bind(this)}
                onDataChange={onDataChange}
                onValueChange={onValueChange}
                onConfirm={onConfirm}
              />
            ))
        }

        {/** 提前渲染下一动作组件选择器 */}
        {
          _metadatas.map(item => renderComponent(item, _sourcedata, _value, onConfirm))
        }

        {/** 多层级Popup展示 */}
        {
          _extensionPopupComponents
          && _.size(_extensionPopupComponents) > 0
          && _extensionPopupComponents.map(item => {
            item = {
              ...item,
              title: item['title'] ? item['title'] : item['label']
            }

            return item.componentRender({
              sourcedata: null,
              metadata: item,
              extensionValue: _value,
              onConfirm: (data) => onConfirm && onConfirm(item, data)
            })
          })
        }
      </List>
    )
  }
}
/**
 * 渲染
 * @returns 
 */
const renderComponent = (item, _sourcedata, _value, onConfirm) => {
  if (!item.componentRender) return null;

  const {
    componentRender,
    key,
    groupKey,
    groups = []
  } = item;

  const sourcedata = _sourcedata ? _sourcedata[key] : {};

  // 渲染当前项
  const renderedComponent = componentRender({
    groupKey,
    metadata: { ...item },
    sourcedata,
    extensionValue: _value,
    onConfirm: data => onConfirm && onConfirm({ ...item }, data)
  });

  // 递归渲染 groups
  const renderedGroups = groups.map(gitem => renderComponent({ ...gitem, groupKey: key }, _sourcedata, _value, onConfirm));

  return (
    <div key={key}>
      {renderedComponent}
      {renderedGroups}
    </div>
  );
};

/**
 * 列表项
 * @returns 
 */
function DataItem({ groupKey, metadata, metadatas, sourcedata, value, className, groupClassName, onClick, onConfirm, onDataChange, onValueChange }) {
  // console.log('DataItem >>>', metadata, metadatas, sourcedata, value)

  let groups = null
  const activeKeys = _.keys(value)
  let itemClassName = activeKeys.includes(metadata['key']) ? `current-active-item ${className}` : `${className}`
  if (groupClassName && !metadata['description'] && !metadata['multiPathComponentRender']) {
    itemClassName = `${itemClassName} ${groupClassName}`
  } else {
    groups = metadata['groups'] && _.size(metadata['groups']) > 0 ? metadata['groups'] : null
    if (groups && _.size(groups) > 0) {
      // itemClassName = `${itemClassName} item-group-prefix`
    }
  }

  const nextParams = {
    groupKey: groupKey,
    extensionValue: value,
    sourcedata: sourcedata ? sourcedata[metadata['key']] : {},
    metadata: metadata
  }

  const mainNode = (
    <List.Item key={`${metadata['key']}-${new Date().getTime()}`}
      disabled={metadata['disabled'] ? metadata['disabled']({ ...nextParams }) : false}
      className={itemClassName}
      extra={
        metadata['componentRender'] && activeKeys.includes(metadata['key']) ? (
          <Button color="primary" fill="none" style={{ fontSize: '12px' }} onClick={() => onClick && onClick('handle_open_edit_popup', metadata, value[metadata['key']])}>编辑数据<MoreOutline style={{ marginLeft: 5 }} /></Button>
        ) : <div>{metadata['placeholder']}</div>
      }
      arrow={activeKeys.includes(metadata['key']) ? null : true}
      onClick={activeKeys.includes(metadata['key']) ? () => null : (e) => onClick && onClick('handle_open_selector_popup', metadata, e)}>
      {metadata['label']}
      {
        metadata['getLabelSuffix'] && metadata['getLabelSuffix']({ ...nextParams })
      }
      {
        metadata['required'] && <Required />
      }
      {
        metadata['itemRender'] && metadata['itemRender']({
          ...nextParams,
          metadatas: metadatas,
          sourcedataOrigin: sourcedata,
          onDataChange: (datas) => onDataChange && onDataChange(metadata, sourcedata, datas)
        })
      }
    </List.Item>
  )

  return (
    <Fragment key={`${metadata['key']}-${new Date().getTime()}`}>
      {
        groups && _.size(groups) > 0 ? (
          <div className="item-group-prefix">
            {mainNode}
            {metadata['showRemove'] && metadata['showRemove']({ ...nextParams, metadatas: metadatas, }) && metadata['onRemove'] && metadata['onRemove']({ ...nextParams, metadatas: metadatas, sourcedataOrigin: sourcedata, onDataChange: (datas) => onDataChange && onDataChange(metadata, sourcedata, datas), onValueChange: (value) => onValueChange && onValueChange(value) })}
          </div>
        ) : mainNode
      }
      {
        (metadata['description'] || metadata['multiPathComponentRender']) && (
          <List.Item key={`${metadata['key']}-item-description`}
            className={groupClassName ? `${groupClassName} item-description` : "item-description"}
            disabled={metadata['disabled'] ? metadata['disabled']({ ...nextParams }) : false}>
            {/** 附加描述信息 */}
            {
              metadata['description'] && metadata['description']({
                ...nextParams,
                onConfirm: (data) => onConfirm && onConfirm(metadata, data)
              })
            }
            {/** 渲染多层级路径选择器 */}
            {
              metadata['multiPathComponentRender'] && metadata['multiPathComponentRender']({
                ...nextParams,
                onSelect: (data) => onClick && onClick('handle_select_multiple_path_option', data),
                onConfirm: (data) => onConfirm && onConfirm(metadata, data),
                onClick: onClick
              })
            }
          </List.Item>
        )
      }
      {
        groups && _.size(groups) > 0 && (
          <Fragment>
            {
              groups.map((item, index) => (
                <DataItem key={`${metadata['key']}-${index}`}
                  className={item['className']}
                  metadata={item}
                  value={value}
                  sourcedata={sourcedata}
                  onClick={onClick}
                  onConfirm={onConfirm}
                  groupClassName={_.size(metadata['groups']) === index + 1 ? 'item-group-suffix' : null}
                  groupKey={metadata['key']}
                />
              ))
            }
          </Fragment>
        )
      }
    </Fragment>
  )
}