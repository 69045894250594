import React, { Fragment, createRef, useRef } from "react";
import { List, Button, DatePicker, Input, Toast, Space, Popover } from 'antd-mobile'
import { EditSFill, DeleteOutline, ExclamationTriangleOutline } from 'antd-mobile-icons';
import _ from "lodash";
import moment from "moment";
import Required from "../../../../../components/Required";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import BaseExtractPopup from "../../../../../components/BaseExtractPopup";
import {
  OPTIONS,
  OPTION_YES_VALUE,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 财务数据
 */
export default class FinancialDataPopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props)

    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })

    this.state = {
      _info: info,
      _currentFinancialDataItemKey: null,
      _currentFinancialDataItem: null,
    }
    this.financialDataItemPopupRef = createRef()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    console.log('handleClick =>', key, data, event)
    const { _info } = this.state

    switch (key) {
      case 'handle_open_financial_data_item_popup':
        this.setState({
          _currentFinancialDataItemKey: data,
          _currentFinancialDataItem: event
        }, () => this.financialDataItemPopupRef?.current?.toggle())
        break;
      case 'handle_delete_financial_data_item':
        if (data && _info[data] && event) {
          var array = []
          _info[data].find(item => {
            if (item['key'] !== event['key']) {
              array.push(item)
            }
          })
          this.setState({ _info: { ..._info, [data]: array } })
        }
        break;
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange >>>>>", key, data, event)
    const { _info, _currentFinancialDataItemKey } = this.state

    switch (key) {
      case 'change_value':
        this.setState({
          _info: _.assign({}, _info, {
            [data]: event
          })
        })
        break;
      case 'change_confirm_financial_data_item':
        if (data) {
          let existValues = _info[_currentFinancialDataItemKey]
          if (!existValues) {
            existValues = []
          }
          existValues = [data]
          // existValues.push(data)
          this.setState({
            _info: { ..._info, [_currentFinancialDataItemKey]: existValues }
          })
        }
        break;
      default:
        break;
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _currentFinancialDataItem,
    } = this.state
    console.log("financial data ==>>>", _info)

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="是否有收入"
            options={OPTIONS}
            value={_info['income'] ? _.map(_info['income'], 'value') : null}
            onChange={(value, options) => this.handleChange('change_value', 'income', options)}
          />
          {
            _info['incomeDatas'] && _.size(_info['incomeDatas']) > 0 && (
              _info['incomeDatas'].map(item => <FinancialDataViewItem item={item} onEdit={this.handleClick.bind(this, 'handle_open_financial_data_item_popup', 'incomeDatas', item)} onDelete={this.handleClick.bind(this, 'handle_delete_financial_data_item', 'incomeDatas', item)} />)
            )
          }
          {
            _info['income'] && _.map(_info['income'], 'value').includes(OPTION_YES_VALUE) && (
              <List.Item extra="添加" onClick={this.handleClick.bind(this, 'handle_open_financial_data_item_popup', 'incomeDatas', null)}>收入财务数据</List.Item>
            )
          }
        </List>
        <List style={{ '--border-top': 'none' }}>
          <CECSelectorListItem label="是否有正向EBITDA"
            options={OPTIONS}
            value={_info['ebitda'] ? _.map(_info['ebitda'], 'value') : null}
            onChange={(value, options) => this.handleChange('change_value', 'ebitda', options)}
          />
          {
            _info['ebitdaDatas'] && _.size(_info['ebitdaDatas']) > 0 && (
              _info['ebitdaDatas'].map(item => <FinancialDataViewItem item={item} onEdit={this.handleClick.bind(this, 'handle_open_financial_data_item_popup', 'ebitdaDatas', item)} onDelete={this.handleClick.bind(this, 'handle_delete_financial_data_item', 'ebitdaDatas', item)} />)
            )
          }
          {
            _info['ebitda'] && _.map(_info['ebitda'], 'value').includes(OPTION_YES_VALUE) && (
              <List.Item onClick={this.handleClick.bind(this, 'handle_open_financial_data_item_popup', 'ebitdaDatas', null)}>正向EBITDA财务数据</List.Item>
            )
          }
        </List>
        <List style={{ '--border-top': 'none' }}>
          <CECSelectorListItem label="是否有利润"
            options={OPTIONS}
            value={_info['profit'] ? _.map(_info['profit'], 'value') : null}
            onChange={(value, options) => this.handleChange('change_value', 'profit', options)}
          />
          {
            _info['profitDatas'] && _.size(_info['profitDatas']) > 0 && (
              _info['profitDatas'].map(item => <FinancialDataViewItem item={item} onEdit={this.handleClick.bind(this, 'handle_open_financial_data_item_popup', 'profitDatas', item)} onDelete={this.handleClick.bind(this, 'handle_delete_financial_data_item', 'profitDatas', null)} />)
            )
          }
          {
            _info['profit'] && _.map(_info['profit'], 'value').includes(OPTION_YES_VALUE) && (
              <List.Item onClick={this.handleClick.bind(this, 'handle_open_financial_data_item_popup', 'profitDatas', null)}>利润财务数据</List.Item>
            )
          }
        </List>
        <List style={{ '--border-top': 'none' }}>
          <CECSelectorListItem label="是否有正现金流"
            options={OPTIONS}
            value={_info['cashflow'] ? _.map(_info['cashflow'], 'value') : null}
            onChange={(value, options) => this.handleChange('change_value', 'cashflow', options)}
          />
        </List>

        {/** 财务数据填写弹窗 */}
        <FinancialDataItemPopup ref={this.financialDataItemPopupRef}
          metadata={{ title: '财务数据' }}
          value={_currentFinancialDataItem}
          onConfirm={this.handleChange.bind(this, 'change_confirm_financial_data_item')}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const {
      _info,
    } = this.state
    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}

/**
 * 财务数据展示Item
 * @param {*} param0 
 * @returns 
 */
export function FinancialDataViewItem({ item, onEdit, onDelete }) {

  const deletePopoverRef = useRef(null)

  return (
    <List.Item extra={(
      <Space style={{ '--gap': 0 }}>
        <Button size="mini" fill="none" color="primary" onClick={() => onEdit && onEdit(item)}><EditSFill /></Button>
        <Popover trigger="click"
          destroyOnHide={true}
          ref={deletePopoverRef}
          content={(
            <div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <span><ExclamationTriangleOutline style={{ color: '#ff8f1f', fontSize: 20, marginRight: 5 }} /></span>
                <span>确定删除数据？</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button size="mini" style={{ marginRight: 5 }} onClick={() => deletePopoverRef?.current?.hide()}>取消</Button>
                <Button size="mini" color="primary" onClick={() => onDelete && onDelete(item)}>确定</Button>
              </div>
            </div>
          )}>
          <Button size="mini" fill="none" color="danger"><DeleteOutline /></Button>
        </Popover>
      </Space>
    )}>
      <span style={{ color: '#1890FF' }}>年份：{item['year'] ? moment(item['year']).format("YYYY") : '--'}</span>
    </List.Item>
  )
}


const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
const UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]
const AMOUNT_OPTIONS_NUMBER = '金额'
const AMOUNT_OPTIONS = [
  { value: AMOUNT_OPTIONS_NUMBER, label: AMOUNT_OPTIONS_NUMBER },
  { value: '数百万', label: '数百万' },
  { value: '数千万', label: '数千万' },
  { value: '数亿', label: '数亿' },
  { value: '数百亿', label: '数百亿' },
  { value: '数千亿', label: '数千亿' },
]
/**
 * 财务数据填写Item弹窗
 */
export class FinancialDataItemPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    this.state = {
      _info: props.value ? props.value : {},
    }
    this.yearDatePickerRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ _info: props.value ? props.value : {} })
  }
  handleClick(key, data, event) {
    console.log("handleClick >>>>>", key, data, event)
    const { _info } = this.state
    const { onConfirm } = this.props

    switch (key) {
      case 'handle_confirm_value':
        if (this.checkValidationData()) {
          onConfirm && onConfirm({ ..._info, key: _info['key'] ? _info['key'] : moment().format('x') })
          this.close()
        }
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    console.log("handleChange >>>>>", key, data, event)
    const {
      _info
    } = this.state

    switch (key) {
      case 'change_input_value':
      case 'change_value':
        this.setState({
          _info: _.assign({}, _info, {
            [data]: event
          })
        })
        break;
      default:
        break;
    }
  }
  checkValidationData() {
    const { _info } = this.state
    if (!_info['year']) {
      Toast.show({ content: '请选择年份' })
      return false
    }
    if (!_info['currencys'] || _.size(_info['currencys']) === 0) {
      Toast.show({ content: '请选择币种' })
      return false
    }
    if (!_info['units'] || _.size(_info['units']) === 0) {
      Toast.show({ content: '请选择单位' })
      return false
    }
    if (!_info['amounts'] || _.size(_info['amounts']) === 0) {
      Toast.show({ content: '请选择金额' })
      return false
    }
    if (_.size(_.filter(_info['amounts'], { value: AMOUNT_OPTIONS_NUMBER })) > 0 && !_info['amountValue']) {
      Toast.show({ content: '请填写具体金额' })
      return false
    }
    return true
  }
  renderBodyContent() {
    const {
      _info
    } = this.state

    return (
      <Fragment>
        <List>
          <List.Item extra={_info['year'] ? moment(_info['year']).format("YYYY") : "请选择"} onClick={() => this.yearDatePickerRef?.current?.toggle()}>年份<Required /></List.Item>
          <CECSelectorListItem label="币种"
            isRequired={true}
            options={CURRENCY_OPTIONS}
            value={_info['currencys'] ? _.map(_info['currencys'], "value") : []}
            onChange={(value, options) => this.handleChange("change_value", 'currencys', options)}
          >
            {
              _info['currencys'] && _.map(_info['currencys'], "value").includes(CURRENCY_OPTIONS_OTHER_VALUE) && (
                <Input placeholder="请输入其他币种" value={_info['currencyOther']} onChange={this.handleChange.bind(this, 'change_input_value', 'currencyOther')} />
              )
            }
          </CECSelectorListItem>

          <CECSelectorListItem label="单位"
            isRequired={true}
            options={UNIT_OPTIONS}
            value={_info['units'] ? _.map(_info['units'], "value") : []}
            onChange={(value, options) => this.handleChange("change_value", 'units', options)}
          >
          </CECSelectorListItem>

          <CECSelectorListItem label="金额"
            isRequired={true}
            options={AMOUNT_OPTIONS}
            value={_info['amounts'] ? _.map(_info['amounts'], "value") : []}
            onChange={(value, options) => this.handleChange("change_value", 'amounts', options)}
          >
            {
              _info['amounts'] && _.map(_info['amounts'], "value").includes(AMOUNT_OPTIONS_NUMBER) && (
                <Input placeholder="请输入金额" value={_info['amountValue']} onChange={this.handleChange.bind(this, 'change_input_value', 'amountValue')} />
              )
            }
          </CECSelectorListItem>
        </List>

        {/** 年份 */}
        <DatePicker title='年份'
          precision="year"
          ref={this.yearDatePickerRef}
          max={moment("2099-12-31").toDate()}
          min={moment("1900-01-01").toDate()}
          value={_info['year']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'year')}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    return (
      <Button block color="primary" style={{ fontSize: '14px' }} onClick={this.handleClick.bind(this, 'handle_confirm_value')}>保存</Button>
    )
  }

}