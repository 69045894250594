import React, { Component } from "react";
import zhCN from 'antd-mobile/es/locales/zh-CN';
import { SafeArea, ConfigProvider } from "antd-mobile";
import './index.css';

export default class Layout extends Component {

  render() {

    let content = (
      <ConfigProvider locale={zhCN}>
        <div className="cec-smartx-box">
          <div className="cec-smartx-head">
            {/* <SafeArea position='top' /> */}
            {
              this.props.head
            }
          </div>
          <div style={this.props.style ? {...this.props.style} : null} className={this.props.foot ? "cec-smartx-body cec-smartx-inner-body" : "cec-smartx-body"} onScroll={this.props.onScroll ? this.props.onScroll : null}>
            {
              this.props.children
            }
          </div>
          <div className={"cec-smartx-foot"}>
            {
              this.props.foot
            }
            {/* <SafeArea position='bottom' /> */}
          </div>
        </div>
      </ConfigProvider>
    )
    return content
  }

}