import React from "react";
import _ from "lodash";
import request from "../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../components/AmountNumber";


// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]

/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type']
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 搜索公司相关联系人
 * @returns 
 */
export async function FetchCompanyOwnerUserList({ code, searchKey, pageNum, pageSize }) {

  try {
    const response = await request("POST", "/cec-sims-server/company/entrepreneurlist", { base_comp_code: code, comgplpflag: true, input: searchKey ? searchKey : "", pageNum, pageSize })
    const total = response ? _.size(response) : 0 //response['total']
    const list = [...response] //response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          label: `${item['base_entrepreneur_name']}`,
          value: item['base_entrepreneur_code'],
          titleExtension: (
            <span style={{ color: '#999', marginLeft: 5 }}>{item['base_entrepreneur_posiname']}</span>
          ),
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}


/**
 * 保存 投资案例更新
 * @param {*} info 
 */
export async function SaveInfo(info) {

  if (!info['company'] || _.size(info['company']) === 0) {
    throw '投资标的为空'
  }

  let roundValues = info['round'] ? _.join(_.map(info['round'], "value")) : null
  let momenyCurrency = info['momenyCurrencyOther']
  if (!momenyCurrency) {
    momenyCurrency = info['momenyCurrency'] ? _.join(_.map(info['momenyCurrency'], "value")) : null
  }
  let momenyUnit = info['momenyUnit'] ? _.join(_.map(info['momenyUnit'], "value")) : null
  let momenyValue = parseAmountNumber(info['momenyValue'], momenyUnit)

  let valuationCurrency = info['valuationCurrencyOther']
  if (!valuationCurrency) {
    valuationCurrency = info['valuationCurrency'] ? _.join(_.map(info['valuationCurrency'], "value")) : null
  }
  let valuationUnit = info['valuationUnit'] ? _.join(_.map(info['valuationUnit'], "value")) : null
  let valuationValue = parseAmountNumber(info['valuationValue'], valuationUnit)

  // 投资方式
  let investmentWays = []
  if (info['investmentWays']) {
    info['investmentWays'].find(item => {
      investmentWays.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'], })
    })
  }

  // 主要负责人
  let owners = []
  if (info['owners']) {
    info['owners'].find(item => {
      owners.push({ base_investor_code: item['value'], base_investor_name: item['label'], })
    })
  }


  let payload = {}
  if (info['targetType'] === 'info_enterprise') {// 企业
    // 投资人（公司）
    var investors = [{
      base_comp_code: info['code'], // 关联对象
      base_trade_currency: momenyCurrency,
      base_trade_unit: momenyUnit,
      base_trade_inmoney: momenyValue,
      invse_guess_currency_name: valuationCurrency,
      invse_guess_unit: valuationUnit,
      invse_guess_particulars: valuationValue,
      invse_stock_ownership: info['equityRatio'],
      label_data: investmentWays,
      owner_data: owners,
    }]

    payload = {
      base_trade_code: info['id'],
      base_comp_code: info['company'] ? _.join(_.map(info['company'], "value")) : null,// 投资标的
      base_trade_stage: roundValues,
      base_trade_date: info['tradeDate'] ? info['tradeDate'].toString() : null,
      // 融资金额/币种/单位
      invse_detail_money: momenyValue,
      invse_currency_name: momenyCurrency,
      invse_detail_unit: momenyUnit,
      // 股权比例
      invse_stock_ownership: info['equityRatio'],
      // 投后估值金额/币种/单位
      invse_guess_particulars: valuationValue,
      invse_guess_currency_name: valuationCurrency,
      invse_guess_unit: valuationUnit,
      investment_datas: JSON.stringify(investors),
      showflag: info['id'] ? true : false,
    }
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    // 投资人（机构）
    var investors = [{
      base_investment_code: info['code'], // 关联对象
      base_trade_currency: momenyCurrency,
      base_trade_unit: momenyUnit,
      base_trade_inmoney: momenyValue,
      invse_guess_currency_name: valuationCurrency,
      invse_guess_unit: valuationUnit,
      invse_guess_particulars: valuationValue,
      invse_stock_ownership: info['equityRatio'],
      label_data: investmentWays,
      owner_data: owners,
    }]

    payload = {
      base_trade_code: info['id'],
      base_comp_code: info['company'] ? _.join(_.map(info['company'], "value")) : null,// 投资标的
      base_trade_stage: roundValues,
      base_trade_date: info['tradeDate'] ? info['tradeDate'].toString() : null,
      // 融资金额/币种/单位
      invse_detail_money: momenyValue,
      invse_currency_name: momenyCurrency,
      invse_detail_unit: momenyUnit,
      // 股权比例
      invse_stock_ownership: info['equityRatio'],
      // 投后估值金额/币种/单位
      invse_guess_particulars: valuationValue,
      invse_guess_currency_name: valuationCurrency,
      invse_guess_unit: valuationUnit,
      investment_datas: JSON.stringify(investors),
      showflag: info['id'] ? true : false,
    }
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    // 投资人（LP）
    var investors = [{
      base_lp_code: info['code'], // 关联对象
      base_trade_currency: momenyCurrency,
      base_trade_unit: momenyUnit,
      base_trade_inmoney: momenyValue,
      invse_guess_currency_name: valuationCurrency,
      invse_guess_unit: valuationUnit,
      invse_guess_particulars: valuationValue,
      invse_stock_ownership: info['equityRatio'],
      label_data: investmentWays,
      owner_data: owners,
    }]

    payload = {
      base_trade_code: info['id'],
      base_comp_code: info['company'] ? _.join(_.map(info['company'], "value")) : null,// 投资标的
      base_trade_stage: roundValues,
      base_trade_date: info['tradeDate'] ? info['tradeDate'].toString() : null,
      // 融资金额/币种/单位
      invse_detail_money: momenyValue,
      invse_currency_name: momenyCurrency,
      invse_detail_unit: momenyUnit,
      // 股权比例
      invse_stock_ownership: info['equityRatio'],
      // 投后估值金额/币种/单位
      invse_guess_particulars: valuationValue,
      invse_guess_currency_name: valuationCurrency,
      invse_guess_unit: valuationUnit,
      investment_datas: JSON.stringify(investors),
      showflag: info['id'] ? true : false,
    }
  }

  try {
    const response = await request("POST", "/cec-sims-server/trade/save", payload)
    return { ...info, id: response['base_trade_code'] }
  } catch (error) {
    throw error
  }
}

/**
 * 删除 投资案例更新
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/trade/delete", { base_trade_code: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}