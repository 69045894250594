import React, { createRef, } from "react";
import { Button, Divider, Dropdown, Selector, SideBar, Space, Tabs, Tag, List, CheckList, SearchBar, Toast } from "antd-mobile";
import { EditSOutline, CheckCircleOutline, DownOutline, ExclamationCircleFill } from 'antd-mobile-icons'
import './index.css'
import BaseExtractPopup from "../../../../components/BaseExtractPopup";
import _ from "lodash";
const DATA = [
    {
        code: "Tesla", 
        status: "LiveDeal",
        subStatus: "",
        isChange: true,
        name:"特斯拉",
        type: "融资",
        date: "2023/08/01启动",
        users: "Ronald Sun",
        group: "科技"
    },
    {
        code: "JD", 
        status: "PreLaunch",
        subStatus: "",
        isChange: false,
        name:"京东",
        type: "融资",
        date: "2023/08/01启动",
        users: "Ronald Sun",
        group: "科技"
    },
    {
        code: "Zeeker", 
        status: "LiveDeal",
        subStatus: "待联系",
        isChange: false,
        name:"极客",
        type: "融资",
        date: "2023/08/01启动",
        users: "Ronald Sun",
        group: "M&A"
    }
]
/** 项目列表 */
export default class ProjectListViewPopup extends BaseExtractPopup {

    constructor(props) {
        super(props)

        this.state = {
            _activeKey: null,
            _dropdownValue: [],
            _dataList: [...DATA,],

            _dropdownDateSelect: "启动年份",
            _dropdownDateValue: [],
            _dropdownUsersSelect: "审批负责人",
            _dropdownUsersValue: [],
            _dropdownOtherSelect: "项目Code",
            _dropdownTypeValue: [],
            _dropdownGroupValue: [],
            _dropdownStateValue: [],
        }
        this.dropdownRef = createRef()
        this.detailRef = createRef()
    }

    toggle() {
        // const {
        //     _activeKey
        // } = this.state
        // this.setState({
        //     _activeKey: "阶段"
        // })
        super.toggle()
    }

    close() {
        this.setState({
            _activeKey: null
        })
        this.dropdownRef.current?.close()
        super.close()
    }

    getDestroyOnClose() {
        return true
    }
    closeDropdowen() {
        this.dropdownRef?.current?.close()
    }
    renderBodyContent() {
        let {
            _activeKey,
            _dataList,
            _dropdownValue,
            _dropdownDateSelect,
            _dropdownDateValue,
            _dropdownUsersSelect,
            _dropdownUsersValue,
            _dropdownOtherSelect,
            _dropdownTypeValue,
            _dropdownGroupValue,
            _dropdownStateValue,
        } = this.state
        // console.log('activeKey >>>', _activeKey)

        return (
            <div className="find-project-popup">
                <div className="find-project-popup-dropdown-box">
                    <Dropdown 
                        className="find-project-popup-dropdown"
                        getContainer={() => document.querySelector('.find-investor-popup')}
                        ref={this.dropdownRef}
                        defaultActiveKey={_activeKey}
                        onChange={val=> {}}
                    >
                        <Dropdown.Item key="阶段" title="阶段" destroyOnClose highlight={_.size(_dropdownValue) > 0 ? true: false}>
                            <DropdownCotentView onClick={()=> this.closeDropdowen()}>
                                <div style={{padding: 12}}>
                                    <Selector 
                                        multiple
                                        columns={2}
                                        options={[
                                            {label: "PreLaunch", value: "PreLaunch"}, 
                                            {label: "LiveDeal", value: "LiveDeal"},
                                            {label: "PostClosing", value: "PostClosing"},
                                            {label: "Closed", value: "Closed"},
                                            {label: "Teminated", value: "Teminated"},
                                            {label: "Suspended", value: "Suspended"}
                                        ]}
                                        value={_dropdownValue}
                                        onChange={val=> this.setState({_dropdownValue: val})}
                                    />
                                </div>
                            </DropdownCotentView>
                        </Dropdown.Item>
                        <Dropdown.Item key="启动时间" title="启动时间" destroyOnClose>
                            <DropdownCotentView onClick={()=> this.closeDropdowen()}>
                                <DropdownCotentSubView
                                    options={[
                                        {label: "启动年份", value: "启动年份"},
                                        {label: "自定义", value: "自定义"},
                                    ]}
                                    value={_dropdownDateSelect}
                                    onChange={val=> this.setState({_dropdownDateSelect: val})}
                                >
                                    {
                                        _dropdownDateSelect == "启动年份" && (
                                            <div style={{padding: 12}}>
                                                <Selector 
                                                        columns={2}
                                                        multiple
                                                        options={[
                                                            {label: "2022", value: "2022"}, 
                                                            {label: "2023", value: "2023"}, 
                                                            {label: "2024", value: "2024"},
                                                        ]}
                                                        value={_dropdownDateValue}
                                                        onChange={val=> this.setState({_dropdownDateValue: val})}
                                                    />
                                            </div>
                                        )
                                    }
                                    {
                                        _dropdownDateSelect == "自定义" && (
                                            <List style={{ width: "100%", '--border-top': 'none', '--font-size': '13px'}}>
                                                <List.Item key={1} onClick={()=> Toast.show({ content: '正在开发中' })}>开始年份</List.Item>
                                                <List.Item key={2} onClick={()=> Toast.show({ content: '正在开发中' })}>结束年份</List.Item>
                                            </List>
                                        )
                                    }
                                </DropdownCotentSubView>
                            </DropdownCotentView>
                        </Dropdown.Item>
                        <Dropdown.Item key="成员" title="成员" destroyOnClose>
                            <DropdownCotentView onClick={()=> this.closeDropdowen()}>
                                <DropdownCotentSubView
                                    options={[
                                        {label: "审批负责人", value: "审批负责人"},
                                        {label: "其他负责人", value: "其他负责人"},
                                        {label: "ECM协调人", value: "ECM协调人"},
                                        {label: "项目PM", value: "项目PM"},
                                        {label: "项目成员", value: "项目成员"},
                                    ]}
                                    value={_dropdownUsersSelect}
                                    onChange={val=> this.setState({_dropdownUsersSelect: val})}
                                >
                                    {/* {
                                        _dropdownUsersSelect == "审批负责人" && (
                                            <div>审批负责人</div>
                                        )
                                    } */}
                                    <CheckList multiple value={_dropdownUsersValue} onChange={val=> this.setState({_dropdownUsersValue: val})} style={{ width: "100%", '--border-top': 'none', '--font-size': '13px'}}>
                                        <CheckList.Item value="张三">张三</CheckList.Item>
                                        <CheckList.Item value="李四">李四</CheckList.Item>
                                        <CheckList.Item value="王五">王五</CheckList.Item>
                                    </CheckList>
                                </DropdownCotentSubView>
                            </DropdownCotentView>
                        </Dropdown.Item>
                        <Dropdown.Item key="其他" title="其他" destroyOnClose>
                            <DropdownCotentView onClick={()=> this.closeDropdowen()}>
                                <DropdownCotentSubView
                                    options={[
                                        {label: "项目Code", value: "项目Code"},
                                        {label: "客户", value: "客户"},
                                        {label: "类型", value: "类型"},
                                        {label: "组", value: "组"},
                                        {label: "阶段", value: "阶段"},
                                    ]}
                                    value={_dropdownOtherSelect}
                                    onChange={val=> this.setState({_dropdownOtherSelect: val})}
                                >
                                    {
                                        _dropdownOtherSelect == "项目Code" && (
                                            <div style={{padding: 8}}>
                                                <SearchBar className="find-project-popup-dropdown-content-sub-view-main-search-bar" placeholder="请输入关键字"/>
                                            </div>
                                        )
                                    }
                                    {
                                        _dropdownOtherSelect == "客户" && (
                                            <div style={{padding: 8}}>
                                                <SearchBar className="find-project-popup-dropdown-content-sub-view-main-search-bar" placeholder="请输入关键字"/>
                                            </div>
                                        )
                                    }
                                    {
                                        _dropdownOtherSelect == "类型" && (
                                            <div style={{padding: 12}}>
                                                <Selector 
                                                    columns={2}
                                                    multiple
                                                    options={[
                                                        {label: "融资", value: "融资"}, {label: "并购", value: "并购"}, 
                                                    ]}
                                                    value={_dropdownTypeValue}
                                                    onChange={val=> this.setState({_dropdownTypeValue: val})}
                                                />
                                            </div>
                                        )
                                    }
                                     {
                                        _dropdownOtherSelect == "组" && (
                                            <div style={{padding: 12}}>
                                                <Selector 
                                                    columns={2}
                                                    multiple
                                                    options={[
                                                        {label: "消费&娱乐", value: "1"}, 
                                                        {label: "科技", value: "2"}, 
                                                        {label: "Pharma", value: "3"}, 
                                                        {label: "Service", value: "4"}, 
                                                        {label: "MedTech", value: "5"}, 
                                                        {label: "M&A", value: "6"}, 
                                                        {label: "Digital", value: "7"}, 
                                                        {label: "SynBio", value: "8"}, 
                                                        {label: "GP/LPf服务-募资", value: "9"}, 
                                                        {label: "GP/LPf服务-投资退出", value: "10"}, 
                                                    ]}
                                                    value={_dropdownGroupValue}
                                                    onChange={val=> this.setState({_dropdownGroupValue: val})}
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        _dropdownOtherSelect == "阶段" && (
                                            <div style={{padding: 12}}>
                                                <Selector 
                                                    columns={2}
                                                    multiple
                                                    options={[
                                                        {label: "正在变更", value: "融资"}, {label: "为变更", value: "并购"}, 
                                                    ]}
                                                    value={_dropdownStateValue}
                                                    onChange={val=> this.setState({_dropdownStateValue: val})}
                                                />
                                            </div>
                                        )
                                    }
                                    
                                </DropdownCotentSubView>
                            </DropdownCotentView>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
                <div className="find-project-popup-count-box">{`共${_.size(_dataList)}条`}</div>
                

                <div className="find-project-popup-box">
                {
                    _.map(_dataList, (item, i)=> (
                        <div key={`item-${i}`} className="find-project-popup-item" onClick={()=> this.detailRef?.current?.toggle()}>
                           <div className="find-project-popup-name">
                              <Space align="center">
                                <div className="title">{item['code']}</div>
                                
                                {
                                    item['status'] == "PreLaunch" ? 
                                    <Tag className="tag-black">{item['status']}</Tag>
                                    : 
                                    item['isChange'] ? (
                                        <div>
                                            <Tag className="tag-yellow">{item['status']}</Tag>
                                            <Tag className="tag-yellow-full">正在变更</Tag>
                                        </div>
                                    ):
                                    <Tag className="tag-blue">{item['status']}</Tag>
                                }
                                
                                <div className="subStatus">{item['subStatus']}</div>
                              </Space>

                              <div className="group">{item['group']}</div>
                           </div> 
                           <div className="find-project-popup-content">
                                
                                <Space align="center">
                                    <span>
                                        <svg t="1703570710340" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4347" width="15" height="15"><path d="M901.12 841.728h-51.712V374.272c0-72.704-41.472-103.936-67.584-109.056l-165.888-51.712V156.16c0-10.24-5.12-15.36-10.24-20.992-5.12-5.12-15.36-5.12-20.992-5.12L237.056 228.864s-62.464 15.36-62.464 98.816v514.048H122.88c-15.36 0-26.112 10.24-26.112 26.112 0 15.36 10.24 26.112 26.112 26.112h778.24c15.36 0 26.112-10.24 26.112-26.112 0-15.872-10.24-26.112-26.112-26.112z m-337.408 0H226.304V327.68c0-36.352 20.992-46.592 26.112-46.592l311.296-88.064v648.704z m233.984 0h-181.76V265.216l160.256 53.248 3.584 1.536s15.872 6.656 18.432 54.272v467.456zM371.712 300.032H320v51.712h51.712V300.032z m119.296 0H439.296v51.712h51.712V300.032zM371.712 413.184H320v51.712h51.712V413.184z m119.296 0H439.296v51.712h51.712V413.184z m-119.296 113.152H320v51.712h51.712v-51.712z m119.296 0H439.296v51.712h51.712v-51.712z m-119.296 112.64H320V691.2h51.712v-52.224z m119.296 0H439.296V691.2h51.712v-52.224z m-119.296 113.152H320V803.84h51.712v-51.712z m119.296 0H439.296V803.84h51.712v-51.712z m192.512-300.032h51.712V400.384h-51.712v51.712z m0 119.296h51.712v-51.712h-51.712v51.712z m0 113.664h51.712v-51.712h-51.712v51.712z m0 115.2h51.712v-51.712h-51.712v51.712z" fill="#999999" p-id="4348"></path></svg>
                                    </span>

                                    <div className="name">{item['name']}</div>
                                </Space>
                                <Divider direction="vertical" style={{margin: "0 8px", borderLeft: "1px solid #999"}} />
                                <div>{item['type']}</div>
                                <Divider direction="vertical" style={{margin: "0 8px", borderLeft: "1px solid #999"}}/>
                                <div>{item['date']}</div>
                           </div> 
                           <div className="find-project-popup-users">
                            <Space align="center">
                                <span style={{paddingTop: 3}}>
                                    <svg t="1703570850237" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5431" width="14" height="14"><path d="M514.5 477.3c-63.3 0-122.9-24.7-167.7-69.5-44.8-44.8-69.5-104.4-69.5-167.7S302 117.3 346.8 72.5 451.2 3 514.5 3c63.3 0 122.9 24.7 167.7 69.5 44.8 44.8 69.5 104.4 69.5 167.7S727 363.1 682.2 407.9c-44.8 44.8-104.4 69.4-167.7 69.4z m0-394.3c-86.7 0-157.2 70.5-157.2 157.2s70.5 157.2 157.2 157.2c86.7 0 157.2-70.5 157.2-157.2S601.2 83 514.5 83zM883.6 1023c-22.1 0-40-17.9-40-40V776.2c0-88.2-71.8-160-160-160H345.4c-88.2 0-160 71.8-160 160V983c0 22.1-17.9 40-40 40s-40-17.9-40-40V776.2c0-64.1 25-124.4 70.3-169.7 45.3-45.3 105.6-70.3 169.7-70.3h338.1c64.1 0 124.4 25 169.7 70.3 45.3 45.3 70.3 105.6 70.3 169.7V983c0.1 22.1-17.8 40-39.9 40z" fill="#999999" p-id="5432"></path><path d="M285 1023c-22.1 0-40-17.9-40-40v-96c0-22.1 17.9-40 40-40s40 17.9 40 40v96c0 22.1-17.9 40-40 40zM744 1023c-22.1 0-40-17.9-40-40v-96c0-22.1 17.9-40 40-40s40 17.9 40 40v96c0 22.1-17.9 40-40 40z" fill="#999999" p-id="5433"></path></svg>
                                </span>
                                <div className="users">{item['users']}</div>
                            </Space>
                           </div> 
                        </div>
                    ))
                }
                </div>   

                <ProjectDetailPopup ref={this.detailRef} metadata={{title: "GLB"}} sourcedata={{}} />
            </div >
        )
    }
}

function DropdownCotentView({children, onClick}){
    return (
        <div className="find-project-popup-dropdown-content">
            <div className="find-project-popup-dropdown-content-main">
                {children}
            </div>
            <div className="find-project-popup-dropdown-content-footer">
                <div style={{flex: 1, marginRight: 12}}>
                    <Button block onClick={onClick}>重置</Button>
                </div>
                <div style={{flex: 1}}>
                    <Button block color="primary" onClick={onClick}>筛选</Button>
                </div>
            </div>
        </div>
    )
}

function DropdownCotentSubView({options, value, onChange, children}){
    return (
        <div className="find-project-popup-dropdown-content-sub-view">
            <SideBar 
                style={{'--font-size': '13px'}}
                value={value}
                onChange={val=> onChange && onChange(val)}
            >
                {_.map(options, item => (
                    <SideBar.Item key={item.value} title={item.label} />
                ))}
            </SideBar>
            <div className="find-project-popup-dropdown-content-sub-view-main">
                {children}
            </div>
        </div>
    )
}



export class ProjectDetailPopup extends BaseExtractPopup{
    constructor(props) {
        super(props)

        this.state = {
            _activeKey: null,
            _dropdownValue: null,
            _dataList: [...DATA,],
        }
    }

    getDestroyOnClose() {
        return true
    }

    renderBodyContent() {
        return (
            <div className="find-project-popup">
                <div className="find-project-popup-box" style={{backgroundColor: "#fff"}}>
                    <div className="find-project-popup-name">
                        <Space align="center">
                            <div>
                                <Tag className="tag-yellow">LiveDeal</Tag>
                                <Tag className="tag-yellow-full">正在变更</Tag>
                            </div>
                            <div onClick={()=> Toast.show({ content: '正在开发中' })} className="update-txt">变更记录</div>
                        </Space>

                        <div className="group">科技</div>
                    </div>
                    <div className="find-project-popup-name">
                        <div className="label-txt">
                            2023/08/01启动 ｜ 2024/03/31完结
                        </div>

                        <div className="label-txt">2023/08/01更新</div>
                    </div>

                    <div className="find-project-popup-row-around">
                        <div className="find-project-popup-row-item">
                            <div style={{fontSize: 14, color: "#1890ff"}}>格陆博科技</div>
                            <div style={{fontSize: 11.5, }}>客户</div>
                        </div>
                        <div className="find-project-popup-row-item">
                            <div style={{fontSize: 14, color: "#1890ff"}}>2条</div>
                            <div style={{fontSize: 11.5, }}>会议记录</div>
                        </div>
                        <div className="find-project-popup-row-item">
                            <div style={{fontSize: 14, color: "#1890ff"}}>1个</div>
                            <div style={{fontSize: 11.5, }}>易凯项目</div>
                        </div>
                        <div className="find-project-popup-row-item">
                            <div style={{fontSize: 14, color: "#1890ff"}}>1个</div>
                            <div style={{fontSize: 11.5, }}>投资人名单</div>
                        </div>
                    </div>

                    <div className="find-project-popup-yellow-box">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{minWidth: 20, color: "#d48806"}}><ExclamationCircleFill /></div>
                            <div style={{color: "#d48806", fontSize: "11.5px"}}>变更项目阶段需先填写项目完结信息并提交，等待项目的审批负责人审批通过</div>
                        </div>
                        <div className="find-project-popup-row-around">
                            <div className="find-project-popup-row-item" style={{padding: "0", backgroundColor: "transparent"}}>
                                <div style={{color: "#999", fontSize: "11.5px"}}>
                                    <CheckCircleOutline /> 公司概况
                                </div>
                            </div>
                            <div className="find-project-popup-row-item" style={{padding: "0", backgroundColor: "transparent"}}>
                            <div style={{color: "#999", fontSize: "11.5px"}}>
                                    <CheckCircleOutline /> 执行资料
                                </div>
                            </div>
                            <div className="find-project-popup-row-item" style={{padding: "0", backgroundColor: "transparent"}}>
                                <div style={{color: "#999", fontSize: "11.5px"}} >
                                    <CheckCircleOutline style={{color: "#389e0d"}}/> 法律文件
                                </div>
                            </div>
                            
                        </div>
                        <Button onClick={()=> Toast.show({ content: '正在开发中' })} block color="primary" size="small" style={{fontSize: "11.5px"}}>填写项目完结信息-待提交</Button>
                    </div>

                    <div className="find-project-popup-more">
                        <Space>
                            <div style={{fontSize: 11.5, color: "#1890ff"}}>更多</div>
                            <DownOutline style={{color: "#1890ff"}}/>
                        </Space>
                    </div>
                </div>

                <div className="find-project-popup-box" style={{backgroundColor: "#fff", padding: 0, marginTop: 8, flex: 1}}>
                    <Tabs style={{'--title-font-size': '14px'}}>
                        <Tabs.Tab title='BD投资人名单' key='1'>
                            <div className="find-project-popup-box-item">
                                BD投资人名单
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab title='立项' key='2'>
                            <div  className="find-project-popup-box-item">
                                立项
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab title='合同' key='3'>
                        <div  className="find-project-popup-box-item">
                        合同
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab title='营销文件' key='4'>
                        <div  className="find-project-popup-box-item">
                        营销文件
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab title='执行' key='5'>
                        <div  className="find-project-popup-box-item">
                        执行
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab title='财务' key='6' disabled={true}></Tabs.Tab>
                    </Tabs>
                </div>
            </div>
        )
    }

    renderFootContent() {
        return (
            <div className="find-project-popup-footer">
                <div onClick={()=> Toast.show({ content: '正在开发中' })} style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <EditSOutline style={{fontSize: 16}}/>
                    <div>编辑项目</div>
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Button onClick={()=> Toast.show({ content: '正在开发中' })} color="primary" style={{fontSize: "11.5px", height: "39.8px", borderRadius: "25px"}}>项目Tracking</Button>
                </div>
                <div style={{flex: 1}}></div>
            </div>
        )
    }
}