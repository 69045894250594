import React, { Fragment, createRef } from "react";
import { List, Button, TextArea, Ellipsis, Toast } from "antd-mobile";
import BaseExtractValuePopup from "../../BaseExtractValuePopup";
import CECSelectorListItem from "../../../../../../components/SelectorListItem";
import CECSelectIndustryPopup from "../../../../../../components/SelectIndustryPopup";
import DeleteAndSaveButton from "../../../../../../components/CompositeButton";
import _ from 'lodash';
import {
  OTHER_RELATION_OPTIONS,
  OTHER_RELATION_OPTIONS_TRUE,
  ConvertIndustryToLabels,
  FetchElementList,
  FetchElementLevelList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 是否有投资需求
 */
export default class InvestmentNeedsPopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props)

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.state = {
      _info: info,
      _investmentWayOptions: [],
      _carrierOptions: [],
      _categoryOptions: [],
      _considerationsOptions: [],
      _ecIndustryOptions: [],
      _areaOptions: [],
      _currentStatusOptions: [],
    }
    this.ecIndustrySelectPopupRef = createRef()
  }
  componentDidMount() {
    this.fetchCompanyIndustryList()
    this.fetchCarrierList()
    this.fetchCategoryList()
    this.fetchConsiderationsList()
    this.fetchInvestmentWayList()
    this.fetchMarketAreaList()
    this.fetchCurrentStatusList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick", key, data, event)
    const { _info } = this.state
    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  async fetchCompanyIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 投资载体
  async fetchCarrierList() {
    try {
      const options = await FetchElementList("Lable_smartx_invest_vehicle")
      this.setState({ _carrierOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 投资方式
  async fetchInvestmentWayList() {
    try {
      const options = await FetchElementList("Lable_smartx_invest_way")
      this.setState({ _investmentWayOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 资产类别
  async fetchCategoryList() {
    try {
      const options = await FetchElementList("Lable_smartx_asset_type")
      this.setState({ _categoryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 投资标的属性
  async fetchConsiderationsList() {
    try {
      const options = await FetchElementList("Lable_smartx_invest_attribute")
      this.setState({ _considerationsOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 目标地理市场
  async fetchMarketAreaList() {
    try {
      const options = await FetchElementList("Lable_smartx_location")
      this.setState({ _areaOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 当前状态
  async fetchCurrentStatusList() {
    try {
      const options = await FetchElementList("Lable_smartx_now_attitude")
      this.setState({ _currentStatusOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _investmentWayOptions,
      _carrierOptions,
      _categoryOptions,
      _considerationsOptions,
      _ecIndustryOptions,
      _areaOptions,
      _currentStatusOptions
    } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label='投资载体'
            columns={2}
            multiple={true}
            options={_carrierOptions}
            value={_info['carrier'] ? _.map(_info['carrier'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'carrier', options)}
          />

          <CECSelectorListItem label='投资方式'
            columns={2}
            multiple={true}
            options={_investmentWayOptions}
            value={_info['investmentWay'] ? _.map(_info['investmentWay'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'investmentWay', options)}
          />

          <CECSelectorListItem label='资产类别'
            columns={2}
            multiple={true}
            options={_categoryOptions}
            value={_info['category'] ? _.map(_info['category'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'category', options)}
          />

          <CECSelectorListItem label='投资标的属性'
            columns={2}
            multiple={true}
            options={_considerationsOptions}
            value={_info['considerations'] ? _.map(_info['considerations'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'considerations', options)}
          />

          <List.Item extra={<Ellipsis content={_info['industry'] ? ConvertIndustryToLabels(_info['industry']) : "请选择"} />} onClick={() => this.ecIndustrySelectPopupRef?.current?.show()}>目标产业及赛道</List.Item>

          <CECSelectorListItem label='目标地理市场'
            multiple={true}
            options={_areaOptions}
            value={_info['marketArea'] ? _.map(_info['marketArea'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'marketArea', options)}
          />

          <CECSelectorListItem label='当前状态'
            options={_currentStatusOptions}
            value={_info['currentStatus'] ? _.map(_info['currentStatus'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'currentStatus', options)}
          />

          <CECSelectorListItem label='其它相关需求'
            columns={2}
            options={OTHER_RELATION_OPTIONS}
            value={_info['other'] ? _.map(_info['other'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'other', options)}
          />
          {
            _.map(_info['other'], 'value').includes(OTHER_RELATION_OPTIONS_TRUE) && <List.Item>
              <TextArea rows={5} value={_info['otherText']} onChange={this.handleChange.bind(this, 'change_value', 'otherText')} placeholder="请补充其它相关需求" />
            </List.Item>
          }
        </List>

        <CECSelectIndustryPopup
          ref={this.ecIndustrySelectPopupRef}
          title="目标产业及赛道"
          multiple={true}
          values={_info['industry']}
          options={_ecIndustryOptions}
          onConfirm={options => this.handleChange('change_value', 'industry', options)}
        />
      </Fragment>
    )
  }
  renderFootContent() {
    const { _info } = this.state
    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}