import React, { createRef, Fragment, PureComponent } from "react";
import _ from "lodash";
import { List, Toast } from "antd-mobile";
import UploadFile from "../../../../../../components/UploadFile";
import Required from "../../../../../../components/Required";
import CECSelectPopup from "../../../../../../components/SelectPopup";
import {
  FetchTargetList,
} from "../../../transform";

/**
 * 机构/公司等对象携带附件
 */
export default class TargetUploadFile extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      _targetCategorys: props.targetCategorys ? props.targetCategorys : ['company', 'investment', 'LP'],
      _target: null,
      _files: [],
    }

    this.targetSelectPopupRef = createRef()
  }

  fetchTargetList = async () => {
    const { _targetCategorys } = this.state
    Toast.show({ content: '加载中...', icon: 'loading', duration: 0, maskStyle: { zIndex: 1500 } })
    await FetchTargetList(_targetCategorys,
      (datas) => {
        Toast.clear()
        this.setState({ _targetOptions: datas })
      })
  }

  render() {
    const {
      _targetOptions,
      _target,
      _files,
    } = this.state
    const {
      idKey,
      onConfirm
    } = this.props
    return (
      <Fragment>
        <List style={{ '--border-top': 'none' }}>
          <List.Item extra={_target ? _target['label'] : "请选择"} onClick={() => { this.fetchTargetList(); this.targetSelectPopupRef?.current?.toggle() }}>名称<Required /></List.Item>
          <List.Item disabled={_target && !_.isEmpty(_target) ? false : true}
            description={<UploadFile multiple={false} values={_files} onChange={(values) => this.setState({ _files: values }, () => onConfirm && onConfirm(idKey, { target: _target, files: values }))} />}>请选择上传的附件<Required /></List.Item>
        </List>

        {/** 选择对象 */}
        <CECSelectPopup ref={this.targetSelectPopupRef}
          title={"选择对象"}
          supportSearch={true}
          supportAvatar={true}
          multiple={false}
          values={[]}
          options={_targetOptions}
          onConfirm={(value) => this.setState({ _target: _.head(value) }, () => onConfirm && onConfirm(idKey, { target: _.head(value), files: _files }))}
        />
      </Fragment >
    )
  }
}