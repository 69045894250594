import _ from "lodash";
import moment from "moment";
import request from "../../../../../api/HttpRequest";

export const OPTION_YES_VALUE = 'YES', OPTION_NO_VALUE = 'NO', OPTION_UNSURE_VALUE = 'UNSURE'
export const OPTIONS = [
  { label: '是', value: OPTION_YES_VALUE },
  { label: '否', value: OPTION_NO_VALUE },
  { label: '不确定', value: OPTION_UNSURE_VALUE },
]


/**
 * 保存财务数据
 * @param {*} info 
 * @returns 
 */
export async function SaveInfo(info) {

  // 收入
  const incomeData = _.head(info['incomeDatas'])
  let incomeYearDate, incomeCurrencys, incomeUnits, incomeAmounts, incomeAmountValue;
  if (_.map(info['income'], "value").includes(OPTION_YES_VALUE)) {
    incomeYearDate = _.get(incomeData, "year")
    incomeCurrencys = _.get(incomeData, "currencys")
    incomeUnits = _.get(incomeData, "units")
    incomeAmounts = _.get(incomeData, "amounts")
    incomeAmountValue = _.get(incomeData, "amountValue")
  }

  // 正向EBITDA
  const ebitdaData = _.head(info['ebitdaDatas'])
  let ebitdaYearDate, ebitdaCurrencys, ebitdaUnits, ebitdaAmounts, ebitdaAmountValue;
  if (_.map(info['ebitda'], "value").includes(OPTION_YES_VALUE)) {
    ebitdaYearDate = _.get(ebitdaData, "year")
    ebitdaCurrencys = _.get(incomeData, "currencys")
    ebitdaUnits = _.get(incomeData, "units")
    ebitdaAmounts = _.get(incomeData, "amounts")
    ebitdaAmountValue = _.get(incomeData, "amountValue")
  }

  // 利润
  const profitData = _.head(info['profitDatas'])
  let profitYearDate, profitCurrencys, profitUnits, profitAmounts, profitAmountValue;
  if (_.map(info['profit'], "value").includes(OPTION_YES_VALUE)) {
    profitYearDate = _.get(profitData, "year")
    profitCurrencys = _.get(incomeData, "currencys")
    profitUnits = _.get(incomeData, "units")
    profitAmounts = _.get(incomeData, "amounts")
    profitAmountValue = _.get(incomeData, "amountValue")
  }

  const payload = {
    id: info['id'],
    base_comp_code: info['code'],
    // 收入
    incomeflag: info['income'] ? _.join(_.map(info['income'], "value")) : null,
    incomeyear: incomeYearDate ? moment(incomeYearDate).get('year') : null,
    incomecurrency: incomeCurrencys ? _.join(_.map(incomeCurrencys, "value")) : null,
    incomeunit: incomeUnits ? _.join(_.map(incomeUnits, "value")) : null,
    incomevalue: incomeAmountValue,
    incomevalue2: incomeAmounts ? _.join(_.map(incomeAmounts, "value")) : null,
    // 正向EBITDA
    ebitdaflag: info['ebitda'] ? _.join(_.map(info['ebitda'], "value")) : null,
    ebitdayear: ebitdaYearDate ? moment(ebitdaYearDate).get('year') : null,
    ebitdacurrency: ebitdaCurrencys ? _.join(_.map(ebitdaCurrencys, "value")) : null,
    ebitdaunit: ebitdaUnits ? _.join(_.map(ebitdaUnits, "value")) : null,
    ebitdavalue: ebitdaAmountValue,
    ebitdavalue2: ebitdaAmounts ? _.join(_.map(ebitdaAmounts, "value")) : null,
    // 利润
    netflag: info['profit'] ? _.join(_.map(info['profit'], "value")) : null,
    netyear: profitYearDate ? moment(profitYearDate).get('year') : null,
    netcurrency: profitCurrencys ? _.join(_.map(profitCurrencys, "value")) : null,
    netunit: profitUnits ? _.join(_.map(profitUnits, "value")) : null,
    netvalue: profitAmountValue,
    netvalue2: profitAmounts ? _.join(_.map(profitAmounts, "value")) : null,
    // 现金流 
    cashflowflag: info['cashflow'] ? _.join(_.map(info['cashflow'], "value")) : null,
    showflag: info['id'] ? true : false,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/financial/save", payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }

}

/**
 * 删除财务数据
 */
export async function DeleteInfo(info) {
  try {
    await request("POST", "/cec-sims-server/smartx/comp/base/financial/delete", { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}