import React, { Fragment, createRef } from "react";
import { List, Button, Input, TextArea, Space, Toast, Ellipsis } from "antd-mobile";
import moment from "moment";
import _ from 'lodash';
import BaseExtractValuePopup from "../../BaseExtractValuePopup";
import CECCalendarPopup from "../../../../../../components/CalendarPopup";
import CECSelectorListItem from "../../../../../../components/SelectorListItem";
import CECSelectIndustryPopup from "../../../../../../components/SelectIndustryPopup";
import DeleteAndSaveButton from "../../../../../../components/CompositeButton";
import AttendTargetPopup from "../../AttendTargetPopup";
import ListItemGroup from "../../../../../../components/ListItemGroup";
import {
  RANGE_OPTIONS,
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_OTHER_VALUE,
  AMOUNT_UNIT_OPTIONS,
  OLD_STOCK_OUT_OPTIONS,
  OLD_STOCK_OUT_OPTIONS_TRUE,
  FA_SUPPORT_OPTIONS,
  FA_SUPPORT_OPTIONS_TRUE,
  OTHER_RELATION_OPTIONS,
  OTHER_RELATION_OPTIONS_TRUE,
  ConvertIndustryToLabels,
  FetchElementList,
  FetchElementLevelList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 是否有融资需求
 */
export default class FinancingNeedsPopup extends BaseExtractValuePopup {

  constructor(props) {
    super(props)

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.state = {
      _info: info,
      _financialScaleOptions: [],
      _ecIndustryOptions: [],
    }
    this.dateRef = createRef()
    this.ecIndustrySelectPopupRef = createRef()
    this.faTargetPopupRef = createRef()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  componentDidMount() {
    this.fetchFinancialScaleList()
    this.fetchCompanyIndustryList()
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  async fetchCompanyIndustryList() {
    try {
      const options = await FetchElementLevelList("Lable_comindu_yk2")
      this.setState({ _ecIndustryOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async fetchFinancialScaleList() {
    try {
      const options = await FetchElementList("Lable_smartx_trade_scale")
      this.setState({ _financialScaleOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _ecIndustryOptions,
      _financialScaleOptions,
    } = this.state

    return (
      <Fragment>
        <List>
          <CECSelectorListItem label="融资范围"
            columns={2}
            options={RANGE_OPTIONS}
            value={_info['range'] ? _.map(_info['range'], 'value') : null}
            onChange={(value, options) => this.handleChange('change_value', 'range', options)}
          />

          <List.Item extra={<Ellipsis content={_info['industry'] ? ConvertIndustryToLabels(_info['industry']) : "请选择"} />} onClick={() => this.ecIndustrySelectPopupRef?.current?.show()}>融资业务所处行业</List.Item>

          <ListItemGroup label="计划融资规模">
            <CECSelectorListItem
              value={_info['scaleCurrency'] ? _.map(_info['scaleCurrency'], 'value') : []}
              options={CURRENCY_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, scaleCurrency: options } })}
            >
              {_info['scaleCurrency'] && _.map(_info['scaleCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['scaleCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'scaleCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={_financialScaleOptions}
              value={_info['scale'] ? _.map(_info['scale'], 'value') : null}
              onChange={(value, options) => this.handleChange('change_value', 'scale', options)}
            >
              <Input placeholder="请输入金额" value={_info['scaleMoney'] ? _info['scaleMoney'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'scaleMoney')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <ListItemGroup label="新股目标估值">
            <CECSelectorListItem
              value={_info['nowStockCurrency'] ? _.map(_info['nowStockCurrency'], 'value') : []}
              options={CURRENCY_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, nowStockCurrency: options } })}
            >
              {_info['nowStockCurrency'] && _.map(_info['nowStockCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['nowStockCurrencyOther']} onChange={val => this.handleChange('change_value', 'nowStockCurrencyOther', val)} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              value={_info['nowStockUnit'] ? _.map(_info['nowStockUnit'], 'value') : []}
              options={AMOUNT_UNIT_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, nowStockUnit: options } })}
            />

            <List.Item extra={
              <Space>
                <Input style={{ width: 80 }} placeholder="请输入" value={_info['nowStockStart'] ? _info['nowStockStart'] : ''} onChange={val => this.handleChange('change_value', 'nowStockStart', val)} />
                <span>-</span>
                <Input style={{ width: 80 }} placeholder="请输入" value={_info['nowStockEnd'] ? _info['nowStockEnd'] : ''} onChange={val => this.handleChange('change_value', 'nowStockEnd', val)} />
              </Space>
            }>估值（区间）</List.Item>
          </ListItemGroup>

          <CECSelectorListItem label="是否有老股退出需求"
            columns={2}
            options={OLD_STOCK_OUT_OPTIONS}
            value={_info['oldStockOut'] ? _.map(_info['oldStockOut'], 'value') : null}
            onChange={(value, options) => this.handleChange('change_value', 'oldStockOut', options)}
          />

          <ListItemGroup label="老股目标估值">
            <CECSelectorListItem
              value={_info['oldStockUnit'] ? _.map(_info['oldStockUnit'], 'value') : []}
              options={AMOUNT_UNIT_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, oldStockUnit: options } })}
            />

            <CECSelectorListItem
              value={_info['oldStockCurrency'] ? _.map(_info['oldStockCurrency'], 'value') : []}
              options={CURRENCY_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, oldStockCurrency: options } })}
            >
              {_info['oldStockCurrency'] && _.map(_info['oldStockCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['oldStockCurrencyOther']} onChange={val => this.handleChange('change_value', 'oldStockCurrencyOther', val)} />}
            </CECSelectorListItem>

            <List.Item extra={
              <Space>
                <Input style={{ width: 80 }} placeholder="请输入" value={_info['oldStockStart'] ? _info['oldStockStart'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'oldStockStart')} />
                <span>-</span>
                <Input style={{ width: 80 }} placeholder="请输入" value={_info['oldStockEnd'] ? _info['oldStockEnd'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'oldStockEnd')} />
              </Space>
            }>估值（区间）</List.Item>
          </ListItemGroup>

          <List.Item extra={_info['beginDate'] ? moment(_info['beginDate']).format('YYYY/MM') : "请选择"} onClick={() => this.dateRef?.current?.toggle()}>目标启动时间</List.Item>

          <CECSelectorListItem label="是否已有FA"
            columns={2}
            options={FA_SUPPORT_OPTIONS}
            value={_info['FA'] ? _.map(_info['FA'], 'value') : null}
            onChange={(val, options) => this.handleChange('change_value', 'FA', options)}
          />
          {
            _info['FA'] && _.map(_info['FA'], 'value').includes(FA_SUPPORT_OPTIONS_TRUE) && (
              <List.Item extra={<Ellipsis content={_info['FAList'] ? _.join(_.map(_info['FAList'], "label")) : "请选择"} />} onClick={() => this.faTargetPopupRef.current.toggle()}>FA</List.Item>
            )
          }

          <CECSelectorListItem label="其它相关需求"
            columns={2}
            options={OTHER_RELATION_OPTIONS}
            value={_info['other'] ? _.map(_info['other'], 'value') : null}
            onChange={(val, options) => this.handleChange('change_value', 'other', options)}
          />
          {
            _.map(_info['other'], 'value').includes(OTHER_RELATION_OPTIONS_TRUE) && <List.Item>
              <TextArea rows={5} value={_info['otherText']} onChange={this.handleChange.bind(this, 'change_value', 'otherText')} placeholder="请补充其它相关需求" />
            </List.Item>
          }
        </List>

        <CECCalendarPopup
          ref={this.dateRef}
          precision='month'
          metadata={{ title: "目标启动时间" }}
          value={_info['beginDate'] ? _info['beginDate'] : moment().toDate()}
          onConfirm={this.handleChange.bind(this, 'change_value', 'beginDate')}
        />

        <CECSelectIndustryPopup
          ref={this.ecIndustrySelectPopupRef}
          title="融资业务所处行业"
          multiple={true}
          options={_ecIndustryOptions}
          values={_info['industry']}
          onConfirm={options => this.handleChange('change_value', 'industry', options)}
        />

        <AttendTargetPopup ref={this.faTargetPopupRef}
          mode={"target"}
          targetCategorys={['investment']}
          targetMultiple={true}
          metadata={{ title: 'FA' }}
          value={{ targets: _info['FAList'] }}
          onConfirm={(data) => this.handleChange('change_value', 'FAList', data['targets'])}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}

