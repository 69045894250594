import React, { Fragment, PureComponent } from "react";
import { InfiniteScroll, DotLoading } from 'antd-mobile'

/**
 * 无限加载
 */
export default class CECInfiniteScroll extends PureComponent {
  render() {
    let { hasMore, threshold, onLoadMore } = this.props

    let content = (
      <InfiniteScroll threshold={threshold ? threshold : 100} loadMore={onLoadMore} hasMore={hasMore}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    )
    return content
  }
}

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <Fragment>
      {
        hasMore ? (
          <Fragment>
            <span>Loading</span>
            <DotLoading />
          </Fragment>
        ) : (
          // <span>--- 加载完成 ---</span>
          <span></span>
        )
      }
    </Fragment>
  )
}