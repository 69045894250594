import React from "react"
import _ from "lodash"
import request from "../../../api/HttpRequest"
import { Space, Tag } from "antd-mobile"

/**
 * 获取公司/机构/LP/企业列表
 * @param {*} searchKey 
 * @param {*} type ['comgplp', 'company', 'investment', 'LP'
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
export async function FetchTargetList({ searchKey, types, pageNum = 1, pageSize = 20 }) {
  const params = {
    input: searchKey,
    base_types: types ? _.join(types) : '',
    pageNum: pageNum < 1 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/comgplp/search", params)
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, (item, index) => {

        const categorys = []
        const categoryLabels = []
        if (item['iscomp'] === 1) {
          categorys.push('company')
          categoryLabels.push("企业(产业方)")
        }
        if (item['isgp'] === 1) {
          categorys.push('investment')
          categoryLabels.push("市场化机构")
        }
        if (item['islp'] === 1) {
          categorys.push('lp')
          categoryLabels.push("政府/家办及个人/其他")
        }
        const typeLabels = item['type_name'] ? _.split(_.replace(item['type_name'], /，/g, ","), ',') : []
        const industryLabels = item['cat_yk_name'] ? _.split(_.replace(item['cat_yk_name'], /，/g, ","), ',') : []

        return {
          label: item['base_name'],
          value: item['base_code'],
          avatar: item['base_logo'],
          categorys: categorys,
          categoryLabels: categoryLabels,
          companyCode: item['main_comp_code'],
          gpCode: item['main_gp_code'],
          lpCode: item['main_lp_code'],
          titleExtension: (
            <Space wrap>
              {
                item['location_name'] && <span style={{ color: '#999', fontSize: '12px' }}>{item['location_name']}</span>
              }
              {
                typeLabels.map(label => <Tag style={{ '--border-color': '#87e8de', '--text-color': '#08979c', '--background-color': '#e6fffb', '--font-size': '12px' }}>{label}</Tag>)
              }
            </Space>
          ),
          description: (
            <Space wrap>
              {
                categoryLabels.map(label => <Tag color="primary">{label}</Tag>)
              }
              {
                industryLabels.map(label => <Tag color="primary">{label}</Tag>)
              }
            </Space>
          ),
        }
      })
    }
    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    // throw error
  }
}

/**
 * 查询公司、机构、LP相关联系人
 * @param {*} type 
 * @param {*} targetValue 
 * @returns 
 */
export async function FetchTargetUserCallbackList(type, targetValue, successCallback, errorCallback) {
  let array = []
  await FetchTargetUserList(type, targetValue)
    .then((datas) => {
      if (datas && _.size(datas) > 0) {
        array = _.map(datas, item => ({ label: item['name'], value: item['code'], avatar: null, description: item['posiname'], category: type, selected: false }))
      }
    })
  successCallback(array)
}
export async function FetchTargetUserList(type, targetValue) {
  return request(
    "POST",
    "/cec-sims-server/common/relatesearch",
    {
      input: '',
      types: type,
      base_code: targetValue,
      pageNum: 1,
      pageSize: 20,
    }
  )
}

/**
 * 查询CEC用户列表
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
export async function FetchCECUserCallbackList(status = 'normal', successCallback, errorCallback) {
  let array = []
  await FetchCECUserList()
    .then((datas) => {
      if (datas && _.size(datas) > 0) {
        array = _.map(_.filter(datas, { 'status': status }), item => ({ label: item['fullname'], value: item['id'], avatar: item['headpic'], description: item['jobLevel'], category: null, selected: false }))
        const jobLevelOrder = ["Founding Partner", "Partner", "MD", "ED", "VP", "SrAssociate", "Associate", "Analyst 2", "SrManager", "Manager", "高级合伙人助理", "Deputy Director", "Director of IR, Changjian", "SrDirector", "Director", "Director, Productions", "IBD财务负责人", "基金财务经理", "Cashier", "HRD", "Director, IT", "影响力中心总经理", "HR Manager", "Senior Recruiter", "Senior Data Engineer", "高级系统构架师", "前端开发工程师", "行政助理", "前台", "视觉设计师"];
        const userIDOrder = [53, 26, 22, 295, 37, 45,]
        array = _.sortBy(_.map(_.filter(array, item => item['value'] !== 50), item => ({ ...item, "category": 'user', "jobLevelIndex": jobLevelOrder.indexOf(item['description']), "valueIndex": userIDOrder.indexOf(item['value']) })), "jobLevelIndex", "valueIndex")
      }
    })
  successCallback(array)
}
export async function FetchCECUserList() {
  return request(
    "POST",
    "/cec-sso-server/user/search",
    {
      input: '',
    }
  )
}

/**
 * 保存对象联系人
 * @param {*} data 
 * @param {*} successCallback 
 * @param {*} failCallback 
 */
export async function SaveTargetUserInfo(data, successCallback, failCallback) {
  if (data['category'] === 'company') {
    const payloadData = {
      base_comp_code: data['targetValue'],
      base_entrepreneur_name: data['name'],
      base_entrepreneur_ename: data['ENName'],
      base_entrepreneur_posiname: data['jobLevel'],
      base_entrepreneur_phone: data['mobile'],
      base_entrepreneur_email: data['email'],
      base_entrepreneur_wechat: data['wechat'],
    }
    await request("POST", "/cec-sims-server/company/saveentrepreneur", payloadData)
      .then((response) => {
        let responseBody = null
        if (response) {
          responseBody = {
            label: _.get(response, 'base_entrepreneur_name'),
            value: _.get(response, 'base_entrepreneur_code'),
            category: data['category'],
            selected: true,
          }
        }
        successCallback && successCallback(responseBody)
      })
      .catch((error) => failCallback && failCallback(error))
  } else if (data['category'] === 'investor') {
    const payloadData = {
      base_investment_code: data['targetValue'],
      base_investor_name: data['name'],
      base_investor_ename: data['ENName'],
      base_investor_posiname: data['jobLevel'],
      base_investor_phone: data['mobile'],
      base_investor_email: data['email'],
      base_investor_wechat: data['wechat'],
    }
    await request("POST", "/cec-sims-server/investor/save", payloadData)
      .then((response) => {
        let responseBody = null
        if (response) {
          responseBody = {
            label: _.get(response, 'base_investor_name'),
            value: _.get(response, 'base_investor_code'),
            category: data['category'],
            selected: true,
          }
        }
        successCallback && successCallback(responseBody)
      })
      .catch((error) => failCallback && failCallback(error))
  } else if (data['category'] === 'lp') {
    const payloadData = {
      base_lp_code: data['targetValue'],
      base_lpuser_name: data['name'],
      base_lpuser_ename: data['ENName'],
      base_lpuser_posiname: data['jobLevel'],
      base_lpuser_phone: data['mobile'],
      base_lpuser_email: data['email'],
      base_lpuser_wechat: data['wechat'],
    }
    await request("POST", "/cec-sims-server/lp/savereleuser", payloadData)
      .then((response) => {
        let responseBody = null
        if (response) {
          responseBody = {
            label: _.get(response, 'base_lpuser_name'),
            value: _.get(response, 'base_lpuser_code'),
            category: data['category'],
            selected: true,
          }
        }
        successCallback && successCallback(responseBody)
      })
      .catch((error) => failCallback && failCallback(error))
  }

}