import { createRef } from "react";
import _ from "lodash";
import {
  FinancingNeedsPopup,
  SaleRequirementPopup,
  MergerAndAcquisitionNeedsPopup,
  SplitAndRestructureNeedsPopup,
  CapitalRaisingRequirementPopup,
  InvestmentNeedsPopup,
  InvestmentExitNeeds4Fund,
  InvestmentExitNeeds4Deal,
} from './components/TradingOpportunity'

// 交易机会（企业方）
export const TRADING_OPPORTUNITY_FOR_ENTERPRISE_OPTIONS = [
  {
    label: '是否有融资需求',
    key: 'info_enterprise_trading_opportunity_financing_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "COM_TRADE_CHANCE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FinancingNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="trade" onConfirm={onConfirm} />
  }, {
    label: '是否有出售需求',
    key: 'info_enterprise_trading_opportunity_sale_requirement',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "COM_TRADE_CHANCE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <SaleRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="sell" onConfirm={onConfirm} />,
  }, {
    label: '是否有并购整合需求',
    key: 'info_enterprise_trading_opportunity_merger_and_acquisition_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "COM_TRADE_CHANCE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <MergerAndAcquisitionNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="merge" onConfirm={onConfirm} />,
  }, {
    label: '是否有分拆/重组需求',
    key: 'info_enterprise_trading_opportunity_split_and_restructure_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "COM_TRADE_CHANCE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <SplitAndRestructureNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="spinoff" onConfirm={onConfirm} />,
  },
  {
    label: '是否有投资需求',
    key: 'info_enterprise_trading_opportunity_investment_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "COM_TRADE_CHANCE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="invest" onConfirm={onConfirm} />,
  }, {
    label: '是否有募资需求',
    key: 'info_enterprise_trading_opportunity_capital_raising_requirement',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "COM_TRADE_CHANCE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <CapitalRaisingRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundraising" onConfirm={onConfirm} />,
  }, {
    label: '是否有投退需求',
    key: 'info_enterprise_trading_opportunity_investment_and_exit_needs',
    children: [
      {
        label: '公司投退',
        key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company',
        children: [
          {
            label: '项目投退（公司）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_deal',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_TRADE_CHANCE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_com" onConfirm={onConfirm} />,
          }, {
            label: '基金投退（公司）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_fund',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_TRADE_CHANCE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_com" onConfirm={onConfirm} />,
          },
        ]
      }, {
        label: '股东/LP投退',
        key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp',
        children: [
          {
            label: '项目投退（股东/LP）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_deal',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_TRADE_CHANCE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_lp" onConfirm={onConfirm} />,
          }, {
            label: '基金投退（股东/LP）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_fund',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "COM_TRADE_CHANCE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_lp" onConfirm={onConfirm} />,
          },
        ]
      }
    ]
  }
]

// 交易机会（机构）
export const TRADING_OPPORTUNITY_FOR_ORG_OPTIONS = [
  {
    label: '是否有融资需求',
    key: 'info_enterprise_trading_opportunity_financing_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "INVESTMENT_TRADE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FinancingNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="trade" onConfirm={onConfirm} />
  }, {
    label: '是否有出售需求',
    key: 'info_enterprise_trading_opportunity_sale_requirement',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "INVESTMENT_TRADE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <SaleRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="sell" onConfirm={onConfirm} />,
  }, {
    label: '是否有并购整合需求',
    key: 'info_enterprise_trading_opportunity_merger_and_acquisition_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "INVESTMENT_TRADE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <MergerAndAcquisitionNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="merge" onConfirm={onConfirm} />,
  }, {
    label: '是否有分拆/重组需求',
    key: 'info_enterprise_trading_opportunity_split_and_restructure_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "INVESTMENT_TRADE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <SplitAndRestructureNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="spinoff" onConfirm={onConfirm} />,
  },
  {
    label: '是否有投资需求',
    key: 'info_enterprise_trading_opportunity_investment_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "INVESTMENT_TRADE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="invest_child" onConfirm={onConfirm} />,
  }, {
    label: '是否有募资需求',
    key: 'info_enterprise_trading_opportunity_capital_raising_requirement',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    dbTypeKey: "INVESTMENT_TRADE",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <CapitalRaisingRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundraising_child" onConfirm={onConfirm} />,
  }, {
    label: '是否有投退需求',
    key: 'info_enterprise_trading_opportunity_investment_and_exit_needs',
    children: [
      {
        label: '公司投退',
        key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company',
        children: [
          {
            label: '项目投退（公司）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_deal',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_com_child" onConfirm={onConfirm} />,
          }, {
            label: '基金投退（公司）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_fund',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_com_child" onConfirm={onConfirm} />,
          },
        ]
      }, {
        label: '股东/LP投退',
        key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp',
        children: [
          {
            label: '项目投退（股东/LP）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_deal',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_lp_child" onConfirm={onConfirm} />,
          }, {
            label: '基金投退（股东/LP）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_fund',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            dbTypeKey: "INVESTMENT_TRADE",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_lp_child" onConfirm={onConfirm} />,
          },
        ]
      }
    ]
  }
]

// 机构
export const TRADING_OPPORTUNITY_ORG_OPTIONS = [
  {
    label: '机构/基金',
    key: 'info_market_trading_org_opportunity',
    children: [
      {
        label: '是否有投资需求',
        key: 'info_org_trading_opportunity_investment_needs',
        ref: createRef(),
        targetRefKey: "info_option_list_attend_target",
        dbTypeKey: "INVESTMENT_TRADE",
        componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="invest" onConfirm={onConfirm} />,
      }, {
        label: '是否有募资需求',
        key: 'info_org_trading_opportunity_capital_raising_requirement',
        ref: createRef(),
        targetRefKey: "info_option_list_attend_target",
        dbTypeKey: "INVESTMENT_TRADE",
        componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <CapitalRaisingRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundraising" onConfirm={onConfirm} />,
      }, {
        label: '是否有投退需求',
        key: 'info_org_trading_opportunity_investment_and_exit_needs',
        children: [
          {
            label: '公司投退',
            key: 'info_org_trading_opportunity_investment_and_exit_needs_for_company',
            children: [
              {
                label: '项目投退（公司）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_company_deal',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "INVESTMENT_TRADE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_com" onConfirm={onConfirm} />,
              }, {
                label: '基金投退（公司）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_company_fund',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "INVESTMENT_TRADE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_com" onConfirm={onConfirm} />,
              },
            ]
          }, {
            label: '股东/LP投退',
            key: 'info_org_trading_opportunity_investment_and_exit_needs_for_lp',
            children: [
              {
                label: '项目投退（股东/LP）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_lp_deal',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "INVESTMENT_TRADE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_lp" onConfirm={onConfirm} />,
              }, {
                label: '基金投退（股东/LP）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_lp_fund',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "INVESTMENT_TRADE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_lp" onConfirm={onConfirm} />,
              },
            ]
          }
        ]
      }
    ]
  }, {
    label: '被投公司',
    key: 'info_market_trading_enterprise_opportunity',
    children: [...TRADING_OPPORTUNITY_FOR_ORG_OPTIONS]
  },
]




// 交易机会（家办）
export const TRADING_OPPORTUNITY_FOR_OFFICE_OPTIONS = [
  {
    label: '是否有融资需求',
    key: 'info_enterprise_trading_opportunity_financing_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <FinancingNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="trade" onConfirm={onConfirm} />
  }, {
    label: '是否有出售需求',
    key: 'infoenterprise_trading_opportunity_sale_requirement',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <SaleRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="sell" onConfirm={onConfirm} />,
  }, {
    label: '是否有并购整合需求',
    key: 'info_enterprise_trading_opportunity_merger_and_acquisition_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <MergerAndAcquisitionNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="merge" onConfirm={onConfirm} />,
  }, {
    label: '是否有分拆/重组需求',
    key: 'info_enterprise_trading_opportunity_split_and_restructure_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <SplitAndRestructureNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="spinoff" onConfirm={onConfirm} />,
  },
  {
    label: '是否有投资需求',
    key: 'info_enterprise_trading_opportunity_investment_needs',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="invest_child" onConfirm={onConfirm} />,
  }, {
    label: '是否有募资需求',
    key: 'info_enterprise_trading_opportunity_capital_raising_requirement',
    ref: createRef(),
    targetRefKey: "info_option_list_attend_target",
    componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <CapitalRaisingRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundraising_child" onConfirm={onConfirm} />,
  }, {
    label: '是否有投退需求',
    key: 'info_enterprise_trading_opportunity_investment_and_exit_needs',
    children: [
      {
        label: '公司投退',
        key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company',
        children: [
          {
            label: '项目投退（公司）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_deal',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_com_child" onConfirm={onConfirm} />,
          }, {
            label: '基金投退（公司）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_company_fund',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_com_child" onConfirm={onConfirm} />,
          },
        ]
      }, {
        label: '股东/LP投退',
        key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp',
        children: [
          {
            label: '项目投退（股东/LP）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_deal',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_lp_child" onConfirm={onConfirm} />,
          }, {
            label: '基金投退（股东/LP）',
            key: 'info_enterprise_trading_opportunity_investment_and_exit_needs_for_lp_fund',
            ref: createRef(),
            targetRefKey: "info_option_list_attend_target",
            componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_lp_child" onConfirm={onConfirm} />,
          },
        ]
      }
    ]
  }
]

// 家办
export const TRADING_OPPORTUNITY_OFFICE_OPTIONS = [
  {
    label: '机构/基金',
    key: 'info_market_trading_org_opportunity',
    children: [
      {
        label: '是否有投资需求',
        key: 'info_org_trading_opportunity_investment_needs',
        ref: createRef(),
        targetRefKey: "info_option_list_attend_target",
        dbTypeKey: "LP_GOV_FAMILY_TRADE_CHANCE",
        componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentNeedsPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="invest" onConfirm={onConfirm} />,
      }, {
        label: '是否有募资需求',
        key: 'info_org_trading_opportunity_capital_raising_requirement',
        ref: createRef(),
        targetRefKey: "info_option_list_attend_target",
        dbTypeKey: "LP_GOV_FAMILY_TRADE_CHANCE",
        componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <CapitalRaisingRequirementPopup key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundraising" onConfirm={onConfirm} />,
      }, {
        label: '是否有投退需求',
        key: 'info_org_trading_opportunity_investment_and_exit_needs',
        children: [
          {
            label: '公司投退',
            key: 'info_org_trading_opportunity_investment_and_exit_needs_for_company',
            children: [
              {
                label: '项目投退（公司）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_company_deal',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "LP_GOV_FAMILY_TRADE_CHANCE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_com" onConfirm={onConfirm} />,
              }, {
                label: '基金投退（公司）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_company_fund',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "LP_GOV_FAMILY_TRADE_CHANCE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_com" onConfirm={onConfirm} />,
              },
            ]
          }, {
            label: '股东/LP投退',
            key: 'info_org_trading_opportunity_investment_and_exit_needs_for_lp',
            children: [
              {
                label: '项目投退（股东/LP）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_lp_deal',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "LP_GOV_FAMILY_TRADE_CHANCE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Deal key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="projectexit_lp" onConfirm={onConfirm} />,
              }, {
                label: '基金投退（股东/LP）',
                key: 'info_org_trading_opportunity_investment_and_exit_needs_for_lp_fund',
                ref: createRef(),
                targetRefKey: "info_option_list_attend_target",
                dbTypeKey: "LP_GOV_FAMILY_TRADE_CHANCE",
                componentRender: ({ metadata, sourcedata, extensionValue, onConfirm }) => <InvestmentExitNeeds4Fund key={metadata['key']} ref={metadata.ref} metadata={metadata} sourcedata={sourcedata} value={_.get(extensionValue, metadata['key'])} extensionValue={extensionValue} type="fundexit_lp" onConfirm={onConfirm} />,
              },
            ]
          }
        ]
      }
    ]
  }, {
    label: '被投公司',
    key: 'info_market_trading_enterprise_opportunity',
    children: [...TRADING_OPPORTUNITY_FOR_OFFICE_OPTIONS]
  },
]



// 轮次
const BASE_ROUNDS_OPTIONS = [
  {
    "value": "Lable-trastage-006", "label": "种子轮",
  },
  {
    "value": "Lable-trastage-002", "label": "天使轮",
  },
  {
    "value": "Lable-trastage-037", "label": "天使+",
  },
  {
    "value": "Lable-trastage-004", "label": "Pre-A轮",
  },
  {
    "value": "Lable-trastage-001", "label": "A轮",
  },
  {
    "value": "Lable-trastage-013", "label": "A+轮",
  },
  {
    "value": "Lable-trastage-017", "label": "Pre-B轮",
  },
  {
    "value": "Lable-trastage-003", "label": "B轮",
  },
  {
    "value": "Lable-trastage-015", "label": "B+轮",
  },
  {
    "value": "Lable-trastage-035", "label": "B++轮",
  },
  {
    "value": "Lable-trastage-007", "label": "C轮",
  },
  {
    "value": "Lable-trastage-019", "label": "C+轮",
  },
  {
    "value": "Lable-trastage-036", "label": "C++轮",
  },
  {
    "value": "Lable-trastage-010", "label": "D轮",
  },
  {
    "value": "Lable-trastage-020", "label": "D+轮",
  },
  {
    "value": "Lable-trastage-038", "label": "D++轮",
  },
  {
    "value": "Lable-trastage-014", "label": "E轮",
  },
  {
    "value": "Lable-trastage-024", "label": "E+轮",
  },
  {
    "value": "Lable-trastage-008", "label": "F轮",
  },
  {
    "value": "Lable-trastage-030", "label": "F+轮",
  },
  {
    "value": "Lable-trastage-031", "label": "G轮",
  },
  {
    "value": "Lable-trastage-032", "label": "H轮",
  },
  {
    "value": "Lable-trastage-009", "label": "新三板",
  },
  {
    "value": "Lable-trastage-021", "label": "新三板定增",
  },
  {
    "value": "Lable-trastage-012", "label": "IPO上市",
  },
  {
    "value": "Lable-trastage-011", "label": "IPO上市后",
  },
  {
    "value": "Lable-trastage-005", "label": "战略投资",
  },
  {
    "value": "Lable-trastage-018", "label": "并购",
  },
  {
    "value": "Lable-trastage-025", "label": "股权融资",
  },
  {
    "value": "Lable-trastage-026", "label": "债权融资",
  },
  {
    "value": "Lable-trastage-027", "label": "股权转让",
  },
  {
    "value": "Lable-trastage-028", "label": "战略合并",
  },
  {
    "value": "Lable-trastage-029", "label": "定向增发",
  },
  {
    "value": "Lable-trastage-033", "label": "出资设立",
  },
  {
    "value": "Lable-trastage-034", "label": "拟收购",
  },
  {
    "value": "Lable-trastage-016", "label": "不明确",
  },
  {
    "value": "Lable-trastage-023", "label": "其他类型",
  }
]
export const fetchRoundOptions = () => {
  return _.map(BASE_ROUNDS_OPTIONS, item => ({ ...item }))
}

const BASE_CURRENCY_OPTIONS = [
  {
    "value": "人民币", "label": "人民币",
  },
  {
    "value": "美元", "label": "美元",
  },
  {
    "value": "其他", "label": "其他",
  },
]
export const fetchCurrencyOptions = () => {
  return _.map(BASE_CURRENCY_OPTIONS, item => ({ ...item }))
}


const BASE_DEAL_BUSSINESS_TYPE_OPTIONS = [
  { label: '投融资', value: '投融资' },
  { label: '并购', value: '并购' },
  { label: '分拆/重组', value: '分拆/重组' },
  { label: '投退', value: '投退' },
  { label: '募资', value: '募资' },
  { label: '其他', value: '其他' },
]
export const fetchDealBussinessOptions = () => {
  return _.map(BASE_DEAL_BUSSINESS_TYPE_OPTIONS, item => ({ ...item }))
}

const BASE_AREA_OPTIONS = [
  { value: '中国大陆', label: '中国大陆 ' },
  { value: '港澳台', label: '港澳台 ' },
  { value: '东南亚', label: '东南亚' },
  { value: '日韩', label: '日韩' },
  { value: '中东', label: '中东' },
  { value: '北美', label: '北美' },
  { value: '欧洲', label: '欧洲' },
  { value: '非洲', label: '非洲' },
  { value: '海外其他', label: '海外其他' }
]
export const fetchBaseAreaOptions = () => {
  return _.map(BASE_AREA_OPTIONS, item => ({ ...item }))
}

const BASE_ECM_USER_OPTIONS = [
  {
    "label": "Maggie Qi",
    "value": 43,
    "avatar": 'http://p.qlogo.cn/bizmail/EiaIAPz321iaLlOTm2fQn68aB20gWIaiaslh9XKPFdia2xeoENJZQziapnw/0',
    "description": "MD",
    "category": "user",
    "selected": false,
  }, {
    "label": "Effie Zheng",
    "value": 134,
    "avatar": 'http://p.qlogo.cn/bizmail/HOhDiauhzDZB6dLRibzX4HzP5PP0fxtzOdqIamFde6M4kYf7eSsu5K0w/0',
    "description": "VP",
    "category": "user",
    "selected": false,
  }, {
    "label": "Freya Cui",
    "value": 326,
    "avatar": 'https://wework.qpic.cn/wwhead/duc2TvpEgSTPk74IwG7BszOze9BHyhlAMSxk5gmUBxicibRWXFtNDbaI05f6PavveI0TpicHq0Atw8/0',
    "description": "VP",
    "category": "user",
    "selected": false,
  }, {
    "label": "Sara Wang",
    "value": 142,
    "avatar": 'http://p.qpic.cn/wwhead/duc2TvpEgST9hicuyypLEKLeicqHhrvicK7YvHCsrOsUwia8xLbabyh4uR0dMzrc5vGtkMC036icJvG8/0',
    "description": "VP",
    "category": "user",
    "selected": false,
  }, {
    "label": "Wincy Weng",
    "value": 402,
    "avatar": 'https://wework.qpic.cn/bizmail/79437VyGJDRnKsm2OTNsJSdyPUvCA5sRUzVyibZ6PhK6NTn4j9F5krA/0',
    "description": "VP",
    "category": "user",
    "selected": false,
  }
]
export const fetchECMUserOptions = () => {
  return _.map(BASE_ECM_USER_OPTIONS, item => ({ ...item }))
}