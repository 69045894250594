import React, { Fragment, createRef } from "react";
import { List, Button, Input, Space, Toast } from 'antd-mobile'
import BaseExtractValuePopup from "../BaseExtractValuePopup";
import CECSelectPopup from "../../../../../components/SelectPopup";
import Required from "../../../../../components/Required";
import CECSelectorListItem from "../../../../../components/SelectorListItem";
import DeleteAndSaveButton from "../../../../../components/CompositeButton";
import CECCalendarPopup from "../../../../../components/CalendarPopup";
import AttendTargetPopup from "../AttendTargetPopup";
import ListItemGroup from "../../../../../components/ListItemGroup";
import _ from 'lodash';
import moment from 'moment';
import {
  CURRENCY_OPTIONS_OTHER_VALUE,
  CURRENCY_OPTIONS,
  AMOUNT_UNIT_OPTIONS,
  FetchElementList,
  FetchCompanyOwnerUserList,
  SaveInfo,
  DeleteInfo,
} from './transform'

/**
 * 投资案例更新
 */
export default class InvestmentCasePopup extends BaseExtractValuePopup {
  constructor(props) {
    super(props)
    const { value, type } = super.getAttendTargetValue()

    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })

    this.state = {
      _info: info,
      _roundOptions: [],
      _investmentWayOptions: [],
    }
    this.companyRef = createRef()
    this.tradingExamplePopupRef = createRef()
    this.monthRef = createRef()
    this.ownerRef = createRef()
  }
  componentDidMount() {
    this.fetchRoundList()
    this.fetchInvestmentWayList()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()

    let info = _.get(props, "value.value", {})
    info = _.assign({}, info, {
      code: value,
      targetType: type,
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  // 轮次
  async fetchRoundList() {
    try {
      let options = await FetchElementList("Lable_trastage")
      if (options) {
        options = _.filter(options, item => item['label'] !== '未定义')
      }
      this.setState({ _roundOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  // 投资方式
  async fetchInvestmentWayList() {
    try {
      let options = await FetchElementList("Lable_smartx_trade_way")
      this.setState({ _investmentWayOptions: options })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const {
      _info,
      _roundOptions,
      _investmentWayOptions,
    } = this.state

    return (
      <Fragment>
        <List>

          <List.Item extra={_info['company'] ? _.join(_.map(_info['company'], "label")) : "请选择"} onClick={() => this.companyRef?.current?.toggle()}>投资标的<Required /></List.Item>

          <CECSelectorListItem label="投资轮次"
            options={_roundOptions}
            value={_info['round'] ? _.map(_info['round'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'round', options)}
          />

          <List.Item extra={_info['date'] ? moment(_info['date']).format('YYYY/MM/DD') : "请选择"} onClick={() => this.monthRef?.current?.toggle()}>投资时间</List.Item>

          <ListItemGroup label="投资金额">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['momenyCurrency'] ? _.map(_info['momenyCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'momenyCurrency', options)}
            >
              {_info['momenyCurrency'] && _.map(_info['momenyCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['momenyCurrencyOther']} onChange={val => this.handleChange('change_value', 'momenyCurrencyOther', val)} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['momenyUnit'] ? _.map(_info['momenyUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'momenyUnit', options)}
            >
              <Input placeholder="请输入" type="number" value={_info['momenyValue'] ? _info['momenyValue'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'momenyValue')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <CECSelectorListItem label="投资方式"
            columns={4}
            multiple={true}
            options={_investmentWayOptions}
            value={_info['investmentWays'] ? _.map(_info['investmentWays'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'investmentWays', options)}
          />

          <ListItemGroup label="最新估值">
            <CECSelectorListItem
              options={CURRENCY_OPTIONS}
              value={_info['valuationCurrency'] ? _.map(_info['valuationCurrency'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'valuationCurrency', options)}
            >
              {_info['valuationCurrency'] && _.map(_info['valuationCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['valuationCurrencyOther']} onChange={val => this.handleChange('change_value', 'valuationCurrencyOther', val)} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              options={AMOUNT_UNIT_OPTIONS}
              value={_info['valuationUnit'] ? _.map(_info['valuationUnit'], 'value') : []}
              onChange={(value, options) => this.handleChange('change_value', 'valuationUnit', options)}
            >
              <Input placeholder="请输入" type="number" value={_info['valuationValue'] ? _info['valuationValue'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'valuationValue')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <List.Item extra={<Space><Input placeholder="请输入" type="number" value={_info['ratio'] ? _info['ratio'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'ratio')} /><span>%</span></Space>}>当前持股比例</List.Item>

          <List.Item extra={_info['owners'] ? _.join(_.map(_info['owners'], "label")) : "请选择"} onClick={() => this.ownerRef?.current?.toggle()}>项目主要负责人</List.Item>

        </List>

        {/** 公司 */}
        <AttendTargetPopup ref={this.companyRef}
          mode={"target"}
          metadata={{ title: '投资标的' }}
          targetCategorys={['company']}
          targetMultiple={false}
          value={{ targets: _info['company'] }}
          onConfirm={(data) => this.handleChange('change_value', 'company', data['targets'])}
        />

        {/** 项目主要负责人 */}
        <CECSelectPopup ref={this.ownerRef}
          title={'项目主要负责人'}
          supportSearch={true}
          supportAvatar={false}
          multiple={true}
          onFetchDatas={(params) => FetchCompanyOwnerUserList({ ...params, code: _info['code'] })}
          values={_info['owners']}
          onConfirm={this.handleChange.bind(this, 'change_value', 'owners')}
        />

        <CECCalendarPopup ref={this.monthRef}
          metadata={{ title: "投资时间" }}
          value={_info['date'] ? _info['date'] : moment().toDate()}
          onConfirm={val => this.handleChange('change_value', 'date', val)}
        />
      </Fragment>
    )
  }

  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}
