import React from 'react';

export default class Auth extends React.Component {
  constructor(props) {
    super(props);

    let result = props && props.match && props.match.params.profile ? props.match.params.profile : null

    let profile = null, redirect = null;
    if (result.indexOf('&') > -1) {
      const array = result.split('&');
      profile = decodeURIComponent(array[0]);
      redirect = decodeURIComponent(array[1]);
    } else {
      profile = decodeURIComponent(result);
    }

    let interval = null;
    let timeout = 60000;

    localStorage.setItem('profiletime', (new Date().getTime()));
    localStorage.setItem('profile', profile);

    interval = setInterval(function () {
      if (localStorage.getItem('profile') === profile || timeout < 0) {
        clearInterval(interval);

        if (redirect) {
          window.location.href = redirect;
        } else {
          window.location.href = '/#/';
        }
      } else {
        timeout -= 10;
      }
    }, 10);
  }

  render() {
    return (
      <div style={{ textAlign: 'center', padding: 10 }}>正在登录...</div>
    );
  }
}
