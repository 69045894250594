import React, { Fragment } from "react";
import _ from "lodash";
import { Space, Image, Toast } from 'antd-mobile'
import { EditSFill } from 'antd-mobile-icons'
import BaseExtractPopup from "../../../../../components/BaseExtractPopup";
import TargetFormEntryMenu from "../../../../../components/TargetFormEntryMenu";
import CompanyBaseInfoView from './CompanyBaseInfoVIew';
import GPBaseInfoView from "./GPBaseInfoView";
import LPBaseInfoView from "./LPBaseInfoView";
import CompanyBodyInfoView from "./CompanyBodyInfoView";
import GPBodyInfoView from "./GPBodyInfoView"
import LPBodyInfoView from "./LPBodyInfoView"
import {
  SwapRelationCode,
  FetchCompanyDetailBaseInfo,
  FetchGPDetailBaseInfo,
  FetchLPDetailBaseInfo,
} from './transform'
import './index.less';

const tabs = [
  { key: 'company', label: '企业(产业方)', selected: true, show: true, },
  { key: 'investment', label: '市场化机构', selected: false, show: true, },
  { key: 'lp', label: '政府/家办及个人/其他', selected: false, show: true, },
]
const COMPANY_INFO_TABS = [
  { key: 'company_info_tab_1', label: '策略及业务方向', selected: true, show: true },
  { key: 'company_info_tab_2', label: '财务数据', selected: false, show: true },
  { key: 'company_info_tab_3', label: '交易机会', selected: false, show: true },
  { key: 'company_info_tab_4', label: '股权结构', selected: false, show: true },
  { key: 'company_info_tab_5', label: '融资/收购历史', selected: false, show: true },
  { key: 'company_info_tab_6', label: '投资案例', selected: false, show: true },
  { key: 'company_info_tab_7', label: '人事信息', selected: false, show: true },
]
const GP_INFO_TABS = [
  { key: 'gp_info_tab_1', label: '基金属性', selected: true, show: true },
  { key: 'gp_info_tab_2', label: '基金募集', selected: false, show: true },
  { key: 'gp_info_tab_3', label: '投资方向', selected: false, show: true },
  { key: 'gp_info_tab_4', label: '交易机会', selected: false, show: true },
  { key: 'gp_info_tab_5', label: '投资案例', selected: false, show: true },
  { key: 'gp_info_tab_6', label: '人事信息', selected: false, show: true },
]
const LP_INFO_TABS = [
  { key: 'lp_info_tab_1', label: '政府-基本信息', selected: true, show: true },
  { key: 'lp_info_tab_2', label: '政府-人事', selected: false, show: true },
  { key: 'lp_info_tab_3', label: '家办及个人-基本信息', selected: false, show: true },
  { key: 'lp_info_tab_4', label: '家办及个人-投资方向', selected: false, show: true },
  { key: 'lp_info_tab_5', label: '家办及个人-交易机会', selected: false, show: true },
  { key: 'lp_info_tab_6', label: '家办及个人-投资案例', selected: false, show: true },
  { key: 'lp_info_tab_7', label: '家办及个人-人事信息', selected: false, show: true },
]


/**
 * 企业信息详情页面
 */
export default class InfoDetailPagePopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    const value = props.value ? props.value : {}
    const { tabArray, infoTabArray } = this.syncTabs(value)

    this.state = {
      _tabs: tabArray,
      _infoTabs: infoTabArray,

      // 关键信息Code、名称、Logo等信息
      _value: value,
      // 角色对象的基本信息
      _baseInfo: {},
      // 当前对象的角色信息
      _targets: [],
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const value = props.value ? props.value : {}
    const { tabArray, infoTabArray } = this.syncTabs(value)
    this.setState({ _tabs: tabArray, _infoTabs: infoTabArray, _value: value, })
  }

  async show() {
    super.show()
    const { _tabs } = this.state
    const currentActiveTab = _.get(_.head(_.filter(_.filter(_tabs, { show: true }), { selected: true })), "key")

    if (currentActiveTab === 'company') {
      this.fetchCompanyDetailBaseInfo()
    } else if (currentActiveTab === 'investment') {
      this.fetchGPDetailBaseInfo()
    } else if (currentActiveTab === 'lp') {
      this.fetchLPDetailBaseInfo()
    }
  }

  close() {
    const { onClose } = this.props
    console.log("close...........1")
    super.close()
    console.log("close...........2")
    onClose && onClose()
  }

  syncTabs(value) {
    let selectedStep = false
    let tabArray = tabs.map(item => ({ ...item, show: value['categorys'] && value['categorys'].includes(item['key']) ? true : false }))
    tabArray = _.map(tabArray, (item, index) => {
      if (item['show'] === true && !selectedStep) {
        selectedStep = true
        item['selected'] = true
      }
      return item
    })
    const currentActiveTab = _.get(_.head(_.filter(_.filter(tabArray, { show: true }), { selected: true })), "key")
    let infoTabArray = []
    if (currentActiveTab === 'company') {
      infoTabArray = [...COMPANY_INFO_TABS]
    } else if (currentActiveTab === 'investment') {
      infoTabArray = [...GP_INFO_TABS]
    } else if (currentActiveTab === 'lp') {
      infoTabArray = [...LP_INFO_TABS]
    }
    return {
      tabArray,
      infoTabArray,
    }
  }

  handleChange(key, data, event) {
    console.log('handleChange >>>>', key, data, event)
    const {
      _tabs,
      _infoTabs,
    } = this.state

    switch (key) {
      case 'change_role_tab':
        const tabs = _.map(_tabs, tab => {
          tab['selected'] = tab['key'] === data['key'] ? true : false
          return tab
        })
        let newInfoTabs = []
        if (data['key'] === 'company') {
          newInfoTabs = [...COMPANY_INFO_TABS]
        } else if (data['key'] === 'investment') {
          newInfoTabs = [...GP_INFO_TABS]
        } else if (data['key'] === 'lp') {
          newInfoTabs = [...LP_INFO_TABS]
        }
        this.setState({ _tabs: tabs, _infoTabs: newInfoTabs }, () => {
          if (data['key'] === 'company') {
            this.fetchCompanyDetailBaseInfo()
          } else if (data['key'] === 'investment') {
            this.fetchGPDetailBaseInfo()
          } else if (data['key'] === 'lp') {
            this.fetchLPDetailBaseInfo()
          }
        })
        break;
      case 'change_info_tab':
        const infoTabs = _.map(_infoTabs, tab => {
          tab['selected'] = tab['key'] === data['key'] ? true : false
          return tab
        })
        this.setState({ _infoTabs: infoTabs })
        break;
      default:
        break;
    }
  }

  async fetchCompanyDetailBaseInfo() {
    Toast.show({ icon: 'loading', duration: 0 })
    const { _value } = this.state
    try {
      const { companyCode, extensionValue } = await SwapRelationCode({ code: _value['value'], type: _value['type'] })
      if (companyCode) {
        const response = await FetchCompanyDetailBaseInfo(companyCode)
        this.setState({ _baseInfo: response, _targets: extensionValue ? [extensionValue] : [] })
      }
      Toast.clear()
    } catch (error) {
      Toast.show({ icon: 'fail', content: error })
    }
  }

  async fetchGPDetailBaseInfo() {
    Toast.show({ icon: 'loading', duration: 0 })
    const { _value } = this.state
    try {
      console.log("fetchGPDetailBaseInfo >>>", _value)
      const { gpCode, extensionValue } = await SwapRelationCode({ code: _value['value'], type: _value['type'] })
      if (gpCode) {
        const response = await FetchGPDetailBaseInfo(gpCode)
        this.setState({ _baseInfo: response, _targets: extensionValue ? [extensionValue] : [] })
      }
      Toast.clear()
    } catch (error) {
      Toast.show({ icon: 'fail', content: error })
    }
  }

  async fetchLPDetailBaseInfo() {
    Toast.show({ icon: 'loading', duration: 0 })
    const { _value } = this.state
    try {
      const { lpCode, extensionValue } = await SwapRelationCode({ code: _value['value'], type: _value['type'] })
      if (lpCode) {
        const response = await FetchLPDetailBaseInfo(lpCode)
        this.setState({ _baseInfo: response, _targets: extensionValue ? [extensionValue] : [] })
      }
      Toast.clear()
    } catch (error) {
      Toast.show({ icon: 'fail', content: error })
    }
  }

  renderNavRight() {
    const { _tabs, _value, } = this.state
    const hiddenTabKeys = _.map(_.filter(_tabs, { show: false }), "key")

    return (
      <div>
        <TargetFormEntryMenu className="info-detail-nav-right"
          enterpriseCode={_value['value']}
          enterpriseType={_value['type']}
          disabledValues={hiddenTabKeys}
          onRefresh={(category) => {
            if (category === 'company') {
              this.fetchCompanyDetailBaseInfo()
            } else if (category === 'investment') {
              this.fetchGPDetailBaseInfo()
            } else if (category === 'lp') {
              this.fetchLPDetailBaseInfo()
            }
          }}>
          <span><EditSFill style={{ fontSize: 18, color: '#1890FF' }} /></span>
        </TargetFormEntryMenu>
      </div>
    )
  }

  renderBodyContent() {
    const {
      _tabs,
      _infoTabs,
      _value,
    } = this.state
    const showTabKeys = _.map(_.filter(_tabs, { show: true }), "key")

    return (
      <div className="info-detail-page-box">
        <div className="info-detail-page-head">
          <div className="base-info">
            <Space align="center">
              <Image lazy fit="contain" src={_value['avatar']} placeholder={<DefaultIcon />} fallback={<DefaultIcon />} className="info-query-item-avatar" style={{ '--width': '32px', '--height': '32px' }} />
              <div>{_value['label']}</div>
            </Space>
          </div>
          <div className="more-info">
            {/** 角色基本信息 */}
            <CECTab tabs={_tabs}
              tabExtra={() => (
                <TargetFormEntryMenu mode="addBtn"
                  className="info-detail-add-tab"
                  enterpriseCode={_value['value']}
                  enterpriseType={_value['type']}
                  disabledValues={showTabKeys}
                  onConfirm={(data) => console.log(data)}
                />
              )}
              tabBodyContent={() => this.renderTabBodyContent()}
              onTabChange={this.handleChange.bind(this, 'change_role_tab')}
            />
          </div>
        </div>
        <div className="info-detail-page-body">
          {/** 角色关联信息 */}
          <CECTab tabs={_infoTabs}
            tabStyle={{ position: 'sticky', top: 0, zIndex: 999 }}
            tabBodyContent={() => this.renderInfoTabBodyContent()}
            onTabChange={this.handleChange.bind(this, 'change_info_tab')}
          />
        </div>
      </div>
    )
  }

  // 角色Tab - 角色基本信息
  renderTabBodyContent() {
    const {
      _tabs,
      _baseInfo,
    } = this.state
    const currentActiveTab = _.get(_.head(_.filter(_.filter(_tabs, { show: true }), { selected: true })), "key")

    return (
      <div>
        {
          currentActiveTab === 'company' && (<CompanyBaseInfoView value={_baseInfo} onRefresh={async () => await this.fetchCompanyDetailBaseInfo()} />)
        }
        {
          currentActiveTab === 'investment' && (<GPBaseInfoView value={_baseInfo} onRefresh={async () => await this.fetchGPDetailBaseInfo()} />)
        }
        {
          currentActiveTab === 'lp' && (<LPBaseInfoView value={_baseInfo} onRefresh={async () => await this.fetchLPDetailBaseInfo()} />)
        }
      </div>
    )
  }

  // 角色表单Tab - 角色关联信息
  renderInfoTabBodyContent() {
    const {
      _tabs,
      _infoTabs,
      _value,
      _baseInfo,
      _targets,
    } = this.state
    const currentActiveTab = _.get(_.head(_.filter(_.filter(_tabs, { show: true }), { selected: true })), "key")

    return (
      <div>
        {
          currentActiveTab === 'company' && (<CompanyBodyInfoView code={_baseInfo['code']} targets={_targets} tabs={_infoTabs} />)
        }
        {
          currentActiveTab === 'investment' && (<GPBodyInfoView code={_baseInfo['code']} targets={_targets} tabs={_infoTabs} />)
        }
        {
          currentActiveTab === 'lp' && (<LPBodyInfoView code={_baseInfo['code']} targets={_targets} tabs={_infoTabs} />)
        }
      </div>
    )
  }
}

// 标签页
function CECTab({ tabs, tabStyle, tabExtra, tabBodyContent, onTabChange }) {

  return (
    <div className="info-detail-tabs-box">
      <div className="info-detail-tabs-list-wrap">
        <div className="info-detail-tabs-list" style={tabStyle}>
          {
            tabs.filter(tab => tab['show']).map(tab => (
              <Fragment>
                <div className={tab['selected'] ? "tabs-item tabs-item-active" : "tabs-item"} onClick={() => onTabChange(tab)}>{tab['label']}</div>
              </Fragment>
            ))
          }
        </div>
        {
          tabExtra && <div className="info-detail-tabs-extra">{tabExtra()}</div>
        }
      </div>
      <div className="info-detail-tabs-body-wrap">
        <div className="info-detail-tabs-body">
          {
            tabBodyContent && tabBodyContent()
          }
        </div>
      </div>
    </div>
  )
}

function DefaultIcon() {
  return <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path d="M894 462c30.9 0 43.8-39.7 18.7-58L530.8 126.2a31.81 31.81 0 0 0-37.6 0L111.3 404c-25.1 18.2-12.2 58 18.8 58H192v374h-72c-4.4 0-8 3.6-8 8v52c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-52c0-4.4-3.6-8-8-8h-72V462h62zM512 196.7l271.1 197.2H240.9L512 196.7zM264 462h117v374H264V462z m189 0h117v374H453V462z m307 374H642V462h118v374z" fill="#dbdbdb"></path></svg>
}