import React, { Fragment, useEffect, useImperativeHandle, useCallback, useRef, useState } from "react"
import _ from "lodash"
import { List, Skeleton, ErrorBlock, Toast, Space } from "antd-mobile"
import { EditSFill } from "antd-mobile-icons"
import CECInfiniteScroll from "../../../../../../../components/InfiniteScroll"
import CECPopup from "../../../../../../../components/Popup"
import './index.less';
import {
  FetchFinancingHistoryDataList,
  FetchFinancingHistoryDataDetail,
} from './transform'

/**
 * 融资收购历史列表
 * @returns 
 */
export default function FinancingHistoryListView({ code }) {

  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [item, setItem] = useState({})
  const dataDetailPopupRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      await fetchDatas(code, 1);
    };
    code && fetchData();
  }, [code]);

  // 获取列表
  const fetchDatas = async (code, currentPageNum) => {
    setLoading(true)
    try {
      const { datas: newDatas, hasMore, total } = await FetchFinancingHistoryDataList({ code, pageNum: currentPageNum, pageSize })
      setLoading(false);
      setHasMore(hasMore);
      setPageNum(currentPageNum + 1);
      if (currentPageNum === 1) {
        setDatas(newDatas)
      } else {
        setDatas(prevDatas => [...prevDatas, ...newDatas]);
      }
    } catch (error) {
      Toast.show({ content: error })
      setLoading(false)
      setHasMore(false);
    }
  }

  const handleLoadMore = useCallback(async () => {
    code && await fetchDatas(code, pageNum);
  }, [code, pageNum])

  const handleClick = (key, data, event) => {
    console.log("handleClick >>>>", key, data, event)

    switch (key) {
      case 'handle_open_item_detail_popup':
        setItem(data)
        dataDetailPopupRef?.current?.show()
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <List>
        {
          loading && _.size(datas) == 0 && (
            <Fragment>
              <Skeleton.Title animated />
              <Skeleton.Paragraph lineCount={4} animated />
            </Fragment>
          )
        }
        {
          !loading && _.size(datas) == 0 && (
            <Fragment>
              <ErrorBlock status='empty' title="" description="暂无数据" />
            </Fragment>
          )
        }
        {
          datas.map(item => (
            <List.Item onClick={() => handleClick('handle_open_item_detail_popup', item)}>
              <div className="financing-history-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <span>交易日期：</span>
                    <span>xxxxxx</span>
                  </div>
                  <div>
                    <span>交易轮次：</span>
                    <span>xxxxxx</span>
                  </div>
                </div>
                <div>
                  <span>投资机构：</span>
                  <span>xxxxxx</span>
                </div>
                <div>
                  <span>投资人：</span>
                  <span>xxxxxx</span>
                </div>
                <div>
                  <span>股权占比：</span>
                  <span>xxxxxx %</span>
                </div>
                <div>
                  <span>融资金额：</span>
                  <span>xxxxxx</span>
                </div>
                <div>
                  <span>投后估值：</span>
                  <span>xxxxxx</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span className="financing-history-item-key">更新时间/更新人：</span>{item['modifyDate']}
                  </div>
                  <div style={{ margin: '0 5px' }}>/</div>
                  <div>{item['modidyUser']}</div>
                </div>
              </div>
            </List.Item>
          ))
        }
        <CECInfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} />
      </List>

      {/** 详情Popup */}

    </Fragment>
  )
}