import React, { Fragment } from 'react';
import _ from 'lodash';
import { Ellipsis } from 'antd-mobile';
import InfoDetailBaseItem, {
  InfoDetailBaseItemTagComponent,
  InfoDetailBaseItemIndustryComponent,
  InfoDetailBaseItemUserComponent,
} from '../InfoDetailBaseItem';
import './index.less';

/**
 * GP基本信息
 */
export default function GPBaseInfoView({ value = {}, onRefresh }) {
  
  return (
    <Fragment>
      <div className="info-detail-base-item-box">
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="类型" renderComponent={() => value['types'] && _.size(value['types']) > 0 && <InfoDetailBaseItemTagComponent datas={value['types']} />} />
        </div>
        <div className='info-detail-space'>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="地区" renderComponent={() => value['nation'] && <span>{value['nation']} {value['location'] ? "-" + value['location'] : null}</span>} />
          </div>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="币种" renderComponent={() => value['currencys'] && _.size(value['currencys']) > 0 && value['currencys'].map(item => <span>{item['label']}</span>)} />
          </div>
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="阶段" renderComponent={() => value['stages'] && _.size(value['stages']) > 0 && value['stages'].map(item => <span>{item['label']}</span>)} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="公司集团所在属地" renderComponent={() => value['groupBaseLocation'] && <span>{value['groupBaseLocation']}</span>} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="上市地" renderComponent={() => value['groupListOn'] && <span>{value['groupListOn']}</span>} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="投委会成员" renderComponent={() => value['icOwnerUsers'] && _.size(value['icOwnerUsers']) > 0 && <InfoDetailBaseItemUserComponent datas={value['icOwnerUsers']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="活跃投资人" renderComponent={() => value['activeInvestors'] && _.size(value['activeInvestors']) > 0 && <InfoDetailBaseItemUserComponent datas={value['activeInvestors']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="易凯联系人" renderComponent={() => value['ecapitalUsers'] && _.size(value['ecapitalUsers']) > 0 && <InfoDetailBaseItemUserComponent datas={value['ecapitalUsers']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="正关注领域" renderComponent={() => value['industrys'] && _.size(value['industrys']) > 0 && <InfoDetailBaseItemIndustryComponent datas={value['industrys']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="简介" renderComponent={() => value['description'] && <Ellipsis direction='end' rows={2} expandText='展开' collapseText='收起' content={value['description']} />} />
        </div>
      </div>
    </Fragment>
  )

}