import _ from "lodash";
import moment from "moment";
import request from "../../../../../../../api/HttpRequest";
import { parseAmountNumber } from "../../../../../../../components/AmountNumber";


// 币种
export const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
export const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
// 金额单位
export const AMOUNT_UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]


/**
 * 转换行业为Label
 * @param {*} datas 
 * @returns 
 */
export const ConvertIndustryToLabels = (datas) => {
  let selectedIndustrys = ""
  if (datas) {
    selectedIndustrys = _.join(_.map(datas, item => {
      let industry = item['label']
      let childrenLabel = ""
      if (item['children'] && _.size(item['children']) > 0) {
        childrenLabel = _.join(_.map(item['children'], "label"), "、")
      }
      if (childrenLabel) {
        industry += `（${childrenLabel}）`
      }
      return industry
    }), "，")
  }
  return selectedIndustrys
}


/**
 * 获取基础数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementList(type, relateType,) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist", { 'etype': type, "etype_p": relateType })
    let array = []
    if (response) {
      array = _.map(response, item => {
        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}

/**
 * 获取基础二级数据
 * @param {*} type 
 * @returns 
 */
export async function FetchElementLevelList(type) {
  try {
    const response = await request("POST", "/cec-sims-server/labelelement/elementlist_level2", { 'etype': type })
    let array = []
    if (response) {
      array = _.map(response, item => {
        let children = []
        if (item['children'] && _.size(item['children']) > 0) {
          children = _.map(item['children'], subItem => ({
            label: subItem['sys_labelelement_name'],
            value: subItem['sys_labelelement_code'],
            type: item['sys_labelelement_code'],
          }))
        }

        return {
          label: item['sys_labelelement_name'],
          value: item['sys_labelelement_code'],
          type: item['sys_labelelement_type'],
          children: children,
        }
      })
    }
    return array
  } catch (error) {
    throw error
  }
}


/**
 * 保存是否有投退需求 - 项目投退
 * @param {*} info 
 */
export async function SaveInfo(info) {

  if (!info['name']) {
    throw '请输入项目名称'
  }

  let industryList = []
  if (info['industry']) {
    info['industry'].find(item => {
      industryList.push({ sys_labelelement_code: item['value'], sys_labelelement_type: item['type'] })

      if (item['children'] && _.size(item['children']) > 0) {
        item['children'].find(childrenItem => {
          industryList.push({ sys_labelelement_code: childrenItem['value'], sys_labelelement_type: childrenItem['type'] })
        })
      }
    })
  }

  let costCurrency = info['costCurrencyOther']
  if (!costCurrency) {
    costCurrency = info['costCurrency'] ? _.join(_.map(info['costCurrency'], "value")) : null
  }
  let costUnit = info['costUnit'] ? _.join(_.map(info['costUnit'], "value")) : null
  let costAmount = parseAmountNumber(info['costAmount'], costUnit)


  let valueCurrency = info['valueCurrencyOther']
  if (!costCurrency) {
    valueCurrency = info['valueCurrency'] ? _.join(_.map(info['valueCurrency'], "value")) : null
  }
  let valueUnit = info['valueUnit'] ? _.join(_.map(info['valueUnit'], "value")) : null
  let valueAmount = parseAmountNumber(info['valueAmount'], costUnit)

  let payload = {}
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/smartx/comp/trade/chance/save"
    payload = {
      id: info['id'],
      base_comp_code: info['code'],
      type: info['type'],
      comp_name2: info['name'],
      label_datas: JSON.stringify(industryList),
      tradedate: info['date'] ? info['date'].toString() : null,
      tradecurrency: costCurrency,
      tradeunit: costUnit,
      tradevalue: costAmount,
      newstockcurrency: valueCurrency,
      newstockunit: valueUnit,
      newstockvalue1: valueAmount,
      tradepercent: info['scaleRatio'],
      showflag: info['id'] ? true : false,
    }
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/save"
    payload = {
      id: info['id'],
      base_investment_code: info['code'],
      type: info['type'],
      comp_name: info['name'],
      label_datas: JSON.stringify(industryList),
      tradedate: info['date'] ? info['date'].toString() : null,
      tradecurrency: costCurrency,
      tradeunit: costUnit,
      tradevalue: costAmount,
      oldstockcurrency: valueCurrency,
      oldstockunit: valueUnit,
      oldstockvalue1: valueAmount,
      tradepercent: info['scaleRatio'],
      showflag: info['id'] ? true : false,
    }
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/trade/family/chance/save"
    payload = {
      id: info['id'],
      base_lp_code: info['code'],
      type: info['type'],
      comp_name: info['name'],
      label_datas: JSON.stringify(industryList),
      tradedate: info['date'] ? info['date'].toString() : null,
      tradecurrency: costCurrency,
      tradeunit: costUnit,
      tradevalue: costAmount,
      oldstockcurrency: valueCurrency,
      oldstockunit: valueUnit,
      oldstockvalue1: valueAmount,
      tradepercent: info['scaleRatio'],
      showflag: info['id'] ? true : false,
    }
  }
  try {
    const response = await request("POST", url, payload)
    return { ...info, id: response['id'] }
  } catch (error) {
    throw error
  }
}
/**
 * 删除是否有投退需求 - 项目投退
 * @param {*} info 
 */
export async function DeleteInfo(info) {
  let url = ""
  if (info['targetType'] === 'info_enterprise') {// 企业
    url = "/cec-sims-server/smartx/comp/trade/chance/delete"
  } else if (info['targetType'] === 'info_market') {// 市场化机构
    url = "/cec-sims-server/smartx/investment/trade/chance/delete"
  } else if (['info_government', 'info_family_office'].includes(info['targetType'])) {// 政府&家办
    url = "/cec-sims-server/smartx/lp/trade/family/chance/delete"
  }
  try {
    await request("POST", url, { id: info['id'] })
    return null
  } catch (error) {
    throw error
  }
}