import React, { Fragment, createRef } from "react";
import _ from "lodash";
import { Button, Toast } from 'antd-mobile'
import CECSelectPopup from "../../../../../components/SelectPopup";
import CECTargetUserFormPopup from '../../../../../components/TargetUserFormPopup'
import {
  FetchTargetUserCallbackList,
  FetchCECUserCallbackList,
  SaveTargetUserInfo,
} from "../../transform";

/**
 * 【会议更新：参会人员】
 */
export default class AttendTargetUserPopup extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      _targetUserCategory: props.metadata['key'].indexOf('_ceccapital_') > -1 ? "cecuser" : "otheruser",
      _targetUserOptions: [],
      _targetUsers: [],
    }
    this.attendTargetUserPopupRef = createRef();
    this.createTargetUserFormRef = createRef()
  }

  show() {
    this.fetchTargetUserList()
    this.attendTargetUserPopupRef.current.show()
  }

  close() {
    this.attendTargetUserPopupRef.current.close()
  }

  toggle() {
    this.fetchTargetUserList()
    this.attendTargetUserPopupRef.current.toggle()
  }

  handleClick(key, data, event) {
    // console.log("handleClick >>>>", key, data, event)
    const { onConfirm } = this.props

    switch (key) {
      case 'handle_confirm_update_target_user_popup':
        this.setState({ _targetUsers: data }, () => this.attendTargetUserPopupRef.current.close())
        onConfirm && onConfirm({ value: data })
        break;
      case 'handle_confirm_create_target_user_popup':
        this.saveTargetUserInfo(data)
        break;
      default:
        break;
    }
  }

  fetchTargetUserList = async () => {
    const { metadata, extensionValue, groupKey } = this.props

    Toast.show({ content: '加载中...', icon: 'loading', duration: 0, maskStyle: { zIndex: 1500 } })
    if (metadata['key'].indexOf('_ceccapital_') > -1) {
      await FetchCECUserCallbackList('normal', datas => this.setState({ _targetUserOptions: datas }))
    } else {
      const target = _.head(_.get(extensionValue, `${groupKey}.targets`))
      await FetchTargetUserCallbackList(target['category'], target['value'], datas => this.setState({ _targetUserOptions: datas }))
    }
    Toast.clear()
    this.setState({
      _targetUserCategory: metadata['key'].indexOf('_ceccapital_') > -1 ? "cecuser" : "otheruser",
    })
  }
  // 创建对象对接人
  saveTargetUserInfo = async (data) => {
    const { _targetUserOptions } = this.state
    await SaveTargetUserInfo(data,
      (data) => {
        Toast.show({ content: '保存成功', icon: 'success' })
        if (_.size(_.filter(_targetUserOptions, { value: data['value'] })) == 0) {
          let targetUserOptions = _.map(_targetUserOptions, item => ({ ...item }))
          targetUserOptions.unshift(data)
          this.setState({ _targetUserOptions: targetUserOptions })
        }
        this.handleClick('handle_confirm_update_target_user_popup', [data])
        this.createTargetUserFormRef.current?.close()
      },
      (msg) => Toast.show({ icon: 'fail', content: msg })
    )
  }

  render() {
    const {
      _targetUserCategory,
      _targetUserOptions,
      _targetUsers,
    } = this.state
    const {
      extensionValue
    } = this.props
    let targetValue = _.get(extensionValue, "memo_option_list_attend_target.targets[0].value")
    let targetCategory = _.get(extensionValue, "memo_option_list_attend_target.targets[0].category")

    return (
      <Fragment>
        {/** 参会人员 */}
        <CECSelectPopup ref={this.attendTargetUserPopupRef}
          title={'参会人员'}
          navRight={_targetUserCategory != "cecuser" && <Button color="primary" fill="none" onClick={() => this.createTargetUserFormRef.current?.toggle()}>新建</Button>}
          supportSearch={true}
          supportAvatar={false}
          multiple={true}
          options={_targetUserOptions}
          values={_targetUsers}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_update_target_user_popup')}
        />

        {/** 创建参会人 */}
        <CECTargetUserFormPopup ref={this.createTargetUserFormRef}
          metadata={{ title: '新建参会人员' }}
          targetValue={targetValue}
          category={targetCategory}
          value={null}
          onConfirm={this.handleClick.bind(this, 'handle_confirm_create_target_user_popup')}
        />
      </Fragment>
    )
  }
}