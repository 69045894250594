import _ from "lodash";
import request from "../../../../../../../api/HttpRequest";
import moment from "moment";

const FINANCIAL_DATA_OPTIONS_YES_VALUE = 'YES'
const FINANCIAL_DATA_OPTIONS = [
  { label: '是', value: FINANCIAL_DATA_OPTIONS_YES_VALUE },
  { label: '否', value: 'NO' },
  { label: '不确定', value: 'UNSURE' },
]


const CURRENCY_OPTIONS_OTHER_VALUE = '其他'
const CURRENCY_OPTIONS = [
  { value: '人民币', label: '人民币' },
  { value: '美元', label: '美元' },
  { value: CURRENCY_OPTIONS_OTHER_VALUE, label: CURRENCY_OPTIONS_OTHER_VALUE },
]
const UNIT_OPTIONS = [
  { value: '1000', label: '千元' },
  { value: '1000000', label: '百万' },
  { value: '1000000000', label: '十亿' },
]
const AMOUNT_OPTIONS_NUMBER = '金额'
const AMOUNT_OPTIONS = [
  { value: AMOUNT_OPTIONS_NUMBER, label: AMOUNT_OPTIONS_NUMBER },
  { value: '数百万', label: '数百万' },
  { value: '数千万', label: '数千万' },
  { value: '数亿', label: '数亿' },
  { value: '数百亿', label: '数百亿' },
  { value: '数千亿', label: '数千亿' },
]

/**
 * 获取财务数据列表
 */
export async function FetchFinancialDataList({ code, pageNum = 1, pageSize = 2 }) {

  const payload = {
    base_comp_code: code,
    pageNum: pageNum <= 0 ? 1 : pageNum,
    pageSize: pageSize,
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/financial/list", payload)
    const total = response['total']
    const list = response['list']

    let datas = []
    if (list && _.size(list) > 0) {
      datas = _.map(list, item => {
        return {
          id: item['id'],
          code: item['base_comp_code'],
          // 收入
          income: _.filter(FINANCIAL_DATA_OPTIONS, { value: item['incomeflag'] }),

          // 正向EBITD
          ebitda: _.filter(FINANCIAL_DATA_OPTIONS, { value: item['ebitdaflag'] }),

          // 利润
          profit: _.filter(FINANCIAL_DATA_OPTIONS, { value: item['netflag'] }),

          // 正现金流
          cashflow: _.filter(FINANCIAL_DATA_OPTIONS, { value: item['cashflowflag'] }),
        }
      })
    }

    const hasMore = (total <= (pageNum - 1 > 0 ? pageNum - 1 : 0) * pageSize + _.size(datas)) ? false : true
    return { datas, total, hasMore }
  } catch (error) {
    throw error
  }
}

/**
 * 获取财务数据详情
 * @param {*} id 
 */
export async function FetchFinancialDataDetail(id) {
  if (!id) {
    return
  }
  try {
    const response = await request("POST", "/cec-sims-server/smartx/comp/base/financial/detail", { id: id })
    let result = {}
    if (response) {
      let incomeDatas = [],
        ebitdaDatas = [],
        profitDatas = []
      if (response['incomeflag'] === FINANCIAL_DATA_OPTIONS_YES_VALUE) {
        var amountValue = response['incomevalue'] && response['incomeunit'] ? _.round(_.divide(response['incomevalue'], response['incomeunit']), 4) : response['incomevalue']
        incomeDatas.push({
          year: response['incomeyear'] ? moment(response['incomeyear'] + "-01-01").toDate() : null,
          currencys: response['incomecurrency'] ? _.filter(CURRENCY_OPTIONS, { value: response['incomecurrency'] }) : [],
          units: response['incomeunit'] ? _.filter(CURRENCY_OPTIONS, { value: response['incomeunit'].toString() }) : [],
          amounts: response['incomevalue2'] ? _.filter(CURRENCY_OPTIONS, { value: response['incomevalue2'].toString() }) : [],
          amountValue: amountValue,
        })
      }

      if (response['ebitda'] === FINANCIAL_DATA_OPTIONS_YES_VALUE) {
        var amountValue = response['ebitdavalue'] && response['ebitdaunit'] ? _.round(_.divide(response['ebitdavalue'], response['ebitdaunit']), 4) : response['ebitdavalue']
        ebitdaDatas.push({
          year: response['ebitdayear'] ? moment(response['ebitdayear'] + "-01-01").toDate() : null,
          currencys: response['ebitdacurrency'] ? _.filter(CURRENCY_OPTIONS, { value: response['ebitdacurrency'] }) : [],
          units: response['ebitdaunit'] ? _.filter(CURRENCY_OPTIONS, { value: response['ebitdaunit'].toString() }) : [],
          amounts: response['ebitdavalue2'] ? _.filter(CURRENCY_OPTIONS, { value: response['ebitdavalue2'].toString() }) : [],
          amountValue: amountValue,
        })
      }

      if (response['profit'] === FINANCIAL_DATA_OPTIONS_YES_VALUE) {
        var amountValue = response['netvalue'] && response['netunit'] ? _.round(_.divide(response['netvalue'], response['netunit']), 4) : response['netvalue']
        profitDatas.push({
          year: response['netyear'] ? moment(response['netyear'] + "-01-01").toDate() : null,
          currencys: response['netcurrency'] ? _.filter(CURRENCY_OPTIONS, { value: response['netcurrency'] }) : [],
          units: response['netunit'] ? _.filter(CURRENCY_OPTIONS, { value: response['netunit'].toString() }) : [],
          amounts: response['netvalue2'] ? _.filter(CURRENCY_OPTIONS, { value: response['netvalue2'].toString() }) : [],
          amountValue: amountValue,
        })
      }

      result = {
        id: response['id'],
        code: response['base_comp_code'],
        income: _.filter(FINANCIAL_DATA_OPTIONS, { value: response['incomeflag'] }),
        incomeDatas: incomeDatas,
        ebitda: _.filter(FINANCIAL_DATA_OPTIONS, { value: response['ebitda'] }),
        ebitdaDatas: ebitdaDatas,
        profit: _.filter(FINANCIAL_DATA_OPTIONS, { value: response['profit'] }),
        profitDatas: profitDatas,
        cashflow: _.filter(FINANCIAL_DATA_OPTIONS, { value: response['cashflow'] }),
      }
    }
    return result
  } catch (error) {
    throw error
  }
}