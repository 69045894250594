import React from "react";
import _ from "lodash";
import request from "../../../../api/HttpRequest";
/**
 * 会议搜索接口
 * @param {*} param0 
 * @returns 
 */
export function SearchMeetingDataList({pageNum, pageSize, filter}) {
    return new Promise((resolve, reject)=> {
        request('POST', '/cec-sims-server/meeting/list', {
            ...filter,  
            pageNum: pageNum,
            pageSize: pageSize,
        }).then(response=> {
            const { total, list } = response
            resolve({ total, list })
        }).catch(msg=> reject(msg))
    })
}