import React, { createRef, Fragment } from "react";
import { Button, CalendarPickerView, Space } from "antd-mobile";
import { LeftOutline, RightOutline } from 'antd-mobile-icons'
import moment from "moment";
import BaseExtractPopup from "../BaseExtractPopup";
import './index.css';
import _ from 'lodash';

/**
 * 日历选择器弹窗
 */
export default class CECCalendarPopup extends BaseExtractPopup {

  constructor(props) {
    super(props)

    let _val = _.get(props, 'value', null)
    let _temPrecision = _.get(props, 'precision', 'day')
    let obj = {}
    switch (_temPrecision) {
      case "quarter":
        const [first, last] = _.split(_val, '/');
        console.log("_val", typeof (_val), first, last)
        obj = {
          _currentYear: first != null ? moment(`${first}-01-01`).format('YYYY') : moment().format('YYYY'),
          _quarterOptions: _.map(new Array(4), (it, i) => ({ label: `Q${(i + 1)}`, value: `Q${(i + 1)}` })),
          _currentQuarter: last
        }
        break
      default:
        const { options, startYear, endYear } = doItOptions(_val != null && moment(_val).format('YYYY'))
        obj = {
          // _precision == "year"
          _yearOptions: options,
          _yearStart: startYear,
          _yearEnd: endYear,
          // _precision == "month"
          _currentYear: _val != null ? moment(_val).format('YYYY') : moment().format('YYYY'),
          _currentMonth: _val != null ? moment(_val).format('MM') : moment().format('MM'),
          _monthOptions: _.map(new Array(12), (it, i) => ({ label: (i + 1), value: (i + 1) < 10 ? `0${(i + 1)}` : `${(i + 1)}` })),
        }
        break
    }

    this.state = {
      // day|日，month|月，year｜年，quarter｜季度
      _precision: _temPrecision,
      _value: props.value,
      _page: {
        year: moment().get('years'),
        month: moment().get('months') + 1,
      },
      ...obj,

    }
    this.calendarPickerRef = createRef()
  }

  getDestroyOnClose() {
    return true
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      _value: props.value,
    })
  }

  handleChangeValue(value) {
    console.log("handleChangeValue", value)
    this.setState({
      _value: value,
      _currentMonth: value ? moment(value).format('MM') : ''
    })
  }

  renderBodyContent() {
    const { _precision } = this.state
    let view = <Fragment></Fragment>
    switch (_precision) {
      case "year":
        //年
        view = this.renderYear()
        break
      case "month":
        //月
        view = this.renderMonth()
        break
      case "quarter":
        //季度
        view = this.renderQuarter()
        break
      default:
        view = this.renderDay()
        break
    }

    return view
  }

  renderFootContent() {
    const {
      onConfirm
    } = this.props
    const {
      _value,
      _precision,
      _currentYear,
      _currentQuarter
    } = this.state

    return (
      <Button block color="primary" onClick={() => {
        if (_precision == 'quarter') {
          onConfirm && onConfirm(`${_currentYear}/${_currentQuarter}`)
        } else {
          onConfirm && onConfirm(_value)
        }

        this.close()
      }}>确定</Button>
    )
  }

  renderQuarter() {
    const { _currentYear, _currentQuarter, _quarterOptions } = this.state;
    return (
      <div className="cec-calendar-popup-year">
        <CalendarNavBar title={`${_currentYear}年`}
          onLeftClick={() => {
            this.setState({
              _currentYear: `${parseInt(_currentYear) - 1}`,
            })
          }}
          onRightClick={() => {
            this.setState({
              _currentYear: `${parseInt(_currentYear) + 1}`,
            })
          }}
        />
        <div className="cec-calendar-popup-year-main">

          {_.map(_quarterOptions, (item, i) => (
            <div key={`quarterOptions-${i}`}
              className='cec-calendar-popup-year-main-item2'
              onClick={() => {
                this.setState({ _currentQuarter: _currentQuarter == item['value'] ? '' : item['value'] })
              }}>
              <div className={
                _currentQuarter == item['value'] ?
                  'cec-calendar-popup-year-main-item-select'
                  : ''
              }>{`${item['label']}`}</div>
            </div>
          ))}

        </div>
      </div>
    )
  }

  renderMonth() {
    const { _currentYear, _currentMonth, _monthOptions } = this.state;
    return (
      <div className="cec-calendar-popup-year">
        <CalendarNavBar title={`${_currentYear}年`}
          onLeftClick={() => {
            this.setState({
              _currentYear: `${parseInt(_currentYear) - 1}`,
              _value: _currentMonth != '' ? `${parseInt(_currentYear) - 1}-${_currentMonth}-01` : null
            })
          }}
          onRightClick={() => {
            this.setState({
              _currentYear: `${parseInt(_currentYear) + 1}`,
              _value: _currentMonth != '' ? `${parseInt(_currentYear) + 1}-${_currentMonth}-01` : null
            })
          }}
        />
        <div className="cec-calendar-popup-year-main">

          {_.map(_monthOptions, (item, i) => (
            <div key={`monthOptions-${i}`}
              className='cec-calendar-popup-year-main-item'
              onClick={() => {
                _currentMonth == item['value'] ?
                  this.handleChangeValue(null) : this.handleChangeValue(moment(`${_currentYear}-${item['value']}-01`).toDate())
              }}>
              <div className={
                _currentMonth == item['value'] ?
                  'cec-calendar-popup-year-main-item-select'
                  : ''
              }>{`${item['label']}月`}</div>
            </div>
          ))}

        </div>
      </div>
    )
  }

  renderYear() {
    const { _yearOptions, _yearStart, _yearEnd, _value } = this.state;
    const value = _value ? moment(_value).format('YYYY') : ''

    return (
      <div className="cec-calendar-popup-year">
        <CalendarNavBar title={`${_yearStart}年-${_yearEnd}年`} leftLabel='前十年' rightLabel="后十年"
          onLeftClick={() => {
            const { options, startYear, endYear } = doItOptions(`${parseInt(_yearStart) - 10}`)
            this.setState({
              _yearOptions: options,
              _yearStart: startYear,
              _yearEnd: endYear
            })
          }}
          onRightClick={() => {
            const { options, startYear, endYear } = doItOptions(`${parseInt(_yearStart) + 10}`)
            this.setState({
              _yearOptions: options, _yearStart: startYear, _yearEnd: endYear
            })
          }}
        />
        <div className="cec-calendar-popup-year-main">
          {_.map(_yearOptions, (item, i) => (
            <div key={`yearOptions-${i}`}
              className='cec-calendar-popup-year-main-item'
              onClick={() => {
                value == item ?
                  this.handleChangeValue(null) : this.handleChangeValue(moment(`${item}-01-01`).toDate())
              }}>
              <div className={
                value == item ?
                  'cec-calendar-popup-year-main-item-select'
                  : ''
              }>{item}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderDay() {
    const {
      title,
      min,
      max,
      value,
      selectionMode,
      onConfirm,
    } = this.props
    const {
      _visible,
      _value,
      _page,
    } = this.state

    return (
      <CalendarPickerView ref={this.calendarPickerRef}
        className="cec-calendar-popup"
        weekStartsOn="Monday"
        visible={_visible}
        selectionMode={selectionMode}
        title={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '0px 10px', fontSize: 12, color: '#1890FF' }}>
            <div style={{ display: 'inline-flex' }}>
              <Space block>
                <div onClick={() => this.calendarPickerRef.current.jumpTo({ year: --_page['year'], month: _page['month'] })}>前一年</div>
                <div onClick={() => this.calendarPickerRef.current.jumpTo({ year: _page['year'], month: --_page['month'] })}>前一月</div>
              </Space>
            </div>
            <div style={{ display: 'inline-flex' }}>
              <Space block>
                <div onClick={() => this.calendarPickerRef.current.jumpTo({ year: _page['year'], month: ++_page['month'] })}>后一月</div>
                <div onClick={() => this.calendarPickerRef.current.jumpTo({ year: ++_page['year'], month: _page['month'] })}>后一年</div>
              </Space>
            </div>
          </div>
        }
        min={min ? min : null}
        max={max ? max : null}
        value={_value}
        onConfirm={onConfirm}
        onChange={this.handleChangeValue.bind(this)}
        onClose={this.close.bind(this)}
      />
    )
  }
}

function CalendarNavBar({ title, leftLabel = '前一年', rightLabel = '后一年', onLeftClick, onRightClick }) {
  return (
    <div className="cec-calendar-popup-year-title">
      <div
        className="cec-calendar-popup-year-title-label-other"
        style={{ justifyContent: "flex-start" }}
        onClick={() => { onLeftClick && onLeftClick() }}
      >
        {leftLabel}
      </div>
      <div className="cec-calendar-popup-year-title-label">{title}</div>
      <div
        className="cec-calendar-popup-year-title-label-other"
        style={{ justifyContent: "flex-end" }}
        onClick={() => { onRightClick && onRightClick() }}
      >
        {rightLabel}
      </div>
    </div>
  )
}

/**
 * 10年分组
 * @param {*} year 
 * @returns 
 */
function doItOptions(year = moment().format('YYYY')) {
  let str = year.substring(0, _.size(year) - 1)
  let _startYear = `${str}0`
  let _endYear = `${str}9`
  let _options = []
  for (let i = parseInt(_startYear); i <= parseInt(_startYear) + 9; i++) {
    _options.push(i)
  }
  // console.log("doItOptions ==> ", year, _options, _startYear, _endYear)
  return { options: _options, startYear: _startYear, endYear: _endYear }
}