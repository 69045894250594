import React, { Fragment, PureComponent, createRef } from "react";
import { FloatingBubble, List, Picker, Tabs, Tag } from "antd-mobile";
import { LeftOutline, AudioOutline } from 'antd-mobile-icons'
import BaseExtractPopup from "../../components/BaseExtractPopup";
import _ from "lodash";
import './index.css';
import QueryDetail from "../QueryDetailEntry";

export default class QueryEntry extends PureComponent {

  constructor(props) {
    super(props)

    let data = null
    if (props.match && props.match.params && props.match.params.data) {
      data = props.match.params.data
    }
    this.state = {
      _id: data,
    }
    this.queryDetailPopupRef = createRef()
    this.filterPickerRef = createRef()
  }

  componentDidMount() {
    const {
      match
    } = this.props
    let data = null
    if (match && match.params && match.params.data) {
      data = match.params.data
    }
    if (data) {
      this.setState({ _id: data }, () => this.handleClick('handle_toggle_popup'))
    }
  }

  handleClick(key, data, event) {
    console.log('handleClick >>>', key, data, event)
    switch (key) {
      case 'handle_toggle_popup':
        this.queryDetailPopupRef.current?.toggle()
        break;
      case 'change_tab':
        if (data === 'key_other_type') {
          this.filterPickerRef.current?.toggle()
        } else {
          this.filterPickerRef.current?.close()
        }
        break;
      default:
        break;
    }
  }

  render() {
    const {
      _id
    } = this.state

    return (
      <Fragment>
        <List style={{ background: '#f0f0f0' }} header={
          <div style={{ height: 30, lineHeight: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}><LeftOutline style={{ fontSize: '24px' }} onClick={() => window.location.href = '/#/'} /></div>
            <div style={{ flex: 'auto', color: '#333', textAlign: 'center' }}>查询</div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <span style={{ color: '#1890FF', fontSize: 12 }}>按更新时间倒序</span>
            </div>
          </div>
        } />
        <Tabs className="query-entry-container-tabs" onChange={this.handleClick.bind(this, 'change_tab')}>
          <Tabs.Tab title="查询全部" key="tab_modify_time" className="tab-item">
            <div style={{ height: '100%' }}>
              <div style={{ color: '#999', textAlign: 'center', padding: '10px', background: '#f0f0f0' }}>2024/07/03</div>
              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>苏宁易购【企业（产业方）】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>融资&并购</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>科技</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>BD及定期回访 - 企业</span>
                </div>
                {/* <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                【更新内容】策略及业务方向更新（有新战略方向、新业务）
              </div> 
              */}
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】主要沟通近期融资进展
                  - 目前因为要产能落地，希望可以接触政府机构
                  - 之前有FA服务，并不排斥与FA的合作。但合作上希望FA先介绍关键机构与公司沟通，在TS或立项后再与FA补EL
                </div>
              </div>

              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>深创投【市场化机构】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>募资</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>消费</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>BD及定期回访 - 机构（GP&LP）</span>
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】深创投有一个主基金，领域不限，医疗也可以投。另外有一支红土医疗基金一期，目前已全部投完，准备开始退出。内部会梳理一下项目被并购需求，然后和易凯对接。另外新成立的红土二期医疗基金规模20亿，已完成首关，在积极看项目，关注IVD、合成生物、消费医疗等领域。
                </div>
              </div>
              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>合肥市政府【政府】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>融资</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>科技</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>BD及定期回访 - 机构（GP&LP）</span>
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【更新内容】交易机会（有投资退出需求）
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】合肥市投促局负责全市企业招商引资工作，包括企业标的筛选，选择具体落地地点，组织与协调、推进投资盘组建及完成，上传下调企业落地过程中所有问题，督导区级单位将项目按时间要求落地完成等。
                </div>
              </div>


              <div style={{ color: '#999', textAlign: 'center', padding: '10px', background: '#f0f0f0' }}>2024/06/28</div>
              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>特金智能【企业（产业方）】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>融资</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>科技</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>项目会议 - 企业</span>
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】进展最快的买家：
                  去年12月第一医药完成尽调，但对于交易分歧较大，难以成交：
                  1） 对于利润的认可有差异：公司自己内部核算认为有2300-2500w净利润（逻辑是净利润1000w，剔除电商亏损的500多万和总部费用，能出2300-2400w利润），同时第一医药认为要社保按国企缴纳合规，封顶缴纳，按照他们的算法，合规成本会在500-600w。公司目前政策是全员缴纳但部分地区不要求公积金就不交，而且也不是按照上一年平均工资缴纳的，该做法参考上市公司同业和浙江当地标准，公司认为没有问题是合规的
                  2） 要求药店高管团队跟投，且金额不小，公司管理层觉得没有义务要投资
                  3） 认为净资产估值给的低，公司目前账上净资产2亿多，其中大部分是现金，总部7000w现金，子公司1.5亿现金+存货，管理层认为公司资产负债率30%多，上市公司都要60%以上，资产负债率很低，认为剔除销售额10%-15%的现金用作运营以外，多余现金应当给予估值溢价

                  综上，公司认为第一医药给的价格太低，至少要多1-2亿估值才行。
                </div>
              </div>
              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>麻六记【企业（产业方）】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>融资</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>消费&娱乐</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>BD及定期回访 - 企业</span>
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】门店：
                  截止21年底在经营12家店，春节前16家店。目前单店300平米左右，前期投入约300万。平均单店经历4-5个月每个月5%左右的增长爬坡后趋于稳定，约150-200万/月销售。预计22年3亿收入，5000万净利润。

                  线上：
                  目前抖音一个月2000万销售，线上净利要高于线下。未来供应链和抖音之外的电商渠道会与别人合作，抖音保持自己经营的状态

                  股权：
                  历史股东除了团队外只有和玉
                </div>
              </div>
              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>唯专医美【企业（产业方）】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>融资</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>Service</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>BD及定期回访 - 企业</span>
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】公司业务及核心价值
                  	公司于2018年创立，目前10家店，其中6家21年新开店，单店平均500平左右。
                  	轻医美，水光针，光电能量，少部分生美。
                  	定位中高端，店面于高端商也内， ifs，skp，万象城等。
                  	单店投资500万左右，2年成本收回。
                  	22-23年计划新开10店，北上广深，新一线。单城市3-5家店，打满50家店左右。
                  	医思医美早期投资孵化，目前创始管理层回购，医思不控股。
                  二．	财务表现
                  	单店1000万，盈利，10-20%，单店最高2000万可。
                  	21年整体5000万， 预计22年预计1.5亿。
                  	目前4店有收入，6店新开早期爬坡。
                </div>
              </div>


              <div style={{ color: '#999', textAlign: 'center', padding: '10px', background: '#f0f0f0' }}>2024/06/27</div>
              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>特步家办【家办及个人】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>投退</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>消费</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>项目会议 - GP&LP</span>
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】
                  主要内容：
                  一、 对方基金布局情况
                  二、 对方对项目的要求
                  三、 集团的项目取向
                  四、 境外投资结构
                </div>
              </div>
              <div style={{ padding: '12px 0 12px 12px', borderBottom: '1px solid #f0f0f0' }} onClick={this.handleClick.bind(this, 'handle_toggle_popup')}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <span style={{ fontSize: '16px' }}>蓝帆医疗【企业（产业方）】</span>
                  <Tag style={{ '--border-color': '#b7eb8f', '--text-color': '#389e0d', '--background-color': '#f6ffed', marginLeft: '8px' }}>并购</Tag>
                  <Tag style={{ '--border-color': '#1677ff', '--text-color': '#ffffff', '--background-color': '#1677ff', marginLeft: '8px' }}>M&A</Tag>
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <span style={{ color: '#1890FF' }}>项目会议 - 企业</span>
                </div>
                <div style={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  【会议内容】
                  主要内容：
                  一、 对方布局情况
                  二、 对方对项目的要求
                  三、 集团的项目取向
                  四、 境外投资结构
                </div>
              </div>
            </div>
          </Tabs.Tab>
          <Tabs.Tab title="查询会议" key="tab_memo" className="tab-item"></Tabs.Tab>
          <Tabs.Tab title="其他分类 ..." key="key_other_type" className="tab-item"></Tabs.Tab>
        </Tabs>

        <Picker ref={this.filterPickerRef}
          title="其他分类"
          columns={[
            [
              { label: '策略及业务方向', value: '策略及业务方向' },
              { label: '财务数据', value: '财务数据' },
              { label: '业务机会', value: '业务机会' },
              { label: '投资案例', value: '投资案例' },
              { label: '股权机构', value: '股权机构' },
              { label: '人事更新', value: '人事更新' },
              { label: '基金属性', value: '基金属性' },
              { label: '基金募集', value: '基金募集' },
              { label: '投资方向', value: '投资方向' },
              { label: '交易机会', value: '交易机会' },
            ]
          ]}
        />
        <QueryDetailPopup ref={this.queryDetailPopupRef} metadata={{ title: '详细信息' }} id={_id} />

        <FloatingBubble
          axis='xy'
          magnetic='x'
          style={{
            '--initial-position-bottom': '24px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
          }}
        >
          <div>
            <div><AudioOutline fontSize={24} /></div>
            <div style={{ fontSize: 12 }}>查询</div>
          </div>
        </FloatingBubble>
      </Fragment>
    )
  }

}

export class QueryDetailPopup extends BaseExtractPopup {

  constructor(props) {
    super(props);
    this.state = {
      value: null,
      detailData: null
    };
  }

  componentDidMount() { }

  componentWillReceiveProps(props) {
    this.setState({ detailData: props.detailData })
  }

  getDestroyOnClose() {
    return true
  }


  renderBodyContent() {
    const { detailData } = this.state;

    return (
      <QueryDetail id="16" detailData={detailData} />
    )
  }
}