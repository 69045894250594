import React, { Fragment, createRef } from "react";
import { Input, List, Button, Toast, Ellipsis, Space, } from "antd-mobile";
import BaseExtractValuePopup from "../../../BaseExtractValuePopup";
import CECCalendarPopup from "../../../../../../../components/CalendarPopup";
import Required from "../../../../../../../components/Required";
import CECSelectorListItem from "../../../../../../../components/SelectorListItem";
import DeleteAndSaveButton from "../../../../../../../components/CompositeButton";
import AttendTargetPopup from "../../../AttendTargetPopup";
import ListItemGroup from "../../../../../../../components/ListItemGroup";
import _ from 'lodash';
import moment from "moment";
import {
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_OTHER_VALUE,
  AMOUNT_UNIT_OPTIONS,
  DEAL_RANGE_OPTIONS,
  SaveInfo,
  DeleteInfo,
} from './transform'


/**
 * 基金投退
 */
export default class InvestmentExitNeeds4Fund extends BaseExtractValuePopup {
  constructor(props) {
    super(props);

    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    let attrType = _.get(props, "type")
    info = _.assign({}, info, {
      code: value,
      targetType: type,
      type: attrType
    })
    this.state = {
      _info: info,
    }
    this.dateRef = createRef()
    this.gpOwnerRef = createRef()
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { value, type } = super.getAttendTargetValue()
    let info = _.get(props, "value.value", {})
    let attrType = _.get(props, "type")
    info = _.assign({}, info, {
      code: value,
      targetType: type,
      type: attrType
    })
    this.setState({ _info: info, })
  }
  async handleClick(key, data, event) {
    // console.log("handleClick ", key, data, event)

    switch (key) {
      case 'handle_confirm_value':
        await this.saveInfo()
        break;
      case 'handle_delete_value':
        await this.deleteInfo()
        break;
      default:
        break;
    }
  }
  handleChange(key, data, event) {
    // console.log("handleChange", key, data, event)
    const { _info } = this.state

    switch (key) {
      case "change_value":
        this.setState({ _info: { ..._info, [data]: event } })
        break;
      default:
        break;
    }
  }
  async saveInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      const response = await SaveInfo(_info)
      this.setState({ _info: response }, () => {
        this.close()
        onConfirm && onConfirm({ ...response })
        Toast.show({ content: '保存成功' })
      })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  async deleteInfo() {
    const { _info } = this.state
    const { onConfirm } = this.props
    try {
      Toast.show({ icon: 'loading' })
      await DeleteInfo(_info)
      this.close()
      onConfirm && onConfirm()
      Toast.show({ content: '删除成功' })
    } catch (error) {
      Toast.show({ content: error })
    }
  }
  renderBodyContent() {
    const { _info } = this.state

    return (
      <Fragment>
        <List>

          <List.Item extra={<Input placeholder="请输入" value={_info['name'] ? _info['name'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'name')} />}>基金名称<Required /></List.Item>

          <List.Item extra={<Space><Input placeholder="请输入" value={_info['ratio'] ? _info['ratio'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'ratio')} /><span>%</span></Space>}>基金持股</List.Item>

          <List.Item extra={_info['date'] ? moment(_info['date']).format('YYYY/MM') : "请选择"} onClick={() => this.dateRef?.current?.toggle()}>投资时间</List.Item>

          <ListItemGroup label="投资成本">
            <CECSelectorListItem
              value={_info['costCurrency'] ? _.map(_info['costCurrency'], 'value') : []}
              options={CURRENCY_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, costCurrency: options } })}
            >
              {_info['costCurrency'] && _.map(_info['costCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['costCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'costCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              value={_info['costUnit'] ? _.map(_info['costUnit'], 'value') : []}
              options={AMOUNT_UNIT_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, costUnit: options } })}
            >
              <Input placeholder="请输入金额" value={_info['costAmount'] ? _info['costAmount'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'costAmount')} />
            </CECSelectorListItem>
          </ListItemGroup>

          <ListItemGroup label="当前价值">
            <CECSelectorListItem
              value={_info['valueCurrency'] ? _.map(_info['valueCurrency'], 'value') : []}
              options={CURRENCY_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, valueCurrency: options } })}
            >
              {_info['valueCurrency'] && _.map(_info['valueCurrency'], 'value').includes(CURRENCY_OPTIONS_OTHER_VALUE) && <Input placeholder="请输入其他币种" value={_info['valueCurrencyOther']} onChange={this.handleChange.bind(this, 'change_value', 'valueCurrencyOther')} />}
            </CECSelectorListItem>

            <CECSelectorListItem
              value={_info['valueUnit'] ? _.map(_info['valueUnit'], 'value') : []}
              options={AMOUNT_UNIT_OPTIONS}
              onChange={(value, options) => this.setState({ _info: { ..._info, valueUnit: options } })}
            >
              <Input placeholder="请输入金额" value={_info['valueAmount'] ? _info['valueAmount'] : ''} onChange={this.handleChange.bind(this, 'change_value', 'valueAmount')} />
            </CECSelectorListItem>
          </ListItemGroup>


          <List.Item extra={<Ellipsis content={_info['gpOwners'] ? _.join(_.map(_info['gpOwners'], 'label')) : "请选择"} />} onClick={() => this.gpOwnerRef?.current?.toggle()}>基金管理人（GP）</List.Item>

          <CECSelectorListItem label="项目范围"
            columns={2}
            options={DEAL_RANGE_OPTIONS}
            value={_info['dealRange'] ? _.map(_info['dealRange'], 'value') : []}
            onChange={(value, options) => this.handleChange('change_value', 'dealRange', options)}
          />
        </List>

        <CECCalendarPopup
          precision="month"
          ref={this.dateRef}
          value={_info['date'] ? _info['date'] : moment().toDate()}
          metadata={{ title: "投资时间" }}
          onConfirm={this.handleChange.bind(this, 'change_value', 'date')}
        />

        <AttendTargetPopup ref={this.gpOwnerRef}
          mode={"target"}
          targetCategorys={['investment']}
          targetMultiple={true}
          metadata={{ title: '基金管理人（GP）' }}
          value={{ targets: _info['gpOwners'] }}
          onConfirm={(data) => this.handleChange('change_value', 'gpOwners', data['targets'])}
        />
      </Fragment>
    )
  }
  renderFootContent() {
    const { _info } = this.state

    return (
      <Fragment>
        {
          _info['id'] ? (
            <DeleteAndSaveButton
              onDelete={this.handleClick.bind(this, 'handle_delete_value')}
              onConfirm={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}
            />
          ) : (
            <Button block color="primary" style={{ fontSize: '14px' }} onClick={_.debounce(this.handleClick.bind(this, 'handle_confirm_value'), 1000)}>保存</Button>
          )
        }
      </Fragment>
    )
  }
}