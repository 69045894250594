import { Fragment } from "react"
import _ from "lodash"
import FundListView from "./FundListView"
import FundraisingListView from "./FundraisingListView"
import InvestmentSectorListView from "./InvestmentSectorListView"
import InvestmentCaseListView from "./InvestmentCaseListView"
import EmploymentHistoryListView from "./EmploymentHistoryListView"

/**
 * 市场化机构 - 表单部分
 * @param {*} param0 
 * @returns 
 */
export default function GPBodyInfoView({ code, targets, tabs }) {
  const currentActiveInfoTab = _.get(_.head(_.filter(tabs, { selected: true })), "key")
  // 组装编辑页面使用的数据
  const targetTypes = [{ value: "info_market", label: "市场化机构" }]
  const targetExtensionValue = {
    targets,
    targetTypes
  }

  return (
    <Fragment>
      {
        currentActiveInfoTab === 'gp_info_tab_1' && <FundListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'gp_info_tab_2' && <FundraisingListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'gp_info_tab_3' && <InvestmentSectorListView code={code} targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'gp_info_tab_4' && <div></div>
      }
      {
        currentActiveInfoTab === 'gp_info_tab_5' && <InvestmentCaseListView code={code} type="investment" targetExtensionValue={targetExtensionValue} />
      }
      {
        currentActiveInfoTab === 'gp_info_tab_6' && <EmploymentHistoryListView code={code} targetExtensionValue={targetExtensionValue} />
      }
    </Fragment>
  )
}