import React, { Fragment, useRef, useImperativeHandle, useState, useEffect } from 'react';
import _ from 'lodash';
import { Ellipsis, Input, List, Button, Popover, Space, Toast } from 'antd-mobile';
import { ExclamationCircleOutline } from 'antd-mobile-icons';
import InfoDetailBaseItem, {
  InfoDetailBaseItemTagComponent,
  InfoDetailBaseItemUserComponent,
  InfoDetailBaseItemIndustryStageScoreComponent,
  InfoDetailBaseItemAmountComponent,
} from '../InfoDetailBaseItem';
import './index.less';
import CECPopup from '../../../../../../components/Popup';
import CECSelectorListItem from '../../../../../../components/SelectorListItem';
import AttendTargetPopup from '../../../../UpdateEntry/components/AttendTargetPopup';
import {
  SaveLPManageInvestment,
  DeleteLPManageInvestment,
} from "../transform";

/**
 * LP基本信息
 */
export default function LPBaseInfoView({ value = {}, onRefresh }) {

  const lpManageOrgPopupRef = useRef(null)

  let currentManageInvestment = _.head(_.get(value, "manageInvestments", []))
  currentManageInvestment = currentManageInvestment ? currentManageInvestment : {}

  return (
    <Fragment>
      <div className="info-detail-base-item-box">
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="类型" renderComponent={() => value['types'] && _.size(value['types']) > 0 && <InfoDetailBaseItemTagComponent datas={value['types']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="LP地址/注册地" renderComponent={() => value['address'] && <span>{value['address']}</span>} />
        </div>
        <div className='info-detail-space'>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="易凯覆盖" renderComponent={() => value['coverageLabel'] && <span>{value['coverageLabel']}</span>} />
          </div>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="是否活跃" renderComponent={() => value['activeLabel'] && <span>{value['activeLabel']}</span>} />
          </div>
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="易凯负责人" renderComponent={() => value['ecapitalUsers'] && _.size(value['ecapitalUsers']) > 0 && <InfoDetailBaseItemUserComponent datas={value['ecapitalUsers']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="管理机构"
            renderComponent={() => (
              <Fragment>
                {
                  value['manageInvestments'] && _.size(value['manageInvestments']) > 0 ? value['manageInvestments'].map(item => <span>{item['label']}</span>) : <span className='base-item-empty'>暂无</span>
                }
                {
                  value['manageInvestments'] && _.size(value['manageInvestments']) > 0 ? <span style={{ color: '#1890FF', textDecoration: 'underline', marginLeft: 5 }} onClick={() => lpManageOrgPopupRef?.current?.show()}>修改</span> : <span style={{ color: '#1890FF', textDecoration: 'underline', marginLeft: 5 }} onClick={() => lpManageOrgPopupRef?.current?.show()}>设置</span>
                }
              </Fragment>
            )} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="受托管理机构" renderComponent={() => value['receiveInvestments'] && _.size(value['receiveInvestments']) > 0 && <Fragment>{value['receiveInvestments'].map(item => <span>{item['label']}</span>)}</Fragment>} />
        </div>
        <div className='info-detail-space'>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="基金规模" renderComponent={() => <InfoDetailBaseItemAmountComponent data={{ amountValue: value['manageFundScale'], amountUnitValue: value['manageFundScaleUnit'], amountUnitLabel: value['manageFundScaleUnitLabel'], amountCurrency: value['manageFundScaleCurrency'] }} />} />
          </div>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="出资规模" renderComponent={() => <InfoDetailBaseItemAmountComponent data={{ amountValue: value['outScale'], amountUnitValue: value['outScaleUnit'], amountUnitLabel: value['outScaleUnitLabel'], amountCurrency: value['outScaleCurrency'] }} />} />
          </div>
        </div>
        <div className='info-detail-space'>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="投资倾向" renderComponent={() => value['tendency'] && _.size(value['tendency']) > 0 && <Fragment>{value['tendency'].map(item => <span>{item['label']}</span>)}</Fragment>} />
          </div>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="已出资" renderComponent={() => (
              <Fragment>
                {
                  value['investment'] === true ? <span>是</span> : <span>否</span>
                }
                {
                  value['investment'] === true && (
                    <span>
                      （
                      {<InfoDetailBaseItemAmountComponent data={{ amountValue: value['investmentAmount'], amountUnitValue: value['investmentUnit'], amountUnitLabel: value['investmentUnitLabel'], amountCurrency: value['investmentCurrency'] }} />}
                      ）
                    </span>
                  )
                }
              </Fragment>
            )} />
          </div>
        </div>
        <div className='info-detail-space'>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="期限" renderComponent={() => value['term'] && _.size(value['term']) > 0 && <Fragment>{value['term'].map(item => <span>{item['label']}</span>)}</Fragment>} />
          </div>
          <div className='info-detail-space-column'>
            <InfoDetailBaseItem label="返投条件" renderComponent={() => value['reinvestmentConditions'] && <span>1:{value['reinvestmentConditions']}</span>} />
          </div>
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="管理规模" renderComponent={() => <InfoDetailBaseItemAmountComponent data={{ amountValue: value['manageScale'], amountUnitValue: value['manageScaleUnit'], amountUnitLabel: value['manageScaleUnitLabel'], amountCurrency: value['manageScaleCurrency'] }} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="决策流程" renderComponent={() => value['voteProgress'] && <span>{value['voteProgress']}</span>} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="特殊条件" renderComponent={() => (
            <Fragment>
              {
                value['specialConditions'] && _.size(value['specialConditions']) > 0 && value['specialConditions'].map(item => (
                  <Fragment>
                    {
                      <div>{_.join(_.map(item['specialCondition'], "label"))}：{_.join(_.map(item['active'], "label"))}</div>
                    }
                  </Fragment>
                ))
              }
            </Fragment>
          )} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="行业/阶段（评分）" renderComponent={() => value['industryAndStages'] && _.size(value['industryAndStages']) > 0 && <InfoDetailBaseItemIndustryStageScoreComponent datas={value['industryAndStages']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="简介" renderComponent={() => value['description'] && <Ellipsis direction='end' rows={2} expandText='展开' collapseText='收起' content={value['description']} />} />
        </div>
        <div className='info-detail-space'>
          <InfoDetailBaseItem label="备注" renderComponent={() => value['note'] && <Ellipsis direction='end' rows={2} expandText='展开' collapseText='收起' content={value['note']} />} />
        </div>
      </div>

      {/** 选择管理机构列表 */}
      <LPManageOrgPopup ref={lpManageOrgPopupRef}
        lpCode={value['code']}
        value={{
          id: currentManageInvestment['id'],
          currencys: currentManageInvestment['currency'] ? [{ value: currentManageInvestment['currency'], label: currentManageInvestment['currency'] }] : [],
          units: currentManageInvestment['amountUnitValue'] && currentManageInvestment['amountUnitLabel'] ? [{ value: currentManageInvestment['amountUnitValue'].toString(), label: currentManageInvestment['amountUnitLabel'] }] : [],
          amountValue: currentManageInvestment['amountValue'],
          orgs: currentManageInvestment['value'] && currentManageInvestment['label'] ? [{ value: currentManageInvestment['value'], label: currentManageInvestment['label'] }] : [],
        }}
        onClose={onRefresh}
      />
    </Fragment>
  )
}


/**
 * LP详情 - 管理机构
 */
export const LPManageOrgPopup = React.forwardRef(({ value, lpCode, onClose }, ref) => {

  const [orgs, setOrgs] = useState([])
  const [currencys, setCurrencys] = useState([])
  const [units, setUnits] = useState([])
  const [amountValue, setAmountValue] = useState('')
  const [popoverVisible, setPopoverVisible] = useState(false)

  const basePopupRef = useRef(null)
  const selectPopupRef = useRef(null)

  // 将ref绑定basePopupRef上
  useImperativeHandle(ref, () => ({
    show: () => {
      basePopupRef?.current?.show();
    },
    close: () => {
      onClose && onClose()
      basePopupRef?.current?.close();
    },
  }))

  useEffect(() => {
    setOrgs(value['orgs'])
    setCurrencys(value['currencys'])
    setUnits(value['units'])
    setAmountValue(value['amountValue'])
  }, [value])

  const save = async () => {
    Toast.show({ icon: 'loading', duration: 0 })
    try {
      const payload = {
        id: value['id'],
        lpCode: lpCode,
        orgCode: _.get(_.head(orgs), "value"),
        amountValue: amountValue * _.get(_.head(units), "value"),
        unitValue: _.get(_.head(units), "value"),
        currency: _.get(_.head(currencys), "value"),
      }
      await SaveLPManageInvestment(payload)
      Toast.show({ content: '保存成功' })
      ref.current?.close();
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  const remove = async () => {
    Toast.show({ icon: 'loading', duration: 0 })
    setPopoverVisible(false)
    try {
      await DeleteLPManageInvestment(value['id'])
      Toast.show({ content: '删除成功' })
      ref.current?.close();
    } catch (error) {
      Toast.show({ content: error })
    }
  }

  const renderFootContent = () => {
    return (
      value['id'] ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Popover trigger='click' visible={popoverVisible} content={(
            <div>
              <div style={{ marginBottom: 10 }}>
                <ExclamationCircleOutline style={{ color: '#ff8f1f', marginRight: 5 }} />
                <span>确定删除当前记录？</span>
              </div>
              <Space align='center' justify='end' style={{ width: '100%' }}>
                <Button size='mini' onClick={() => setPopoverVisible(false)}>取消</Button>
                <Button size='mini' color='primary' onClick={() => remove()}>确定</Button>
              </Space>
            </div>
          )}>
            <Button block color='danger' style={{ marginRight: 5 }} onClick={() => setPopoverVisible(!popoverVisible)}>删除</Button>
          </Popover>
          <Button block color='primary' onClick={() => save()}>保存</Button>
        </div>
      ) : (
        <div>
          <Button block color='primary' onClick={() => save()}>保存</Button >
        </div >
      )
    )
  }

  const renderBodyContent = () => {
    return (
      <Fragment>
        <List>
          <List.Item extra={orgs && _.size(orgs) > 0 ? _.join(_.map(orgs, "label")) : "请选择"} onClick={() => selectPopupRef?.current?.show()}>管理机构</List.Item>
          <CECSelectorListItem label="总管理规模币种"
            isRequired={false}
            columns={2}
            options={[
              { label: '人民币', value: '人民币' },
              { label: '美元', value: '美元' },
              { label: '人民币/美元', value: '人民币/美元' },
              { label: '其他', value: '其他' },
            ]}
            value={currencys ? _.map(currencys, "value") : []}
            onChange={(value, options) => setCurrencys(options)}
          />
          <CECSelectorListItem label="总管理规模单位"
            isRequired={false}
            options={[
              { label: '千元', value: '1000' },
              { label: '百万', value: '1000000' },
              { label: '十亿', value: '1000000000' },
            ]}
            value={units ? _.map(units, "value") : []}
            onChange={(value, options) => setUnits(options)}
          />
          <List.Item extra={<Input placeholder='请输入金额' value={amountValue ? amountValue : ""} onChange={value => setAmountValue(value)} />}>总管理规模金额</List.Item>
        </List>

        {/** 请选择管理机构 */}
        <AttendTargetPopup mode="target"
          targetCategorys={['investment']}
          ref={selectPopupRef}
          metadata={{ title: '管理机构' }}
          value={{ targets: orgs }}
          onConfirm={(value) => setOrgs(_.get(value, "targets", []))}
        />
      </Fragment>
    )
  }

  return (
    <CECPopup ref={basePopupRef}
      overflow={false}
      navTitle="管理机构"
      navRight={null}
      destroyOnClose={false}
      onNavBack={() => basePopupRef?.current?.close()}
      foot={renderFootContent()}>
      {
        renderBodyContent()
      }
    </CECPopup>
  )
})