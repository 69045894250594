


/**
 * 格式化数字（千分位）
 * @param {*} amountValue 金额数字
 * @param {*} amountLabel 金额描述
 * @param {*} unitValue 单位数字
 * @param {*} unitLabel 单位描述
 * @param {*} currencyLabel 币种名称
 * @returns 
 */
export function formatAmountNumber(amountValue, amountLabel, unitValue = 1, unitLabel = '万', currencyLabel) {
  // console.log('formatNumber >>>', amountValue, amountLabel, unitValue, unitLabel, currencyLabel)

  if (amountValue) {
    // 提取数字部分和单位部分
    let matches = `${amountValue}`.match(/([\d,.]+)(.*)/);
    if (!matches) {
      return `${amountValue}${unitLabel}${currencyLabel}`;
    }

    let num = parseFloat(matches[1].replace(/,/g, ''));
    // 检查不为数字
    if (isNaN(num)) {
      return `${amountValue}${unitLabel}${currencyLabel}`;
    }

    // 保留最多四位小数
    let formattedNum = (num / unitValue).toFixed(4);
    // 将整数部分与小数部分分开
    let [integerPart, decimalPart] = formattedNum.split('.');
    // 去掉小数点后无效的0
    decimalPart = decimalPart.replace(/\.?0+$/, "");
    // 千分位分割
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedNum = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    return `${formattedNum}${unitLabel}${currencyLabel}`;
  } else {
    return amountLabel ? (amountLabel != "未透露" ? `${amountLabel}${currencyLabel}` : amountLabel) : ''
  }
}


/**
 * 解析金额为具体数值
 * @param {*} amountValue 
 * @param {*} unitValue 
 */
export function parseAmountNumber(amountValue, unitValue = 1) {

  if (amountValue) {

    return unitValue ? amountValue * unitValue : amountValue

  }

  return amountValue
}

/**
 * 解析金额为不含单位数值
 * @param {*} amountValue 
 * @param {*} unitValue 
 * @param {*} fixed 
 */
export function parseAmountUnitNumber(amountValue, unitValue = 1, fixed = 4) {

  if (amountValue) {

    return unitValue ? (amountValue / unitValue).toFixed(fixed) : amountValue

  }

  return amountValue

}