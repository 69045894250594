import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Toast, Avatar, Space, CapsuleTabs, Collapse } from 'antd-mobile';
import {
  FetchInfoDetail
} from './transform';
import BaseExtractPopup from "../../components/BaseExtractPopup";
import './index.css';
export class InfoUpdateDetail extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      _loading: false,
      _code: _.get(props, 'code', null),
      _detail: null
    }
  }

  componentDidMount() {
    this.fetchDetail()
  }

  componentWillReceiveProps(props) {
    this.setState({
      _code: _.get(props, 'code', null)
    }, () => this.fetchDetail())
  }

  fetchDetail() {
    const { _loading, _code } = this.state
    console.log("fetchDetail===>", _code)
    if (!_.isNull(_code)) {
      if (_loading) {
        return
      }
      this.setState({ _loading: true })

      FetchInfoDetail(_code).then((detail) => {

        this.setState({ _loading: false, _detail: detail })
      }).catch(e => this.setState({ _loading: false }, () => Toast.show({ content: e })))

    }
  }

  render() {
    const { _detail, _loading, _code } = this.state
    
    let list = formatList(_detail?.dataList)
    // console.log("allList====>", _detail?.activityTab)  
    return (
      <div className='cec-info-update-layout'>
        <div className='cec-info-update-layout-title'>
          <Space >
            <Avatar style={{ border: "1px solid #eee", borderRadius: 4, padding: 2 }} src={_detail?.logo} fallback={<svg t="1724051007943" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2482" width="44" height="44"><path d="M1001.7 969.6H890.4V399.4c0-27.7-17.4-52.8-43.3-62.5L580 236.7c-14.9-5.6-31.1-5.5-45.7-0.4V76.6c0-21.9-10.7-42.4-28.7-54.9s-41-15.3-61.5-7.6L176.9 114.3c-25.9 9.7-43.3 34.9-43.3 62.5v792.8H22.3C10 969.6 0 979.6 0 991.9s10 22.3 22.3 22.3H1001.8c12.3 0 22.3-10 22.3-22.3s-10.1-22.3-22.4-22.3zM178.1 176.8c0-9.2 5.8-17.6 14.4-20.8L459.7 55.8c7-2.6 14.4-1.7 20.5 2.5s9.6 10.9 9.6 18.3v893H178.1V176.8z m356.2 792.8V299.3c0-7.4 3.5-14.1 9.6-18.3 6.1-4.2 13.6-5.2 20.5-2.5l267.1 100.2c8.6 3.2 14.4 11.6 14.4 20.8v570.2H534.3z" p-id="2483"></path><path d="M391.8 346.3H258.2c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3h133.6c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM748 479.9H614.4c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3H748c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM748 613.4H614.4c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3H748c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3zM391.8 613.4H258.2c-12.3 0-22.3 10-22.3 22.3s10 22.3 22.3 22.3h133.6c12.3 0 22.3-10 22.3-22.3s-10-22.3-22.3-22.3z" p-id="2484"></path></svg>} />
            <span>{_detail?.name}</span>
            <span style={{ color: "#999", fontSize: 13, marginTop: 10 }}>{_detail?.type}</span>
          </Space>
        </div>
        <CapsuleTabs className='cec-info-update-tabs' activeKey={_detail?.activityTab}>
          <CapsuleTabs.Tab key="公司" title="公司" />
          <CapsuleTabs.Tab key="GP/LP" title="GP" />
          <CapsuleTabs.Tab key="LP" title="LP" />
        </CapsuleTabs>
        
        {
          _.size(list) > 0 && (
            <Collapse className='cec-info-update-collapse' defaultActiveKey={[`1`]}>
                {
                  _.map(list, (group, i) => renderGroupView2({...group, index: `${i+1}`}))
                }
            </Collapse>
          )
        }
        {
          _.size(list) == 0 && (
            <div style={{width: "100%", height: 300, lineHeight: "300px", textAlign: "center", fontSize: 13, color: "#999"}}>
              暂无数据
            </div>
          )
        }
        
        
        {/* {
          _.map(_detail?.dataList, group => renderGroupView(group))
        } */}
      </div>
    )
  }
}

function renderGroupView2({ label, value, index}) {
  // console.log("renderGroupView2==>", index)
  return (
    <Collapse.Panel key={index} title={label}>
      {
        _.map(value, (v) => {
          if (typeof (v['value']) == "string") {
            return (
              <ItemView key={`ItemView-${Math.random()}`} label={v['label']} value={v['value']} />
            )
          } 
          // else if (_.isArray(v['value'])) {
          //   return renderGroupView2({...v, index: `10${index}`})
          // }
        })
      }
    </Collapse.Panel>
  )
}

function renderGroupView({ label, value }) {
  return (
    <div key={`GroupItem-${Math.random()}`} style={{ marginTop: 8 }}>
      <div className="item-label" style={{ color: "#666", padding: '2px 0', }}>{label}</div>
      {
        _.map(value, v => {
          if (typeof (v['value']) == "string") {
            return (
              <ItemView key={`ItemView-${Math.random()}`} label={v['label']} value={v['value']} />
            )
          } else if (_.isArray(v['value'])) {
            return renderGroupView(v)
          }
        })
      }
    </div>
  )
}

function ItemView({ label, value }) {
  return (
    <div key={`ItemView-${Math.random()}`} className="query-entry-detail-meeting-attendee-content" style={{ padding: '2px 0' }}>
      <div className="item-label">{label}：</div>
      <div>{value}</div>
    </div>
  )
}

export default class extends BaseExtractPopup {

  getDestroyOnClose() {
    return true
  }

  renderBodyContent() {
    const { code } = this.props
    return <InfoUpdateDetail code={code} />
  }

  // renderFootContent() {
  //     return (
  //       <div>
  //         <Button block color="primary" onClick={()=>{}}>保存</Button>
  //       </div>
  //     )
  // }
}


function formatList(dataList) {
  let list = []
  _.map(dataList, item=> {
    list.push(item)
    _.map(item['value'], subItem=> {
      if(_.isArray(subItem['value'])) {
        list.push(subItem)
      }
    })

    
  })
  return list
}

